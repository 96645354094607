import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: number;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: string;
  /** The built-in `Decimal` scalar type. */
  Decimal: number;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: number;
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: any;
  UUID: string;
};

export type ActivateUserRequest = {
  authenticatedId: Scalars["UUID"];
  userId: Scalars["UUID"];
};

export type Agent = {
  __typename?: "Agent";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  cellPhoneNumber?: Maybe<Scalars["String"]>;
  /**
   * A red flag - if an agent is no longer certified. Clubs cannot negotiate with a third party
   * that has not been certified by the NFLPA
   */
  certified: Scalars["Boolean"];
  city?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastName?: Maybe<Scalars["String"]>;
  lastUpdated: Scalars["DateTime"];
  leagueAgentId: Scalars["Int"];
  middleName?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  state?: Maybe<Scalars["String"]>;
  update: Agent;
  workPhone?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type AgentUpdateArgs = {
  details: AgentDetailsInput;
};

export type AgentDetailsInput = {
  address1?: InputMaybe<Scalars["String"]>;
  address2?: InputMaybe<Scalars["String"]>;
  cellPhoneNumber?: InputMaybe<Scalars["String"]>;
  certified: Scalars["Boolean"];
  city?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  fax?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  leagueAgentId: Scalars["Int"];
  middleName?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  workPhone?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type AgentFilterInput = {
  address1?: InputMaybe<StringOperationFilterInput>;
  address2?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AgentFilterInput>>;
  cellPhoneNumber?: InputMaybe<StringOperationFilterInput>;
  /**
   * A red flag - if an agent is no longer certified. Clubs cannot negotiate with a third party
   * that has not been certified by the NFLPA
   */
  certified?: InputMaybe<BooleanOperationFilterInput>;
  city?: InputMaybe<StringOperationFilterInput>;
  company?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  fax?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueAgentId?: InputMaybe<IntOperationFilterInput>;
  middleName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AgentFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  state?: InputMaybe<StringOperationFilterInput>;
  workPhone?: InputMaybe<StringOperationFilterInput>;
  zip?: InputMaybe<StringOperationFilterInput>;
};

export type AgentSortInput = {
  address1?: InputMaybe<SortEnumType>;
  address2?: InputMaybe<SortEnumType>;
  cellPhoneNumber?: InputMaybe<SortEnumType>;
  /**
   * A red flag - if an agent is no longer certified. Clubs cannot negotiate with a third party
   * that has not been certified by the NFLPA
   */
  certified?: InputMaybe<SortEnumType>;
  city?: InputMaybe<SortEnumType>;
  company?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  fax?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueAgentId?: InputMaybe<SortEnumType>;
  middleName?: InputMaybe<SortEnumType>;
  state?: InputMaybe<SortEnumType>;
  workPhone?: InputMaybe<SortEnumType>;
  zip?: InputMaybe<SortEnumType>;
};

/** Player alerts */
export type Alert = {
  __typename?: "Alert";
  abbreviation: Scalars["String"];
  alertType: AlertTypeEnum;
  caption: Scalars["String"];
  created: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  sort?: Maybe<Scalars["Int"]>;
  update: Alert;
};

/** Player alerts */
export type AlertUpdateArgs = {
  details: AlertDetailsInput;
};

export type AlertDetailsInput = {
  abbreviation: Scalars["String"];
  caption: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  sort?: InputMaybe<Scalars["Int"]>;
};

/** Player alerts */
export type AlertFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  alertType?: InputMaybe<AlertTypeEnumOperationFilterInput>;
  and?: InputMaybe<Array<AlertFilterInput>>;
  caption?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<AlertFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

/** Player alerts */
export type AlertSortInput = {
  abbreviation?: InputMaybe<SortEnumType>;
  alertType?: InputMaybe<SortEnumType>;
  caption?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  sort?: InputMaybe<SortEnumType>;
};

export enum AlertTypeEnum {
  SYSTEM = "SYSTEM",
  USER = "USER",
}

export type AlertTypeEnumOperationFilterInput = {
  eq?: InputMaybe<AlertTypeEnum>;
  in?: InputMaybe<Array<AlertTypeEnum>>;
  neq?: InputMaybe<AlertTypeEnum>;
  nin?: InputMaybe<Array<AlertTypeEnum>>;
};

export type AnyEntitySearchResult = {
  __typename?: "AnyEntitySearchResult";
  /** The club search result entity (if that is the type of the particular result row) */
  club?: Maybe<SearchResultOfClubSearchDocument>;
  /** The player search result entity (if that is the type of the particular result row) */
  player?: Maybe<SearchResultOfPlayerSearchDocument>;
  /** The school search result entity (if that is the type of the particular result row) */
  school?: Maybe<SearchResultOfSchoolSearchDocument>;
  /** The score of the search result from its respective index */
  score?: Maybe<Scalars["Float"]>;
};

export type AnyEntitySearchResultFilterInput = {
  and?: InputMaybe<Array<AnyEntitySearchResultFilterInput>>;
  /** The club search result entity (if that is the type of the particular result row) */
  club?: InputMaybe<SearchResultOfClubSearchDocumentFilterInput>;
  or?: InputMaybe<Array<AnyEntitySearchResultFilterInput>>;
  /** The player search result entity (if that is the type of the particular result row) */
  player?: InputMaybe<SearchResultOfPlayerSearchDocumentFilterInput>;
  /** The school search result entity (if that is the type of the particular result row) */
  school?: InputMaybe<SearchResultOfSchoolSearchDocumentFilterInput>;
  /** The score of the search result from its respective index */
  score?: InputMaybe<FloatOperationFilterInput>;
};

export type AnyEntitySearchResultSortInput = {
  /** The club search result entity (if that is the type of the particular result row) */
  club?: InputMaybe<SearchResultOfClubSearchDocumentSortInput>;
  /** The player search result entity (if that is the type of the particular result row) */
  player?: InputMaybe<SearchResultOfPlayerSearchDocumentSortInput>;
  /** The school search result entity (if that is the type of the particular result row) */
  school?: InputMaybe<SearchResultOfSchoolSearchDocumentSortInput>;
  /** The score of the search result from its respective index */
  score?: InputMaybe<SortEnumType>;
};

/**
 * A role is a certain job function (like scout, GM, or admin) that can be assigned to a user.  Each role
 * has a collection of permissions associated with it that the user will have actions to perform.
 */
export type AppRole = {
  __typename?: "AppRole";
  created: Scalars["DateTime"];
  createdById: Scalars["UUID"];
  /** Details about what the role is for */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  /** Denotes if the role has been soft deleted */
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  lastUpdatedById: Scalars["UUID"];
  /** The name of the role */
  name: Scalars["String"];
  /** Permission list assigned to role */
  permissions: Array<Permission>;
  rowVersion: Array<Scalars["Byte"]>;
  type: RoleType;
};

/**
 * A role is a certain job function (like scout, GM, or admin) that can be assigned to a user.  Each role
 * has a collection of permissions associated with it that the user will have actions to perform.
 */
export type AppRoleFilterInput = {
  and?: InputMaybe<Array<AppRoleFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  /** Details about what the role is for */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Denotes if the role has been soft deleted */
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedById?: InputMaybe<UuidOperationFilterInput>;
  /** The name of the role */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppRoleFilterInput>>;
  /** Permission list assigned to role */
  permissions?: InputMaybe<ListFilterInputTypeOfPermissionFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  type?: InputMaybe<RoleTypeOperationFilterInput>;
};

/**
 * A role is a certain job function (like scout, GM, or admin) that can be assigned to a user.  Each role
 * has a collection of permissions associated with it that the user will have actions to perform.
 */
export type AppRoleSortInput = {
  created?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  /** Details about what the role is for */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Denotes if the role has been soft deleted */
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  lastUpdatedById?: InputMaybe<SortEnumType>;
  /** The name of the role */
  name?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export enum ApplyPolicy {
  AFTER_RESOLVER = "AFTER_RESOLVER",
  BEFORE_RESOLVER = "BEFORE_RESOLVER",
  VALIDATION = "VALIDATION",
}

export type AssignContractTotalsRequest = {
  /** For a contract */
  contractId?: InputMaybe<Scalars["UUID"]>;
  effective?: InputMaybe<Scalars["Boolean"]>;
  /** All for player */
  playerId?: InputMaybe<Scalars["UUID"]>;
};

export type AssignFinalEvaluationStatusRequest = {
  /** The ID of the evaluation to assign status. */
  evaluationId: Scalars["UUID"];
};

export type AssignPermissionsToRoleRequest = {
  permissionIds: Array<Scalars["UUID"]>;
  roleId: Scalars["UUID"];
};

export type AssignPlayerOverrideRequest = {
  details: PlayerOverrideDetailsInput;
};

export type AssignReadyForEditEvaluationStatusRequest = {
  /** The ID of the evaluation to assign status. */
  evaluationId: Scalars["UUID"];
};

export type AssignWipEvaluationStatusRequest = {
  /** The ID of the evaluation to assign status. */
  evaluationId: Scalars["UUID"];
};

export type Assumption = {
  __typename?: "Assumption";
  constraint: Constraint;
  contractMinimumEndDate?: Maybe<Scalars["Date"]>;
  contracts: Array<Nym>;
  description: Scalars["String"];
  draftPick?: Maybe<DraftPick>;
  draftPickTrade?: Maybe<Array<DraftPickTransaction>>;
  draftRoundMinimum?: Maybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  isValid: Scalars["Boolean"];
  name: Scalars["String"];
  playerId?: Maybe<Scalars["String"]>;
  subject: Subject;
};

export type AssumptionFilterInput = {
  and?: InputMaybe<Array<AssumptionFilterInput>>;
  constraint?: InputMaybe<ConstraintOperationFilterInput>;
  contractMinimumEndDate?: InputMaybe<DateOperationFilterInput>;
  contracts?: InputMaybe<ListFilterInputTypeOfNymFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  draftPick?: InputMaybe<DraftPickFilterInput>;
  draftPickTrade?: InputMaybe<ListFilterInputTypeOfDraftPickTransactionFilterInput>;
  draftRoundMinimum?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isValid?: InputMaybe<BooleanOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AssumptionFilterInput>>;
  playerId?: InputMaybe<StringOperationFilterInput>;
  subject?: InputMaybe<SubjectOperationFilterInput>;
};

export type BasicPlayerDetailsInput = {
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  height?: InputMaybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  speed?: InputMaybe<Scalars["Decimal"]>;
  weight?: InputMaybe<Scalars["Int"]>;
};

export type BigBoardPlayer = {
  __typename?: "BigBoardPlayer";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  player: Player;
  playerId: Scalars["UUID"];
  rank: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
};

export type BigBoardPlayerDetailInput = {
  playerId: Scalars["UUID"];
  rank: Scalars["Int"];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars["Boolean"]>;
  neq?: InputMaybe<Scalars["Boolean"]>;
};

export type ByteOperationFilterInput = {
  eq?: InputMaybe<Scalars["Byte"]>;
  gt?: InputMaybe<Scalars["Byte"]>;
  gte?: InputMaybe<Scalars["Byte"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Byte"]>>>;
  lt?: InputMaybe<Scalars["Byte"]>;
  lte?: InputMaybe<Scalars["Byte"]>;
  neq?: InputMaybe<Scalars["Byte"]>;
  ngt?: InputMaybe<Scalars["Byte"]>;
  ngte?: InputMaybe<Scalars["Byte"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Byte"]>>>;
  nlt?: InputMaybe<Scalars["Byte"]>;
  nlte?: InputMaybe<Scalars["Byte"]>;
};

export type Calendar = {
  __typename?: "Calendar";
  /** The calendar date */
  calendarDate: Scalars["DateTime"];
  created: Scalars["DateTime"];
  /** Description of event associated with the date */
  description?: Maybe<Scalars["String"]>;
  /** The draft season the calendar date belongs */
  draftSeason: Scalars["Int"];
  id: Scalars["ID"];
  /** Is calendar date part of the draft days */
  isDraft: Scalars["Boolean"];
  /** Is calendar date part of the post season */
  isPostSeason: Scalars["Boolean"];
  /** Is calendar date part of the regular season */
  isRegSeason: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  /** The season the calendar date belongs */
  season: Scalars["Int"];
};

export type CareerStat = {
  __typename?: "CareerStat";
  gameId?: Maybe<Scalars["UUID"]>;
  lastUpdated: Scalars["DateTime"];
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  perGameStatsGamesPlayed?: Maybe<Scalars["Int"]>;
  perGameStatsPassAttemptsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsPassingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsReceivingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsReceptionsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsRushAttemptsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsRushingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  playerId: Scalars["UUID"];
  playerStat?: Maybe<PlayerStat>;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  seasonType: SeasonType;
  seasonTypeId: Scalars["Int"];
  statType: StatType;
  statTypeId: Scalars["Int"];
};

export type CareerStatFilterInput = {
  and?: InputMaybe<Array<CareerStatFilterInput>>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<CareerStatFilterInput>>;
  perGameStatsGamesPlayed?: InputMaybe<IntOperationFilterInput>;
  perGameStatsPassAttemptsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsPassingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsReceivingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsReceptionsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsRushAttemptsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsRushingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  playerStat?: InputMaybe<PlayerStatFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonType?: InputMaybe<SeasonTypeFilterInput>;
  seasonTypeId?: InputMaybe<IntOperationFilterInput>;
  statType?: InputMaybe<StatTypeFilterInput>;
  statTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type CareerStatSortInput = {
  gameId?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueType?: InputMaybe<LeagueTypeSortInput>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  perGameStatsGamesPlayed?: InputMaybe<SortEnumType>;
  perGameStatsPassAttemptsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsPassingYardsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsReceivingYardsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsReceptionsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsRushAttemptsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsRushingYardsPerGame?: InputMaybe<SortEnumType>;
  playerId?: InputMaybe<SortEnumType>;
  playerStat?: InputMaybe<PlayerStatSortInput>;
  season?: InputMaybe<SortEnumType>;
  seasonType?: InputMaybe<SeasonTypeSortInput>;
  seasonTypeId?: InputMaybe<SortEnumType>;
  statType?: InputMaybe<StatTypeSortInput>;
  statTypeId?: InputMaybe<SortEnumType>;
};

export type CarryOver = {
  __typename?: "CarryOver";
  cap: Scalars["Decimal"];
  existingCash: Scalars["Decimal"];
};

export type CarryOverFilterInput = {
  and?: InputMaybe<Array<CarryOverFilterInput>>;
  cap?: InputMaybe<DecimalOperationFilterInput>;
  existingCash?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<CarryOverFilterInput>>;
};

export type CarryOverSortInput = {
  cap?: InputMaybe<SortEnumType>;
  existingCash?: InputMaybe<SortEnumType>;
};

/** A club is an encapsulation of a team within the NFL, such as the Green Bay Packers or the Atlanta Falcons. */
export type Club = {
  __typename?: "Club";
  /** The NFL's designated club code */
  clubCode: Scalars["String"];
  /** The club's full name */
  clubName: Scalars["String"];
  /** A URL to the team's website */
  clubRosterUrl: Scalars["String"];
  /** The conference the club belongs to */
  conference: Scalars["String"];
  created: Scalars["DateTime"];
  /** The current coach of the club */
  currentCoach: Scalars["String"];
  /** The division the club belongs to */
  division: Scalars["String"];
  id: Scalars["ID"];
  /** Indicates whether or not the club is still active in the league */
  isActive: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** Where the club is located */
  location: Scalars["String"];
  /** A URL to a logo of the club */
  logoUrl: Scalars["String"];
  /** The club's nickname/mascot */
  nickName: Scalars["String"];
  /** Indicates whether team plays in a dome (indoor stadium) */
  outdoorStadium?: Maybe<Scalars["Boolean"]>;
  /** Who owns the club */
  owners: Scalars["String"];
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: Maybe<Scalars["String"]>;
  /** The type of weather team regularly plays in in their stadium. */
  stadiumTurf?: Maybe<Scalars["String"]>;
  /** The timezone the team plays in. */
  timeZone?: Maybe<Scalars["String"]>;
  /**
   * Updates a club using all the supplied values in the details
   *
   *
   * **Returns:**
   * The updated club entity
   */
  updateClubDetails: Club;
  /** The primary stadium the club plays home games at */
  venue: Scalars["String"];
  /** The year the club was formed */
  yearEstablished: Scalars["Int"];
};

/** A club is an encapsulation of a team within the NFL, such as the Green Bay Packers or the Atlanta Falcons. */
export type ClubUpdateClubDetailsArgs = {
  request: UpdateClubDetailsRequest;
};

export type ClubDetailsInput = {
  abbreviation: Scalars["String"];
  clubRosterUrl: Scalars["String"];
  conference: Scalars["String"];
  currentCoach: Scalars["String"];
  currentLogo: Scalars["String"];
  division: Scalars["String"];
  endSeason?: InputMaybe<Scalars["Int"]>;
  gsisAbbreviation?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  league?: InputMaybe<Scalars["String"]>;
  leagueClubId?: InputMaybe<Scalars["Int"]>;
  leagueTeamId?: InputMaybe<Scalars["UUID"]>;
  location: Scalars["String"];
  name: Scalars["String"];
  nickName: Scalars["String"];
  officialWebsiteUrl: Scalars["String"];
  outdoorStadium?: InputMaybe<Scalars["Boolean"]>;
  owners: Scalars["String"];
  pffFranchiseId: Scalars["Int"];
  pffLeagueId?: InputMaybe<Scalars["Int"]>;
  pffTeamId: Scalars["Int"];
  primaryColor?: InputMaybe<Scalars["String"]>;
  secondaryColor?: InputMaybe<Scalars["String"]>;
  stadiumTurf?: InputMaybe<Scalars["String"]>;
  startSeason: Scalars["Int"];
  venue: Scalars["String"];
};

/** A club is an encapsulation of a team within the NFL, such as the Green Bay Packers or the Atlanta Falcons. */
export type ClubFilterInput = {
  and?: InputMaybe<Array<ClubFilterInput>>;
  /** The NFL's designated club code */
  clubCode?: InputMaybe<StringOperationFilterInput>;
  /** The club's full name */
  clubName?: InputMaybe<StringOperationFilterInput>;
  /** A URL to the team's website */
  clubRosterUrl?: InputMaybe<StringOperationFilterInput>;
  /** The conference the club belongs to */
  conference?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The current coach of the club */
  currentCoach?: InputMaybe<StringOperationFilterInput>;
  /** The division the club belongs to */
  division?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Indicates whether or not the club is still active in the league */
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** Where the club is located */
  location?: InputMaybe<StringOperationFilterInput>;
  /** A URL to a logo of the club */
  logoUrl?: InputMaybe<StringOperationFilterInput>;
  /** The club's nickname/mascot */
  nickName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClubFilterInput>>;
  /** Indicates whether team plays in a dome (indoor stadium) */
  outdoorStadium?: InputMaybe<BooleanOperationFilterInput>;
  /** Who owns the club */
  owners?: InputMaybe<StringOperationFilterInput>;
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: InputMaybe<StringOperationFilterInput>;
  /** The type of weather team regularly plays in in their stadium. */
  stadiumTurf?: InputMaybe<StringOperationFilterInput>;
  /** The timezone the team plays in. */
  timeZone?: InputMaybe<StringOperationFilterInput>;
  /** The primary stadium the club plays home games at */
  venue?: InputMaybe<StringOperationFilterInput>;
  /** The year the club was formed */
  yearEstablished?: InputMaybe<IntOperationFilterInput>;
};

/** Encapsulates a flattened view of a club that can be searched against.  Annotated with Azure Search attributes. */
export type ClubSearchDocument = {
  __typename?: "ClubSearchDocument";
  /** The NFL's designated club code */
  clubCode: Scalars["String"];
  /** The club's full name */
  clubName: Scalars["String"];
  /** The conference the club belongs to */
  conference?: Maybe<Scalars["String"]>;
  /** The division the club belongs to */
  division?: Maybe<Scalars["String"]>;
  /** Club's internal ID */
  id: Scalars["String"];
  /** Indicates whether or not the club is still active in the league */
  isActive: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** The NFL's unique ID for this club */
  leagueId?: Maybe<Scalars["String"]>;
};

/** Encapsulates a flattened view of a club that can be searched against.  Annotated with Azure Search attributes. */
export type ClubSearchDocumentFilterInput = {
  and?: InputMaybe<Array<ClubSearchDocumentFilterInput>>;
  /** The NFL's designated club code */
  clubCode?: InputMaybe<StringOperationFilterInput>;
  /** The club's full name */
  clubName?: InputMaybe<StringOperationFilterInput>;
  /** The conference the club belongs to */
  conference?: InputMaybe<StringOperationFilterInput>;
  /** The division the club belongs to */
  division?: InputMaybe<StringOperationFilterInput>;
  /** Club's internal ID */
  id?: InputMaybe<StringOperationFilterInput>;
  /** Indicates whether or not the club is still active in the league */
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The NFL's unique ID for this club */
  leagueId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ClubSearchDocumentFilterInput>>;
};

/** Encapsulates a flattened view of a club that can be searched against.  Annotated with Azure Search attributes. */
export type ClubSearchDocumentSortInput = {
  /** The NFL's designated club code */
  clubCode?: InputMaybe<SortEnumType>;
  /** The club's full name */
  clubName?: InputMaybe<SortEnumType>;
  /** The conference the club belongs to */
  conference?: InputMaybe<SortEnumType>;
  /** The division the club belongs to */
  division?: InputMaybe<SortEnumType>;
  /** Club's internal ID */
  id?: InputMaybe<SortEnumType>;
  /** Indicates whether or not the club is still active in the league */
  isActive?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The NFL's unique ID for this club */
  leagueId?: InputMaybe<SortEnumType>;
};

/** Encapsulates a result set of clubs from the search appliance. */
export type ClubSearchResults = {
  __typename?: "ClubSearchResults";
  /** The collection of matched clubs, ordered by relevance. */
  clubs: Array<SearchResultOfClubSearchDocument>;
  /** The total number of clubs that would match the search query (excluding paging parameters) */
  total: Scalars["Long"];
};

/** Encapsulates a result set of clubs from the search appliance. */
export type ClubSearchResultsFilterInput = {
  and?: InputMaybe<Array<ClubSearchResultsFilterInput>>;
  /** The collection of matched clubs, ordered by relevance. */
  clubs?: InputMaybe<ListFilterInputTypeOfSearchResultOfClubSearchDocumentFilterInput>;
  or?: InputMaybe<Array<ClubSearchResultsFilterInput>>;
  /** The total number of clubs that would match the search query (excluding paging parameters) */
  total?: InputMaybe<LongOperationFilterInput>;
};

/** Encapsulates a result set of clubs from the search appliance. */
export type ClubSearchResultsSortInput = {
  /** The total number of clubs that would match the search query (excluding paging parameters) */
  total?: InputMaybe<SortEnumType>;
};

/** A club is an encapsulation of a team within the NFL, such as the Green Bay Packers or the Atlanta Falcons. */
export type ClubSortInput = {
  /** The NFL's designated club code */
  clubCode?: InputMaybe<SortEnumType>;
  /** The club's full name */
  clubName?: InputMaybe<SortEnumType>;
  /** A URL to the team's website */
  clubRosterUrl?: InputMaybe<SortEnumType>;
  /** The conference the club belongs to */
  conference?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** The current coach of the club */
  currentCoach?: InputMaybe<SortEnumType>;
  /** The division the club belongs to */
  division?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Indicates whether or not the club is still active in the league */
  isActive?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Where the club is located */
  location?: InputMaybe<SortEnumType>;
  /** A URL to a logo of the club */
  logoUrl?: InputMaybe<SortEnumType>;
  /** The club's nickname/mascot */
  nickName?: InputMaybe<SortEnumType>;
  /** Indicates whether team plays in a dome (indoor stadium) */
  outdoorStadium?: InputMaybe<SortEnumType>;
  /** Who owns the club */
  owners?: InputMaybe<SortEnumType>;
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: InputMaybe<SortEnumType>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: InputMaybe<SortEnumType>;
  /** The type of weather team regularly plays in in their stadium. */
  stadiumTurf?: InputMaybe<SortEnumType>;
  /** The timezone the team plays in. */
  timeZone?: InputMaybe<SortEnumType>;
  /** The primary stadium the club plays home games at */
  venue?: InputMaybe<SortEnumType>;
  /** The year the club was formed */
  yearEstablished?: InputMaybe<SortEnumType>;
};

export type CollegeReportPlayer = {
  __typename?: "CollegeReportPlayer";
  agentCompany?: Maybe<Scalars["String"]>;
  agentName?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["DateTime"]>;
  eligibility?: Maybe<Scalars["String"]>;
  evaluations: Array<Evaluation>;
  expectedDraftPosition?: Maybe<Scalars["Decimal"]>;
  firstName: Scalars["String"];
  headshotUrl?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["Int"]>;
  hometownCity?: Maybe<Scalars["String"]>;
  hometownCountry?: Maybe<Scalars["String"]>;
  hometownState?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  jersey?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  latestBirthDate?: Maybe<Scalars["String"]>;
  primaryEvaluation?: Maybe<Evaluation>;
  sage?: Maybe<Scalars["Decimal"]>;
  schoolCode?: Maybe<Scalars["String"]>;
  speed?: Maybe<Scalars["Decimal"]>;
  sumerAtlasPosition?: Maybe<Scalars["String"]>;
  sumerGeneralPosition?: Maybe<Scalars["String"]>;
  war?: Maybe<Scalars["Float"]>;
  weight?: Maybe<Scalars["Int"]>;
  workouts: Array<WorkoutPlayerMeasure>;
};

export enum Constraint {
  CUT = "CUT",
  DO_NOT_DRAFT = "DO_NOT_DRAFT",
  DO_NOT_DRAFT_UNTIL_ROUND = "DO_NOT_DRAFT_UNTIL_ROUND",
  DRAFT_PICK_TRADE = "DRAFT_PICK_TRADE",
  KEEP = "KEEP",
  MUST_DRAFT = "MUST_DRAFT",
  MUST_NOT_SIGN = "MUST_NOT_SIGN",
  MUST_SIGN = "MUST_SIGN",
}

export type ConstraintOperationFilterInput = {
  eq?: InputMaybe<Constraint>;
  in?: InputMaybe<Array<Constraint>>;
  neq?: InputMaybe<Constraint>;
  nin?: InputMaybe<Array<Constraint>>;
};

export type Contract = {
  __typename?: "Contract";
  agent?: Maybe<Contracts_Internal_Agent>;
  agentId?: Maybe<Scalars["UUID"]>;
  /** Club that submitted and is associated with the contract */
  clubId: Scalars["UUID"];
  /** Accrued seasons of the player at signing */
  contractAccSeason: Scalars["Int"];
  /** Date contract was entered into the league's system */
  contractEnteredSystem?: Maybe<Scalars["DateTime"]>;
  /** Age of the player at signing */
  contractSignAge?: Maybe<Scalars["Int"]>;
  created: Scalars["DateTime"];
  /** Last year of contract that was effective */
  effectiveLastContractYear: Scalars["Int"];
  /** First year of contract */
  firstContractYear: Scalars["Int"];
  hasVoidYears: Scalars["Boolean"];
  id: Scalars["ID"];
  /** Current contract that the player is under */
  isEffectiveContract: Scalars["Boolean"];
  /** Contract that applies the MSB rule */
  isMinSalaryBenefitContract: Scalars["Boolean"];
  /** Most recent */
  isMostRecentContract: Scalars["Boolean"];
  /** Last year of contract */
  lastContractYear: Scalars["Int"];
  lastUpdated: Scalars["DateTime"];
  /** The league's contract ID */
  leagueContractId: Scalars["Int"];
  /** Player that is on given contract */
  playerId: Scalars["UUID"];
  /** Previous league contract Id used for extension calculations */
  previousContractId?: Maybe<Scalars["Int"]>;
  rowVersion: Array<Scalars["Byte"]>;
  seasons: Array<ContractSeason>;
  sections: Array<ContractSeasonSection>;
  setSumerValues: Contract;
  setTotals: Contract;
  /** Sign date of the contract */
  signDate: Scalars["DateTime"];
  /** Signing type */
  signingType: Scalars["String"];
  source: SourceTypeEnum;
  sumer: SumerValues;
  total: ContractTotals;
  /** Transaction associated with the contract (if there is one) */
  transactionId?: Maybe<Scalars["UUID"]>;
  update: Contract;
};

export type ContractSeasonsArgs = {
  order?: InputMaybe<Array<ContractSeasonSortInput>>;
};

export type ContractSectionsArgs = {
  order?: InputMaybe<Array<ContractSeasonSectionSortInput>>;
};

export type ContractSetSumerValuesArgs = {
  details: ContractSummaryDetailsInput;
};

export type ContractSetTotalsArgs = {
  details: ContractTotalDetailsInput;
};

export type ContractUpdateArgs = {
  contractDetails: ContractDetailsInput;
};

export type ContractDetailsInput = {
  agentId?: InputMaybe<Scalars["UUID"]>;
  clubId: Scalars["UUID"];
  contractAccSeason: Scalars["Int"];
  contractEnteredSystem?: InputMaybe<Scalars["DateTime"]>;
  contractRepID: Scalars["Int"];
  contractSignAge?: InputMaybe<Scalars["Int"]>;
  effectiveLastContractYear: Scalars["Int"];
  existingAmount?: InputMaybe<Scalars["Decimal"]>;
  existingYears?: InputMaybe<Scalars["Int"]>;
  firstContractYear: Scalars["Int"];
  isEffectiveContract: Scalars["Boolean"];
  isMinSalaryBenefitContract: Scalars["Boolean"];
  isMostRecentContract: Scalars["Boolean"];
  lastContractYear: Scalars["Int"];
  leagueContractId: Scalars["Int"];
  playerId: Scalars["UUID"];
  previousContractId?: InputMaybe<Scalars["Int"]>;
  signDate: Scalars["DateTime"];
  signingType: Scalars["String"];
  source: SourceTypeEnum;
  threeYearComp: Scalars["Decimal"];
  totalIncentives: Scalars["Decimal"];
  totalOffSeason: Scalars["Decimal"];
  totalOption: Scalars["Decimal"];
  totalP5: Scalars["Decimal"];
  totalPackageAmount: Scalars["Decimal"];
  totalReportingBonus: Scalars["Decimal"];
  totalRosterBonus: Scalars["Decimal"];
  totalSigningBonus: Scalars["Decimal"];
  transactionId?: InputMaybe<Scalars["UUID"]>;
};

export type ContractFilterInput = {
  agent?: InputMaybe<AgentFilterInput>;
  agentId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<ContractFilterInput>>;
  /** Club that submitted and is associated with the contract */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  /** Accrued seasons of the player at signing */
  contractAccSeason?: InputMaybe<IntOperationFilterInput>;
  /** Date contract was entered into the league's system */
  contractEnteredSystem?: InputMaybe<DateTimeOperationFilterInput>;
  /** Age of the player at signing */
  contractSignAge?: InputMaybe<IntOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Last year of contract that was effective */
  effectiveLastContractYear?: InputMaybe<IntOperationFilterInput>;
  /** First year of contract */
  firstContractYear?: InputMaybe<IntOperationFilterInput>;
  hasVoidYears?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Current contract that the player is under */
  isEffectiveContract?: InputMaybe<BooleanOperationFilterInput>;
  /** Contract that applies the MSB rule */
  isMinSalaryBenefitContract?: InputMaybe<BooleanOperationFilterInput>;
  /** Most recent */
  isMostRecentContract?: InputMaybe<BooleanOperationFilterInput>;
  /** Last year of contract */
  lastContractYear?: InputMaybe<IntOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The league's contract ID */
  leagueContractId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ContractFilterInput>>;
  /** Player that is on given contract */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** Previous league contract Id used for extension calculations */
  previousContractId?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  seasons?: InputMaybe<ListFilterInputTypeOfContractSeasonFilterInput>;
  sections?: InputMaybe<ListFilterInputTypeOfContractSeasonSectionFilterInput>;
  /** Sign date of the contract */
  signDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Signing type */
  signingType?: InputMaybe<StringOperationFilterInput>;
  source?: InputMaybe<SourceTypeEnumOperationFilterInput>;
  sumer?: InputMaybe<SumerValuesFilterInput>;
  total?: InputMaybe<ContractTotalsFilterInput>;
  /** Transaction associated with the contract (if there is one) */
  transactionId?: InputMaybe<UuidOperationFilterInput>;
};

export type ContractSeason = {
  __typename?: "ContractSeason";
  /** Values carried over from previous contract for reworks/extensions */
  carryOvers: CarryOver;
  contract: Contract;
  /** Foreign key to Contract. Contract the years belong. */
  contractId: Scalars["UUID"];
  created: Scalars["DateTime"];
  /**
   * An escalator is a clause that allows a player to earn additional compensation based on
   * reaching certain performance-related milestones or achievements. These milestones are
   * typically specified in the contract and can include various statistical benchmarks,
   * playing time thresholds, or team performance goals.
   */
  escalators: Scalars["Decimal"];
  /** Money from previous contract brought over to new. Renegotiation */
  existingProration?: Maybe<Scalars["Decimal"]>;
  /** Contract guaranteed monies */
  guarantees: Guarantee;
  id: Scalars["ID"];
  /** Contract incentives */
  incentives: Scalars["Decimal"];
  /** If contract year is optional (rookie deals, 5th year) */
  isOptionYear: Scalars["Boolean"];
  /** If contract year is voided */
  isVoidYear: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** The league contract ID */
  leagueContractId: Scalars["Int"];
  /** Option bonus for the season */
  optionBonus: Scalars["Decimal"];
  /** Option bonus proration for season of no more than five years */
  optionBonusProrated: Scalars["Decimal"];
  /** P5 or salary for that season */
  p5: Scalars["Decimal"];
  /** Most Reporting Bonus are not guaranteed */
  reportingBonus: Scalars["Decimal"];
  resetSeasonValue: ContractSeason;
  /** Contract roster bonuses */
  rosterBonuses: RosterBonus;
  rowVersion: Array<Scalars["Byte"]>;
  /** Season of the contract */
  season: Scalars["Int"];
  setOptionBonusProration: ContractSeason;
  setOverrideValue: ContractSeason;
  setProration: ContractSeason;
  /** Signing bonus for the season */
  signingBonus: Scalars["Decimal"];
  /** The signing bonus proration for the season of no more than five years */
  signingBonusProrated: Scalars["Decimal"];
  source: SourceTypeEnum;
  update: ContractSeason;
  /** Bonus received during the off-season */
  workoutBonus: Scalars["Decimal"];
};

export type ContractSeasonResetSeasonValueArgs = {
  guaranteeType?: InputMaybe<Scalars["String"]>;
  value: Scalars["Decimal"];
  worksheetItem: Scalars["String"];
};

export type ContractSeasonSetOptionBonusProrationArgs = {
  amount: Scalars["Decimal"];
};

export type ContractSeasonSetOverrideValueArgs = {
  firstContractYear: Scalars["Int"];
  guaranteedType?: InputMaybe<Scalars["String"]>;
  guaranteedValue?: InputMaybe<Scalars["Decimal"]>;
  overrideItem: Scalars["String"];
  source: SourceTypeEnum;
  value?: InputMaybe<Scalars["Decimal"]>;
};

export type ContractSeasonSetProrationArgs = {
  bonusSeason: Scalars["Int"];
  firstContractYear: Scalars["Int"];
  lastContractYear: Scalars["Int"];
  overrideItem: Scalars["String"];
  season: Scalars["Int"];
  signingType: Scalars["String"];
  value?: InputMaybe<Scalars["Decimal"]>;
};

export type ContractSeasonUpdateArgs = {
  details: ContractSeasonDetailsInput;
};

export type ContractSeasonDetailsInput = {
  contractId: Scalars["UUID"];
  escalators: Scalars["Decimal"];
  existingProration?: InputMaybe<Scalars["Decimal"]>;
  guaranteedType_Incentives: Scalars["String"];
  guaranteedType_P5: Scalars["String"];
  guaranteedType_RB46Man: Scalars["String"];
  guaranteedType_RB53Man: Scalars["String"];
  guaranteedType_RB90Man: Scalars["String"];
  guaranteedType_RBPerGame: Scalars["String"];
  guaranteedType_ReportingBonus: Scalars["String"];
  guaranteedType_WorkoutBonus: Scalars["String"];
  guaranteed_Incentives: Scalars["Decimal"];
  guaranteed_P5: Scalars["Decimal"];
  guaranteed_P5Injury: Scalars["Decimal"];
  guaranteed_RB46Man: Scalars["Decimal"];
  guaranteed_RB53Man: Scalars["Decimal"];
  guaranteed_RB90Man: Scalars["Decimal"];
  guaranteed_RB90Man_prorated: Scalars["Decimal"];
  guaranteed_RBPerGame: Scalars["Decimal"];
  guaranteed_RBPerGame_prorated: Scalars["Decimal"];
  guaranteed_ReportingBonus: Scalars["Decimal"];
  guaranteed_ReportingBonus_prorated: Scalars["Decimal"];
  guaranteed_WorkoutBonus: Scalars["Decimal"];
  guaranteed_WorkoutBonus_prorated: Scalars["Decimal"];
  incentives: Scalars["Decimal"];
  isOptionYear: Scalars["Boolean"];
  isVoidYear: Scalars["Boolean"];
  leagueContractId: Scalars["Int"];
  optionBonus: Scalars["Decimal"];
  optionBonus_prorated: Scalars["Decimal"];
  p5: Scalars["Decimal"];
  rb_46Man: Scalars["Decimal"];
  rb_53Man: Scalars["Decimal"];
  rb_90Man: Scalars["Decimal"];
  rb_PerGame: Scalars["Decimal"];
  reportingBonus: Scalars["Decimal"];
  sb: Scalars["Decimal"];
  sb_prorated: Scalars["Decimal"];
  season: Scalars["Int"];
  source: SourceTypeEnum;
  workoutBonus: Scalars["Decimal"];
};

export type ContractSeasonFilterInput = {
  and?: InputMaybe<Array<ContractSeasonFilterInput>>;
  /** Values carried over from previous contract for reworks/extensions */
  carryOvers?: InputMaybe<CarryOverFilterInput>;
  contract?: InputMaybe<ContractFilterInput>;
  /** Foreign key to Contract. Contract the years belong. */
  contractId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /**
   * An escalator is a clause that allows a player to earn additional compensation based on
   * reaching certain performance-related milestones or achievements. These milestones are
   * typically specified in the contract and can include various statistical benchmarks,
   * playing time thresholds, or team performance goals.
   */
  escalators?: InputMaybe<DecimalOperationFilterInput>;
  /** Money from previous contract brought over to new. Renegotiation */
  existingProration?: InputMaybe<DecimalOperationFilterInput>;
  /** Contract guaranteed monies */
  guarantees?: InputMaybe<GuaranteeFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Contract incentives */
  incentives?: InputMaybe<DecimalOperationFilterInput>;
  /** If contract year is optional (rookie deals, 5th year) */
  isOptionYear?: InputMaybe<BooleanOperationFilterInput>;
  /** If contract year is voided */
  isVoidYear?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The league contract ID */
  leagueContractId?: InputMaybe<IntOperationFilterInput>;
  /** Option bonus for the season */
  optionBonus?: InputMaybe<DecimalOperationFilterInput>;
  /** Option bonus proration for season of no more than five years */
  optionBonusProrated?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<ContractSeasonFilterInput>>;
  /** P5 or salary for that season */
  p5?: InputMaybe<DecimalOperationFilterInput>;
  /** Most Reporting Bonus are not guaranteed */
  reportingBonus?: InputMaybe<DecimalOperationFilterInput>;
  /** Contract roster bonuses */
  rosterBonuses?: InputMaybe<RosterBonusFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Season of the contract */
  season?: InputMaybe<IntOperationFilterInput>;
  /** Signing bonus for the season */
  signingBonus?: InputMaybe<DecimalOperationFilterInput>;
  /** The signing bonus proration for the season of no more than five years */
  signingBonusProrated?: InputMaybe<DecimalOperationFilterInput>;
  source?: InputMaybe<SourceTypeEnumOperationFilterInput>;
  /** Bonus received during the off-season */
  workoutBonus?: InputMaybe<DecimalOperationFilterInput>;
};

export type ContractSeasonSection = {
  __typename?: "ContractSeasonSection";
  /** The cap hit for this section */
  capAmt: Scalars["Decimal"];
  /** Sub type of section type */
  clauseType?: Maybe<Scalars["String"]>;
  contract: Contract;
  /** Contract that section belongs */
  contractId: Scalars["UUID"];
  created: Scalars["DateTime"];
  /** Credited seasons to earn ? */
  creditedSeasons?: Maybe<Scalars["Int"]>;
  /** Total Guaranteed amount */
  guaranteeAmount: Scalars["Decimal"];
  /** Guarantee type */
  guaranteeType?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Is item deferred */
  isDefer: Scalars["Boolean"];
  /** Is item guaranteed money */
  isGuarantee: Scalars["Boolean"];
  /** Is item prorated */
  isProrate: Scalars["Boolean"];
  /** Is item a salary advance */
  isSalaryAdvance: Scalars["Boolean"];
  /** Rookie deals mostly. Splits are P5 pay for when the player is healthy vs injured */
  isSplit: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  leagueContractId: Scalars["Int"];
  /** Max amount a player can earn for said item */
  maxAmt?: Maybe<Scalars["Decimal"]>;
  /** Number of games the bonus was earned or will be */
  numGames?: Maybe<Scalars["Int"]>;
  /** P5 down is the pay player earns when on IR (or injured) */
  p5DownAmt: Scalars["Decimal"];
  /** P5 up is the pay player earns when healthy */
  p5UpAmt: Scalars["Decimal"];
  /** Per game amount player earns (within roster bonus) */
  perGameAmt?: Maybe<Scalars["Decimal"]>;
  /** Type of roster bonus */
  rosterBonusType?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** Season section applies to */
  season: Scalars["Int"];
  /** The contract's section actual text or language */
  sectionText: Scalars["String"];
  /** The contract's section title */
  sectionTitle: Scalars["String"];
  sectionType: SectionType;
  /** The section type */
  sectionTypeId: Scalars["UUID"];
  /** League unique key (gets reseeded though) */
  sectionYearRepId: Scalars["Int"];
  statusId: Scalars["String"];
  /** Total amount prorated */
  totalProrateAmt: Scalars["Decimal"];
  update: ContractSeasonSection;
};

export type ContractSeasonSectionUpdateArgs = {
  details: ContractSectionDetailsInput;
};

export type ContractSeasonSectionFilterInput = {
  and?: InputMaybe<Array<ContractSeasonSectionFilterInput>>;
  /** The cap hit for this section */
  capAmt?: InputMaybe<DecimalOperationFilterInput>;
  /** Sub type of section type */
  clauseType?: InputMaybe<StringOperationFilterInput>;
  contract?: InputMaybe<ContractFilterInput>;
  /** Contract that section belongs */
  contractId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Credited seasons to earn ? */
  creditedSeasons?: InputMaybe<IntOperationFilterInput>;
  /** Total Guaranteed amount */
  guaranteeAmount?: InputMaybe<DecimalOperationFilterInput>;
  /** Guarantee type */
  guaranteeType?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Is item deferred */
  isDefer?: InputMaybe<BooleanOperationFilterInput>;
  /** Is item guaranteed money */
  isGuarantee?: InputMaybe<BooleanOperationFilterInput>;
  /** Is item prorated */
  isProrate?: InputMaybe<BooleanOperationFilterInput>;
  /** Is item a salary advance */
  isSalaryAdvance?: InputMaybe<BooleanOperationFilterInput>;
  /** Rookie deals mostly. Splits are P5 pay for when the player is healthy vs injured */
  isSplit?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueContractId?: InputMaybe<IntOperationFilterInput>;
  /** Max amount a player can earn for said item */
  maxAmt?: InputMaybe<DecimalOperationFilterInput>;
  /** Number of games the bonus was earned or will be */
  numGames?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ContractSeasonSectionFilterInput>>;
  /** P5 down is the pay player earns when on IR (or injured) */
  p5DownAmt?: InputMaybe<DecimalOperationFilterInput>;
  /** P5 up is the pay player earns when healthy */
  p5UpAmt?: InputMaybe<DecimalOperationFilterInput>;
  /** Per game amount player earns (within roster bonus) */
  perGameAmt?: InputMaybe<DecimalOperationFilterInput>;
  /** Type of roster bonus */
  rosterBonusType?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Season section applies to */
  season?: InputMaybe<IntOperationFilterInput>;
  /** The contract's section actual text or language */
  sectionText?: InputMaybe<StringOperationFilterInput>;
  /** The contract's section title */
  sectionTitle?: InputMaybe<StringOperationFilterInput>;
  sectionType?: InputMaybe<SectionTypeFilterInput>;
  /** The section type */
  sectionTypeId?: InputMaybe<UuidOperationFilterInput>;
  /** League unique key (gets reseeded though) */
  sectionYearRepId?: InputMaybe<IntOperationFilterInput>;
  statusId?: InputMaybe<StringOperationFilterInput>;
  /** Total amount prorated */
  totalProrateAmt?: InputMaybe<DecimalOperationFilterInput>;
};

export type ContractSeasonSectionSortInput = {
  /** The cap hit for this section */
  capAmt?: InputMaybe<SortEnumType>;
  /** Sub type of section type */
  clauseType?: InputMaybe<SortEnumType>;
  contract?: InputMaybe<ContractSortInput>;
  /** Contract that section belongs */
  contractId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** Credited seasons to earn ? */
  creditedSeasons?: InputMaybe<SortEnumType>;
  /** Total Guaranteed amount */
  guaranteeAmount?: InputMaybe<SortEnumType>;
  /** Guarantee type */
  guaranteeType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Is item deferred */
  isDefer?: InputMaybe<SortEnumType>;
  /** Is item guaranteed money */
  isGuarantee?: InputMaybe<SortEnumType>;
  /** Is item prorated */
  isProrate?: InputMaybe<SortEnumType>;
  /** Is item a salary advance */
  isSalaryAdvance?: InputMaybe<SortEnumType>;
  /** Rookie deals mostly. Splits are P5 pay for when the player is healthy vs injured */
  isSplit?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueContractId?: InputMaybe<SortEnumType>;
  /** Max amount a player can earn for said item */
  maxAmt?: InputMaybe<SortEnumType>;
  /** Number of games the bonus was earned or will be */
  numGames?: InputMaybe<SortEnumType>;
  /** P5 down is the pay player earns when on IR (or injured) */
  p5DownAmt?: InputMaybe<SortEnumType>;
  /** P5 up is the pay player earns when healthy */
  p5UpAmt?: InputMaybe<SortEnumType>;
  /** Per game amount player earns (within roster bonus) */
  perGameAmt?: InputMaybe<SortEnumType>;
  /** Type of roster bonus */
  rosterBonusType?: InputMaybe<SortEnumType>;
  /** Season section applies to */
  season?: InputMaybe<SortEnumType>;
  /** The contract's section actual text or language */
  sectionText?: InputMaybe<SortEnumType>;
  /** The contract's section title */
  sectionTitle?: InputMaybe<SortEnumType>;
  sectionType?: InputMaybe<SectionTypeSortInput>;
  /** The section type */
  sectionTypeId?: InputMaybe<SortEnumType>;
  /** League unique key (gets reseeded though) */
  sectionYearRepId?: InputMaybe<SortEnumType>;
  statusId?: InputMaybe<SortEnumType>;
  /** Total amount prorated */
  totalProrateAmt?: InputMaybe<SortEnumType>;
};

export type ContractSeasonSortInput = {
  /** Values carried over from previous contract for reworks/extensions */
  carryOvers?: InputMaybe<CarryOverSortInput>;
  contract?: InputMaybe<ContractSortInput>;
  /** Foreign key to Contract. Contract the years belong. */
  contractId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /**
   * An escalator is a clause that allows a player to earn additional compensation based on
   * reaching certain performance-related milestones or achievements. These milestones are
   * typically specified in the contract and can include various statistical benchmarks,
   * playing time thresholds, or team performance goals.
   */
  escalators?: InputMaybe<SortEnumType>;
  /** Money from previous contract brought over to new. Renegotiation */
  existingProration?: InputMaybe<SortEnumType>;
  /** Contract guaranteed monies */
  guarantees?: InputMaybe<GuaranteeSortInput>;
  id?: InputMaybe<SortEnumType>;
  /** Contract incentives */
  incentives?: InputMaybe<SortEnumType>;
  /** If contract year is optional (rookie deals, 5th year) */
  isOptionYear?: InputMaybe<SortEnumType>;
  /** If contract year is voided */
  isVoidYear?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The league contract ID */
  leagueContractId?: InputMaybe<SortEnumType>;
  /** Option bonus for the season */
  optionBonus?: InputMaybe<SortEnumType>;
  /** Option bonus proration for season of no more than five years */
  optionBonusProrated?: InputMaybe<SortEnumType>;
  /** P5 or salary for that season */
  p5?: InputMaybe<SortEnumType>;
  /** Most Reporting Bonus are not guaranteed */
  reportingBonus?: InputMaybe<SortEnumType>;
  /** Contract roster bonuses */
  rosterBonuses?: InputMaybe<RosterBonusSortInput>;
  /** Season of the contract */
  season?: InputMaybe<SortEnumType>;
  /** Signing bonus for the season */
  signingBonus?: InputMaybe<SortEnumType>;
  /** The signing bonus proration for the season of no more than five years */
  signingBonusProrated?: InputMaybe<SortEnumType>;
  source?: InputMaybe<SortEnumType>;
  /** Bonus received during the off-season */
  workoutBonus?: InputMaybe<SortEnumType>;
};

export type ContractSectionDetailsInput = {
  capAmt: Scalars["Decimal"];
  clauseType?: InputMaybe<Scalars["String"]>;
  contractId: Scalars["UUID"];
  creditedSeasons?: InputMaybe<Scalars["Int"]>;
  guaranteeAmount: Scalars["Decimal"];
  guaranteeType?: InputMaybe<Scalars["String"]>;
  isDefer: Scalars["Boolean"];
  isGuarantee: Scalars["Boolean"];
  isProrate: Scalars["Boolean"];
  isSalaryAdvance: Scalars["Boolean"];
  isSplit: Scalars["Boolean"];
  leagueContractId: Scalars["Int"];
  maxAmt?: InputMaybe<Scalars["Decimal"]>;
  numGames?: InputMaybe<Scalars["Int"]>;
  p5DownAmt: Scalars["Decimal"];
  p5UpAmt: Scalars["Decimal"];
  perGameAmt?: InputMaybe<Scalars["Decimal"]>;
  rosterBonusType?: InputMaybe<Scalars["String"]>;
  season: Scalars["Int"];
  sectionText?: InputMaybe<Scalars["String"]>;
  sectionTitle?: InputMaybe<Scalars["String"]>;
  sectionType: Scalars["String"];
  sectionTypeId: Scalars["UUID"];
  sectionyearrepID: Scalars["Int"];
  statusId: Scalars["String"];
  totalProrateAmt: Scalars["Decimal"];
};

export type ContractSortInput = {
  agent?: InputMaybe<AgentSortInput>;
  agentId?: InputMaybe<SortEnumType>;
  /** Club that submitted and is associated with the contract */
  clubId?: InputMaybe<SortEnumType>;
  /** Accrued seasons of the player at signing */
  contractAccSeason?: InputMaybe<SortEnumType>;
  /** Date contract was entered into the league's system */
  contractEnteredSystem?: InputMaybe<SortEnumType>;
  /** Age of the player at signing */
  contractSignAge?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** Last year of contract that was effective */
  effectiveLastContractYear?: InputMaybe<SortEnumType>;
  /** First year of contract */
  firstContractYear?: InputMaybe<SortEnumType>;
  hasVoidYears?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Current contract that the player is under */
  isEffectiveContract?: InputMaybe<SortEnumType>;
  /** Contract that applies the MSB rule */
  isMinSalaryBenefitContract?: InputMaybe<SortEnumType>;
  /** Most recent */
  isMostRecentContract?: InputMaybe<SortEnumType>;
  /** Last year of contract */
  lastContractYear?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The league's contract ID */
  leagueContractId?: InputMaybe<SortEnumType>;
  /** Player that is on given contract */
  playerId?: InputMaybe<SortEnumType>;
  /** Previous league contract Id used for extension calculations */
  previousContractId?: InputMaybe<SortEnumType>;
  /** Sign date of the contract */
  signDate?: InputMaybe<SortEnumType>;
  /** Signing type */
  signingType?: InputMaybe<SortEnumType>;
  source?: InputMaybe<SortEnumType>;
  sumer?: InputMaybe<SumerValuesSortInput>;
  total?: InputMaybe<ContractTotalsSortInput>;
  /** Transaction associated with the contract (if there is one) */
  transactionId?: InputMaybe<SortEnumType>;
};

export type ContractSummaryDetailsInput = {
  comments?: InputMaybe<Scalars["String"]>;
  evaluated: Scalars["Boolean"];
  existingAmount?: InputMaybe<Scalars["Decimal"]>;
  existingAmountGuaranteed?: InputMaybe<Scalars["Decimal"]>;
  existingYears?: InputMaybe<Scalars["Int"]>;
  fullGuaranteed?: InputMaybe<Scalars["Decimal"]>;
  functionalAmount?: InputMaybe<Scalars["Decimal"]>;
  functionalYears?: InputMaybe<Scalars["Int"]>;
  isExistingAmountGuaranteed?: InputMaybe<Scalars["Boolean"]>;
  isRework: Scalars["Boolean"];
  newGuaranteed?: InputMaybe<Scalars["Decimal"]>;
  practicalGuaranteed?: InputMaybe<Scalars["Decimal"]>;
};

export type ContractTotalDetailsInput = {
  extensionPackage: Scalars["Decimal"];
  extensionThreeYear: Scalars["Decimal"];
  extensionYears: Scalars["Int"];
  guaranteed: Scalars["Decimal"];
  package: Scalars["Decimal"];
  threeYear: Scalars["Decimal"];
};

export type ContractTotals = {
  __typename?: "ContractTotals";
  /** Average per year */
  apy: Scalars["Decimal"];
  /** Average per year of extension package */
  extensionApy: Scalars["Decimal"];
  /**
   * An extension package refers to a negotiated agreement between a player and a team to prolong the
   * player's contract beyond its original term. It typically includes additional years, increased salary,
   * signing bonuses, and other incentives that provide both parties with mutual benefits and security for
   * the extended period.
   */
  extensionPackage: Scalars["Decimal"];
  /** Three year value of extension package */
  extensionThreeYear: Scalars["Decimal"];
  /** Extension Years */
  extensionYears: Scalars["Int"];
  /** Total Guaranteed */
  guaranteed: Scalars["Decimal"];
  /** Incentives earned */
  incentives: Scalars["Decimal"];
  /** Off-season bonus */
  offSeason: Scalars["Decimal"];
  /** Total option bonus earned */
  option: Scalars["Decimal"];
  /**
   * P5 stands for "Paragraph 5," which refers to a specific section of the standard player contract.
   * This section outlines the player's base salary, including any guaranteed money, incentives, and bonuses,
   * which are used to calculate the player's salary cap hit for a given season.
   */
  p5: Scalars["Decimal"];
  /** Contract cap hit (cap amount) */
  packageAmount: Scalars["Decimal"];
  /**
   * A reporting bonus is a financial incentive provided to a player for reporting to the team's
   * training camp or participating in mandatory offseason activities. It serves as an encouragement for
   * players to fulfill their contractual obligations by attending team activities promptly and actively
   * engaging in the preparation for the upcoming season.
   */
  reportingBonus: Scalars["Decimal"];
  /**
   * A roster bonus is a predetermined sum of money paid to a player if they are on the team's active roster
   * on a specified date, typically during the offseason or at the start of the regular season. It serves as
   * an incentive for the team to retain the player and ensures the player receives additional compensation for
   * remaining with the team up to that specific point in time.
   */
  rosterBonus: Scalars["Decimal"];
  /**
   * A signing bonus is a one-time payment given to a player when they sign a new contract with a team.
   * It is typically guaranteed and paid upfront, providing financial security to the player and serving
   * as a form of incentive to attract top talent to the team.
   */
  signingBonus: Scalars["Decimal"];
  /** Three year compensation amount */
  threeYearComp: Scalars["Decimal"];
  /** Total contract years */
  years: Scalars["Int"];
};

export type ContractTotalsFilterInput = {
  and?: InputMaybe<Array<ContractTotalsFilterInput>>;
  /** Average per year */
  apy?: InputMaybe<DecimalOperationFilterInput>;
  /** Average per year of extension package */
  extensionApy?: InputMaybe<DecimalOperationFilterInput>;
  /**
   * An extension package refers to a negotiated agreement between a player and a team to prolong the
   * player's contract beyond its original term. It typically includes additional years, increased salary,
   * signing bonuses, and other incentives that provide both parties with mutual benefits and security for
   * the extended period.
   */
  extensionPackage?: InputMaybe<DecimalOperationFilterInput>;
  /** Three year value of extension package */
  extensionThreeYear?: InputMaybe<DecimalOperationFilterInput>;
  /** Extension Years */
  extensionYears?: InputMaybe<IntOperationFilterInput>;
  /** Total Guaranteed */
  guaranteed?: InputMaybe<DecimalOperationFilterInput>;
  /** Incentives earned */
  incentives?: InputMaybe<DecimalOperationFilterInput>;
  /** Off-season bonus */
  offSeason?: InputMaybe<DecimalOperationFilterInput>;
  /** Total option bonus earned */
  option?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<ContractTotalsFilterInput>>;
  /**
   * P5 stands for "Paragraph 5," which refers to a specific section of the standard player contract.
   * This section outlines the player's base salary, including any guaranteed money, incentives, and bonuses,
   * which are used to calculate the player's salary cap hit for a given season.
   */
  p5?: InputMaybe<DecimalOperationFilterInput>;
  /** Contract cap hit (cap amount) */
  packageAmount?: InputMaybe<DecimalOperationFilterInput>;
  /**
   * A reporting bonus is a financial incentive provided to a player for reporting to the team's
   * training camp or participating in mandatory offseason activities. It serves as an encouragement for
   * players to fulfill their contractual obligations by attending team activities promptly and actively
   * engaging in the preparation for the upcoming season.
   */
  reportingBonus?: InputMaybe<DecimalOperationFilterInput>;
  /**
   * A roster bonus is a predetermined sum of money paid to a player if they are on the team's active roster
   * on a specified date, typically during the offseason or at the start of the regular season. It serves as
   * an incentive for the team to retain the player and ensures the player receives additional compensation for
   * remaining with the team up to that specific point in time.
   */
  rosterBonus?: InputMaybe<DecimalOperationFilterInput>;
  /**
   * A signing bonus is a one-time payment given to a player when they sign a new contract with a team.
   * It is typically guaranteed and paid upfront, providing financial security to the player and serving
   * as a form of incentive to attract top talent to the team.
   */
  signingBonus?: InputMaybe<DecimalOperationFilterInput>;
  /** Three year compensation amount */
  threeYearComp?: InputMaybe<DecimalOperationFilterInput>;
  /** Total contract years */
  years?: InputMaybe<IntOperationFilterInput>;
};

export type ContractTotalsSortInput = {
  /** Average per year */
  apy?: InputMaybe<SortEnumType>;
  /** Average per year of extension package */
  extensionApy?: InputMaybe<SortEnumType>;
  /**
   * An extension package refers to a negotiated agreement between a player and a team to prolong the
   * player's contract beyond its original term. It typically includes additional years, increased salary,
   * signing bonuses, and other incentives that provide both parties with mutual benefits and security for
   * the extended period.
   */
  extensionPackage?: InputMaybe<SortEnumType>;
  /** Three year value of extension package */
  extensionThreeYear?: InputMaybe<SortEnumType>;
  /** Extension Years */
  extensionYears?: InputMaybe<SortEnumType>;
  /** Total Guaranteed */
  guaranteed?: InputMaybe<SortEnumType>;
  /** Incentives earned */
  incentives?: InputMaybe<SortEnumType>;
  /** Off-season bonus */
  offSeason?: InputMaybe<SortEnumType>;
  /** Total option bonus earned */
  option?: InputMaybe<SortEnumType>;
  /**
   * P5 stands for "Paragraph 5," which refers to a specific section of the standard player contract.
   * This section outlines the player's base salary, including any guaranteed money, incentives, and bonuses,
   * which are used to calculate the player's salary cap hit for a given season.
   */
  p5?: InputMaybe<SortEnumType>;
  /** Contract cap hit (cap amount) */
  packageAmount?: InputMaybe<SortEnumType>;
  /**
   * A reporting bonus is a financial incentive provided to a player for reporting to the team's
   * training camp or participating in mandatory offseason activities. It serves as an encouragement for
   * players to fulfill their contractual obligations by attending team activities promptly and actively
   * engaging in the preparation for the upcoming season.
   */
  reportingBonus?: InputMaybe<SortEnumType>;
  /**
   * A roster bonus is a predetermined sum of money paid to a player if they are on the team's active roster
   * on a specified date, typically during the offseason or at the start of the regular season. It serves as
   * an incentive for the team to retain the player and ensures the player receives additional compensation for
   * remaining with the team up to that specific point in time.
   */
  rosterBonus?: InputMaybe<SortEnumType>;
  /**
   * A signing bonus is a one-time payment given to a player when they sign a new contract with a team.
   * It is typically guaranteed and paid upfront, providing financial security to the player and serving
   * as a form of incentive to attract top talent to the team.
   */
  signingBonus?: InputMaybe<SortEnumType>;
  /** Three year compensation amount */
  threeYearComp?: InputMaybe<SortEnumType>;
  /** Total contract years */
  years?: InputMaybe<SortEnumType>;
};

export enum ConversionTypeEnum {
  CONV = "CONV",
  FULL = "FULL",
  INJ = "INJ",
}

export type CreateEvaluationRequest = {
  details: EvaluationDetailsInput;
};

export type CreateGameEvaluationRequest = {
  details: EvaluationGameDetailsInput;
};

export type CreatePermissionRequest = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name: Scalars["String"];
};

export type CreatePlayerInjuryColWithDetailsRequest = {
  /** Details about the injury */
  details: PlayerInjuryCollegeDetailsInput;
  /** Marval's internal ID for the injury */
  id?: InputMaybe<Scalars["UUID"]>;
};

export type CreatePlayerInjuryProWithDetailsRequest = {
  /** Details about the injury */
  details: PlayerInjuryProDetailsInput;
  /** Marval's internal ID for the injury */
  id?: InputMaybe<Scalars["UUID"]>;
};

export type CreatePlayerRequest = {
  /** Details about the player */
  details: PlayerDetailsInput;
  /** Marval's internal ID for the player */
  id?: InputMaybe<Scalars["UUID"]>;
};

export type CreatePlayerTagRequest = {
  playerId: Scalars["UUID"];
  sort?: InputMaybe<Scalars["Int"]>;
  tagId: Scalars["UUID"];
};

export type CreatePositionAtlasNodeRequest = {
  nodeId: Scalars["String"];
  parentNodeId: Scalars["String"];
};

export type CreateRoleRequest = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  name: Scalars["String"];
};

export type CreateScoutingAssignmentRequest = {
  details: ScoutingAssignmentDetailsInput;
};

export type CreateTagRequest = {
  description: Scalars["String"];
  name: Scalars["String"];
};

export type CreateUserRequest = {
  authenticatedId?: InputMaybe<Scalars["UUID"]>;
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  id?: InputMaybe<Scalars["UUID"]>;
  lastName: Scalars["String"];
  onboardingLink?: InputMaybe<Scalars["String"]>;
  roleId?: InputMaybe<Scalars["UUID"]>;
};

export type CreateWorksheetItemRequest = {
  details: WorksheetItemDetailsInput;
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars["Date"]>;
  gt?: InputMaybe<Scalars["Date"]>;
  gte?: InputMaybe<Scalars["Date"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  lt?: InputMaybe<Scalars["Date"]>;
  lte?: InputMaybe<Scalars["Date"]>;
  neq?: InputMaybe<Scalars["Date"]>;
  ngt?: InputMaybe<Scalars["Date"]>;
  ngte?: InputMaybe<Scalars["Date"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Date"]>>>;
  nlt?: InputMaybe<Scalars["Date"]>;
  nlte?: InputMaybe<Scalars["Date"]>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  neq?: InputMaybe<Scalars["DateTime"]>;
  ngt?: InputMaybe<Scalars["DateTime"]>;
  ngte?: InputMaybe<Scalars["DateTime"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  nlt?: InputMaybe<Scalars["DateTime"]>;
  nlte?: InputMaybe<Scalars["DateTime"]>;
};

export type DeactivateUserRequest = {
  userId: Scalars["UUID"];
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars["Decimal"]>;
  gt?: InputMaybe<Scalars["Decimal"]>;
  gte?: InputMaybe<Scalars["Decimal"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Decimal"]>>>;
  lt?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
  neq?: InputMaybe<Scalars["Decimal"]>;
  ngt?: InputMaybe<Scalars["Decimal"]>;
  ngte?: InputMaybe<Scalars["Decimal"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Decimal"]>>>;
  nlt?: InputMaybe<Scalars["Decimal"]>;
  nlte?: InputMaybe<Scalars["Decimal"]>;
};

export type DeletePermissionRequest = {
  permissionId: Scalars["UUID"];
};

export type DeletePlayerTagRequest = {
  id: Scalars["UUID"];
  playerId: Scalars["UUID"];
};

export type DeleteRoleRequest = {
  roleId: Scalars["UUID"];
};

export type DeleteScoutingAssignmentRequest = {
  id: Scalars["UUID"];
};

export type DeleteWorksheetItemRequest = {
  id: Scalars["UUID"];
};

export type DraftContractTier = {
  __typename?: "DraftContractTier";
  averageGuarantee: Scalars["Int"];
  description: Scalars["String"];
  maxPick: Scalars["Int"];
  minPick: Scalars["Int"];
};

export type DraftContractTierFilterInput = {
  and?: InputMaybe<Array<DraftContractTierFilterInput>>;
  averageGuarantee?: InputMaybe<IntOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  maxPick?: InputMaybe<IntOperationFilterInput>;
  minPick?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DraftContractTierFilterInput>>;
};

export type DraftContractTierSortInput = {
  averageGuarantee?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  maxPick?: InputMaybe<SortEnumType>;
  minPick?: InputMaybe<SortEnumType>;
};

export type DraftPick = {
  __typename?: "DraftPick";
  /** The number of the pick in the round.  Null in future seasons. */
  number?: Maybe<Scalars["Int"]>;
  /** The pick number overall.  Null in future seasons. */
  overall?: Maybe<Scalars["Int"]>;
  /** The round the pick is in. */
  round?: Maybe<Scalars["Int"]>;
  /** The season the pick belongs to. */
  season: Scalars["Int"];
};

export type DraftPickFilterInput = {
  and?: InputMaybe<Array<DraftPickFilterInput>>;
  /** The number of the pick in the round.  Null in future seasons. */
  number?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<DraftPickFilterInput>>;
  /** The pick number overall.  Null in future seasons. */
  overall?: InputMaybe<IntOperationFilterInput>;
  /** The round the pick is in. */
  round?: InputMaybe<IntOperationFilterInput>;
  /** The season the pick belongs to. */
  season?: InputMaybe<IntOperationFilterInput>;
};

export type DraftPickTransaction = {
  __typename?: "DraftPickTransaction";
  clubId: Scalars["UUID"];
  draftPicksGained: Array<DraftPick>;
  draftPicksLost: Array<DraftPick>;
};

export type DraftPickTransactionFilterInput = {
  and?: InputMaybe<Array<DraftPickTransactionFilterInput>>;
  clubId?: InputMaybe<UuidOperationFilterInput>;
  draftPicksGained?: InputMaybe<ListFilterInputTypeOfDraftPickFilterInput>;
  draftPicksLost?: InputMaybe<ListFilterInputTypeOfDraftPickFilterInput>;
  or?: InputMaybe<Array<DraftPickTransactionFilterInput>>;
};

export enum EligibilityType {
  FR = "FR",
  G = "G",
  JR = "JR",
  SO = "SO",
  SR = "SR",
}

/** Denotes an eval type of pro, college, etc. */
export enum EvalStatusEnum {
  FINAL = "FINAL",
  READY_FOR_EDIT = "READY_FOR_EDIT",
  WORK_IN_PROGRESS = "WORK_IN_PROGRESS",
}

export type EvalStatusEnumOperationFilterInput = {
  eq?: InputMaybe<EvalStatusEnum>;
  in?: InputMaybe<Array<EvalStatusEnum>>;
  neq?: InputMaybe<EvalStatusEnum>;
  nin?: InputMaybe<Array<EvalStatusEnum>>;
};

/** Specifies the type of evaluation in which grading will take place against. */
export type EvalType = {
  __typename?: "EvalType";
  created: Scalars["DateTime"];
  /** Details about what type of evaluation - a scout or coach */
  description: Scalars["String"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Details about what type of evaluation - college or pro */
  type: EvalTypeEnum;
};

/** Denotes an eval type of pro, college, etc. */
export enum EvalTypeEnum {
  COLLEGE_CROSS_CHECK = "COLLEGE_CROSS_CHECK",
  COLLEGE_PORTAL = "COLLEGE_PORTAL",
  COLLEGE_SCOUT = "COLLEGE_SCOUT",
  PRO_CROSS_CHECK = "PRO_CROSS_CHECK",
  PRO_SCOUT = "PRO_SCOUT",
}

export type EvalTypeEnumOperationFilterInput = {
  eq?: InputMaybe<EvalTypeEnum>;
  in?: InputMaybe<Array<EvalTypeEnum>>;
  neq?: InputMaybe<EvalTypeEnum>;
  nin?: InputMaybe<Array<EvalTypeEnum>>;
};

/** Specifies the type of evaluation in which grading will take place against. */
export type EvalTypeFilterInput = {
  and?: InputMaybe<Array<EvalTypeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Details about what type of evaluation - a scout or coach */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvalTypeFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Details about what type of evaluation - college or pro */
  type?: InputMaybe<EvalTypeEnumOperationFilterInput>;
};

/** Specifies the type of evaluation in which grading will take place against. */
export type EvalTypeSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Details about what type of evaluation - a scout or coach */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Details about what type of evaluation - college or pro */
  type?: InputMaybe<SortEnumType>;
};

/**
 * This represents an evaluation of a given player.  An evaluation is an aggregation of grades across
 * different configured metrics.
 */
export type Evaluation = {
  __typename?: "Evaluation";
  /** Player alerts associated with evaluation */
  alerts: Array<EvaluationAlert>;
  /** One-liner for the player. More concise than the summary */
  bottomLine?: Maybe<Scalars["String"]>;
  /** Character summary of the player */
  characterSummary?: Maybe<Scalars["String"]>;
  /** Player team fits associated with evaluation */
  clubFits: Array<EvaluationClubFit>;
  created: Scalars["DateTime"];
  /** The evaluation type that determines what criteria is graded within this evaluation */
  evalType: EvalType;
  /** Foreign key to EvalType */
  evalTypeId: Scalars["UUID"];
  finalizedOn?: Maybe<Scalars["DateTime"]>;
  /** Player fits associated with evaluation */
  fits: Array<EvaluationFit>;
  /** Games graded in for evaluation */
  gameGrades: Array<EvaluationGame>;
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  /** Flag determining if the evaluation is marked as final. If true, it will no longer be editable. */
  isFinal: Scalars["Boolean"];
  /** Flag to track primary evaluation */
  isPrimary: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** The player being evaluated */
  player: Player;
  /** Foreign key to Player */
  playerId: Scalars["UUID"];
  /** The position being evaluated */
  position: Position;
  /** Foreign key to Position */
  positionId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  scoutGrade?: Maybe<ScoutGrade>;
  /** The overall grade for this evaluation */
  scoutGradeId?: Maybe<Scalars["UUID"]>;
  /** The season of which the player is being evaluated in */
  season: Scalars["Int"];
  status: EvalStatusEnum;
  /** Evaluation Summary */
  summary?: Maybe<Scalars["String"]>;
  /** Evaluation trait grades */
  traitGrades: Array<EvaluationTrait>;
  /** Evaluation trait group grades */
  traitGroupGrades: Array<EvaluationTraitGroup>;
  user: User;
  /** Internal Marval ID of the user that created the evaluation */
  userId: Scalars["UUID"];
};

export type EvaluationAlert = {
  __typename?: "EvaluationAlert";
  alert: Alert;
  alertId: Scalars["UUID"];
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  evaluationId: Scalars["UUID"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
};

export type EvaluationAlertFilterInput = {
  alert?: InputMaybe<AlertFilterInput>;
  alertId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<EvaluationAlertFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationAlertFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

export type EvaluationClubFit = {
  __typename?: "EvaluationClubFit";
  club: Club;
  clubId: Scalars["UUID"];
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  /** Evaluation game grade belongs */
  evaluationId: Scalars["UUID"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
};

export type EvaluationClubFitFilterInput = {
  and?: InputMaybe<Array<EvaluationClubFitFilterInput>>;
  club?: InputMaybe<ClubFilterInput>;
  clubId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  /** Evaluation game grade belongs */
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationClubFitFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

export type EvaluationDetailsInput = {
  bottomLine?: InputMaybe<Scalars["String"]>;
  characterSummary?: InputMaybe<Scalars["String"]>;
  evaluationTypeId?: InputMaybe<Scalars["UUID"]>;
  isFinal?: InputMaybe<Scalars["Boolean"]>;
  playerId?: InputMaybe<Scalars["UUID"]>;
  /** Position being graded */
  positionId?: InputMaybe<Scalars["UUID"]>;
  /** Final Grade of evaluation */
  scoutGradeId?: InputMaybe<Scalars["UUID"]>;
  /** The season of the evaluation.  If null, the current season will be used. */
  season?: InputMaybe<Scalars["Int"]>;
  summary?: InputMaybe<Scalars["String"]>;
};

/**
 * This represents an evaluation of a given player.  An evaluation is an aggregation of grades across
 * different configured metrics.
 */
export type EvaluationFilterInput = {
  /** Player alerts associated with evaluation */
  alerts?: InputMaybe<ListFilterInputTypeOfEvaluationAlertFilterInput>;
  and?: InputMaybe<Array<EvaluationFilterInput>>;
  /** One-liner for the player. More concise than the summary */
  bottomLine?: InputMaybe<StringOperationFilterInput>;
  /** Character summary of the player */
  characterSummary?: InputMaybe<StringOperationFilterInput>;
  /** Player team fits associated with evaluation */
  clubFits?: InputMaybe<ListFilterInputTypeOfEvaluationClubFitFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The evaluation type that determines what criteria is graded within this evaluation */
  evalType?: InputMaybe<EvalTypeFilterInput>;
  /** Foreign key to EvalType */
  evalTypeId?: InputMaybe<UuidOperationFilterInput>;
  finalizedOn?: InputMaybe<DateTimeOperationFilterInput>;
  /** Player fits associated with evaluation */
  fits?: InputMaybe<ListFilterInputTypeOfEvaluationFitFilterInput>;
  /** Games graded in for evaluation */
  gameGrades?: InputMaybe<ListFilterInputTypeOfEvaluationGameFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  /** Flag determining if the evaluation is marked as final. If true, it will no longer be editable. */
  isFinal?: InputMaybe<BooleanOperationFilterInput>;
  /** Flag to track primary evaluation */
  isPrimary?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationFilterInput>>;
  /** The player being evaluated */
  player?: InputMaybe<PlayerFilterInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** The position being evaluated */
  position?: InputMaybe<PositionFilterInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  scoutGrade?: InputMaybe<ScoutGradeFilterInput>;
  /** The overall grade for this evaluation */
  scoutGradeId?: InputMaybe<UuidOperationFilterInput>;
  /** The season of which the player is being evaluated in */
  season?: InputMaybe<IntOperationFilterInput>;
  status?: InputMaybe<EvalStatusEnumOperationFilterInput>;
  /** Evaluation Summary */
  summary?: InputMaybe<StringOperationFilterInput>;
  /** Evaluation trait grades */
  traitGrades?: InputMaybe<ListFilterInputTypeOfEvaluationTraitFilterInput>;
  /** Evaluation trait group grades */
  traitGroupGrades?: InputMaybe<ListFilterInputTypeOfEvaluationTraitGroupFilterInput>;
  /** Internal Marval ID of the user that created the evaluation */
  userId?: InputMaybe<UuidOperationFilterInput>;
};

/** Evaluation player fits (if more than one) */
export type EvaluationFit = {
  __typename?: "EvaluationFit";
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  evaluationId: Scalars["UUID"];
  fit: Fit;
  fitId: Scalars["UUID"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** The priority order of the fit where 0 is the highest priority, 1 is the next highest, etc. */
  priority: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
};

/** Evaluation player fits (if more than one) */
export type EvaluationFitFilterInput = {
  and?: InputMaybe<Array<EvaluationFitFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  fit?: InputMaybe<FitFilterInput>;
  fitId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationFitFilterInput>>;
  /** The priority order of the fit where 0 is the highest priority, 1 is the next highest, etc. */
  priority?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

/** Table to store game grades */
export type EvaluationGame = {
  __typename?: "EvaluationGame";
  /** Comments about game performance */
  comments?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  /** Evaluation game grade belongs */
  evaluationId: Scalars["UUID"];
  game: Game;
  /** Game associated to evaluation */
  gameId: Scalars["UUID"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  scoutGrade?: Maybe<ScoutGrade>;
  /** Grade given to game */
  scoutGradeId?: Maybe<Scalars["UUID"]>;
  update: EvaluationGame;
};

/** Table to store game grades */
export type EvaluationGameUpdateArgs = {
  details: EvaluationGameDetailsInput;
};

export type EvaluationGameDetailsInput = {
  comments?: InputMaybe<Scalars["String"]>;
  evaluationId: Scalars["UUID"];
  gameId: Scalars["UUID"];
  scoutGradeId?: InputMaybe<Scalars["UUID"]>;
};

/** Table to store game grades */
export type EvaluationGameFilterInput = {
  and?: InputMaybe<Array<EvaluationGameFilterInput>>;
  /** Comments about game performance */
  comments?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  /** Evaluation game grade belongs */
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  /** Game associated to evaluation */
  gameId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationGameFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  scoutGrade?: InputMaybe<ScoutGradeFilterInput>;
  /** Grade given to game */
  scoutGradeId?: InputMaybe<UuidOperationFilterInput>;
};

/** Table to store game grades */
export type EvaluationGameSortInput = {
  /** Comments about game performance */
  comments?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  evaluation?: InputMaybe<EvaluationSortInput>;
  /** Evaluation game grade belongs */
  evaluationId?: InputMaybe<SortEnumType>;
  /** Game associated to evaluation */
  gameId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  scoutGrade?: InputMaybe<ScoutGradeSortInput>;
  /** Grade given to game */
  scoutGradeId?: InputMaybe<SortEnumType>;
};

/**
 * This represents an evaluation of a given player.  An evaluation is an aggregation of grades across
 * different configured metrics.
 */
export type EvaluationSortInput = {
  /** One-liner for the player. More concise than the summary */
  bottomLine?: InputMaybe<SortEnumType>;
  /** Character summary of the player */
  characterSummary?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** The evaluation type that determines what criteria is graded within this evaluation */
  evalType?: InputMaybe<EvalTypeSortInput>;
  /** Foreign key to EvalType */
  evalTypeId?: InputMaybe<SortEnumType>;
  finalizedOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  /** Flag determining if the evaluation is marked as final. If true, it will no longer be editable. */
  isFinal?: InputMaybe<SortEnumType>;
  /** Flag to track primary evaluation */
  isPrimary?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The player being evaluated */
  player?: InputMaybe<PlayerSortInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<SortEnumType>;
  /** The position being evaluated */
  position?: InputMaybe<PositionSortInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<SortEnumType>;
  scoutGrade?: InputMaybe<ScoutGradeSortInput>;
  /** The overall grade for this evaluation */
  scoutGradeId?: InputMaybe<SortEnumType>;
  /** The season of which the player is being evaluated in */
  season?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  /** Evaluation Summary */
  summary?: InputMaybe<SortEnumType>;
  /** Internal Marval ID of the user that created the evaluation */
  userId?: InputMaybe<SortEnumType>;
};

/** Encapsulates a player's grade (or score) for a given metric (or trait) as part of an evaluation instance. */
export type EvaluationTrait = {
  __typename?: "EvaluationTrait";
  /** Free-form comments about the grade */
  comments?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  /** Foreign key to Evaluation */
  evaluationId: Scalars["UUID"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  scoutGrade?: Maybe<ScoutGrade>;
  /** The grade of the player being evaluated for the given metric */
  scoutGradeId?: Maybe<Scalars["UUID"]>;
  /** The metric that was evaluated */
  traitDefinition: TraitDefinition;
  /** Foreign key to TraitDefinition */
  traitDefinitionId: Scalars["UUID"];
  update: EvaluationTrait;
};

/** Encapsulates a player's grade (or score) for a given metric (or trait) as part of an evaluation instance. */
export type EvaluationTraitUpdateArgs = {
  details: EvaluationTraitDetailsInput;
};

export type EvaluationTraitDetailsInput = {
  comment: Scalars["String"];
  evaluationId: Scalars["UUID"];
  scoutGradeId?: InputMaybe<Scalars["UUID"]>;
  traitDefinitionId: Scalars["UUID"];
};

/** Encapsulates a player's grade (or score) for a given metric (or trait) as part of an evaluation instance. */
export type EvaluationTraitFilterInput = {
  and?: InputMaybe<Array<EvaluationTraitFilterInput>>;
  /** Free-form comments about the grade */
  comments?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  /** Foreign key to Evaluation */
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationTraitFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  scoutGrade?: InputMaybe<ScoutGradeFilterInput>;
  /** The grade of the player being evaluated for the given metric */
  scoutGradeId?: InputMaybe<UuidOperationFilterInput>;
  /** The metric that was evaluated */
  traitDefinition?: InputMaybe<TraitDefinitionFilterInput>;
  /** Foreign key to TraitDefinition */
  traitDefinitionId?: InputMaybe<UuidOperationFilterInput>;
};

export type EvaluationTraitGroup = {
  __typename?: "EvaluationTraitGroup";
  /** Free-form comments about the grade */
  comments?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  evaluation: Evaluation;
  /** Foreign key to Evaluation */
  evaluationId: Scalars["UUID"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  scoutGrade?: Maybe<ScoutGrade>;
  /** The grade of the player being evaluated for the given metric */
  scoutGradeId?: Maybe<Scalars["UUID"]>;
  traitGroup: TraitGroup;
  traitGroupId: Scalars["UUID"];
  update: EvaluationTraitGroup;
};

export type EvaluationTraitGroupUpdateArgs = {
  request: SaveEvaluationTraitGroupRequest;
};

export type EvaluationTraitGroupFilterInput = {
  and?: InputMaybe<Array<EvaluationTraitGroupFilterInput>>;
  /** Free-form comments about the grade */
  comments?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  evaluation?: InputMaybe<EvaluationFilterInput>;
  /** Foreign key to Evaluation */
  evaluationId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<EvaluationTraitGroupFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  scoutGrade?: InputMaybe<ScoutGradeFilterInput>;
  /** The grade of the player being evaluated for the given metric */
  scoutGradeId?: InputMaybe<UuidOperationFilterInput>;
  traitGroup?: InputMaybe<TraitGroupFilterInput>;
  traitGroupId?: InputMaybe<UuidOperationFilterInput>;
};

export type Fit = {
  __typename?: "Fit";
  abbreviation: Scalars["String"];
  created: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  name: Scalars["String"];
  position: Scalars["String"];
  rowVersion: Array<Scalars["Byte"]>;
  sort?: Maybe<Scalars["Int"]>;
  update: Fit;
};

export type FitUpdateArgs = {
  details: FitDetailsInput;
};

export type FitDetailsInput = {
  abbreviation: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  position: Scalars["String"];
  sort?: InputMaybe<Scalars["Int"]>;
};

export type FitFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<FitFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FitFilterInput>>;
  position?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type FitSortInput = {
  abbreviation?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  sort?: InputMaybe<SortEnumType>;
};

export type FlagEvaluationForDeleteRequest = {
  /** The ID of the evaluation to flag for delete. */
  evaluationId: Scalars["UUID"];
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars["Float"]>;
  gt?: InputMaybe<Scalars["Float"]>;
  gte?: InputMaybe<Scalars["Float"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  lt?: InputMaybe<Scalars["Float"]>;
  lte?: InputMaybe<Scalars["Float"]>;
  neq?: InputMaybe<Scalars["Float"]>;
  ngt?: InputMaybe<Scalars["Float"]>;
  ngte?: InputMaybe<Scalars["Float"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  nlt?: InputMaybe<Scalars["Float"]>;
  nlte?: InputMaybe<Scalars["Float"]>;
};

export enum FreeAgencyStatus {
  CAP_CASUALTY = "CAP_CASUALTY",
  ERFA = "ERFA",
  POTENTIAL_VOID = "POTENTIAL_VOID",
  RFA = "RFA",
  UFA = "UFA",
}

export type Game = {
  __typename?: "Game";
  awayTeam?: Maybe<Scalars["String"]>;
  awayTeamId?: Maybe<Scalars["UUID"]>;
  awayTeamScore?: Maybe<Scalars["Int"]>;
  gameStats: Array<GameStat>;
  gsisGameId?: Maybe<Scalars["Int"]>;
  homeTeam?: Maybe<Scalars["String"]>;
  homeTeamId?: Maybe<Scalars["UUID"]>;
  homeTeamScore?: Maybe<Scalars["Int"]>;
  id: Scalars["UUID"];
  kickoffTime?: Maybe<Scalars["DateTime"]>;
  lastUpdated: Scalars["DateTime"];
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  playerPlays: Array<PlayerPlay>;
  plays: Array<Play>;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  seasonType: SeasonType;
  seasonTypeId: Scalars["Int"];
  venue?: Maybe<Scalars["String"]>;
  week?: Maybe<Scalars["Int"]>;
  weekType: WeekType;
  weekTypeId: Scalars["Int"];
};

export type GameFilterInput = {
  and?: InputMaybe<Array<GameFilterInput>>;
  awayTeam?: InputMaybe<StringOperationFilterInput>;
  awayTeamId?: InputMaybe<UuidOperationFilterInput>;
  awayTeamScore?: InputMaybe<IntOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  gsisGameId?: InputMaybe<IntOperationFilterInput>;
  homeTeam?: InputMaybe<StringOperationFilterInput>;
  homeTeamId?: InputMaybe<UuidOperationFilterInput>;
  homeTeamScore?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  kickoffTime?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<GameFilterInput>>;
  playerPlays?: InputMaybe<ListFilterInputTypeOfPlayerPlayFilterInput>;
  plays?: InputMaybe<ListFilterInputTypeOfPlayFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonType?: InputMaybe<SeasonTypeFilterInput>;
  seasonTypeId?: InputMaybe<IntOperationFilterInput>;
  venue?: InputMaybe<StringOperationFilterInput>;
  week?: InputMaybe<IntOperationFilterInput>;
  weekType?: InputMaybe<WeekTypeFilterInput>;
  weekTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type GameSortInput = {
  awayTeam?: InputMaybe<SortEnumType>;
  awayTeamId?: InputMaybe<SortEnumType>;
  awayTeamScore?: InputMaybe<SortEnumType>;
  gsisGameId?: InputMaybe<SortEnumType>;
  homeTeam?: InputMaybe<SortEnumType>;
  homeTeamId?: InputMaybe<SortEnumType>;
  homeTeamScore?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  kickoffTime?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueType?: InputMaybe<LeagueTypeSortInput>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  seasonType?: InputMaybe<SeasonTypeSortInput>;
  seasonTypeId?: InputMaybe<SortEnumType>;
  venue?: InputMaybe<SortEnumType>;
  week?: InputMaybe<SortEnumType>;
  weekType?: InputMaybe<WeekTypeSortInput>;
  weekTypeId?: InputMaybe<SortEnumType>;
};

export type GameStat = {
  __typename?: "GameStat";
  game: Game;
  gameId: Scalars["UUID"];
  lastUpdated: Scalars["DateTime"];
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  playerId: Scalars["UUID"];
  playerStat: PlayerStat;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  seasonType: SeasonType;
  seasonTypeId: Scalars["Int"];
  statType: StatType;
  statTypeId: Scalars["Int"];
  teamId?: Maybe<Scalars["UUID"]>;
  weekType: WeekType;
  weekTypeId: Scalars["Int"];
};

export type GameStatFilterInput = {
  and?: InputMaybe<Array<GameStatFilterInput>>;
  game?: InputMaybe<GameFilterInput>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<GameStatFilterInput>>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  playerStat?: InputMaybe<PlayerStatFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonType?: InputMaybe<SeasonTypeFilterInput>;
  seasonTypeId?: InputMaybe<IntOperationFilterInput>;
  statType?: InputMaybe<StatTypeFilterInput>;
  statTypeId?: InputMaybe<IntOperationFilterInput>;
  teamId?: InputMaybe<UuidOperationFilterInput>;
  weekType?: InputMaybe<WeekTypeFilterInput>;
  weekTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type GameStatSortInput = {
  game?: InputMaybe<GameSortInput>;
  gameId?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueType?: InputMaybe<LeagueTypeSortInput>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  playerId?: InputMaybe<SortEnumType>;
  playerStat?: InputMaybe<PlayerStatSortInput>;
  season?: InputMaybe<SortEnumType>;
  seasonType?: InputMaybe<SeasonTypeSortInput>;
  seasonTypeId?: InputMaybe<SortEnumType>;
  statType?: InputMaybe<StatTypeSortInput>;
  statTypeId?: InputMaybe<SortEnumType>;
  teamId?: InputMaybe<SortEnumType>;
  weekType?: InputMaybe<WeekTypeSortInput>;
  weekTypeId?: InputMaybe<SortEnumType>;
};

/** List of top college spring players */
export type GetCollegeSpringPlayersRequest = {
  count: Scalars["Int"];
  eligibilityYear: Scalars["Int"];
  includeAll: Scalars["Boolean"];
  position: Scalars["String"];
};

/** List of top college players by draft season */
export type GetCollegeTopPlayersRequest = {
  count: Scalars["Int"];
  eligibilityYear: Scalars["Int"];
};

/** List of players in the roster to be evaluated by the model */
export type GetCurrentRosterValueRequest = {
  /** ID of the relevant club */
  clubId: Scalars["UUID"];
  /** Current season to evaluate against. Used to get the current season salary cap */
  currentSeason: Scalars["Int"];
  /**
   * All draftable players to be considered by the RV model.
   * If none are specified, will use the draft player pool for the passed in CurrentSeason
   */
  draftablePlayerIds?: InputMaybe<Array<Scalars["UUID"]>>;
  /** Season data so RV can use PGM and WPG over past seasons */
  seasons: Array<Scalars["Int"]>;
};

/** List of UFA players */
export type GetProUfaPlayersRequest = {
  count: Scalars["Int"];
};

export type GetVideoForPlaysRequest = {
  playFilterRequest: PlayFilterRequestInput;
};

export type Grade = {
  __typename?: "Grade";
  created: Scalars["DateTime"];
  /** Salary cap dollars per point above replacement */
  dollars?: Maybe<Scalars["Decimal"]>;
  /** Grade type */
  gradeType: GradeTypeEnum;
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  player: Player;
  /** Marval Player Id */
  playerId: Scalars["UUID"];
  /** The position the player was evaluated */
  position?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** Season the grade belongs. College club grade this is draft season. */
  season: Scalars["Int"];
  update: Grade;
  /** Grade value (or points) */
  value: Scalars["Decimal"];
};

export type GradeUpdateArgs = {
  dollars?: InputMaybe<Scalars["Decimal"]>;
  gradeType: GradeTypeEnum;
  playerId: Scalars["UUID"];
  points: Scalars["Decimal"];
  position?: InputMaybe<Scalars["String"]>;
  season: Scalars["Int"];
};

export type GradeFilterInput = {
  and?: InputMaybe<Array<GradeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Salary cap dollars per point above replacement */
  dollars?: InputMaybe<DecimalOperationFilterInput>;
  /** Grade type */
  gradeType?: InputMaybe<GradeTypeEnumOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<GradeFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  /** Marval Player Id */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** The position the player was evaluated */
  position?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Season the grade belongs. College club grade this is draft season. */
  season?: InputMaybe<IntOperationFilterInput>;
  /** Grade value (or points) */
  value?: InputMaybe<DecimalOperationFilterInput>;
};

export enum GradeTypeEnum {
  COLLEGE_CLUB_GRADE = "COLLEGE_CLUB_GRADE",
  COLLEGE_TO_PRO_PROJECTION = "COLLEGE_TO_PRO_PROJECTION",
  ENSEMBLE_GRADE = "ENSEMBLE_GRADE",
  FOOTBALL_VALUE = "FOOTBALL_VALUE",
  PRO_CLUB_GRADE = "PRO_CLUB_GRADE",
}

export type GradeTypeEnumOperationFilterInput = {
  eq?: InputMaybe<GradeTypeEnum>;
  in?: InputMaybe<Array<GradeTypeEnum>>;
  neq?: InputMaybe<GradeTypeEnum>;
  nin?: InputMaybe<Array<GradeTypeEnum>>;
};

export enum GradeWeightEnum {
  CLUB = "CLUB",
  SUMER_ANALYTICS_GRADE_ENSEMBLE = "SUMER_ANALYTICS_GRADE_ENSEMBLE",
  SUMER_SCOUT = "SUMER_SCOUT",
  WEIGHTED = "WEIGHTED",
}

export type Guarantee = {
  __typename?: "Guarantee";
  incentives: Scalars["Decimal"];
  incentivesGuaranteedType: Scalars["String"];
  offseasonConversion: Scalars["Decimal"];
  optionBonus: Scalars["Decimal"];
  p5: Scalars["Decimal"];
  p5Conversion: Scalars["Decimal"];
  p5GuaranteedType: Scalars["String"];
  p5Injury: Scalars["Decimal"];
  practical: Scalars["Decimal"];
  reportingBonus: Scalars["Decimal"];
  reportingBonusGuaranteedType: Scalars["String"];
  reportingBonusProrated: Scalars["Decimal"];
  rosterBonus46Man: Scalars["Decimal"];
  rosterBonus46ManGuaranteedType: Scalars["String"];
  rosterBonus53Man: Scalars["Decimal"];
  rosterBonus53ManGuaranteedType: Scalars["String"];
  rosterBonus90Man: Scalars["Decimal"];
  rosterBonus90ManGuaranteedType: Scalars["String"];
  rosterBonus90ManProrated: Scalars["Decimal"];
  rosterBonusConversion: Scalars["Decimal"];
  rosterBonusInjury: Scalars["Decimal"];
  rosterBonusPerGame: Scalars["Decimal"];
  rosterBonusPerGameGuaranteedType: Scalars["String"];
  rosterBonusPerGameProrated: Scalars["Decimal"];
  workoutBonus: Scalars["Decimal"];
  workoutBonusGuaranteedType: Scalars["String"];
  workoutBonusProrated: Scalars["Decimal"];
};

export type GuaranteeFilterInput = {
  and?: InputMaybe<Array<GuaranteeFilterInput>>;
  incentives?: InputMaybe<DecimalOperationFilterInput>;
  incentivesGuaranteedType?: InputMaybe<StringOperationFilterInput>;
  offseasonConversion?: InputMaybe<DecimalOperationFilterInput>;
  optionBonus?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<GuaranteeFilterInput>>;
  p5?: InputMaybe<DecimalOperationFilterInput>;
  p5Conversion?: InputMaybe<DecimalOperationFilterInput>;
  p5GuaranteedType?: InputMaybe<StringOperationFilterInput>;
  p5Injury?: InputMaybe<DecimalOperationFilterInput>;
  practical?: InputMaybe<DecimalOperationFilterInput>;
  reportingBonus?: InputMaybe<DecimalOperationFilterInput>;
  reportingBonusGuaranteedType?: InputMaybe<StringOperationFilterInput>;
  reportingBonusProrated?: InputMaybe<DecimalOperationFilterInput>;
  rosterBonus46Man?: InputMaybe<DecimalOperationFilterInput>;
  rosterBonus46ManGuaranteedType?: InputMaybe<StringOperationFilterInput>;
  rosterBonus53Man?: InputMaybe<DecimalOperationFilterInput>;
  rosterBonus53ManGuaranteedType?: InputMaybe<StringOperationFilterInput>;
  rosterBonus90Man?: InputMaybe<DecimalOperationFilterInput>;
  rosterBonus90ManGuaranteedType?: InputMaybe<StringOperationFilterInput>;
  rosterBonus90ManProrated?: InputMaybe<DecimalOperationFilterInput>;
  rosterBonusConversion?: InputMaybe<DecimalOperationFilterInput>;
  rosterBonusInjury?: InputMaybe<DecimalOperationFilterInput>;
  rosterBonusPerGame?: InputMaybe<DecimalOperationFilterInput>;
  rosterBonusPerGameGuaranteedType?: InputMaybe<StringOperationFilterInput>;
  rosterBonusPerGameProrated?: InputMaybe<DecimalOperationFilterInput>;
  workoutBonus?: InputMaybe<DecimalOperationFilterInput>;
  workoutBonusGuaranteedType?: InputMaybe<StringOperationFilterInput>;
  workoutBonusProrated?: InputMaybe<DecimalOperationFilterInput>;
};

export type GuaranteeSortInput = {
  incentives?: InputMaybe<SortEnumType>;
  incentivesGuaranteedType?: InputMaybe<SortEnumType>;
  offseasonConversion?: InputMaybe<SortEnumType>;
  optionBonus?: InputMaybe<SortEnumType>;
  p5?: InputMaybe<SortEnumType>;
  p5Conversion?: InputMaybe<SortEnumType>;
  p5GuaranteedType?: InputMaybe<SortEnumType>;
  p5Injury?: InputMaybe<SortEnumType>;
  practical?: InputMaybe<SortEnumType>;
  reportingBonus?: InputMaybe<SortEnumType>;
  reportingBonusGuaranteedType?: InputMaybe<SortEnumType>;
  reportingBonusProrated?: InputMaybe<SortEnumType>;
  rosterBonus46Man?: InputMaybe<SortEnumType>;
  rosterBonus46ManGuaranteedType?: InputMaybe<SortEnumType>;
  rosterBonus53Man?: InputMaybe<SortEnumType>;
  rosterBonus53ManGuaranteedType?: InputMaybe<SortEnumType>;
  rosterBonus90Man?: InputMaybe<SortEnumType>;
  rosterBonus90ManGuaranteedType?: InputMaybe<SortEnumType>;
  rosterBonus90ManProrated?: InputMaybe<SortEnumType>;
  rosterBonusConversion?: InputMaybe<SortEnumType>;
  rosterBonusInjury?: InputMaybe<SortEnumType>;
  rosterBonusPerGame?: InputMaybe<SortEnumType>;
  rosterBonusPerGameGuaranteedType?: InputMaybe<SortEnumType>;
  rosterBonusPerGameProrated?: InputMaybe<SortEnumType>;
  workoutBonus?: InputMaybe<SortEnumType>;
  workoutBonusGuaranteedType?: InputMaybe<SortEnumType>;
  workoutBonusProrated?: InputMaybe<SortEnumType>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars["Int"]>;
  gt?: InputMaybe<Scalars["Int"]>;
  gte?: InputMaybe<Scalars["Int"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  lt?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
  neq?: InputMaybe<Scalars["Int"]>;
  ngt?: InputMaybe<Scalars["Int"]>;
  ngte?: InputMaybe<Scalars["Int"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  nlt?: InputMaybe<Scalars["Int"]>;
  nlte?: InputMaybe<Scalars["Int"]>;
};

export enum KeyPlay {
  NEGATIVE = "NEGATIVE",
  NEUTRAL = "NEUTRAL",
  POSITIVE = "POSITIVE",
}

export type KeyValuePairOfStringAndPositionAtlasNode = {
  __typename?: "KeyValuePairOfStringAndPositionAtlasNode";
  key: Scalars["String"];
  value: PositionAtlasNode;
};

export type LeagueCapSeasonInfo = {
  __typename?: "LeagueCapSeasonInfo";
  camSeason?: Maybe<Scalars["Int"]>;
  /** Carryover from prior year? */
  carryOver?: Maybe<Scalars["Decimal"]>;
  cashMechanismAdjustment?: Maybe<Scalars["Decimal"]>;
  /** How much a club is under the cap */
  clubAdjustment?: Maybe<Scalars["Decimal"]>;
  /** The NFL cap amount for the season */
  clubCap?: Maybe<Scalars["Decimal"]>;
  clubId: Scalars["UUID"];
  created: Scalars["DateTime"];
  /** Dates the snapshot is valid */
  fromDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Minimum NFL designation pay for a rookie */
  minRookieP5?: Maybe<Scalars["Decimal"]>;
  /** The club's allocation towards the NFL cap amount */
  nflCap?: Maybe<Scalars["Decimal"]>;
  offSeasonHold?: Maybe<Scalars["Decimal"]>;
  rowVersion: Array<Scalars["Byte"]>;
  season?: Maybe<Scalars["Int"]>;
  /** Where ToDate is null is the current state */
  toDate?: Maybe<Scalars["DateTime"]>;
};

export type LeagueCapSeasonInfoFilterInput = {
  and?: InputMaybe<Array<LeagueCapSeasonInfoFilterInput>>;
  camSeason?: InputMaybe<IntOperationFilterInput>;
  /** Carryover from prior year? */
  carryOver?: InputMaybe<DecimalOperationFilterInput>;
  cashMechanismAdjustment?: InputMaybe<DecimalOperationFilterInput>;
  /** How much a club is under the cap */
  clubAdjustment?: InputMaybe<DecimalOperationFilterInput>;
  /** The NFL cap amount for the season */
  clubCap?: InputMaybe<DecimalOperationFilterInput>;
  clubId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Dates the snapshot is valid */
  fromDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** Minimum NFL designation pay for a rookie */
  minRookieP5?: InputMaybe<DecimalOperationFilterInput>;
  /** The club's allocation towards the NFL cap amount */
  nflCap?: InputMaybe<DecimalOperationFilterInput>;
  offSeasonHold?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<LeagueCapSeasonInfoFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  /** Where ToDate is null is the current state */
  toDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type LeagueCapSeasonInfoSortInput = {
  camSeason?: InputMaybe<SortEnumType>;
  /** Carryover from prior year? */
  carryOver?: InputMaybe<SortEnumType>;
  cashMechanismAdjustment?: InputMaybe<SortEnumType>;
  /** How much a club is under the cap */
  clubAdjustment?: InputMaybe<SortEnumType>;
  /** The NFL cap amount for the season */
  clubCap?: InputMaybe<SortEnumType>;
  clubId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** Dates the snapshot is valid */
  fromDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Minimum NFL designation pay for a rookie */
  minRookieP5?: InputMaybe<SortEnumType>;
  /** The club's allocation towards the NFL cap amount */
  nflCap?: InputMaybe<SortEnumType>;
  offSeasonHold?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  /** Where ToDate is null is the current state */
  toDate?: InputMaybe<SortEnumType>;
};

export type LeagueSalaryCap = {
  __typename?: "LeagueSalaryCap";
  /** The NFL cap amount for the season */
  capAmount?: Maybe<Scalars["Decimal"]>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Minimum NFL designation pay for a rookie */
  minRookieP5?: Maybe<Scalars["Decimal"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** Cap Amount season */
  season: Scalars["Int"];
};

export type LeagueSalaryCapDetail = {
  __typename?: "LeagueSalaryCapDetail";
  capAmount?: Maybe<Scalars["Decimal"]>;
  cashAmount?: Maybe<Scalars["Decimal"]>;
  clubCode?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  leagueClubId?: Maybe<Scalars["Int"]>;
  leaguePlayerId?: Maybe<Scalars["Int"]>;
  loadDate?: Maybe<Scalars["DateTime"]>;
  playerId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  season?: Maybe<Scalars["Int"]>;
  sectionId: Scalars["Int"];
  sectionTitle?: Maybe<Scalars["String"]>;
  sectionType?: Maybe<Scalars["String"]>;
  signDate?: Maybe<Scalars["DateTime"]>;
};

export type LeagueSalaryCapDetailFilterInput = {
  and?: InputMaybe<Array<LeagueSalaryCapDetailFilterInput>>;
  capAmount?: InputMaybe<DecimalOperationFilterInput>;
  cashAmount?: InputMaybe<DecimalOperationFilterInput>;
  clubCode?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueClubId?: InputMaybe<IntOperationFilterInput>;
  leaguePlayerId?: InputMaybe<IntOperationFilterInput>;
  loadDate?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<LeagueSalaryCapDetailFilterInput>>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  sectionId?: InputMaybe<IntOperationFilterInput>;
  sectionTitle?: InputMaybe<StringOperationFilterInput>;
  sectionType?: InputMaybe<StringOperationFilterInput>;
  signDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type LeagueSalaryCapDetailSortInput = {
  capAmount?: InputMaybe<SortEnumType>;
  cashAmount?: InputMaybe<SortEnumType>;
  clubCode?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueClubId?: InputMaybe<SortEnumType>;
  leaguePlayerId?: InputMaybe<SortEnumType>;
  loadDate?: InputMaybe<SortEnumType>;
  playerId?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  sectionId?: InputMaybe<SortEnumType>;
  sectionTitle?: InputMaybe<SortEnumType>;
  sectionType?: InputMaybe<SortEnumType>;
  signDate?: InputMaybe<SortEnumType>;
};

export type LeagueSalaryCapFilterInput = {
  and?: InputMaybe<Array<LeagueSalaryCapFilterInput>>;
  /** The NFL cap amount for the season */
  capAmount?: InputMaybe<DecimalOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** Minimum NFL designation pay for a rookie */
  minRookieP5?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<LeagueSalaryCapFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Cap Amount season */
  season?: InputMaybe<IntOperationFilterInput>;
};

export type LeagueSalaryCapRollup = {
  __typename?: "LeagueSalaryCapRollup";
  capAmount?: Maybe<Scalars["Decimal"]>;
  created: Scalars["DateTime"];
  fullPara5?: Maybe<Scalars["Decimal"]>;
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  leagueClubId: Scalars["Int"];
  leaguePlayerId: Scalars["Int"];
  loadDate?: Maybe<Scalars["DateTime"]>;
  ltbe?: Maybe<Scalars["Decimal"]>;
  miscellaneous?: Maybe<Scalars["Decimal"]>;
  para5?: Maybe<Scalars["Decimal"]>;
  playerId: Scalars["UUID"];
  potAccel?: Maybe<Scalars["Decimal"]>;
  reAllocation?: Maybe<Scalars["Decimal"]>;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  signingBonus?: Maybe<Scalars["Decimal"]>;
  vetCapCredit?: Maybe<Scalars["Decimal"]>;
};

export type LeagueSalaryCapRollupFilterInput = {
  and?: InputMaybe<Array<LeagueSalaryCapRollupFilterInput>>;
  capAmount?: InputMaybe<DecimalOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  fullPara5?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueClubId?: InputMaybe<IntOperationFilterInput>;
  leaguePlayerId?: InputMaybe<IntOperationFilterInput>;
  loadDate?: InputMaybe<DateTimeOperationFilterInput>;
  ltbe?: InputMaybe<DecimalOperationFilterInput>;
  miscellaneous?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<LeagueSalaryCapRollupFilterInput>>;
  para5?: InputMaybe<DecimalOperationFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  potAccel?: InputMaybe<DecimalOperationFilterInput>;
  reAllocation?: InputMaybe<DecimalOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  signingBonus?: InputMaybe<DecimalOperationFilterInput>;
  vetCapCredit?: InputMaybe<DecimalOperationFilterInput>;
};

export type LeagueSalaryCapRollupSortInput = {
  capAmount?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  fullPara5?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueClubId?: InputMaybe<SortEnumType>;
  leaguePlayerId?: InputMaybe<SortEnumType>;
  loadDate?: InputMaybe<SortEnumType>;
  ltbe?: InputMaybe<SortEnumType>;
  miscellaneous?: InputMaybe<SortEnumType>;
  para5?: InputMaybe<SortEnumType>;
  playerId?: InputMaybe<SortEnumType>;
  potAccel?: InputMaybe<SortEnumType>;
  reAllocation?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  signingBonus?: InputMaybe<SortEnumType>;
  vetCapCredit?: InputMaybe<SortEnumType>;
};

export type LeagueSalaryCapSortInput = {
  /** The NFL cap amount for the season */
  capAmount?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Minimum NFL designation pay for a rookie */
  minRookieP5?: InputMaybe<SortEnumType>;
  /** Cap Amount season */
  season?: InputMaybe<SortEnumType>;
};

export type LeagueType = {
  __typename?: "LeagueType";
  careerStats: Array<CareerStat>;
  description?: Maybe<Scalars["String"]>;
  gameStats: Array<GameStat>;
  games: Array<Game>;
  id: Scalars["Int"];
  playerPlays: Array<PlayerPlay>;
  plays: Array<Play>;
  seasonStats: Array<SeasonStat>;
};

export type LeagueTypeFilterInput = {
  and?: InputMaybe<Array<LeagueTypeFilterInput>>;
  careerStats?: InputMaybe<ListFilterInputTypeOfCareerStatFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  games?: InputMaybe<ListFilterInputTypeOfGameFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<LeagueTypeFilterInput>>;
  playerPlays?: InputMaybe<ListFilterInputTypeOfPlayerPlayFilterInput>;
  plays?: InputMaybe<ListFilterInputTypeOfPlayFilterInput>;
  seasonStats?: InputMaybe<ListFilterInputTypeOfSeasonStatFilterInput>;
};

export type LeagueTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type ListByteOperationFilterInput = {
  all?: InputMaybe<ByteOperationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ByteOperationFilterInput>;
  some?: InputMaybe<ByteOperationFilterInput>;
};

export type ListFilterInputTypeOfAppRoleFilterInput = {
  all?: InputMaybe<AppRoleFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<AppRoleFilterInput>;
  some?: InputMaybe<AppRoleFilterInput>;
};

export type ListFilterInputTypeOfAssumptionFilterInput = {
  all?: InputMaybe<AssumptionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<AssumptionFilterInput>;
  some?: InputMaybe<AssumptionFilterInput>;
};

export type ListFilterInputTypeOfCareerStatFilterInput = {
  all?: InputMaybe<CareerStatFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<CareerStatFilterInput>;
  some?: InputMaybe<CareerStatFilterInput>;
};

export type ListFilterInputTypeOfContractSeasonFilterInput = {
  all?: InputMaybe<ContractSeasonFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ContractSeasonFilterInput>;
  some?: InputMaybe<ContractSeasonFilterInput>;
};

export type ListFilterInputTypeOfContractSeasonSectionFilterInput = {
  all?: InputMaybe<ContractSeasonSectionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<ContractSeasonSectionFilterInput>;
  some?: InputMaybe<ContractSeasonSectionFilterInput>;
};

export type ListFilterInputTypeOfDraftPickFilterInput = {
  all?: InputMaybe<DraftPickFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<DraftPickFilterInput>;
  some?: InputMaybe<DraftPickFilterInput>;
};

export type ListFilterInputTypeOfDraftPickTransactionFilterInput = {
  all?: InputMaybe<DraftPickTransactionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<DraftPickTransactionFilterInput>;
  some?: InputMaybe<DraftPickTransactionFilterInput>;
};

export type ListFilterInputTypeOfEvaluationAlertFilterInput = {
  all?: InputMaybe<EvaluationAlertFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationAlertFilterInput>;
  some?: InputMaybe<EvaluationAlertFilterInput>;
};

export type ListFilterInputTypeOfEvaluationClubFitFilterInput = {
  all?: InputMaybe<EvaluationClubFitFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationClubFitFilterInput>;
  some?: InputMaybe<EvaluationClubFitFilterInput>;
};

export type ListFilterInputTypeOfEvaluationFilterInput = {
  all?: InputMaybe<EvaluationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationFilterInput>;
  some?: InputMaybe<EvaluationFilterInput>;
};

export type ListFilterInputTypeOfEvaluationFitFilterInput = {
  all?: InputMaybe<EvaluationFitFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationFitFilterInput>;
  some?: InputMaybe<EvaluationFitFilterInput>;
};

export type ListFilterInputTypeOfEvaluationGameFilterInput = {
  all?: InputMaybe<EvaluationGameFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationGameFilterInput>;
  some?: InputMaybe<EvaluationGameFilterInput>;
};

export type ListFilterInputTypeOfEvaluationTraitFilterInput = {
  all?: InputMaybe<EvaluationTraitFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationTraitFilterInput>;
  some?: InputMaybe<EvaluationTraitFilterInput>;
};

export type ListFilterInputTypeOfEvaluationTraitGroupFilterInput = {
  all?: InputMaybe<EvaluationTraitGroupFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<EvaluationTraitGroupFilterInput>;
  some?: InputMaybe<EvaluationTraitGroupFilterInput>;
};

export type ListFilterInputTypeOfGameFilterInput = {
  all?: InputMaybe<GameFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<GameFilterInput>;
  some?: InputMaybe<GameFilterInput>;
};

export type ListFilterInputTypeOfGameStatFilterInput = {
  all?: InputMaybe<GameStatFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<GameStatFilterInput>;
  some?: InputMaybe<GameStatFilterInput>;
};

export type ListFilterInputTypeOfGradeFilterInput = {
  all?: InputMaybe<GradeFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<GradeFilterInput>;
  some?: InputMaybe<GradeFilterInput>;
};

export type ListFilterInputTypeOfNymFilterInput = {
  all?: InputMaybe<NymFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<NymFilterInput>;
  some?: InputMaybe<NymFilterInput>;
};

export type ListFilterInputTypeOfPermissionFilterInput = {
  all?: InputMaybe<PermissionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PermissionFilterInput>;
  some?: InputMaybe<PermissionFilterInput>;
};

export type ListFilterInputTypeOfPlayFilterInput = {
  all?: InputMaybe<PlayFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayFilterInput>;
  some?: InputMaybe<PlayFilterInput>;
};

export type ListFilterInputTypeOfPlayerGradeSeasonFilterInput = {
  all?: InputMaybe<PlayerGradeSeasonFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerGradeSeasonFilterInput>;
  some?: InputMaybe<PlayerGradeSeasonFilterInput>;
};

export type ListFilterInputTypeOfPlayerOverrideFilterInput = {
  all?: InputMaybe<PlayerOverrideFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerOverrideFilterInput>;
  some?: InputMaybe<PlayerOverrideFilterInput>;
};

export type ListFilterInputTypeOfPlayerPackagePositionFilterInput = {
  all?: InputMaybe<PlayerPackagePositionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerPackagePositionFilterInput>;
  some?: InputMaybe<PlayerPackagePositionFilterInput>;
};

export type ListFilterInputTypeOfPlayerPlayFilterInput = {
  all?: InputMaybe<PlayerPlayFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerPlayFilterInput>;
  some?: InputMaybe<PlayerPlayFilterInput>;
};

export type ListFilterInputTypeOfPlayerProjectedGamesMissedFilterInput = {
  all?: InputMaybe<PlayerProjectedGamesMissedFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerProjectedGamesMissedFilterInput>;
  some?: InputMaybe<PlayerProjectedGamesMissedFilterInput>;
};

export type ListFilterInputTypeOfPlayerTagFilterInput = {
  all?: InputMaybe<PlayerTagFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<PlayerTagFilterInput>;
  some?: InputMaybe<PlayerTagFilterInput>;
};

export type ListFilterInputTypeOfSearchResultOfClubSearchDocumentFilterInput = {
  all?: InputMaybe<SearchResultOfClubSearchDocumentFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<SearchResultOfClubSearchDocumentFilterInput>;
  some?: InputMaybe<SearchResultOfClubSearchDocumentFilterInput>;
};

export type ListFilterInputTypeOfSearchResultOfPlayerSearchDocumentFilterInput =
  {
    all?: InputMaybe<SearchResultOfPlayerSearchDocumentFilterInput>;
    any?: InputMaybe<Scalars["Boolean"]>;
    none?: InputMaybe<SearchResultOfPlayerSearchDocumentFilterInput>;
    some?: InputMaybe<SearchResultOfPlayerSearchDocumentFilterInput>;
  };

export type ListFilterInputTypeOfSearchResultOfSchoolSearchDocumentFilterInput =
  {
    all?: InputMaybe<SearchResultOfSchoolSearchDocumentFilterInput>;
    any?: InputMaybe<Scalars["Boolean"]>;
    none?: InputMaybe<SearchResultOfSchoolSearchDocumentFilterInput>;
    some?: InputMaybe<SearchResultOfSchoolSearchDocumentFilterInput>;
  };

export type ListFilterInputTypeOfSeasonStatFilterInput = {
  all?: InputMaybe<SeasonStatFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<SeasonStatFilterInput>;
  some?: InputMaybe<SeasonStatFilterInput>;
};

export type ListFilterInputTypeOfTraitGroupPositionFilterInput = {
  all?: InputMaybe<TraitGroupPositionFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<TraitGroupPositionFilterInput>;
  some?: InputMaybe<TraitGroupPositionFilterInput>;
};

export type ListFilterInputTypeOfWorkoutPlayerMeasureFilterInput = {
  all?: InputMaybe<WorkoutPlayerMeasureFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<WorkoutPlayerMeasureFilterInput>;
  some?: InputMaybe<WorkoutPlayerMeasureFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars["Boolean"]>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars["Long"]>;
  gt?: InputMaybe<Scalars["Long"]>;
  gte?: InputMaybe<Scalars["Long"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Long"]>>>;
  lt?: InputMaybe<Scalars["Long"]>;
  lte?: InputMaybe<Scalars["Long"]>;
  neq?: InputMaybe<Scalars["Long"]>;
  ngt?: InputMaybe<Scalars["Long"]>;
  ngte?: InputMaybe<Scalars["Long"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Long"]>>>;
  nlt?: InputMaybe<Scalars["Long"]>;
  nlte?: InputMaybe<Scalars["Long"]>;
};

export type MarkEvaluationAsPrimaryRequest = {
  /** The ID of the evaluation to mark as primary. */
  evaluationId: Scalars["UUID"];
};

export type MarketContractTier = {
  __typename?: "MarketContractTier";
  currentContractTier?: Maybe<Scalars["String"]>;
  marvalPlayerId: Scalars["UUID"];
  ufaContractTier?: Maybe<Scalars["String"]>;
};

export type MarketContractTierFilterInput = {
  and?: InputMaybe<Array<MarketContractTierFilterInput>>;
  currentContractTier?: InputMaybe<StringOperationFilterInput>;
  marvalPlayerId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<MarketContractTierFilterInput>>;
  ufaContractTier?: InputMaybe<StringOperationFilterInput>;
};

export type MarketContractTierSortInput = {
  currentContractTier?: InputMaybe<SortEnumType>;
  marvalPlayerId?: InputMaybe<SortEnumType>;
  ufaContractTier?: InputMaybe<SortEnumType>;
};

export type MeasureType = {
  __typename?: "MeasureType";
  created: Scalars["DateTime"];
  /** Brief description of measure */
  description: Scalars["String"];
  id: Scalars["ID"];
  /** Whether the display should be a fraction */
  isFraction: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** Maximum value for validation */
  maxValue: Scalars["Decimal"];
  /** Minimum value for validation */
  minValue: Scalars["Decimal"];
  /** Name of measure */
  name: Scalars["String"];
  /** Decimal precision */
  precision: Scalars["Int"];
  /** Order of display/sort */
  priority: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Short name for display */
  shortName: Scalars["String"];
  updateMeasureType: MeasureType;
};

export type MeasureTypeUpdateMeasureTypeArgs = {
  request: UpdateMeasureTypeRequest;
};

export type MeasureTypeFilterInput = {
  and?: InputMaybe<Array<MeasureTypeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Brief description of measure */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Whether the display should be a fraction */
  isFraction?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** Maximum value for validation */
  maxValue?: InputMaybe<DecimalOperationFilterInput>;
  /** Minimum value for validation */
  minValue?: InputMaybe<DecimalOperationFilterInput>;
  /** Name of measure */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MeasureTypeFilterInput>>;
  /** Decimal precision */
  precision?: InputMaybe<IntOperationFilterInput>;
  /** Order of display/sort */
  priority?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Short name for display */
  shortName?: InputMaybe<StringOperationFilterInput>;
};

export type MeasureTypeSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Brief description of measure */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Whether the display should be a fraction */
  isFraction?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Maximum value for validation */
  maxValue?: InputMaybe<SortEnumType>;
  /** Minimum value for validation */
  minValue?: InputMaybe<SortEnumType>;
  /** Name of measure */
  name?: InputMaybe<SortEnumType>;
  /** Decimal precision */
  precision?: InputMaybe<SortEnumType>;
  /** Order of display/sort */
  priority?: InputMaybe<SortEnumType>;
  /** Short name for display */
  shortName?: InputMaybe<SortEnumType>;
};

export type MeasureWithTypeInput = {
  measureTypeId: Scalars["UUID"];
  measureTypeName: Scalars["String"];
  value?: InputMaybe<Scalars["Decimal"]>;
  verified: Scalars["Boolean"];
};

/**
 * Per league CBA, beginning in the 2020 legue year, the P5 salary of any player on a Club's active/inactive list
 * at any time during the regular season will not be less than MinimumSalaryRoster. The minimum salary of any player
 * not on a Club's active/inactive list (excluding practice squad) will be MinimumSalary.
 */
export type MinimumSalaries = {
  __typename?: "MinimumSalaries";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  maxCreditedSeasons: Scalars["Int"];
  /** Minimum credited seasons to earn salary */
  minCreditedSeasons: Scalars["Int"];
  /** Minimum for non-rostered players (see comments above) */
  minimumSalary: Scalars["Decimal"];
  /** minimum for rostered players (see comments above) */
  minimumSalaryRoster: Scalars["Decimal"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Season values for minimum salary */
  season: Scalars["Int"];
};

/**
 * Per league CBA, beginning in the 2020 legue year, the P5 salary of any player on a Club's active/inactive list
 * at any time during the regular season will not be less than MinimumSalaryRoster. The minimum salary of any player
 * not on a Club's active/inactive list (excluding practice squad) will be MinimumSalary.
 */
export type MinimumSalariesFilterInput = {
  and?: InputMaybe<Array<MinimumSalariesFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  maxCreditedSeasons?: InputMaybe<IntOperationFilterInput>;
  /** Minimum credited seasons to earn salary */
  minCreditedSeasons?: InputMaybe<IntOperationFilterInput>;
  /** Minimum for non-rostered players (see comments above) */
  minimumSalary?: InputMaybe<DecimalOperationFilterInput>;
  /** minimum for rostered players (see comments above) */
  minimumSalaryRoster?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<MinimumSalariesFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Season values for minimum salary */
  season?: InputMaybe<IntOperationFilterInput>;
};

/**
 * Per league CBA, beginning in the 2020 legue year, the P5 salary of any player on a Club's active/inactive list
 * at any time during the regular season will not be less than MinimumSalaryRoster. The minimum salary of any player
 * not on a Club's active/inactive list (excluding practice squad) will be MinimumSalary.
 */
export type MinimumSalariesSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  maxCreditedSeasons?: InputMaybe<SortEnumType>;
  /** Minimum credited seasons to earn salary */
  minCreditedSeasons?: InputMaybe<SortEnumType>;
  /** Minimum for non-rostered players (see comments above) */
  minimumSalary?: InputMaybe<SortEnumType>;
  /** minimum for rostered players (see comments above) */
  minimumSalaryRoster?: InputMaybe<SortEnumType>;
  /** Season values for minimum salary */
  season?: InputMaybe<SortEnumType>;
};

export type Mutation = {
  __typename?: "Mutation";
  activateUser: User;
  /** Updates an evaluations status to Final */
  assignFinalEvaluationStatus?: Maybe<Evaluation>;
  assignPermissionsToRole: AppRole;
  /** Assign player field override */
  assignPlayerOverride?: Maybe<Player>;
  /** Updates an evaluations status to Ready for Edit */
  assignReadyForEditEvaluationStatus?: Maybe<Evaluation>;
  /** Updates an evaluations status to Work in Progress */
  assignWorkInProgressEvaluationStatus?: Maybe<Evaluation>;
  /** Create a new evaluation */
  createEvaluation?: Maybe<Evaluation>;
  createGameEvaluation?: Maybe<Evaluation>;
  createInjuryCollege?: Maybe<PlayerInjuryCollege>;
  createInjuryPro?: Maybe<PlayerInjuryPro>;
  createPermission: Permission;
  /** Create a new player */
  createPlayer?: Maybe<Player>;
  /** assign a player tag */
  createPlayerTag: Array<Player>;
  createPositionAtlasNode?: Maybe<PositionAtlasNode>;
  createRole: AppRole;
  /** Create a new scout attribute */
  createScoutingAssignment?: Maybe<ScoutingAssignment>;
  /** Create a new tag */
  createTag?: Maybe<Tag>;
  createUser: User;
  /** create a worksheet item override value */
  createWorksheetItem: WorksheetItem;
  deactivateUser: User;
  /** Delete a game grade. */
  deleteGameEvaluation?: Maybe<Evaluation>;
  deletePermission: Permission;
  /** Remove a player tag */
  deletePlayerTag: Array<Player>;
  deleteRole: AppRole;
  /** Delete a scout attribute */
  deleteScoutingAssignment: ScoutingAssignment;
  /** Delete a worksheet item override value */
  deleteWorksheetItem: WorksheetItem;
  /** Method to flag evaluation for delete */
  flagEvaluationForDelete?: Maybe<Evaluation>;
  initAtlas?: Maybe<PositionAtlasNode>;
  /** Mark evaluation as primary, unmark existing primary evaluation */
  markEvaluationAsPrimary?: Maybe<Evaluation>;
  provisionUser: User;
  removePermissionsFromRole: AppRole;
  /** Reset a contract back to league defaults */
  resetContract: Contract;
  /** Deletes, recreates and reloads all the data in the search indices for player-related data. */
  resetPlayerSearchIndices: Scalars["Boolean"];
  /** Create/update a big board player ranks */
  saveBigBoardPlayers: Array<BigBoardPlayer>;
  /** Create/update a evaluation alerts */
  saveEvaluationAlerts?: Maybe<Evaluation>;
  /** Create/update a evaluation team/club fit */
  saveEvaluationClubFits?: Maybe<Evaluation>;
  /** Create/update a evaluation fits */
  saveEvaluationFits?: Maybe<Evaluation>;
  /** Create/update a evaluation trait */
  saveEvaluationTrait?: Maybe<Evaluation>;
  /** Create/update a evaluation trait group grade */
  saveEvaluationTraitGroup?: Maybe<Evaluation>;
  /** Save player scout assignments */
  savePlayerAssignment: Array<ScoutingAssignment>;
  /** Create a new player tag */
  savePlayerTags: Array<Player>;
  saveSumerScoutMeasure: Array<WorkoutPlayerMeasure>;
  /** Initial sync for alerts table */
  syncAlerts: Scalars["Boolean"];
  /** Initial sync for eval tables */
  syncEvals: Scalars["Boolean"];
  /** Initial sync of fits table */
  syncFits: Scalars["Boolean"];
  /** Initial sync for positions table */
  syncPositions: Scalars["Boolean"];
  /** Sets the IsFinal flag to false, evaluation will be able to be edited again */
  unMarkEvaluationAsFinal?: Maybe<Evaluation>;
  /** Update an basic details of existing player */
  updateBasicPlayerDetails?: Maybe<Player>;
  /** Update a contract summary field */
  updateContractSummary: Contract;
  /** Method to update contract totals */
  updateContractTotals: Contract;
  /** Update an existing evaluation */
  updateEvaluation?: Maybe<Evaluation>;
  updateGameEvaluation?: Maybe<Evaluation>;
  updateInjuryCollege?: Maybe<PlayerInjuryCollege>;
  updateInjuryPro?: Maybe<PlayerInjuryPro>;
  updatePermission: Permission;
  /** Update an existing player */
  updatePlayer?: Maybe<Player>;
  updateRole: AppRole;
  /** Update a scout attribute */
  updateScoutingAssignment?: Maybe<ScoutingAssignment>;
  /** Update a tag */
  updateTag?: Maybe<Tag>;
  updateUser: User;
  /** Update a worksheet item override value */
  updateWorksheetItem: WorksheetItem;
};

export type MutationActivateUserArgs = {
  request: ActivateUserRequest;
};

export type MutationAssignFinalEvaluationStatusArgs = {
  request: AssignFinalEvaluationStatusRequest;
};

export type MutationAssignPermissionsToRoleArgs = {
  request: AssignPermissionsToRoleRequest;
};

export type MutationAssignPlayerOverrideArgs = {
  assignPlayerOverride: AssignPlayerOverrideRequest;
};

export type MutationAssignReadyForEditEvaluationStatusArgs = {
  request: AssignReadyForEditEvaluationStatusRequest;
};

export type MutationAssignWorkInProgressEvaluationStatusArgs = {
  request: AssignWipEvaluationStatusRequest;
};

export type MutationCreateEvaluationArgs = {
  request: CreateEvaluationRequest;
};

export type MutationCreateGameEvaluationArgs = {
  request: CreateGameEvaluationRequest;
};

export type MutationCreateInjuryCollegeArgs = {
  createInjuryCollegeRequest: CreatePlayerInjuryColWithDetailsRequest;
};

export type MutationCreateInjuryProArgs = {
  createInjuryProRequest: CreatePlayerInjuryProWithDetailsRequest;
};

export type MutationCreatePermissionArgs = {
  request: CreatePermissionRequest;
};

export type MutationCreatePlayerArgs = {
  createPlayerRequest: CreatePlayerRequest;
};

export type MutationCreatePlayerTagArgs = {
  request: CreatePlayerTagRequest;
};

export type MutationCreatePositionAtlasNodeArgs = {
  createPositionAtlasNodeRequest: CreatePositionAtlasNodeRequest;
};

export type MutationCreateRoleArgs = {
  request: CreateRoleRequest;
};

export type MutationCreateScoutingAssignmentArgs = {
  request: CreateScoutingAssignmentRequest;
};

export type MutationCreateTagArgs = {
  request: CreateTagRequest;
};

export type MutationCreateUserArgs = {
  request: CreateUserRequest;
};

export type MutationCreateWorksheetItemArgs = {
  createWorksheetItemRequest: CreateWorksheetItemRequest;
};

export type MutationDeactivateUserArgs = {
  request: DeactivateUserRequest;
};

export type MutationDeleteGameEvaluationArgs = {
  evaluationGameId: Scalars["UUID"];
  evaluationId: Scalars["UUID"];
};

export type MutationDeletePermissionArgs = {
  request: DeletePermissionRequest;
};

export type MutationDeletePlayerTagArgs = {
  request: DeletePlayerTagRequest;
};

export type MutationDeleteRoleArgs = {
  request: DeleteRoleRequest;
};

export type MutationDeleteScoutingAssignmentArgs = {
  request: DeleteScoutingAssignmentRequest;
};

export type MutationDeleteWorksheetItemArgs = {
  deleteWorksheetItemRequest: DeleteWorksheetItemRequest;
};

export type MutationFlagEvaluationForDeleteArgs = {
  request: FlagEvaluationForDeleteRequest;
};

export type MutationMarkEvaluationAsPrimaryArgs = {
  request: MarkEvaluationAsPrimaryRequest;
};

export type MutationProvisionUserArgs = {
  request: ProvisionUserRequest;
};

export type MutationRemovePermissionsFromRoleArgs = {
  request: RemovePermissionsFromRoleRequest;
};

export type MutationResetContractArgs = {
  resetContractRequest: ResetContractRequest;
};

export type MutationResetPlayerSearchIndicesArgs = {
  resetPlayerSearchIndicesRequest: ResetPlayerSearchIndicesRequest;
};

export type MutationSaveBigBoardPlayersArgs = {
  request: SaveBigBoardPlayerRequest;
};

export type MutationSaveEvaluationAlertsArgs = {
  request: SaveEvaluationAlertRequest;
};

export type MutationSaveEvaluationClubFitsArgs = {
  request: SaveEvaluationClubFitRequest;
};

export type MutationSaveEvaluationFitsArgs = {
  request: SaveEvaluationFitRequest;
};

export type MutationSaveEvaluationTraitArgs = {
  request: SaveEvaluationTraitRequest;
};

export type MutationSaveEvaluationTraitGroupArgs = {
  request: SaveEvaluationTraitGroupRequest;
};

export type MutationSavePlayerAssignmentArgs = {
  request: SavePlayerAssignmentRequest;
};

export type MutationSavePlayerTagsArgs = {
  request: SavePlayerTagsRequest;
};

export type MutationSaveSumerScoutMeasureArgs = {
  request: UpsertSumerScoutMeasureRequest;
};

export type MutationSyncAlertsArgs = {
  request: SyncAlertsRequestInput;
};

export type MutationSyncEvalsArgs = {
  request: SyncEvaluationMetricGroupsRequestInput;
};

export type MutationSyncFitsArgs = {
  request: SyncFitsRequestInput;
};

export type MutationSyncPositionsArgs = {
  request: SyncPositionsRequestInput;
};

export type MutationUnMarkEvaluationAsFinalArgs = {
  request: UnMarkEvaluationAsFinalRequest;
};

export type MutationUpdateBasicPlayerDetailsArgs = {
  updateBasicPlayerDetailsRequest: UpdateBasicPlayerDetailsRequest;
};

export type MutationUpdateContractSummaryArgs = {
  updateContractSummaryRequest: UpdateContractSummaryRequest;
};

export type MutationUpdateContractTotalsArgs = {
  updateContractTotalsRequest: AssignContractTotalsRequest;
};

export type MutationUpdateEvaluationArgs = {
  request: UpdateEvaluationRequest;
};

export type MutationUpdateGameEvaluationArgs = {
  request: UpdateGameEvaluationRequest;
};

export type MutationUpdateInjuryCollegeArgs = {
  updateInjuryCollegeRequest: UpdatePlayerInjuryCollegeWithDetailsRequest;
};

export type MutationUpdateInjuryProArgs = {
  updateInjuryProRequest: UpdatePlayerInjuryProWithDetailsRequest;
};

export type MutationUpdatePermissionArgs = {
  request: UpdatePermissionRequest;
};

export type MutationUpdatePlayerArgs = {
  updatePlayerRequest: UpdatePlayerRequest;
};

export type MutationUpdateRoleArgs = {
  request: UpdateRoleRequest;
};

export type MutationUpdateScoutingAssignmentArgs = {
  request: UpdateScoutingAssignmentRequest;
};

export type MutationUpdateTagArgs = {
  request: UpdateTagRequest;
};

export type MutationUpdateUserArgs = {
  request: UpdateUserRequest;
};

export type MutationUpdateWorksheetItemArgs = {
  updateWorksheetItemRequest: UpdateWorksheetItemRequest;
};

export type Nym = {
  __typename?: "NYM";
  id: Scalars["UUID"];
};

export type NymFilterInput = {
  and?: InputMaybe<Array<NymFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<NymFilterInput>>;
};

export type NflCutdownPrediction = {
  __typename?: "NflCutdownPrediction";
  atlasNode?: Maybe<Scalars["String"]>;
  clubId: Scalars["Byte"];
  cutProb: Scalars["Float"];
  cutdownBucket: Scalars["String"];
  displayName?: Maybe<Scalars["String"]>;
  lastUpdate: Scalars["String"];
  marvalPlayerId: Scalars["UUID"];
  playerType: Scalars["String"];
  practiceSquadProb: Scalars["Float"];
  season: Scalars["Short"];
  signProb: Scalars["Float"];
  sumerGeneralPosition?: Maybe<Scalars["String"]>;
  teamCode: Scalars["String"];
};

export type NflCutdownPredictionFilterInput = {
  and?: InputMaybe<Array<NflCutdownPredictionFilterInput>>;
  atlasNode?: InputMaybe<StringOperationFilterInput>;
  clubId?: InputMaybe<ByteOperationFilterInput>;
  cutProb?: InputMaybe<FloatOperationFilterInput>;
  cutdownBucket?: InputMaybe<StringOperationFilterInput>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  lastUpdate?: InputMaybe<StringOperationFilterInput>;
  marvalPlayerId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<NflCutdownPredictionFilterInput>>;
  playerType?: InputMaybe<StringOperationFilterInput>;
  practiceSquadProb?: InputMaybe<FloatOperationFilterInput>;
  season?: InputMaybe<ShortOperationFilterInput>;
  signProb?: InputMaybe<FloatOperationFilterInput>;
  sumerGeneralPosition?: InputMaybe<StringOperationFilterInput>;
  teamCode?: InputMaybe<StringOperationFilterInput>;
};

export type NflCutdownPredictionSortInput = {
  atlasNode?: InputMaybe<SortEnumType>;
  clubId?: InputMaybe<SortEnumType>;
  cutProb?: InputMaybe<SortEnumType>;
  cutdownBucket?: InputMaybe<SortEnumType>;
  displayName?: InputMaybe<SortEnumType>;
  lastUpdate?: InputMaybe<SortEnumType>;
  marvalPlayerId?: InputMaybe<SortEnumType>;
  playerType?: InputMaybe<SortEnumType>;
  practiceSquadProb?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  signProb?: InputMaybe<SortEnumType>;
  sumerGeneralPosition?: InputMaybe<SortEnumType>;
  teamCode?: InputMaybe<SortEnumType>;
};

export type NullableOfConversionTypeEnumOperationFilterInput = {
  eq?: InputMaybe<ConversionTypeEnum>;
  in?: InputMaybe<Array<InputMaybe<ConversionTypeEnum>>>;
  neq?: InputMaybe<ConversionTypeEnum>;
  nin?: InputMaybe<Array<InputMaybe<ConversionTypeEnum>>>;
};

export type NullableOfEligibilityTypeOperationFilterInput = {
  eq?: InputMaybe<EligibilityType>;
  in?: InputMaybe<Array<InputMaybe<EligibilityType>>>;
  neq?: InputMaybe<EligibilityType>;
  nin?: InputMaybe<Array<InputMaybe<EligibilityType>>>;
};

export type NullableOfFreeAgencyStatusOperationFilterInput = {
  eq?: InputMaybe<FreeAgencyStatus>;
  in?: InputMaybe<Array<InputMaybe<FreeAgencyStatus>>>;
  neq?: InputMaybe<FreeAgencyStatus>;
  nin?: InputMaybe<Array<InputMaybe<FreeAgencyStatus>>>;
};

export type NullableOfGradeWeightEnumOperationFilterInput = {
  eq?: InputMaybe<GradeWeightEnum>;
  in?: InputMaybe<Array<InputMaybe<GradeWeightEnum>>>;
  neq?: InputMaybe<GradeWeightEnum>;
  nin?: InputMaybe<Array<InputMaybe<GradeWeightEnum>>>;
};

export type NullableOfSideOfBallOperationFilterInput = {
  eq?: InputMaybe<SideOfBall>;
  in?: InputMaybe<Array<InputMaybe<SideOfBall>>>;
  neq?: InputMaybe<SideOfBall>;
  nin?: InputMaybe<Array<InputMaybe<SideOfBall>>>;
};

export type NullableOfSourceTypeEnumOperationFilterInput = {
  eq?: InputMaybe<SourceTypeEnum>;
  in?: InputMaybe<Array<InputMaybe<SourceTypeEnum>>>;
  neq?: InputMaybe<SourceTypeEnum>;
  nin?: InputMaybe<Array<InputMaybe<SourceTypeEnum>>>;
};

/** Defines an offensive or defensive personnel group used by a team */
export type Package = {
  __typename?: "Package";
  /** Package abbreviation defined by PFF */
  abbreviation: Scalars["String"];
  created: Scalars["DateTime"];
  /** Package description defined by PFF */
  description: Scalars["String"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Package name - defaulted to abbreviation */
  name: Scalars["String"];
  pffPackageId: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Package side of ball */
  sideOfBall: SideOfBall;
};

/** PFF's definition of packages a club commonly uses (per pff.packages_depthcharts_nfl) */
export type PackageClub = {
  __typename?: "PackageClub";
  club: Club;
  /** Club that runs the package according to PFF */
  clubId: Scalars["UUID"];
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  /**
   * Is this a base package for the club? Not set by default.
   * Might be able to pull from league data
   */
  isBase: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  package: Package;
  /** Package Id */
  packageId: Scalars["UUID"];
  /** Priority of the package for that club */
  priority?: Maybe<Scalars["Int"]>;
  rowVersion: Array<Scalars["Byte"]>;
};

/** PFF's definition of packages a club commonly uses (per pff.packages_depthcharts_nfl) */
export type PackageClubFilterInput = {
  and?: InputMaybe<Array<PackageClubFilterInput>>;
  club?: InputMaybe<ClubFilterInput>;
  /** Club that runs the package according to PFF */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /**
   * Is this a base package for the club? Not set by default.
   * Might be able to pull from league data
   */
  isBase?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PackageClubFilterInput>>;
  package?: InputMaybe<PackageFilterInput>;
  /** Package Id */
  packageId?: InputMaybe<UuidOperationFilterInput>;
  /** Priority of the package for that club */
  priority?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

/** PFF's definition of packages a club commonly uses (per pff.packages_depthcharts_nfl) */
export type PackageClubSortInput = {
  club?: InputMaybe<ClubSortInput>;
  /** Club that runs the package according to PFF */
  clubId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /**
   * Is this a base package for the club? Not set by default.
   * Might be able to pull from league data
   */
  isBase?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  package?: InputMaybe<PackageSortInput>;
  /** Package Id */
  packageId?: InputMaybe<SortEnumType>;
  /** Priority of the package for that club */
  priority?: InputMaybe<SortEnumType>;
};

/** Defines an offensive or defensive personnel group used by a team */
export type PackageFilterInput = {
  /** Package abbreviation defined by PFF */
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<PackageFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Package description defined by PFF */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** Package name - defaulted to abbreviation */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PackageFilterInput>>;
  pffPackageId?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Package side of ball */
  sideOfBall?: InputMaybe<SideOfBallOperationFilterInput>;
};

/** Positions defined within a package */
export type PackagePosition = {
  __typename?: "PackagePosition";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** One of the packages that contains the position. */
  package: Package;
  /** Foreign key to Package */
  packageId: Scalars["UUID"];
  /** One of the positions the player plays. */
  position: Position;
  /** Foreign key to Position */
  positionId: Scalars["UUID"];
  /** Order of display */
  priority?: Maybe<Scalars["Int"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** Position of package position for display */
  x?: Maybe<Scalars["Int"]>;
  y?: Maybe<Scalars["Int"]>;
};

/** Positions defined within a package */
export type PackagePositionFilterInput = {
  and?: InputMaybe<Array<PackagePositionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PackagePositionFilterInput>>;
  /** One of the packages that contains the position. */
  package?: InputMaybe<PackageFilterInput>;
  /** Foreign key to Package */
  packageId?: InputMaybe<UuidOperationFilterInput>;
  /** One of the positions the player plays. */
  position?: InputMaybe<PositionFilterInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<UuidOperationFilterInput>;
  /** Order of display */
  priority?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Position of package position for display */
  x?: InputMaybe<IntOperationFilterInput>;
  y?: InputMaybe<IntOperationFilterInput>;
};

/** Positions defined within a package */
export type PackagePositionSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** One of the packages that contains the position. */
  package?: InputMaybe<PackageSortInput>;
  /** Foreign key to Package */
  packageId?: InputMaybe<SortEnumType>;
  /** One of the positions the player plays. */
  position?: InputMaybe<PositionSortInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<SortEnumType>;
  /** Order of display */
  priority?: InputMaybe<SortEnumType>;
  /** Position of package position for display */
  x?: InputMaybe<SortEnumType>;
  y?: InputMaybe<SortEnumType>;
};

/** Defines an offensive or defensive personnel group used by a team */
export type PackageSortInput = {
  /** Package abbreviation defined by PFF */
  abbreviation?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** Package description defined by PFF */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Package name - defaulted to abbreviation */
  name?: InputMaybe<SortEnumType>;
  pffPackageId?: InputMaybe<SortEnumType>;
  /** Package side of ball */
  sideOfBall?: InputMaybe<SortEnumType>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars["Boolean"];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** A permission represents access to some feature or data element within the system. */
export type Permission = {
  __typename?: "Permission";
  created: Scalars["DateTime"];
  createdById: Scalars["UUID"];
  /** Description of permission */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  lastUpdated: Scalars["DateTime"];
  lastUpdatedById: Scalars["UUID"];
  /** Permission name for application functionality */
  name: Scalars["String"];
  /** Roles with permission assigned */
  roles: Array<AppRole>;
  rowVersion: Array<Scalars["Byte"]>;
};

/** A permission represents access to some feature or data element within the system. */
export type PermissionFilterInput = {
  and?: InputMaybe<Array<PermissionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  /** Description of permission */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedById?: InputMaybe<UuidOperationFilterInput>;
  /** Permission name for application functionality */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PermissionFilterInput>>;
  /** Roles with permission assigned */
  roles?: InputMaybe<ListFilterInputTypeOfAppRoleFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

/** A permission represents access to some feature or data element within the system. */
export type PermissionSortInput = {
  created?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  /** Description of permission */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  lastUpdatedById?: InputMaybe<SortEnumType>;
  /** Permission name for application functionality */
  name?: InputMaybe<SortEnumType>;
};

export enum Personnel {
  DEFENSE_BASE = "DEFENSE_BASE",
  DEFENSE_DIME = "DEFENSE_DIME",
  DEFENSE_NICKEL = "DEFENSE_NICKEL",
  OFFENSE01 = "OFFENSE01",
  OFFENSE02 = "OFFENSE02",
  OFFENSE11 = "OFFENSE11",
  OFFENSE12 = "OFFENSE12",
  OFFENSE13 = "OFFENSE13",
  OFFENSE20 = "OFFENSE20",
  OFFENSE21 = "OFFENSE21",
  OFFENSE22 = "OFFENSE22",
  OFFENSE_JUMBO = "OFFENSE_JUMBO",
}

export type Play = {
  __typename?: "Play";
  clock?: Maybe<Scalars["String"]>;
  defense?: Maybe<Scalars["UUID"]>;
  defensivePersonnel?: Maybe<Scalars["String"]>;
  distance?: Maybe<Scalars["Int"]>;
  down?: Maybe<Scalars["Int"]>;
  fieldPosition?: Maybe<Scalars["Int"]>;
  game: Game;
  gameId: Scalars["UUID"];
  goalLine: Scalars["Boolean"];
  gsisGameId?: Maybe<Scalars["Int"]>;
  gsisPlayId?: Maybe<Scalars["Int"]>;
  lastModified?: Maybe<Scalars["DateTime"]>;
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  offense?: Maybe<Scalars["UUID"]>;
  offensivePersonnel?: Maybe<Scalars["String"]>;
  playId: Scalars["UUID"];
  playerPlays: Array<PlayerPlay>;
  quarter?: Maybe<Scalars["Int"]>;
  redZone: Scalars["Boolean"];
  runPass?: Maybe<Scalars["String"]>;
  season?: Maybe<Scalars["Int"]>;
  shortYardage: Scalars["Boolean"];
  specialTeamsType?: Maybe<Scalars["String"]>;
  twoMinute?: Maybe<Scalars["Int"]>;
};

export type PlayFilterInput = {
  and?: InputMaybe<Array<PlayFilterInput>>;
  clock?: InputMaybe<StringOperationFilterInput>;
  defense?: InputMaybe<UuidOperationFilterInput>;
  defensivePersonnel?: InputMaybe<StringOperationFilterInput>;
  distance?: InputMaybe<IntOperationFilterInput>;
  down?: InputMaybe<IntOperationFilterInput>;
  fieldPosition?: InputMaybe<IntOperationFilterInput>;
  game?: InputMaybe<GameFilterInput>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  goalLine?: InputMaybe<BooleanOperationFilterInput>;
  gsisGameId?: InputMaybe<IntOperationFilterInput>;
  gsisPlayId?: InputMaybe<IntOperationFilterInput>;
  lastModified?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  offense?: InputMaybe<UuidOperationFilterInput>;
  offensivePersonnel?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PlayFilterInput>>;
  playId?: InputMaybe<UuidOperationFilterInput>;
  playerPlays?: InputMaybe<ListFilterInputTypeOfPlayerPlayFilterInput>;
  quarter?: InputMaybe<IntOperationFilterInput>;
  redZone?: InputMaybe<BooleanOperationFilterInput>;
  runPass?: InputMaybe<StringOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  shortYardage?: InputMaybe<BooleanOperationFilterInput>;
  specialTeamsType?: InputMaybe<StringOperationFilterInput>;
  twoMinute?: InputMaybe<IntOperationFilterInput>;
};

export type PlayFilterRequestInput = {
  distances?: InputMaybe<Array<Scalars["Int"]>>;
  downs?: InputMaybe<Array<Scalars["Int"]>>;
  gameIds?: InputMaybe<Array<Scalars["UUID"]>>;
  isGoalline?: InputMaybe<Scalars["Boolean"]>;
  isInTwoMinutes?: InputMaybe<Scalars["Boolean"]>;
  isRedzone?: InputMaybe<Scalars["Boolean"]>;
  isShortYardage?: InputMaybe<Scalars["Boolean"]>;
  keyPlays?: InputMaybe<Array<KeyPlay>>;
  personnels?: InputMaybe<Array<Personnel>>;
  playTypes?: InputMaybe<Array<PlayType>>;
  playerIds?: InputMaybe<Array<Scalars["UUID"]>>;
  seasons?: InputMaybe<Array<Scalars["Int"]>>;
  sidesOfBall?: InputMaybe<Array<SideOfBall>>;
  situations?: InputMaybe<Array<Situation>>;
  teamId?: InputMaybe<Scalars["UUID"]>;
};

export type PlaySortInput = {
  clock?: InputMaybe<SortEnumType>;
  defense?: InputMaybe<SortEnumType>;
  defensivePersonnel?: InputMaybe<SortEnumType>;
  distance?: InputMaybe<SortEnumType>;
  down?: InputMaybe<SortEnumType>;
  fieldPosition?: InputMaybe<SortEnumType>;
  game?: InputMaybe<GameSortInput>;
  gameId?: InputMaybe<SortEnumType>;
  goalLine?: InputMaybe<SortEnumType>;
  gsisGameId?: InputMaybe<SortEnumType>;
  gsisPlayId?: InputMaybe<SortEnumType>;
  lastModified?: InputMaybe<SortEnumType>;
  leagueType?: InputMaybe<LeagueTypeSortInput>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  offense?: InputMaybe<SortEnumType>;
  offensivePersonnel?: InputMaybe<SortEnumType>;
  playId?: InputMaybe<SortEnumType>;
  quarter?: InputMaybe<SortEnumType>;
  redZone?: InputMaybe<SortEnumType>;
  runPass?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  shortYardage?: InputMaybe<SortEnumType>;
  specialTeamsType?: InputMaybe<SortEnumType>;
  twoMinute?: InputMaybe<SortEnumType>;
};

export type PlayTime = {
  __typename?: "PlayTime";
  active: Scalars["Boolean"];
  defensivePlays?: Maybe<Scalars["Int"]>;
  marvalClubId: Scalars["UUID"];
  marvalGameId: Scalars["UUID"];
  marvalPlayerId: Scalars["UUID"];
  offensivePlays?: Maybe<Scalars["Int"]>;
  season: Scalars["Int"];
  seasonType: Scalars["String"];
  specialTeamsPlays?: Maybe<Scalars["Int"]>;
  teamDefensivePlays?: Maybe<Scalars["Int"]>;
  teamOffensivePlays?: Maybe<Scalars["Int"]>;
  teamSpecialTeamsPlays?: Maybe<Scalars["Int"]>;
};

export type PlayTimeFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<PlayTimeFilterInput>>;
  defensivePlays?: InputMaybe<IntOperationFilterInput>;
  marvalClubId?: InputMaybe<UuidOperationFilterInput>;
  marvalGameId?: InputMaybe<UuidOperationFilterInput>;
  marvalPlayerId?: InputMaybe<UuidOperationFilterInput>;
  offensivePlays?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PlayTimeFilterInput>>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonType?: InputMaybe<StringOperationFilterInput>;
  specialTeamsPlays?: InputMaybe<IntOperationFilterInput>;
  teamDefensivePlays?: InputMaybe<IntOperationFilterInput>;
  teamOffensivePlays?: InputMaybe<IntOperationFilterInput>;
  teamSpecialTeamsPlays?: InputMaybe<IntOperationFilterInput>;
};

export type PlayTimeSortInput = {
  active?: InputMaybe<SortEnumType>;
  defensivePlays?: InputMaybe<SortEnumType>;
  marvalClubId?: InputMaybe<SortEnumType>;
  marvalGameId?: InputMaybe<SortEnumType>;
  marvalPlayerId?: InputMaybe<SortEnumType>;
  offensivePlays?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  seasonType?: InputMaybe<SortEnumType>;
  specialTeamsPlays?: InputMaybe<SortEnumType>;
  teamDefensivePlays?: InputMaybe<SortEnumType>;
  teamOffensivePlays?: InputMaybe<SortEnumType>;
  teamSpecialTeamsPlays?: InputMaybe<SortEnumType>;
};

export enum PlayType {
  EXTRA_POINT = "EXTRA_POINT",
  FIELD_GOAL = "FIELD_GOAL",
  KICKOFF = "KICKOFF",
  NO_PLAY = "NO_PLAY",
  PASS = "PASS",
  PUNT = "PUNT",
  RUN = "RUN",
}

/** A football player. */
export type Player = {
  __typename?: "Player";
  /**
   * Accrued seasons in an NFL contract refer to the number of seasons a player has been on an active
   * roster or injured reserve for at least six games. These accrued seasons impact a player's eligibility
   * for various benefits, such as unrestricted free agency, pension plans, and the ability to qualify for
   * certain salary escalators or contract guarantees.
   */
  accruedSeasons?: Maybe<Scalars["Int"]>;
  agent?: Maybe<Agent>;
  /** Player's agent */
  agentId?: Maybe<Scalars["UUID"]>;
  assumptions: Array<Assumption>;
  /** The day the player was born */
  birthDate?: Maybe<Scalars["DateTime"]>;
  /** The team the player currently plays for */
  club?: Maybe<Club>;
  /**
   * The position (if any) that the club has graded the player at.  Expectation is that the application
   * will only ingest one graded position for a given player.
   */
  clubGradingPosition?: Maybe<Position>;
  /** FK ID of the club graded position */
  clubGradingPositionId?: Maybe<Scalars["UUID"]>;
  /** Foreign key to Club */
  clubId?: Maybe<Scalars["UUID"]>;
  /** The player's league replication ID */
  collegeGsisId?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  draftClub?: Maybe<Club>;
  /** The club that drafted the player (if drafted) */
  draftClubId?: Maybe<Scalars["UUID"]>;
  draftRound?: Maybe<Scalars["Int"]>;
  draftSelection?: Maybe<Scalars["Int"]>;
  /** The year the player was drafted into the NFL */
  draftYear?: Maybe<Scalars["Int"]>;
  /** College details - college class */
  eligibility?: Maybe<EligibilityType>;
  /** College details - year eligible for the draft */
  eligibilityYear?: Maybe<Scalars["Int"]>;
  /** All evaluations performed against this player */
  evaluations: Array<Evaluation>;
  favorite?: Maybe<PlayerFavorite>;
  /** The player's legal first name */
  firstName: Scalars["String"];
  /** The player's known name or goes by */
  footballName?: Maybe<Scalars["String"]>;
  freeAgencyStatus?: Maybe<FreeAgencyStatus>;
  grades: Array<Grade>;
  /** The player's league replication ID */
  gsisId?: Maybe<Scalars["String"]>;
  /** A URL to the player's headshot image */
  headshotUrl?: Maybe<Scalars["String"]>;
  /** The player's latest height measurement in inches according to pff/league */
  height?: Maybe<Scalars["Int"]>;
  /** Year player graduated from high school. Help determines eligibility */
  highSchoolGradYear?: Maybe<Scalars["Int"]>;
  highschool?: Maybe<Scalars["String"]>;
  /** Where the player is from */
  hometownCity?: Maybe<Scalars["String"]>;
  hometownCountry?: Maybe<Scalars["String"]>;
  hometownState?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** The player's current jersey number */
  jersey?: Maybe<Scalars["String"]>;
  /** The player's last name */
  lastName: Scalars["String"];
  lastUpdated: Scalars["DateTime"];
  latestBirthDate?: Maybe<Scalars["String"]>;
  latestClubId?: Maybe<Scalars["UUID"]>;
  latestEligibility?: Maybe<EligibilityType>;
  latestEligibilityYear?: Maybe<Scalars["Int"]>;
  latestHighSchoolGradYear?: Maybe<Scalars["Int"]>;
  latestSchoolId?: Maybe<Scalars["UUID"]>;
  /** The player's College league id (CollegePlayerId - nonprodb.college_player_extract) */
  leagueCollegeId?: Maybe<Scalars["Int"]>;
  /** The player's middle name */
  middleName?: Maybe<Scalars["String"]>;
  /**
   * Minimum salary credits refer to the financial benefit given to a team when they sign a player with a
   * higher salary than the league's minimum. These credits offset a portion of the player's salary cap hit,
   * allowing the team to allocate more funds to other player contracts while still meeting the league's salary floor.
   */
  minSalaryCredits?: Maybe<Scalars["Int"]>;
  /**
   * The most recent team a player played for
   * Solves issue when a club drops from a roster for reporting
   */
  mostRecentClub?: Maybe<Club>;
  /** Foreign key to Club */
  mostRecentClubId?: Maybe<Scalars["UUID"]>;
  /**
   * The most recent team a player played for
   * Solves issue when a player drops from a roster for reporting
   */
  mostRecentSchool?: Maybe<School>;
  /** Foreign key to Club */
  mostRecentSchoolId?: Maybe<Scalars["UUID"]>;
  namePronunciation?: Maybe<Scalars["String"]>;
  /** The number of years of professional playing experience the player has */
  nflExperience: Scalars["Int"];
  /** the player's overall and season grades */
  playerGrade?: Maybe<PlayerGrade>;
  /**
   * Overriden fields on the player.
   * Ex: Scout may override BirthDate for a player
   */
  playerOverrides: Array<PlayerOverride>;
  /** The depth chart package positions a player plays, based on PFF. */
  playerPffPackagePositions: Array<PlayerPackagePosition>;
  /** Credits given each season for a player on the practice squad */
  practiceSquadCredits: Scalars["Int"];
  /** Player's eligibility for the practice squad */
  practiceSquadEligible: Scalars["Boolean"];
  practiceSquadExceptionEligible: Scalars["Boolean"];
  practiceSquadInternationalCredits: Scalars["Int"];
  /** Same practice squad eligibility for international players */
  practiceSquadInternationalEligible: Scalars["Boolean"];
  /** Games missed projection for the player */
  projectedGamesMissed: Array<PlayerProjectedGamesMissed>;
  /** College details - has redshirted */
  redshirt: Scalars["Boolean"];
  rowVersion: Array<Scalars["Byte"]>;
  /** The school the player is currently attending (college) or last college of pro player */
  school?: Maybe<School>;
  /** Foreign key to School */
  schoolId?: Maybe<Scalars["UUID"]>;
  /** The player's latest speed according to pff */
  speed?: Maybe<Scalars["Decimal"]>;
  /** The player's current status */
  status: PlayerStatus;
  /** The player's last name suffix */
  suffix?: Maybe<Scalars["String"]>;
  /**
   * The position (if any) of the player in the Sumer atlas position set.  This is a
   * subset of the SumerGeneralPosition that is significantly more granular.
   */
  sumerAtlasPosition?: Maybe<Position>;
  /** FK ID of the sumer atlas position */
  sumerAtlasPositionId?: Maybe<Scalars["UUID"]>;
  /** The position (if any) of the player in the Sumer general position set. */
  sumerGeneralPosition?: Maybe<Position>;
  /** FK ID of the sumer general position */
  sumerGeneralPositionId?: Maybe<Scalars["UUID"]>;
  /** tags associated with player */
  tags: Array<PlayerTag>;
  /**
   * In the NFL, pension credits are accumulated based on the number of seasons a player is on a team's
   * roster for at least three games. These credits determine the amount of retirement benefits players
   * receive after they retire from professional football. Players become eligible for pension benefits
   * after three credited seasons and the amount is calculated based on their years of service and
   * retirement age. Used to determine the Vet tag. Must be greater than three.
   */
  totalPensionCredits: Scalars["Int"];
  /** The player's latest weight measurement in pounds according to pff/league */
  weight?: Maybe<Scalars["Int"]>;
  /** All measurable sources for this player */
  workouts: Array<WorkoutPlayerMeasure>;
};

export type PlayerDetailsInput = {
  accruedSeasons?: InputMaybe<Scalars["Int"]>;
  agentId?: InputMaybe<Scalars["UUID"]>;
  birthDate?: InputMaybe<Scalars["DateTime"]>;
  clubFranchiseId?: InputMaybe<Scalars["Int"]>;
  collegeClass?: InputMaybe<EligibilityType>;
  collegeGsisId?: InputMaybe<Scalars["String"]>;
  currentEligibleYear?: InputMaybe<Scalars["Int"]>;
  draftFranchiseId?: InputMaybe<Scalars["Int"]>;
  draftRound?: InputMaybe<Scalars["Int"]>;
  draftSelection?: InputMaybe<Scalars["Int"]>;
  draftYear?: InputMaybe<Scalars["Int"]>;
  firstName: Scalars["String"];
  footballName: Scalars["String"];
  freeAgencyStatus?: InputMaybe<FreeAgencyStatus>;
  gsisAltId?: InputMaybe<Scalars["String"]>;
  gsisId?: InputMaybe<Scalars["String"]>;
  headshot?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Int"]>;
  highSchoolGradYear?: InputMaybe<Scalars["Int"]>;
  highschool?: InputMaybe<Scalars["String"]>;
  hometownCountry?: InputMaybe<Scalars["String"]>;
  hometownName?: InputMaybe<Scalars["String"]>;
  hometownState?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  jersey?: InputMaybe<Scalars["String"]>;
  lastModified?: InputMaybe<Scalars["Int"]>;
  lastName: Scalars["String"];
  league?: InputMaybe<Scalars["String"]>;
  leagueAgentId?: InputMaybe<Scalars["Int"]>;
  leagueCollegeId?: InputMaybe<Scalars["Int"]>;
  leagueNflId?: InputMaybe<Scalars["Int"]>;
  marvalClubId?: InputMaybe<Scalars["UUID"]>;
  marvalDraftClubId?: InputMaybe<Scalars["UUID"]>;
  marvalMostRecentClubId?: InputMaybe<Scalars["UUID"]>;
  middleName?: InputMaybe<Scalars["String"]>;
  minSalaryCredits?: InputMaybe<Scalars["Int"]>;
  mostRecentSchoolCode?: InputMaybe<Scalars["String"]>;
  mostRecentSchoolId?: InputMaybe<Scalars["UUID"]>;
  namePronunciation?: InputMaybe<Scalars["String"]>;
  nflApiPersonId?: InputMaybe<Scalars["UUID"]>;
  nflExperience: Scalars["Int"];
  pffPlayerId: Scalars["Int"];
  practiceSquadCredits: Scalars["Int"];
  practiceSquadEligible: Scalars["Boolean"];
  practiceSquadExceptionEligible: Scalars["Boolean"];
  practiceSquadInternationalCredits: Scalars["Int"];
  practiceSquadInternationalEligible: Scalars["Boolean"];
  redshirt: Scalars["Boolean"];
  retired?: InputMaybe<Scalars["Boolean"]>;
  retiredSeason?: InputMaybe<Scalars["Int"]>;
  schoolCode?: InputMaybe<Scalars["String"]>;
  schoolId?: InputMaybe<Scalars["UUID"]>;
  speed?: InputMaybe<Scalars["Decimal"]>;
  status: PlayerStatus;
  suffix?: InputMaybe<Scalars["String"]>;
  sumerAtlasPosition?: InputMaybe<Scalars["String"]>;
  sumerAtlasPositionId?: InputMaybe<Scalars["UUID"]>;
  sumerGeneralPosition?: InputMaybe<Scalars["String"]>;
  sumerGeneralPositionId?: InputMaybe<Scalars["UUID"]>;
  totalPensionCredits: Scalars["Int"];
  weight?: InputMaybe<Scalars["Int"]>;
};

/**
 * Player favorite store for the draft dashboard
 * TODO: Future release, we will add an UserId to this entity to store by user
 * to implement favorites by user vs global
 */
export type PlayerFavorite = {
  __typename?: "PlayerFavorite";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** The Player foreign key */
  player: Player;
  /** Player assigned to favorite */
  playerId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
};

/**
 * Player favorite store for the draft dashboard
 * TODO: Future release, we will add an UserId to this entity to store by user
 * to implement favorites by user vs global
 */
export type PlayerFavoriteFilterInput = {
  and?: InputMaybe<Array<PlayerFavoriteFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerFavoriteFilterInput>>;
  /** The Player foreign key */
  player?: InputMaybe<PlayerFilterInput>;
  /** Player assigned to favorite */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

/**
 * Player favorite store for the draft dashboard
 * TODO: Future release, we will add an UserId to this entity to store by user
 * to implement favorites by user vs global
 */
export type PlayerFavoriteSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The Player foreign key */
  player?: InputMaybe<PlayerSortInput>;
  /** Player assigned to favorite */
  playerId?: InputMaybe<SortEnumType>;
};

/** A football player. */
export type PlayerFilterInput = {
  /**
   * Accrued seasons in an NFL contract refer to the number of seasons a player has been on an active
   * roster or injured reserve for at least six games. These accrued seasons impact a player's eligibility
   * for various benefits, such as unrestricted free agency, pension plans, and the ability to qualify for
   * certain salary escalators or contract guarantees.
   */
  accruedSeasons?: InputMaybe<IntOperationFilterInput>;
  agent?: InputMaybe<AgentFilterInput>;
  /** Player's agent */
  agentId?: InputMaybe<UuidOperationFilterInput>;
  and?: InputMaybe<Array<PlayerFilterInput>>;
  assumptions?: InputMaybe<ListFilterInputTypeOfAssumptionFilterInput>;
  /** The day the player was born */
  birthDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** The team the player currently plays for */
  club?: InputMaybe<ClubFilterInput>;
  /**
   * The position (if any) that the club has graded the player at.  Expectation is that the application
   * will only ingest one graded position for a given player.
   */
  clubGradingPosition?: InputMaybe<PositionFilterInput>;
  /** FK ID of the club graded position */
  clubGradingPositionId?: InputMaybe<UuidOperationFilterInput>;
  /** Foreign key to Club */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  /** The player's league replication ID */
  collegeGsisId?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  draftClub?: InputMaybe<ClubFilterInput>;
  /** The club that drafted the player (if drafted) */
  draftClubId?: InputMaybe<UuidOperationFilterInput>;
  draftRound?: InputMaybe<IntOperationFilterInput>;
  draftSelection?: InputMaybe<IntOperationFilterInput>;
  /** The year the player was drafted into the NFL */
  draftYear?: InputMaybe<IntOperationFilterInput>;
  /** College details - college class */
  eligibility?: InputMaybe<NullableOfEligibilityTypeOperationFilterInput>;
  /** College details - year eligible for the draft */
  eligibilityYear?: InputMaybe<IntOperationFilterInput>;
  /** All evaluations performed against this player */
  evaluations?: InputMaybe<ListFilterInputTypeOfEvaluationFilterInput>;
  favorite?: InputMaybe<PlayerFavoriteFilterInput>;
  /** The player's legal first name */
  firstName?: InputMaybe<StringOperationFilterInput>;
  /** The player's known name or goes by */
  footballName?: InputMaybe<StringOperationFilterInput>;
  freeAgencyStatus?: InputMaybe<NullableOfFreeAgencyStatusOperationFilterInput>;
  grades?: InputMaybe<ListFilterInputTypeOfGradeFilterInput>;
  /** The player's league replication ID */
  gsisId?: InputMaybe<StringOperationFilterInput>;
  /** A URL to the player's headshot image */
  headshotUrl?: InputMaybe<StringOperationFilterInput>;
  /** The player's latest height measurement in inches according to pff/league */
  height?: InputMaybe<IntOperationFilterInput>;
  /** Year player graduated from high school. Help determines eligibility */
  highSchoolGradYear?: InputMaybe<IntOperationFilterInput>;
  highschool?: InputMaybe<StringOperationFilterInput>;
  /** Where the player is from */
  hometownCity?: InputMaybe<StringOperationFilterInput>;
  hometownCountry?: InputMaybe<StringOperationFilterInput>;
  hometownState?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** The player's current jersey number */
  jersey?: InputMaybe<StringOperationFilterInput>;
  /** The player's last name */
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  latestBirthDate?: InputMaybe<StringOperationFilterInput>;
  latestClubId?: InputMaybe<UuidOperationFilterInput>;
  latestEligibility?: InputMaybe<NullableOfEligibilityTypeOperationFilterInput>;
  latestEligibilityYear?: InputMaybe<IntOperationFilterInput>;
  latestHighSchoolGradYear?: InputMaybe<IntOperationFilterInput>;
  latestSchoolId?: InputMaybe<UuidOperationFilterInput>;
  /** The player's College league id (CollegePlayerId - nonprodb.college_player_extract) */
  leagueCollegeId?: InputMaybe<IntOperationFilterInput>;
  /** The player's middle name */
  middleName?: InputMaybe<StringOperationFilterInput>;
  /**
   * Minimum salary credits refer to the financial benefit given to a team when they sign a player with a
   * higher salary than the league's minimum. These credits offset a portion of the player's salary cap hit,
   * allowing the team to allocate more funds to other player contracts while still meeting the league's salary floor.
   */
  minSalaryCredits?: InputMaybe<IntOperationFilterInput>;
  /**
   * The most recent team a player played for
   * Solves issue when a club drops from a roster for reporting
   */
  mostRecentClub?: InputMaybe<ClubFilterInput>;
  /** Foreign key to Club */
  mostRecentClubId?: InputMaybe<UuidOperationFilterInput>;
  /**
   * The most recent team a player played for
   * Solves issue when a player drops from a roster for reporting
   */
  mostRecentSchool?: InputMaybe<SchoolFilterInput>;
  /** Foreign key to Club */
  mostRecentSchoolId?: InputMaybe<UuidOperationFilterInput>;
  namePronunciation?: InputMaybe<StringOperationFilterInput>;
  /** The number of years of professional playing experience the player has */
  nflExperience?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PlayerFilterInput>>;
  /** the player's overall and season grades */
  playerGrade?: InputMaybe<PlayerGradeFilterInput>;
  /**
   * Overriden fields on the player.
   * Ex: Scout may override BirthDate for a player
   */
  playerOverrides?: InputMaybe<ListFilterInputTypeOfPlayerOverrideFilterInput>;
  /** The depth chart package positions a player plays, based on PFF. */
  playerPffPackagePositions?: InputMaybe<ListFilterInputTypeOfPlayerPackagePositionFilterInput>;
  /** Credits given each season for a player on the practice squad */
  practiceSquadCredits?: InputMaybe<IntOperationFilterInput>;
  /** Player's eligibility for the practice squad */
  practiceSquadEligible?: InputMaybe<BooleanOperationFilterInput>;
  practiceSquadExceptionEligible?: InputMaybe<BooleanOperationFilterInput>;
  practiceSquadInternationalCredits?: InputMaybe<IntOperationFilterInput>;
  /** Same practice squad eligibility for international players */
  practiceSquadInternationalEligible?: InputMaybe<BooleanOperationFilterInput>;
  /** Games missed projection for the player */
  projectedGamesMissed?: InputMaybe<ListFilterInputTypeOfPlayerProjectedGamesMissedFilterInput>;
  /** College details - has redshirted */
  redshirt?: InputMaybe<BooleanOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** The school the player is currently attending (college) or last college of pro player */
  school?: InputMaybe<SchoolFilterInput>;
  /** Foreign key to School */
  schoolId?: InputMaybe<UuidOperationFilterInput>;
  /** The player's latest speed according to pff */
  speed?: InputMaybe<DecimalOperationFilterInput>;
  /** The player's current status */
  status?: InputMaybe<PlayerStatusOperationFilterInput>;
  /** The player's last name suffix */
  suffix?: InputMaybe<StringOperationFilterInput>;
  /**
   * The position (if any) of the player in the Sumer atlas position set.  This is a
   * subset of the SumerGeneralPosition that is significantly more granular.
   */
  sumerAtlasPosition?: InputMaybe<PositionFilterInput>;
  /** FK ID of the sumer atlas position */
  sumerAtlasPositionId?: InputMaybe<UuidOperationFilterInput>;
  /** The position (if any) of the player in the Sumer general position set. */
  sumerGeneralPosition?: InputMaybe<PositionFilterInput>;
  /** FK ID of the sumer general position */
  sumerGeneralPositionId?: InputMaybe<UuidOperationFilterInput>;
  /** tags associated with player */
  tags?: InputMaybe<ListFilterInputTypeOfPlayerTagFilterInput>;
  /**
   * In the NFL, pension credits are accumulated based on the number of seasons a player is on a team's
   * roster for at least three games. These credits determine the amount of retirement benefits players
   * receive after they retire from professional football. Players become eligible for pension benefits
   * after three credited seasons and the amount is calculated based on their years of service and
   * retirement age. Used to determine the Vet tag. Must be greater than three.
   */
  totalPensionCredits?: InputMaybe<IntOperationFilterInput>;
  /** The player's latest weight measurement in pounds according to pff/league */
  weight?: InputMaybe<IntOperationFilterInput>;
  /** All measurable sources for this player */
  workouts?: InputMaybe<ListFilterInputTypeOfWorkoutPlayerMeasureFilterInput>;
};

export type PlayerFiltersInput = {
  alertAbbreviation?: InputMaybe<Scalars["String"]>;
  clubCode?: InputMaybe<Scalars["String"]>;
  collegeOnly?: InputMaybe<Scalars["Boolean"]>;
  displayPositionShortName?: InputMaybe<Array<Scalars["String"]>>;
  draftYear?: InputMaybe<Scalars["Int"]>;
  evaluatedByUserId?: InputMaybe<Scalars["String"]>;
  maxClubGrade?: InputMaybe<Scalars["Float"]>;
  maxDraftYear?: InputMaybe<Scalars["Int"]>;
  maxHeight?: InputMaybe<Scalars["Int"]>;
  maxPrimaryEvaluationGrade?: InputMaybe<Scalars["Float"]>;
  maxSpeed?: InputMaybe<Scalars["Float"]>;
  maxWeight?: InputMaybe<Scalars["Int"]>;
  minClubGrade?: InputMaybe<Scalars["Float"]>;
  minDraftYear?: InputMaybe<Scalars["Int"]>;
  minHeight?: InputMaybe<Scalars["Int"]>;
  minPrimaryEvaluationGrade?: InputMaybe<Scalars["Float"]>;
  minSpeed?: InputMaybe<Scalars["Float"]>;
  minWeight?: InputMaybe<Scalars["Int"]>;
  primarySchemeFitAbbreviation?: InputMaybe<Scalars["String"]>;
  primarySchemePositionShortName?: InputMaybe<Scalars["String"]>;
  proOnly?: InputMaybe<Scalars["Boolean"]>;
  schemeFitAbbreviation?: InputMaybe<Scalars["String"]>;
  schoolCode?: InputMaybe<Scalars["String"]>;
  sumerAtlasPositionShortName?: InputMaybe<Array<Scalars["String"]>>;
  sumerGeneralPositionShortName?: InputMaybe<Array<Scalars["String"]>>;
};

/** Represents a player grade, taking into account the weighted grade types, and n-seasons worth of projections. */
export type PlayerGrade = {
  __typename?: "PlayerGrade";
  /** average club gapypoc dollars */
  avgClubGapypocDollars: Scalars["Decimal"];
  /** average sage gapypoc dollars */
  avgSageGapypocDollars: Scalars["Decimal"];
  /** average sumer scout gapypoc dollars */
  avgSumerScoutGapypocDollars: Scalars["Decimal"];
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** the football player this grade corresponds to */
  player: Player;
  /** the marval player id */
  playerId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  /** grades by season */
  seasons: Array<PlayerGradeSeason>;
  /** overall weighted grade value */
  value: PlayerGradeValue;
};

/** Represents a player grade, taking into account the weighted grade types, and n-seasons worth of projections. */
export type PlayerGradeFilterInput = {
  and?: InputMaybe<Array<PlayerGradeFilterInput>>;
  /** average club gapypoc dollars */
  avgClubGapypocDollars?: InputMaybe<DecimalOperationFilterInput>;
  /** average sage gapypoc dollars */
  avgSageGapypocDollars?: InputMaybe<DecimalOperationFilterInput>;
  /** average sumer scout gapypoc dollars */
  avgSumerScoutGapypocDollars?: InputMaybe<DecimalOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerGradeFilterInput>>;
  /** the football player this grade corresponds to */
  player?: InputMaybe<PlayerFilterInput>;
  /** the marval player id */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** grades by season */
  seasons?: InputMaybe<ListFilterInputTypeOfPlayerGradeSeasonFilterInput>;
  /** overall weighted grade value */
  value?: InputMaybe<PlayerGradeValueFilterInput>;
};

export type PlayerGradeSeason = {
  __typename?: "PlayerGradeSeason";
  created: Scalars["DateTime"];
  gradeType?: Maybe<GradeWeightEnum>;
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  playerGrade: PlayerGrade;
  playerGradeId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  value: PlayerGradeValue;
};

export type PlayerGradeSeasonFilterInput = {
  and?: InputMaybe<Array<PlayerGradeSeasonFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  gradeType?: InputMaybe<NullableOfGradeWeightEnumOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerGradeSeasonFilterInput>>;
  playerGrade?: InputMaybe<PlayerGradeFilterInput>;
  playerGradeId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<PlayerGradeValueFilterInput>;
};

/** Represents a player grade, taking into account the weighted grade types, and n-seasons worth of projections. */
export type PlayerGradeSortInput = {
  /** average club gapypoc dollars */
  avgClubGapypocDollars?: InputMaybe<SortEnumType>;
  /** average sage gapypoc dollars */
  avgSageGapypocDollars?: InputMaybe<SortEnumType>;
  /** average sumer scout gapypoc dollars */
  avgSumerScoutGapypocDollars?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** the football player this grade corresponds to */
  player?: InputMaybe<PlayerSortInput>;
  /** the marval player id */
  playerId?: InputMaybe<SortEnumType>;
  /** overall weighted grade value */
  value?: InputMaybe<PlayerGradeValueSortInput>;
};

/** Represents a player grade in GAPYPOC and GAPYPOC dollars. */
export type PlayerGradeValue = {
  __typename?: "PlayerGradeValue";
  gapypoc: Scalars["Decimal"];
  gapypocDollars: Scalars["Decimal"];
  rawValue?: Maybe<Scalars["String"]>;
  rawValueOverride?: Maybe<Scalars["String"]>;
  /** the raw value to use (default to overridden value if exists) */
  value: Scalars["String"];
};

/** Represents a player grade in GAPYPOC and GAPYPOC dollars. */
export type PlayerGradeValueFilterInput = {
  and?: InputMaybe<Array<PlayerGradeValueFilterInput>>;
  gapypoc?: InputMaybe<DecimalOperationFilterInput>;
  gapypocDollars?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<PlayerGradeValueFilterInput>>;
  rawValue?: InputMaybe<StringOperationFilterInput>;
  rawValueOverride?: InputMaybe<StringOperationFilterInput>;
  /** the raw value to use (default to overridden value if exists) */
  value?: InputMaybe<StringOperationFilterInput>;
};

/** Represents a player grade in GAPYPOC and GAPYPOC dollars. */
export type PlayerGradeValueSortInput = {
  gapypoc?: InputMaybe<SortEnumType>;
  gapypocDollars?: InputMaybe<SortEnumType>;
  rawValue?: InputMaybe<SortEnumType>;
  rawValueOverride?: InputMaybe<SortEnumType>;
  /** the raw value to use (default to overridden value if exists) */
  value?: InputMaybe<SortEnumType>;
};

export type PlayerHonor = {
  __typename?: "PlayerHonor";
  description: Scalars["String"];
  marvalClubId: Scalars["UUID"];
  marvalPlayerId: Scalars["UUID"];
  season: Scalars["Int"];
};

export type PlayerHonorFilterInput = {
  and?: InputMaybe<Array<PlayerHonorFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  marvalClubId?: InputMaybe<UuidOperationFilterInput>;
  marvalPlayerId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<PlayerHonorFilterInput>>;
  season?: InputMaybe<IntOperationFilterInput>;
};

export type PlayerHonorSortInput = {
  description?: InputMaybe<SortEnumType>;
  marvalClubId?: InputMaybe<SortEnumType>;
  marvalPlayerId?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
};

export type PlayerInjuryCollege = {
  __typename?: "PlayerInjuryCollege";
  created: Scalars["DateTime"];
  /** Date injury was reported by Rotowire */
  dateTime: Scalars["DateTime"];
  id: Scalars["ID"];
  /** Injury detail */
  injuryDetail?: Maybe<Scalars["String"]>;
  /** Injury side */
  injurySide?: Maybe<Scalars["String"]>;
  /** Injury status */
  injuryStatus?: Maybe<Scalars["String"]>;
  /** Injury type */
  injuryType?: Maybe<Scalars["String"]>;
  lastUpdated: Scalars["DateTime"];
  /** Player associated to injury */
  playerId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  school?: Maybe<School>;
  /** School the player was assocated with during injry */
  schoolId?: Maybe<Scalars["UUID"]>;
  /** Source i.e. League, Scout, etc. */
  source?: Maybe<Scalars["String"]>;
};

/** A connection to a list of items. */
export type PlayerInjuryCollegeConnection = {
  __typename?: "PlayerInjuryCollegeConnection";
  /** A list of edges. */
  edges?: Maybe<Array<PlayerInjuryCollegeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PlayerInjuryCollege>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

export type PlayerInjuryCollegeDetailsInput = {
  dateTime?: InputMaybe<Scalars["DateTime"]>;
  injuryDetail?: InputMaybe<Scalars["String"]>;
  injurySide?: InputMaybe<Scalars["String"]>;
  injuryStatus: Scalars["String"];
  injuryType?: InputMaybe<Scalars["String"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]>;
  playerId: Scalars["UUID"];
  schoolId: Scalars["UUID"];
  source?: InputMaybe<Scalars["String"]>;
};

/** An edge in a connection. */
export type PlayerInjuryCollegeEdge = {
  __typename?: "PlayerInjuryCollegeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: PlayerInjuryCollege;
};

export type PlayerInjuryCollegeFilterInput = {
  and?: InputMaybe<Array<PlayerInjuryCollegeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Date injury was reported by Rotowire */
  dateTime?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Injury detail */
  injuryDetail?: InputMaybe<StringOperationFilterInput>;
  /** Injury side */
  injurySide?: InputMaybe<StringOperationFilterInput>;
  /** Injury status */
  injuryStatus?: InputMaybe<StringOperationFilterInput>;
  /** Injury type */
  injuryType?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerInjuryCollegeFilterInput>>;
  /** Player associated to injury */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  school?: InputMaybe<SchoolFilterInput>;
  /** School the player was assocated with during injry */
  schoolId?: InputMaybe<UuidOperationFilterInput>;
  /** Source i.e. League, Scout, etc. */
  source?: InputMaybe<StringOperationFilterInput>;
};

export type PlayerInjuryCollegeSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Date injury was reported by Rotowire */
  dateTime?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Injury detail */
  injuryDetail?: InputMaybe<SortEnumType>;
  /** Injury side */
  injurySide?: InputMaybe<SortEnumType>;
  /** Injury status */
  injuryStatus?: InputMaybe<SortEnumType>;
  /** Injury type */
  injuryType?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Player associated to injury */
  playerId?: InputMaybe<SortEnumType>;
  school?: InputMaybe<SchoolSortInput>;
  /** School the player was assocated with during injry */
  schoolId?: InputMaybe<SortEnumType>;
  /** Source i.e. League, Scout, etc. */
  source?: InputMaybe<SortEnumType>;
};

export type PlayerInjuryPro = {
  __typename?: "PlayerInjuryPro";
  club?: Maybe<Club>;
  /** School the player was assocated with during injry */
  clubId?: Maybe<Scalars["UUID"]>;
  created: Scalars["DateTime"];
  /** Date for manually created injuries (not sync'd from league) */
  dateTime?: Maybe<Scalars["DateTime"]>;
  game?: Maybe<Game>;
  /** Game week injury is associated */
  gameId?: Maybe<Scalars["UUID"]>;
  id: Scalars["ID"];
  /** Injury body part */
  injury1?: Maybe<Scalars["String"]>;
  /** Injury body part if more than one */
  injury2?: Maybe<Scalars["String"]>;
  /** Injury body part with side */
  injuryDesc1?: Maybe<Scalars["String"]>;
  injuryDesc2?: Maybe<Scalars["String"]>;
  /** Injury status */
  injuryStatus: Scalars["String"];
  lastUpdated: Scalars["DateTime"];
  /** The league's injury id */
  leagueInjuryId?: Maybe<Scalars["Int"]>;
  /** Player associated to injury */
  playerId: Scalars["UUID"];
  /** Practice Day of game week */
  reportDay?: Maybe<Scalars["Int"]>;
  /** Injury context */
  reportType?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  season?: Maybe<Scalars["Int"]>;
  /** Source i.e. League, Scout, etc. */
  source?: Maybe<Scalars["String"]>;
};

/** A connection to a list of items. */
export type PlayerInjuryProConnection = {
  __typename?: "PlayerInjuryProConnection";
  /** A list of edges. */
  edges?: Maybe<Array<PlayerInjuryProEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PlayerInjuryPro>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

export type PlayerInjuryProDetailsInput = {
  clubId?: InputMaybe<Scalars["UUID"]>;
  dateTime?: InputMaybe<Scalars["DateTime"]>;
  gameId?: InputMaybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  injury1: Scalars["String"];
  injury2?: InputMaybe<Scalars["String"]>;
  injuryDesc1: Scalars["String"];
  injuryDesc2?: InputMaybe<Scalars["String"]>;
  injuryStatus: Scalars["String"];
  leagueInjuryId?: InputMaybe<Scalars["Int"]>;
  playerId: Scalars["UUID"];
  reportDay?: InputMaybe<Scalars["Int"]>;
  reportType?: InputMaybe<Scalars["String"]>;
  season?: InputMaybe<Scalars["Int"]>;
  source?: InputMaybe<Scalars["String"]>;
};

/** An edge in a connection. */
export type PlayerInjuryProEdge = {
  __typename?: "PlayerInjuryProEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: PlayerInjuryPro;
};

export type PlayerInjuryProFilterInput = {
  and?: InputMaybe<Array<PlayerInjuryProFilterInput>>;
  club?: InputMaybe<ClubFilterInput>;
  /** School the player was assocated with during injry */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Date for manually created injuries (not sync'd from league) */
  dateTime?: InputMaybe<DateTimeOperationFilterInput>;
  /** Game week injury is associated */
  gameId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Injury body part */
  injury1?: InputMaybe<StringOperationFilterInput>;
  /** Injury body part if more than one */
  injury2?: InputMaybe<StringOperationFilterInput>;
  /** Injury body part with side */
  injuryDesc1?: InputMaybe<StringOperationFilterInput>;
  injuryDesc2?: InputMaybe<StringOperationFilterInput>;
  /** Injury status */
  injuryStatus?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The league's injury id */
  leagueInjuryId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PlayerInjuryProFilterInput>>;
  /** Player associated to injury */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** Practice Day of game week */
  reportDay?: InputMaybe<IntOperationFilterInput>;
  /** Injury context */
  reportType?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  /** Source i.e. League, Scout, etc. */
  source?: InputMaybe<StringOperationFilterInput>;
};

export type PlayerInjuryProSortInput = {
  club?: InputMaybe<ClubSortInput>;
  /** School the player was assocated with during injry */
  clubId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** Date for manually created injuries (not sync'd from league) */
  dateTime?: InputMaybe<SortEnumType>;
  /** Game week injury is associated */
  gameId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Injury body part */
  injury1?: InputMaybe<SortEnumType>;
  /** Injury body part if more than one */
  injury2?: InputMaybe<SortEnumType>;
  /** Injury body part with side */
  injuryDesc1?: InputMaybe<SortEnumType>;
  injuryDesc2?: InputMaybe<SortEnumType>;
  /** Injury status */
  injuryStatus?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The league's injury id */
  leagueInjuryId?: InputMaybe<SortEnumType>;
  /** Player associated to injury */
  playerId?: InputMaybe<SortEnumType>;
  /** Practice Day of game week */
  reportDay?: InputMaybe<SortEnumType>;
  /** Injury context */
  reportType?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  /** Source i.e. League, Scout, etc. */
  source?: InputMaybe<SortEnumType>;
};

/** Player field override. */
export type PlayerOverride = {
  __typename?: "PlayerOverride";
  assignOverride: PlayerOverride;
  created: Scalars["DateTime"];
  /** Field on Player to override. Ex: BirthDate */
  field: Scalars["String"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  player: Player;
  playerId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  /**
   * Value of field on Player to override
   * Ex: 03/24/1997
   */
  value?: Maybe<Scalars["String"]>;
};

/** Player field override. */
export type PlayerOverrideAssignOverrideArgs = {
  details: PlayerOverrideDetailsInput;
};

export type PlayerOverrideDetailsInput = {
  field: Scalars["String"];
  playerId: Scalars["UUID"];
  value?: InputMaybe<Scalars["String"]>;
};

/** Player field override. */
export type PlayerOverrideFilterInput = {
  and?: InputMaybe<Array<PlayerOverrideFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Field on Player to override. Ex: BirthDate */
  field?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerOverrideFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /**
   * Value of field on Player to override
   * Ex: 03/24/1997
   */
  value?: InputMaybe<StringOperationFilterInput>;
};

/** Essentially a depth chart mapping for players in different scheme positions. */
export type PlayerPackagePosition = {
  __typename?: "PlayerPackagePosition";
  created: Scalars["DateTime"];
  /** The location of this player in this position on the team's depth chart */
  depth: Scalars["Decimal"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** One of the positions the player plays. */
  package: Package;
  /** The package the player position belongs */
  packageId?: Maybe<Scalars["UUID"]>;
  /** The player that is in some position */
  player: Player;
  /** Foreign key to Player */
  playerId: Scalars["UUID"];
  /** One of the positions the player plays. */
  position: Position;
  /** Foreign key to Position */
  positionId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
};

/** Essentially a depth chart mapping for players in different scheme positions. */
export type PlayerPackagePositionFilterInput = {
  and?: InputMaybe<Array<PlayerPackagePositionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The location of this player in this position on the team's depth chart */
  depth?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerPackagePositionFilterInput>>;
  /** One of the positions the player plays. */
  package?: InputMaybe<PackageFilterInput>;
  /** The package the player position belongs */
  packageId?: InputMaybe<UuidOperationFilterInput>;
  /** The player that is in some position */
  player?: InputMaybe<PlayerFilterInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** One of the positions the player plays. */
  position?: InputMaybe<PositionFilterInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

/** Essentially a depth chart mapping for players in different scheme positions. */
export type PlayerPackagePositionSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** The location of this player in this position on the team's depth chart */
  depth?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** One of the positions the player plays. */
  package?: InputMaybe<PackageSortInput>;
  /** The package the player position belongs */
  packageId?: InputMaybe<SortEnumType>;
  /** The player that is in some position */
  player?: InputMaybe<PlayerSortInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<SortEnumType>;
  /** One of the positions the player plays. */
  position?: InputMaybe<PositionSortInput>;
  /** Foreign key to Position */
  positionId?: InputMaybe<SortEnumType>;
};

export type PlayerPlay = {
  __typename?: "PlayerPlay";
  assist?: Maybe<Scalars["Boolean"]>;
  ballCarrier?: Maybe<Scalars["Boolean"]>;
  battedPass?: Maybe<Scalars["Boolean"]>;
  contestedTarget?: Maybe<Scalars["Boolean"]>;
  droppedPass?: Maybe<Scalars["Int"]>;
  forcedFumble?: Maybe<Scalars["Boolean"]>;
  game: Game;
  gameId: Scalars["UUID"];
  hit?: Maybe<Scalars["Boolean"]>;
  hurry?: Maybe<Scalars["Boolean"]>;
  interception?: Maybe<Scalars["Boolean"]>;
  interceptionThrown?: Maybe<Scalars["Boolean"]>;
  lastModified: Scalars["DateTime"];
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  missedTackle?: Maybe<Scalars["Boolean"]>;
  passBreakup?: Maybe<Scalars["Boolean"]>;
  passDepth?: Maybe<Scalars["Int"]>;
  passDirection?: Maybe<Scalars["String"]>;
  passResult?: Maybe<Scalars["String"]>;
  pffGrade?: Maybe<Scalars["Float"]>;
  play: Play;
  playId: Scalars["UUID"];
  playerId: Scalars["UUID"];
  pressure?: Maybe<Scalars["Boolean"]>;
  pressureAllowed?: Maybe<Scalars["Boolean"]>;
  primaryCoverage?: Maybe<Scalars["Boolean"]>;
  receivingYards?: Maybe<Scalars["Int"]>;
  reception?: Maybe<Scalars["Boolean"]>;
  rushingYards?: Maybe<Scalars["Int"]>;
  sack?: Maybe<Scalars["Boolean"]>;
  sackAllowed?: Maybe<Scalars["Boolean"]>;
  sideOfBall?: Maybe<Scalars["String"]>;
  tackle?: Maybe<Scalars["Decimal"]>;
  tacklesAvoided?: Maybe<Scalars["Int"]>;
  targetedReceiver?: Maybe<Scalars["Boolean"]>;
  touchdown?: Maybe<Scalars["Boolean"]>;
};

export type PlayerPlayFilterInput = {
  and?: InputMaybe<Array<PlayerPlayFilterInput>>;
  assist?: InputMaybe<BooleanOperationFilterInput>;
  ballCarrier?: InputMaybe<BooleanOperationFilterInput>;
  battedPass?: InputMaybe<BooleanOperationFilterInput>;
  contestedTarget?: InputMaybe<BooleanOperationFilterInput>;
  droppedPass?: InputMaybe<IntOperationFilterInput>;
  forcedFumble?: InputMaybe<BooleanOperationFilterInput>;
  game?: InputMaybe<GameFilterInput>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  hit?: InputMaybe<BooleanOperationFilterInput>;
  hurry?: InputMaybe<BooleanOperationFilterInput>;
  interception?: InputMaybe<BooleanOperationFilterInput>;
  interceptionThrown?: InputMaybe<BooleanOperationFilterInput>;
  lastModified?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  missedTackle?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<PlayerPlayFilterInput>>;
  passBreakup?: InputMaybe<BooleanOperationFilterInput>;
  passDepth?: InputMaybe<IntOperationFilterInput>;
  passDirection?: InputMaybe<StringOperationFilterInput>;
  passResult?: InputMaybe<StringOperationFilterInput>;
  pffGrade?: InputMaybe<FloatOperationFilterInput>;
  play?: InputMaybe<PlayFilterInput>;
  playId?: InputMaybe<UuidOperationFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  pressure?: InputMaybe<BooleanOperationFilterInput>;
  pressureAllowed?: InputMaybe<BooleanOperationFilterInput>;
  primaryCoverage?: InputMaybe<BooleanOperationFilterInput>;
  receivingYards?: InputMaybe<IntOperationFilterInput>;
  reception?: InputMaybe<BooleanOperationFilterInput>;
  rushingYards?: InputMaybe<IntOperationFilterInput>;
  sack?: InputMaybe<BooleanOperationFilterInput>;
  sackAllowed?: InputMaybe<BooleanOperationFilterInput>;
  sideOfBall?: InputMaybe<StringOperationFilterInput>;
  tackle?: InputMaybe<DecimalOperationFilterInput>;
  tacklesAvoided?: InputMaybe<IntOperationFilterInput>;
  targetedReceiver?: InputMaybe<BooleanOperationFilterInput>;
  touchdown?: InputMaybe<BooleanOperationFilterInput>;
};

export type PlayerProjectedGamesMissed = {
  __typename?: "PlayerProjectedGamesMissed";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Ability to override from application */
  override: PlayerProjectedGamesMissed;
  player: Player;
  playerId: Scalars["UUID"];
  /** Value from the ETL */
  projectedGamesMissed: Scalars["Decimal"];
  /** Value for override */
  projectedGamesMissedOverride?: Maybe<Scalars["Decimal"]>;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  /** Update from ETL */
  update: PlayerProjectedGamesMissed;
  value: Scalars["Decimal"];
};

export type PlayerProjectedGamesMissedOverrideArgs = {
  overrideValue?: InputMaybe<Scalars["Decimal"]>;
};

export type PlayerProjectedGamesMissedUpdateArgs = {
  playerId: Scalars["UUID"];
  projectGamesMissed: Scalars["Decimal"];
  season: Scalars["Int"];
};

export type PlayerProjectedGamesMissedFilterInput = {
  and?: InputMaybe<Array<PlayerProjectedGamesMissedFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerProjectedGamesMissedFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  /** Value from the ETL */
  projectedGamesMissed?: InputMaybe<DecimalOperationFilterInput>;
  /** Value for override */
  projectedGamesMissedOverride?: InputMaybe<DecimalOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  value?: InputMaybe<DecimalOperationFilterInput>;
};

/** Encapsulates a flattened view of a player that can be searched against.  Annotated with Azure Search attributes. */
export type PlayerSearchDocument = {
  __typename?: "PlayerSearchDocument";
  /** All alerts this player has on evaluations */
  alertAbbreviations?: Maybe<Array<Scalars["String"]>>;
  /** Highest ProClubGrade or CollegeClubGrade from Grades */
  bestClubGrade?: Maybe<Scalars["Float"]>;
  /** Type of grade present in BestClubGrade, if any. ProClubGrade or CollegeClubGrade */
  bestClubGradeType?: Maybe<Scalars["String"]>;
  /** The code of the club the player belongs to, if any */
  clubCode?: Maybe<Scalars["String"]>;
  /** The ID of the club the player belongs to, if any */
  clubId?: Maybe<Scalars["String"]>;
  /** The full name of the club the player belongs to, if any */
  clubName?: Maybe<Scalars["String"]>;
  /** The player's club position's short name or Sumer position if club is missing */
  displayPositionShortName?: Maybe<Scalars["String"]>;
  /** The year the player was drafted */
  draftYear?: Maybe<Scalars["Int"]>;
  /**
   * College details - college class
   * One of Fr So Jr Sr
   */
  eligibility?: Maybe<Scalars["String"]>;
  /** College details - year eligible for the draft */
  eligibilityYear?: Maybe<Scalars["Int"]>;
  /** All ids of users who have written evaluations against this player */
  evaluationAuthorIds?: Maybe<Array<Scalars["String"]>>;
  /** Player's first name */
  firstName?: Maybe<Scalars["String"]>;
  /** A URL to the player's headshot image */
  headshotUrl?: Maybe<Scalars["String"]>;
  /** The latest height measurement of the player */
  height?: Maybe<Scalars["Int"]>;
  /** The town the player is from */
  hometown?: Maybe<Scalars["String"]>;
  /** Player's internal ID */
  id: Scalars["String"];
  /** The player's current jersey number */
  jersey?: Maybe<Scalars["String"]>;
  /** Player's last name */
  lastName?: Maybe<Scalars["String"]>;
  lastUpdated: Scalars["DateTime"];
  /** Primary fit abbreviation */
  primarySchemeFitAbbreviation?: Maybe<Scalars["String"]>;
  /** The player's primary position name as defined by the club's scheme */
  primarySchemePositionShortName?: Maybe<Scalars["String"]>;
  /** College details - has redshirted */
  redshirt?: Maybe<Scalars["Boolean"]>;
  /** All scheme fit abbreviations that apply for this player */
  schemeFitAbbreviations?: Maybe<Array<Scalars["String"]>>;
  /** The code of the school the player belongs to or last attended */
  schoolCode?: Maybe<Scalars["String"]>;
  /** The ID of the school the player belongs to or last attended */
  schoolId?: Maybe<Scalars["String"]>;
  /** The full name of the school the player belongs to or last attended */
  schoolName?: Maybe<Scalars["String"]>;
  /** The nickname (mascot) of the school the player belongs to or last attended */
  schoolNickname?: Maybe<Scalars["String"]>;
  /** The latest speed measurement of the player */
  speed?: Maybe<Scalars["Float"]>;
  /** Player's current status within the league */
  status?: Maybe<Scalars["String"]>;
  /** The player's sumer atlas position's short name */
  sumerAtlasPositionShortName?: Maybe<Scalars["String"]>;
  /** The player's sumer general position's short name */
  sumerGeneralPositionShortName?: Maybe<Scalars["String"]>;
  /** Player primary evaluation grade */
  sumerPrimaryEvaluationGrade?: Maybe<Scalars["Float"]>;
  /** The latest weight measurement of the player */
  weight?: Maybe<Scalars["Int"]>;
};

/** Encapsulates a flattened view of a player that can be searched against.  Annotated with Azure Search attributes. */
export type PlayerSearchDocumentFilterInput = {
  /** All alerts this player has on evaluations */
  alertAbbreviations?: InputMaybe<ListStringOperationFilterInput>;
  and?: InputMaybe<Array<PlayerSearchDocumentFilterInput>>;
  /** Highest ProClubGrade or CollegeClubGrade from Grades */
  bestClubGrade?: InputMaybe<FloatOperationFilterInput>;
  /** Type of grade present in BestClubGrade, if any. ProClubGrade or CollegeClubGrade */
  bestClubGradeType?: InputMaybe<StringOperationFilterInput>;
  /** The code of the club the player belongs to, if any */
  clubCode?: InputMaybe<StringOperationFilterInput>;
  /** The ID of the club the player belongs to, if any */
  clubId?: InputMaybe<StringOperationFilterInput>;
  /** The full name of the club the player belongs to, if any */
  clubName?: InputMaybe<StringOperationFilterInput>;
  /** The player's club position's short name or Sumer position if club is missing */
  displayPositionShortName?: InputMaybe<StringOperationFilterInput>;
  /** The year the player was drafted */
  draftYear?: InputMaybe<IntOperationFilterInput>;
  /**
   * College details - college class
   * One of Fr So Jr Sr
   */
  eligibility?: InputMaybe<StringOperationFilterInput>;
  /** College details - year eligible for the draft */
  eligibilityYear?: InputMaybe<IntOperationFilterInput>;
  /** All ids of users who have written evaluations against this player */
  evaluationAuthorIds?: InputMaybe<ListStringOperationFilterInput>;
  /** Player's first name */
  firstName?: InputMaybe<StringOperationFilterInput>;
  /** A URL to the player's headshot image */
  headshotUrl?: InputMaybe<StringOperationFilterInput>;
  /** The latest height measurement of the player */
  height?: InputMaybe<IntOperationFilterInput>;
  /** The town the player is from */
  hometown?: InputMaybe<StringOperationFilterInput>;
  /** Player's internal ID */
  id?: InputMaybe<StringOperationFilterInput>;
  /** The player's current jersey number */
  jersey?: InputMaybe<StringOperationFilterInput>;
  /** Player's last name */
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerSearchDocumentFilterInput>>;
  /** Primary fit abbreviation */
  primarySchemeFitAbbreviation?: InputMaybe<StringOperationFilterInput>;
  /** The player's primary position name as defined by the club's scheme */
  primarySchemePositionShortName?: InputMaybe<StringOperationFilterInput>;
  /** College details - has redshirted */
  redshirt?: InputMaybe<BooleanOperationFilterInput>;
  /** All scheme fit abbreviations that apply for this player */
  schemeFitAbbreviations?: InputMaybe<ListStringOperationFilterInput>;
  /** The code of the school the player belongs to or last attended */
  schoolCode?: InputMaybe<StringOperationFilterInput>;
  /** The ID of the school the player belongs to or last attended */
  schoolId?: InputMaybe<StringOperationFilterInput>;
  /** The full name of the school the player belongs to or last attended */
  schoolName?: InputMaybe<StringOperationFilterInput>;
  /** The nickname (mascot) of the school the player belongs to or last attended */
  schoolNickname?: InputMaybe<StringOperationFilterInput>;
  /** The latest speed measurement of the player */
  speed?: InputMaybe<FloatOperationFilterInput>;
  /** Player's current status within the league */
  status?: InputMaybe<StringOperationFilterInput>;
  /** The player's sumer atlas position's short name */
  sumerAtlasPositionShortName?: InputMaybe<StringOperationFilterInput>;
  /** The player's sumer general position's short name */
  sumerGeneralPositionShortName?: InputMaybe<StringOperationFilterInput>;
  /** Player primary evaluation grade */
  sumerPrimaryEvaluationGrade?: InputMaybe<FloatOperationFilterInput>;
  /** The latest weight measurement of the player */
  weight?: InputMaybe<IntOperationFilterInput>;
};

/** Encapsulates a flattened view of a player that can be searched against.  Annotated with Azure Search attributes. */
export type PlayerSearchDocumentSortInput = {
  /** Highest ProClubGrade or CollegeClubGrade from Grades */
  bestClubGrade?: InputMaybe<SortEnumType>;
  /** Type of grade present in BestClubGrade, if any. ProClubGrade or CollegeClubGrade */
  bestClubGradeType?: InputMaybe<SortEnumType>;
  /** The code of the club the player belongs to, if any */
  clubCode?: InputMaybe<SortEnumType>;
  /** The ID of the club the player belongs to, if any */
  clubId?: InputMaybe<SortEnumType>;
  /** The full name of the club the player belongs to, if any */
  clubName?: InputMaybe<SortEnumType>;
  /** The player's club position's short name or Sumer position if club is missing */
  displayPositionShortName?: InputMaybe<SortEnumType>;
  /** The year the player was drafted */
  draftYear?: InputMaybe<SortEnumType>;
  /**
   * College details - college class
   * One of Fr So Jr Sr
   */
  eligibility?: InputMaybe<SortEnumType>;
  /** College details - year eligible for the draft */
  eligibilityYear?: InputMaybe<SortEnumType>;
  /** Player's first name */
  firstName?: InputMaybe<SortEnumType>;
  /** A URL to the player's headshot image */
  headshotUrl?: InputMaybe<SortEnumType>;
  /** The latest height measurement of the player */
  height?: InputMaybe<SortEnumType>;
  /** The town the player is from */
  hometown?: InputMaybe<SortEnumType>;
  /** Player's internal ID */
  id?: InputMaybe<SortEnumType>;
  /** The player's current jersey number */
  jersey?: InputMaybe<SortEnumType>;
  /** Player's last name */
  lastName?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Primary fit abbreviation */
  primarySchemeFitAbbreviation?: InputMaybe<SortEnumType>;
  /** The player's primary position name as defined by the club's scheme */
  primarySchemePositionShortName?: InputMaybe<SortEnumType>;
  /** College details - has redshirted */
  redshirt?: InputMaybe<SortEnumType>;
  /** The code of the school the player belongs to or last attended */
  schoolCode?: InputMaybe<SortEnumType>;
  /** The ID of the school the player belongs to or last attended */
  schoolId?: InputMaybe<SortEnumType>;
  /** The full name of the school the player belongs to or last attended */
  schoolName?: InputMaybe<SortEnumType>;
  /** The nickname (mascot) of the school the player belongs to or last attended */
  schoolNickname?: InputMaybe<SortEnumType>;
  /** The latest speed measurement of the player */
  speed?: InputMaybe<SortEnumType>;
  /** Player's current status within the league */
  status?: InputMaybe<SortEnumType>;
  /** The player's sumer atlas position's short name */
  sumerAtlasPositionShortName?: InputMaybe<SortEnumType>;
  /** The player's sumer general position's short name */
  sumerGeneralPositionShortName?: InputMaybe<SortEnumType>;
  /** Player primary evaluation grade */
  sumerPrimaryEvaluationGrade?: InputMaybe<SortEnumType>;
  /** The latest weight measurement of the player */
  weight?: InputMaybe<SortEnumType>;
};

/** Encapsulates a result set of players from the search appliance. */
export type PlayerSearchResults = {
  __typename?: "PlayerSearchResults";
  /** The collection of matched players, ordered by relevance. */
  players: Array<SearchResultOfPlayerSearchDocument>;
  /** The total number of players that would match the search query (excluding paging parameters) */
  total: Scalars["Long"];
};

/** Encapsulates a result set of players from the search appliance. */
export type PlayerSearchResultsFilterInput = {
  and?: InputMaybe<Array<PlayerSearchResultsFilterInput>>;
  or?: InputMaybe<Array<PlayerSearchResultsFilterInput>>;
  /** The collection of matched players, ordered by relevance. */
  players?: InputMaybe<ListFilterInputTypeOfSearchResultOfPlayerSearchDocumentFilterInput>;
  /** The total number of players that would match the search query (excluding paging parameters) */
  total?: InputMaybe<LongOperationFilterInput>;
};

/** Encapsulates a result set of players from the search appliance. */
export type PlayerSearchResultsSortInput = {
  /** The total number of players that would match the search query (excluding paging parameters) */
  total?: InputMaybe<SortEnumType>;
};

export enum PlayerSearchSortCriteriaEnum {
  ALPHA = "ALPHA",
  CLUB_ALPHA = "CLUB_ALPHA",
  CLUB_POSITION = "CLUB_POSITION",
  SCHOOL_ALPHA = "SCHOOL_ALPHA",
  SCHOOL_POSITION = "SCHOOL_POSITION",
  SUMER_ATLAS_POSITION = "SUMER_ATLAS_POSITION",
  SUMER_GENERAL_POSITION = "SUMER_GENERAL_POSITION",
}

/** A football player. */
export type PlayerSortInput = {
  /**
   * Accrued seasons in an NFL contract refer to the number of seasons a player has been on an active
   * roster or injured reserve for at least six games. These accrued seasons impact a player's eligibility
   * for various benefits, such as unrestricted free agency, pension plans, and the ability to qualify for
   * certain salary escalators or contract guarantees.
   */
  accruedSeasons?: InputMaybe<SortEnumType>;
  agent?: InputMaybe<AgentSortInput>;
  /** Player's agent */
  agentId?: InputMaybe<SortEnumType>;
  /** The day the player was born */
  birthDate?: InputMaybe<SortEnumType>;
  /** The team the player currently plays for */
  club?: InputMaybe<ClubSortInput>;
  /**
   * The position (if any) that the club has graded the player at.  Expectation is that the application
   * will only ingest one graded position for a given player.
   */
  clubGradingPosition?: InputMaybe<PositionSortInput>;
  /** FK ID of the club graded position */
  clubGradingPositionId?: InputMaybe<SortEnumType>;
  /** Foreign key to Club */
  clubId?: InputMaybe<SortEnumType>;
  /** The player's league replication ID */
  collegeGsisId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  draftClub?: InputMaybe<ClubSortInput>;
  /** The club that drafted the player (if drafted) */
  draftClubId?: InputMaybe<SortEnumType>;
  draftRound?: InputMaybe<SortEnumType>;
  draftSelection?: InputMaybe<SortEnumType>;
  /** The year the player was drafted into the NFL */
  draftYear?: InputMaybe<SortEnumType>;
  /** College details - college class */
  eligibility?: InputMaybe<SortEnumType>;
  /** College details - year eligible for the draft */
  eligibilityYear?: InputMaybe<SortEnumType>;
  favorite?: InputMaybe<PlayerFavoriteSortInput>;
  /** The player's legal first name */
  firstName?: InputMaybe<SortEnumType>;
  /** The player's known name or goes by */
  footballName?: InputMaybe<SortEnumType>;
  freeAgencyStatus?: InputMaybe<SortEnumType>;
  /** The player's league replication ID */
  gsisId?: InputMaybe<SortEnumType>;
  /** A URL to the player's headshot image */
  headshotUrl?: InputMaybe<SortEnumType>;
  /** The player's latest height measurement in inches according to pff/league */
  height?: InputMaybe<SortEnumType>;
  /** Year player graduated from high school. Help determines eligibility */
  highSchoolGradYear?: InputMaybe<SortEnumType>;
  highschool?: InputMaybe<SortEnumType>;
  /** Where the player is from */
  hometownCity?: InputMaybe<SortEnumType>;
  hometownCountry?: InputMaybe<SortEnumType>;
  hometownState?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** The player's current jersey number */
  jersey?: InputMaybe<SortEnumType>;
  /** The player's last name */
  lastName?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  latestBirthDate?: InputMaybe<SortEnumType>;
  latestClubId?: InputMaybe<SortEnumType>;
  latestEligibility?: InputMaybe<SortEnumType>;
  latestEligibilityYear?: InputMaybe<SortEnumType>;
  latestHighSchoolGradYear?: InputMaybe<SortEnumType>;
  latestSchoolId?: InputMaybe<SortEnumType>;
  /** The player's College league id (CollegePlayerId - nonprodb.college_player_extract) */
  leagueCollegeId?: InputMaybe<SortEnumType>;
  /** The player's middle name */
  middleName?: InputMaybe<SortEnumType>;
  /**
   * Minimum salary credits refer to the financial benefit given to a team when they sign a player with a
   * higher salary than the league's minimum. These credits offset a portion of the player's salary cap hit,
   * allowing the team to allocate more funds to other player contracts while still meeting the league's salary floor.
   */
  minSalaryCredits?: InputMaybe<SortEnumType>;
  /**
   * The most recent team a player played for
   * Solves issue when a club drops from a roster for reporting
   */
  mostRecentClub?: InputMaybe<ClubSortInput>;
  /** Foreign key to Club */
  mostRecentClubId?: InputMaybe<SortEnumType>;
  /**
   * The most recent team a player played for
   * Solves issue when a player drops from a roster for reporting
   */
  mostRecentSchool?: InputMaybe<SchoolSortInput>;
  /** Foreign key to Club */
  mostRecentSchoolId?: InputMaybe<SortEnumType>;
  namePronunciation?: InputMaybe<SortEnumType>;
  /** The number of years of professional playing experience the player has */
  nflExperience?: InputMaybe<SortEnumType>;
  /** the player's overall and season grades */
  playerGrade?: InputMaybe<PlayerGradeSortInput>;
  /** Credits given each season for a player on the practice squad */
  practiceSquadCredits?: InputMaybe<SortEnumType>;
  /** Player's eligibility for the practice squad */
  practiceSquadEligible?: InputMaybe<SortEnumType>;
  practiceSquadExceptionEligible?: InputMaybe<SortEnumType>;
  practiceSquadInternationalCredits?: InputMaybe<SortEnumType>;
  /** Same practice squad eligibility for international players */
  practiceSquadInternationalEligible?: InputMaybe<SortEnumType>;
  /** College details - has redshirted */
  redshirt?: InputMaybe<SortEnumType>;
  /** The school the player is currently attending (college) or last college of pro player */
  school?: InputMaybe<SchoolSortInput>;
  /** Foreign key to School */
  schoolId?: InputMaybe<SortEnumType>;
  /** The player's latest speed according to pff */
  speed?: InputMaybe<SortEnumType>;
  /** The player's current status */
  status?: InputMaybe<SortEnumType>;
  /** The player's last name suffix */
  suffix?: InputMaybe<SortEnumType>;
  /**
   * The position (if any) of the player in the Sumer atlas position set.  This is a
   * subset of the SumerGeneralPosition that is significantly more granular.
   */
  sumerAtlasPosition?: InputMaybe<PositionSortInput>;
  /** FK ID of the sumer atlas position */
  sumerAtlasPositionId?: InputMaybe<SortEnumType>;
  /** The position (if any) of the player in the Sumer general position set. */
  sumerGeneralPosition?: InputMaybe<PositionSortInput>;
  /** FK ID of the sumer general position */
  sumerGeneralPositionId?: InputMaybe<SortEnumType>;
  /**
   * In the NFL, pension credits are accumulated based on the number of seasons a player is on a team's
   * roster for at least three games. These credits determine the amount of retirement benefits players
   * receive after they retire from professional football. Players become eligible for pension benefits
   * after three credited seasons and the amount is calculated based on their years of service and
   * retirement age. Used to determine the Vet tag. Must be greater than three.
   */
  totalPensionCredits?: InputMaybe<SortEnumType>;
  /** The player's latest weight measurement in pounds according to pff/league */
  weight?: InputMaybe<SortEnumType>;
};

export type PlayerStat = {
  __typename?: "PlayerStat";
  careerStats: Array<CareerStat>;
  defenseStatsBattedPasses?: Maybe<Scalars["Int"]>;
  defenseStatsBurns?: Maybe<Scalars["Int"]>;
  defenseStatsCoverageAttempts?: Maybe<Scalars["Int"]>;
  defenseStatsDefensiveTargets?: Maybe<Scalars["Int"]>;
  defenseStatsDroppedInterceptions?: Maybe<Scalars["Int"]>;
  defenseStatsExtraPointsBlocked?: Maybe<Scalars["Int"]>;
  defenseStatsFieldGoalsBlocked?: Maybe<Scalars["Int"]>;
  defenseStatsFumblesForced?: Maybe<Scalars["Int"]>;
  defenseStatsFumblesRecovered?: Maybe<Scalars["Int"]>;
  defenseStatsFumblesRecoveredForTouchdown?: Maybe<Scalars["Int"]>;
  defenseStatsInjuriesInGame?: Maybe<Scalars["Int"]>;
  defenseStatsInterceptionLongestReturn?: Maybe<Scalars["Int"]>;
  defenseStatsInterceptionPercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsInterceptionTouchdowns?: Maybe<Scalars["Int"]>;
  defenseStatsInterceptionYards?: Maybe<Scalars["Int"]>;
  defenseStatsInterceptions?: Maybe<Scalars["Int"]>;
  defenseStatsMissedTackles?: Maybe<Scalars["Int"]>;
  defenseStatsMissedTacklesOnQb?: Maybe<Scalars["Int"]>;
  defenseStatsPassBreakups?: Maybe<Scalars["Int"]>;
  defenseStatsPassRushAttempts?: Maybe<Scalars["Int"]>;
  defenseStatsPuntsBlocked?: Maybe<Scalars["Int"]>;
  defenseStatsQbHitPercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsQbHits?: Maybe<Scalars["Int"]>;
  defenseStatsQbHurries?: Maybe<Scalars["Int"]>;
  defenseStatsQbHurryPercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsQbPressurePercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsQbPressures?: Maybe<Scalars["Int"]>;
  defenseStatsSackPercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsSackPlays?: Maybe<Scalars["Int"]>;
  defenseStatsSacks?: Maybe<Scalars["Decimal"]>;
  defenseStatsSnapPercentage?: Maybe<Scalars["Decimal"]>;
  defenseStatsSnaps?: Maybe<Scalars["Int"]>;
  defenseStatsStops?: Maybe<Scalars["Int"]>;
  defenseStatsTacklesAssisted?: Maybe<Scalars["Int"]>;
  defenseStatsTacklesCombined?: Maybe<Scalars["Int"]>;
  defenseStatsTacklesForLoss?: Maybe<Scalars["Decimal"]>;
  defenseStatsTacklesSolo?: Maybe<Scalars["Int"]>;
  defenseStatsTeamSnaps?: Maybe<Scalars["Int"]>;
  defenseStatsTouchdowns?: Maybe<Scalars["Int"]>;
  defensiveSnaps?: Maybe<Scalars["Int"]>;
  defensiveSnapsPercentage?: Maybe<Scalars["Decimal"]>;
  defensiveTeamSnaps?: Maybe<Scalars["Int"]>;
  gameId: Scalars["UUID"];
  gameStats: Array<GameStat>;
  gamesMissed?: Maybe<Scalars["Int"]>;
  gamesPlayed?: Maybe<Scalars["Int"]>;
  gamesStarted?: Maybe<Scalars["Int"]>;
  kickingStatsBlockedFieldGoalsAndExtraPoints?: Maybe<Scalars["Int"]>;
  kickingStatsExtraPointAttempts?: Maybe<Scalars["Int"]>;
  kickingStatsExtraPointsBlocked?: Maybe<Scalars["Int"]>;
  kickingStatsExtraPointsMade?: Maybe<Scalars["Int"]>;
  kickingStatsExtraPointsPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoalAttempts?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoalMadeLongest?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoalPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoalSnapPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoalSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoalTeamSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoalYardsAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals1To19Attempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals1To19Made?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals1To19Percentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals20To29Attempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals20To29Made?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals20To29Percentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals30To39Attempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals30To39Made?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals30To39Percentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals40To49Attempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals40To49Made?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals40To49Percentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals50To59Attempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals50To59Made?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals50To59Percentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoals60PlusAttempted?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals60PlusMade?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoals60PlusPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsFieldGoalsBlocked?: Maybe<Scalars["Int"]>;
  kickingStatsFieldGoalsMade?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffAttempts?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffDepthAttempts?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffDepthAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsKickoffOnsideKicks?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffOnsideKicksRecovered?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffReturnLong?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffReturnTouchdowns?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffReturnYards?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffReturnYardsAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsKickoffSnapPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsKickoffSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffTeamSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffTouchbackPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsKickoffTouchbacks?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffYards?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffYardsAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsKickoffsOutOfBounds?: Maybe<Scalars["Int"]>;
  kickingStatsKickoffsReturned?: Maybe<Scalars["Int"]>;
  kickingStatsPuntAttempts?: Maybe<Scalars["Int"]>;
  kickingStatsPuntHangTimeAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntOperationTimeAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntReturnTouchdowns?: Maybe<Scalars["Int"]>;
  kickingStatsPuntReturnYards?: Maybe<Scalars["Int"]>;
  kickingStatsPuntReturnYardsAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntSnapPercentage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsPuntTeamSnaps?: Maybe<Scalars["Int"]>;
  kickingStatsPuntYards?: Maybe<Scalars["Int"]>;
  kickingStatsPuntYardsAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntYardsLongest?: Maybe<Scalars["Int"]>;
  kickingStatsPuntYardsNet?: Maybe<Scalars["Int"]>;
  kickingStatsPuntYardsNetAverage?: Maybe<Scalars["Decimal"]>;
  kickingStatsPuntsBlocked?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsDowned?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsFairCaught?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsIn20?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsOutOfBounds?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsReturned?: Maybe<Scalars["Int"]>;
  kickingStatsPuntsTouchbacks?: Maybe<Scalars["Int"]>;
  lastUpdated: Scalars["DateTime"];
  leagueTypeId: Scalars["Int"];
  offensiveLinePressuresAllowed?: Maybe<Scalars["Int"]>;
  offensiveLineSacksAllowed?: Maybe<Scalars["Int"]>;
  offensiveLineScreenBlocks?: Maybe<Scalars["Int"]>;
  offensiveSnaps?: Maybe<Scalars["Int"]>;
  offensiveSnapsPercentage?: Maybe<Scalars["Decimal"]>;
  offensiveTeamSnaps?: Maybe<Scalars["Int"]>;
  passingStatsAttempts?: Maybe<Scalars["Int"]>;
  passingStatsCatchableThrowPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsCatchableThrows?: Maybe<Scalars["Int"]>;
  passingStatsCompletionPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsCompletions?: Maybe<Scalars["Int"]>;
  passingStatsCompletionsFortyYardsPlus?: Maybe<Scalars["Int"]>;
  passingStatsCompletionsTwentyYardsPlus?: Maybe<Scalars["Int"]>;
  passingStatsDropbackAttempts?: Maybe<Scalars["Int"]>;
  passingStatsDropbackDepthAverage?: Maybe<Scalars["Decimal"]>;
  passingStatsDrops?: Maybe<Scalars["Int"]>;
  passingStatsFirstDownPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsFirstDowns?: Maybe<Scalars["Int"]>;
  passingStatsFumbles?: Maybe<Scalars["Int"]>;
  passingStatsFumblesRecovered?: Maybe<Scalars["Int"]>;
  passingStatsHurriesAllowed?: Maybe<Scalars["Int"]>;
  passingStatsInjuriesInGame?: Maybe<Scalars["Int"]>;
  passingStatsInterceptionPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsInterceptions?: Maybe<Scalars["Int"]>;
  passingStatsLongestCompletion?: Maybe<Scalars["Int"]>;
  passingStatsPassDepthAttempts?: Maybe<Scalars["Int"]>;
  passingStatsPassDepthAverage?: Maybe<Scalars["Decimal"]>;
  passingStatsPasserRating?: Maybe<Scalars["Decimal"]>;
  passingStatsPassingEfficiency?: Maybe<Scalars["Decimal"]>;
  passingStatsPenalties?: Maybe<Scalars["Int"]>;
  passingStatsPumpFakes?: Maybe<Scalars["Int"]>;
  passingStatsSackYards?: Maybe<Scalars["Int"]>;
  passingStatsSacks?: Maybe<Scalars["Int"]>;
  passingStatsSnapPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsSnaps?: Maybe<Scalars["Int"]>;
  passingStatsTeamSnaps?: Maybe<Scalars["Int"]>;
  passingStatsTimeToPressureAttempts?: Maybe<Scalars["Int"]>;
  passingStatsTimeToPressureAverage?: Maybe<Scalars["Decimal"]>;
  passingStatsTimeToThrowAttempts?: Maybe<Scalars["Int"]>;
  passingStatsTimeToThrowAverage?: Maybe<Scalars["Decimal"]>;
  passingStatsTouchdownInterceptionRatio?: Maybe<Scalars["Decimal"]>;
  passingStatsTouchdownPercentage?: Maybe<Scalars["Decimal"]>;
  passingStatsTouchdowns?: Maybe<Scalars["Int"]>;
  passingStatsTwoPointAttempts?: Maybe<Scalars["Int"]>;
  passingStatsTwoPointConversions?: Maybe<Scalars["Int"]>;
  passingStatsYards?: Maybe<Scalars["Int"]>;
  passingStatsYardsAfterCatch?: Maybe<Scalars["Int"]>;
  passingStatsYardsPerAttempt?: Maybe<Scalars["Decimal"]>;
  passingStatsYardsPerCompletionAverage?: Maybe<Scalars["Decimal"]>;
  penalties?: Maybe<Scalars["Int"]>;
  playerId: Scalars["UUID"];
  receivingStatsCatchableThrows?: Maybe<Scalars["Int"]>;
  receivingStatsDrops?: Maybe<Scalars["Int"]>;
  receivingStatsDropsPercentage?: Maybe<Scalars["Decimal"]>;
  receivingStatsFirstDownPercentage?: Maybe<Scalars["Decimal"]>;
  receivingStatsFirstDowns?: Maybe<Scalars["Int"]>;
  receivingStatsInjuriesInGame?: Maybe<Scalars["Int"]>;
  receivingStatsLongestReception?: Maybe<Scalars["Int"]>;
  receivingStatsPassRouteDepthAverage?: Maybe<Scalars["Decimal"]>;
  receivingStatsPassRoutesRun?: Maybe<Scalars["Int"]>;
  receivingStatsPenalties?: Maybe<Scalars["Int"]>;
  receivingStatsReceivingFumbles?: Maybe<Scalars["Int"]>;
  receivingStatsReceptions?: Maybe<Scalars["Int"]>;
  receivingStatsReceptionsFortyYardsPlus?: Maybe<Scalars["Int"]>;
  receivingStatsReceptionsPerTouchdown?: Maybe<Scalars["Decimal"]>;
  receivingStatsReceptionsTwentyYardsPlus?: Maybe<Scalars["Int"]>;
  receivingStatsSnapPercentage?: Maybe<Scalars["Decimal"]>;
  receivingStatsSnaps?: Maybe<Scalars["Int"]>;
  receivingStatsTacklesAvoided?: Maybe<Scalars["Int"]>;
  receivingStatsTargets?: Maybe<Scalars["Int"]>;
  receivingStatsTeamSnaps?: Maybe<Scalars["Int"]>;
  receivingStatsTouchdowns?: Maybe<Scalars["Int"]>;
  receivingStatsTwoPointAttempts?: Maybe<Scalars["Int"]>;
  receivingStatsTwoPointConversions?: Maybe<Scalars["Int"]>;
  receivingStatsYardAfterCatchPerReception?: Maybe<Scalars["Decimal"]>;
  receivingStatsYards?: Maybe<Scalars["Int"]>;
  receivingStatsYardsAfterCatch?: Maybe<Scalars["Int"]>;
  receivingStatsYardsAfterContact?: Maybe<Scalars["Int"]>;
  receivingStatsYardsPerReception?: Maybe<Scalars["Decimal"]>;
  returnStatsKickReturnAttempts?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnFairCatches?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnFumbles?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnLongestYards?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnSnapPercentage?: Maybe<Scalars["Decimal"]>;
  returnStatsKickReturnSnaps?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnTeamSnaps?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnTouchdowns?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnYards?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnYardsAfterContact?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnYardsAfterContactAverage?: Maybe<Scalars["Decimal"]>;
  returnStatsKickReturnYardsAverage?: Maybe<Scalars["Decimal"]>;
  returnStatsKickReturnsFortyYardsPlus?: Maybe<Scalars["Int"]>;
  returnStatsKickReturnsTwentyYardsPlus?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnAttempts?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnFairCatches?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnFumbles?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnLongestYards?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnSnapPercentage?: Maybe<Scalars["Decimal"]>;
  returnStatsPuntReturnSnaps?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnTeamSnaps?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnTouchdowns?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnYards?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnYardsAfterContact?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnYardsAfterContactAverage?: Maybe<Scalars["Decimal"]>;
  returnStatsPuntReturnYardsAverage?: Maybe<Scalars["Decimal"]>;
  returnStatsPuntReturnsFortyYardsPlus?: Maybe<Scalars["Int"]>;
  returnStatsPuntReturnsTwentyYardsPlus?: Maybe<Scalars["Int"]>;
  rowVersion: Array<Scalars["Byte"]>;
  rushingStatsAttempts?: Maybe<Scalars["Int"]>;
  rushingStatsFirstDownPercentage?: Maybe<Scalars["Decimal"]>;
  rushingStatsFirstDownRushes?: Maybe<Scalars["Int"]>;
  rushingStatsFumbles?: Maybe<Scalars["Int"]>;
  rushingStatsFumblesLost?: Maybe<Scalars["Int"]>;
  rushingStatsFumblesRecovered?: Maybe<Scalars["Int"]>;
  rushingStatsHurriesAllowed?: Maybe<Scalars["Int"]>;
  rushingStatsInjuriesInGame?: Maybe<Scalars["Int"]>;
  rushingStatsLongestRun?: Maybe<Scalars["Int"]>;
  rushingStatsPenalties?: Maybe<Scalars["Int"]>;
  rushingStatsPressuresAllowed?: Maybe<Scalars["Int"]>;
  rushingStatsRunsFortyYardsPlus?: Maybe<Scalars["Int"]>;
  rushingStatsRunsTwentyYardsPlus?: Maybe<Scalars["Int"]>;
  rushingStatsSacksAllowed?: Maybe<Scalars["Int"]>;
  rushingStatsSnapPercentage?: Maybe<Scalars["Decimal"]>;
  rushingStatsSnaps?: Maybe<Scalars["Int"]>;
  rushingStatsTacklesAvoided?: Maybe<Scalars["Int"]>;
  rushingStatsTeamSnaps?: Maybe<Scalars["Int"]>;
  rushingStatsTouchdowns?: Maybe<Scalars["Int"]>;
  rushingStatsTwoPointAttempts?: Maybe<Scalars["Int"]>;
  rushingStatsTwoPointConversions?: Maybe<Scalars["Int"]>;
  rushingStatsYards?: Maybe<Scalars["Int"]>;
  rushingStatsYardsAfterContact?: Maybe<Scalars["Int"]>;
  rushingStatsYardsAfterContactPerRush?: Maybe<Scalars["Decimal"]>;
  rushingStatsYardsPerRush?: Maybe<Scalars["Decimal"]>;
  season: Scalars["Int"];
  seasonStats: Array<SeasonStat>;
  seasonTypeId: Scalars["Int"];
  specialTeamsSnaps?: Maybe<Scalars["Int"]>;
  specialTeamsSnapsPercentage?: Maybe<Scalars["Decimal"]>;
  specialTeamsTeamSnaps?: Maybe<Scalars["Int"]>;
  statTypeId: Scalars["Int"];
};

export type PlayerStatFilterInput = {
  and?: InputMaybe<Array<PlayerStatFilterInput>>;
  careerStats?: InputMaybe<ListFilterInputTypeOfCareerStatFilterInput>;
  defenseStatsBattedPasses?: InputMaybe<IntOperationFilterInput>;
  defenseStatsBurns?: InputMaybe<IntOperationFilterInput>;
  defenseStatsCoverageAttempts?: InputMaybe<IntOperationFilterInput>;
  defenseStatsDefensiveTargets?: InputMaybe<IntOperationFilterInput>;
  defenseStatsDroppedInterceptions?: InputMaybe<IntOperationFilterInput>;
  defenseStatsExtraPointsBlocked?: InputMaybe<IntOperationFilterInput>;
  defenseStatsFieldGoalsBlocked?: InputMaybe<IntOperationFilterInput>;
  defenseStatsFumblesForced?: InputMaybe<IntOperationFilterInput>;
  defenseStatsFumblesRecovered?: InputMaybe<IntOperationFilterInput>;
  defenseStatsFumblesRecoveredForTouchdown?: InputMaybe<IntOperationFilterInput>;
  defenseStatsInjuriesInGame?: InputMaybe<IntOperationFilterInput>;
  defenseStatsInterceptionLongestReturn?: InputMaybe<IntOperationFilterInput>;
  defenseStatsInterceptionPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsInterceptionTouchdowns?: InputMaybe<IntOperationFilterInput>;
  defenseStatsInterceptionYards?: InputMaybe<IntOperationFilterInput>;
  defenseStatsInterceptions?: InputMaybe<IntOperationFilterInput>;
  defenseStatsMissedTackles?: InputMaybe<IntOperationFilterInput>;
  defenseStatsMissedTacklesOnQb?: InputMaybe<IntOperationFilterInput>;
  defenseStatsPassBreakups?: InputMaybe<IntOperationFilterInput>;
  defenseStatsPassRushAttempts?: InputMaybe<IntOperationFilterInput>;
  defenseStatsPuntsBlocked?: InputMaybe<IntOperationFilterInput>;
  defenseStatsQbHitPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsQbHits?: InputMaybe<IntOperationFilterInput>;
  defenseStatsQbHurries?: InputMaybe<IntOperationFilterInput>;
  defenseStatsQbHurryPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsQbPressurePercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsQbPressures?: InputMaybe<IntOperationFilterInput>;
  defenseStatsSackPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsSackPlays?: InputMaybe<IntOperationFilterInput>;
  defenseStatsSacks?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsSnaps?: InputMaybe<IntOperationFilterInput>;
  defenseStatsStops?: InputMaybe<IntOperationFilterInput>;
  defenseStatsTacklesAssisted?: InputMaybe<IntOperationFilterInput>;
  defenseStatsTacklesCombined?: InputMaybe<IntOperationFilterInput>;
  defenseStatsTacklesForLoss?: InputMaybe<DecimalOperationFilterInput>;
  defenseStatsTacklesSolo?: InputMaybe<IntOperationFilterInput>;
  defenseStatsTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  defenseStatsTouchdowns?: InputMaybe<IntOperationFilterInput>;
  defensiveSnaps?: InputMaybe<IntOperationFilterInput>;
  defensiveSnapsPercentage?: InputMaybe<DecimalOperationFilterInput>;
  defensiveTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  gamesMissed?: InputMaybe<IntOperationFilterInput>;
  gamesPlayed?: InputMaybe<IntOperationFilterInput>;
  gamesStarted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsBlockedFieldGoalsAndExtraPoints?: InputMaybe<IntOperationFilterInput>;
  kickingStatsExtraPointAttempts?: InputMaybe<IntOperationFilterInput>;
  kickingStatsExtraPointsBlocked?: InputMaybe<IntOperationFilterInput>;
  kickingStatsExtraPointsMade?: InputMaybe<IntOperationFilterInput>;
  kickingStatsExtraPointsPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoalAttempts?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoalMadeLongest?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoalPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoalSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoalSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoalTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoalYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals1To19Attempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals1To19Made?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals1To19Percentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals20To29Attempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals20To29Made?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals20To29Percentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals30To39Attempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals30To39Made?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals30To39Percentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals40To49Attempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals40To49Made?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals40To49Percentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals50To59Attempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals50To59Made?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals50To59Percentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoals60PlusAttempted?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals60PlusMade?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoals60PlusPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsFieldGoalsBlocked?: InputMaybe<IntOperationFilterInput>;
  kickingStatsFieldGoalsMade?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffAttempts?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffDepthAttempts?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffDepthAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsKickoffOnsideKicks?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffOnsideKicksRecovered?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffReturnLong?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffReturnTouchdowns?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffReturnYards?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffReturnYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsKickoffSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsKickoffSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffTouchbackPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsKickoffTouchbacks?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffYards?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsKickoffsOutOfBounds?: InputMaybe<IntOperationFilterInput>;
  kickingStatsKickoffsReturned?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntAttempts?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntHangTimeAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntOperationTimeAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntReturnTouchdowns?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntReturnYards?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntReturnYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntYards?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntYardsLongest?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntYardsNet?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntYardsNetAverage?: InputMaybe<DecimalOperationFilterInput>;
  kickingStatsPuntsBlocked?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsDowned?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsFairCaught?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsIn20?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsOutOfBounds?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsReturned?: InputMaybe<IntOperationFilterInput>;
  kickingStatsPuntsTouchbacks?: InputMaybe<IntOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  offensiveLinePressuresAllowed?: InputMaybe<IntOperationFilterInput>;
  offensiveLineSacksAllowed?: InputMaybe<IntOperationFilterInput>;
  offensiveLineScreenBlocks?: InputMaybe<IntOperationFilterInput>;
  offensiveSnaps?: InputMaybe<IntOperationFilterInput>;
  offensiveSnapsPercentage?: InputMaybe<DecimalOperationFilterInput>;
  offensiveTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<PlayerStatFilterInput>>;
  passingStatsAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsCatchableThrowPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsCatchableThrows?: InputMaybe<IntOperationFilterInput>;
  passingStatsCompletionPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsCompletions?: InputMaybe<IntOperationFilterInput>;
  passingStatsCompletionsFortyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  passingStatsCompletionsTwentyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  passingStatsDropbackAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsDropbackDepthAverage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsDrops?: InputMaybe<IntOperationFilterInput>;
  passingStatsFirstDownPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsFirstDowns?: InputMaybe<IntOperationFilterInput>;
  passingStatsFumbles?: InputMaybe<IntOperationFilterInput>;
  passingStatsFumblesRecovered?: InputMaybe<IntOperationFilterInput>;
  passingStatsHurriesAllowed?: InputMaybe<IntOperationFilterInput>;
  passingStatsInjuriesInGame?: InputMaybe<IntOperationFilterInput>;
  passingStatsInterceptionPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsInterceptions?: InputMaybe<IntOperationFilterInput>;
  passingStatsLongestCompletion?: InputMaybe<IntOperationFilterInput>;
  passingStatsPassDepthAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsPassDepthAverage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsPasserRating?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsPassingEfficiency?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsPenalties?: InputMaybe<IntOperationFilterInput>;
  passingStatsPumpFakes?: InputMaybe<IntOperationFilterInput>;
  passingStatsSackYards?: InputMaybe<IntOperationFilterInput>;
  passingStatsSacks?: InputMaybe<IntOperationFilterInput>;
  passingStatsSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsSnaps?: InputMaybe<IntOperationFilterInput>;
  passingStatsTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  passingStatsTimeToPressureAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsTimeToPressureAverage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsTimeToThrowAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsTimeToThrowAverage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsTouchdownInterceptionRatio?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsTouchdownPercentage?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsTouchdowns?: InputMaybe<IntOperationFilterInput>;
  passingStatsTwoPointAttempts?: InputMaybe<IntOperationFilterInput>;
  passingStatsTwoPointConversions?: InputMaybe<IntOperationFilterInput>;
  passingStatsYards?: InputMaybe<IntOperationFilterInput>;
  passingStatsYardsAfterCatch?: InputMaybe<IntOperationFilterInput>;
  passingStatsYardsPerAttempt?: InputMaybe<DecimalOperationFilterInput>;
  passingStatsYardsPerCompletionAverage?: InputMaybe<DecimalOperationFilterInput>;
  penalties?: InputMaybe<IntOperationFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  receivingStatsCatchableThrows?: InputMaybe<IntOperationFilterInput>;
  receivingStatsDrops?: InputMaybe<IntOperationFilterInput>;
  receivingStatsDropsPercentage?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsFirstDownPercentage?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsFirstDowns?: InputMaybe<IntOperationFilterInput>;
  receivingStatsInjuriesInGame?: InputMaybe<IntOperationFilterInput>;
  receivingStatsLongestReception?: InputMaybe<IntOperationFilterInput>;
  receivingStatsPassRouteDepthAverage?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsPassRoutesRun?: InputMaybe<IntOperationFilterInput>;
  receivingStatsPenalties?: InputMaybe<IntOperationFilterInput>;
  receivingStatsReceivingFumbles?: InputMaybe<IntOperationFilterInput>;
  receivingStatsReceptions?: InputMaybe<IntOperationFilterInput>;
  receivingStatsReceptionsFortyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  receivingStatsReceptionsPerTouchdown?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsReceptionsTwentyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  receivingStatsSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsSnaps?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTacklesAvoided?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTargets?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTouchdowns?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTwoPointAttempts?: InputMaybe<IntOperationFilterInput>;
  receivingStatsTwoPointConversions?: InputMaybe<IntOperationFilterInput>;
  receivingStatsYardAfterCatchPerReception?: InputMaybe<DecimalOperationFilterInput>;
  receivingStatsYards?: InputMaybe<IntOperationFilterInput>;
  receivingStatsYardsAfterCatch?: InputMaybe<IntOperationFilterInput>;
  receivingStatsYardsAfterContact?: InputMaybe<IntOperationFilterInput>;
  receivingStatsYardsPerReception?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsKickReturnAttempts?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnFairCatches?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnFumbles?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnLongestYards?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsKickReturnSnaps?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnTouchdowns?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnYards?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnYardsAfterContact?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnYardsAfterContactAverage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsKickReturnYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsKickReturnsFortyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  returnStatsKickReturnsTwentyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnAttempts?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnFairCatches?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnFumbles?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnLongestYards?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsPuntReturnSnaps?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnTouchdowns?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnYards?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnYardsAfterContact?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnYardsAfterContactAverage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsPuntReturnYardsAverage?: InputMaybe<DecimalOperationFilterInput>;
  returnStatsPuntReturnsFortyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  returnStatsPuntReturnsTwentyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  rushingStatsAttempts?: InputMaybe<IntOperationFilterInput>;
  rushingStatsFirstDownPercentage?: InputMaybe<DecimalOperationFilterInput>;
  rushingStatsFirstDownRushes?: InputMaybe<IntOperationFilterInput>;
  rushingStatsFumbles?: InputMaybe<IntOperationFilterInput>;
  rushingStatsFumblesLost?: InputMaybe<IntOperationFilterInput>;
  rushingStatsFumblesRecovered?: InputMaybe<IntOperationFilterInput>;
  rushingStatsHurriesAllowed?: InputMaybe<IntOperationFilterInput>;
  rushingStatsInjuriesInGame?: InputMaybe<IntOperationFilterInput>;
  rushingStatsLongestRun?: InputMaybe<IntOperationFilterInput>;
  rushingStatsPenalties?: InputMaybe<IntOperationFilterInput>;
  rushingStatsPressuresAllowed?: InputMaybe<IntOperationFilterInput>;
  rushingStatsRunsFortyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  rushingStatsRunsTwentyYardsPlus?: InputMaybe<IntOperationFilterInput>;
  rushingStatsSacksAllowed?: InputMaybe<IntOperationFilterInput>;
  rushingStatsSnapPercentage?: InputMaybe<DecimalOperationFilterInput>;
  rushingStatsSnaps?: InputMaybe<IntOperationFilterInput>;
  rushingStatsTacklesAvoided?: InputMaybe<IntOperationFilterInput>;
  rushingStatsTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  rushingStatsTouchdowns?: InputMaybe<IntOperationFilterInput>;
  rushingStatsTwoPointAttempts?: InputMaybe<IntOperationFilterInput>;
  rushingStatsTwoPointConversions?: InputMaybe<IntOperationFilterInput>;
  rushingStatsYards?: InputMaybe<IntOperationFilterInput>;
  rushingStatsYardsAfterContact?: InputMaybe<IntOperationFilterInput>;
  rushingStatsYardsAfterContactPerRush?: InputMaybe<DecimalOperationFilterInput>;
  rushingStatsYardsPerRush?: InputMaybe<DecimalOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonStats?: InputMaybe<ListFilterInputTypeOfSeasonStatFilterInput>;
  seasonTypeId?: InputMaybe<IntOperationFilterInput>;
  specialTeamsSnaps?: InputMaybe<IntOperationFilterInput>;
  specialTeamsSnapsPercentage?: InputMaybe<DecimalOperationFilterInput>;
  specialTeamsTeamSnaps?: InputMaybe<IntOperationFilterInput>;
  statTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type PlayerStatSortInput = {
  defenseStatsBattedPasses?: InputMaybe<SortEnumType>;
  defenseStatsBurns?: InputMaybe<SortEnumType>;
  defenseStatsCoverageAttempts?: InputMaybe<SortEnumType>;
  defenseStatsDefensiveTargets?: InputMaybe<SortEnumType>;
  defenseStatsDroppedInterceptions?: InputMaybe<SortEnumType>;
  defenseStatsExtraPointsBlocked?: InputMaybe<SortEnumType>;
  defenseStatsFieldGoalsBlocked?: InputMaybe<SortEnumType>;
  defenseStatsFumblesForced?: InputMaybe<SortEnumType>;
  defenseStatsFumblesRecovered?: InputMaybe<SortEnumType>;
  defenseStatsFumblesRecoveredForTouchdown?: InputMaybe<SortEnumType>;
  defenseStatsInjuriesInGame?: InputMaybe<SortEnumType>;
  defenseStatsInterceptionLongestReturn?: InputMaybe<SortEnumType>;
  defenseStatsInterceptionPercentage?: InputMaybe<SortEnumType>;
  defenseStatsInterceptionTouchdowns?: InputMaybe<SortEnumType>;
  defenseStatsInterceptionYards?: InputMaybe<SortEnumType>;
  defenseStatsInterceptions?: InputMaybe<SortEnumType>;
  defenseStatsMissedTackles?: InputMaybe<SortEnumType>;
  defenseStatsMissedTacklesOnQb?: InputMaybe<SortEnumType>;
  defenseStatsPassBreakups?: InputMaybe<SortEnumType>;
  defenseStatsPassRushAttempts?: InputMaybe<SortEnumType>;
  defenseStatsPuntsBlocked?: InputMaybe<SortEnumType>;
  defenseStatsQbHitPercentage?: InputMaybe<SortEnumType>;
  defenseStatsQbHits?: InputMaybe<SortEnumType>;
  defenseStatsQbHurries?: InputMaybe<SortEnumType>;
  defenseStatsQbHurryPercentage?: InputMaybe<SortEnumType>;
  defenseStatsQbPressurePercentage?: InputMaybe<SortEnumType>;
  defenseStatsQbPressures?: InputMaybe<SortEnumType>;
  defenseStatsSackPercentage?: InputMaybe<SortEnumType>;
  defenseStatsSackPlays?: InputMaybe<SortEnumType>;
  defenseStatsSacks?: InputMaybe<SortEnumType>;
  defenseStatsSnapPercentage?: InputMaybe<SortEnumType>;
  defenseStatsSnaps?: InputMaybe<SortEnumType>;
  defenseStatsStops?: InputMaybe<SortEnumType>;
  defenseStatsTacklesAssisted?: InputMaybe<SortEnumType>;
  defenseStatsTacklesCombined?: InputMaybe<SortEnumType>;
  defenseStatsTacklesForLoss?: InputMaybe<SortEnumType>;
  defenseStatsTacklesSolo?: InputMaybe<SortEnumType>;
  defenseStatsTeamSnaps?: InputMaybe<SortEnumType>;
  defenseStatsTouchdowns?: InputMaybe<SortEnumType>;
  defensiveSnaps?: InputMaybe<SortEnumType>;
  defensiveSnapsPercentage?: InputMaybe<SortEnumType>;
  defensiveTeamSnaps?: InputMaybe<SortEnumType>;
  gameId?: InputMaybe<SortEnumType>;
  gamesMissed?: InputMaybe<SortEnumType>;
  gamesPlayed?: InputMaybe<SortEnumType>;
  gamesStarted?: InputMaybe<SortEnumType>;
  kickingStatsBlockedFieldGoalsAndExtraPoints?: InputMaybe<SortEnumType>;
  kickingStatsExtraPointAttempts?: InputMaybe<SortEnumType>;
  kickingStatsExtraPointsBlocked?: InputMaybe<SortEnumType>;
  kickingStatsExtraPointsMade?: InputMaybe<SortEnumType>;
  kickingStatsExtraPointsPercentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalAttempts?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalMadeLongest?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalPercentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalSnapPercentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalSnaps?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalTeamSnaps?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalYardsAverage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals1To19Attempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals1To19Made?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals1To19Percentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals20To29Attempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals20To29Made?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals20To29Percentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals30To39Attempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals30To39Made?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals30To39Percentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals40To49Attempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals40To49Made?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals40To49Percentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals50To59Attempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals50To59Made?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals50To59Percentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals60PlusAttempted?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals60PlusMade?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoals60PlusPercentage?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalsBlocked?: InputMaybe<SortEnumType>;
  kickingStatsFieldGoalsMade?: InputMaybe<SortEnumType>;
  kickingStatsKickoffAttempts?: InputMaybe<SortEnumType>;
  kickingStatsKickoffDepthAttempts?: InputMaybe<SortEnumType>;
  kickingStatsKickoffDepthAverage?: InputMaybe<SortEnumType>;
  kickingStatsKickoffOnsideKicks?: InputMaybe<SortEnumType>;
  kickingStatsKickoffOnsideKicksRecovered?: InputMaybe<SortEnumType>;
  kickingStatsKickoffReturnLong?: InputMaybe<SortEnumType>;
  kickingStatsKickoffReturnTouchdowns?: InputMaybe<SortEnumType>;
  kickingStatsKickoffReturnYards?: InputMaybe<SortEnumType>;
  kickingStatsKickoffReturnYardsAverage?: InputMaybe<SortEnumType>;
  kickingStatsKickoffSnapPercentage?: InputMaybe<SortEnumType>;
  kickingStatsKickoffSnaps?: InputMaybe<SortEnumType>;
  kickingStatsKickoffTeamSnaps?: InputMaybe<SortEnumType>;
  kickingStatsKickoffTouchbackPercentage?: InputMaybe<SortEnumType>;
  kickingStatsKickoffTouchbacks?: InputMaybe<SortEnumType>;
  kickingStatsKickoffYards?: InputMaybe<SortEnumType>;
  kickingStatsKickoffYardsAverage?: InputMaybe<SortEnumType>;
  kickingStatsKickoffsOutOfBounds?: InputMaybe<SortEnumType>;
  kickingStatsKickoffsReturned?: InputMaybe<SortEnumType>;
  kickingStatsPuntAttempts?: InputMaybe<SortEnumType>;
  kickingStatsPuntHangTimeAverage?: InputMaybe<SortEnumType>;
  kickingStatsPuntOperationTimeAverage?: InputMaybe<SortEnumType>;
  kickingStatsPuntReturnTouchdowns?: InputMaybe<SortEnumType>;
  kickingStatsPuntReturnYards?: InputMaybe<SortEnumType>;
  kickingStatsPuntReturnYardsAverage?: InputMaybe<SortEnumType>;
  kickingStatsPuntSnapPercentage?: InputMaybe<SortEnumType>;
  kickingStatsPuntSnaps?: InputMaybe<SortEnumType>;
  kickingStatsPuntTeamSnaps?: InputMaybe<SortEnumType>;
  kickingStatsPuntYards?: InputMaybe<SortEnumType>;
  kickingStatsPuntYardsAverage?: InputMaybe<SortEnumType>;
  kickingStatsPuntYardsLongest?: InputMaybe<SortEnumType>;
  kickingStatsPuntYardsNet?: InputMaybe<SortEnumType>;
  kickingStatsPuntYardsNetAverage?: InputMaybe<SortEnumType>;
  kickingStatsPuntsBlocked?: InputMaybe<SortEnumType>;
  kickingStatsPuntsDowned?: InputMaybe<SortEnumType>;
  kickingStatsPuntsFairCaught?: InputMaybe<SortEnumType>;
  kickingStatsPuntsIn20?: InputMaybe<SortEnumType>;
  kickingStatsPuntsOutOfBounds?: InputMaybe<SortEnumType>;
  kickingStatsPuntsReturned?: InputMaybe<SortEnumType>;
  kickingStatsPuntsTouchbacks?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  offensiveLinePressuresAllowed?: InputMaybe<SortEnumType>;
  offensiveLineSacksAllowed?: InputMaybe<SortEnumType>;
  offensiveLineScreenBlocks?: InputMaybe<SortEnumType>;
  offensiveSnaps?: InputMaybe<SortEnumType>;
  offensiveSnapsPercentage?: InputMaybe<SortEnumType>;
  offensiveTeamSnaps?: InputMaybe<SortEnumType>;
  passingStatsAttempts?: InputMaybe<SortEnumType>;
  passingStatsCatchableThrowPercentage?: InputMaybe<SortEnumType>;
  passingStatsCatchableThrows?: InputMaybe<SortEnumType>;
  passingStatsCompletionPercentage?: InputMaybe<SortEnumType>;
  passingStatsCompletions?: InputMaybe<SortEnumType>;
  passingStatsCompletionsFortyYardsPlus?: InputMaybe<SortEnumType>;
  passingStatsCompletionsTwentyYardsPlus?: InputMaybe<SortEnumType>;
  passingStatsDropbackAttempts?: InputMaybe<SortEnumType>;
  passingStatsDropbackDepthAverage?: InputMaybe<SortEnumType>;
  passingStatsDrops?: InputMaybe<SortEnumType>;
  passingStatsFirstDownPercentage?: InputMaybe<SortEnumType>;
  passingStatsFirstDowns?: InputMaybe<SortEnumType>;
  passingStatsFumbles?: InputMaybe<SortEnumType>;
  passingStatsFumblesRecovered?: InputMaybe<SortEnumType>;
  passingStatsHurriesAllowed?: InputMaybe<SortEnumType>;
  passingStatsInjuriesInGame?: InputMaybe<SortEnumType>;
  passingStatsInterceptionPercentage?: InputMaybe<SortEnumType>;
  passingStatsInterceptions?: InputMaybe<SortEnumType>;
  passingStatsLongestCompletion?: InputMaybe<SortEnumType>;
  passingStatsPassDepthAttempts?: InputMaybe<SortEnumType>;
  passingStatsPassDepthAverage?: InputMaybe<SortEnumType>;
  passingStatsPasserRating?: InputMaybe<SortEnumType>;
  passingStatsPassingEfficiency?: InputMaybe<SortEnumType>;
  passingStatsPenalties?: InputMaybe<SortEnumType>;
  passingStatsPumpFakes?: InputMaybe<SortEnumType>;
  passingStatsSackYards?: InputMaybe<SortEnumType>;
  passingStatsSacks?: InputMaybe<SortEnumType>;
  passingStatsSnapPercentage?: InputMaybe<SortEnumType>;
  passingStatsSnaps?: InputMaybe<SortEnumType>;
  passingStatsTeamSnaps?: InputMaybe<SortEnumType>;
  passingStatsTimeToPressureAttempts?: InputMaybe<SortEnumType>;
  passingStatsTimeToPressureAverage?: InputMaybe<SortEnumType>;
  passingStatsTimeToThrowAttempts?: InputMaybe<SortEnumType>;
  passingStatsTimeToThrowAverage?: InputMaybe<SortEnumType>;
  passingStatsTouchdownInterceptionRatio?: InputMaybe<SortEnumType>;
  passingStatsTouchdownPercentage?: InputMaybe<SortEnumType>;
  passingStatsTouchdowns?: InputMaybe<SortEnumType>;
  passingStatsTwoPointAttempts?: InputMaybe<SortEnumType>;
  passingStatsTwoPointConversions?: InputMaybe<SortEnumType>;
  passingStatsYards?: InputMaybe<SortEnumType>;
  passingStatsYardsAfterCatch?: InputMaybe<SortEnumType>;
  passingStatsYardsPerAttempt?: InputMaybe<SortEnumType>;
  passingStatsYardsPerCompletionAverage?: InputMaybe<SortEnumType>;
  penalties?: InputMaybe<SortEnumType>;
  playerId?: InputMaybe<SortEnumType>;
  receivingStatsCatchableThrows?: InputMaybe<SortEnumType>;
  receivingStatsDrops?: InputMaybe<SortEnumType>;
  receivingStatsDropsPercentage?: InputMaybe<SortEnumType>;
  receivingStatsFirstDownPercentage?: InputMaybe<SortEnumType>;
  receivingStatsFirstDowns?: InputMaybe<SortEnumType>;
  receivingStatsInjuriesInGame?: InputMaybe<SortEnumType>;
  receivingStatsLongestReception?: InputMaybe<SortEnumType>;
  receivingStatsPassRouteDepthAverage?: InputMaybe<SortEnumType>;
  receivingStatsPassRoutesRun?: InputMaybe<SortEnumType>;
  receivingStatsPenalties?: InputMaybe<SortEnumType>;
  receivingStatsReceivingFumbles?: InputMaybe<SortEnumType>;
  receivingStatsReceptions?: InputMaybe<SortEnumType>;
  receivingStatsReceptionsFortyYardsPlus?: InputMaybe<SortEnumType>;
  receivingStatsReceptionsPerTouchdown?: InputMaybe<SortEnumType>;
  receivingStatsReceptionsTwentyYardsPlus?: InputMaybe<SortEnumType>;
  receivingStatsSnapPercentage?: InputMaybe<SortEnumType>;
  receivingStatsSnaps?: InputMaybe<SortEnumType>;
  receivingStatsTacklesAvoided?: InputMaybe<SortEnumType>;
  receivingStatsTargets?: InputMaybe<SortEnumType>;
  receivingStatsTeamSnaps?: InputMaybe<SortEnumType>;
  receivingStatsTouchdowns?: InputMaybe<SortEnumType>;
  receivingStatsTwoPointAttempts?: InputMaybe<SortEnumType>;
  receivingStatsTwoPointConversions?: InputMaybe<SortEnumType>;
  receivingStatsYardAfterCatchPerReception?: InputMaybe<SortEnumType>;
  receivingStatsYards?: InputMaybe<SortEnumType>;
  receivingStatsYardsAfterCatch?: InputMaybe<SortEnumType>;
  receivingStatsYardsAfterContact?: InputMaybe<SortEnumType>;
  receivingStatsYardsPerReception?: InputMaybe<SortEnumType>;
  returnStatsKickReturnAttempts?: InputMaybe<SortEnumType>;
  returnStatsKickReturnFairCatches?: InputMaybe<SortEnumType>;
  returnStatsKickReturnFumbles?: InputMaybe<SortEnumType>;
  returnStatsKickReturnLongestYards?: InputMaybe<SortEnumType>;
  returnStatsKickReturnSnapPercentage?: InputMaybe<SortEnumType>;
  returnStatsKickReturnSnaps?: InputMaybe<SortEnumType>;
  returnStatsKickReturnTeamSnaps?: InputMaybe<SortEnumType>;
  returnStatsKickReturnTouchdowns?: InputMaybe<SortEnumType>;
  returnStatsKickReturnYards?: InputMaybe<SortEnumType>;
  returnStatsKickReturnYardsAfterContact?: InputMaybe<SortEnumType>;
  returnStatsKickReturnYardsAfterContactAverage?: InputMaybe<SortEnumType>;
  returnStatsKickReturnYardsAverage?: InputMaybe<SortEnumType>;
  returnStatsKickReturnsFortyYardsPlus?: InputMaybe<SortEnumType>;
  returnStatsKickReturnsTwentyYardsPlus?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnAttempts?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnFairCatches?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnFumbles?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnLongestYards?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnSnapPercentage?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnSnaps?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnTeamSnaps?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnTouchdowns?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnYards?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnYardsAfterContact?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnYardsAfterContactAverage?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnYardsAverage?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnsFortyYardsPlus?: InputMaybe<SortEnumType>;
  returnStatsPuntReturnsTwentyYardsPlus?: InputMaybe<SortEnumType>;
  rushingStatsAttempts?: InputMaybe<SortEnumType>;
  rushingStatsFirstDownPercentage?: InputMaybe<SortEnumType>;
  rushingStatsFirstDownRushes?: InputMaybe<SortEnumType>;
  rushingStatsFumbles?: InputMaybe<SortEnumType>;
  rushingStatsFumblesLost?: InputMaybe<SortEnumType>;
  rushingStatsFumblesRecovered?: InputMaybe<SortEnumType>;
  rushingStatsHurriesAllowed?: InputMaybe<SortEnumType>;
  rushingStatsInjuriesInGame?: InputMaybe<SortEnumType>;
  rushingStatsLongestRun?: InputMaybe<SortEnumType>;
  rushingStatsPenalties?: InputMaybe<SortEnumType>;
  rushingStatsPressuresAllowed?: InputMaybe<SortEnumType>;
  rushingStatsRunsFortyYardsPlus?: InputMaybe<SortEnumType>;
  rushingStatsRunsTwentyYardsPlus?: InputMaybe<SortEnumType>;
  rushingStatsSacksAllowed?: InputMaybe<SortEnumType>;
  rushingStatsSnapPercentage?: InputMaybe<SortEnumType>;
  rushingStatsSnaps?: InputMaybe<SortEnumType>;
  rushingStatsTacklesAvoided?: InputMaybe<SortEnumType>;
  rushingStatsTeamSnaps?: InputMaybe<SortEnumType>;
  rushingStatsTouchdowns?: InputMaybe<SortEnumType>;
  rushingStatsTwoPointAttempts?: InputMaybe<SortEnumType>;
  rushingStatsTwoPointConversions?: InputMaybe<SortEnumType>;
  rushingStatsYards?: InputMaybe<SortEnumType>;
  rushingStatsYardsAfterContact?: InputMaybe<SortEnumType>;
  rushingStatsYardsAfterContactPerRush?: InputMaybe<SortEnumType>;
  rushingStatsYardsPerRush?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  seasonTypeId?: InputMaybe<SortEnumType>;
  specialTeamsSnaps?: InputMaybe<SortEnumType>;
  specialTeamsSnapsPercentage?: InputMaybe<SortEnumType>;
  specialTeamsTeamSnaps?: InputMaybe<SortEnumType>;
  statTypeId?: InputMaybe<SortEnumType>;
};

export enum PlayerStatus {
  ACTIVE = "ACTIVE",
  ACTIVE_NON_FOOTBALL_INJURY = "ACTIVE_NON_FOOTBALL_INJURY",
  ACTIVE_PHYSICALLY_UNABLE_TO_PERFORM = "ACTIVE_PHYSICALLY_UNABLE_TO_PERFORM",
  COMMITTED_TO_TEAM = "COMMITTED_TO_TEAM",
  CUT = "CUT",
  DECEASED = "DECEASED",
  DECLARED_FOR_DRAFT = "DECLARED_FOR_DRAFT",
  DEVELOPMENT_PRACTICE_SQUAD = "DEVELOPMENT_PRACTICE_SQUAD",
  ENTERED_TRANSFER_PORTAL = "ENTERED_TRANSFER_PORTAL",
  EXEMPT = "EXEMPT",
  EXEMPT_INTERNATIONAL_PLAYER = "EXEMPT_INTERNATIONAL_PLAYER",
  FREE_AGENT = "FREE_AGENT",
  INACTIVE = "INACTIVE",
  INELIGIBLE = "INELIGIBLE",
  INJURED_RESERVE = "INJURED_RESERVE",
  INJURED_RESERVE_DESIGNATED_FOR_RETURN = "INJURED_RESERVE_DESIGNATED_FOR_RETURN",
  NON_FOOTBALL_RELATED_INJURED_RESERVE = "NON_FOOTBALL_RELATED_INJURED_RESERVE",
  NOT_WITH_TEAM = "NOT_WITH_TEAM",
  OUT_FOR_YEAR = "OUT_FOR_YEAR",
  PHYSICALLY_UNABLE_TO_PERFORM = "PHYSICALLY_UNABLE_TO_PERFORM",
  PRACTICE_SQUAD = "PRACTICE_SQUAD",
  PRACTICE_SQUAD_COVID = "PRACTICE_SQUAD_COVID",
  PRACTICE_SQUAD_INJURED = "PRACTICE_SQUAD_INJURED",
  PRACTICE_SQUAD_INTERNATIONAL = "PRACTICE_SQUAD_INTERNATIONAL",
  PRACTICE_SQUAD_SUSPENDED = "PRACTICE_SQUAD_SUSPENDED",
  RESERVE_COVID = "RESERVE_COVID",
  RESERVE_DETAILS_UNKNOWN = "RESERVE_DETAILS_UNKNOWN",
  RESERVE_DID_NOT_REPORT = "RESERVE_DID_NOT_REPORT",
  RESERVE_DRAFTED_UNSIGNED = "RESERVE_DRAFTED_UNSIGNED",
  RESERVE_HIGHER_RISK_OPT_OUT = "RESERVE_HIGHER_RISK_OPT_OUT",
  RESERVE_ILLNESS = "RESERVE_ILLNESS",
  RESERVE_LEFT_SQUAD = "RESERVE_LEFT_SQUAD",
  RESERVE_NON_FOOTBALL_INJURY = "RESERVE_NON_FOOTBALL_INJURY",
  RESERVE_PHYSICALLY_UNABLE_TO_PERFORM = "RESERVE_PHYSICALLY_UNABLE_TO_PERFORM",
  RESERVE_VET_FREE_AGENT_ASKED_TO_RESIGN = "RESERVE_VET_FREE_AGENT_ASKED_TO_RESIGN",
  RESERVE_VOLUNTARY_OPT_OUT = "RESERVE_VOLUNTARY_OPT_OUT",
  RESTRICTED_FREE_AGENT = "RESTRICTED_FREE_AGENT",
  RETIRED = "RETIRED",
  SUSPENDED = "SUSPENDED",
  TRADED_TO_ANOTHER_CONFERENCE = "TRADED_TO_ANOTHER_CONFERENCE",
  TRADED_TO_ANOTHER_DIVISION = "TRADED_TO_ANOTHER_DIVISION",
  TRADED_TO_ANOTHER_LEAGUE = "TRADED_TO_ANOTHER_LEAGUE",
  TRADED_TO_ANOTHER_TEAM = "TRADED_TO_ANOTHER_TEAM",
  UNKNOWN = "UNKNOWN",
  UNRESTRICTED_FREE_AGENT = "UNRESTRICTED_FREE_AGENT",
  UNSIGNED_DRAFT_PICK = "UNSIGNED_DRAFT_PICK",
}

export type PlayerStatusOperationFilterInput = {
  eq?: InputMaybe<PlayerStatus>;
  in?: InputMaybe<Array<PlayerStatus>>;
  neq?: InputMaybe<PlayerStatus>;
  nin?: InputMaybe<Array<PlayerStatus>>;
};

export type PlayerTag = {
  __typename?: "PlayerTag";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** the football player this tag corresponds to */
  player: Player;
  /** the marval player id */
  playerId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  sort?: Maybe<Scalars["Int"]>;
  /** the tag associated */
  tag: Tag;
  /** the tag id */
  tagId: Scalars["UUID"];
};

export type PlayerTagFilterInput = {
  and?: InputMaybe<Array<PlayerTagFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PlayerTagFilterInput>>;
  /** the football player this tag corresponds to */
  player?: InputMaybe<PlayerFilterInput>;
  /** the marval player id */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
  /** the tag associated */
  tag?: InputMaybe<TagFilterInput>;
  /** the tag id */
  tagId?: InputMaybe<UuidOperationFilterInput>;
};

/** A connection to a list of items. */
export type PlayersPagedConnection = {
  __typename?: "PlayersPagedConnection";
  /** A list of edges. */
  edges?: Maybe<Array<PlayersPagedEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Player>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type PlayersPagedEdge = {
  __typename?: "PlayersPagedEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Player;
};

/** A connection to a list of items. */
export type PlaysConnection = {
  __typename?: "PlaysConnection";
  /** A list of edges. */
  edges?: Maybe<Array<PlaysEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Play>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type PlaysEdge = {
  __typename?: "PlaysEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Play;
};

/** Defines a position a player can play in football. */
export type Position = {
  __typename?: "Position";
  created: Scalars["DateTime"];
  /** Details about the position */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** The type of position */
  positionType: PositionType;
  rowVersion: Array<Scalars["Byte"]>;
  /** A short name of the position */
  shortName: Scalars["String"];
  /** Which team unit the position belongs to. */
  sideOfBall?: Maybe<SideOfBall>;
  /** The club's sort of the position. */
  sort?: Maybe<Scalars["Int"]>;
};

export type PositionAtlas = {
  __typename?: "PositionAtlas";
  nodes: Array<PositionAtlasNode>;
  nodesByNodeId: Array<KeyValuePairOfStringAndPositionAtlasNode>;
  root: PositionAtlasNode;
};

/**
 * Granular position and fit graph
 * See: https://miro.com/app/board/uXjVM0u4WHA=/
 */
export type PositionAtlasNode = {
  __typename?: "PositionAtlasNode";
  /**
   * A list of references to the node's children
   * Not stored in SQL directly
   */
  children: Array<PositionAtlasNode>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Node id / name */
  nodeId: Scalars["String"];
  /**
   * Type of the node - roughly what level of the tree it is on
   * values: "root", "unit", "position_group", "position", "fit_group", "fit"
   */
  nodeType: Scalars["String"];
  /**
   * A reference to the node's parent
   * Not stored in SQL directly
   */
  parentNode: PositionAtlasNode;
  /** Parent node id */
  parentNodeId: Scalars["String"];
  rowVersion: Array<Scalars["Byte"]>;
};

/** Defines a position a player can play in football. */
export type PositionFilterInput = {
  and?: InputMaybe<Array<PositionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Details about the position */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PositionFilterInput>>;
  /** The type of position */
  positionType?: InputMaybe<PositionTypeOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** A short name of the position */
  shortName?: InputMaybe<StringOperationFilterInput>;
  /** Which team unit the position belongs to. */
  sideOfBall?: InputMaybe<NullableOfSideOfBallOperationFilterInput>;
  /** The club's sort of the position. */
  sort?: InputMaybe<IntOperationFilterInput>;
};

export type PositionMarketTier = {
  __typename?: "PositionMarketTier";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  signYear: Scalars["Int"];
  sumerGeneralPosition?: Maybe<Scalars["String"]>;
  tier?: Maybe<Scalars["String"]>;
  totalApyMax?: Maybe<Scalars["Decimal"]>;
  totalApyMin?: Maybe<Scalars["Decimal"]>;
  totalGuaranteedMax?: Maybe<Scalars["Decimal"]>;
  totalGuaranteedMin?: Maybe<Scalars["Decimal"]>;
};

export type PositionMarketTierFilterInput = {
  and?: InputMaybe<Array<PositionMarketTierFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<PositionMarketTierFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  signYear?: InputMaybe<IntOperationFilterInput>;
  sumerGeneralPosition?: InputMaybe<StringOperationFilterInput>;
  tier?: InputMaybe<StringOperationFilterInput>;
  totalApyMax?: InputMaybe<DecimalOperationFilterInput>;
  totalApyMin?: InputMaybe<DecimalOperationFilterInput>;
  totalGuaranteedMax?: InputMaybe<DecimalOperationFilterInput>;
  totalGuaranteedMin?: InputMaybe<DecimalOperationFilterInput>;
};

export type PositionMarketTierSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  signYear?: InputMaybe<SortEnumType>;
  sumerGeneralPosition?: InputMaybe<SortEnumType>;
  tier?: InputMaybe<SortEnumType>;
  totalApyMax?: InputMaybe<SortEnumType>;
  totalApyMin?: InputMaybe<SortEnumType>;
  totalGuaranteedMax?: InputMaybe<SortEnumType>;
  totalGuaranteedMin?: InputMaybe<SortEnumType>;
};

export type PositionSetItem = {
  __typename?: "PositionSetItem";
  /** indicate whether the position set is specific to a club. */
  clubSpecific: Scalars["Boolean"];
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Node id / name */
  nodeId: Scalars["String"];
  /** indicate whether there is exactly one such position for every player within the time_scale specified. yes/no. If no, each player may be associated with more than one of these positions. */
  oneToOne: Scalars["Boolean"];
  /** Parent node id */
  positionSetName: Scalars["String"];
  rowVersion: Array<Scalars["Byte"]>;
  /** frame, play, season, career. */
  timeScale: Scalars["String"];
};

/** Defines a position a player can play in football. */
export type PositionSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Details about the position */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The type of position */
  positionType?: InputMaybe<SortEnumType>;
  /** A short name of the position */
  shortName?: InputMaybe<SortEnumType>;
  /** Which team unit the position belongs to. */
  sideOfBall?: InputMaybe<SortEnumType>;
  /** The club's sort of the position. */
  sort?: InputMaybe<SortEnumType>;
};

export enum PositionType {
  CLUB_GRADING = "CLUB_GRADING",
  SCHEME = "SCHEME",
  SUMER_ATLAS = "SUMER_ATLAS",
  SUMER_GENERAL = "SUMER_GENERAL",
}

export type PositionTypeOperationFilterInput = {
  eq?: InputMaybe<PositionType>;
  in?: InputMaybe<Array<PositionType>>;
  neq?: InputMaybe<PositionType>;
  nin?: InputMaybe<Array<PositionType>>;
};

export type PredraftExpectedDraftPosition = {
  __typename?: "PredraftExpectedDraftPosition";
  active: Scalars["Boolean"];
  expectedDraftPosition: Scalars["Int"];
  marvalPlayerId: Scalars["UUID"];
  season: Scalars["Int"];
};

export type PredraftExpectedDraftPositionFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<PredraftExpectedDraftPositionFilterInput>>;
  expectedDraftPosition?: InputMaybe<IntOperationFilterInput>;
  marvalPlayerId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<PredraftExpectedDraftPositionFilterInput>>;
  season?: InputMaybe<IntOperationFilterInput>;
};

export type PredraftExpectedDraftPositionSortInput = {
  active?: InputMaybe<SortEnumType>;
  expectedDraftPosition?: InputMaybe<SortEnumType>;
  marvalPlayerId?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
};

export type ProvisionUserRequest = {
  onboardingLink?: InputMaybe<Scalars["String"]>;
  userId: Scalars["UUID"];
};

export type Query = {
  __typename?: "Query";
  alerts: Array<Alert>;
  atlas: PositionAtlas;
  authorizedIntercomKey: Scalars["String"];
  bigBoardPlayers: Array<BigBoardPlayer>;
  careerStats: Array<CareerStat>;
  clubById?: Maybe<Club>;
  /** Gets the last modified datetime of Club IP. */
  clubIpAuditAttributes: Scalars["DateTime"];
  clubs: Array<Club>;
  /** Get a list of top college players */
  collegeSpringPlayers: Array<CollegeReportPlayer>;
  /** TO BE DEPRECATED.GET CONTRACTS WILL REPLACE */
  contractById: Array<Contract>;
  contractWorksheetItems: Array<WorksheetItem>;
  /** TO BE DEPRECATED. GET CONTRACT WORKSHEET ITEMS WILL REPLACE */
  contractWorksheetItemsById: Array<WorksheetItem>;
  contracts: Array<Contract>;
  currentRosterValue: Array<RosterValuePlayer>;
  currentSeason?: Maybe<Calendar>;
  draftContractTiers: Array<DraftContractTier>;
  evalById?: Maybe<Evaluation>;
  evalGameGrades: Array<EvaluationGame>;
  evalTypes: Array<EvalType>;
  evals: Array<Evaluation>;
  filteredPlays: Array<Play>;
  filteredPlaysCount: Scalars["Int"];
  fits: Array<Fit>;
  gameById?: Maybe<Game>;
  gameByNullableId?: Maybe<Game>;
  gameStats: Array<GameStat>;
  games: Array<Game>;
  indexStatus: SearchIndexStatus;
  /** Get season historical league cap amounts */
  leagueCapAmounts: Array<LeagueSalaryCap>;
  /** Get season historical club cap amounts */
  leagueClubCapAmounts: Array<LeagueCapSeasonInfo>;
  /** League minimum salaries as designated by the CBA */
  leagueMinimumSalaries: Array<MinimumSalaries>;
  leagueSalaryCapDetailsByPlayer: Array<LeagueSalaryCapDetail>;
  leagueSalaryCapRollupsByPlayer: Array<LeagueSalaryCapRollup>;
  marketTiersByPosition: Array<PositionMarketTier>;
  measureTypes: Array<MeasureType>;
  nflCutdownPredictions: Array<NflCutdownPrediction>;
  packageClubs: Array<PackageClub>;
  packagePositions: Array<PackagePosition>;
  packages: Array<Package>;
  permissions: Array<Permission>;
  playerAdvancedAnalytics: Array<RawAiGrade>;
  playerByGsisId?: Maybe<Player>;
  playerById?: Maybe<Player>;
  playerContractsById: Array<Contract>;
  playerExpectedDraftPosition: Array<PredraftExpectedDraftPosition>;
  playerHonors: Array<PlayerHonor>;
  playerInjuryCollege?: Maybe<PlayerInjuryCollegeConnection>;
  playerInjuryPro?: Maybe<PlayerInjuryProConnection>;
  playerMarketTiers: Array<MarketContractTier>;
  playerPlayTime: Array<PlayTime>;
  playerPositionDepths: Array<PlayerPackagePosition>;
  playerSage: Array<SageGradesToDollar>;
  playerStats: Array<PlayerStat>;
  players: Array<Player>;
  playersPaged?: Maybe<PlayersPagedConnection>;
  plays?: Maybe<PlaysConnection>;
  positionMaps: Array<PositionSetItem>;
  positions: Array<Position>;
  roles: Array<AppRole>;
  /** TO BE DEPRECATED.GET CONTRACTS WILL REPLACE */
  rosterPlayerContractsById: Array<Contract>;
  rosterPlayers: Array<Player>;
  schoolById?: Maybe<School>;
  schools: Array<School>;
  scoutGrades: Array<ScoutGrade>;
  /** Get scout assignments */
  scoutingAssignments: Array<ScoutingAssignment>;
  /** Get scout assignments by userId */
  scoutingAssignmentsByUserId: Array<ScoutingAssignment>;
  /** Searches across all entities and returns them ordered by relevance */
  searchAll: Array<AnyEntitySearchResult>;
  /** Searches for clubs based on the supplied search term. */
  searchClubs: ClubSearchResults;
  /** Searches for players based on the supplied search term. */
  searchPlayers: PlayerSearchResults;
  /** Searches for schools based on the supplied search term. */
  searchSchools: SchoolSearchResults;
  seasonStats: Array<SeasonStat>;
  tagById?: Maybe<Tag>;
  taggedPlayers?: Maybe<TaggedPlayersConnection>;
  tags: Array<Tag>;
  /** Get a list of top college players */
  topCollegePlayers: Array<CollegeReportPlayer>;
  traitGroups: Array<TraitGroup>;
  transactionsByDateRange?: Maybe<TransactionsByDateRangeConnection>;
  /** Get a list of top 300 UFAs */
  unRestrictedFreeAgents: Array<UfaPlayer>;
  user?: Maybe<User>;
  userByAuthenticatedId?: Maybe<User>;
  userById?: Maybe<User>;
  users: Array<User>;
  videoForPlays: Video;
  workoutMeasuresByPlayerId: Array<WorkoutMeasure>;
  /** get workout players and measures */
  workoutPlayers: Array<WorkoutPlayerMeasure>;
  /** get workouts */
  workouts: Array<Workout>;
  /** get player workouts at a club */
  workoutsByClubId: Array<WorkoutPlayerMeasure>;
  workoutsByPlayerId: Array<WorkoutPlayerMeasure>;
  /** get player workouts at a school */
  workoutsBySchoolId: Array<WorkoutPlayerMeasure>;
};

export type QueryAlertsArgs = {
  order?: InputMaybe<Array<AlertSortInput>>;
  where?: InputMaybe<AlertFilterInput>;
};

export type QueryCareerStatsArgs = {
  order?: InputMaybe<Array<CareerStatSortInput>>;
  where?: InputMaybe<CareerStatFilterInput>;
};

export type QueryClubByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryClubsArgs = {
  order?: InputMaybe<Array<ClubSortInput>>;
  where?: InputMaybe<ClubFilterInput>;
};

export type QueryCollegeSpringPlayersArgs = {
  request: GetCollegeSpringPlayersRequest;
};

export type QueryContractByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryContractWorksheetItemsArgs = {
  order?: InputMaybe<Array<WorksheetItemSortInput>>;
  where?: InputMaybe<WorksheetItemFilterInput>;
};

export type QueryContractWorksheetItemsByIdArgs = {
  id: Scalars["UUID"];
  order?: InputMaybe<Array<WorksheetItemSortInput>>;
};

export type QueryContractsArgs = {
  order?: InputMaybe<Array<ContractSortInput>>;
  where?: InputMaybe<ContractFilterInput>;
};

export type QueryCurrentRosterValueArgs = {
  currentRosterValue: GetCurrentRosterValueRequest;
};

export type QueryDraftContractTiersArgs = {
  order?: InputMaybe<Array<DraftContractTierSortInput>>;
  where?: InputMaybe<DraftContractTierFilterInput>;
};

export type QueryEvalByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryEvalGameGradesArgs = {
  evaluationId: Scalars["UUID"];
  order?: InputMaybe<Array<EvaluationGameSortInput>>;
  where?: InputMaybe<EvaluationGameFilterInput>;
};

export type QueryEvalTypesArgs = {
  order?: InputMaybe<Array<EvalTypeSortInput>>;
  where?: InputMaybe<EvalTypeFilterInput>;
};

export type QueryEvalsArgs = {
  groupFilter?: InputMaybe<EvalTypeEnum>;
  order?: InputMaybe<Array<EvaluationSortInput>>;
  playerFilter?: InputMaybe<Scalars["UUID"]>;
  positionFilter?: InputMaybe<Scalars["UUID"]>;
  userFilter?: InputMaybe<Scalars["UUID"]>;
  where?: InputMaybe<EvaluationFilterInput>;
};

export type QueryFilteredPlaysArgs = {
  playFilterRequest: PlayFilterRequestInput;
  where?: InputMaybe<PlayFilterInput>;
};

export type QueryFilteredPlaysCountArgs = {
  playFilterRequest: PlayFilterRequestInput;
};

export type QueryFitsArgs = {
  order?: InputMaybe<Array<FitSortInput>>;
  where?: InputMaybe<FitFilterInput>;
};

export type QueryGameByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryGameByNullableIdArgs = {
  id?: InputMaybe<Scalars["UUID"]>;
};

export type QueryGameStatsArgs = {
  order?: InputMaybe<Array<GameStatSortInput>>;
  where?: InputMaybe<GameStatFilterInput>;
};

export type QueryGamesArgs = {
  order?: InputMaybe<Array<GameSortInput>>;
  where?: InputMaybe<GameFilterInput>;
};

export type QueryLeagueCapAmountsArgs = {
  order?: InputMaybe<Array<LeagueSalaryCapSortInput>>;
  where?: InputMaybe<LeagueSalaryCapFilterInput>;
};

export type QueryLeagueClubCapAmountsArgs = {
  order?: InputMaybe<Array<LeagueCapSeasonInfoSortInput>>;
  where?: InputMaybe<LeagueCapSeasonInfoFilterInput>;
};

export type QueryLeagueMinimumSalariesArgs = {
  order?: InputMaybe<Array<MinimumSalariesSortInput>>;
  where?: InputMaybe<MinimumSalariesFilterInput>;
};

export type QueryLeagueSalaryCapDetailsByPlayerArgs = {
  order?: InputMaybe<Array<LeagueSalaryCapDetailSortInput>>;
  playerId: Scalars["UUID"];
  where?: InputMaybe<LeagueSalaryCapDetailFilterInput>;
};

export type QueryLeagueSalaryCapRollupsByPlayerArgs = {
  order?: InputMaybe<Array<LeagueSalaryCapRollupSortInput>>;
  playerId: Scalars["UUID"];
  where?: InputMaybe<LeagueSalaryCapRollupFilterInput>;
};

export type QueryMarketTiersByPositionArgs = {
  order?: InputMaybe<Array<PositionMarketTierSortInput>>;
  where?: InputMaybe<PositionMarketTierFilterInput>;
};

export type QueryNflCutdownPredictionsArgs = {
  order?: InputMaybe<Array<NflCutdownPredictionSortInput>>;
  where?: InputMaybe<NflCutdownPredictionFilterInput>;
};

export type QueryPackageClubsArgs = {
  order?: InputMaybe<Array<PackageClubSortInput>>;
  where?: InputMaybe<PackageClubFilterInput>;
};

export type QueryPackagePositionsArgs = {
  order?: InputMaybe<Array<PackagePositionSortInput>>;
  where?: InputMaybe<PackagePositionFilterInput>;
};

export type QueryPackagesArgs = {
  order?: InputMaybe<Array<PackageSortInput>>;
  where?: InputMaybe<PackageFilterInput>;
};

export type QueryPermissionsArgs = {
  order?: InputMaybe<Array<PermissionSortInput>>;
  where?: InputMaybe<PermissionFilterInput>;
};

export type QueryPlayerAdvancedAnalyticsArgs = {
  order?: InputMaybe<Array<RawAiGradeSortInput>>;
  where?: InputMaybe<RawAiGradeFilterInput>;
};

export type QueryPlayerByGsisIdArgs = {
  gsisId: Scalars["String"];
};

export type QueryPlayerByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryPlayerContractsByIdArgs = {
  effectiveOnly: Scalars["Boolean"];
  id: Scalars["UUID"];
  order?: InputMaybe<Array<ContractSortInput>>;
  where?: InputMaybe<ContractFilterInput>;
};

export type QueryPlayerExpectedDraftPositionArgs = {
  order?: InputMaybe<Array<PredraftExpectedDraftPositionSortInput>>;
  where?: InputMaybe<PredraftExpectedDraftPositionFilterInput>;
};

export type QueryPlayerHonorsArgs = {
  order?: InputMaybe<Array<PlayerHonorSortInput>>;
  where?: InputMaybe<PlayerHonorFilterInput>;
};

export type QueryPlayerInjuryCollegeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<PlayerInjuryCollegeSortInput>>;
  where?: InputMaybe<PlayerInjuryCollegeFilterInput>;
};

export type QueryPlayerInjuryProArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<PlayerInjuryProSortInput>>;
  where?: InputMaybe<PlayerInjuryProFilterInput>;
};

export type QueryPlayerMarketTiersArgs = {
  order?: InputMaybe<Array<MarketContractTierSortInput>>;
  where?: InputMaybe<MarketContractTierFilterInput>;
};

export type QueryPlayerPlayTimeArgs = {
  order?: InputMaybe<Array<PlayTimeSortInput>>;
  where?: InputMaybe<PlayTimeFilterInput>;
};

export type QueryPlayerPositionDepthsArgs = {
  order?: InputMaybe<Array<PlayerPackagePositionSortInput>>;
  where?: InputMaybe<PlayerPackagePositionFilterInput>;
};

export type QueryPlayerSageArgs = {
  order?: InputMaybe<Array<SageGradesToDollarSortInput>>;
  where?: InputMaybe<SageGradesToDollarFilterInput>;
};

export type QueryPlayerStatsArgs = {
  order?: InputMaybe<Array<PlayerStatSortInput>>;
  where?: InputMaybe<PlayerStatFilterInput>;
};

export type QueryPlayersArgs = {
  clubFilter?: InputMaybe<Scalars["String"]>;
  draftYearFilter?: InputMaybe<Scalars["String"]>;
  groupFilter?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<PlayerSortInput>>;
  schoolFilter?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
  sumerAtlasPositionFilter?: InputMaybe<Scalars["String"]>;
  sumerGeneralPositionFilter?: InputMaybe<Scalars["String"]>;
  where?: InputMaybe<PlayerFilterInput>;
};

export type QueryPlayersPagedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  clubFilter?: InputMaybe<Scalars["String"]>;
  draftYearFilter?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  groupFilter?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<PlayerSortInput>>;
  schoolFilter?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
  sumerAtlasPositionFilter?: InputMaybe<Scalars["String"]>;
  sumerGeneralPositionFilter?: InputMaybe<Scalars["String"]>;
  where?: InputMaybe<PlayerFilterInput>;
};

export type QueryPlaysArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<PlaySortInput>>;
  where?: InputMaybe<PlayFilterInput>;
};

export type QueryPositionsArgs = {
  order?: InputMaybe<Array<PositionSortInput>>;
  positionTypeFilter?: InputMaybe<PositionType>;
  where?: InputMaybe<PositionFilterInput>;
};

export type QueryRolesArgs = {
  order?: InputMaybe<Array<AppRoleSortInput>>;
  where?: InputMaybe<AppRoleFilterInput>;
};

export type QueryRosterPlayerContractsByIdArgs = {
  id: Scalars["UUID"];
  order?: InputMaybe<Array<ContractSortInput>>;
  where?: InputMaybe<ContractFilterInput>;
};

export type QueryRosterPlayersArgs = {
  order?: InputMaybe<Array<PlayerSortInput>>;
  where?: InputMaybe<PlayerFilterInput>;
};

export type QuerySchoolByIdArgs = {
  id: Scalars["UUID"];
};

export type QuerySchoolsArgs = {
  order?: InputMaybe<Array<SchoolSortInput>>;
  where?: InputMaybe<SchoolFilterInput>;
};

export type QueryScoutGradesArgs = {
  order?: InputMaybe<Array<ScoutGradeSortInput>>;
  where?: InputMaybe<ScoutGradeFilterInput>;
};

export type QueryScoutingAssignmentsArgs = {
  order?: InputMaybe<Array<ScoutingAssignmentSortInput>>;
  where?: InputMaybe<ScoutingAssignmentFilterInput>;
};

export type QueryScoutingAssignmentsByUserIdArgs = {
  userId: Scalars["UUID"];
};

export type QuerySearchAllArgs = {
  order?: InputMaybe<Array<AnyEntitySearchResultSortInput>>;
  searchTerm: Scalars["String"];
  where?: InputMaybe<AnyEntitySearchResultFilterInput>;
};

export type QuerySearchClubsArgs = {
  options?: InputMaybe<SearchOptionsInput>;
  order?: InputMaybe<Array<ClubSearchResultsSortInput>>;
  searchTerm: Scalars["String"];
  where?: InputMaybe<ClubSearchResultsFilterInput>;
};

export type QuerySearchPlayersArgs = {
  filters?: InputMaybe<PlayerFiltersInput>;
  options?: InputMaybe<SearchOptionsInput>;
  order?: InputMaybe<Array<PlayerSearchResultsSortInput>>;
  orderByFields?: InputMaybe<Array<Scalars["String"]>>;
  searchTerm: Scalars["String"];
  where?: InputMaybe<PlayerSearchResultsFilterInput>;
};

export type QuerySearchSchoolsArgs = {
  options?: InputMaybe<SearchOptionsInput>;
  order?: InputMaybe<Array<SchoolSearchResultsSortInput>>;
  searchTerm: Scalars["String"];
  where?: InputMaybe<SchoolSearchResultsFilterInput>;
};

export type QuerySeasonStatsArgs = {
  order?: InputMaybe<Array<SeasonStatSortInput>>;
  where?: InputMaybe<SeasonStatFilterInput>;
};

export type QueryTagByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryTaggedPlayersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<PlayerSortInput>>;
  sortCriteria?: InputMaybe<TaggedPlayerSortCriteriaEnum>;
  tagId: Scalars["UUID"];
  where?: InputMaybe<PlayerFilterInput>;
};

export type QueryTagsArgs = {
  order?: InputMaybe<Array<TagSortInput>>;
  where?: InputMaybe<TagFilterInput>;
};

export type QueryTopCollegePlayersArgs = {
  request: GetCollegeTopPlayersRequest;
};

export type QueryTraitGroupsArgs = {
  order?: InputMaybe<Array<TraitGroupSortInput>>;
};

export type QueryTransactionsByDateRangeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  order?: InputMaybe<Array<TransactionSortInput>>;
  where?: InputMaybe<TransactionFilterInput>;
};

export type QueryUnRestrictedFreeAgentsArgs = {
  request: GetProUfaPlayersRequest;
};

export type QueryUserByAuthenticatedIdArgs = {
  id: Scalars["UUID"];
};

export type QueryUserByIdArgs = {
  id: Scalars["UUID"];
};

export type QueryUsersArgs = {
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};

export type QueryVideoForPlaysArgs = {
  request: GetVideoForPlaysRequest;
};

export type QueryWorkoutMeasuresByPlayerIdArgs = {
  playerId: Scalars["UUID"];
};

export type QueryWorkoutPlayersArgs = {
  order?: InputMaybe<Array<WorkoutPlayerMeasureSortInput>>;
  where?: InputMaybe<WorkoutPlayerMeasureFilterInput>;
};

export type QueryWorkoutsArgs = {
  order?: InputMaybe<Array<WorkoutSortInput>>;
  where?: InputMaybe<WorkoutFilterInput>;
};

export type QueryWorkoutsByClubIdArgs = {
  clubId: Scalars["UUID"];
  order?: InputMaybe<Array<WorkoutPlayerMeasureSortInput>>;
};

export type QueryWorkoutsByPlayerIdArgs = {
  order?: InputMaybe<Array<WorkoutPlayerMeasureSortInput>>;
  playerId: Scalars["UUID"];
};

export type QueryWorkoutsBySchoolIdArgs = {
  order?: InputMaybe<Array<WorkoutPlayerMeasureSortInput>>;
  schoolId: Scalars["UUID"];
};

export type RawAiGrade = {
  __typename?: "RawAiGrade";
  aiChartingCoverageGrade?: Maybe<Scalars["Float"]>;
  aiChartingCoverageGradeN?: Maybe<Scalars["Int"]>;
  aiChartingCoverageGradeRank?: Maybe<Scalars["Float"]>;
  aiChartingPassBlockGrade?: Maybe<Scalars["Float"]>;
  aiChartingPassBlockGradeN?: Maybe<Scalars["Int"]>;
  aiChartingPassBlockGradeRank?: Maybe<Scalars["Float"]>;
  aiChartingPassRushGrade?: Maybe<Scalars["Float"]>;
  aiChartingPassRushGradeN?: Maybe<Scalars["Int"]>;
  aiChartingPassRushGradeRank?: Maybe<Scalars["Float"]>;
  aiChartingPassingGrade?: Maybe<Scalars["Float"]>;
  aiChartingPassingGradeN?: Maybe<Scalars["Int"]>;
  aiChartingPassingGradeRank?: Maybe<Scalars["Float"]>;
  aiChartingReceivingGrade?: Maybe<Scalars["Float"]>;
  aiChartingReceivingGradeN?: Maybe<Scalars["Int"]>;
  aiChartingReceivingGradeRank?: Maybe<Scalars["Float"]>;
  aiChartingRunBlockGrade?: Maybe<Scalars["Float"]>;
  aiChartingRunBlockGradeN?: Maybe<Scalars["Int"]>;
  aiChartingRunBlockGradeRank?: Maybe<Scalars["Float"]>;
  aiChartingRunDefenseGrade?: Maybe<Scalars["Float"]>;
  aiChartingRunDefenseGradeN?: Maybe<Scalars["Int"]>;
  aiChartingRunDefenseGradeRank?: Maybe<Scalars["Float"]>;
  aiChartingRushingGrade?: Maybe<Scalars["Float"]>;
  aiChartingRushingGradeN?: Maybe<Scalars["Int"]>;
  aiChartingRushingGradeRank?: Maybe<Scalars["Float"]>;
  aiSeasonPosition?: Maybe<Scalars["String"]>;
  ballSkills?: Maybe<Scalars["Float"]>;
  ballSkillsN?: Maybe<Scalars["Int"]>;
  ballSkillsRank?: Maybe<Scalars["Float"]>;
  bigThrow?: Maybe<Scalars["Float"]>;
  bigThrowN?: Maybe<Scalars["Int"]>;
  bigThrowRank?: Maybe<Scalars["Float"]>;
  coverageCbWar?: Maybe<Scalars["Float"]>;
  coverageCbXwar?: Maybe<Scalars["Float"]>;
  coverageNDbWar?: Maybe<Scalars["Float"]>;
  coverageNDbXwar?: Maybe<Scalars["Float"]>;
  coverageSWar?: Maybe<Scalars["Float"]>;
  coverageSXwar?: Maybe<Scalars["Float"]>;
  decisionMaking?: Maybe<Scalars["Float"]>;
  decisionMakingN?: Maybe<Scalars["Int"]>;
  decisionMakingRank?: Maybe<Scalars["Float"]>;
  league?: Maybe<Scalars["String"]>;
  marvalPlayerId?: Maybe<Scalars["UUID"]>;
  passAccuracy?: Maybe<Scalars["Float"]>;
  passAccuracyN?: Maybe<Scalars["Int"]>;
  passAccuracyRank?: Maybe<Scalars["Float"]>;
  passBlockFinishing?: Maybe<Scalars["Float"]>;
  passBlockFinishingN?: Maybe<Scalars["Int"]>;
  passBlockFinishingRank?: Maybe<Scalars["Float"]>;
  passBlockGetOff?: Maybe<Scalars["Float"]>;
  passBlockGetOffN?: Maybe<Scalars["Int"]>;
  passBlockGetOffRank?: Maybe<Scalars["Float"]>;
  passBlockNOlWar?: Maybe<Scalars["Float"]>;
  passBlockNOlXwar?: Maybe<Scalars["Float"]>;
  passBlockOlWar?: Maybe<Scalars["Float"]>;
  passBlockOlXwar?: Maybe<Scalars["Float"]>;
  passRouteHbWar?: Maybe<Scalars["Float"]>;
  passRouteHbXwar?: Maybe<Scalars["Float"]>;
  passRouteTeWar?: Maybe<Scalars["Float"]>;
  passRouteTeXwar?: Maybe<Scalars["Float"]>;
  passRouteWrWar?: Maybe<Scalars["Float"]>;
  passRouteWrXwar?: Maybe<Scalars["Float"]>;
  passRushIDlWar?: Maybe<Scalars["Float"]>;
  passRushIDlXwar?: Maybe<Scalars["Float"]>;
  passRushNiDlWar?: Maybe<Scalars["Float"]>;
  passRushNiDlXwar?: Maybe<Scalars["Float"]>;
  passRushWinFinishing?: Maybe<Scalars["Float"]>;
  passRushWinFinishingN?: Maybe<Scalars["Int"]>;
  passRushWinFinishingRank?: Maybe<Scalars["Float"]>;
  passRushWinGetOff?: Maybe<Scalars["Float"]>;
  passRushWinGetOffN?: Maybe<Scalars["Int"]>;
  passRushWinGetOffRank?: Maybe<Scalars["Float"]>;
  passWar?: Maybe<Scalars["Float"]>;
  passXwar?: Maybe<Scalars["Float"]>;
  pressureFinishing?: Maybe<Scalars["Float"]>;
  pressureFinishingN?: Maybe<Scalars["Int"]>;
  pressureFinishingRank?: Maybe<Scalars["Float"]>;
  pressureGetOff?: Maybe<Scalars["Float"]>;
  pressureGetOffN?: Maybe<Scalars["Int"]>;
  pressureGetOffRank?: Maybe<Scalars["Float"]>;
  productionPlusMinus?: Maybe<Scalars["Float"]>;
  productionPlusMinusN?: Maybe<Scalars["Int"]>;
  productionPlusMinusRank?: Maybe<Scalars["Float"]>;
  productionUnderPressure?: Maybe<Scalars["Float"]>;
  productionUnderPressureN?: Maybe<Scalars["Int"]>;
  productionUnderPressureRank?: Maybe<Scalars["Float"]>;
  runBlockNOlWar?: Maybe<Scalars["Float"]>;
  runBlockNOlXwar?: Maybe<Scalars["Float"]>;
  runBlockOlWar?: Maybe<Scalars["Float"]>;
  runBlockOlXwar?: Maybe<Scalars["Float"]>;
  runDefenseIDlWar?: Maybe<Scalars["Float"]>;
  runDefenseIDlXwar?: Maybe<Scalars["Float"]>;
  runDefenseNiDlWar?: Maybe<Scalars["Float"]>;
  runDefenseNiDlXwar?: Maybe<Scalars["Float"]>;
  runPursuitFinishing?: Maybe<Scalars["Float"]>;
  runPursuitFinishingN?: Maybe<Scalars["Int"]>;
  runPursuitFinishingRank?: Maybe<Scalars["Float"]>;
  runPursuitGetOff?: Maybe<Scalars["Float"]>;
  runPursuitGetOffN?: Maybe<Scalars["Int"]>;
  runPursuitGetOffRank?: Maybe<Scalars["Float"]>;
  rushHbWar?: Maybe<Scalars["Float"]>;
  rushHbXwar?: Maybe<Scalars["Float"]>;
  rushQbWar?: Maybe<Scalars["Float"]>;
  rushQbXwar?: Maybe<Scalars["Float"]>;
  rushWar?: Maybe<Scalars["Float"]>;
  rushXwar?: Maybe<Scalars["Float"]>;
  rushingElusiveness?: Maybe<Scalars["Float"]>;
  rushingElusivenessN?: Maybe<Scalars["Int"]>;
  rushingElusivenessRank?: Maybe<Scalars["Float"]>;
  rushingYardsPlusMinus?: Maybe<Scalars["Float"]>;
  rushingYardsPlusMinusN?: Maybe<Scalars["Int"]>;
  rushingYardsPlusMinusRank?: Maybe<Scalars["Float"]>;
  sackAvoidance?: Maybe<Scalars["Float"]>;
  sackAvoidanceN?: Maybe<Scalars["Int"]>;
  sackAvoidanceRank?: Maybe<Scalars["Float"]>;
  season?: Maybe<Scalars["Int"]>;
  snapsCoverage?: Maybe<Scalars["Int"]>;
  snapsPass?: Maybe<Scalars["Int"]>;
  snapsPassBlock?: Maybe<Scalars["Int"]>;
  snapsPassRoute?: Maybe<Scalars["Int"]>;
  snapsPassRush?: Maybe<Scalars["Int"]>;
  snapsRun?: Maybe<Scalars["Int"]>;
  snapsRunBlock?: Maybe<Scalars["Int"]>;
  snapsRunDefense?: Maybe<Scalars["Int"]>;
  snapsYac?: Maybe<Scalars["Int"]>;
  team?: Maybe<Scalars["String"]>;
  telemetryPlayerId?: Maybe<Scalars["String"]>;
  war?: Maybe<Scalars["Float"]>;
  warN?: Maybe<Scalars["Int"]>;
  warRank?: Maybe<Scalars["Float"]>;
  xWar?: Maybe<Scalars["Float"]>;
  xWarN?: Maybe<Scalars["Int"]>;
  xWarRank?: Maybe<Scalars["Float"]>;
  yardsAfterCatchPlusMinus?: Maybe<Scalars["Float"]>;
  yardsAfterCatchPlusMinusN?: Maybe<Scalars["Int"]>;
  yardsAfterCatchPlusMinusRank?: Maybe<Scalars["Float"]>;
};

export type RawAiGradeFilterInput = {
  aiChartingCoverageGrade?: InputMaybe<FloatOperationFilterInput>;
  aiChartingCoverageGradeN?: InputMaybe<IntOperationFilterInput>;
  aiChartingCoverageGradeRank?: InputMaybe<FloatOperationFilterInput>;
  aiChartingPassBlockGrade?: InputMaybe<FloatOperationFilterInput>;
  aiChartingPassBlockGradeN?: InputMaybe<IntOperationFilterInput>;
  aiChartingPassBlockGradeRank?: InputMaybe<FloatOperationFilterInput>;
  aiChartingPassRushGrade?: InputMaybe<FloatOperationFilterInput>;
  aiChartingPassRushGradeN?: InputMaybe<IntOperationFilterInput>;
  aiChartingPassRushGradeRank?: InputMaybe<FloatOperationFilterInput>;
  aiChartingPassingGrade?: InputMaybe<FloatOperationFilterInput>;
  aiChartingPassingGradeN?: InputMaybe<IntOperationFilterInput>;
  aiChartingPassingGradeRank?: InputMaybe<FloatOperationFilterInput>;
  aiChartingReceivingGrade?: InputMaybe<FloatOperationFilterInput>;
  aiChartingReceivingGradeN?: InputMaybe<IntOperationFilterInput>;
  aiChartingReceivingGradeRank?: InputMaybe<FloatOperationFilterInput>;
  aiChartingRunBlockGrade?: InputMaybe<FloatOperationFilterInput>;
  aiChartingRunBlockGradeN?: InputMaybe<IntOperationFilterInput>;
  aiChartingRunBlockGradeRank?: InputMaybe<FloatOperationFilterInput>;
  aiChartingRunDefenseGrade?: InputMaybe<FloatOperationFilterInput>;
  aiChartingRunDefenseGradeN?: InputMaybe<IntOperationFilterInput>;
  aiChartingRunDefenseGradeRank?: InputMaybe<FloatOperationFilterInput>;
  aiChartingRushingGrade?: InputMaybe<FloatOperationFilterInput>;
  aiChartingRushingGradeN?: InputMaybe<IntOperationFilterInput>;
  aiChartingRushingGradeRank?: InputMaybe<FloatOperationFilterInput>;
  aiSeasonPosition?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<RawAiGradeFilterInput>>;
  ballSkills?: InputMaybe<FloatOperationFilterInput>;
  ballSkillsN?: InputMaybe<IntOperationFilterInput>;
  ballSkillsRank?: InputMaybe<FloatOperationFilterInput>;
  bigThrow?: InputMaybe<FloatOperationFilterInput>;
  bigThrowN?: InputMaybe<IntOperationFilterInput>;
  bigThrowRank?: InputMaybe<FloatOperationFilterInput>;
  coverageCbWar?: InputMaybe<FloatOperationFilterInput>;
  coverageCbXwar?: InputMaybe<FloatOperationFilterInput>;
  coverageNDbWar?: InputMaybe<FloatOperationFilterInput>;
  coverageNDbXwar?: InputMaybe<FloatOperationFilterInput>;
  coverageSWar?: InputMaybe<FloatOperationFilterInput>;
  coverageSXwar?: InputMaybe<FloatOperationFilterInput>;
  decisionMaking?: InputMaybe<FloatOperationFilterInput>;
  decisionMakingN?: InputMaybe<IntOperationFilterInput>;
  decisionMakingRank?: InputMaybe<FloatOperationFilterInput>;
  league?: InputMaybe<StringOperationFilterInput>;
  marvalPlayerId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<RawAiGradeFilterInput>>;
  passAccuracy?: InputMaybe<FloatOperationFilterInput>;
  passAccuracyN?: InputMaybe<IntOperationFilterInput>;
  passAccuracyRank?: InputMaybe<FloatOperationFilterInput>;
  passBlockFinishing?: InputMaybe<FloatOperationFilterInput>;
  passBlockFinishingN?: InputMaybe<IntOperationFilterInput>;
  passBlockFinishingRank?: InputMaybe<FloatOperationFilterInput>;
  passBlockGetOff?: InputMaybe<FloatOperationFilterInput>;
  passBlockGetOffN?: InputMaybe<IntOperationFilterInput>;
  passBlockGetOffRank?: InputMaybe<FloatOperationFilterInput>;
  passBlockNOlWar?: InputMaybe<FloatOperationFilterInput>;
  passBlockNOlXwar?: InputMaybe<FloatOperationFilterInput>;
  passBlockOlWar?: InputMaybe<FloatOperationFilterInput>;
  passBlockOlXwar?: InputMaybe<FloatOperationFilterInput>;
  passRouteHbWar?: InputMaybe<FloatOperationFilterInput>;
  passRouteHbXwar?: InputMaybe<FloatOperationFilterInput>;
  passRouteTeWar?: InputMaybe<FloatOperationFilterInput>;
  passRouteTeXwar?: InputMaybe<FloatOperationFilterInput>;
  passRouteWrWar?: InputMaybe<FloatOperationFilterInput>;
  passRouteWrXwar?: InputMaybe<FloatOperationFilterInput>;
  passRushIDlWar?: InputMaybe<FloatOperationFilterInput>;
  passRushIDlXwar?: InputMaybe<FloatOperationFilterInput>;
  passRushNiDlWar?: InputMaybe<FloatOperationFilterInput>;
  passRushNiDlXwar?: InputMaybe<FloatOperationFilterInput>;
  passRushWinFinishing?: InputMaybe<FloatOperationFilterInput>;
  passRushWinFinishingN?: InputMaybe<IntOperationFilterInput>;
  passRushWinFinishingRank?: InputMaybe<FloatOperationFilterInput>;
  passRushWinGetOff?: InputMaybe<FloatOperationFilterInput>;
  passRushWinGetOffN?: InputMaybe<IntOperationFilterInput>;
  passRushWinGetOffRank?: InputMaybe<FloatOperationFilterInput>;
  passWar?: InputMaybe<FloatOperationFilterInput>;
  passXwar?: InputMaybe<FloatOperationFilterInput>;
  pressureFinishing?: InputMaybe<FloatOperationFilterInput>;
  pressureFinishingN?: InputMaybe<IntOperationFilterInput>;
  pressureFinishingRank?: InputMaybe<FloatOperationFilterInput>;
  pressureGetOff?: InputMaybe<FloatOperationFilterInput>;
  pressureGetOffN?: InputMaybe<IntOperationFilterInput>;
  pressureGetOffRank?: InputMaybe<FloatOperationFilterInput>;
  productionPlusMinus?: InputMaybe<FloatOperationFilterInput>;
  productionPlusMinusN?: InputMaybe<IntOperationFilterInput>;
  productionPlusMinusRank?: InputMaybe<FloatOperationFilterInput>;
  productionUnderPressure?: InputMaybe<FloatOperationFilterInput>;
  productionUnderPressureN?: InputMaybe<IntOperationFilterInput>;
  productionUnderPressureRank?: InputMaybe<FloatOperationFilterInput>;
  runBlockNOlWar?: InputMaybe<FloatOperationFilterInput>;
  runBlockNOlXwar?: InputMaybe<FloatOperationFilterInput>;
  runBlockOlWar?: InputMaybe<FloatOperationFilterInput>;
  runBlockOlXwar?: InputMaybe<FloatOperationFilterInput>;
  runDefenseIDlWar?: InputMaybe<FloatOperationFilterInput>;
  runDefenseIDlXwar?: InputMaybe<FloatOperationFilterInput>;
  runDefenseNiDlWar?: InputMaybe<FloatOperationFilterInput>;
  runDefenseNiDlXwar?: InputMaybe<FloatOperationFilterInput>;
  runPursuitFinishing?: InputMaybe<FloatOperationFilterInput>;
  runPursuitFinishingN?: InputMaybe<IntOperationFilterInput>;
  runPursuitFinishingRank?: InputMaybe<FloatOperationFilterInput>;
  runPursuitGetOff?: InputMaybe<FloatOperationFilterInput>;
  runPursuitGetOffN?: InputMaybe<IntOperationFilterInput>;
  runPursuitGetOffRank?: InputMaybe<FloatOperationFilterInput>;
  rushHbWar?: InputMaybe<FloatOperationFilterInput>;
  rushHbXwar?: InputMaybe<FloatOperationFilterInput>;
  rushQbWar?: InputMaybe<FloatOperationFilterInput>;
  rushQbXwar?: InputMaybe<FloatOperationFilterInput>;
  rushWar?: InputMaybe<FloatOperationFilterInput>;
  rushXwar?: InputMaybe<FloatOperationFilterInput>;
  rushingElusiveness?: InputMaybe<FloatOperationFilterInput>;
  rushingElusivenessN?: InputMaybe<IntOperationFilterInput>;
  rushingElusivenessRank?: InputMaybe<FloatOperationFilterInput>;
  rushingYardsPlusMinus?: InputMaybe<FloatOperationFilterInput>;
  rushingYardsPlusMinusN?: InputMaybe<IntOperationFilterInput>;
  rushingYardsPlusMinusRank?: InputMaybe<FloatOperationFilterInput>;
  sackAvoidance?: InputMaybe<FloatOperationFilterInput>;
  sackAvoidanceN?: InputMaybe<IntOperationFilterInput>;
  sackAvoidanceRank?: InputMaybe<FloatOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  snapsCoverage?: InputMaybe<IntOperationFilterInput>;
  snapsPass?: InputMaybe<IntOperationFilterInput>;
  snapsPassBlock?: InputMaybe<IntOperationFilterInput>;
  snapsPassRoute?: InputMaybe<IntOperationFilterInput>;
  snapsPassRush?: InputMaybe<IntOperationFilterInput>;
  snapsRun?: InputMaybe<IntOperationFilterInput>;
  snapsRunBlock?: InputMaybe<IntOperationFilterInput>;
  snapsRunDefense?: InputMaybe<IntOperationFilterInput>;
  snapsYac?: InputMaybe<IntOperationFilterInput>;
  team?: InputMaybe<StringOperationFilterInput>;
  telemetryPlayerId?: InputMaybe<StringOperationFilterInput>;
  war?: InputMaybe<FloatOperationFilterInput>;
  warN?: InputMaybe<IntOperationFilterInput>;
  warRank?: InputMaybe<FloatOperationFilterInput>;
  xWar?: InputMaybe<FloatOperationFilterInput>;
  xWarN?: InputMaybe<IntOperationFilterInput>;
  xWarRank?: InputMaybe<FloatOperationFilterInput>;
  yardsAfterCatchPlusMinus?: InputMaybe<FloatOperationFilterInput>;
  yardsAfterCatchPlusMinusN?: InputMaybe<IntOperationFilterInput>;
  yardsAfterCatchPlusMinusRank?: InputMaybe<FloatOperationFilterInput>;
};

export type RawAiGradeSortInput = {
  aiChartingCoverageGrade?: InputMaybe<SortEnumType>;
  aiChartingCoverageGradeN?: InputMaybe<SortEnumType>;
  aiChartingCoverageGradeRank?: InputMaybe<SortEnumType>;
  aiChartingPassBlockGrade?: InputMaybe<SortEnumType>;
  aiChartingPassBlockGradeN?: InputMaybe<SortEnumType>;
  aiChartingPassBlockGradeRank?: InputMaybe<SortEnumType>;
  aiChartingPassRushGrade?: InputMaybe<SortEnumType>;
  aiChartingPassRushGradeN?: InputMaybe<SortEnumType>;
  aiChartingPassRushGradeRank?: InputMaybe<SortEnumType>;
  aiChartingPassingGrade?: InputMaybe<SortEnumType>;
  aiChartingPassingGradeN?: InputMaybe<SortEnumType>;
  aiChartingPassingGradeRank?: InputMaybe<SortEnumType>;
  aiChartingReceivingGrade?: InputMaybe<SortEnumType>;
  aiChartingReceivingGradeN?: InputMaybe<SortEnumType>;
  aiChartingReceivingGradeRank?: InputMaybe<SortEnumType>;
  aiChartingRunBlockGrade?: InputMaybe<SortEnumType>;
  aiChartingRunBlockGradeN?: InputMaybe<SortEnumType>;
  aiChartingRunBlockGradeRank?: InputMaybe<SortEnumType>;
  aiChartingRunDefenseGrade?: InputMaybe<SortEnumType>;
  aiChartingRunDefenseGradeN?: InputMaybe<SortEnumType>;
  aiChartingRunDefenseGradeRank?: InputMaybe<SortEnumType>;
  aiChartingRushingGrade?: InputMaybe<SortEnumType>;
  aiChartingRushingGradeN?: InputMaybe<SortEnumType>;
  aiChartingRushingGradeRank?: InputMaybe<SortEnumType>;
  aiSeasonPosition?: InputMaybe<SortEnumType>;
  ballSkills?: InputMaybe<SortEnumType>;
  ballSkillsN?: InputMaybe<SortEnumType>;
  ballSkillsRank?: InputMaybe<SortEnumType>;
  bigThrow?: InputMaybe<SortEnumType>;
  bigThrowN?: InputMaybe<SortEnumType>;
  bigThrowRank?: InputMaybe<SortEnumType>;
  coverageCbWar?: InputMaybe<SortEnumType>;
  coverageCbXwar?: InputMaybe<SortEnumType>;
  coverageNDbWar?: InputMaybe<SortEnumType>;
  coverageNDbXwar?: InputMaybe<SortEnumType>;
  coverageSWar?: InputMaybe<SortEnumType>;
  coverageSXwar?: InputMaybe<SortEnumType>;
  decisionMaking?: InputMaybe<SortEnumType>;
  decisionMakingN?: InputMaybe<SortEnumType>;
  decisionMakingRank?: InputMaybe<SortEnumType>;
  league?: InputMaybe<SortEnumType>;
  marvalPlayerId?: InputMaybe<SortEnumType>;
  passAccuracy?: InputMaybe<SortEnumType>;
  passAccuracyN?: InputMaybe<SortEnumType>;
  passAccuracyRank?: InputMaybe<SortEnumType>;
  passBlockFinishing?: InputMaybe<SortEnumType>;
  passBlockFinishingN?: InputMaybe<SortEnumType>;
  passBlockFinishingRank?: InputMaybe<SortEnumType>;
  passBlockGetOff?: InputMaybe<SortEnumType>;
  passBlockGetOffN?: InputMaybe<SortEnumType>;
  passBlockGetOffRank?: InputMaybe<SortEnumType>;
  passBlockNOlWar?: InputMaybe<SortEnumType>;
  passBlockNOlXwar?: InputMaybe<SortEnumType>;
  passBlockOlWar?: InputMaybe<SortEnumType>;
  passBlockOlXwar?: InputMaybe<SortEnumType>;
  passRouteHbWar?: InputMaybe<SortEnumType>;
  passRouteHbXwar?: InputMaybe<SortEnumType>;
  passRouteTeWar?: InputMaybe<SortEnumType>;
  passRouteTeXwar?: InputMaybe<SortEnumType>;
  passRouteWrWar?: InputMaybe<SortEnumType>;
  passRouteWrXwar?: InputMaybe<SortEnumType>;
  passRushIDlWar?: InputMaybe<SortEnumType>;
  passRushIDlXwar?: InputMaybe<SortEnumType>;
  passRushNiDlWar?: InputMaybe<SortEnumType>;
  passRushNiDlXwar?: InputMaybe<SortEnumType>;
  passRushWinFinishing?: InputMaybe<SortEnumType>;
  passRushWinFinishingN?: InputMaybe<SortEnumType>;
  passRushWinFinishingRank?: InputMaybe<SortEnumType>;
  passRushWinGetOff?: InputMaybe<SortEnumType>;
  passRushWinGetOffN?: InputMaybe<SortEnumType>;
  passRushWinGetOffRank?: InputMaybe<SortEnumType>;
  passWar?: InputMaybe<SortEnumType>;
  passXwar?: InputMaybe<SortEnumType>;
  pressureFinishing?: InputMaybe<SortEnumType>;
  pressureFinishingN?: InputMaybe<SortEnumType>;
  pressureFinishingRank?: InputMaybe<SortEnumType>;
  pressureGetOff?: InputMaybe<SortEnumType>;
  pressureGetOffN?: InputMaybe<SortEnumType>;
  pressureGetOffRank?: InputMaybe<SortEnumType>;
  productionPlusMinus?: InputMaybe<SortEnumType>;
  productionPlusMinusN?: InputMaybe<SortEnumType>;
  productionPlusMinusRank?: InputMaybe<SortEnumType>;
  productionUnderPressure?: InputMaybe<SortEnumType>;
  productionUnderPressureN?: InputMaybe<SortEnumType>;
  productionUnderPressureRank?: InputMaybe<SortEnumType>;
  runBlockNOlWar?: InputMaybe<SortEnumType>;
  runBlockNOlXwar?: InputMaybe<SortEnumType>;
  runBlockOlWar?: InputMaybe<SortEnumType>;
  runBlockOlXwar?: InputMaybe<SortEnumType>;
  runDefenseIDlWar?: InputMaybe<SortEnumType>;
  runDefenseIDlXwar?: InputMaybe<SortEnumType>;
  runDefenseNiDlWar?: InputMaybe<SortEnumType>;
  runDefenseNiDlXwar?: InputMaybe<SortEnumType>;
  runPursuitFinishing?: InputMaybe<SortEnumType>;
  runPursuitFinishingN?: InputMaybe<SortEnumType>;
  runPursuitFinishingRank?: InputMaybe<SortEnumType>;
  runPursuitGetOff?: InputMaybe<SortEnumType>;
  runPursuitGetOffN?: InputMaybe<SortEnumType>;
  runPursuitGetOffRank?: InputMaybe<SortEnumType>;
  rushHbWar?: InputMaybe<SortEnumType>;
  rushHbXwar?: InputMaybe<SortEnumType>;
  rushQbWar?: InputMaybe<SortEnumType>;
  rushQbXwar?: InputMaybe<SortEnumType>;
  rushWar?: InputMaybe<SortEnumType>;
  rushXwar?: InputMaybe<SortEnumType>;
  rushingElusiveness?: InputMaybe<SortEnumType>;
  rushingElusivenessN?: InputMaybe<SortEnumType>;
  rushingElusivenessRank?: InputMaybe<SortEnumType>;
  rushingYardsPlusMinus?: InputMaybe<SortEnumType>;
  rushingYardsPlusMinusN?: InputMaybe<SortEnumType>;
  rushingYardsPlusMinusRank?: InputMaybe<SortEnumType>;
  sackAvoidance?: InputMaybe<SortEnumType>;
  sackAvoidanceN?: InputMaybe<SortEnumType>;
  sackAvoidanceRank?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  snapsCoverage?: InputMaybe<SortEnumType>;
  snapsPass?: InputMaybe<SortEnumType>;
  snapsPassBlock?: InputMaybe<SortEnumType>;
  snapsPassRoute?: InputMaybe<SortEnumType>;
  snapsPassRush?: InputMaybe<SortEnumType>;
  snapsRun?: InputMaybe<SortEnumType>;
  snapsRunBlock?: InputMaybe<SortEnumType>;
  snapsRunDefense?: InputMaybe<SortEnumType>;
  snapsYac?: InputMaybe<SortEnumType>;
  team?: InputMaybe<SortEnumType>;
  telemetryPlayerId?: InputMaybe<SortEnumType>;
  war?: InputMaybe<SortEnumType>;
  warN?: InputMaybe<SortEnumType>;
  warRank?: InputMaybe<SortEnumType>;
  xWar?: InputMaybe<SortEnumType>;
  xWarN?: InputMaybe<SortEnumType>;
  xWarRank?: InputMaybe<SortEnumType>;
  yardsAfterCatchPlusMinus?: InputMaybe<SortEnumType>;
  yardsAfterCatchPlusMinusN?: InputMaybe<SortEnumType>;
  yardsAfterCatchPlusMinusRank?: InputMaybe<SortEnumType>;
};

export type RemovePermissionsFromRoleRequest = {
  permissionIdsToRemove: Array<Scalars["UUID"]>;
  roleId: Scalars["UUID"];
};

export type ResetContractRequest = {
  id: Scalars["UUID"];
};

/** Request to recreate and reload all search data to the players index. */
export type ResetPlayerSearchIndicesRequest = {
  /** Indicates whether or not the Clubs index should be refreshed */
  refreshClubs: Scalars["Boolean"];
  /** Indicates whether or not the Players index should be refreshed */
  refreshPlayers: Scalars["Boolean"];
  /** Indicates whether or not the Schools index should be refreshed */
  refreshSchools: Scalars["Boolean"];
  /** Indicates whether or not to delete/recreate indices before hydrating with create data */
  skipIndexRecreation: Scalars["Boolean"];
};

export enum RoleType {
  SYSTEM = "SYSTEM",
  USER_DEFINED = "USER_DEFINED",
}

export type RoleTypeOperationFilterInput = {
  eq?: InputMaybe<RoleType>;
  in?: InputMaybe<Array<RoleType>>;
  neq?: InputMaybe<RoleType>;
  nin?: InputMaybe<Array<RoleType>>;
};

export type RosterBonus = {
  __typename?: "RosterBonus";
  fiftyThreeMan: Scalars["Decimal"];
  fortySixMan: Scalars["Decimal"];
  /**
   * The 90-man roster bonus is a financial incentive given to players who are included
   * on the team's roster during the offseason. It serves as compensation for attending
   * team activities, participating in offseason workouts, and potentially competing for a
   * spot on the final 53-man regular-season roster.
   */
  ninetyMan: Scalars["Decimal"];
  perGame: Scalars["Decimal"];
};

export type RosterBonusFilterInput = {
  and?: InputMaybe<Array<RosterBonusFilterInput>>;
  fiftyThreeMan?: InputMaybe<DecimalOperationFilterInput>;
  fortySixMan?: InputMaybe<DecimalOperationFilterInput>;
  /**
   * The 90-man roster bonus is a financial incentive given to players who are included
   * on the team's roster during the offseason. It serves as compensation for attending
   * team activities, participating in offseason workouts, and potentially competing for a
   * spot on the final 53-man regular-season roster.
   */
  ninetyMan?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<RosterBonusFilterInput>>;
  perGame?: InputMaybe<DecimalOperationFilterInput>;
};

export type RosterBonusSortInput = {
  fiftyThreeMan?: InputMaybe<SortEnumType>;
  fortySixMan?: InputMaybe<SortEnumType>;
  /**
   * The 90-man roster bonus is a financial incentive given to players who are included
   * on the team's roster during the offseason. It serves as compensation for attending
   * team activities, participating in offseason workouts, and potentially competing for a
   * spot on the final 53-man regular-season roster.
   */
  ninetyMan?: InputMaybe<SortEnumType>;
  perGame?: InputMaybe<SortEnumType>;
};

export type RosterValuePlayer = {
  __typename?: "RosterValuePlayer";
  playerId: Scalars["UUID"];
  rosterValueAdded: Value;
};

export type SageGradesToDollar = {
  __typename?: "SageGradesToDollar";
  active: Scalars["Boolean"];
  gapypocPrediction: Scalars["Decimal"];
  marvalPlayerId: Scalars["UUID"];
  season: Scalars["Int"];
  sumerGeneralPosition: Scalars["String"];
};

export type SageGradesToDollarFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<SageGradesToDollarFilterInput>>;
  gapypocPrediction?: InputMaybe<DecimalOperationFilterInput>;
  marvalPlayerId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<SageGradesToDollarFilterInput>>;
  season?: InputMaybe<IntOperationFilterInput>;
  sumerGeneralPosition?: InputMaybe<StringOperationFilterInput>;
};

export type SageGradesToDollarSortInput = {
  active?: InputMaybe<SortEnumType>;
  gapypocPrediction?: InputMaybe<SortEnumType>;
  marvalPlayerId?: InputMaybe<SortEnumType>;
  season?: InputMaybe<SortEnumType>;
  sumerGeneralPosition?: InputMaybe<SortEnumType>;
};

/** Save list of big board player ranks */
export type SaveBigBoardPlayerRequest = {
  players: Array<BigBoardPlayerDetailInput>;
};

export type SaveEvaluationAlertRequest = {
  alerts: Array<Scalars["UUID"]>;
  evaluationId: Scalars["UUID"];
};

export type SaveEvaluationClubFitRequest = {
  clubs: Array<Scalars["UUID"]>;
  evaluationId: Scalars["UUID"];
};

export type SaveEvaluationFitRequest = {
  evaluationId: Scalars["UUID"];
  fits: Array<Scalars["UUID"]>;
};

export type SaveEvaluationTraitGroupRequest = {
  comments?: InputMaybe<Scalars["String"]>;
  evaluationId: Scalars["UUID"];
  scoutGradeId?: InputMaybe<Scalars["UUID"]>;
  traitGroupId: Scalars["UUID"];
};

export type SaveEvaluationTraitRequest = {
  details: EvaluationTraitDetailsInput;
};

export type SavePlayerAssignmentRequest = {
  playerId: Scalars["UUID"];
  users: Array<Scalars["UUID"]>;
};

export type SavePlayerTagsRequest = {
  playerId: Scalars["UUID"];
  tags: Array<Scalars["UUID"]>;
};

/** Represents a school that has an NCAA football team. */
export type School = {
  __typename?: "School";
  /** The conference the school belongs to. League data has this */
  conference?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  /** The division the school belongs to. League data has this */
  division?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive: Scalars["Boolean"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** The NCAA's unique ID for the school */
  ncaaId?: Maybe<Scalars["Int"]>;
  /** The school's nickname */
  nickname?: Maybe<Scalars["String"]>;
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** The NFL's league designated code for the school */
  schoolCode?: Maybe<Scalars["String"]>;
  /** The name of the school */
  schoolName: Scalars["String"];
  /** A URL to the school's website */
  schoolUrl?: Maybe<Scalars["String"]>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: Maybe<Scalars["String"]>;
  /** The short name of the school */
  shortName?: Maybe<Scalars["String"]>;
  /**
   * Updates a club using all the supplied values in the command.  Throws ValidationException if invalid.
   *
   *
   * **Returns:**
   * The updated club entity
   */
  updateSchoolDetails: School;
};

/** Represents a school that has an NCAA football team. */
export type SchoolUpdateSchoolDetailsArgs = {
  request: UpdateSchoolRequest;
};

export type SchoolDetailsInput = {
  abbreviation?: InputMaybe<Scalars["String"]>;
  conference?: InputMaybe<Scalars["String"]>;
  division?: InputMaybe<Scalars["String"]>;
  endSeason?: InputMaybe<Scalars["Int"]>;
  franchiseId?: InputMaybe<Scalars["Int"]>;
  gsisAbbreviation?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["UUID"]>;
  league?: InputMaybe<Scalars["String"]>;
  leagueClubId?: InputMaybe<Scalars["Int"]>;
  leagueId?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickName?: InputMaybe<Scalars["String"]>;
  primaryColor?: InputMaybe<Scalars["String"]>;
  secondaryColor?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  startSeason?: InputMaybe<Scalars["Int"]>;
  teamId?: InputMaybe<Scalars["Int"]>;
};

/** Represents a school that has an NCAA football team. */
export type SchoolFilterInput = {
  and?: InputMaybe<Array<SchoolFilterInput>>;
  /** The conference the school belongs to. League data has this */
  conference?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The division the school belongs to. League data has this */
  division?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The NCAA's unique ID for the school */
  ncaaId?: InputMaybe<IntOperationFilterInput>;
  /** The school's nickname */
  nickname?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SchoolFilterInput>>;
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** The NFL's league designated code for the school */
  schoolCode?: InputMaybe<StringOperationFilterInput>;
  /** The name of the school */
  schoolName?: InputMaybe<StringOperationFilterInput>;
  /** A URL to the school's website */
  schoolUrl?: InputMaybe<StringOperationFilterInput>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: InputMaybe<StringOperationFilterInput>;
  /** The short name of the school */
  shortName?: InputMaybe<StringOperationFilterInput>;
};

/** Encapsulates a flattened view of a school that can be searched against.  Annotated with Azure Search attributes. */
export type SchoolSearchDocument = {
  __typename?: "SchoolSearchDocument";
  /** School's internal ID */
  id: Scalars["String"];
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** The NFL's unique ID for the school */
  leagueClubId?: Maybe<Scalars["Int"]>;
  /** The NCAA's unique ID for the school */
  ncaaId?: Maybe<Scalars["Int"]>;
  /** The school's nickname */
  nickname?: Maybe<Scalars["String"]>;
  /** The NFL's league designated code for the school */
  schoolCode?: Maybe<Scalars["String"]>;
  /** The name of the school */
  schoolName: Scalars["String"];
  /** The short name of the school */
  shortName?: Maybe<Scalars["String"]>;
};

/** Encapsulates a flattened view of a school that can be searched against.  Annotated with Azure Search attributes. */
export type SchoolSearchDocumentFilterInput = {
  and?: InputMaybe<Array<SchoolSearchDocumentFilterInput>>;
  /** School's internal ID */
  id?: InputMaybe<StringOperationFilterInput>;
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** The NFL's unique ID for the school */
  leagueClubId?: InputMaybe<IntOperationFilterInput>;
  /** The NCAA's unique ID for the school */
  ncaaId?: InputMaybe<IntOperationFilterInput>;
  /** The school's nickname */
  nickname?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SchoolSearchDocumentFilterInput>>;
  /** The NFL's league designated code for the school */
  schoolCode?: InputMaybe<StringOperationFilterInput>;
  /** The name of the school */
  schoolName?: InputMaybe<StringOperationFilterInput>;
  /** The short name of the school */
  shortName?: InputMaybe<StringOperationFilterInput>;
};

/** Encapsulates a flattened view of a school that can be searched against.  Annotated with Azure Search attributes. */
export type SchoolSearchDocumentSortInput = {
  /** School's internal ID */
  id?: InputMaybe<SortEnumType>;
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The NFL's unique ID for the school */
  leagueClubId?: InputMaybe<SortEnumType>;
  /** The NCAA's unique ID for the school */
  ncaaId?: InputMaybe<SortEnumType>;
  /** The school's nickname */
  nickname?: InputMaybe<SortEnumType>;
  /** The NFL's league designated code for the school */
  schoolCode?: InputMaybe<SortEnumType>;
  /** The name of the school */
  schoolName?: InputMaybe<SortEnumType>;
  /** The short name of the school */
  shortName?: InputMaybe<SortEnumType>;
};

/** Encapsulates a result set of schools from the search appliance. */
export type SchoolSearchResults = {
  __typename?: "SchoolSearchResults";
  /** The collection of matched schools, ordered by relevance. */
  schools: Array<SearchResultOfSchoolSearchDocument>;
  /** The total number of schools that would match the search query (excluding paging parameters) */
  total: Scalars["Long"];
};

/** Encapsulates a result set of schools from the search appliance. */
export type SchoolSearchResultsFilterInput = {
  and?: InputMaybe<Array<SchoolSearchResultsFilterInput>>;
  or?: InputMaybe<Array<SchoolSearchResultsFilterInput>>;
  /** The collection of matched schools, ordered by relevance. */
  schools?: InputMaybe<ListFilterInputTypeOfSearchResultOfSchoolSearchDocumentFilterInput>;
  /** The total number of schools that would match the search query (excluding paging parameters) */
  total?: InputMaybe<LongOperationFilterInput>;
};

/** Encapsulates a result set of schools from the search appliance. */
export type SchoolSearchResultsSortInput = {
  /** The total number of schools that would match the search query (excluding paging parameters) */
  total?: InputMaybe<SortEnumType>;
};

/** Represents a school that has an NCAA football team. */
export type SchoolSortInput = {
  /** The conference the school belongs to. League data has this */
  conference?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** The division the school belongs to. League data has this */
  division?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Indicates whether or not this school has an active football program in the NCAA */
  isActive?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** The NCAA's unique ID for the school */
  ncaaId?: InputMaybe<SortEnumType>;
  /** The school's nickname */
  nickname?: InputMaybe<SortEnumType>;
  /** A hex color of the team's primary color in their color scheme */
  primaryColor?: InputMaybe<SortEnumType>;
  /** The NFL's league designated code for the school */
  schoolCode?: InputMaybe<SortEnumType>;
  /** The name of the school */
  schoolName?: InputMaybe<SortEnumType>;
  /** A URL to the school's website */
  schoolUrl?: InputMaybe<SortEnumType>;
  /** A hex color of the team's secondary color in their color scheme */
  secondaryColor?: InputMaybe<SortEnumType>;
  /** The short name of the school */
  shortName?: InputMaybe<SortEnumType>;
};

export type ScoutGrade = {
  __typename?: "ScoutGrade";
  created: Scalars["DateTime"];
  /** Grade description */
  description: Scalars["String"];
  /** Grade */
  grade: Scalars["Decimal"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** Quality of player assigned to the grade */
  quality: Scalars["String"];
  rowVersion: Array<Scalars["Byte"]>;
  /** The scale the grade belongs */
  scale: Scalars["String"];
  sort?: Maybe<Scalars["Int"]>;
  /** Description of the Quality */
  tier: Scalars["String"];
  update: ScoutGrade;
};

export type ScoutGradeUpdateArgs = {
  details: ScoutGradeDetailsInput;
};

export type ScoutGradeDetailsInput = {
  /** Grade description */
  description: Scalars["String"];
  /** Grade */
  grade: Scalars["Decimal"];
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  /** Quality of player assigned to the grade */
  quality: Scalars["String"];
  scale: Scalars["String"];
  sort?: InputMaybe<Scalars["Int"]>;
  /** Description of the Quality */
  tier: Scalars["String"];
};

export type ScoutGradeFilterInput = {
  and?: InputMaybe<Array<ScoutGradeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Grade description */
  description?: InputMaybe<StringOperationFilterInput>;
  /** Grade */
  grade?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ScoutGradeFilterInput>>;
  /** Quality of player assigned to the grade */
  quality?: InputMaybe<StringOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** The scale the grade belongs */
  scale?: InputMaybe<StringOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
  /** Description of the Quality */
  tier?: InputMaybe<StringOperationFilterInput>;
};

export type ScoutGradeSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Grade description */
  description?: InputMaybe<SortEnumType>;
  /** Grade */
  grade?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Quality of player assigned to the grade */
  quality?: InputMaybe<SortEnumType>;
  /** The scale the grade belongs */
  scale?: InputMaybe<SortEnumType>;
  sort?: InputMaybe<SortEnumType>;
  /** Description of the Quality */
  tier?: InputMaybe<SortEnumType>;
};

export enum ScoutTypeEnum {
  COLLEGE = "COLLEGE",
  CROSS_CHECK = "CROSS_CHECK",
  OTHER = "OTHER",
  PORTAL = "PORTAL",
  PRO = "PRO",
}

export type ScoutTypeEnumOperationFilterInput = {
  eq?: InputMaybe<ScoutTypeEnum>;
  in?: InputMaybe<Array<ScoutTypeEnum>>;
  neq?: InputMaybe<ScoutTypeEnum>;
  nin?: InputMaybe<Array<ScoutTypeEnum>>;
};

export type ScoutingAssignment = {
  __typename?: "ScoutingAssignment";
  club?: Maybe<Club>;
  /** Club assigned to the scout */
  clubId?: Maybe<Scalars["UUID"]>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  player?: Maybe<Player>;
  /** Player assigned to scout */
  playerId?: Maybe<Scalars["UUID"]>;
  position?: Maybe<Position>;
  /** Position assigned to the scout */
  positionId?: Maybe<Scalars["UUID"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** Scout type */
  scoutType: ScoutTypeEnum;
  update: ScoutingAssignment;
  /** Scout the attributes belong */
  userId: Scalars["UUID"];
};

export type ScoutingAssignmentUpdateArgs = {
  request: UpdateScoutingAssignmentRequest;
};

export type ScoutingAssignmentDetailsInput = {
  /** Club assigned to the scout */
  clubId?: InputMaybe<Scalars["UUID"]>;
  /** Player assigned to the scout */
  playerId?: InputMaybe<Scalars["UUID"]>;
  /** Position assigned to the scout */
  positionId?: InputMaybe<Scalars["UUID"]>;
  /** Scout type */
  scoutType: ScoutTypeEnum;
  /** Scout the attributes belong */
  userId: Scalars["UUID"];
};

export type ScoutingAssignmentFilterInput = {
  and?: InputMaybe<Array<ScoutingAssignmentFilterInput>>;
  club?: InputMaybe<ClubFilterInput>;
  /** Club assigned to the scout */
  clubId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<ScoutingAssignmentFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  /** Player assigned to scout */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  position?: InputMaybe<PositionFilterInput>;
  /** Position assigned to the scout */
  positionId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Scout type */
  scoutType?: InputMaybe<ScoutTypeEnumOperationFilterInput>;
  /** Scout the attributes belong */
  userId?: InputMaybe<UuidOperationFilterInput>;
};

export type ScoutingAssignmentSortInput = {
  club?: InputMaybe<ClubSortInput>;
  /** Club assigned to the scout */
  clubId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  player?: InputMaybe<PlayerSortInput>;
  /** Player assigned to scout */
  playerId?: InputMaybe<SortEnumType>;
  position?: InputMaybe<PositionSortInput>;
  /** Position assigned to the scout */
  positionId?: InputMaybe<SortEnumType>;
  /** Scout type */
  scoutType?: InputMaybe<SortEnumType>;
  /** Scout the attributes belong */
  userId?: InputMaybe<SortEnumType>;
};

export type SearchIndexStatus = {
  __typename?: "SearchIndexStatus";
  clubsDatabaseCount: Scalars["Long"];
  clubsDocumentCount: Scalars["Long"];
  playersDatabaseCount: Scalars["Long"];
  playersDocumentCount: Scalars["Long"];
  schoolsDatabaseCount: Scalars["Long"];
  schoolsDocumentCount: Scalars["Long"];
};

/** Options used to configure search queries. */
export type SearchOptionsInput = {
  /** Indicates whether or not to include the total count of records, excluding any paging parameters */
  includeTotalCount: Scalars["Boolean"];
  /** Which page to retrieve from */
  page?: InputMaybe<Scalars["Int"]>;
  /** The size of the page to retrieve */
  pageSize?: InputMaybe<Scalars["Int"]>;
};

export type SearchResultOfClubSearchDocument = {
  __typename?: "SearchResultOfClubSearchDocument";
  record: ClubSearchDocument;
  searchScore?: Maybe<Scalars["Float"]>;
};

export type SearchResultOfClubSearchDocumentFilterInput = {
  and?: InputMaybe<Array<SearchResultOfClubSearchDocumentFilterInput>>;
  or?: InputMaybe<Array<SearchResultOfClubSearchDocumentFilterInput>>;
  record?: InputMaybe<ClubSearchDocumentFilterInput>;
  searchScore?: InputMaybe<FloatOperationFilterInput>;
};

export type SearchResultOfClubSearchDocumentSortInput = {
  record?: InputMaybe<ClubSearchDocumentSortInput>;
  searchScore?: InputMaybe<SortEnumType>;
};

export type SearchResultOfPlayerSearchDocument = {
  __typename?: "SearchResultOfPlayerSearchDocument";
  record: PlayerSearchDocument;
  searchScore?: Maybe<Scalars["Float"]>;
};

export type SearchResultOfPlayerSearchDocumentFilterInput = {
  and?: InputMaybe<Array<SearchResultOfPlayerSearchDocumentFilterInput>>;
  or?: InputMaybe<Array<SearchResultOfPlayerSearchDocumentFilterInput>>;
  record?: InputMaybe<PlayerSearchDocumentFilterInput>;
  searchScore?: InputMaybe<FloatOperationFilterInput>;
};

export type SearchResultOfPlayerSearchDocumentSortInput = {
  record?: InputMaybe<PlayerSearchDocumentSortInput>;
  searchScore?: InputMaybe<SortEnumType>;
};

export type SearchResultOfSchoolSearchDocument = {
  __typename?: "SearchResultOfSchoolSearchDocument";
  record: SchoolSearchDocument;
  searchScore?: Maybe<Scalars["Float"]>;
};

export type SearchResultOfSchoolSearchDocumentFilterInput = {
  and?: InputMaybe<Array<SearchResultOfSchoolSearchDocumentFilterInput>>;
  or?: InputMaybe<Array<SearchResultOfSchoolSearchDocumentFilterInput>>;
  record?: InputMaybe<SchoolSearchDocumentFilterInput>;
  searchScore?: InputMaybe<FloatOperationFilterInput>;
};

export type SearchResultOfSchoolSearchDocumentSortInput = {
  record?: InputMaybe<SchoolSearchDocumentSortInput>;
  searchScore?: InputMaybe<SortEnumType>;
};

export type SeasonStat = {
  __typename?: "SeasonStat";
  gameId?: Maybe<Scalars["UUID"]>;
  lastUpdated: Scalars["DateTime"];
  leagueType: LeagueType;
  leagueTypeId: Scalars["Int"];
  perGameStatsGamesPlayed?: Maybe<Scalars["Int"]>;
  perGameStatsPassAttemptsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsPassingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsReceivingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsReceptionsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsRushAttemptsPerGame?: Maybe<Scalars["Decimal"]>;
  perGameStatsRushingYardsPerGame?: Maybe<Scalars["Decimal"]>;
  playerId: Scalars["UUID"];
  playerStat?: Maybe<PlayerStat>;
  rowVersion: Array<Scalars["Byte"]>;
  season: Scalars["Int"];
  seasonType: SeasonType;
  seasonTypeId: Scalars["Int"];
  statType: StatType;
  statTypeId: Scalars["Int"];
};

export type SeasonStatFilterInput = {
  and?: InputMaybe<Array<SeasonStatFilterInput>>;
  gameId?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  leagueType?: InputMaybe<LeagueTypeFilterInput>;
  leagueTypeId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SeasonStatFilterInput>>;
  perGameStatsGamesPlayed?: InputMaybe<IntOperationFilterInput>;
  perGameStatsPassAttemptsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsPassingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsReceivingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsReceptionsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsRushAttemptsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  perGameStatsRushingYardsPerGame?: InputMaybe<DecimalOperationFilterInput>;
  playerId?: InputMaybe<UuidOperationFilterInput>;
  playerStat?: InputMaybe<PlayerStatFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  season?: InputMaybe<IntOperationFilterInput>;
  seasonType?: InputMaybe<SeasonTypeFilterInput>;
  seasonTypeId?: InputMaybe<IntOperationFilterInput>;
  statType?: InputMaybe<StatTypeFilterInput>;
  statTypeId?: InputMaybe<IntOperationFilterInput>;
};

export type SeasonStatSortInput = {
  gameId?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  leagueType?: InputMaybe<LeagueTypeSortInput>;
  leagueTypeId?: InputMaybe<SortEnumType>;
  perGameStatsGamesPlayed?: InputMaybe<SortEnumType>;
  perGameStatsPassAttemptsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsPassingYardsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsReceivingYardsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsReceptionsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsRushAttemptsPerGame?: InputMaybe<SortEnumType>;
  perGameStatsRushingYardsPerGame?: InputMaybe<SortEnumType>;
  playerId?: InputMaybe<SortEnumType>;
  playerStat?: InputMaybe<PlayerStatSortInput>;
  season?: InputMaybe<SortEnumType>;
  seasonType?: InputMaybe<SeasonTypeSortInput>;
  seasonTypeId?: InputMaybe<SortEnumType>;
  statType?: InputMaybe<StatTypeSortInput>;
  statTypeId?: InputMaybe<SortEnumType>;
};

export type SeasonType = {
  __typename?: "SeasonType";
  abbreviation?: Maybe<Scalars["String"]>;
  careerStats: Array<CareerStat>;
  description?: Maybe<Scalars["String"]>;
  gameStats: Array<GameStat>;
  games: Array<Game>;
  id: Scalars["Int"];
  seasonStats: Array<SeasonStat>;
};

export type SeasonTypeFilterInput = {
  abbreviation?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<SeasonTypeFilterInput>>;
  careerStats?: InputMaybe<ListFilterInputTypeOfCareerStatFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  games?: InputMaybe<ListFilterInputTypeOfGameFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<SeasonTypeFilterInput>>;
  seasonStats?: InputMaybe<ListFilterInputTypeOfSeasonStatFilterInput>;
};

export type SeasonTypeSortInput = {
  abbreviation?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type SectionType = {
  __typename?: "SectionType";
  /** The category the type belongs */
  category?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  /** A contract section type */
  type: Scalars["String"];
};

export type SectionTypeFilterInput = {
  and?: InputMaybe<Array<SectionTypeFilterInput>>;
  /** The category the type belongs */
  category?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<SectionTypeFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** A contract section type */
  type?: InputMaybe<StringOperationFilterInput>;
};

export type SectionTypeSortInput = {
  /** The category the type belongs */
  category?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** A contract section type */
  type?: InputMaybe<SortEnumType>;
};

export type ShortOperationFilterInput = {
  eq?: InputMaybe<Scalars["Short"]>;
  gt?: InputMaybe<Scalars["Short"]>;
  gte?: InputMaybe<Scalars["Short"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["Short"]>>>;
  lt?: InputMaybe<Scalars["Short"]>;
  lte?: InputMaybe<Scalars["Short"]>;
  neq?: InputMaybe<Scalars["Short"]>;
  ngt?: InputMaybe<Scalars["Short"]>;
  ngte?: InputMaybe<Scalars["Short"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["Short"]>>>;
  nlt?: InputMaybe<Scalars["Short"]>;
  nlte?: InputMaybe<Scalars["Short"]>;
};

/** Denotes a grouping of positions based on their role on the field. */
export enum SideOfBall {
  DEFENSE = "DEFENSE",
  OFFENSE = "OFFENSE",
  SPECIAL_TEAMS = "SPECIAL_TEAMS",
}

export type SideOfBallOperationFilterInput = {
  eq?: InputMaybe<SideOfBall>;
  in?: InputMaybe<Array<SideOfBall>>;
  neq?: InputMaybe<SideOfBall>;
  nin?: InputMaybe<Array<SideOfBall>>;
};

export enum Situation {
  ASSIST = "ASSIST",
  BALL_CARRIER = "BALL_CARRIER",
  BATTED_PASS = "BATTED_PASS",
  CONTESTED_TARGET = "CONTESTED_TARGET",
  DROPPED_PASS = "DROPPED_PASS",
  FORCED_FUMBLE = "FORCED_FUMBLE",
  HIT = "HIT",
  HURRY = "HURRY",
  INTERCEPTION = "INTERCEPTION",
  INTERCEPTION_THROWN = "INTERCEPTION_THROWN",
  MISSED_TACKLE = "MISSED_TACKLE",
  PASS_BREAKUP = "PASS_BREAKUP",
  PRESSURE = "PRESSURE",
  PRESSURE_ALLOWED = "PRESSURE_ALLOWED",
  PRIMARY_COVERAGE = "PRIMARY_COVERAGE",
  RECEPTION = "RECEPTION",
  SACK = "SACK",
  SACK_ALLOWED = "SACK_ALLOWED",
  TACKLE = "TACKLE",
  TACKLES_AVOIDED = "TACKLES_AVOIDED",
  TARGETED_RECEIVER = "TARGETED_RECEIVER",
  TOUCHDOWN = "TOUCHDOWN",
}

export enum SortEnumType {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SourceTypeEnum {
  CLUB = "CLUB",
  LEAGUE = "LEAGUE",
  SUMER = "SUMER",
}

export type SourceTypeEnumOperationFilterInput = {
  eq?: InputMaybe<SourceTypeEnum>;
  in?: InputMaybe<Array<SourceTypeEnum>>;
  neq?: InputMaybe<SourceTypeEnum>;
  nin?: InputMaybe<Array<SourceTypeEnum>>;
};

export type StatType = {
  __typename?: "StatType";
  careerStats: Array<CareerStat>;
  description?: Maybe<Scalars["String"]>;
  gameStats: Array<GameStat>;
  id: Scalars["Int"];
  seasonStats: Array<SeasonStat>;
};

export type StatTypeFilterInput = {
  and?: InputMaybe<Array<StatTypeFilterInput>>;
  careerStats?: InputMaybe<ListFilterInputTypeOfCareerStatFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<StatTypeFilterInput>>;
  seasonStats?: InputMaybe<ListFilterInputTypeOfSeasonStatFilterInput>;
};

export type StatTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars["String"]>;
  endsWith?: InputMaybe<Scalars["String"]>;
  eq?: InputMaybe<Scalars["String"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ncontains?: InputMaybe<Scalars["String"]>;
  nendsWith?: InputMaybe<Scalars["String"]>;
  neq?: InputMaybe<Scalars["String"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nstartsWith?: InputMaybe<Scalars["String"]>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars["String"]>;
};

export enum Subject {
  DRAFT_PICK = "DRAFT_PICK",
  PLAYER = "PLAYER",
}

export type SubjectOperationFilterInput = {
  eq?: InputMaybe<Subject>;
  in?: InputMaybe<Array<Subject>>;
  neq?: InputMaybe<Subject>;
  nin?: InputMaybe<Array<Subject>>;
};

export type SumerValues = {
  __typename?: "SumerValues";
  /** Comments about the contract */
  comments?: Maybe<Scalars["String"]>;
  /** Indicates whether the contract has been evaluated by Capologist */
  evaluated: Scalars["Boolean"];
  /** Remaining cash amount */
  existingAmount?: Maybe<Scalars["Decimal"]>;
  /** The remaining cash amount that's guaranteed. */
  existingAmountGuaranteed?: Maybe<Scalars["Decimal"]>;
  /** Remaining years on contract */
  existingYears?: Maybe<Scalars["Int"]>;
  /** Contract amount that is fully guaranteed */
  fullGuaranteed?: Maybe<Scalars["Decimal"]>;
  functionalAmount?: Maybe<Scalars["Decimal"]>;
  functionalYears?: Maybe<Scalars["Int"]>;
  /** Whether the carry over amount is guaranteed */
  isExistingAmountGuaranteed?: Maybe<Scalars["Boolean"]>;
  /** Indicates if the contract is a rework or restructured deal */
  isRework: Scalars["Boolean"];
  /** New guranteed - FullGuaranteed minus carry over (existing amount) */
  newGuaranteed?: Maybe<Scalars["Decimal"]>;
  /** Money that is practically G$ */
  practicalGuaranteed?: Maybe<Scalars["Decimal"]>;
};

export type SumerValuesFilterInput = {
  and?: InputMaybe<Array<SumerValuesFilterInput>>;
  /** Comments about the contract */
  comments?: InputMaybe<StringOperationFilterInput>;
  /** Indicates whether the contract has been evaluated by Capologist */
  evaluated?: InputMaybe<BooleanOperationFilterInput>;
  /** Remaining cash amount */
  existingAmount?: InputMaybe<DecimalOperationFilterInput>;
  /** The remaining cash amount that's guaranteed. */
  existingAmountGuaranteed?: InputMaybe<DecimalOperationFilterInput>;
  /** Remaining years on contract */
  existingYears?: InputMaybe<IntOperationFilterInput>;
  /** Contract amount that is fully guaranteed */
  fullGuaranteed?: InputMaybe<DecimalOperationFilterInput>;
  functionalAmount?: InputMaybe<DecimalOperationFilterInput>;
  functionalYears?: InputMaybe<IntOperationFilterInput>;
  /** Whether the carry over amount is guaranteed */
  isExistingAmountGuaranteed?: InputMaybe<BooleanOperationFilterInput>;
  /** Indicates if the contract is a rework or restructured deal */
  isRework?: InputMaybe<BooleanOperationFilterInput>;
  /** New guranteed - FullGuaranteed minus carry over (existing amount) */
  newGuaranteed?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<SumerValuesFilterInput>>;
  /** Money that is practically G$ */
  practicalGuaranteed?: InputMaybe<DecimalOperationFilterInput>;
};

export type SumerValuesSortInput = {
  /** Comments about the contract */
  comments?: InputMaybe<SortEnumType>;
  /** Indicates whether the contract has been evaluated by Capologist */
  evaluated?: InputMaybe<SortEnumType>;
  /** Remaining cash amount */
  existingAmount?: InputMaybe<SortEnumType>;
  /** The remaining cash amount that's guaranteed. */
  existingAmountGuaranteed?: InputMaybe<SortEnumType>;
  /** Remaining years on contract */
  existingYears?: InputMaybe<SortEnumType>;
  /** Contract amount that is fully guaranteed */
  fullGuaranteed?: InputMaybe<SortEnumType>;
  functionalAmount?: InputMaybe<SortEnumType>;
  functionalYears?: InputMaybe<SortEnumType>;
  /** Whether the carry over amount is guaranteed */
  isExistingAmountGuaranteed?: InputMaybe<SortEnumType>;
  /** Indicates if the contract is a rework or restructured deal */
  isRework?: InputMaybe<SortEnumType>;
  /** New guranteed - FullGuaranteed minus carry over (existing amount) */
  newGuaranteed?: InputMaybe<SortEnumType>;
  /** Money that is practically G$ */
  practicalGuaranteed?: InputMaybe<SortEnumType>;
};

export type SyncAlertsRequestInput = {
  testId?: InputMaybe<Scalars["String"]>;
};

export type SyncEvaluationMetricGroupsRequestInput = {
  testId?: InputMaybe<Scalars["String"]>;
};

export type SyncFitsRequestInput = {
  testId?: InputMaybe<Scalars["String"]>;
};

export type SyncPositionsRequestInput = {
  dummyId?: InputMaybe<Scalars["String"]>;
};

export type Tag = {
  __typename?: "Tag";
  created: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  name: Scalars["String"];
  rowVersion: Array<Scalars["Byte"]>;
  tagType: TagTypeEnum;
  update: Tag;
};

export type TagUpdateArgs = {
  request: UpdateTagRequest;
};

export type TagFilterInput = {
  and?: InputMaybe<Array<TagFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TagFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  tagType?: InputMaybe<TagTypeEnumOperationFilterInput>;
};

export type TagSortInput = {
  created?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  tagType?: InputMaybe<SortEnumType>;
};

export enum TagTypeEnum {
  SYSTEM = "SYSTEM",
  USER = "USER",
}

export type TagTypeEnumOperationFilterInput = {
  eq?: InputMaybe<TagTypeEnum>;
  in?: InputMaybe<Array<TagTypeEnum>>;
  neq?: InputMaybe<TagTypeEnum>;
  nin?: InputMaybe<Array<TagTypeEnum>>;
};

export enum TaggedPlayerSortCriteriaEnum {
  ALPHA = "ALPHA",
  CLUB_ALPHA = "CLUB_ALPHA",
  CLUB_POSITION = "CLUB_POSITION",
  GRADE = "GRADE",
  SCHOOL_ALPHA = "SCHOOL_ALPHA",
  SCHOOL_POSITION = "SCHOOL_POSITION",
  SUMER_ATLAS_POSITION = "SUMER_ATLAS_POSITION",
  SUMER_GENERAL_POSITION = "SUMER_GENERAL_POSITION",
  TAG = "TAG",
}

/** A connection to a list of items. */
export type TaggedPlayersConnection = {
  __typename?: "TaggedPlayersConnection";
  /** A list of edges. */
  edges?: Maybe<Array<TaggedPlayersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Player>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TaggedPlayersEdge = {
  __typename?: "TaggedPlayersEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Player;
};

/** Encapsulates a metric that a player could be evaluated against. */
export type TraitDefinition = {
  __typename?: "TraitDefinition";
  /** The metric to be evaluated */
  caption: Scalars["String"];
  created: Scalars["DateTime"];
  /** The metric to be evaluated */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Shorter name for metric definition for display purposes */
  shortName: Scalars["String"];
  /** Order of display */
  sort: Scalars["Int"];
  update: TraitDefinition;
  /** The metric importance */
  weight: Scalars["Decimal"];
};

/** Encapsulates a metric that a player could be evaluated against. */
export type TraitDefinitionUpdateArgs = {
  details: TraitDefinitionDetailsInput;
};

export type TraitDefinitionDetailsInput = {
  /** The metric to be evaluated */
  caption: Scalars["String"];
  /** The metric to be evaluated */
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  /** Shorter name for metric definition for display purposes */
  shortName: Scalars["String"];
  /** Order of display */
  sort: Scalars["Int"];
  /** The metric importance */
  weight: Scalars["Decimal"];
};

/** Encapsulates a metric that a player could be evaluated against. */
export type TraitDefinitionFilterInput = {
  and?: InputMaybe<Array<TraitDefinitionFilterInput>>;
  /** The metric to be evaluated */
  caption?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** The metric to be evaluated */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TraitDefinitionFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Shorter name for metric definition for display purposes */
  shortName?: InputMaybe<StringOperationFilterInput>;
  /** Order of display */
  sort?: InputMaybe<IntOperationFilterInput>;
  /** The metric importance */
  weight?: InputMaybe<DecimalOperationFilterInput>;
};

/** Encapsulates a metric that a player could be evaluated against. */
export type TraitDefinitionSortInput = {
  /** The metric to be evaluated */
  caption?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** The metric to be evaluated */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Shorter name for metric definition for display purposes */
  shortName?: InputMaybe<SortEnumType>;
  /** Order of display */
  sort?: InputMaybe<SortEnumType>;
  /** The metric importance */
  weight?: InputMaybe<SortEnumType>;
};

/**
 * Encapsulates a group of related metrics that will be graded and then weighted as a group
 * according to model specifications.
 */
export type TraitGroup = {
  __typename?: "TraitGroup";
  /** Details about what all the metrics in this group encompass */
  caption: Scalars["String"];
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Order of display */
  sort: Scalars["Int"];
  /** The list of metrics that apply to this metric group */
  traitGroupPositions: Array<TraitGroupPosition>;
  update: TraitGroup;
  /** The metric group importance */
  weight: Scalars["Decimal"];
};

/**
 * Encapsulates a group of related metrics that will be graded and then weighted as a group
 * according to model specifications.
 */
export type TraitGroupTraitGroupPositionsArgs = {
  order?: InputMaybe<Array<TraitGroupPositionSortInput>>;
};

/**
 * Encapsulates a group of related metrics that will be graded and then weighted as a group
 * according to model specifications.
 */
export type TraitGroupUpdateArgs = {
  details: TraitGroupDetailsInput;
};

export type TraitGroupDetailsInput = {
  /** Details about what all the metrics in this group encompass */
  caption: Scalars["String"];
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  /** Order of display */
  sort: Scalars["Int"];
  /** The metric group importance */
  weight: Scalars["Decimal"];
};

/**
 * Encapsulates a group of related metrics that will be graded and then weighted as a group
 * according to model specifications.
 */
export type TraitGroupFilterInput = {
  and?: InputMaybe<Array<TraitGroupFilterInput>>;
  /** Details about what all the metrics in this group encompass */
  caption?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TraitGroupFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Order of display */
  sort?: InputMaybe<IntOperationFilterInput>;
  /** The list of metrics that apply to this metric group */
  traitGroupPositions?: InputMaybe<ListFilterInputTypeOfTraitGroupPositionFilterInput>;
  /** The metric group importance */
  weight?: InputMaybe<DecimalOperationFilterInput>;
};

export type TraitGroupPosition = {
  __typename?: "TraitGroupPosition";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  position: Position;
  positionId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  sort: Scalars["Int"];
  traitDefinition: TraitDefinition;
  traitDefinitionId: Scalars["UUID"];
  traitGroup: TraitGroup;
  traitGroupId: Scalars["UUID"];
  update: TraitGroupPosition;
};

export type TraitGroupPositionUpdateArgs = {
  request: UpdateTraitGroupPositionRequest;
};

export type TraitGroupPositionFilterInput = {
  and?: InputMaybe<Array<TraitGroupPositionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TraitGroupPositionFilterInput>>;
  position?: InputMaybe<PositionFilterInput>;
  positionId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  sort?: InputMaybe<IntOperationFilterInput>;
  traitDefinition?: InputMaybe<TraitDefinitionFilterInput>;
  traitDefinitionId?: InputMaybe<UuidOperationFilterInput>;
  traitGroup?: InputMaybe<TraitGroupFilterInput>;
  traitGroupId?: InputMaybe<UuidOperationFilterInput>;
};

export type TraitGroupPositionSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  position?: InputMaybe<PositionSortInput>;
  positionId?: InputMaybe<SortEnumType>;
  sort?: InputMaybe<SortEnumType>;
  traitDefinition?: InputMaybe<TraitDefinitionSortInput>;
  traitDefinitionId?: InputMaybe<SortEnumType>;
  traitGroup?: InputMaybe<TraitGroupSortInput>;
  traitGroupId?: InputMaybe<SortEnumType>;
};

/**
 * Encapsulates a group of related metrics that will be graded and then weighted as a group
 * according to model specifications.
 */
export type TraitGroupSortInput = {
  /** Details about what all the metrics in this group encompass */
  caption?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Order of display */
  sort?: InputMaybe<SortEnumType>;
  /** The metric group importance */
  weight?: InputMaybe<SortEnumType>;
};

export type Transaction = {
  __typename?: "Transaction";
  /** Comments around the transaction */
  comments?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  initialStatus: TransactionStatus;
  /** Status of player before transaction (league status id) */
  initialStatusId: Scalars["UUID"];
  lastUpdated: Scalars["DateTime"];
  /** League transaction ID */
  leagueTransID: Scalars["Int"];
  player: Player;
  /** Marval player associated with transaction */
  playerId: Scalars["UUID"];
  potentialClub?: Maybe<Club>;
  /** Potential player could belong to  (better desc here) */
  potentialClubId?: Maybe<Scalars["UUID"]>;
  resultClub?: Maybe<Club>;
  /** Club that the player belongs to post transaction */
  resultClubId?: Maybe<Scalars["UUID"]>;
  resultStatus: TransactionStatus;
  /** Status of player post transaction */
  resultStatusId: Scalars["UUID"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Season transaction was executed */
  season: Scalars["Int"];
  startClub?: Maybe<Club>;
  /** Club that the player belongs to be before transaction */
  startClubId?: Maybe<Scalars["UUID"]>;
  /** Not sure. */
  toDate?: Maybe<Scalars["DateTime"]>;
  /** Description of transaction (league id) */
  transDescriptionId: Scalars["UUID"];
  /** Date transaction was executed */
  transactionDate: Scalars["DateTime"];
  transactionDescription: TransactionDescription;
  /** Transaction was voided */
  voided: Scalars["Boolean"];
};

export type TransactionDescription = {
  __typename?: "TransactionDescription";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  /** Whether current status is active or in use */
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** League's transaction description ID */
  leagueTransDescriptionID: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Longer description of transaction */
  transactionDesc: Scalars["String"];
  /** League's transaction desc abbr */
  transactionDescAbbr: Scalars["String"];
};

export type TransactionDescriptionFilterInput = {
  and?: InputMaybe<Array<TransactionDescriptionFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Whether current status is active or in use */
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** League's transaction description ID */
  leagueTransDescriptionID?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TransactionDescriptionFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Longer description of transaction */
  transactionDesc?: InputMaybe<StringOperationFilterInput>;
  /** League's transaction desc abbr */
  transactionDescAbbr?: InputMaybe<StringOperationFilterInput>;
};

export type TransactionDescriptionSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Whether current status is active or in use */
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** League's transaction description ID */
  leagueTransDescriptionID?: InputMaybe<SortEnumType>;
  /** Longer description of transaction */
  transactionDesc?: InputMaybe<SortEnumType>;
  /** League's transaction desc abbr */
  transactionDescAbbr?: InputMaybe<SortEnumType>;
};

export type TransactionFilterInput = {
  and?: InputMaybe<Array<TransactionFilterInput>>;
  /** Comments around the transaction */
  comments?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  initialStatus?: InputMaybe<TransactionStatusFilterInput>;
  /** Status of player before transaction (league status id) */
  initialStatusId?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** League transaction ID */
  leagueTransID?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TransactionFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  /** Marval player associated with transaction */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  potentialClub?: InputMaybe<ClubFilterInput>;
  /** Potential player could belong to  (better desc here) */
  potentialClubId?: InputMaybe<UuidOperationFilterInput>;
  resultClub?: InputMaybe<ClubFilterInput>;
  /** Club that the player belongs to post transaction */
  resultClubId?: InputMaybe<UuidOperationFilterInput>;
  resultStatus?: InputMaybe<TransactionStatusFilterInput>;
  /** Status of player post transaction */
  resultStatusId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Season transaction was executed */
  season?: InputMaybe<IntOperationFilterInput>;
  startClub?: InputMaybe<ClubFilterInput>;
  /** Club that the player belongs to be before transaction */
  startClubId?: InputMaybe<UuidOperationFilterInput>;
  /** Not sure. */
  toDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Description of transaction (league id) */
  transDescriptionId?: InputMaybe<UuidOperationFilterInput>;
  /** Date transaction was executed */
  transactionDate?: InputMaybe<DateTimeOperationFilterInput>;
  transactionDescription?: InputMaybe<TransactionDescriptionFilterInput>;
  /** Transaction was voided */
  voided?: InputMaybe<BooleanOperationFilterInput>;
};

export type TransactionSortInput = {
  /** Comments around the transaction */
  comments?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  initialStatus?: InputMaybe<TransactionStatusSortInput>;
  /** Status of player before transaction (league status id) */
  initialStatusId?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** League transaction ID */
  leagueTransID?: InputMaybe<SortEnumType>;
  player?: InputMaybe<PlayerSortInput>;
  /** Marval player associated with transaction */
  playerId?: InputMaybe<SortEnumType>;
  potentialClub?: InputMaybe<ClubSortInput>;
  /** Potential player could belong to  (better desc here) */
  potentialClubId?: InputMaybe<SortEnumType>;
  resultClub?: InputMaybe<ClubSortInput>;
  /** Club that the player belongs to post transaction */
  resultClubId?: InputMaybe<SortEnumType>;
  resultStatus?: InputMaybe<TransactionStatusSortInput>;
  /** Status of player post transaction */
  resultStatusId?: InputMaybe<SortEnumType>;
  /** Season transaction was executed */
  season?: InputMaybe<SortEnumType>;
  startClub?: InputMaybe<ClubSortInput>;
  /** Club that the player belongs to be before transaction */
  startClubId?: InputMaybe<SortEnumType>;
  /** Not sure. */
  toDate?: InputMaybe<SortEnumType>;
  /** Description of transaction (league id) */
  transDescriptionId?: InputMaybe<SortEnumType>;
  /** Date transaction was executed */
  transactionDate?: InputMaybe<SortEnumType>;
  transactionDescription?: InputMaybe<TransactionDescriptionSortInput>;
  /** Transaction was voided */
  voided?: InputMaybe<SortEnumType>;
};

export type TransactionStatus = {
  __typename?: "TransactionStatus";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  /** Whether current status is active or in use */
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** League transaction status ID */
  leagueTransStatusID: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
  /** Longer description of transaction status */
  transStatusDesc: Scalars["String"];
  /** League transaction description abbr. */
  transStatusDescAbbr: Scalars["String"];
  /** Transaction status descriptions */
  transStatusShortDesc: Scalars["String"];
};

export type TransactionStatusFilterInput = {
  and?: InputMaybe<Array<TransactionStatusFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Whether current status is active or in use */
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** League transaction status ID */
  leagueTransStatusID?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<TransactionStatusFilterInput>>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Longer description of transaction status */
  transStatusDesc?: InputMaybe<StringOperationFilterInput>;
  /** League transaction description abbr. */
  transStatusDescAbbr?: InputMaybe<StringOperationFilterInput>;
  /** Transaction status descriptions */
  transStatusShortDesc?: InputMaybe<StringOperationFilterInput>;
};

export type TransactionStatusSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Whether current status is active or in use */
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** League transaction status ID */
  leagueTransStatusID?: InputMaybe<SortEnumType>;
  /** Longer description of transaction status */
  transStatusDesc?: InputMaybe<SortEnumType>;
  /** League transaction description abbr. */
  transStatusDescAbbr?: InputMaybe<SortEnumType>;
  /** Transaction status descriptions */
  transStatusShortDesc?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type TransactionsByDateRangeConnection = {
  __typename?: "TransactionsByDateRangeConnection";
  /** A list of edges. */
  edges?: Maybe<Array<TransactionsByDateRangeEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Transaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TransactionsByDateRangeEdge = {
  __typename?: "TransactionsByDateRangeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Transaction;
};

export type UfaPlayer = {
  __typename?: "UfaPlayer";
  accruedSeasons?: Maybe<Scalars["Int"]>;
  agentCompany?: Maybe<Scalars["String"]>;
  agentName?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["DateTime"]>;
  club?: Maybe<Scalars["String"]>;
  draftClub?: Maybe<Scalars["String"]>;
  draftRound?: Maybe<Scalars["Int"]>;
  draftSelection?: Maybe<Scalars["Int"]>;
  draftYear?: Maybe<Scalars["Int"]>;
  evaluations: Array<Evaluation>;
  firstName: Scalars["String"];
  freeAgencyStatus?: Maybe<FreeAgencyStatus>;
  headshotUrl?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["Int"]>;
  hometownCity?: Maybe<Scalars["String"]>;
  hometownCountry?: Maybe<Scalars["String"]>;
  hometownState?: Maybe<Scalars["String"]>;
  id: Scalars["UUID"];
  jersey?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  latestBirthDate?: Maybe<Scalars["String"]>;
  primaryEvaluation?: Maybe<Evaluation>;
  sage?: Maybe<Scalars["Decimal"]>;
  school?: Maybe<Scalars["String"]>;
  speed?: Maybe<Scalars["Decimal"]>;
  sumerAtlasPosition?: Maybe<Scalars["String"]>;
  sumerGeneralPosition?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["Int"]>;
  workouts: Array<WorkoutPlayerMeasure>;
};

/** Undo marking an evaluation as final - intended use is for admin/leads to undo mistakes */
export type UnMarkEvaluationAsFinalRequest = {
  /** The ID of the evaluation to update. */
  evaluationId: Scalars["UUID"];
};

export type UpdateBasicPlayerDetailsRequest = {
  /** Attributes for the basic player details */
  details: BasicPlayerDetailsInput;
};

export type UpdateClubDetailsRequest = {
  /** Attributes for the club */
  details: ClubDetailsInput;
  /** The Marval ID of the club to update */
  id: Scalars["UUID"];
};

/** Edit details of an existing contract */
export type UpdateContractSummaryRequest = {
  details: ContractSummaryDetailsInput;
  id: Scalars["UUID"];
};

/** Update an evaluation by setting all defined properties on the underlying entity */
export type UpdateEvaluationRequest = {
  details: EvaluationDetailsInput;
  /** The ID of the evaluation to update. */
  evaluationId: Scalars["UUID"];
};

export type UpdateGameEvaluationRequest = {
  details: EvaluationGameDetailsInput;
  id: Scalars["UUID"];
};

export type UpdateMeasureTypeRequest = {
  /** Brief description of measure */
  description: Scalars["String"];
  id: Scalars["UUID"];
  /** Whether the display should be a fraction */
  isFraction: Scalars["Boolean"];
  /** Maximum value for validation */
  maxValue: Scalars["Decimal"];
  /** Minimum value for validation */
  minValue: Scalars["Decimal"];
  /** Name of measure */
  name: Scalars["String"];
  /** Decimal precision */
  precision: Scalars["Int"];
  /** Order of display/sort */
  priority: Scalars["Int"];
  /** Short name for display */
  shortName: Scalars["String"];
};

export type UpdatePermissionRequest = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  permissionId: Scalars["UUID"];
};

export type UpdatePlayerInjuryCollegeWithDetailsRequest = {
  /** Details about the injury */
  details: PlayerInjuryCollegeDetailsInput;
  /** Marval's internal ID for the injury */
  id: Scalars["UUID"];
};

export type UpdatePlayerInjuryProWithDetailsRequest = {
  /** Details about the injury */
  details: PlayerInjuryProDetailsInput;
  /** Marval's internal ID for the injury */
  id: Scalars["UUID"];
};

/** Update a player by setting all defined properties on the underlying entity */
export type UpdatePlayerRequest = {
  /** Details about the player */
  details: PlayerDetailsInput;
  /** Marval's internal ID for the player */
  id: Scalars["UUID"];
};

export type UpdateRoleRequest = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  roleId: Scalars["UUID"];
};

export type UpdateSchoolRequest = {
  /** Attributes for the club */
  details: SchoolDetailsInput;
  /** The Marval ID of the club to update */
  id: Scalars["UUID"];
};

export type UpdateScoutingAssignmentRequest = {
  details: ScoutingAssignmentDetailsInput;
  id: Scalars["UUID"];
};

export type UpdateTagRequest = {
  description: Scalars["String"];
  id: Scalars["UUID"];
  name: Scalars["String"];
};

export type UpdateTraitGroupPositionRequest = {
  id: Scalars["UUID"];
  isDeleted: Scalars["Boolean"];
  positionId: Scalars["UUID"];
  sort: Scalars["Int"];
  traitDefinitionId: Scalars["UUID"];
  traitGroupId: Scalars["UUID"];
};

export type UpdateUserRequest = {
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  roleId?: InputMaybe<Scalars["UUID"]>;
  userId: Scalars["UUID"];
};

export type UpdateWorkoutPlayerMeasureWithDetailsCommandInput = {
  details: WorkoutPlayerMeasureDetailsInput;
};

export type UpdateWorkoutTypeRequest = {
  /** Brief description of workout */
  description: Scalars["String"];
  id: Scalars["UUID"];
  /** Name of workout */
  name: Scalars["String"];
  /** Order of display/sort */
  priority: Scalars["Int"];
};

export type UpdateWorksheetItemRequest = {
  details: WorksheetItemDetailsInput;
  id: Scalars["UUID"];
};

export type UpsertSumerScoutMeasureRequest = {
  measures: Array<MeasureWithTypeInput>;
  playerId: Scalars["UUID"];
};

/** A user is someone that uses the system and is authorized to make calls to the backend. */
export type User = {
  __typename?: "User";
  /** The user's unique ID from the authentication provider (currently oid in Azure AD) */
  authenticatedId?: Maybe<Scalars["UUID"]>;
  created: Scalars["DateTime"];
  createdById: Scalars["UUID"];
  /** The user's email */
  emailAddress: Scalars["String"];
  /** The user's first name */
  firstName: Scalars["String"];
  id: Scalars["UUID"];
  /** The user's last name */
  lastName: Scalars["String"];
  lastUpdated: Scalars["DateTime"];
  lastUpdatedById: Scalars["UUID"];
  role?: Maybe<AppRole>;
  /** Foreign key to Role. Role assigned to user. */
  roleId?: Maybe<Scalars["UUID"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /**
   * User's account status. Options: PROVISIONED, ACTIVE, INACTIVE
   * Newly created users will by default be provisioned, until they register into the system at which point they become active.
   */
  status: UserStatus;
};

/** A user is someone that uses the system and is authorized to make calls to the backend. */
export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  /** The user's unique ID from the authentication provider (currently oid in Azure AD) */
  authenticatedId?: InputMaybe<UuidOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  createdById?: InputMaybe<UuidOperationFilterInput>;
  /** The user's email */
  emailAddress?: InputMaybe<StringOperationFilterInput>;
  /** The user's first name */
  firstName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** The user's last name */
  lastName?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  lastUpdatedById?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  role?: InputMaybe<AppRoleFilterInput>;
  /** Foreign key to Role. Role assigned to user. */
  roleId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /**
   * User's account status. Options: PROVISIONED, ACTIVE, INACTIVE
   * Newly created users will by default be provisioned, until they register into the system at which point they become active.
   */
  status?: InputMaybe<UserStatusOperationFilterInput>;
};

/** A user is someone that uses the system and is authorized to make calls to the backend. */
export type UserSortInput = {
  /** The user's unique ID from the authentication provider (currently oid in Azure AD) */
  authenticatedId?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  createdById?: InputMaybe<SortEnumType>;
  /** The user's email */
  emailAddress?: InputMaybe<SortEnumType>;
  /** The user's first name */
  firstName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** The user's last name */
  lastName?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  lastUpdatedById?: InputMaybe<SortEnumType>;
  role?: InputMaybe<AppRoleSortInput>;
  /** Foreign key to Role. Role assigned to user. */
  roleId?: InputMaybe<SortEnumType>;
  /**
   * User's account status. Options: PROVISIONED, ACTIVE, INACTIVE
   * Newly created users will by default be provisioned, until they register into the system at which point they become active.
   */
  status?: InputMaybe<SortEnumType>;
};

export enum UserStatus {
  /** User is active, account verification and registration is complete. */
  ACTIVE = "ACTIVE",
  /** Account has been unprovisioned/deactivated by an administrator. */
  INACTIVE = "INACTIVE",
  /**
   * Users have a provisioned status when an account and role has been assigned to them, but the user has
   * not completed verification by registering through the activation email and logging into the system.
   */
  PROVISIONED = "PROVISIONED",
}

export type UserStatusOperationFilterInput = {
  eq?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  neq?: InputMaybe<UserStatus>;
  nin?: InputMaybe<Array<UserStatus>>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars["UUID"]>;
  gt?: InputMaybe<Scalars["UUID"]>;
  gte?: InputMaybe<Scalars["UUID"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  lt?: InputMaybe<Scalars["UUID"]>;
  lte?: InputMaybe<Scalars["UUID"]>;
  neq?: InputMaybe<Scalars["UUID"]>;
  ngt?: InputMaybe<Scalars["UUID"]>;
  ngte?: InputMaybe<Scalars["UUID"]>;
  nin?: InputMaybe<Array<InputMaybe<Scalars["UUID"]>>>;
  nlt?: InputMaybe<Scalars["UUID"]>;
  nlte?: InputMaybe<Scalars["UUID"]>;
};

/** A generic wrapper of a value within a draft scenario that is based on GAPYPOC and dollars and may be summed over multiple seasons' worth of values. */
export type Value = {
  __typename?: "Value";
  /** Converted dollar amount of GAPYPOC */
  dollars: Scalars["Float"];
  /** Guaranteed APY percent of cap */
  gapypoc: Scalars["Float"];
};

/** Video object contains data for viewing game film and associated metadata. */
export type Video = {
  __typename?: "Video";
  /** The expiration of the launch URL */
  expiration: Scalars["DateTime"];
  /** The URL to launch the video player to watch video for the game and its plays */
  launchUrl: Scalars["String"];
};

export type WeekType = {
  __typename?: "WeekType";
  description?: Maybe<Scalars["String"]>;
  gameStats: Array<GameStat>;
  games: Array<Game>;
  id: Scalars["Int"];
};

export type WeekTypeFilterInput = {
  and?: InputMaybe<Array<WeekTypeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  gameStats?: InputMaybe<ListFilterInputTypeOfGameStatFilterInput>;
  games?: InputMaybe<ListFilterInputTypeOfGameFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<WeekTypeFilterInput>>;
};

export type WeekTypeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type Workout = {
  __typename?: "Workout";
  /** All-star game identifier */
  allStarGameId?: Maybe<Scalars["Int"]>;
  /**
   * Blesto identifier
   * Scouting service some club's subscribe to
   */
  blestoId?: Maybe<Scalars["UUID"]>;
  /** Combine identifier */
  combineId?: Maybe<Scalars["Int"]>;
  /** Comments from source */
  comments?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  /** Players included in the workout */
  players: Array<WorkoutPlayerMeasure>;
  /** Pro Day identifier */
  proDayId?: Maybe<Scalars["Int"]>;
  rowVersion: Array<Scalars["Byte"]>;
  school?: Maybe<School>;
  /** School measurable taken for Pro Day */
  schoolId?: Maybe<Scalars["UUID"]>;
  /** Season of Workout */
  season: Scalars["Int"];
  /** Scout identifier */
  userId?: Maybe<Scalars["UUID"]>;
  /** Season of measurable source */
  workoutDate: Scalars["DateTime"];
  workoutType: WorkoutType;
  /** Workout types: All-star game, Combine, Pro Day, Blesto, Scout */
  workoutTypeId: Scalars["UUID"];
};

export type WorkoutFilterInput = {
  /** All-star game identifier */
  allStarGameId?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<WorkoutFilterInput>>;
  /**
   * Blesto identifier
   * Scouting service some club's subscribe to
   */
  blestoId?: InputMaybe<UuidOperationFilterInput>;
  /** Combine identifier */
  combineId?: InputMaybe<IntOperationFilterInput>;
  /** Comments from source */
  comments?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<WorkoutFilterInput>>;
  /** Players included in the workout */
  players?: InputMaybe<ListFilterInputTypeOfWorkoutPlayerMeasureFilterInput>;
  /** Pro Day identifier */
  proDayId?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  school?: InputMaybe<SchoolFilterInput>;
  /** School measurable taken for Pro Day */
  schoolId?: InputMaybe<UuidOperationFilterInput>;
  /** Season of Workout */
  season?: InputMaybe<IntOperationFilterInput>;
  /** Scout identifier */
  userId?: InputMaybe<UuidOperationFilterInput>;
  /** Season of measurable source */
  workoutDate?: InputMaybe<DateTimeOperationFilterInput>;
  workoutType?: InputMaybe<WorkoutTypeFilterInput>;
  /** Workout types: All-star game, Combine, Pro Day, Blesto, Scout */
  workoutTypeId?: InputMaybe<UuidOperationFilterInput>;
};

export type WorkoutMeasure = {
  __typename?: "WorkoutMeasure";
  measure: Scalars["Decimal"];
  name: Scalars["String"];
};

export type WorkoutPlayerMeasure = {
  __typename?: "WorkoutPlayerMeasure";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  /** Measures get marked as verified or estimate */
  isVerified: Scalars["Boolean"];
  lastUpdated: Scalars["DateTime"];
  measureType: MeasureType;
  /** Foreign key to MeasureType */
  measureTypeId: Scalars["UUID"];
  player: Player;
  /** Foreign key to Player */
  playerId: Scalars["UUID"];
  /** update an existing workout player measure.  Throws ValidationException if invalid. */
  process: WorkoutPlayerMeasure;
  rowVersion: Array<Scalars["Byte"]>;
  /** Measure value */
  value: Scalars["Decimal"];
  workout: Workout;
  /** Foreign key to Workout */
  workoutId: Scalars["UUID"];
};

export type WorkoutPlayerMeasureProcessArgs = {
  command: UpdateWorkoutPlayerMeasureWithDetailsCommandInput;
};

export type WorkoutPlayerMeasureDetailsInput = {
  id?: InputMaybe<Scalars["UUID"]>;
  isVerified: Scalars["Boolean"];
  measureTypeId: Scalars["UUID"];
  playerId: Scalars["UUID"];
  value: Scalars["Decimal"];
  workoutId: Scalars["UUID"];
};

export type WorkoutPlayerMeasureFilterInput = {
  and?: InputMaybe<Array<WorkoutPlayerMeasureFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  /** Measures get marked as verified or estimate */
  isVerified?: InputMaybe<BooleanOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  measureType?: InputMaybe<MeasureTypeFilterInput>;
  /** Foreign key to MeasureType */
  measureTypeId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<WorkoutPlayerMeasureFilterInput>>;
  player?: InputMaybe<PlayerFilterInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<UuidOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Measure value */
  value?: InputMaybe<DecimalOperationFilterInput>;
  workout?: InputMaybe<WorkoutFilterInput>;
  /** Foreign key to Workout */
  workoutId?: InputMaybe<UuidOperationFilterInput>;
};

export type WorkoutPlayerMeasureSortInput = {
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  /** Measures get marked as verified or estimate */
  isVerified?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  measureType?: InputMaybe<MeasureTypeSortInput>;
  /** Foreign key to MeasureType */
  measureTypeId?: InputMaybe<SortEnumType>;
  player?: InputMaybe<PlayerSortInput>;
  /** Foreign key to Player */
  playerId?: InputMaybe<SortEnumType>;
  /** Measure value */
  value?: InputMaybe<SortEnumType>;
  workout?: InputMaybe<WorkoutSortInput>;
  /** Foreign key to Workout */
  workoutId?: InputMaybe<SortEnumType>;
};

export type WorkoutSortInput = {
  /** All-star game identifier */
  allStarGameId?: InputMaybe<SortEnumType>;
  /**
   * Blesto identifier
   * Scouting service some club's subscribe to
   */
  blestoId?: InputMaybe<SortEnumType>;
  /** Combine identifier */
  combineId?: InputMaybe<SortEnumType>;
  /** Comments from source */
  comments?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Pro Day identifier */
  proDayId?: InputMaybe<SortEnumType>;
  school?: InputMaybe<SchoolSortInput>;
  /** School measurable taken for Pro Day */
  schoolId?: InputMaybe<SortEnumType>;
  /** Season of Workout */
  season?: InputMaybe<SortEnumType>;
  /** Scout identifier */
  userId?: InputMaybe<SortEnumType>;
  /** Season of measurable source */
  workoutDate?: InputMaybe<SortEnumType>;
  workoutType?: InputMaybe<WorkoutTypeSortInput>;
  /** Workout types: All-star game, Combine, Pro Day, Blesto, Scout */
  workoutTypeId?: InputMaybe<SortEnumType>;
};

export type WorkoutType = {
  __typename?: "WorkoutType";
  created: Scalars["DateTime"];
  /** Brief description of the source */
  description: Scalars["String"];
  id: Scalars["ID"];
  lastUpdated: Scalars["DateTime"];
  /** Source type name */
  name: Scalars["String"];
  /** Order of display/importance */
  priority: Scalars["Int"];
  rowVersion: Array<Scalars["Byte"]>;
  updateWorkoutType: WorkoutType;
};

export type WorkoutTypeUpdateWorkoutTypeArgs = {
  request: UpdateWorkoutTypeRequest;
};

export type WorkoutTypeFilterInput = {
  and?: InputMaybe<Array<WorkoutTypeFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Brief description of the source */
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  /** Source type name */
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkoutTypeFilterInput>>;
  /** Order of display/importance */
  priority?: InputMaybe<IntOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
};

export type WorkoutTypeSortInput = {
  created?: InputMaybe<SortEnumType>;
  /** Brief description of the source */
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Source type name */
  name?: InputMaybe<SortEnumType>;
  /** Order of display/importance */
  priority?: InputMaybe<SortEnumType>;
};

export type WorksheetItem = {
  __typename?: "WorksheetItem";
  /** Value that will override league's */
  amount?: Maybe<Scalars["Decimal"]>;
  /** Comments about value */
  comments: Scalars["String"];
  contract: Contract;
  /** Foreign key to Contract. Contract the seasons belong. */
  contractId: Scalars["UUID"];
  /**
   * In the context of NFL contracts, "contract conversion" or "restructuring" typically refers to
   * transforming a portion of a player's base salary or roster bonus into a signing bonus, which
   * allows the amount to be spread over the contract's duration for salary cap purposes. This strategy
   * provides short-term cap relief but could lead to future "dead money" if the player is released or
   * traded before the contract expires.
   */
  conversionType?: Maybe<ConversionTypeEnum>;
  created: Scalars["DateTime"];
  /** Day at which value is paid */
  day?: Maybe<Scalars["Int"]>;
  /** Is this value deferred */
  deferred: Scalars["Boolean"];
  /** Amount to be deferred */
  deferredAmount?: Maybe<Scalars["Decimal"]>;
  /** Date which money is paid for items marked deferred */
  deferredDate?: Maybe<Scalars["DateTime"]>;
  /** Whether there is guaranteed money */
  guaranteed: Scalars["Boolean"];
  /** Guaranteed monies */
  guaranteedAmount?: Maybe<Scalars["Decimal"]>;
  /** League guarantee type (or type of guarantee) */
  guaranteedType?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Item to override for the season */
  item: Scalars["String"];
  /** The league's label for a worksheet item. */
  label: Scalars["String"];
  lastUpdated: Scalars["DateTime"];
  /** Does this value prorate over multiple seasons */
  prorate: Scalars["Boolean"];
  /** Amount prorated if Prorate = true */
  proratedAmount?: Maybe<Scalars["Decimal"]>;
  rowVersion: Array<Scalars["Byte"]>;
  /** Season entry to override */
  season: Scalars["Int"];
  source?: Maybe<SourceTypeEnum>;
  update: WorksheetItem;
  /** Date which money is paid */
  vestingDate?: Maybe<Scalars["DateTime"]>;
  /** League year at which value is paid */
  vestingOption?: Maybe<Scalars["String"]>;
};

export type WorksheetItemUpdateArgs = {
  details: WorksheetItemDetailsInput;
};

export type WorksheetItemDetailsInput = {
  comments?: InputMaybe<Scalars["String"]>;
  contractId: Scalars["UUID"];
  conversionType?: InputMaybe<ConversionTypeEnum>;
  day?: InputMaybe<Scalars["Int"]>;
  deferred: Scalars["Boolean"];
  deferredAmount?: InputMaybe<Scalars["Decimal"]>;
  deferredDate?: InputMaybe<Scalars["DateTime"]>;
  guaranteed: Scalars["Boolean"];
  guaranteedType?: InputMaybe<Scalars["String"]>;
  guaranteedValue?: InputMaybe<Scalars["Decimal"]>;
  item: Scalars["String"];
  label: Scalars["String"];
  prorate: Scalars["Boolean"];
  proratedValue?: InputMaybe<Scalars["Decimal"]>;
  season: Scalars["Int"];
  source: SourceTypeEnum;
  value?: InputMaybe<Scalars["Decimal"]>;
  vestingDate?: InputMaybe<Scalars["DateTime"]>;
  vestingOption?: InputMaybe<Scalars["String"]>;
};

export type WorksheetItemFilterInput = {
  /** Value that will override league's */
  amount?: InputMaybe<DecimalOperationFilterInput>;
  and?: InputMaybe<Array<WorksheetItemFilterInput>>;
  /** Comments about value */
  comments?: InputMaybe<StringOperationFilterInput>;
  contract?: InputMaybe<ContractFilterInput>;
  /** Foreign key to Contract. Contract the seasons belong. */
  contractId?: InputMaybe<UuidOperationFilterInput>;
  /**
   * In the context of NFL contracts, "contract conversion" or "restructuring" typically refers to
   * transforming a portion of a player's base salary or roster bonus into a signing bonus, which
   * allows the amount to be spread over the contract's duration for salary cap purposes. This strategy
   * provides short-term cap relief but could lead to future "dead money" if the player is released or
   * traded before the contract expires.
   */
  conversionType?: InputMaybe<NullableOfConversionTypeEnumOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  /** Day at which value is paid */
  day?: InputMaybe<IntOperationFilterInput>;
  /** Is this value deferred */
  deferred?: InputMaybe<BooleanOperationFilterInput>;
  /** Amount to be deferred */
  deferredAmount?: InputMaybe<DecimalOperationFilterInput>;
  /** Date which money is paid for items marked deferred */
  deferredDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** Whether there is guaranteed money */
  guaranteed?: InputMaybe<BooleanOperationFilterInput>;
  /** Guaranteed monies */
  guaranteedAmount?: InputMaybe<DecimalOperationFilterInput>;
  /** League guarantee type (or type of guarantee) */
  guaranteedType?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  /** Item to override for the season */
  item?: InputMaybe<StringOperationFilterInput>;
  /** The league's label for a worksheet item. */
  label?: InputMaybe<StringOperationFilterInput>;
  lastUpdated?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<WorksheetItemFilterInput>>;
  /** Does this value prorate over multiple seasons */
  prorate?: InputMaybe<BooleanOperationFilterInput>;
  /** Amount prorated if Prorate = true */
  proratedAmount?: InputMaybe<DecimalOperationFilterInput>;
  rowVersion?: InputMaybe<ListByteOperationFilterInput>;
  /** Season entry to override */
  season?: InputMaybe<IntOperationFilterInput>;
  source?: InputMaybe<NullableOfSourceTypeEnumOperationFilterInput>;
  /** Date which money is paid */
  vestingDate?: InputMaybe<DateTimeOperationFilterInput>;
  /** League year at which value is paid */
  vestingOption?: InputMaybe<StringOperationFilterInput>;
};

export type WorksheetItemSortInput = {
  /** Value that will override league's */
  amount?: InputMaybe<SortEnumType>;
  /** Comments about value */
  comments?: InputMaybe<SortEnumType>;
  contract?: InputMaybe<ContractSortInput>;
  /** Foreign key to Contract. Contract the seasons belong. */
  contractId?: InputMaybe<SortEnumType>;
  /**
   * In the context of NFL contracts, "contract conversion" or "restructuring" typically refers to
   * transforming a portion of a player's base salary or roster bonus into a signing bonus, which
   * allows the amount to be spread over the contract's duration for salary cap purposes. This strategy
   * provides short-term cap relief but could lead to future "dead money" if the player is released or
   * traded before the contract expires.
   */
  conversionType?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  /** Day at which value is paid */
  day?: InputMaybe<SortEnumType>;
  /** Is this value deferred */
  deferred?: InputMaybe<SortEnumType>;
  /** Amount to be deferred */
  deferredAmount?: InputMaybe<SortEnumType>;
  /** Date which money is paid for items marked deferred */
  deferredDate?: InputMaybe<SortEnumType>;
  /** Whether there is guaranteed money */
  guaranteed?: InputMaybe<SortEnumType>;
  /** Guaranteed monies */
  guaranteedAmount?: InputMaybe<SortEnumType>;
  /** League guarantee type (or type of guarantee) */
  guaranteedType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  /** Item to override for the season */
  item?: InputMaybe<SortEnumType>;
  /** The league's label for a worksheet item. */
  label?: InputMaybe<SortEnumType>;
  lastUpdated?: InputMaybe<SortEnumType>;
  /** Does this value prorate over multiple seasons */
  prorate?: InputMaybe<SortEnumType>;
  /** Amount prorated if Prorate = true */
  proratedAmount?: InputMaybe<SortEnumType>;
  /** Season entry to override */
  season?: InputMaybe<SortEnumType>;
  source?: InputMaybe<SortEnumType>;
  /** Date which money is paid */
  vestingDate?: InputMaybe<SortEnumType>;
  /** League year at which value is paid */
  vestingOption?: InputMaybe<SortEnumType>;
};

export type Contracts_Internal_Agent = {
  __typename?: "contracts_internal_Agent";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  cellPhoneNumber?: Maybe<Scalars["String"]>;
  certified?: Maybe<Scalars["Int"]>;
  city?: Maybe<Scalars["String"]>;
  company?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  email?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isDeleted: Scalars["Boolean"];
  lastName?: Maybe<Scalars["String"]>;
  lastUpdated: Scalars["DateTime"];
  leagueAgentId?: Maybe<Scalars["Int"]>;
  middleName?: Maybe<Scalars["String"]>;
  rowVersion: Array<Scalars["Byte"]>;
  state?: Maybe<Scalars["String"]>;
  update: Contracts_Internal_Agent;
  workPhone?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type Contracts_Internal_AgentUpdateArgs = {
  details: AgentDetailsInput;
};

export type AdssignmentPlayerListFragment = {
  __typename?: "Player";
  id: string;
  footballName?: string | null;
  firstName: string;
  lastName: string;
  jersey?: string | null;
  eligibilityYear?: number | null;
  school?: {
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
  } | null;
  club?: { __typename?: "Club"; id: string; clubCode: string } | null;
};

export type GetAdminScoutingAssignmentsForUserQueryVariables = Exact<{
  userId: Scalars["UUID"];
  scoutType: ScoutTypeEnum;
}>;

export type GetAdminScoutingAssignmentsForUserQuery = {
  __typename?: "Query";
  scoutingAssignments: Array<{
    __typename?: "ScoutingAssignment";
    id: string;
    userId: string;
    scoutType: ScoutTypeEnum;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
    position?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    player?: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      headshotUrl?: string | null;
      draftYear?: number | null;
      eligibilityYear?: number | null;
      accruedSeasons?: number | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
      } | null;
      club?: { __typename?: "Club"; id: string; clubCode: string } | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        season: number;
        status: EvalStatusEnum;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
      }>;
    } | null;
  }>;
};

export type GetProPlayerListQueryVariables = Exact<{
  sortCriteria: PlayerSearchSortCriteriaEnum;
  positionId: Scalars["UUID"];
  clubIds: Array<InputMaybe<Scalars["UUID"]>> | InputMaybe<Scalars["UUID"]>;
}>;

export type GetProPlayerListQuery = {
  __typename?: "Query";
  players: Array<{
    __typename?: "Player";
    id: string;
    footballName?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    eligibilityYear?: number | null;
    school?: {
      __typename?: "School";
      id: string;
      schoolCode?: string | null;
    } | null;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
  }>;
};

export type GetCollegePlayerListQueryVariables = Exact<{
  sortCriteria: PlayerSearchSortCriteriaEnum;
  positionId: Scalars["UUID"];
}>;

export type GetCollegePlayerListQuery = {
  __typename?: "Query";
  players: Array<{
    __typename?: "Player";
    id: string;
    footballName?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    eligibilityYear?: number | null;
    school?: {
      __typename?: "School";
      id: string;
      schoolCode?: string | null;
    } | null;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
  }>;
};

export type AdminScoutingAssignmentFieldsFragment = {
  __typename?: "ScoutingAssignment";
  id: string;
  userId: string;
  scoutType: ScoutTypeEnum;
  club?: { __typename?: "Club"; id: string; clubCode: string } | null;
  position?: { __typename?: "Position"; id: string; shortName: string } | null;
  player?: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    headshotUrl?: string | null;
    draftYear?: number | null;
    eligibilityYear?: number | null;
    accruedSeasons?: number | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    school?: {
      __typename?: "School";
      id: string;
      schoolCode?: string | null;
    } | null;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      season: number;
      status: EvalStatusEnum;
      lastUpdated: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
    }>;
  } | null;
};

export type ProTraitGroupGradeFragment = {
  __typename?: "EvaluationTraitGroup";
  comments?: string | null;
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  traitGroup: {
    __typename?: "TraitGroup";
    id: string;
    caption: string;
    sort: number;
  };
};

export type ProTraitGradeFragment = {
  __typename?: "EvaluationTrait";
  comments?: string | null;
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  traitDefinition: {
    __typename?: "TraitDefinition";
    id: string;
    caption: string;
    sort: number;
  };
};

export type ProEvaluationFragment = {
  __typename?: "Evaluation";
  id: string;
  season: number;
  lastUpdated: string;
  bottomLine?: string | null;
  summary?: string | null;
  position: { __typename?: "Position"; id: string; shortName: string };
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  clubFits: Array<{
    __typename?: "EvaluationClubFit";
    club: { __typename?: "Club"; clubCode: string };
  }>;
  traitGroupGrades: Array<{
    __typename?: "EvaluationTraitGroup";
    comments?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    traitGroup: {
      __typename?: "TraitGroup";
      id: string;
      caption: string;
      sort: number;
    };
  }>;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    comments?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    traitDefinition: {
      __typename?: "TraitDefinition";
      id: string;
      caption: string;
      sort: number;
    };
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    gameId: string;
    lastUpdated: string;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  }>;
  user: { __typename?: "User"; id: string; lastName: string };
};

export type ProEvaluationPlayerFragment = {
  __typename?: "Player";
  id: string;
  jersey?: string | null;
  firstName: string;
  lastName: string;
  headshotUrl?: string | null;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    season: number;
    lastUpdated: string;
    bottomLine?: string | null;
    summary?: string | null;
    position: { __typename?: "Position"; id: string; shortName: string };
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    clubFits: Array<{
      __typename?: "EvaluationClubFit";
      club: { __typename?: "Club"; clubCode: string };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitGroup: {
        __typename?: "TraitGroup";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      gameId: string;
      lastUpdated: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    }>;
    user: { __typename?: "User"; id: string; lastName: string };
  }>;
};

export type GetProRosterEvaluationsQueryVariables = Exact<{
  clubCode?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
}>;

export type GetProRosterEvaluationsQuery = {
  __typename?: "Query";
  players: Array<{
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    headshotUrl?: string | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      season: number;
      lastUpdated: string;
      bottomLine?: string | null;
      summary?: string | null;
      position: { __typename?: "Position"; id: string; shortName: string };
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        id: string;
        evaluationId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          sort?: number | null;
        };
      }>;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      clubFits: Array<{
        __typename?: "EvaluationClubFit";
        club: { __typename?: "Club"; clubCode: string };
      }>;
      traitGroupGrades: Array<{
        __typename?: "EvaluationTraitGroup";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitGroup: {
          __typename?: "TraitGroup";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      traitGrades: Array<{
        __typename?: "EvaluationTrait";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      }>;
      user: { __typename?: "User"; id: string; lastName: string };
    }>;
  }>;
};

export type BigBoardEvalSummaryFragment = {
  __typename?: "Evaluation";
  id: string;
  created: string;
  lastUpdated: string;
  season: number;
  isPrimary: boolean;
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  player: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    draftYear?: number | null;
    redshirt: boolean;
    eligibility?: EligibilityType | null;
    latestEligibility?: EligibilityType | null;
    eligibilityYear?: number | null;
    latestEligibilityYear?: number | null;
    latestSchoolId?: string | null;
    schoolId?: string | null;
    headshotUrl?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
    club?: {
      __typename?: "Club";
      id: string;
      division: string;
      clubCode: string;
    } | null;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
  };
  position: {
    __typename?: "Position";
    id: string;
    shortName: string;
    sort?: number | null;
  };
  evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
};

export type BoardPlayerWarFragment = {
  __typename?: "RawAiGrade";
  marvalPlayerId?: string | null;
  season?: number | null;
  xWar?: number | null;
  war?: number | null;
};

export type GetBigBoardEvalSummariesQueryVariables = Exact<{
  position?: InputMaybe<Scalars["UUID"]>;
  user?: InputMaybe<Scalars["UUID"]>;
  playerId?: InputMaybe<Scalars["UUID"]>;
  group?: InputMaybe<EvalTypeEnum>;
}>;

export type GetBigBoardEvalSummariesQuery = {
  __typename?: "Query";
  evals: Array<{
    __typename?: "Evaluation";
    id: string;
    created: string;
    lastUpdated: string;
    season: number;
    isPrimary: boolean;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    player: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      draftYear?: number | null;
      redshirt: boolean;
      eligibility?: EligibilityType | null;
      latestEligibility?: EligibilityType | null;
      eligibilityYear?: number | null;
      latestEligibilityYear?: number | null;
      latestSchoolId?: string | null;
      schoolId?: string | null;
      headshotUrl?: string | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      playerOverrides: Array<{
        __typename?: "PlayerOverride";
        field: string;
        value?: string | null;
      }>;
      club?: {
        __typename?: "Club";
        id: string;
        division: string;
        clubCode: string;
      } | null;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        id: string;
        playerId: string;
        value: number;
        isVerified: boolean;
        workout: {
          __typename?: "Workout";
          id: string;
          comments?: string | null;
          workoutDate: string;
          workoutType: {
            __typename?: "WorkoutType";
            name: string;
            priority: number;
          };
        };
        measureType: {
          __typename?: "MeasureType";
          shortName: string;
          name: string;
          isFraction: boolean;
        };
      }>;
    };
    position: {
      __typename?: "Position";
      id: string;
      shortName: string;
      sort?: number | null;
    };
    evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
  }>;
};

export type GetLatestWarForPlayersQueryVariables = Exact<{
  playerIds: Array<InputMaybe<Scalars["UUID"]>> | InputMaybe<Scalars["UUID"]>;
  league: Scalars["String"];
}>;

export type GetLatestWarForPlayersQuery = {
  __typename?: "Query";
  playerAdvancedAnalytics: Array<{
    __typename?: "RawAiGrade";
    marvalPlayerId?: string | null;
    season?: number | null;
    xWar?: number | null;
    war?: number | null;
  }>;
};

export type CollegeBoardPlayerEvalFragment = {
  __typename?: "Evaluation";
  id: string;
  lastUpdated: string;
  season: number;
  isPrimary: boolean;
  status: EvalStatusEnum;
  finalizedOn?: string | null;
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  user: { __typename?: "User"; firstName: string; lastName: string };
  player: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    eligibility?: EligibilityType | null;
    latestEligibility?: EligibilityType | null;
    eligibilityYear?: number | null;
    latestEligibilityYear?: number | null;
    birthDate?: string | null;
    latestBirthDate?: string | null;
    redshirt: boolean;
    latestSchoolId?: string | null;
    schoolId?: string | null;
    draftYear?: number | null;
    headshotUrl?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
  };
  position: {
    __typename?: "Position";
    id: string;
    shortName: string;
    sort?: number | null;
  };
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    scoutGradeId?: string | null;
  }>;
};

export type EvalBoardTraitGradeFragment = {
  __typename?: "EvaluationTrait";
  id: string;
  scoutGradeId?: string | null;
  traitDefinition: { __typename?: "TraitDefinition"; id: string };
};

export type GetCollegeBoardQueryVariables = Exact<{
  position?: InputMaybe<Scalars["UUID"]>;
  user?: InputMaybe<Scalars["UUID"]>;
  playerId?: InputMaybe<Scalars["UUID"]>;
  group?: InputMaybe<EvalTypeEnum>;
}>;

export type GetCollegeBoardQuery = {
  __typename?: "Query";
  evals: Array<{
    __typename?: "Evaluation";
    id: string;
    lastUpdated: string;
    season: number;
    isPrimary: boolean;
    status: EvalStatusEnum;
    finalizedOn?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    user: { __typename?: "User"; firstName: string; lastName: string };
    player: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      eligibility?: EligibilityType | null;
      latestEligibility?: EligibilityType | null;
      eligibilityYear?: number | null;
      latestEligibilityYear?: number | null;
      birthDate?: string | null;
      latestBirthDate?: string | null;
      redshirt: boolean;
      latestSchoolId?: string | null;
      schoolId?: string | null;
      draftYear?: number | null;
      headshotUrl?: string | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      playerOverrides: Array<{
        __typename?: "PlayerOverride";
        field: string;
        value?: string | null;
      }>;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        id: string;
        playerId: string;
        value: number;
        isVerified: boolean;
        workout: {
          __typename?: "Workout";
          id: string;
          comments?: string | null;
          workoutDate: string;
          workoutType: {
            __typename?: "WorkoutType";
            name: string;
            priority: number;
          };
        };
        measureType: {
          __typename?: "MeasureType";
          shortName: string;
          name: string;
          isFraction: boolean;
        };
      }>;
    };
    position: {
      __typename?: "Position";
      id: string;
      shortName: string;
      sort?: number | null;
    };
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      scoutGradeId?: string | null;
    }>;
  }>;
};

export type ProListEvaluationFragment = {
  __typename?: "Evaluation";
  id: string;
  season: number;
  lastUpdated: string;
  isFinal: boolean;
  finalizedOn?: string | null;
  status: EvalStatusEnum;
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  player: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    footballName?: string | null;
    firstName: string;
    lastName: string;
    draftYear?: number | null;
    headshotUrl?: string | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    status: PlayerStatus;
    club?: {
      __typename?: "Club";
      id: string;
      division: string;
      clubCode: string;
    } | null;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
  };
  position: {
    __typename?: "Position";
    id: string;
    shortName: string;
    sort?: number | null;
  };
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    scoutGradeId?: string | null;
  }>;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    id: string;
    scoutGradeId?: string | null;
    comments?: string | null;
    traitDefinition: { __typename?: "TraitDefinition"; id: string };
  }>;
};

export type CollegeListEvaluationFragment = {
  __typename?: "Evaluation";
  id: string;
  season: number;
  lastUpdated: string;
  isFinal: boolean;
  finalizedOn?: string | null;
  status: EvalStatusEnum;
  isPrimary: boolean;
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
  player: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    draftYear?: number | null;
    eligibility?: EligibilityType | null;
    latestEligibility?: EligibilityType | null;
    eligibilityYear?: number | null;
    latestEligibilityYear?: number | null;
    latestBirthDate?: string | null;
    redshirt: boolean;
    latestSchoolId?: string | null;
    schoolId?: string | null;
    headshotUrl?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
  };
  position: {
    __typename?: "Position";
    id: string;
    shortName: string;
    sort?: number | null;
  };
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    scoutGradeId?: string | null;
  }>;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    id: string;
    scoutGradeId?: string | null;
    comments?: string | null;
    traitDefinition: { __typename?: "TraitDefinition"; id: string };
  }>;
};

export type GetProEvaluationListQueryVariables = Exact<{
  group?: InputMaybe<EvalTypeEnum>;
  season?: InputMaybe<Scalars["Int"]>;
}>;

export type GetProEvaluationListQuery = {
  __typename?: "Query";
  evals: Array<{
    __typename?: "Evaluation";
    id: string;
    season: number;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    status: EvalStatusEnum;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    player: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      footballName?: string | null;
      firstName: string;
      lastName: string;
      draftYear?: number | null;
      headshotUrl?: string | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      status: PlayerStatus;
      club?: {
        __typename?: "Club";
        id: string;
        division: string;
        clubCode: string;
      } | null;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        id: string;
        playerId: string;
        value: number;
        isVerified: boolean;
        workout: {
          __typename?: "Workout";
          id: string;
          comments?: string | null;
          workoutDate: string;
          workoutType: {
            __typename?: "WorkoutType";
            name: string;
            priority: number;
          };
        };
        measureType: {
          __typename?: "MeasureType";
          shortName: string;
          name: string;
          isFraction: boolean;
        };
      }>;
    };
    position: {
      __typename?: "Position";
      id: string;
      shortName: string;
      sort?: number | null;
    };
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      scoutGradeId?: string | null;
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      scoutGradeId?: string | null;
      comments?: string | null;
      traitDefinition: { __typename?: "TraitDefinition"; id: string };
    }>;
  }>;
};

export type GetCollegeEvaluationListQueryVariables = Exact<{
  group?: InputMaybe<EvalTypeEnum>;
  eligibilityYear?: InputMaybe<Scalars["Int"]>;
}>;

export type GetCollegeEvaluationListQuery = {
  __typename?: "Query";
  evals: Array<{
    __typename?: "Evaluation";
    id: string;
    season: number;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    status: EvalStatusEnum;
    isPrimary: boolean;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
    player: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      draftYear?: number | null;
      eligibility?: EligibilityType | null;
      latestEligibility?: EligibilityType | null;
      eligibilityYear?: number | null;
      latestEligibilityYear?: number | null;
      latestBirthDate?: string | null;
      redshirt: boolean;
      latestSchoolId?: string | null;
      schoolId?: string | null;
      headshotUrl?: string | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      playerOverrides: Array<{
        __typename?: "PlayerOverride";
        field: string;
        value?: string | null;
      }>;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        id: string;
        playerId: string;
        value: number;
        isVerified: boolean;
        workout: {
          __typename?: "Workout";
          id: string;
          comments?: string | null;
          workoutDate: string;
          workoutType: {
            __typename?: "WorkoutType";
            name: string;
            priority: number;
          };
        };
        measureType: {
          __typename?: "MeasureType";
          shortName: string;
          name: string;
          isFraction: boolean;
        };
      }>;
    };
    position: {
      __typename?: "Position";
      id: string;
      shortName: string;
      sort?: number | null;
    };
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      scoutGradeId?: string | null;
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      scoutGradeId?: string | null;
      comments?: string | null;
      traitDefinition: { __typename?: "TraitDefinition"; id: string };
    }>;
  }>;
};

export type FreeAgencyEvalFragment = {
  __typename?: "Evaluation";
  id: string;
  isPrimary: boolean;
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
};

export type FreeAgencyPlayerFragment = {
  __typename?: "Player";
  id: string;
  firstName: string;
  lastName: string;
  jersey?: string | null;
  draftYear?: number | null;
  birthDate?: string | null;
  latestBirthDate?: string | null;
  headshotUrl?: string | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  freeAgencyStatus?: FreeAgencyStatus | null;
  club?: { __typename?: "Club"; id: string; clubCode: string } | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    isPrimary: boolean;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
  }>;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
  sumerGeneralPosition?: {
    __typename?: "Position";
    shortName: string;
    sort?: number | null;
  } | null;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
};

export type GetFreeAgencyPlayersQueryVariables = Exact<{
  sortCriteria: PlayerSearchSortCriteriaEnum;
}>;

export type GetFreeAgencyPlayersQuery = {
  __typename?: "Query";
  players: Array<{
    __typename?: "Player";
    id: string;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    birthDate?: string | null;
    latestBirthDate?: string | null;
    headshotUrl?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      isPrimary: boolean;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        id: string;
        evaluationId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          sort?: number | null;
        };
      }>;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
      sort?: number | null;
    } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  }>;
};

export type GetPlayerSageQueryVariables = Exact<{
  season: Scalars["Int"];
  playerIds: Array<InputMaybe<Scalars["UUID"]>> | InputMaybe<Scalars["UUID"]>;
}>;

export type GetPlayerSageQuery = {
  __typename?: "Query";
  playerSage: Array<{
    __typename?: "SageGradesToDollar";
    marvalPlayerId: string;
    season: number;
    sumerGeneralPosition: string;
    gapypocPrediction: number;
  }>;
};

export type AssignedPlayerFragment = {
  __typename?: "Player";
  id: string;
  jersey?: string | null;
  firstName: string;
  lastName: string;
  footballName?: string | null;
  headshotUrl?: string | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  draftYear?: number | null;
  freeAgencyStatus?: FreeAgencyStatus | null;
  eligibility?: EligibilityType | null;
  latestEligibility?: EligibilityType | null;
  eligibilityYear?: number | null;
  latestEligibilityYear?: number | null;
  latestBirthDate?: string | null;
  redshirt: boolean;
  latestSchoolId?: string | null;
  schoolId?: string | null;
  highSchoolGradYear?: number | null;
  status: PlayerStatus;
  playerOverrides: Array<{
    __typename?: "PlayerOverride";
    field: string;
    value?: string | null;
  }>;
  club?: { __typename?: "Club"; id: string; clubCode: string } | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    season: number;
    lastUpdated: string;
    status: EvalStatusEnum;
    finalizedOn?: string | null;
    isPrimary: boolean;
    isFinal: boolean;
    user: { __typename?: "User"; id: string };
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    position: { __typename?: "Position"; id: string; shortName: string };
    alerts: Array<{
      __typename?: "EvaluationAlert";
      alert: { __typename?: "Alert"; abbreviation: string; caption: string };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      fit: { __typename?: "Fit"; name: string };
    }>;
    gameGrades: Array<{ __typename?: "EvaluationGame"; id: string }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      scoutGradeId?: string | null;
      comments?: string | null;
      traitDefinition: { __typename?: "TraitDefinition"; id: string };
    }>;
    evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
  }>;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
  school?: {
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
  } | null;
  tags: Array<{
    __typename?: "PlayerTag";
    tag: { __typename?: "Tag"; name: string };
  }>;
};

export type AssignedPlayerEvaluationFragment = {
  __typename?: "Evaluation";
  season: number;
  lastUpdated: string;
  status: EvalStatusEnum;
  finalizedOn?: string | null;
  isPrimary: boolean;
  isFinal: boolean;
  user: { __typename?: "User"; id: string };
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  position: { __typename?: "Position"; id: string; shortName: string };
  alerts: Array<{
    __typename?: "EvaluationAlert";
    alert: { __typename?: "Alert"; abbreviation: string; caption: string };
  }>;
  fits: Array<{
    __typename?: "EvaluationFit";
    fit: { __typename?: "Fit"; name: string };
  }>;
  gameGrades: Array<{ __typename?: "EvaluationGame"; id: string }>;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    id: string;
    scoutGradeId?: string | null;
    comments?: string | null;
    traitDefinition: { __typename?: "TraitDefinition"; id: string };
  }>;
  evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
};

export type GetAssignedCollegePlayersQueryVariables = Exact<{
  userId: Scalars["UUID"];
}>;

export type GetAssignedCollegePlayersQuery = {
  __typename?: "Query";
  scoutingAssignments: Array<{
    __typename?: "ScoutingAssignment";
    id: string;
    userId: string;
    playerId?: string | null;
    scoutType: ScoutTypeEnum;
    player?: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      footballName?: string | null;
      headshotUrl?: string | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      draftYear?: number | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      eligibility?: EligibilityType | null;
      latestEligibility?: EligibilityType | null;
      eligibilityYear?: number | null;
      latestEligibilityYear?: number | null;
      latestBirthDate?: string | null;
      redshirt: boolean;
      latestSchoolId?: string | null;
      schoolId?: string | null;
      highSchoolGradYear?: number | null;
      status: PlayerStatus;
      playerOverrides: Array<{
        __typename?: "PlayerOverride";
        field: string;
        value?: string | null;
      }>;
      club?: { __typename?: "Club"; id: string; clubCode: string } | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        season: number;
        lastUpdated: string;
        status: EvalStatusEnum;
        finalizedOn?: string | null;
        isPrimary: boolean;
        isFinal: boolean;
        user: { __typename?: "User"; id: string };
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        position: { __typename?: "Position"; id: string; shortName: string };
        alerts: Array<{
          __typename?: "EvaluationAlert";
          alert: {
            __typename?: "Alert";
            abbreviation: string;
            caption: string;
          };
        }>;
        fits: Array<{
          __typename?: "EvaluationFit";
          fit: { __typename?: "Fit"; name: string };
        }>;
        gameGrades: Array<{ __typename?: "EvaluationGame"; id: string }>;
        traitGrades: Array<{
          __typename?: "EvaluationTrait";
          id: string;
          scoutGradeId?: string | null;
          comments?: string | null;
          traitDefinition: { __typename?: "TraitDefinition"; id: string };
        }>;
        evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
      }>;
      sumerGeneralPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        id: string;
        playerId: string;
        value: number;
        isVerified: boolean;
        workout: {
          __typename?: "Workout";
          id: string;
          comments?: string | null;
          workoutDate: string;
          workoutType: {
            __typename?: "WorkoutType";
            name: string;
            priority: number;
          };
        };
        measureType: {
          __typename?: "MeasureType";
          shortName: string;
          name: string;
          isFraction: boolean;
        };
      }>;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
      } | null;
      tags: Array<{
        __typename?: "PlayerTag";
        tag: { __typename?: "Tag"; name: string };
      }>;
    } | null;
  }>;
};

export type GetAssignedProPlayersQueryVariables = Exact<{
  userId: Scalars["UUID"];
}>;

export type GetAssignedProPlayersQuery = {
  __typename?: "Query";
  scoutingAssignments: Array<{
    __typename?: "ScoutingAssignment";
    id: string;
    userId: string;
    playerId?: string | null;
    scoutType: ScoutTypeEnum;
    player?: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      footballName?: string | null;
      headshotUrl?: string | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      draftYear?: number | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      eligibility?: EligibilityType | null;
      latestEligibility?: EligibilityType | null;
      eligibilityYear?: number | null;
      latestEligibilityYear?: number | null;
      latestBirthDate?: string | null;
      redshirt: boolean;
      latestSchoolId?: string | null;
      schoolId?: string | null;
      highSchoolGradYear?: number | null;
      status: PlayerStatus;
      playerOverrides: Array<{
        __typename?: "PlayerOverride";
        field: string;
        value?: string | null;
      }>;
      club?: { __typename?: "Club"; id: string; clubCode: string } | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        season: number;
        lastUpdated: string;
        status: EvalStatusEnum;
        finalizedOn?: string | null;
        isPrimary: boolean;
        isFinal: boolean;
        user: { __typename?: "User"; id: string };
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        position: { __typename?: "Position"; id: string; shortName: string };
        alerts: Array<{
          __typename?: "EvaluationAlert";
          alert: {
            __typename?: "Alert";
            abbreviation: string;
            caption: string;
          };
        }>;
        fits: Array<{
          __typename?: "EvaluationFit";
          fit: { __typename?: "Fit"; name: string };
        }>;
        gameGrades: Array<{ __typename?: "EvaluationGame"; id: string }>;
        traitGrades: Array<{
          __typename?: "EvaluationTrait";
          id: string;
          scoutGradeId?: string | null;
          comments?: string | null;
          traitDefinition: { __typename?: "TraitDefinition"; id: string };
        }>;
        evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
      }>;
      sumerGeneralPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        id: string;
        playerId: string;
        value: number;
        isVerified: boolean;
        workout: {
          __typename?: "Workout";
          id: string;
          comments?: string | null;
          workoutDate: string;
          workoutType: {
            __typename?: "WorkoutType";
            name: string;
            priority: number;
          };
        };
        measureType: {
          __typename?: "MeasureType";
          shortName: string;
          name: string;
          isFraction: boolean;
        };
      }>;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
      } | null;
      tags: Array<{
        __typename?: "PlayerTag";
        tag: { __typename?: "Tag"; name: string };
      }>;
    } | null;
  }>;
};

export type GetAssignedPortalPlayersQueryVariables = Exact<{
  userId: Scalars["UUID"];
}>;

export type GetAssignedPortalPlayersQuery = {
  __typename?: "Query";
  scoutingAssignments: Array<{
    __typename?: "ScoutingAssignment";
    id: string;
    userId: string;
    playerId?: string | null;
    scoutType: ScoutTypeEnum;
    player?: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      footballName?: string | null;
      headshotUrl?: string | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      draftYear?: number | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      eligibility?: EligibilityType | null;
      latestEligibility?: EligibilityType | null;
      eligibilityYear?: number | null;
      latestEligibilityYear?: number | null;
      latestBirthDate?: string | null;
      redshirt: boolean;
      latestSchoolId?: string | null;
      schoolId?: string | null;
      highSchoolGradYear?: number | null;
      status: PlayerStatus;
      playerOverrides: Array<{
        __typename?: "PlayerOverride";
        field: string;
        value?: string | null;
      }>;
      club?: { __typename?: "Club"; id: string; clubCode: string } | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        season: number;
        lastUpdated: string;
        status: EvalStatusEnum;
        finalizedOn?: string | null;
        isPrimary: boolean;
        isFinal: boolean;
        user: { __typename?: "User"; id: string };
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        position: { __typename?: "Position"; id: string; shortName: string };
        alerts: Array<{
          __typename?: "EvaluationAlert";
          alert: {
            __typename?: "Alert";
            abbreviation: string;
            caption: string;
          };
        }>;
        fits: Array<{
          __typename?: "EvaluationFit";
          fit: { __typename?: "Fit"; name: string };
        }>;
        gameGrades: Array<{ __typename?: "EvaluationGame"; id: string }>;
        traitGrades: Array<{
          __typename?: "EvaluationTrait";
          id: string;
          scoutGradeId?: string | null;
          comments?: string | null;
          traitDefinition: { __typename?: "TraitDefinition"; id: string };
        }>;
        evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
      }>;
      sumerGeneralPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        id: string;
        playerId: string;
        value: number;
        isVerified: boolean;
        workout: {
          __typename?: "Workout";
          id: string;
          comments?: string | null;
          workoutDate: string;
          workoutType: {
            __typename?: "WorkoutType";
            name: string;
            priority: number;
          };
        };
        measureType: {
          __typename?: "MeasureType";
          shortName: string;
          name: string;
          isFraction: boolean;
        };
      }>;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
      } | null;
      tags: Array<{
        __typename?: "PlayerTag";
        tag: { __typename?: "Tag"; name: string };
      }>;
    } | null;
  }>;
};

export type GetNflCutdownPredictionsQueryVariables = Exact<{
  playerIds: Array<InputMaybe<Scalars["UUID"]>> | InputMaybe<Scalars["UUID"]>;
}>;

export type GetNflCutdownPredictionsQuery = {
  __typename?: "Query";
  nflCutdownPredictions: Array<{
    __typename?: "NflCutdownPrediction";
    marvalPlayerId: string;
    cutdownBucket: string;
    cutProb: number;
  }>;
};

export type TaggedPlayerFragment = {
  __typename?: "Player";
  id: string;
  jersey?: string | null;
  firstName: string;
  lastName: string;
  headshotUrl?: string | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  draftYear?: number | null;
  eligibilityYear?: number | null;
  latestEligibilityYear?: number | null;
  birthDate?: string | null;
  latestBirthDate?: string | null;
  latestSchoolId?: string | null;
  schoolId?: string | null;
  eligibility?: EligibilityType | null;
  latestEligibility?: EligibilityType | null;
  redshirt: boolean;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  highschool?: string | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
  tags: Array<{
    __typename?: "PlayerTag";
    sort?: number | null;
    tag: { __typename?: "Tag"; id: string; name: string };
  }>;
  evaluations: Array<{
    __typename?: "Evaluation";
    isPrimary: boolean;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
  }>;
  mostRecentClub?: { __typename?: "Club"; id: string; clubCode: string } | null;
  playerOverrides: Array<{
    __typename?: "PlayerOverride";
    field: string;
    value?: string | null;
  }>;
};

export type GetTaggedPlayersQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  tagId: Scalars["UUID"];
  name?: InputMaybe<Scalars["String"]>;
  sortCriteria?: InputMaybe<TaggedPlayerSortCriteriaEnum>;
}>;

export type GetTaggedPlayersQuery = {
  __typename?: "Query";
  taggedPlayers?: {
    __typename?: "TaggedPlayersConnection";
    totalCount: number;
    edges?: Array<{
      __typename?: "TaggedPlayersEdge";
      node: {
        __typename?: "Player";
        id: string;
        jersey?: string | null;
        firstName: string;
        lastName: string;
        headshotUrl?: string | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        draftYear?: number | null;
        eligibilityYear?: number | null;
        latestEligibilityYear?: number | null;
        birthDate?: string | null;
        latestBirthDate?: string | null;
        latestSchoolId?: string | null;
        schoolId?: string | null;
        eligibility?: EligibilityType | null;
        latestEligibility?: EligibilityType | null;
        redshirt: boolean;
        hometownCity?: string | null;
        hometownState?: string | null;
        hometownCountry?: string | null;
        highschool?: string | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          shortName: string;
        } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          shortName: string;
        } | null;
        workouts: Array<{
          __typename?: "WorkoutPlayerMeasure";
          id: string;
          playerId: string;
          value: number;
          isVerified: boolean;
          workout: {
            __typename?: "Workout";
            id: string;
            comments?: string | null;
            workoutDate: string;
            workoutType: {
              __typename?: "WorkoutType";
              name: string;
              priority: number;
            };
          };
          measureType: {
            __typename?: "MeasureType";
            shortName: string;
            name: string;
            isFraction: boolean;
          };
        }>;
        tags: Array<{
          __typename?: "PlayerTag";
          sort?: number | null;
          tag: { __typename?: "Tag"; id: string; name: string };
        }>;
        evaluations: Array<{
          __typename?: "Evaluation";
          isPrimary: boolean;
          scoutGrade?: {
            __typename?: "ScoutGrade";
            id: string;
            grade: number;
          } | null;
        }>;
        mostRecentClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        playerOverrides: Array<{
          __typename?: "PlayerOverride";
          field: string;
          value?: string | null;
        }>;
      };
    }> | null;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type PlayerSummaryFragment = {
  __typename?: "Player";
  id: string;
  gsisId?: string | null;
  footballName?: string | null;
  firstName: string;
  lastName: string;
  jersey?: string | null;
  draftYear?: number | null;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  highschool?: string | null;
  highSchoolGradYear?: number | null;
  latestHighSchoolGradYear?: number | null;
  eligibilityYear?: number | null;
  eligibility?: EligibilityType | null;
  schoolId?: string | null;
  redshirt: boolean;
  draftRound?: number | null;
  draftSelection?: number | null;
  headshotUrl?: string | null;
  status: PlayerStatus;
  clubId?: string | null;
  birthDate?: string | null;
  namePronunciation?: string | null;
  nflExperience: number;
  minSalaryCredits?: number | null;
  accruedSeasons?: number | null;
  latestBirthDate?: string | null;
  latestClubId?: string | null;
  latestSchoolId?: string | null;
  latestEligibility?: EligibilityType | null;
  latestEligibilityYear?: number | null;
  draftClub?: { __typename?: "Club"; clubCode: string } | null;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  agent?: {
    __typename?: "Agent";
    firstName?: string | null;
    lastName?: string | null;
    company?: string | null;
  } | null;
  mostRecentClub?: {
    __typename?: "Club";
    id: string;
    clubName: string;
    clubCode: string;
  } | null;
  playerOverrides: Array<{
    __typename?: "PlayerOverride";
    field: string;
    value?: string | null;
  }>;
  tags: Array<{
    __typename?: "PlayerTag";
    tag: { __typename?: "Tag"; id: string; name: string; tagType: TagTypeEnum };
  }>;
};

export type PlayerTagFragment = {
  __typename?: "PlayerTag";
  tag: { __typename?: "Tag"; id: string; name: string; tagType: TagTypeEnum };
};

export type GetPlayerSummaryQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetPlayerSummaryQuery = {
  __typename?: "Query";
  playerById?: {
    __typename?: "Player";
    id: string;
    gsisId?: string | null;
    footballName?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    highschool?: string | null;
    highSchoolGradYear?: number | null;
    latestHighSchoolGradYear?: number | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    schoolId?: string | null;
    redshirt: boolean;
    draftRound?: number | null;
    draftSelection?: number | null;
    headshotUrl?: string | null;
    status: PlayerStatus;
    clubId?: string | null;
    birthDate?: string | null;
    namePronunciation?: string | null;
    nflExperience: number;
    minSalaryCredits?: number | null;
    accruedSeasons?: number | null;
    latestBirthDate?: string | null;
    latestClubId?: string | null;
    latestSchoolId?: string | null;
    latestEligibility?: EligibilityType | null;
    latestEligibilityYear?: number | null;
    draftClub?: { __typename?: "Club"; clubCode: string } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    agent?: {
      __typename?: "Agent";
      firstName?: string | null;
      lastName?: string | null;
      company?: string | null;
    } | null;
    mostRecentClub?: {
      __typename?: "Club";
      id: string;
      clubName: string;
      clubCode: string;
    } | null;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
    tags: Array<{
      __typename?: "PlayerTag";
      tag: {
        __typename?: "Tag";
        id: string;
        name: string;
        tagType: TagTypeEnum;
      };
    }>;
  } | null;
};

export type GetPlayerHonorsQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetPlayerHonorsQuery = {
  __typename?: "Query";
  playerHonors: Array<{
    __typename?: "PlayerHonor";
    marvalPlayerId: string;
    marvalClubId: string;
    description: string;
    season: number;
  }>;
};

export type CollegeReportEvaluationFragment = {
  __typename?: "Evaluation";
  id: string;
  isFinal: boolean;
  finalizedOn?: string | null;
  season: number;
  isPrimary: boolean;
  bottomLine?: string | null;
  summary?: string | null;
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  position: {
    __typename?: "Position";
    id: string;
    shortName: string;
    sort?: number | null;
  };
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  traitGroupGrades: Array<{
    __typename?: "EvaluationTraitGroup";
    comments?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    traitGroup: {
      __typename?: "TraitGroup";
      id: string;
      caption: string;
      sort: number;
    };
  }>;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    comments?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    traitDefinition: {
      __typename?: "TraitDefinition";
      id: string;
      caption: string;
      sort: number;
    };
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    gameId: string;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    game: {
      __typename?: "Game";
      id: string;
      season: number;
      week?: number | null;
      venue?: string | null;
      homeTeamId?: string | null;
      homeTeam?: string | null;
      awayTeam?: string | null;
      homeTeamScore?: number | null;
      awayTeamScore?: number | null;
      kickoffTime?: string | null;
      seasonType: {
        __typename?: "SeasonType";
        id: number;
        abbreviation?: string | null;
        description?: string | null;
      };
      weekType: {
        __typename?: "WeekType";
        id: number;
        description?: string | null;
      };
      leagueType: {
        __typename?: "LeagueType";
        id: number;
        description?: string | null;
      };
    };
  }>;
};

export type TopCollegePlayerFragment = {
  __typename?: "CollegeReportPlayer";
  id: string;
  jersey?: string | null;
  firstName: string;
  lastName: string;
  birthDate?: string | null;
  latestBirthDate?: string | null;
  eligibility?: string | null;
  agentName?: string | null;
  agentCompany?: string | null;
  schoolCode?: string | null;
  sumerGeneralPosition?: string | null;
  sumerAtlasPosition?: string | null;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  headshotUrl?: string | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  sage?: number | null;
  expectedDraftPosition?: number | null;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    season: number;
    isPrimary: boolean;
    bottomLine?: string | null;
    summary?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    position: {
      __typename?: "Position";
      id: string;
      shortName: string;
      sort?: number | null;
    };
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitGroup: {
        __typename?: "TraitGroup";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      gameId: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      game: {
        __typename?: "Game";
        id: string;
        season: number;
        week?: number | null;
        venue?: string | null;
        homeTeamId?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
        homeTeamScore?: number | null;
        awayTeamScore?: number | null;
        kickoffTime?: string | null;
        seasonType: {
          __typename?: "SeasonType";
          id: number;
          abbreviation?: string | null;
          description?: string | null;
        };
        weekType: {
          __typename?: "WeekType";
          id: number;
          description?: string | null;
        };
        leagueType: {
          __typename?: "LeagueType";
          id: number;
          description?: string | null;
        };
      };
    }>;
  }>;
};

export type CollegeSpringPlayerFragment = {
  __typename?: "CollegeReportPlayer";
  id: string;
  jersey?: string | null;
  firstName: string;
  lastName: string;
  birthDate?: string | null;
  latestBirthDate?: string | null;
  eligibility?: string | null;
  schoolCode?: string | null;
  sumerGeneralPosition?: string | null;
  sumerAtlasPosition?: string | null;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  headshotUrl?: string | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    season: number;
    isPrimary: boolean;
    bottomLine?: string | null;
    summary?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    position: {
      __typename?: "Position";
      id: string;
      shortName: string;
      sort?: number | null;
    };
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitGroup: {
        __typename?: "TraitGroup";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      gameId: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      game: {
        __typename?: "Game";
        id: string;
        season: number;
        week?: number | null;
        venue?: string | null;
        homeTeamId?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
        homeTeamScore?: number | null;
        awayTeamScore?: number | null;
        kickoffTime?: string | null;
        seasonType: {
          __typename?: "SeasonType";
          id: number;
          abbreviation?: string | null;
          description?: string | null;
        };
        weekType: {
          __typename?: "WeekType";
          id: number;
          description?: string | null;
        };
        leagueType: {
          __typename?: "LeagueType";
          id: number;
          description?: string | null;
        };
      };
    }>;
  }>;
};

export type GetTopCollegePlayersQueryVariables = Exact<{
  eligibilityYear: Scalars["Int"];
  count: Scalars["Int"];
}>;

export type GetTopCollegePlayersQuery = {
  __typename?: "Query";
  topCollegePlayers: Array<{
    __typename?: "CollegeReportPlayer";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    birthDate?: string | null;
    latestBirthDate?: string | null;
    eligibility?: string | null;
    agentName?: string | null;
    agentCompany?: string | null;
    schoolCode?: string | null;
    sumerGeneralPosition?: string | null;
    sumerAtlasPosition?: string | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    headshotUrl?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    sage?: number | null;
    expectedDraftPosition?: number | null;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      isFinal: boolean;
      finalizedOn?: string | null;
      season: number;
      isPrimary: boolean;
      bottomLine?: string | null;
      summary?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      position: {
        __typename?: "Position";
        id: string;
        shortName: string;
        sort?: number | null;
      };
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        id: string;
        evaluationId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          sort?: number | null;
        };
      }>;
      traitGroupGrades: Array<{
        __typename?: "EvaluationTraitGroup";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitGroup: {
          __typename?: "TraitGroup";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      traitGrades: Array<{
        __typename?: "EvaluationTrait";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        game: {
          __typename?: "Game";
          id: string;
          season: number;
          week?: number | null;
          venue?: string | null;
          homeTeamId?: string | null;
          homeTeam?: string | null;
          awayTeam?: string | null;
          homeTeamScore?: number | null;
          awayTeamScore?: number | null;
          kickoffTime?: string | null;
          seasonType: {
            __typename?: "SeasonType";
            id: number;
            abbreviation?: string | null;
            description?: string | null;
          };
          weekType: {
            __typename?: "WeekType";
            id: number;
            description?: string | null;
          };
          leagueType: {
            __typename?: "LeagueType";
            id: number;
            description?: string | null;
          };
        };
      }>;
    }>;
  }>;
};

export type GetCollegeReportByPlayerIdQueryVariables = Exact<{
  playerId: Scalars["UUID"];
}>;

export type GetCollegeReportByPlayerIdQuery = {
  __typename?: "Query";
  playerById?: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    birthDate?: string | null;
    latestBirthDate?: string | null;
    eligibility?: EligibilityType | null;
    eligibilityYear?: number | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    headshotUrl?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    schoolId?: string | null;
    latestSchoolId?: string | null;
    latestEligibility?: EligibilityType | null;
    latestEligibilityYear?: number | null;
    agent?: {
      __typename?: "Agent";
      firstName?: string | null;
      lastName?: string | null;
      company?: string | null;
    } | null;
    school?: { __typename?: "School"; schoolCode?: string | null } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      isFinal: boolean;
      finalizedOn?: string | null;
      season: number;
      isPrimary: boolean;
      bottomLine?: string | null;
      summary?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      position: {
        __typename?: "Position";
        id: string;
        shortName: string;
        sort?: number | null;
      };
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        id: string;
        evaluationId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          sort?: number | null;
        };
      }>;
      traitGroupGrades: Array<{
        __typename?: "EvaluationTraitGroup";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitGroup: {
          __typename?: "TraitGroup";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      traitGrades: Array<{
        __typename?: "EvaluationTrait";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        game: {
          __typename?: "Game";
          id: string;
          season: number;
          week?: number | null;
          venue?: string | null;
          homeTeamId?: string | null;
          homeTeam?: string | null;
          awayTeam?: string | null;
          homeTeamScore?: number | null;
          awayTeamScore?: number | null;
          kickoffTime?: string | null;
          seasonType: {
            __typename?: "SeasonType";
            id: number;
            abbreviation?: string | null;
            description?: string | null;
          };
          weekType: {
            __typename?: "WeekType";
            id: number;
            description?: string | null;
          };
          leagueType: {
            __typename?: "LeagueType";
            id: number;
            description?: string | null;
          };
        };
      }>;
    }>;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
  } | null;
};

export type DraftContractTierFragment = {
  __typename?: "DraftContractTier";
  minPick: number;
  maxPick: number;
  averageGuarantee: number;
  description: string;
};

export type GetDraftContractTiersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDraftContractTiersQuery = {
  __typename?: "Query";
  draftContractTiers: Array<{
    __typename?: "DraftContractTier";
    minPick: number;
    maxPick: number;
    averageGuarantee: number;
    description: string;
  }>;
};

export type GetPlayerEdpQueryVariables = Exact<{
  playerId: Scalars["UUID"];
  season?: InputMaybe<Scalars["Int"]>;
}>;

export type GetPlayerEdpQuery = {
  __typename?: "Query";
  playerExpectedDraftPosition: Array<{
    __typename?: "PredraftExpectedDraftPosition";
    marvalPlayerId: string;
    season: number;
    expectedDraftPosition: number;
  }>;
};

export type GetCollegeSpringPlayersQueryVariables = Exact<{
  eligibilityYear: Scalars["Int"];
  count: Scalars["Int"];
  includeAll: Scalars["Boolean"];
  position: Scalars["String"];
}>;

export type GetCollegeSpringPlayersQuery = {
  __typename?: "Query";
  collegeSpringPlayers: Array<{
    __typename?: "CollegeReportPlayer";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    birthDate?: string | null;
    latestBirthDate?: string | null;
    eligibility?: string | null;
    schoolCode?: string | null;
    sumerGeneralPosition?: string | null;
    sumerAtlasPosition?: string | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    headshotUrl?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      isFinal: boolean;
      finalizedOn?: string | null;
      season: number;
      isPrimary: boolean;
      bottomLine?: string | null;
      summary?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      position: {
        __typename?: "Position";
        id: string;
        shortName: string;
        sort?: number | null;
      };
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        id: string;
        evaluationId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          sort?: number | null;
        };
      }>;
      traitGroupGrades: Array<{
        __typename?: "EvaluationTraitGroup";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitGroup: {
          __typename?: "TraitGroup";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      traitGrades: Array<{
        __typename?: "EvaluationTrait";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        game: {
          __typename?: "Game";
          id: string;
          season: number;
          week?: number | null;
          venue?: string | null;
          homeTeamId?: string | null;
          homeTeam?: string | null;
          awayTeam?: string | null;
          homeTeamScore?: number | null;
          awayTeamScore?: number | null;
          kickoffTime?: string | null;
          seasonType: {
            __typename?: "SeasonType";
            id: number;
            abbreviation?: string | null;
            description?: string | null;
          };
          weekType: {
            __typename?: "WeekType";
            id: number;
            description?: string | null;
          };
          leagueType: {
            __typename?: "LeagueType";
            id: number;
            description?: string | null;
          };
        };
      }>;
    }>;
  }>;
};

export type ReportPlayerFragment = {
  __typename?: "Player";
  id: string;
  jersey?: string | null;
  firstName: string;
  lastName: string;
  birthDate?: string | null;
  latestBirthDate?: string | null;
  draftYear?: number | null;
  draftRound?: number | null;
  draftSelection?: number | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  accruedSeasons?: number | null;
  headshotUrl?: string | null;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  freeAgencyStatus?: FreeAgencyStatus | null;
  draftClub?: { __typename?: "Club"; clubCode: string } | null;
  school?: { __typename?: "School"; schoolName: string } | null;
  club?: { __typename?: "Club"; clubCode: string } | null;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    season: number;
    summary?: string | null;
    bottomLine?: string | null;
    isPrimary: boolean;
    isFinal: boolean;
    position: { __typename?: "Position"; id: string; shortName: string };
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitGroup: {
        __typename?: "TraitGroup";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      gameId: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      game: {
        __typename?: "Game";
        id: string;
        season: number;
        week?: number | null;
        venue?: string | null;
        homeTeamId?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
        homeTeamScore?: number | null;
        awayTeamScore?: number | null;
        kickoffTime?: string | null;
        seasonType: {
          __typename?: "SeasonType";
          id: number;
          abbreviation?: string | null;
          description?: string | null;
        };
        weekType: {
          __typename?: "WeekType";
          id: number;
          description?: string | null;
        };
        leagueType: {
          __typename?: "LeagueType";
          id: number;
          description?: string | null;
        };
      };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      alert: { __typename?: "Alert"; abbreviation: string; caption: string };
    }>;
  }>;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
  agent?: {
    __typename?: "Agent";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    company?: string | null;
  } | null;
};

export type ReportGameGradesFragment = {
  __typename?: "EvaluationGame";
  gameId: string;
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  game: {
    __typename?: "Game";
    id: string;
    season: number;
    week?: number | null;
    venue?: string | null;
    homeTeamId?: string | null;
    homeTeam?: string | null;
    awayTeam?: string | null;
    homeTeamScore?: number | null;
    awayTeamScore?: number | null;
    kickoffTime?: string | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
  };
};

export type ReportEvaluationFragment = {
  __typename?: "Evaluation";
  id: string;
  season: number;
  summary?: string | null;
  bottomLine?: string | null;
  isPrimary: boolean;
  isFinal: boolean;
  position: { __typename?: "Position"; id: string; shortName: string };
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  traitGroupGrades: Array<{
    __typename?: "EvaluationTraitGroup";
    comments?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    traitGroup: {
      __typename?: "TraitGroup";
      id: string;
      caption: string;
      sort: number;
    };
  }>;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    comments?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    traitDefinition: {
      __typename?: "TraitDefinition";
      id: string;
      caption: string;
      sort: number;
    };
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    gameId: string;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    game: {
      __typename?: "Game";
      id: string;
      season: number;
      week?: number | null;
      venue?: string | null;
      homeTeamId?: string | null;
      homeTeam?: string | null;
      awayTeam?: string | null;
      homeTeamScore?: number | null;
      awayTeamScore?: number | null;
      kickoffTime?: string | null;
      seasonType: {
        __typename?: "SeasonType";
        id: number;
        abbreviation?: string | null;
        description?: string | null;
      };
      weekType: {
        __typename?: "WeekType";
        id: number;
        description?: string | null;
      };
      leagueType: {
        __typename?: "LeagueType";
        id: number;
        description?: string | null;
      };
    };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    alert: { __typename?: "Alert"; abbreviation: string; caption: string };
  }>;
};

export type PlayerWarFragment = {
  __typename?: "RawAiGrade";
  marvalPlayerId?: string | null;
  season?: number | null;
  league?: string | null;
  war?: number | null;
  passWar?: number | null;
  rushWar?: number | null;
  passRouteWrWar?: number | null;
  passRouteTeWar?: number | null;
  passRouteHbWar?: number | null;
  passBlockOlWar?: number | null;
  passBlockNOlWar?: number | null;
  runBlockOlWar?: number | null;
  runBlockNOlWar?: number | null;
  coverageCbWar?: number | null;
  coverageSWar?: number | null;
  coverageNDbWar?: number | null;
  runDefenseNiDlWar?: number | null;
  runDefenseIDlWar?: number | null;
  passRushNiDlWar?: number | null;
  passRushIDlWar?: number | null;
  xWar?: number | null;
  passXwar?: number | null;
  rushXwar?: number | null;
  passRouteWrXwar?: number | null;
  passRouteTeXwar?: number | null;
  passRouteHbXwar?: number | null;
  passBlockOlXwar?: number | null;
  passBlockNOlXwar?: number | null;
  runBlockOlXwar?: number | null;
  runBlockNOlXwar?: number | null;
  coverageCbXwar?: number | null;
  coverageSXwar?: number | null;
  coverageNDbXwar?: number | null;
  runDefenseNiDlXwar?: number | null;
  runDefenseIDlXwar?: number | null;
  passRushNiDlXwar?: number | null;
  passRushIDlXwar?: number | null;
  rushHbXwar?: number | null;
  rushHbWar?: number | null;
};

export type UfaPlayerFragment = {
  __typename?: "UfaPlayer";
  id: string;
  jersey?: string | null;
  firstName: string;
  lastName: string;
  birthDate?: string | null;
  latestBirthDate?: string | null;
  sumerGeneralPosition?: string | null;
  sumerAtlasPosition?: string | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  club?: string | null;
  school?: string | null;
  draftYear?: number | null;
  draftRound?: number | null;
  draftSelection?: number | null;
  draftClub?: string | null;
  agentName?: string | null;
  agentCompany?: string | null;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  headshotUrl?: string | null;
  accruedSeasons?: number | null;
  freeAgencyStatus?: FreeAgencyStatus | null;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    season: number;
    summary?: string | null;
    bottomLine?: string | null;
    isPrimary: boolean;
    isFinal: boolean;
    position: { __typename?: "Position"; id: string; shortName: string };
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitGroup: {
        __typename?: "TraitGroup";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      gameId: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      game: {
        __typename?: "Game";
        id: string;
        season: number;
        week?: number | null;
        venue?: string | null;
        homeTeamId?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
        homeTeamScore?: number | null;
        awayTeamScore?: number | null;
        kickoffTime?: string | null;
        seasonType: {
          __typename?: "SeasonType";
          id: number;
          abbreviation?: string | null;
          description?: string | null;
        };
        weekType: {
          __typename?: "WeekType";
          id: number;
          description?: string | null;
        };
        leagueType: {
          __typename?: "LeagueType";
          id: number;
          description?: string | null;
        };
      };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      alert: { __typename?: "Alert"; abbreviation: string; caption: string };
    }>;
  }>;
};

export type AdvancedAnalyticsFragment = {
  __typename?: "RawAiGrade";
  season?: number | null;
  marvalPlayerId?: string | null;
  passAccuracy?: number | null;
  passAccuracyRank?: number | null;
  passAccuracyN?: number | null;
  bigThrow?: number | null;
  bigThrowRank?: number | null;
  bigThrowN?: number | null;
  decisionMaking?: number | null;
  decisionMakingRank?: number | null;
  decisionMakingN?: number | null;
  sackAvoidance?: number | null;
  sackAvoidanceRank?: number | null;
  sackAvoidanceN?: number | null;
  aiChartingPassingGrade?: number | null;
  aiChartingPassingGradeRank?: number | null;
  aiChartingPassingGradeN?: number | null;
  rushingElusiveness?: number | null;
  rushingElusivenessRank?: number | null;
  rushingElusivenessN?: number | null;
  rushingYardsPlusMinus?: number | null;
  rushingYardsPlusMinusRank?: number | null;
  rushingYardsPlusMinusN?: number | null;
  productionUnderPressure?: number | null;
  productionUnderPressureRank?: number | null;
  productionUnderPressureN?: number | null;
  yardsAfterCatchPlusMinus?: number | null;
  yardsAfterCatchPlusMinusN?: number | null;
  yardsAfterCatchPlusMinusRank?: number | null;
  passBlockGetOff?: number | null;
  passBlockGetOffRank?: number | null;
  passBlockGetOffN?: number | null;
  passBlockFinishing?: number | null;
  passBlockFinishingRank?: number | null;
  passBlockFinishingN?: number | null;
  aiChartingRushingGrade?: number | null;
  aiChartingRushingGradeRank?: number | null;
  aiChartingRushingGradeN?: number | null;
  aiChartingReceivingGrade?: number | null;
  aiChartingReceivingGradeN?: number | null;
  aiChartingReceivingGradeRank?: number | null;
  aiChartingPassBlockGrade?: number | null;
  aiChartingPassBlockGradeRank?: number | null;
  aiChartingPassBlockGradeN?: number | null;
  aiChartingRunBlockGrade?: number | null;
  aiChartingRunBlockGradeRank?: number | null;
  aiChartingRunBlockGradeN?: number | null;
  runPursuitGetOff?: number | null;
  runPursuitGetOffRank?: number | null;
  runPursuitGetOffN?: number | null;
  runPursuitFinishing?: number | null;
  runPursuitFinishingRank?: number | null;
  runPursuitFinishingN?: number | null;
  passRushWinGetOff?: number | null;
  passRushWinGetOffRank?: number | null;
  passRushWinGetOffN?: number | null;
  passRushWinFinishing?: number | null;
  passRushWinFinishingRank?: number | null;
  passRushWinFinishingN?: number | null;
  aiChartingRunDefenseGrade?: number | null;
  aiChartingRunDefenseGradeRank?: number | null;
  aiChartingRunDefenseGradeN?: number | null;
  productionPlusMinus?: number | null;
  productionPlusMinusRank?: number | null;
  productionPlusMinusN?: number | null;
};

export type GetReportStatsForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
  leagueType?: InputMaybe<Scalars["Int"]>;
  seasonType?: InputMaybe<Scalars["String"]>;
}>;

export type GetReportStatsForPlayerQuery = {
  __typename?: "Query";
  seasonStats: Array<{
    __typename?: "SeasonStat";
    season: number;
    perGameStatsGamesPlayed?: number | null;
    perGameStatsPassAttemptsPerGame?: number | null;
    perGameStatsPassingYardsPerGame?: number | null;
    perGameStatsReceivingYardsPerGame?: number | null;
    perGameStatsReceptionsPerGame?: number | null;
    perGameStatsRushAttemptsPerGame?: number | null;
    perGameStatsRushingYardsPerGame?: number | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
    playerStat?: {
      __typename?: "PlayerStat";
      rushingStatsYards?: number | null;
      rushingStatsAttempts?: number | null;
      rushingStatsTouchdowns?: number | null;
      rushingStatsRunsTwentyYardsPlus?: number | null;
      rushingStatsRunsFortyYardsPlus?: number | null;
      rushingStatsLongestRun?: number | null;
      rushingStatsFirstDownRushes?: number | null;
      rushingStatsFirstDownPercentage?: number | null;
      rushingStatsFumbles?: number | null;
      rushingStatsYardsAfterContact?: number | null;
      rushingStatsYardsAfterContactPerRush?: number | null;
      rushingStatsTacklesAvoided?: number | null;
      rushingStatsFumblesRecovered?: number | null;
      rushingStatsFumblesLost?: number | null;
      rushingStatsHurriesAllowed?: number | null;
      rushingStatsPenalties?: number | null;
      rushingStatsInjuriesInGame?: number | null;
      rushingStatsPressuresAllowed?: number | null;
      rushingStatsSacksAllowed?: number | null;
      rushingStatsYardsPerRush?: number | null;
      rushingStatsSnaps?: number | null;
      rushingStatsTeamSnaps?: number | null;
      rushingStatsSnapPercentage?: number | null;
      rushingStatsTwoPointAttempts?: number | null;
      rushingStatsTwoPointConversions?: number | null;
      returnStatsKickReturnYardsAverage?: number | null;
      returnStatsKickReturnAttempts?: number | null;
      returnStatsKickReturnYards?: number | null;
      returnStatsKickReturnTouchdowns?: number | null;
      returnStatsKickReturnsTwentyYardsPlus?: number | null;
      returnStatsKickReturnsFortyYardsPlus?: number | null;
      returnStatsKickReturnLongestYards?: number | null;
      returnStatsKickReturnFairCatches?: number | null;
      returnStatsKickReturnFumbles?: number | null;
      returnStatsKickReturnYardsAfterContact?: number | null;
      returnStatsKickReturnYardsAfterContactAverage?: number | null;
      returnStatsKickReturnSnaps?: number | null;
      returnStatsKickReturnTeamSnaps?: number | null;
      returnStatsKickReturnSnapPercentage?: number | null;
      returnStatsPuntReturnYardsAverage?: number | null;
      returnStatsPuntReturnAttempts?: number | null;
      returnStatsPuntReturnYards?: number | null;
      returnStatsPuntReturnTouchdowns?: number | null;
      returnStatsPuntReturnsTwentyYardsPlus?: number | null;
      returnStatsPuntReturnsFortyYardsPlus?: number | null;
      returnStatsPuntReturnLongestYards?: number | null;
      returnStatsPuntReturnFairCatches?: number | null;
      returnStatsPuntReturnFumbles?: number | null;
      returnStatsPuntReturnYardsAfterContact?: number | null;
      returnStatsPuntReturnYardsAfterContactAverage?: number | null;
      returnStatsPuntReturnSnaps?: number | null;
      returnStatsPuntReturnTeamSnaps?: number | null;
      returnStatsPuntReturnSnapPercentage?: number | null;
      receivingStatsReceptions?: number | null;
      receivingStatsTouchdowns?: number | null;
      receivingStatsYards?: number | null;
      receivingStatsYardsPerReception?: number | null;
      receivingStatsReceptionsTwentyYardsPlus?: number | null;
      receivingStatsReceptionsFortyYardsPlus?: number | null;
      receivingStatsLongestReception?: number | null;
      receivingStatsFirstDowns?: number | null;
      receivingStatsFirstDownPercentage?: number | null;
      receivingStatsReceivingFumbles?: number | null;
      receivingStatsYardsAfterCatch?: number | null;
      receivingStatsYardAfterCatchPerReception?: number | null;
      receivingStatsTargets?: number | null;
      receivingStatsTacklesAvoided?: number | null;
      receivingStatsDrops?: number | null;
      receivingStatsDropsPercentage?: number | null;
      receivingStatsYardsAfterContact?: number | null;
      receivingStatsInjuriesInGame?: number | null;
      receivingStatsPenalties?: number | null;
      receivingStatsCatchableThrows?: number | null;
      receivingStatsPassRouteDepthAverage?: number | null;
      receivingStatsSnaps?: number | null;
      receivingStatsTeamSnaps?: number | null;
      receivingStatsSnapPercentage?: number | null;
      receivingStatsPassRoutesRun?: number | null;
      receivingStatsReceptionsPerTouchdown?: number | null;
      receivingStatsTwoPointAttempts?: number | null;
      receivingStatsTwoPointConversions?: number | null;
      passingStatsYards?: number | null;
      passingStatsAttempts?: number | null;
      passingStatsCompletions?: number | null;
      passingStatsCompletionPercentage?: number | null;
      passingStatsYardsPerCompletionAverage?: number | null;
      passingStatsTouchdowns?: number | null;
      passingStatsTouchdownPercentage?: number | null;
      passingStatsInterceptions?: number | null;
      passingStatsInterceptionPercentage?: number | null;
      passingStatsPasserRating?: number | null;
      passingStatsFirstDowns?: number | null;
      passingStatsFirstDownPercentage?: number | null;
      passingStatsCompletionsTwentyYardsPlus?: number | null;
      passingStatsCompletionsFortyYardsPlus?: number | null;
      passingStatsLongestCompletion?: number | null;
      passingStatsSacks?: number | null;
      passingStatsSackYards?: number | null;
      passingStatsYardsAfterCatch?: number | null;
      passingStatsDrops?: number | null;
      passingStatsInjuriesInGame?: number | null;
      passingStatsFumbles?: number | null;
      passingStatsFumblesRecovered?: number | null;
      passingStatsHurriesAllowed?: number | null;
      passingStatsPassDepthAverage?: number | null;
      passingStatsPassDepthAttempts?: number | null;
      passingStatsCatchableThrows?: number | null;
      passingStatsCatchableThrowPercentage?: number | null;
      passingStatsPenalties?: number | null;
      passingStatsTimeToPressureAverage?: number | null;
      passingStatsTimeToPressureAttempts?: number | null;
      passingStatsTimeToThrowAverage?: number | null;
      passingStatsTimeToThrowAttempts?: number | null;
      passingStatsDropbackDepthAverage?: number | null;
      passingStatsDropbackAttempts?: number | null;
      passingStatsSnaps?: number | null;
      passingStatsTeamSnaps?: number | null;
      passingStatsSnapPercentage?: number | null;
      passingStatsPumpFakes?: number | null;
      passingStatsPassingEfficiency?: number | null;
      passingStatsYardsPerAttempt?: number | null;
      passingStatsTouchdownInterceptionRatio?: number | null;
      passingStatsTwoPointAttempts?: number | null;
      passingStatsTwoPointConversions?: number | null;
      defenseStatsFumblesForced?: number | null;
      defenseStatsTacklesSolo?: number | null;
      defenseStatsSacks?: number | null;
      defenseStatsSackPlays?: number | null;
      defenseStatsSackPercentage?: number | null;
      defenseStatsSnaps?: number | null;
      defenseStatsTeamSnaps?: number | null;
      defenseStatsSnapPercentage?: number | null;
      defenseStatsBattedPasses?: number | null;
      defenseStatsQbHits?: number | null;
      defenseStatsQbHitPercentage?: number | null;
      defenseStatsFumblesRecovered?: number | null;
      defenseStatsInjuriesInGame?: number | null;
      defenseStatsMissedTacklesOnQb?: number | null;
      defenseStatsPassBreakups?: number | null;
      defenseStatsStops?: number | null;
      defenseStatsTouchdowns?: number | null;
      defenseStatsPassRushAttempts?: number | null;
      defenseStatsCoverageAttempts?: number | null;
      defenseStatsBurns?: number | null;
      defenseStatsDefensiveTargets?: number | null;
      defenseStatsQbPressures?: number | null;
      defenseStatsFumblesRecoveredForTouchdown?: number | null;
      defenseStatsQbPressurePercentage?: number | null;
      defenseStatsQbHurries?: number | null;
      defenseStatsQbHurryPercentage?: number | null;
      defenseStatsMissedTackles?: number | null;
      defenseStatsPuntsBlocked?: number | null;
      defenseStatsFieldGoalsBlocked?: number | null;
      defenseStatsExtraPointsBlocked?: number | null;
      defenseStatsTacklesForLoss?: number | null;
      defenseStatsDroppedInterceptions?: number | null;
      defenseStatsInterceptions?: number | null;
      defenseStatsInterceptionTouchdowns?: number | null;
      defenseStatsInterceptionYards?: number | null;
      defenseStatsInterceptionLongestReturn?: number | null;
      defenseStatsInterceptionPercentage?: number | null;
      defenseStatsTacklesCombined?: number | null;
      defenseStatsTacklesAssisted?: number | null;
      kickingStatsFieldGoalsMade?: number | null;
      kickingStatsFieldGoalAttempts?: number | null;
      kickingStatsFieldGoalPercentage?: number | null;
      kickingStatsFieldGoals1To19Attempted?: number | null;
      kickingStatsFieldGoals1To19Made?: number | null;
      kickingStatsFieldGoals1To19Percentage?: number | null;
      kickingStatsFieldGoals20To29Attempted?: number | null;
      kickingStatsFieldGoals20To29Made?: number | null;
      kickingStatsFieldGoals20To29Percentage?: number | null;
      kickingStatsFieldGoals30To39Attempted?: number | null;
      kickingStatsFieldGoals30To39Made?: number | null;
      kickingStatsFieldGoals30To39Percentage?: number | null;
      kickingStatsFieldGoals40To49Attempted?: number | null;
      kickingStatsFieldGoals40To49Made?: number | null;
      kickingStatsFieldGoals40To49Percentage?: number | null;
      kickingStatsFieldGoals50To59Attempted?: number | null;
      kickingStatsFieldGoals50To59Made?: number | null;
      kickingStatsFieldGoals50To59Percentage?: number | null;
      kickingStatsFieldGoals60PlusAttempted?: number | null;
      kickingStatsFieldGoals60PlusMade?: number | null;
      kickingStatsFieldGoals60PlusPercentage?: number | null;
      kickingStatsFieldGoalMadeLongest?: number | null;
      kickingStatsFieldGoalsBlocked?: number | null;
      kickingStatsFieldGoalSnaps?: number | null;
      kickingStatsFieldGoalTeamSnaps?: number | null;
      kickingStatsFieldGoalSnapPercentage?: number | null;
      kickingStatsFieldGoalYardsAverage?: number | null;
      kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
      kickingStatsExtraPointAttempts?: number | null;
      kickingStatsExtraPointsMade?: number | null;
      kickingStatsExtraPointsBlocked?: number | null;
      kickingStatsExtraPointsPercentage?: number | null;
      kickingStatsPuntYardsAverage?: number | null;
      kickingStatsPuntYardsNetAverage?: number | null;
      kickingStatsPuntAttempts?: number | null;
      kickingStatsPuntYardsLongest?: number | null;
      kickingStatsPuntYards?: number | null;
      kickingStatsPuntsIn20?: number | null;
      kickingStatsPuntsOutOfBounds?: number | null;
      kickingStatsPuntsDowned?: number | null;
      kickingStatsPuntsTouchbacks?: number | null;
      kickingStatsPuntsFairCaught?: number | null;
      kickingStatsPuntsReturned?: number | null;
      kickingStatsPuntReturnYards?: number | null;
      kickingStatsPuntReturnTouchdowns?: number | null;
      kickingStatsPuntsBlocked?: number | null;
      kickingStatsPuntSnaps?: number | null;
      kickingStatsPuntTeamSnaps?: number | null;
      kickingStatsPuntSnapPercentage?: number | null;
      kickingStatsPuntReturnYardsAverage?: number | null;
      kickingStatsPuntYardsNet?: number | null;
      kickingStatsPuntHangTimeAverage?: number | null;
      kickingStatsPuntOperationTimeAverage?: number | null;
      kickingStatsKickoffAttempts?: number | null;
      kickingStatsKickoffYards?: number | null;
      kickingStatsKickoffReturnYards?: number | null;
      kickingStatsKickoffTouchbacks?: number | null;
      kickingStatsKickoffTouchbackPercentage?: number | null;
      kickingStatsKickoffsReturned?: number | null;
      kickingStatsKickoffReturnLong?: number | null;
      kickingStatsKickoffReturnYardsAverage?: number | null;
      kickingStatsKickoffOnsideKicks?: number | null;
      kickingStatsKickoffOnsideKicksRecovered?: number | null;
      kickingStatsKickoffsOutOfBounds?: number | null;
      kickingStatsKickoffReturnTouchdowns?: number | null;
      kickingStatsKickoffDepthAverage?: number | null;
      kickingStatsKickoffYardsAverage?: number | null;
      kickingStatsKickoffSnaps?: number | null;
      kickingStatsKickoffTeamSnaps?: number | null;
      kickingStatsKickoffSnapPercentage?: number | null;
      kickingStatsKickoffDepthAttempts?: number | null;
      offensiveLinePressuresAllowed?: number | null;
      offensiveLineSacksAllowed?: number | null;
      offensiveLineScreenBlocks?: number | null;
      penalties?: number | null;
    } | null;
  }>;
};

export type GetAdvancedAnalyticsForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
  season?: InputMaybe<Scalars["Int"]>;
}>;

export type GetAdvancedAnalyticsForPlayerQuery = {
  __typename?: "Query";
  playerAdvancedAnalytics: Array<{
    __typename?: "RawAiGrade";
    season?: number | null;
    marvalPlayerId?: string | null;
    passAccuracy?: number | null;
    passAccuracyRank?: number | null;
    passAccuracyN?: number | null;
    bigThrow?: number | null;
    bigThrowRank?: number | null;
    bigThrowN?: number | null;
    decisionMaking?: number | null;
    decisionMakingRank?: number | null;
    decisionMakingN?: number | null;
    sackAvoidance?: number | null;
    sackAvoidanceRank?: number | null;
    sackAvoidanceN?: number | null;
    aiChartingPassingGrade?: number | null;
    aiChartingPassingGradeRank?: number | null;
    aiChartingPassingGradeN?: number | null;
    rushingElusiveness?: number | null;
    rushingElusivenessRank?: number | null;
    rushingElusivenessN?: number | null;
    rushingYardsPlusMinus?: number | null;
    rushingYardsPlusMinusRank?: number | null;
    rushingYardsPlusMinusN?: number | null;
    productionUnderPressure?: number | null;
    productionUnderPressureRank?: number | null;
    productionUnderPressureN?: number | null;
    yardsAfterCatchPlusMinus?: number | null;
    yardsAfterCatchPlusMinusN?: number | null;
    yardsAfterCatchPlusMinusRank?: number | null;
    passBlockGetOff?: number | null;
    passBlockGetOffRank?: number | null;
    passBlockGetOffN?: number | null;
    passBlockFinishing?: number | null;
    passBlockFinishingRank?: number | null;
    passBlockFinishingN?: number | null;
    aiChartingRushingGrade?: number | null;
    aiChartingRushingGradeRank?: number | null;
    aiChartingRushingGradeN?: number | null;
    aiChartingReceivingGrade?: number | null;
    aiChartingReceivingGradeN?: number | null;
    aiChartingReceivingGradeRank?: number | null;
    aiChartingPassBlockGrade?: number | null;
    aiChartingPassBlockGradeRank?: number | null;
    aiChartingPassBlockGradeN?: number | null;
    aiChartingRunBlockGrade?: number | null;
    aiChartingRunBlockGradeRank?: number | null;
    aiChartingRunBlockGradeN?: number | null;
    runPursuitGetOff?: number | null;
    runPursuitGetOffRank?: number | null;
    runPursuitGetOffN?: number | null;
    runPursuitFinishing?: number | null;
    runPursuitFinishingRank?: number | null;
    runPursuitFinishingN?: number | null;
    passRushWinGetOff?: number | null;
    passRushWinGetOffRank?: number | null;
    passRushWinGetOffN?: number | null;
    passRushWinFinishing?: number | null;
    passRushWinFinishingRank?: number | null;
    passRushWinFinishingN?: number | null;
    aiChartingRunDefenseGrade?: number | null;
    aiChartingRunDefenseGradeRank?: number | null;
    aiChartingRunDefenseGradeN?: number | null;
    productionPlusMinus?: number | null;
    productionPlusMinusRank?: number | null;
    productionPlusMinusN?: number | null;
  }>;
};

export type GetMarketTierForPlayerQueryVariables = Exact<{
  ids?: InputMaybe<
    Array<InputMaybe<Scalars["UUID"]>> | InputMaybe<Scalars["UUID"]>
  >;
}>;

export type GetMarketTierForPlayerQuery = {
  __typename?: "Query";
  playerMarketTiers: Array<{
    __typename?: "MarketContractTier";
    marvalPlayerId: string;
    currentContractTier?: string | null;
    ufaContractTier?: string | null;
  }>;
};

export type GetWarForPlayerQueryVariables = Exact<{
  playerId: Scalars["UUID"];
  league?: InputMaybe<Scalars["String"]>;
}>;

export type GetWarForPlayerQuery = {
  __typename?: "Query";
  playerAdvancedAnalytics: Array<{
    __typename?: "RawAiGrade";
    marvalPlayerId?: string | null;
    season?: number | null;
    league?: string | null;
    war?: number | null;
    passWar?: number | null;
    rushWar?: number | null;
    passRouteWrWar?: number | null;
    passRouteTeWar?: number | null;
    passRouteHbWar?: number | null;
    passBlockOlWar?: number | null;
    passBlockNOlWar?: number | null;
    runBlockOlWar?: number | null;
    runBlockNOlWar?: number | null;
    coverageCbWar?: number | null;
    coverageSWar?: number | null;
    coverageNDbWar?: number | null;
    runDefenseNiDlWar?: number | null;
    runDefenseIDlWar?: number | null;
    passRushNiDlWar?: number | null;
    passRushIDlWar?: number | null;
    xWar?: number | null;
    passXwar?: number | null;
    rushXwar?: number | null;
    passRouteWrXwar?: number | null;
    passRouteTeXwar?: number | null;
    passRouteHbXwar?: number | null;
    passBlockOlXwar?: number | null;
    passBlockNOlXwar?: number | null;
    runBlockOlXwar?: number | null;
    runBlockNOlXwar?: number | null;
    coverageCbXwar?: number | null;
    coverageSXwar?: number | null;
    coverageNDbXwar?: number | null;
    runDefenseNiDlXwar?: number | null;
    runDefenseIDlXwar?: number | null;
    passRushNiDlXwar?: number | null;
    passRushIDlXwar?: number | null;
    rushHbXwar?: number | null;
    rushHbWar?: number | null;
  }>;
};

export type GetPositionMarketTiersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPositionMarketTiersQuery = {
  __typename?: "Query";
  marketTiersByPosition: Array<{
    __typename?: "PositionMarketTier";
    sumerGeneralPosition?: string | null;
    tier?: string | null;
    totalGuaranteedMin?: number | null;
    totalGuaranteedMax?: number | null;
    totalApyMin?: number | null;
    totalApyMax?: number | null;
  }>;
};

export type GetUfaPlayersQueryVariables = Exact<{
  count: Scalars["Int"];
}>;

export type GetUfaPlayersQuery = {
  __typename?: "Query";
  unRestrictedFreeAgents: Array<{
    __typename?: "UfaPlayer";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    birthDate?: string | null;
    latestBirthDate?: string | null;
    sumerGeneralPosition?: string | null;
    sumerAtlasPosition?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    club?: string | null;
    school?: string | null;
    draftYear?: number | null;
    draftRound?: number | null;
    draftSelection?: number | null;
    draftClub?: string | null;
    agentName?: string | null;
    agentCompany?: string | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    headshotUrl?: string | null;
    accruedSeasons?: number | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      season: number;
      summary?: string | null;
      bottomLine?: string | null;
      isPrimary: boolean;
      isFinal: boolean;
      position: { __typename?: "Position"; id: string; shortName: string };
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      traitGroupGrades: Array<{
        __typename?: "EvaluationTraitGroup";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitGroup: {
          __typename?: "TraitGroup";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      traitGrades: Array<{
        __typename?: "EvaluationTrait";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        game: {
          __typename?: "Game";
          id: string;
          season: number;
          week?: number | null;
          venue?: string | null;
          homeTeamId?: string | null;
          homeTeam?: string | null;
          awayTeam?: string | null;
          homeTeamScore?: number | null;
          awayTeamScore?: number | null;
          kickoffTime?: string | null;
          seasonType: {
            __typename?: "SeasonType";
            id: number;
            abbreviation?: string | null;
            description?: string | null;
          };
          weekType: {
            __typename?: "WeekType";
            id: number;
            description?: string | null;
          };
          leagueType: {
            __typename?: "LeagueType";
            id: number;
            description?: string | null;
          };
        };
      }>;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        alert: { __typename?: "Alert"; abbreviation: string; caption: string };
      }>;
    }>;
  }>;
};

export type GetProReportByPlayerIdQueryVariables = Exact<{
  playerId: Scalars["UUID"];
}>;

export type GetProReportByPlayerIdQuery = {
  __typename?: "Query";
  playerById?: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    birthDate?: string | null;
    latestBirthDate?: string | null;
    draftYear?: number | null;
    draftRound?: number | null;
    draftSelection?: number | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    accruedSeasons?: number | null;
    headshotUrl?: string | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    draftClub?: { __typename?: "Club"; clubCode: string } | null;
    school?: { __typename?: "School"; schoolName: string } | null;
    club?: { __typename?: "Club"; clubCode: string } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      season: number;
      summary?: string | null;
      bottomLine?: string | null;
      isPrimary: boolean;
      isFinal: boolean;
      position: { __typename?: "Position"; id: string; shortName: string };
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      traitGroupGrades: Array<{
        __typename?: "EvaluationTraitGroup";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitGroup: {
          __typename?: "TraitGroup";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      traitGrades: Array<{
        __typename?: "EvaluationTrait";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        game: {
          __typename?: "Game";
          id: string;
          season: number;
          week?: number | null;
          venue?: string | null;
          homeTeamId?: string | null;
          homeTeam?: string | null;
          awayTeam?: string | null;
          homeTeamScore?: number | null;
          awayTeamScore?: number | null;
          kickoffTime?: string | null;
          seasonType: {
            __typename?: "SeasonType";
            id: number;
            abbreviation?: string | null;
            description?: string | null;
          };
          weekType: {
            __typename?: "WeekType";
            id: number;
            description?: string | null;
          };
          leagueType: {
            __typename?: "LeagueType";
            id: number;
            description?: string | null;
          };
        };
      }>;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        alert: { __typename?: "Alert"; abbreviation: string; caption: string };
      }>;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
    agent?: {
      __typename?: "Agent";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      company?: string | null;
    } | null;
  } | null;
};

export type TraitGroupGradeFragment = {
  __typename?: "EvaluationTraitGroup";
  comments?: string | null;
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  traitGroup: {
    __typename?: "TraitGroup";
    id: string;
    caption: string;
    sort: number;
  };
};

export type TraitGradeFragment = {
  __typename?: "EvaluationTrait";
  comments?: string | null;
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  traitDefinition: {
    __typename?: "TraitDefinition";
    id: string;
    caption: string;
    sort: number;
  };
};

export type CollegeEvaluationFragment = {
  __typename?: "Evaluation";
  id: string;
  season: number;
  lastUpdated: string;
  summary?: string | null;
  bottomLine?: string | null;
  position: { __typename?: "Position"; id: string; shortName: string };
  scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  clubFits: Array<{
    __typename?: "EvaluationClubFit";
    club: { __typename?: "Club"; clubCode: string };
  }>;
  traitGroupGrades: Array<{
    __typename?: "EvaluationTraitGroup";
    comments?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    traitGroup: {
      __typename?: "TraitGroup";
      id: string;
      caption: string;
      sort: number;
    };
  }>;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    comments?: string | null;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    traitDefinition: {
      __typename?: "TraitDefinition";
      id: string;
      caption: string;
      sort: number;
    };
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    gameId: string;
    lastUpdated: string;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  }>;
  user: { __typename?: "User"; id: string; lastName: string };
};

export type CollegeEvaluationPlayerFragment = {
  __typename?: "Player";
  id: string;
  jersey?: string | null;
  firstName: string;
  lastName: string;
  headshotUrl?: string | null;
  eligibilityYear?: number | null;
  latestEligibilityYear?: number | null;
  latestSchoolId?: string | null;
  schoolId?: string | null;
  playerOverrides: Array<{
    __typename?: "PlayerOverride";
    field: string;
    value?: string | null;
  }>;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    season: number;
    lastUpdated: string;
    summary?: string | null;
    bottomLine?: string | null;
    position: { __typename?: "Position"; id: string; shortName: string };
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    clubFits: Array<{
      __typename?: "EvaluationClubFit";
      club: { __typename?: "Club"; clubCode: string };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitGroup: {
        __typename?: "TraitGroup";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      comments?: string | null;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      gameId: string;
      lastUpdated: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    }>;
    user: { __typename?: "User"; id: string; lastName: string };
  }>;
};

export type GetCollegeRosterEvaluationsQueryVariables = Exact<{
  schoolId: Scalars["UUID"];
  overrideSchoolId?: InputMaybe<Scalars["String"]>;
  eligibilityYear?: InputMaybe<Scalars["Int"]>;
}>;

export type GetCollegeRosterEvaluationsQuery = {
  __typename?: "Query";
  rosterPlayers: Array<{
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    headshotUrl?: string | null;
    eligibilityYear?: number | null;
    latestEligibilityYear?: number | null;
    latestSchoolId?: string | null;
    schoolId?: string | null;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      season: number;
      lastUpdated: string;
      summary?: string | null;
      bottomLine?: string | null;
      position: { __typename?: "Position"; id: string; shortName: string };
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        id: string;
        evaluationId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          sort?: number | null;
        };
      }>;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      clubFits: Array<{
        __typename?: "EvaluationClubFit";
        club: { __typename?: "Club"; clubCode: string };
      }>;
      traitGroupGrades: Array<{
        __typename?: "EvaluationTraitGroup";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitGroup: {
          __typename?: "TraitGroup";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      traitGrades: Array<{
        __typename?: "EvaluationTrait";
        comments?: string | null;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      }>;
      user: { __typename?: "User"; id: string; lastName: string };
    }>;
  }>;
};

export type GetSearchIndexStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSearchIndexStatusQuery = {
  __typename?: "Query";
  indexStatus: {
    __typename?: "SearchIndexStatus";
    playersDocumentCount: number;
    playersDatabaseCount: number;
    schoolsDocumentCount: number;
    schoolsDatabaseCount: number;
    clubsDocumentCount: number;
    clubsDatabaseCount: number;
  };
};

export type ResetPlayerSearchIndexMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResetPlayerSearchIndexMutation = {
  __typename?: "Mutation";
  resetPlayerSearchIndices: boolean;
};

export type GetPositionAtlasQueryVariables = Exact<{ [key: string]: never }>;

export type GetPositionAtlasQuery = {
  __typename?: "Query";
  atlas: {
    __typename?: "PositionAtlas";
    nodes: Array<{
      __typename?: "PositionAtlasNode";
      id: string;
      nodeId: string;
      nodeType: string;
      children: Array<{
        __typename?: "PositionAtlasNode";
        nodeId: string;
        id: string;
      }>;
    }>;
  };
  positionMaps: Array<{
    __typename?: "PositionSetItem";
    nodeId: string;
    positionSetName: string;
  }>;
};

export type GetClubsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClubsQuery = {
  __typename?: "Query";
  clubs: Array<{
    __typename?: "Club";
    id: string;
    clubCode: string;
    clubName: string;
    conference: string;
    division: string;
    primaryColor?: string | null;
    secondaryColor?: string | null;
    nickName: string;
  }>;
};

export type ClubFragment = {
  __typename?: "Club";
  id: string;
  clubCode: string;
  clubName: string;
  clubRosterUrl: string;
  conference: string;
  division: string;
  outdoorStadium?: boolean | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  currentCoach: string;
  location: string;
  venue: string;
  yearEstablished: number;
};

export type GetClubQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetClubQuery = {
  __typename?: "Query";
  clubById?: {
    __typename?: "Club";
    id: string;
    clubCode: string;
    clubName: string;
    clubRosterUrl: string;
    conference: string;
    division: string;
    outdoorStadium?: boolean | null;
    primaryColor?: string | null;
    secondaryColor?: string | null;
    currentCoach: string;
    location: string;
    venue: string;
    yearEstablished: number;
  } | null;
};

export type UpdateContractSummaryMutationVariables = Exact<{
  updateContractSummaryRequest: UpdateContractSummaryRequest;
}>;

export type UpdateContractSummaryMutation = {
  __typename?: "Mutation";
  updateContractSummary: {
    __typename?: "Contract";
    id: string;
    sumer: {
      __typename?: "SumerValues";
      existingAmount?: number | null;
      existingAmountGuaranteed?: number | null;
      existingYears?: number | null;
      functionalAmount?: number | null;
      functionalYears?: number | null;
      practicalGuaranteed?: number | null;
      comments?: string | null;
      evaluated: boolean;
      isRework: boolean;
    };
  };
};

export type CreateWorksheetItemMutationVariables = Exact<{
  createWorksheetItemRequest: CreateWorksheetItemRequest;
}>;

export type CreateWorksheetItemMutation = {
  __typename?: "Mutation";
  createWorksheetItem: {
    __typename?: "WorksheetItem";
    id: string;
    season: number;
    item: string;
    amount?: number | null;
    guaranteedAmount?: number | null;
    vestingDate?: string | null;
    vestingOption?: string | null;
    deferred: boolean;
    deferredAmount?: number | null;
    deferredDate?: string | null;
    comments: string;
  };
};

export type UpdateWorksheetItemMutationVariables = Exact<{
  updateWorksheetItemRequest: UpdateWorksheetItemRequest;
}>;

export type UpdateWorksheetItemMutation = {
  __typename?: "Mutation";
  updateWorksheetItem: {
    __typename?: "WorksheetItem";
    id: string;
    season: number;
    item: string;
    amount?: number | null;
    guaranteedAmount?: number | null;
    vestingDate?: string | null;
    vestingOption?: string | null;
    deferred: boolean;
    deferredAmount?: number | null;
    deferredDate?: string | null;
    comments: string;
  };
};

export type ResetContractMutationVariables = Exact<{
  resetContractRequest: ResetContractRequest;
}>;

export type ResetContractMutation = {
  __typename?: "Mutation";
  resetContract: { __typename?: "Contract"; id: string };
};

export type DeleteWorksheetItemMutationVariables = Exact<{
  deleteWorksheetItemRequest: DeleteWorksheetItemRequest;
}>;

export type DeleteWorksheetItemMutation = {
  __typename?: "Mutation";
  deleteWorksheetItem: { __typename?: "WorksheetItem"; id: string };
};

export type UpdateContractTotalsMutationVariables = Exact<{
  updateContractTotalsRequest: AssignContractTotalsRequest;
}>;

export type UpdateContractTotalsMutation = {
  __typename?: "Mutation";
  updateContractTotals: { __typename?: "Contract"; id: string };
};

export type ContractSeasonFragment = {
  __typename?: "ContractSeason";
  contractId: string;
  season: number;
  p5: number;
  signingBonus: number;
  signingBonusProrated: number;
  optionBonus: number;
  optionBonusProrated: number;
  existingProration?: number | null;
  reportingBonus: number;
  workoutBonus: number;
  incentives: number;
  escalators: number;
  isVoidYear: boolean;
  isOptionYear: boolean;
  rosterBonuses: {
    __typename?: "RosterBonus";
    ninetyMan: number;
    fiftyThreeMan: number;
    fortySixMan: number;
    perGame: number;
  };
  carryOvers: { __typename?: "CarryOver"; existingCash: number; cap: number };
  guarantees: {
    __typename?: "Guarantee";
    p5: number;
    p5GuaranteedType: string;
    p5Conversion: number;
    p5Injury: number;
    rosterBonus53Man: number;
    rosterBonus53ManGuaranteedType: string;
    rosterBonusPerGame: number;
    rosterBonusPerGameProrated: number;
    rosterBonusPerGameGuaranteedType: string;
    rosterBonus46Man: number;
    rosterBonus46ManGuaranteedType: string;
    rosterBonus90Man: number;
    rosterBonus90ManProrated: number;
    rosterBonus90ManGuaranteedType: string;
    rosterBonusConversion: number;
    rosterBonusInjury: number;
    reportingBonus: number;
    reportingBonusProrated: number;
    reportingBonusGuaranteedType: string;
    workoutBonus: number;
    workoutBonusGuaranteedType: string;
    incentives: number;
    incentivesGuaranteedType: string;
    optionBonus: number;
    practical: number;
    offseasonConversion: number;
  };
};

export type ContractSectionFragment = {
  __typename?: "ContractSeasonSection";
  id: string;
  contractId: string;
  season: number;
  isProrate: boolean;
  clauseType?: string | null;
  capAmt: number;
  guaranteeAmount: number;
  sectionTitle: string;
  sectionText: string;
  sectionYearRepId: number;
  statusId: string;
  sectionType: { __typename?: "SectionType"; id: string; type: string };
};

export type TransactionContractFragment = {
  __typename?: "Contract";
  playerId: string;
  firstContractYear: number;
  effectiveLastContractYear: number;
  signingType: string;
  total: { __typename?: "ContractTotals"; guaranteed: number };
  sumer: { __typename?: "SumerValues"; evaluated: boolean };
};

export type ContractFragment = {
  __typename?: "Contract";
  id: string;
  leagueContractId: number;
  playerId: string;
  clubId: string;
  firstContractYear: number;
  lastContractYear: number;
  effectiveLastContractYear: number;
  isEffectiveContract: boolean;
  isMostRecentContract: boolean;
  signDate: string;
  signingType: string;
  contractSignAge?: number | null;
  previousContractId?: number | null;
  agent?: {
    __typename?: "contracts_internal_Agent";
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    company?: string | null;
  } | null;
  seasons: Array<{
    __typename?: "ContractSeason";
    contractId: string;
    season: number;
    p5: number;
    signingBonus: number;
    signingBonusProrated: number;
    optionBonus: number;
    optionBonusProrated: number;
    existingProration?: number | null;
    reportingBonus: number;
    workoutBonus: number;
    incentives: number;
    escalators: number;
    isVoidYear: boolean;
    isOptionYear: boolean;
    rosterBonuses: {
      __typename?: "RosterBonus";
      ninetyMan: number;
      fiftyThreeMan: number;
      fortySixMan: number;
      perGame: number;
    };
    carryOvers: { __typename?: "CarryOver"; existingCash: number; cap: number };
    guarantees: {
      __typename?: "Guarantee";
      p5: number;
      p5GuaranteedType: string;
      p5Conversion: number;
      p5Injury: number;
      rosterBonus53Man: number;
      rosterBonus53ManGuaranteedType: string;
      rosterBonusPerGame: number;
      rosterBonusPerGameProrated: number;
      rosterBonusPerGameGuaranteedType: string;
      rosterBonus46Man: number;
      rosterBonus46ManGuaranteedType: string;
      rosterBonus90Man: number;
      rosterBonus90ManProrated: number;
      rosterBonus90ManGuaranteedType: string;
      rosterBonusConversion: number;
      rosterBonusInjury: number;
      reportingBonus: number;
      reportingBonusProrated: number;
      reportingBonusGuaranteedType: string;
      workoutBonus: number;
      workoutBonusGuaranteedType: string;
      incentives: number;
      incentivesGuaranteedType: string;
      optionBonus: number;
      practical: number;
      offseasonConversion: number;
    };
  }>;
  sections: Array<{
    __typename?: "ContractSeasonSection";
    id: string;
    contractId: string;
    season: number;
    isProrate: boolean;
    clauseType?: string | null;
    capAmt: number;
    guaranteeAmount: number;
    sectionTitle: string;
    sectionText: string;
    sectionYearRepId: number;
    statusId: string;
    sectionType: { __typename?: "SectionType"; id: string; type: string };
  }>;
  sumer: {
    __typename?: "SumerValues";
    isRework: boolean;
    evaluated: boolean;
    existingYears?: number | null;
    existingAmount?: number | null;
    existingAmountGuaranteed?: number | null;
    isExistingAmountGuaranteed?: boolean | null;
    functionalYears?: number | null;
    functionalAmount?: number | null;
    practicalGuaranteed?: number | null;
    comments?: string | null;
  };
};

export type AgentFragment = {
  __typename?: "Agent";
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  company?: string | null;
};

export type GetPlayerContractsByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
  effectiveOnly: Scalars["Boolean"];
}>;

export type GetPlayerContractsByIdQuery = {
  __typename?: "Query";
  playerContractsById: Array<{
    __typename?: "Contract";
    id: string;
    leagueContractId: number;
    playerId: string;
    clubId: string;
    firstContractYear: number;
    lastContractYear: number;
    effectiveLastContractYear: number;
    isEffectiveContract: boolean;
    isMostRecentContract: boolean;
    signDate: string;
    signingType: string;
    contractSignAge?: number | null;
    previousContractId?: number | null;
    agent?: {
      __typename?: "contracts_internal_Agent";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      company?: string | null;
    } | null;
    seasons: Array<{
      __typename?: "ContractSeason";
      contractId: string;
      season: number;
      p5: number;
      signingBonus: number;
      signingBonusProrated: number;
      optionBonus: number;
      optionBonusProrated: number;
      existingProration?: number | null;
      reportingBonus: number;
      workoutBonus: number;
      incentives: number;
      escalators: number;
      isVoidYear: boolean;
      isOptionYear: boolean;
      rosterBonuses: {
        __typename?: "RosterBonus";
        ninetyMan: number;
        fiftyThreeMan: number;
        fortySixMan: number;
        perGame: number;
      };
      carryOvers: {
        __typename?: "CarryOver";
        existingCash: number;
        cap: number;
      };
      guarantees: {
        __typename?: "Guarantee";
        p5: number;
        p5GuaranteedType: string;
        p5Conversion: number;
        p5Injury: number;
        rosterBonus53Man: number;
        rosterBonus53ManGuaranteedType: string;
        rosterBonusPerGame: number;
        rosterBonusPerGameProrated: number;
        rosterBonusPerGameGuaranteedType: string;
        rosterBonus46Man: number;
        rosterBonus46ManGuaranteedType: string;
        rosterBonus90Man: number;
        rosterBonus90ManProrated: number;
        rosterBonus90ManGuaranteedType: string;
        rosterBonusConversion: number;
        rosterBonusInjury: number;
        reportingBonus: number;
        reportingBonusProrated: number;
        reportingBonusGuaranteedType: string;
        workoutBonus: number;
        workoutBonusGuaranteedType: string;
        incentives: number;
        incentivesGuaranteedType: string;
        optionBonus: number;
        practical: number;
        offseasonConversion: number;
      };
    }>;
    sections: Array<{
      __typename?: "ContractSeasonSection";
      id: string;
      contractId: string;
      season: number;
      isProrate: boolean;
      clauseType?: string | null;
      capAmt: number;
      guaranteeAmount: number;
      sectionTitle: string;
      sectionText: string;
      sectionYearRepId: number;
      statusId: string;
      sectionType: { __typename?: "SectionType"; id: string; type: string };
    }>;
    sumer: {
      __typename?: "SumerValues";
      isRework: boolean;
      evaluated: boolean;
      existingYears?: number | null;
      existingAmount?: number | null;
      existingAmountGuaranteed?: number | null;
      isExistingAmountGuaranteed?: boolean | null;
      functionalYears?: number | null;
      functionalAmount?: number | null;
      practicalGuaranteed?: number | null;
      comments?: string | null;
    };
  }>;
};

export type GetPlayerTransactionContractByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetPlayerTransactionContractByIdQuery = {
  __typename?: "Query";
  playerContractsById: Array<{
    __typename?: "Contract";
    playerId: string;
    firstContractYear: number;
    effectiveLastContractYear: number;
    signingType: string;
    total: { __typename?: "ContractTotals"; guaranteed: number };
    sumer: { __typename?: "SumerValues"; evaluated: boolean };
  }>;
};

export type GetContractByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetContractByIdQuery = {
  __typename?: "Query";
  contracts: Array<{
    __typename?: "Contract";
    id: string;
    leagueContractId: number;
    playerId: string;
    clubId: string;
    firstContractYear: number;
    lastContractYear: number;
    effectiveLastContractYear: number;
    isEffectiveContract: boolean;
    isMostRecentContract: boolean;
    signDate: string;
    signingType: string;
    contractSignAge?: number | null;
    previousContractId?: number | null;
    agent?: {
      __typename?: "contracts_internal_Agent";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      company?: string | null;
    } | null;
    seasons: Array<{
      __typename?: "ContractSeason";
      contractId: string;
      season: number;
      p5: number;
      signingBonus: number;
      signingBonusProrated: number;
      optionBonus: number;
      optionBonusProrated: number;
      existingProration?: number | null;
      reportingBonus: number;
      workoutBonus: number;
      incentives: number;
      escalators: number;
      isVoidYear: boolean;
      isOptionYear: boolean;
      rosterBonuses: {
        __typename?: "RosterBonus";
        ninetyMan: number;
        fiftyThreeMan: number;
        fortySixMan: number;
        perGame: number;
      };
      carryOvers: {
        __typename?: "CarryOver";
        existingCash: number;
        cap: number;
      };
      guarantees: {
        __typename?: "Guarantee";
        p5: number;
        p5GuaranteedType: string;
        p5Conversion: number;
        p5Injury: number;
        rosterBonus53Man: number;
        rosterBonus53ManGuaranteedType: string;
        rosterBonusPerGame: number;
        rosterBonusPerGameProrated: number;
        rosterBonusPerGameGuaranteedType: string;
        rosterBonus46Man: number;
        rosterBonus46ManGuaranteedType: string;
        rosterBonus90Man: number;
        rosterBonus90ManProrated: number;
        rosterBonus90ManGuaranteedType: string;
        rosterBonusConversion: number;
        rosterBonusInjury: number;
        reportingBonus: number;
        reportingBonusProrated: number;
        reportingBonusGuaranteedType: string;
        workoutBonus: number;
        workoutBonusGuaranteedType: string;
        incentives: number;
        incentivesGuaranteedType: string;
        optionBonus: number;
        practical: number;
        offseasonConversion: number;
      };
    }>;
    sections: Array<{
      __typename?: "ContractSeasonSection";
      id: string;
      contractId: string;
      season: number;
      isProrate: boolean;
      clauseType?: string | null;
      capAmt: number;
      guaranteeAmount: number;
      sectionTitle: string;
      sectionText: string;
      sectionYearRepId: number;
      statusId: string;
      sectionType: { __typename?: "SectionType"; id: string; type: string };
    }>;
    sumer: {
      __typename?: "SumerValues";
      isRework: boolean;
      evaluated: boolean;
      existingYears?: number | null;
      existingAmount?: number | null;
      existingAmountGuaranteed?: number | null;
      isExistingAmountGuaranteed?: boolean | null;
      functionalYears?: number | null;
      functionalAmount?: number | null;
      practicalGuaranteed?: number | null;
      comments?: string | null;
    };
  }>;
};

export type GetContractWorksheetItemsByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetContractWorksheetItemsByIdQuery = {
  __typename?: "Query";
  contractWorksheetItemsById: Array<{
    __typename?: "WorksheetItem";
    id: string;
    contractId: string;
    season: number;
    label: string;
    item: string;
    amount?: number | null;
    prorate: boolean;
    proratedAmount?: number | null;
    guaranteed: boolean;
    guaranteedType?: string | null;
    guaranteedAmount?: number | null;
    conversionType?: ConversionTypeEnum | null;
    vestingDate?: string | null;
    comments: string;
    vestingOption?: string | null;
    day?: number | null;
    deferred: boolean;
    deferredDate?: string | null;
    deferredAmount?: number | null;
  }>;
};

export type GetPlayerContractsQueryVariables = Exact<{
  ids?: InputMaybe<
    Array<InputMaybe<Scalars["UUID"]>> | InputMaybe<Scalars["UUID"]>
  >;
}>;

export type GetPlayerContractsQuery = {
  __typename?: "Query";
  contracts: Array<{
    __typename?: "Contract";
    id: string;
    playerId: string;
    clubId: string;
    isEffectiveContract: boolean;
    effectiveLastContractYear: number;
    sumer: {
      __typename?: "SumerValues";
      isRework: boolean;
      evaluated: boolean;
    };
    total: { __typename?: "ContractTotals"; guaranteed: number; apy: number };
  }>;
};

export type GetSalaryCapAccountingByPlayerQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetSalaryCapAccountingByPlayerQuery = {
  __typename?: "Query";
  leagueSalaryCapRollupsByPlayer: Array<{
    __typename?: "LeagueSalaryCapRollup";
    id: string;
    playerId: string;
    season: number;
    capAmount?: number | null;
    para5?: number | null;
    signingBonus?: number | null;
    ltbe?: number | null;
    miscellaneous?: number | null;
    potAccel?: number | null;
    fullPara5?: number | null;
    reAllocation?: number | null;
    vetCapCredit?: number | null;
    loadDate?: string | null;
  }>;
  leagueSalaryCapDetailsByPlayer: Array<{
    __typename?: "LeagueSalaryCapDetail";
    id: string;
    playerId: string;
    season?: number | null;
    capAmount?: number | null;
    cashAmount?: number | null;
    sectionId: number;
    sectionType?: string | null;
    sectionTitle?: string | null;
    clubCode?: string | null;
    signDate?: string | null;
  }>;
};

export type GetLeagueCapAmountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLeagueCapAmountsQuery = {
  __typename?: "Query";
  leagueCapAmounts: Array<{
    __typename?: "LeagueSalaryCap";
    id: string;
    season: number;
    capAmount?: number | null;
    minRookieP5?: number | null;
  }>;
};

export type GetLeagueClubCapAmountsQueryVariables = Exact<{
  season: Scalars["Int"];
}>;

export type GetLeagueClubCapAmountsQuery = {
  __typename?: "Query";
  leagueClubCapAmounts: Array<{
    __typename?: "LeagueCapSeasonInfo";
    id: string;
    season?: number | null;
    clubId: string;
    nflCap?: number | null;
    clubCap?: number | null;
    minRookieP5?: number | null;
    clubAdjustment?: number | null;
    carryOver?: number | null;
  }>;
};

export type CreateEvaluationMutationVariables = Exact<{
  request: CreateEvaluationRequest;
}>;

export type CreateEvaluationMutation = {
  __typename?: "Mutation";
  createEvaluation?: {
    __typename?: "Evaluation";
    id: string;
    season: number;
    isFinal: boolean;
    isPrimary: boolean;
    playerId: string;
    positionId: string;
    scoutGradeId?: string | null;
    summary?: string | null;
    bottomLine?: string | null;
    characterSummary?: string | null;
    lastUpdated: string;
    finalizedOn?: string | null;
    status: EvalStatusEnum;
    position: { __typename?: "Position"; id: string; shortName: string };
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
  } | null;
};

export type UpdateEvaluationMutationVariables = Exact<{
  request: UpdateEvaluationRequest;
}>;

export type UpdateEvaluationMutation = {
  __typename?: "Mutation";
  updateEvaluation?: {
    __typename?: "Evaluation";
    id: string;
    season: number;
    isFinal: boolean;
    isPrimary: boolean;
    playerId: string;
    positionId: string;
    scoutGradeId?: string | null;
    summary?: string | null;
    bottomLine?: string | null;
    characterSummary?: string | null;
    lastUpdated: string;
    finalizedOn?: string | null;
    status: EvalStatusEnum;
    position: { __typename?: "Position"; id: string; shortName: string };
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
  } | null;
};

export type SaveEvaluationTraitMutationVariables = Exact<{
  request: SaveEvaluationTraitRequest;
}>;

export type SaveEvaluationTraitMutation = {
  __typename?: "Mutation";
  saveEvaluationTrait?: {
    __typename?: "Evaluation";
    id: string;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
        quality: string;
      } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        shortName: string;
        sort: number;
      };
    }>;
  } | null;
};

export type SaveEvaluationFitsMutationVariables = Exact<{
  request: SaveEvaluationFitRequest;
}>;

export type SaveEvaluationFitsMutation = {
  __typename?: "Mutation";
  saveEvaluationFits?: {
    __typename?: "Evaluation";
    id: string;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
  } | null;
};

export type SaveEvaluationClubFitsMutationVariables = Exact<{
  request: SaveEvaluationClubFitRequest;
}>;

export type SaveEvaluationClubFitsMutation = {
  __typename?: "Mutation";
  saveEvaluationClubFits?: {
    __typename?: "Evaluation";
    id: string;
    clubFits: Array<{
      __typename?: "EvaluationClubFit";
      id: string;
      evaluationId: string;
      club: { __typename?: "Club"; id: string; clubCode: string };
    }>;
  } | null;
};

export type UnMarkEvaluationAsFinalMutationVariables = Exact<{
  request: UnMarkEvaluationAsFinalRequest;
}>;

export type UnMarkEvaluationAsFinalMutation = {
  __typename?: "Mutation";
  unMarkEvaluationAsFinal?: {
    __typename?: "Evaluation";
    id: string;
    isFinal: boolean;
    finalizedOn?: string | null;
  } | null;
};

export type SaveEvaluationAlertsMutationVariables = Exact<{
  request: SaveEvaluationAlertRequest;
}>;

export type SaveEvaluationAlertsMutation = {
  __typename?: "Mutation";
  saveEvaluationAlerts?: {
    __typename?: "Evaluation";
    id: string;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
  } | null;
};

export type CreateGameEvaluationMutationVariables = Exact<{
  request: CreateGameEvaluationRequest;
}>;

export type CreateGameEvaluationMutation = {
  __typename?: "Mutation";
  createGameEvaluation?: {
    __typename?: "Evaluation";
    id: string;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      id: string;
      gameId: string;
      evaluationId: string;
      scoutGradeId?: string | null;
      comments?: string | null;
      game: {
        __typename?: "Game";
        id: string;
        kickoffTime?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
      };
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
    }>;
  } | null;
};

export type UpdateGameEvaluationMutationVariables = Exact<{
  request: UpdateGameEvaluationRequest;
}>;

export type UpdateGameEvaluationMutation = {
  __typename?: "Mutation";
  updateGameEvaluation?: {
    __typename?: "Evaluation";
    id: string;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      id: string;
      gameId: string;
      evaluationId: string;
      scoutGradeId?: string | null;
      comments?: string | null;
      game: {
        __typename?: "Game";
        id: string;
        kickoffTime?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
      };
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
    }>;
  } | null;
};

export type SaveEvaluationTraitGroupMutationVariables = Exact<{
  request: SaveEvaluationTraitGroupRequest;
}>;

export type SaveEvaluationTraitGroupMutation = {
  __typename?: "Mutation";
  saveEvaluationTraitGroup?: {
    __typename?: "Evaluation";
    id: string;
    scoutGradeId?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
      quality: string;
    } | null;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      id: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
    }>;
  } | null;
};

export type FlagEvaluationForDeleteMutationVariables = Exact<{
  request: FlagEvaluationForDeleteRequest;
}>;

export type FlagEvaluationForDeleteMutation = {
  __typename?: "Mutation";
  flagEvaluationForDelete?: {
    __typename?: "Evaluation";
    id: string;
    isDeleted: boolean;
  } | null;
};

export type MarkEvaluationAsPrimaryMutationVariables = Exact<{
  request: MarkEvaluationAsPrimaryRequest;
}>;

export type MarkEvaluationAsPrimaryMutation = {
  __typename?: "Mutation";
  markEvaluationAsPrimary?: {
    __typename?: "Evaluation";
    id: string;
    isPrimary: boolean;
  } | null;
};

export type DeleteGameEvaluationMutationVariables = Exact<{
  evaluationId: Scalars["UUID"];
  evaluationGameId: Scalars["UUID"];
}>;

export type DeleteGameEvaluationMutation = {
  __typename?: "Mutation";
  deleteGameEvaluation?: { __typename?: "Evaluation"; id: string } | null;
};

export type AssignWorkInProgressEvaluationStatusMutationVariables = Exact<{
  request: AssignWipEvaluationStatusRequest;
}>;

export type AssignWorkInProgressEvaluationStatusMutation = {
  __typename?: "Mutation";
  assignWorkInProgressEvaluationStatus?: {
    __typename?: "Evaluation";
    id: string;
    status: EvalStatusEnum;
  } | null;
};

export type AssignReadyForEditEvaluationStatusMutationVariables = Exact<{
  request: AssignReadyForEditEvaluationStatusRequest;
}>;

export type AssignReadyForEditEvaluationStatusMutation = {
  __typename?: "Mutation";
  assignReadyForEditEvaluationStatus?: {
    __typename?: "Evaluation";
    id: string;
    status: EvalStatusEnum;
  } | null;
};

export type AssignFinalEvaluationStatusMutationVariables = Exact<{
  request: AssignFinalEvaluationStatusRequest;
}>;

export type AssignFinalEvaluationStatusMutation = {
  __typename?: "Mutation";
  assignFinalEvaluationStatus?: {
    __typename?: "Evaluation";
    id: string;
    status: EvalStatusEnum;
  } | null;
};

export type EvalFragment = {
  __typename?: "Evaluation";
  id: string;
  created: string;
  lastUpdated: string;
  isFinal: boolean;
  finalizedOn?: string | null;
  summary?: string | null;
  bottomLine?: string | null;
  characterSummary?: string | null;
  season: number;
  isPrimary: boolean;
  status: EvalStatusEnum;
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  player: {
    __typename?: "Player";
    id: string;
    firstName: string;
    lastName: string;
    headshotUrl?: string | null;
    school?: {
      __typename?: "School";
      id: string;
      schoolName: string;
      schoolCode?: string | null;
    } | null;
    club?: {
      __typename?: "Club";
      id: string;
      division: string;
      conference: string;
      clubCode: string;
      clubName: string;
      location: string;
    } | null;
  };
  position: { __typename?: "Position"; id: string; shortName: string };
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    id: string;
    comments?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    traitDefinition: {
      __typename?: "TraitDefinition";
      id: string;
      caption: string;
      shortName: string;
      sort: number;
    };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    id: string;
    gameId: string;
    comments?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    game: {
      __typename?: "Game";
      id: string;
      season: number;
      week?: number | null;
      venue?: string | null;
      homeTeamId?: string | null;
      homeTeam?: string | null;
      awayTeam?: string | null;
      homeTeamScore?: number | null;
      awayTeamScore?: number | null;
      kickoffTime?: string | null;
      seasonType: {
        __typename?: "SeasonType";
        id: number;
        abbreviation?: string | null;
        description?: string | null;
      };
      weekType: {
        __typename?: "WeekType";
        id: number;
        description?: string | null;
      };
      leagueType: {
        __typename?: "LeagueType";
        id: number;
        description?: string | null;
      };
    };
  }>;
  clubFits: Array<{
    __typename?: "EvaluationClubFit";
    id: string;
    evaluationId: string;
    club: { __typename?: "Club"; id: string; clubCode: string };
  }>;
  traitGroupGrades: Array<{
    __typename?: "EvaluationTraitGroup";
    id: string;
    evaluationId: string;
    comments?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    traitGroup: {
      __typename?: "TraitGroup";
      id: string;
      caption: string;
      sort: number;
      traitGroupPositions: Array<{
        __typename?: "TraitGroupPosition";
        id: string;
        positionId: string;
        sort: number;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          description?: string | null;
          sort: number;
        };
      }>;
    };
  }>;
  evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
};

export type EvalSummaryFragment = {
  __typename?: "Evaluation";
  id: string;
  created: string;
  lastUpdated: string;
  isFinal: boolean;
  finalizedOn?: string | null;
  season: number;
  isPrimary: boolean;
  status: EvalStatusEnum;
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  player: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    draftYear?: number | null;
    eligibility?: EligibilityType | null;
    latestEligibility?: EligibilityType | null;
    eligibilityYear?: number | null;
    latestEligibilityYear?: number | null;
    latestBirthDate?: string | null;
    redshirt: boolean;
    latestSchoolId?: string | null;
    schoolId?: string | null;
    headshotUrl?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    club?: {
      __typename?: "Club";
      id: string;
      division: string;
      clubCode: string;
      clubName: string;
      location: string;
    } | null;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
  };
  position: {
    __typename?: "Position";
    id: string;
    shortName: string;
    sort?: number | null;
  };
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  clubFits: Array<{
    __typename?: "EvaluationClubFit";
    id: string;
    evaluationId: string;
    club: { __typename?: "Club"; id: string; clubCode: string };
  }>;
  evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    scoutGradeId?: string | null;
  }>;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    id: string;
    scoutGradeId?: string | null;
    comments?: string | null;
    traitDefinition: { __typename?: "TraitDefinition"; id: string };
  }>;
};

export type PriorSeasonEvalFragment = {
  __typename?: "Evaluation";
  id: string;
  season: number;
  lastUpdated: string;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    id: string;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    traitDefinition: { __typename?: "TraitDefinition"; id: string };
  }>;
  traitGroupGrades: Array<{
    __typename?: "EvaluationTraitGroup";
    id: string;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    traitGroup: { __typename?: "TraitGroup"; id: string };
  }>;
};

export type EvalSummaryTraitGradeFragment = {
  __typename?: "EvaluationTrait";
  id: string;
  scoutGradeId?: string | null;
  comments?: string | null;
  traitDefinition: { __typename?: "TraitDefinition"; id: string };
};

export type EvalSummaryWithMeasuresFragment = {
  __typename?: "Evaluation";
  id: string;
  created: string;
  lastUpdated: string;
  isFinal: boolean;
  finalizedOn?: string | null;
  season: number;
  isPrimary: boolean;
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  player: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    draftYear?: number | null;
    eligibility?: EligibilityType | null;
    eligibilityYear?: number | null;
    redshirt: boolean;
    headshotUrl?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    school?: {
      __typename?: "School";
      id: string;
      schoolName: string;
      schoolCode?: string | null;
    } | null;
    club?: {
      __typename?: "Club";
      id: string;
      division: string;
      clubCode: string;
      clubName: string;
      location: string;
    } | null;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
  };
  position: {
    __typename?: "Position";
    id: string;
    shortName: string;
    sort?: number | null;
  };
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  clubFits: Array<{
    __typename?: "EvaluationClubFit";
    id: string;
    evaluationId: string;
    club: { __typename?: "Club"; id: string; clubCode: string };
  }>;
  evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
};

export type EvalMatrixExportFragment = {
  __typename?: "Evaluation";
  id: string;
  created: string;
  lastUpdated: string;
  isFinal: boolean;
  finalizedOn?: string | null;
  season: number;
  isPrimary: boolean;
  status: EvalStatusEnum;
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
  player: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    draftYear?: number | null;
    eligibility?: EligibilityType | null;
    latestEligibility?: EligibilityType | null;
    eligibilityYear?: number | null;
    latestEligibilityYear?: number | null;
    latestBirthDate?: string | null;
    redshirt: boolean;
    latestSchoolId?: string | null;
    schoolId?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
  };
  position: {
    __typename?: "Position";
    id: string;
    shortName: string;
    sort?: number | null;
  };
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  }>;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    id: string;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    traitDefinition: {
      __typename?: "TraitDefinition";
      id: string;
      caption: string;
      shortName: string;
      sort: number;
    };
  }>;
  traitGroupGrades: Array<{
    __typename?: "EvaluationTraitGroup";
    id: string;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    traitGroup: {
      __typename?: "TraitGroup";
      id: string;
      caption: string;
      sort: number;
      traitGroupPositions: Array<{
        __typename?: "TraitGroupPosition";
        id: string;
        positionId: string;
        sort: number;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          description?: string | null;
          sort: number;
        };
      }>;
    };
  }>;
};

export type EvaluationFitFragment = {
  __typename?: "EvaluationFit";
  id: string;
  evaluationId: string;
  priority: number;
  fit: { __typename?: "Fit"; id: string; position: string; name: string };
};

export type EvaluationClubFitFragment = {
  __typename?: "EvaluationClubFit";
  id: string;
  evaluationId: string;
  club: { __typename?: "Club"; id: string; clubCode: string };
};

export type EvaluationAlertFragment = {
  __typename?: "EvaluationAlert";
  id: string;
  evaluationId: string;
  alert: {
    __typename?: "Alert";
    id: string;
    caption: string;
    abbreviation: string;
    sort?: number | null;
  };
};

export type EvaluationTraitFragment = {
  __typename?: "EvaluationTrait";
  id: string;
  comments?: string | null;
  traitDefinition: {
    __typename?: "TraitDefinition";
    id: string;
    caption: string;
    sort: number;
  };
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
};

export type EvaluationTraitGroupFragment = {
  __typename?: "EvaluationTraitGroup";
  id: string;
  evaluationId: string;
  comments?: string | null;
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  traitGroup: {
    __typename?: "TraitGroup";
    id: string;
    caption: string;
    sort: number;
    traitGroupPositions: Array<{
      __typename?: "TraitGroupPosition";
      id: string;
      positionId: string;
      sort: number;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        description?: string | null;
        sort: number;
      };
    }>;
  };
};

export type TraitGroupFragment = {
  __typename?: "TraitGroup";
  id: string;
  caption: string;
  sort: number;
  traitGroupPositions: Array<{
    __typename?: "TraitGroupPosition";
    id: string;
    positionId: string;
    sort: number;
    traitDefinition: {
      __typename?: "TraitDefinition";
      id: string;
      caption: string;
      description?: string | null;
      sort: number;
    };
  }>;
};

export type TraitGroupPositionFragment = {
  __typename?: "TraitGroupPosition";
  id: string;
  positionId: string;
  sort: number;
  traitDefinition: {
    __typename?: "TraitDefinition";
    id: string;
    caption: string;
    description?: string | null;
    sort: number;
  };
};

export type FitFragment = {
  __typename?: "Fit";
  id: string;
  position: string;
  name: string;
};

export type AlertFragment = {
  __typename?: "Alert";
  id: string;
  caption: string;
  abbreviation: string;
  sort?: number | null;
};

export type EvaluationGameFragment = {
  __typename?: "EvaluationGame";
  id: string;
  gameId: string;
  comments?: string | null;
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  game: {
    __typename?: "Game";
    id: string;
    season: number;
    week?: number | null;
    venue?: string | null;
    homeTeamId?: string | null;
    homeTeam?: string | null;
    awayTeam?: string | null;
    homeTeamScore?: number | null;
    awayTeamScore?: number | null;
    kickoffTime?: string | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
  };
};

export type ScoutGradeFragment = {
  __typename?: "ScoutGrade";
  id: string;
  grade: number;
  quality: string;
  description: string;
};

export type GetEvalQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetEvalQuery = {
  __typename?: "Query";
  evalById?: {
    __typename?: "Evaluation";
    id: string;
    created: string;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    summary?: string | null;
    bottomLine?: string | null;
    characterSummary?: string | null;
    season: number;
    isPrimary: boolean;
    status: EvalStatusEnum;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      headshotUrl?: string | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolName: string;
        schoolCode?: string | null;
      } | null;
      club?: {
        __typename?: "Club";
        id: string;
        division: string;
        conference: string;
        clubCode: string;
        clubName: string;
        location: string;
      } | null;
    };
    position: { __typename?: "Position"; id: string; shortName: string };
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        shortName: string;
        sort: number;
      };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      id: string;
      gameId: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      game: {
        __typename?: "Game";
        id: string;
        season: number;
        week?: number | null;
        venue?: string | null;
        homeTeamId?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
        homeTeamScore?: number | null;
        awayTeamScore?: number | null;
        kickoffTime?: string | null;
        seasonType: {
          __typename?: "SeasonType";
          id: number;
          abbreviation?: string | null;
          description?: string | null;
        };
        weekType: {
          __typename?: "WeekType";
          id: number;
          description?: string | null;
        };
        leagueType: {
          __typename?: "LeagueType";
          id: number;
          description?: string | null;
        };
      };
    }>;
    clubFits: Array<{
      __typename?: "EvaluationClubFit";
      id: string;
      evaluationId: string;
      club: { __typename?: "Club"; id: string; clubCode: string };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      id: string;
      evaluationId: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      traitGroup: {
        __typename?: "TraitGroup";
        id: string;
        caption: string;
        sort: number;
        traitGroupPositions: Array<{
          __typename?: "TraitGroupPosition";
          id: string;
          positionId: string;
          sort: number;
          traitDefinition: {
            __typename?: "TraitDefinition";
            id: string;
            caption: string;
            description?: string | null;
            sort: number;
          };
        }>;
      };
    }>;
    evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
  } | null;
};

export type GetEvalsQueryVariables = Exact<{
  position?: InputMaybe<Scalars["UUID"]>;
  user?: InputMaybe<Scalars["UUID"]>;
  playerId?: InputMaybe<Scalars["UUID"]>;
  group?: InputMaybe<EvalTypeEnum>;
}>;

export type GetEvalsQuery = {
  __typename?: "Query";
  evals: Array<{
    __typename?: "Evaluation";
    id: string;
    created: string;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    summary?: string | null;
    bottomLine?: string | null;
    characterSummary?: string | null;
    season: number;
    isPrimary: boolean;
    status: EvalStatusEnum;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      headshotUrl?: string | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolName: string;
        schoolCode?: string | null;
      } | null;
      club?: {
        __typename?: "Club";
        id: string;
        division: string;
        conference: string;
        clubCode: string;
        clubName: string;
        location: string;
      } | null;
    };
    position: { __typename?: "Position"; id: string; shortName: string };
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        shortName: string;
        sort: number;
      };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      id: string;
      gameId: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      game: {
        __typename?: "Game";
        id: string;
        season: number;
        week?: number | null;
        venue?: string | null;
        homeTeamId?: string | null;
        homeTeam?: string | null;
        awayTeam?: string | null;
        homeTeamScore?: number | null;
        awayTeamScore?: number | null;
        kickoffTime?: string | null;
        seasonType: {
          __typename?: "SeasonType";
          id: number;
          abbreviation?: string | null;
          description?: string | null;
        };
        weekType: {
          __typename?: "WeekType";
          id: number;
          description?: string | null;
        };
        leagueType: {
          __typename?: "LeagueType";
          id: number;
          description?: string | null;
        };
      };
    }>;
    clubFits: Array<{
      __typename?: "EvaluationClubFit";
      id: string;
      evaluationId: string;
      club: { __typename?: "Club"; id: string; clubCode: string };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      id: string;
      evaluationId: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      traitGroup: {
        __typename?: "TraitGroup";
        id: string;
        caption: string;
        sort: number;
        traitGroupPositions: Array<{
          __typename?: "TraitGroupPosition";
          id: string;
          positionId: string;
          sort: number;
          traitDefinition: {
            __typename?: "TraitDefinition";
            id: string;
            caption: string;
            description?: string | null;
            sort: number;
          };
        }>;
      };
    }>;
    evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
  }>;
};

export type GetPriorSeasonEvalTraitsQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
  season?: InputMaybe<Scalars["Int"]>;
}>;

export type GetPriorSeasonEvalTraitsQuery = {
  __typename?: "Query";
  evals: Array<{
    __typename?: "Evaluation";
    id: string;
    season: number;
    lastUpdated: string;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitDefinition: { __typename?: "TraitDefinition"; id: string };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      id: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      traitGroup: { __typename?: "TraitGroup"; id: string };
    }>;
  }>;
};

export type GetEvalSummariesQueryVariables = Exact<{
  position?: InputMaybe<Scalars["UUID"]>;
  user?: InputMaybe<Scalars["UUID"]>;
  playerId?: InputMaybe<Scalars["UUID"]>;
  group?: InputMaybe<EvalTypeEnum>;
}>;

export type GetEvalSummariesQuery = {
  __typename?: "Query";
  evals: Array<{
    __typename?: "Evaluation";
    id: string;
    created: string;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    season: number;
    isPrimary: boolean;
    status: EvalStatusEnum;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    player: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      draftYear?: number | null;
      eligibility?: EligibilityType | null;
      latestEligibility?: EligibilityType | null;
      eligibilityYear?: number | null;
      latestEligibilityYear?: number | null;
      latestBirthDate?: string | null;
      redshirt: boolean;
      latestSchoolId?: string | null;
      schoolId?: string | null;
      headshotUrl?: string | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      club?: {
        __typename?: "Club";
        id: string;
        division: string;
        clubCode: string;
        clubName: string;
        location: string;
      } | null;
      playerOverrides: Array<{
        __typename?: "PlayerOverride";
        field: string;
        value?: string | null;
      }>;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        id: string;
        playerId: string;
        value: number;
        isVerified: boolean;
        workout: {
          __typename?: "Workout";
          id: string;
          comments?: string | null;
          workoutDate: string;
          workoutType: {
            __typename?: "WorkoutType";
            name: string;
            priority: number;
          };
        };
        measureType: {
          __typename?: "MeasureType";
          shortName: string;
          name: string;
          isFraction: boolean;
        };
      }>;
    };
    position: {
      __typename?: "Position";
      id: string;
      shortName: string;
      sort?: number | null;
    };
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    clubFits: Array<{
      __typename?: "EvaluationClubFit";
      id: string;
      evaluationId: string;
      club: { __typename?: "Club"; id: string; clubCode: string };
    }>;
    evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      scoutGradeId?: string | null;
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      scoutGradeId?: string | null;
      comments?: string | null;
      traitDefinition: { __typename?: "TraitDefinition"; id: string };
    }>;
  }>;
};

export type GetEvalMatrixForExportQueryVariables = Exact<{
  position?: InputMaybe<Scalars["UUID"]>;
  user?: InputMaybe<Scalars["UUID"]>;
  playerId?: InputMaybe<Scalars["UUID"]>;
  group?: InputMaybe<EvalTypeEnum>;
}>;

export type GetEvalMatrixForExportQuery = {
  __typename?: "Query";
  evals: Array<{
    __typename?: "Evaluation";
    id: string;
    created: string;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    season: number;
    isPrimary: boolean;
    status: EvalStatusEnum;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    evalType: { __typename?: "EvalType"; id: string; type: EvalTypeEnum };
    player: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      draftYear?: number | null;
      eligibility?: EligibilityType | null;
      latestEligibility?: EligibilityType | null;
      eligibilityYear?: number | null;
      latestEligibilityYear?: number | null;
      latestBirthDate?: string | null;
      redshirt: boolean;
      latestSchoolId?: string | null;
      schoolId?: string | null;
      height?: number | null;
      weight?: number | null;
      speed?: number | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      club?: { __typename?: "Club"; id: string; clubCode: string } | null;
      playerOverrides: Array<{
        __typename?: "PlayerOverride";
        field: string;
        value?: string | null;
      }>;
      workouts: Array<{
        __typename?: "WorkoutPlayerMeasure";
        id: string;
        playerId: string;
        value: number;
        isVerified: boolean;
        workout: {
          __typename?: "Workout";
          id: string;
          comments?: string | null;
          workoutDate: string;
          workoutType: {
            __typename?: "WorkoutType";
            name: string;
            priority: number;
          };
        };
        measureType: {
          __typename?: "MeasureType";
          shortName: string;
          name: string;
          isFraction: boolean;
        };
      }>;
    };
    position: {
      __typename?: "Position";
      id: string;
      shortName: string;
      sort?: number | null;
    };
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        shortName: string;
        sort: number;
      };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      id: string;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      traitGroup: {
        __typename?: "TraitGroup";
        id: string;
        caption: string;
        sort: number;
        traitGroupPositions: Array<{
          __typename?: "TraitGroupPosition";
          id: string;
          positionId: string;
          sort: number;
          traitDefinition: {
            __typename?: "TraitDefinition";
            id: string;
            caption: string;
            description?: string | null;
            sort: number;
          };
        }>;
      };
    }>;
  }>;
};

export type GetTraitGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTraitGroupsQuery = {
  __typename?: "Query";
  traitGroups: Array<{
    __typename?: "TraitGroup";
    id: string;
    caption: string;
    sort: number;
    traitGroupPositions: Array<{
      __typename?: "TraitGroupPosition";
      id: string;
      positionId: string;
      sort: number;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        description?: string | null;
        sort: number;
      };
    }>;
  }>;
};

export type GetScoutGradesQueryVariables = Exact<{
  scale?: InputMaybe<Scalars["String"]>;
}>;

export type GetScoutGradesQuery = {
  __typename?: "Query";
  scoutGrades: Array<{
    __typename?: "ScoutGrade";
    id: string;
    grade: number;
    quality: string;
    description: string;
  }>;
};

export type GetFitsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFitsQuery = {
  __typename?: "Query";
  fits: Array<{
    __typename?: "Fit";
    id: string;
    position: string;
    name: string;
  }>;
};

export type GetAlertsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAlertsQuery = {
  __typename?: "Query";
  alerts: Array<{
    __typename?: "Alert";
    id: string;
    caption: string;
    abbreviation: string;
    sort?: number | null;
  }>;
};

export type EvaluatorFragment = {
  __typename?: "User";
  id: string;
  firstName: string;
  lastName: string;
  status: UserStatus;
};

export type EvalTypeFragment = {
  __typename?: "EvalType";
  id: string;
  type: EvalTypeEnum;
};

export type GetEvaluatorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEvaluatorsQuery = {
  __typename?: "Query";
  users: Array<{
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    status: UserStatus;
  }>;
};

export type GetEvalTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEvalTypesQuery = {
  __typename?: "Query";
  evalTypes: Array<{ __typename?: "EvalType"; id: string; type: EvalTypeEnum }>;
};

export type CreateInjuryCollegeMutationVariables = Exact<{
  createInjuryCollegeRequest: CreatePlayerInjuryColWithDetailsRequest;
}>;

export type CreateInjuryCollegeMutation = {
  __typename?: "Mutation";
  createInjuryCollege?: {
    __typename?: "PlayerInjuryCollege";
    id: string;
    playerId: string;
    schoolId?: string | null;
    dateTime: string;
    source?: string | null;
    injuryType?: string | null;
    injuryDetail?: string | null;
    injuryStatus?: string | null;
  } | null;
};

export type CreateInjuryProMutationVariables = Exact<{
  createInjuryProRequest: CreatePlayerInjuryProWithDetailsRequest;
}>;

export type CreateInjuryProMutation = {
  __typename?: "Mutation";
  createInjuryPro?: {
    __typename?: "PlayerInjuryPro";
    id: string;
    playerId: string;
    clubId?: string | null;
    dateTime?: string | null;
    source?: string | null;
    injury1?: string | null;
    injuryDesc1?: string | null;
    injuryStatus: string;
  } | null;
};

export type UpdateInjuryCollegeMutationVariables = Exact<{
  updateInjuryCollegeRequest: UpdatePlayerInjuryCollegeWithDetailsRequest;
}>;

export type UpdateInjuryCollegeMutation = {
  __typename?: "Mutation";
  updateInjuryCollege?: {
    __typename?: "PlayerInjuryCollege";
    id: string;
    playerId: string;
    schoolId?: string | null;
    dateTime: string;
    source?: string | null;
    injuryType?: string | null;
    injuryDetail?: string | null;
    injuryStatus?: string | null;
  } | null;
};

export type UpdateInjuryProMutationVariables = Exact<{
  updateInjuryProRequest: UpdatePlayerInjuryProWithDetailsRequest;
}>;

export type UpdateInjuryProMutation = {
  __typename?: "Mutation";
  updateInjuryPro?: {
    __typename?: "PlayerInjuryPro";
    id: string;
    playerId: string;
    clubId?: string | null;
    dateTime?: string | null;
    source?: string | null;
    injury1?: string | null;
    injuryDesc1?: string | null;
    injuryStatus: string;
  } | null;
};

export type GetInjuriesForProPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetInjuriesForProPlayerQuery = {
  __typename?: "Query";
  playerInjuryPro?: {
    __typename?: "PlayerInjuryProConnection";
    edges?: Array<{
      __typename?: "PlayerInjuryProEdge";
      node: {
        __typename?: "PlayerInjuryPro";
        id: string;
        playerId: string;
        source?: string | null;
        season?: number | null;
        injury1?: string | null;
        injuryDesc1?: string | null;
        injury2?: string | null;
        injuryDesc2?: string | null;
        leagueInjuryId?: number | null;
        reportType?: string | null;
        reportDay?: number | null;
        injuryStatus: string;
        dateTime?: string | null;
        clubId?: string | null;
        gameId?: string | null;
        club?: { __typename?: "Club"; clubCode: string } | null;
        game?: { __typename?: "Game"; kickoffTime?: string | null } | null;
      };
    }> | null;
  } | null;
};

export type GetInjuriesForCollegePlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetInjuriesForCollegePlayerQuery = {
  __typename?: "Query";
  playerInjuryCollege?: {
    __typename?: "PlayerInjuryCollegeConnection";
    edges?: Array<{
      __typename?: "PlayerInjuryCollegeEdge";
      node: {
        __typename?: "PlayerInjuryCollege";
        id: string;
        playerId: string;
        source?: string | null;
        dateTime: string;
        injuryStatus?: string | null;
        injuryType?: string | null;
        injuryDetail?: string | null;
        injurySide?: string | null;
        school?: { __typename?: "School"; schoolCode?: string | null } | null;
      };
    }> | null;
  } | null;
};

export type SaveScoutMeasuresMutationVariables = Exact<{
  request: UpsertSumerScoutMeasureRequest;
}>;

export type SaveScoutMeasuresMutation = {
  __typename?: "Mutation";
  saveSumerScoutMeasure: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    measureTypeId: string;
    value: number;
    isVerified: boolean;
  }>;
};

export type GetWorkoutsQueryVariables = Exact<{
  type: Scalars["String"];
}>;

export type GetWorkoutsQuery = {
  __typename?: "Query";
  workouts: Array<{
    __typename?: "Workout";
    id: string;
    season: number;
    comments?: string | null;
    workoutType: { __typename?: "WorkoutType"; id: string; name: string };
  }>;
};

export type GetWorkoutPlayersQueryVariables = Exact<{
  workoutId: Scalars["UUID"];
}>;

export type GetWorkoutPlayersQuery = {
  __typename?: "Query";
  workoutPlayers: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    value: number;
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      headshotUrl?: string | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
        schoolName: string;
      } | null;
    };
    measureType: { __typename?: "MeasureType"; name: string };
  }>;
};

export type GetWorkoutsByPlayerQueryVariables = Exact<{
  playerId: Scalars["UUID"];
}>;

export type GetWorkoutsByPlayerQuery = {
  __typename?: "Query";
  workoutsByPlayerId: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
};

export type GetWorkoutMeasuresBySchoolQueryVariables = Exact<{
  schoolId: Scalars["UUID"];
}>;

export type GetWorkoutMeasuresBySchoolQuery = {
  __typename?: "Query";
  workoutsBySchoolId: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
};

export type GetWorkoutMeasuresByPlayerQueryVariables = Exact<{
  playerId: Scalars["UUID"];
}>;

export type GetWorkoutMeasuresByPlayerQuery = {
  __typename?: "Query";
  workoutMeasuresByPlayerId: Array<{
    __typename?: "WorkoutMeasure";
    name: string;
    measure: number;
  }>;
};

export type GetWorkoutsBySchoolQueryVariables = Exact<{
  schoolId: Scalars["UUID"];
}>;

export type GetWorkoutsBySchoolQuery = {
  __typename?: "Query";
  workoutsBySchoolId: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
};

export type GetMeasureTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeasureTypesQuery = {
  __typename?: "Query";
  measureTypes: Array<{
    __typename?: "MeasureType";
    id: string;
    name: string;
    shortName: string;
    description: string;
    precision: number;
    isFraction: boolean;
    minValue: number;
    maxValue: number;
    priority: number;
  }>;
};

export type MeasureTypeFragment = {
  __typename?: "MeasureType";
  id: string;
  name: string;
  shortName: string;
  description: string;
  precision: number;
  isFraction: boolean;
  minValue: number;
  maxValue: number;
  priority: number;
};

export type WorkoutFragment = {
  __typename?: "Workout";
  id: string;
  season: number;
  comments?: string | null;
  workoutType: { __typename?: "WorkoutType"; id: string; name: string };
};

export type PlayerMeasureFragment = {
  __typename?: "WorkoutPlayerMeasure";
  id: string;
  playerId: string;
  value: number;
  isVerified: boolean;
  workout: {
    __typename?: "Workout";
    id: string;
    comments?: string | null;
    workoutDate: string;
    workoutType: { __typename?: "WorkoutType"; name: string; priority: number };
  };
  measureType: {
    __typename?: "MeasureType";
    shortName: string;
    name: string;
    isFraction: boolean;
  };
};

export type SearchAllQueryVariables = Exact<{
  searchTerm: Scalars["String"];
}>;

export type SearchAllQuery = {
  __typename?: "Query";
  searchAll: Array<{
    __typename?: "AnyEntitySearchResult";
    score?: number | null;
    player?: {
      __typename?: "SearchResultOfPlayerSearchDocument";
      searchScore?: number | null;
      record: {
        __typename?: "PlayerSearchDocument";
        id: string;
        clubCode?: string | null;
        clubId?: string | null;
        clubName?: string | null;
        schoolId?: string | null;
        schoolCode?: string | null;
        draftYear?: number | null;
        firstName?: string | null;
        lastName?: string | null;
        hometown?: string | null;
        height?: number | null;
        sumerAtlasPositionShortName?: string | null;
        sumerGeneralPositionShortName?: string | null;
        primarySchemePositionShortName?: string | null;
      };
    } | null;
    school?: {
      __typename?: "SearchResultOfSchoolSearchDocument";
      searchScore?: number | null;
      record: {
        __typename?: "SchoolSearchDocument";
        id: string;
        schoolCode?: string | null;
        schoolName: string;
      };
    } | null;
    club?: {
      __typename?: "SearchResultOfClubSearchDocument";
      searchScore?: number | null;
      record: {
        __typename?: "ClubSearchDocument";
        id: string;
        clubCode: string;
        clubName: string;
      };
    } | null;
  }>;
};

export type PositionFragment = {
  __typename?: "Position";
  id: string;
  shortName: string;
  sort?: number | null;
  sideOfBall?: SideOfBall | null;
};

export type GetPositionsQueryVariables = Exact<{
  positionTypeFilter?: InputMaybe<PositionType>;
}>;

export type GetPositionsQuery = {
  __typename?: "Query";
  positions: Array<{
    __typename?: "Position";
    id: string;
    shortName: string;
    sort?: number | null;
    sideOfBall?: SideOfBall | null;
  }>;
};

export type GetCurrentSeasonQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentSeasonQuery = {
  __typename?: "Query";
  currentSeason?: {
    __typename?: "Calendar";
    calendarDate: string;
    season: number;
    description?: string | null;
    isRegSeason: boolean;
    isPostSeason: boolean;
    isDraft: boolean;
  } | null;
};

export type GetPlayerPositionDepthsQueryVariables = Exact<{
  packageId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetPlayerPositionDepthsQuery = {
  __typename?: "Query";
  playerPositionDepths: Array<{
    __typename?: "PlayerPackagePosition";
    depth: number;
    id: string;
    packageId?: string | null;
    playerId: string;
    positionId: string;
    package: {
      __typename?: "Package";
      id: string;
      abbreviation: string;
      description: string;
      name: string;
      pffPackageId: number;
      sideOfBall: SideOfBall;
    };
    player: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
      jersey?: string | null;
      sumerAtlasPositionId?: string | null;
      sumerGeneralPositionId?: string | null;
      status: PlayerStatus;
    };
    position: {
      __typename?: "Position";
      id: string;
      sideOfBall?: SideOfBall | null;
      positionType: PositionType;
      shortName: string;
    };
  }>;
};

export type GetPackagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPackagesQuery = {
  __typename?: "Query";
  packages: Array<{
    __typename?: "Package";
    id: string;
    abbreviation: string;
    description: string;
    name: string;
    pffPackageId: number;
    sideOfBall: SideOfBall;
  }>;
};

export type GetPackagePositionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPackagePositionsQuery = {
  __typename?: "Query";
  packagePositions: Array<{
    __typename?: "PackagePosition";
    id: string;
    packageId: string;
    positionId: string;
    x?: number | null;
    y?: number | null;
    package: {
      __typename?: "Package";
      abbreviation: string;
      description: string;
      id: string;
      name: string;
      pffPackageId: number;
      sideOfBall: SideOfBall;
    };
    position: {
      __typename?: "Position";
      id: string;
      sideOfBall?: SideOfBall | null;
      positionType: PositionType;
      shortName: string;
    };
  }>;
};

export type GetPackageClubsQueryVariables = Exact<{
  clubId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetPackageClubsQuery = {
  __typename?: "Query";
  packageClubs: Array<{
    __typename?: "PackageClub";
    id: string;
    clubId: string;
    isBase: boolean;
    packageId: string;
    club: {
      __typename?: "Club";
      id: string;
      clubName: string;
      clubCode: string;
    };
    package: {
      __typename?: "Package";
      id: string;
      abbreviation: string;
      description: string;
      name: string;
      pffPackageId: number;
      sideOfBall: SideOfBall;
    };
  }>;
};

export type PackageFieldsFragment = {
  __typename?: "Package";
  id: string;
  abbreviation: string;
  description: string;
  name: string;
  pffPackageId: number;
  sideOfBall: SideOfBall;
};

export type PlayerPositionDepthFragment = {
  __typename?: "PlayerPackagePosition";
  depth: number;
  id: string;
  playerId: string;
  player: {
    __typename?: "Player";
    id: string;
    lastName: string;
    jersey?: string | null;
    status: PlayerStatus;
  };
  position: { __typename?: "Position"; id: string; shortName: string };
};

export type GetPermissionByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetPermissionByIdQuery = {
  __typename?: "Query";
  permissions: Array<{
    __typename?: "Permission";
    id: string;
    name: string;
    description?: string | null;
    roles: Array<{ __typename?: "AppRole"; id: string; name: string }>;
  }>;
};

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPermissionsQuery = {
  __typename?: "Query";
  permissions: Array<{
    __typename?: "Permission";
    id: string;
    name: string;
    description?: string | null;
    roles: Array<{ __typename?: "AppRole"; id: string; name: string }>;
  }>;
};

export type UpdatePlayerMutationVariables = Exact<{
  updatePlayerRequest: UpdatePlayerRequest;
}>;

export type UpdatePlayerMutation = {
  __typename?: "Mutation";
  updatePlayer?: { __typename?: "Player"; id: string } | null;
};

export type UpdateBasicPlayerDetailsMutationVariables = Exact<{
  updateBasicPlayerDetailsRequest: UpdateBasicPlayerDetailsRequest;
}>;

export type UpdateBasicPlayerDetailsMutation = {
  __typename?: "Mutation";
  updateBasicPlayerDetails?: { __typename?: "Player"; id: string } | null;
};

export type AssignPlayerOverrideMutationVariables = Exact<{
  assignPlayerOverride: AssignPlayerOverrideRequest;
}>;

export type AssignPlayerOverrideMutation = {
  __typename?: "Mutation";
  assignPlayerOverride?: {
    __typename?: "Player";
    id: string;
    latestBirthDate?: string | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    latestEligibilityYear?: number | null;
    latestEligibility?: EligibilityType | null;
    latestSchoolId?: string | null;
    latestClubId?: string | null;
    latestHighSchoolGradYear?: number | null;
    highSchoolGradYear?: number | null;
    clubId?: string | null;
    schoolId?: string | null;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
  } | null;
};

export type SaveBigBoardPlayersMutationVariables = Exact<{
  request: SaveBigBoardPlayerRequest;
}>;

export type SaveBigBoardPlayersMutation = {
  __typename?: "Mutation";
  saveBigBoardPlayers: Array<{
    __typename?: "BigBoardPlayer";
    id: string;
    playerId: string;
    rank: number;
  }>;
};

export type SavePlayerTagsMutationVariables = Exact<{
  request: SavePlayerTagsRequest;
}>;

export type SavePlayerTagsMutation = {
  __typename?: "Mutation";
  savePlayerTags: Array<{ __typename?: "Player"; id: string }>;
};

export type PlayerListFragment = {
  __typename?: "Player";
  id: string;
  footballName?: string | null;
  firstName: string;
  lastName: string;
  jersey?: string | null;
  eligibilityYear?: number | null;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  school?: { __typename?: "School"; id: string } | null;
  club?: { __typename?: "Club"; id: string } | null;
};

export type PlayerFragment = {
  __typename?: "Player";
  id: string;
  gsisId?: string | null;
  collegeGsisId?: string | null;
  footballName?: string | null;
  firstName: string;
  lastName: string;
  jersey?: string | null;
  draftYear?: number | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  status: PlayerStatus;
  birthDate?: string | null;
  draftRound?: number | null;
  draftSelection?: number | null;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  eligibilityYear?: number | null;
  eligibility?: EligibilityType | null;
  redshirt: boolean;
  headshotUrl?: string | null;
  nflExperience: number;
  minSalaryCredits?: number | null;
  accruedSeasons?: number | null;
  schoolId?: string | null;
  clubId?: string | null;
  latestBirthDate?: string | null;
  highschool?: string | null;
  namePronunciation?: string | null;
  freeAgencyStatus?: FreeAgencyStatus | null;
  latestClubId?: string | null;
  latestSchoolId?: string | null;
  latestEligibility?: EligibilityType | null;
  latestEligibilityYear?: number | null;
  totalPensionCredits: number;
  draftClub?: { __typename?: "Club"; clubCode: string } | null;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  playerPffPackagePositions: Array<{
    __typename?: "PlayerPackagePosition";
    depth: number;
    package: { __typename?: "Package"; name: string };
    position: { __typename?: "Position"; shortName: string };
  }>;
  school?: {
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
    schoolName: string;
    primaryColor?: string | null;
    secondaryColor?: string | null;
  } | null;
  club?: {
    __typename?: "Club";
    id: string;
    clubCode: string;
    clubName: string;
  } | null;
  mostRecentClub?: { __typename?: "Club"; id: string; clubCode: string } | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    userId: string;
    summary?: string | null;
    lastUpdated: string;
    season: number;
    isFinal: boolean;
    finalizedOn?: string | null;
    isPrimary: boolean;
    evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    alerts: Array<{
      __typename?: "EvaluationAlert";
      evaluationId: string;
      id: string;
      alertId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        description: string;
        sort?: number | null;
      };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    clubFits: Array<{
      __typename?: "EvaluationClubFit";
      club: { __typename?: "Club"; id: string; clubCode: string };
    }>;
    traitGrades: Array<{
      __typename?: "EvaluationTrait";
      id: string;
      comments?: string | null;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      traitDefinition: {
        __typename?: "TraitDefinition";
        id: string;
        caption: string;
        sort: number;
      };
    }>;
    traitGroupGrades: Array<{
      __typename?: "EvaluationTraitGroup";
      comments?: string | null;
      traitGroup: { __typename?: "TraitGroup"; caption: string };
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      gameId: string;
      lastUpdated: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    }>;
    position: { __typename?: "Position"; id: string; shortName: string };
  }>;
  favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
  grades: Array<{
    __typename?: "Grade";
    playerId: string;
    gradeType: GradeTypeEnum;
    value: number;
    dollars?: number | null;
    season: number;
    id: string;
  }>;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    value: number;
    isVerified: boolean;
    measureType: {
      __typename?: "MeasureType";
      name: string;
      shortName: string;
      isFraction: boolean;
    };
    workout: {
      __typename?: "Workout";
      id: string;
      workoutDate: string;
      comments?: string | null;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
  }>;
  playerOverrides: Array<{
    __typename?: "PlayerOverride";
    field: string;
    value?: string | null;
  }>;
};

export type CollegeRosterPlayerFragment = {
  __typename?: "Player";
  id: string;
  footballName?: string | null;
  firstName: string;
  lastName: string;
  jersey?: string | null;
  draftYear?: number | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  birthDate?: string | null;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  schoolId?: string | null;
  eligibilityYear?: number | null;
  eligibility?: EligibilityType | null;
  redshirt: boolean;
  headshotUrl?: string | null;
  latestSchoolId?: string | null;
  latestEligibility?: EligibilityType | null;
  latestEligibilityYear?: number | null;
  latestBirthDate?: string | null;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    userId: string;
    season: number;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    isPrimary: boolean;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    position: { __typename?: "Position"; id: string; shortName: string };
    evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      gameId: string;
      lastUpdated: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    }>;
  }>;
  playerOverrides: Array<{
    __typename?: "PlayerOverride";
    field: string;
    value?: string | null;
  }>;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
};

export type ClubRosterPlayerFragment = {
  __typename?: "Player";
  id: string;
  footballName?: string | null;
  firstName: string;
  lastName: string;
  jersey?: string | null;
  draftYear?: number | null;
  draftRound?: number | null;
  draftSelection?: number | null;
  eligibilityYear?: number | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  status: PlayerStatus;
  birthDate?: string | null;
  latestBirthDate?: string | null;
  latestClubId?: string | null;
  headshotUrl?: string | null;
  freeAgencyStatus?: FreeAgencyStatus | null;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  school?: { __typename?: "School"; id: string; schoolName: string } | null;
  club?: {
    __typename?: "Club";
    id: string;
    clubCode: string;
    clubName: string;
  } | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    userId: string;
    season: number;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    isPrimary: boolean;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    position: { __typename?: "Position"; id: string; shortName: string };
    evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      gameId: string;
      lastUpdated: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    }>;
  }>;
  workouts: Array<{
    __typename?: "WorkoutPlayerMeasure";
    id: string;
    playerId: string;
    value: number;
    isVerified: boolean;
    workout: {
      __typename?: "Workout";
      id: string;
      comments?: string | null;
      workoutDate: string;
      workoutType: {
        __typename?: "WorkoutType";
        name: string;
        priority: number;
      };
    };
    measureType: {
      __typename?: "MeasureType";
      shortName: string;
      name: string;
      isFraction: boolean;
    };
  }>;
};

export type PlayerEvalSlimFragment = {
  __typename?: "Evaluation";
  id: string;
  userId: string;
  season: number;
  lastUpdated: string;
  isFinal: boolean;
  finalizedOn?: string | null;
  isPrimary: boolean;
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  position: { __typename?: "Position"; id: string; shortName: string };
  evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  alerts: Array<{
    __typename?: "EvaluationAlert";
    id: string;
    evaluationId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      sort?: number | null;
    };
  }>;
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    gameId: string;
    lastUpdated: string;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  }>;
};

export type PlayerSlimFragment = {
  __typename?: "Player";
  id: string;
  gsisId?: string | null;
  firstName: string;
  lastName: string;
  jersey?: string | null;
  draftYear?: number | null;
  hometownCity?: string | null;
  hometownState?: string | null;
  hometownCountry?: string | null;
  height?: number | null;
  weight?: number | null;
  speed?: number | null;
  highschool?: string | null;
  schoolId?: string | null;
  eligibilityYear?: number | null;
  eligibility?: EligibilityType | null;
  redshirt: boolean;
  draftRound?: number | null;
  draftSelection?: number | null;
  headshotUrl?: string | null;
  status: PlayerStatus;
  latestBirthDate?: string | null;
  latestSchoolId?: string | null;
  latestClubId?: string | null;
  latestEligibility?: EligibilityType | null;
  latestEligibilityYear?: number | null;
  namePronunciation?: string | null;
  nflExperience: number;
  minSalaryCredits?: number | null;
  accruedSeasons?: number | null;
  draftClub?: { __typename?: "Club"; clubCode: string } | null;
  evaluations: Array<{
    __typename?: "Evaluation";
    id: string;
    userId: string;
    season: number;
    lastUpdated: string;
    isFinal: boolean;
    finalizedOn?: string | null;
    isPrimary: boolean;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
    };
    position: { __typename?: "Position"; id: string; shortName: string };
    evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    alerts: Array<{
      __typename?: "EvaluationAlert";
      id: string;
      evaluationId: string;
      alert: {
        __typename?: "Alert";
        id: string;
        caption: string;
        abbreviation: string;
        sort?: number | null;
      };
    }>;
    fits: Array<{
      __typename?: "EvaluationFit";
      id: string;
      evaluationId: string;
      priority: number;
      fit: { __typename?: "Fit"; id: string; position: string; name: string };
    }>;
    gameGrades: Array<{
      __typename?: "EvaluationGame";
      gameId: string;
      lastUpdated: string;
      scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
    }>;
  }>;
  sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
  sumerGeneralPosition?: { __typename?: "Position"; shortName: string } | null;
  playerPffPackagePositions: Array<{
    __typename?: "PlayerPackagePosition";
    depth: number;
    package: { __typename?: "Package"; name: string };
    position: { __typename?: "Position"; shortName: string };
  }>;
  club?: {
    __typename?: "Club";
    id: string;
    clubCode: string;
    clubName: string;
  } | null;
};

export type PlayerEvalFragment = {
  __typename?: "Evaluation";
  id: string;
  userId: string;
  summary?: string | null;
  lastUpdated: string;
  season: number;
  isFinal: boolean;
  finalizedOn?: string | null;
  isPrimary: boolean;
  evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
  user: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
  };
  alerts: Array<{
    __typename?: "EvaluationAlert";
    evaluationId: string;
    id: string;
    alertId: string;
    alert: {
      __typename?: "Alert";
      id: string;
      caption: string;
      abbreviation: string;
      description: string;
      sort?: number | null;
    };
  }>;
  fits: Array<{
    __typename?: "EvaluationFit";
    id: string;
    evaluationId: string;
    priority: number;
    fit: { __typename?: "Fit"; id: string; position: string; name: string };
  }>;
  scoutGrade?: { __typename?: "ScoutGrade"; id: string; grade: number } | null;
  clubFits: Array<{
    __typename?: "EvaluationClubFit";
    club: { __typename?: "Club"; id: string; clubCode: string };
  }>;
  traitGrades: Array<{
    __typename?: "EvaluationTrait";
    id: string;
    comments?: string | null;
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
    traitDefinition: {
      __typename?: "TraitDefinition";
      id: string;
      caption: string;
      sort: number;
    };
  }>;
  traitGroupGrades: Array<{
    __typename?: "EvaluationTraitGroup";
    comments?: string | null;
    traitGroup: { __typename?: "TraitGroup"; caption: string };
    scoutGrade?: {
      __typename?: "ScoutGrade";
      id: string;
      grade: number;
    } | null;
  }>;
  gameGrades: Array<{
    __typename?: "EvaluationGame";
    gameId: string;
    lastUpdated: string;
    scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
  }>;
  position: { __typename?: "Position"; id: string; shortName: string };
};

export type PlayerPositionFragment = {
  __typename?: "PlayerPackagePosition";
  depth: number;
  package: { __typename?: "Package"; name: string };
  position: { __typename?: "Position"; shortName: string };
};

export type GetPlayerQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetPlayerQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
  playerById?: {
    __typename?: "Player";
    id: string;
    gsisId?: string | null;
    collegeGsisId?: string | null;
    footballName?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    status: PlayerStatus;
    birthDate?: string | null;
    draftRound?: number | null;
    draftSelection?: number | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    redshirt: boolean;
    headshotUrl?: string | null;
    nflExperience: number;
    minSalaryCredits?: number | null;
    accruedSeasons?: number | null;
    schoolId?: string | null;
    clubId?: string | null;
    latestBirthDate?: string | null;
    highschool?: string | null;
    namePronunciation?: string | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    latestClubId?: string | null;
    latestSchoolId?: string | null;
    latestEligibility?: EligibilityType | null;
    latestEligibilityYear?: number | null;
    totalPensionCredits: number;
    draftClub?: { __typename?: "Club"; clubCode: string } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    playerPffPackagePositions: Array<{
      __typename?: "PlayerPackagePosition";
      depth: number;
      package: { __typename?: "Package"; name: string };
      position: { __typename?: "Position"; shortName: string };
    }>;
    school?: {
      __typename?: "School";
      id: string;
      schoolCode?: string | null;
      schoolName: string;
      primaryColor?: string | null;
      secondaryColor?: string | null;
    } | null;
    club?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    } | null;
    mostRecentClub?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      userId: string;
      summary?: string | null;
      lastUpdated: string;
      season: number;
      isFinal: boolean;
      finalizedOn?: string | null;
      isPrimary: boolean;
      evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      alerts: Array<{
        __typename?: "EvaluationAlert";
        evaluationId: string;
        id: string;
        alertId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          description: string;
          sort?: number | null;
        };
      }>;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      clubFits: Array<{
        __typename?: "EvaluationClubFit";
        club: { __typename?: "Club"; id: string; clubCode: string };
      }>;
      traitGrades: Array<{
        __typename?: "EvaluationTrait";
        id: string;
        comments?: string | null;
        scoutGrade?: {
          __typename?: "ScoutGrade";
          id: string;
          grade: number;
        } | null;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      traitGroupGrades: Array<{
        __typename?: "EvaluationTraitGroup";
        comments?: string | null;
        traitGroup: { __typename?: "TraitGroup"; caption: string };
        scoutGrade?: {
          __typename?: "ScoutGrade";
          id: string;
          grade: number;
        } | null;
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      }>;
      position: { __typename?: "Position"; id: string; shortName: string };
    }>;
    favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
    grades: Array<{
      __typename?: "Grade";
      playerId: string;
      gradeType: GradeTypeEnum;
      value: number;
      dollars?: number | null;
      season: number;
      id: string;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      value: number;
      isVerified: boolean;
      measureType: {
        __typename?: "MeasureType";
        name: string;
        shortName: string;
        isFraction: boolean;
      };
      workout: {
        __typename?: "Workout";
        id: string;
        workoutDate: string;
        comments?: string | null;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
    }>;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
  } | null;
};

export type GetPlayerSlimQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetPlayerSlimQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
  playerById?: {
    __typename?: "Player";
    id: string;
    gsisId?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    highschool?: string | null;
    schoolId?: string | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    redshirt: boolean;
    draftRound?: number | null;
    draftSelection?: number | null;
    headshotUrl?: string | null;
    status: PlayerStatus;
    latestBirthDate?: string | null;
    latestSchoolId?: string | null;
    latestClubId?: string | null;
    latestEligibility?: EligibilityType | null;
    latestEligibilityYear?: number | null;
    namePronunciation?: string | null;
    nflExperience: number;
    minSalaryCredits?: number | null;
    accruedSeasons?: number | null;
    draftClub?: { __typename?: "Club"; clubCode: string } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      userId: string;
      season: number;
      lastUpdated: string;
      isFinal: boolean;
      finalizedOn?: string | null;
      isPrimary: boolean;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      position: { __typename?: "Position"; id: string; shortName: string };
      evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        id: string;
        evaluationId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          sort?: number | null;
        };
      }>;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      }>;
    }>;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    playerPffPackagePositions: Array<{
      __typename?: "PlayerPackagePosition";
      depth: number;
      package: { __typename?: "Package"; name: string };
      position: { __typename?: "Position"; shortName: string };
    }>;
    club?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    } | null;
  } | null;
};

export type GetPlayersQueryVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  clubName?: InputMaybe<Scalars["String"]>;
  schoolCode?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  positionGroup?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  draftYear?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
}>;

export type GetPlayersQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
  players: Array<{
    __typename?: "Player";
    id: string;
    gsisId?: string | null;
    collegeGsisId?: string | null;
    footballName?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    status: PlayerStatus;
    birthDate?: string | null;
    draftRound?: number | null;
    draftSelection?: number | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    redshirt: boolean;
    headshotUrl?: string | null;
    nflExperience: number;
    minSalaryCredits?: number | null;
    accruedSeasons?: number | null;
    schoolId?: string | null;
    clubId?: string | null;
    latestBirthDate?: string | null;
    highschool?: string | null;
    namePronunciation?: string | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    latestClubId?: string | null;
    latestSchoolId?: string | null;
    latestEligibility?: EligibilityType | null;
    latestEligibilityYear?: number | null;
    totalPensionCredits: number;
    draftClub?: { __typename?: "Club"; clubCode: string } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    playerPffPackagePositions: Array<{
      __typename?: "PlayerPackagePosition";
      depth: number;
      package: { __typename?: "Package"; name: string };
      position: { __typename?: "Position"; shortName: string };
    }>;
    school?: {
      __typename?: "School";
      id: string;
      schoolCode?: string | null;
      schoolName: string;
      primaryColor?: string | null;
      secondaryColor?: string | null;
    } | null;
    club?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    } | null;
    mostRecentClub?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      userId: string;
      summary?: string | null;
      lastUpdated: string;
      season: number;
      isFinal: boolean;
      finalizedOn?: string | null;
      isPrimary: boolean;
      evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      alerts: Array<{
        __typename?: "EvaluationAlert";
        evaluationId: string;
        id: string;
        alertId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          description: string;
          sort?: number | null;
        };
      }>;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      clubFits: Array<{
        __typename?: "EvaluationClubFit";
        club: { __typename?: "Club"; id: string; clubCode: string };
      }>;
      traitGrades: Array<{
        __typename?: "EvaluationTrait";
        id: string;
        comments?: string | null;
        scoutGrade?: {
          __typename?: "ScoutGrade";
          id: string;
          grade: number;
        } | null;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      traitGroupGrades: Array<{
        __typename?: "EvaluationTraitGroup";
        comments?: string | null;
        traitGroup: { __typename?: "TraitGroup"; caption: string };
        scoutGrade?: {
          __typename?: "ScoutGrade";
          id: string;
          grade: number;
        } | null;
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      }>;
      position: { __typename?: "Position"; id: string; shortName: string };
    }>;
    favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
    grades: Array<{
      __typename?: "Grade";
      playerId: string;
      gradeType: GradeTypeEnum;
      value: number;
      dollars?: number | null;
      season: number;
      id: string;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      value: number;
      isVerified: boolean;
      measureType: {
        __typename?: "MeasureType";
        name: string;
        shortName: string;
        isFraction: boolean;
      };
      workout: {
        __typename?: "Workout";
        id: string;
        workoutDate: string;
        comments?: string | null;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
    }>;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
  }>;
};

export type GetPlayersPagedQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  clubName?: InputMaybe<Scalars["String"]>;
  schoolCode?: InputMaybe<Scalars["String"]>;
  position?: InputMaybe<Scalars["String"]>;
  positionGroup?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  draftYear?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
}>;

export type GetPlayersPagedQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
  playersPaged?: {
    __typename?: "PlayersPagedConnection";
    totalCount: number;
    edges?: Array<{
      __typename?: "PlayersPagedEdge";
      node: {
        __typename?: "Player";
        id: string;
        gsisId?: string | null;
        collegeGsisId?: string | null;
        footballName?: string | null;
        firstName: string;
        lastName: string;
        jersey?: string | null;
        draftYear?: number | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        status: PlayerStatus;
        birthDate?: string | null;
        draftRound?: number | null;
        draftSelection?: number | null;
        hometownCity?: string | null;
        hometownState?: string | null;
        hometownCountry?: string | null;
        eligibilityYear?: number | null;
        eligibility?: EligibilityType | null;
        redshirt: boolean;
        headshotUrl?: string | null;
        nflExperience: number;
        minSalaryCredits?: number | null;
        accruedSeasons?: number | null;
        schoolId?: string | null;
        clubId?: string | null;
        latestBirthDate?: string | null;
        highschool?: string | null;
        namePronunciation?: string | null;
        freeAgencyStatus?: FreeAgencyStatus | null;
        latestClubId?: string | null;
        latestSchoolId?: string | null;
        latestEligibility?: EligibilityType | null;
        latestEligibilityYear?: number | null;
        totalPensionCredits: number;
        draftClub?: { __typename?: "Club"; clubCode: string } | null;
        sumerAtlasPosition?: {
          __typename?: "Position";
          shortName: string;
        } | null;
        sumerGeneralPosition?: {
          __typename?: "Position";
          shortName: string;
        } | null;
        playerPffPackagePositions: Array<{
          __typename?: "PlayerPackagePosition";
          depth: number;
          package: { __typename?: "Package"; name: string };
          position: { __typename?: "Position"; shortName: string };
        }>;
        school?: {
          __typename?: "School";
          id: string;
          schoolCode?: string | null;
          schoolName: string;
          primaryColor?: string | null;
          secondaryColor?: string | null;
        } | null;
        club?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
          clubName: string;
        } | null;
        mostRecentClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        evaluations: Array<{
          __typename?: "Evaluation";
          id: string;
          userId: string;
          summary?: string | null;
          lastUpdated: string;
          season: number;
          isFinal: boolean;
          finalizedOn?: string | null;
          isPrimary: boolean;
          evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
          user: {
            __typename?: "User";
            id: string;
            firstName: string;
            lastName: string;
          };
          alerts: Array<{
            __typename?: "EvaluationAlert";
            evaluationId: string;
            id: string;
            alertId: string;
            alert: {
              __typename?: "Alert";
              id: string;
              caption: string;
              abbreviation: string;
              description: string;
              sort?: number | null;
            };
          }>;
          fits: Array<{
            __typename?: "EvaluationFit";
            id: string;
            evaluationId: string;
            priority: number;
            fit: {
              __typename?: "Fit";
              id: string;
              position: string;
              name: string;
            };
          }>;
          scoutGrade?: {
            __typename?: "ScoutGrade";
            id: string;
            grade: number;
          } | null;
          clubFits: Array<{
            __typename?: "EvaluationClubFit";
            club: { __typename?: "Club"; id: string; clubCode: string };
          }>;
          traitGrades: Array<{
            __typename?: "EvaluationTrait";
            id: string;
            comments?: string | null;
            scoutGrade?: {
              __typename?: "ScoutGrade";
              id: string;
              grade: number;
            } | null;
            traitDefinition: {
              __typename?: "TraitDefinition";
              id: string;
              caption: string;
              sort: number;
            };
          }>;
          traitGroupGrades: Array<{
            __typename?: "EvaluationTraitGroup";
            comments?: string | null;
            traitGroup: { __typename?: "TraitGroup"; caption: string };
            scoutGrade?: {
              __typename?: "ScoutGrade";
              id: string;
              grade: number;
            } | null;
          }>;
          gameGrades: Array<{
            __typename?: "EvaluationGame";
            gameId: string;
            lastUpdated: string;
            scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
          }>;
          position: { __typename?: "Position"; id: string; shortName: string };
        }>;
        favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
        grades: Array<{
          __typename?: "Grade";
          playerId: string;
          gradeType: GradeTypeEnum;
          value: number;
          dollars?: number | null;
          season: number;
          id: string;
        }>;
        workouts: Array<{
          __typename?: "WorkoutPlayerMeasure";
          id: string;
          value: number;
          isVerified: boolean;
          measureType: {
            __typename?: "MeasureType";
            name: string;
            shortName: string;
            isFraction: boolean;
          };
          workout: {
            __typename?: "Workout";
            id: string;
            workoutDate: string;
            comments?: string | null;
            workoutType: {
              __typename?: "WorkoutType";
              name: string;
              priority: number;
            };
          };
        }>;
        playerOverrides: Array<{
          __typename?: "PlayerOverride";
          field: string;
          value?: string | null;
        }>;
      };
    }> | null;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type GetOnRosterPlayersQueryVariables = Exact<{
  clubName?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
}>;

export type GetOnRosterPlayersQuery = {
  __typename?: "Query";
  players: Array<{
    __typename?: "Player";
    id: string;
    gsisId?: string | null;
    collegeGsisId?: string | null;
    footballName?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    status: PlayerStatus;
    birthDate?: string | null;
    draftRound?: number | null;
    draftSelection?: number | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    redshirt: boolean;
    headshotUrl?: string | null;
    nflExperience: number;
    minSalaryCredits?: number | null;
    accruedSeasons?: number | null;
    schoolId?: string | null;
    clubId?: string | null;
    latestBirthDate?: string | null;
    highschool?: string | null;
    namePronunciation?: string | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    latestClubId?: string | null;
    latestSchoolId?: string | null;
    latestEligibility?: EligibilityType | null;
    latestEligibilityYear?: number | null;
    totalPensionCredits: number;
    draftClub?: { __typename?: "Club"; clubCode: string } | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    playerPffPackagePositions: Array<{
      __typename?: "PlayerPackagePosition";
      depth: number;
      package: { __typename?: "Package"; name: string };
      position: { __typename?: "Position"; shortName: string };
    }>;
    school?: {
      __typename?: "School";
      id: string;
      schoolCode?: string | null;
      schoolName: string;
      primaryColor?: string | null;
      secondaryColor?: string | null;
    } | null;
    club?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    } | null;
    mostRecentClub?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      userId: string;
      summary?: string | null;
      lastUpdated: string;
      season: number;
      isFinal: boolean;
      finalizedOn?: string | null;
      isPrimary: boolean;
      evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      alerts: Array<{
        __typename?: "EvaluationAlert";
        evaluationId: string;
        id: string;
        alertId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          description: string;
          sort?: number | null;
        };
      }>;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      clubFits: Array<{
        __typename?: "EvaluationClubFit";
        club: { __typename?: "Club"; id: string; clubCode: string };
      }>;
      traitGrades: Array<{
        __typename?: "EvaluationTrait";
        id: string;
        comments?: string | null;
        scoutGrade?: {
          __typename?: "ScoutGrade";
          id: string;
          grade: number;
        } | null;
        traitDefinition: {
          __typename?: "TraitDefinition";
          id: string;
          caption: string;
          sort: number;
        };
      }>;
      traitGroupGrades: Array<{
        __typename?: "EvaluationTraitGroup";
        comments?: string | null;
        traitGroup: { __typename?: "TraitGroup"; caption: string };
        scoutGrade?: {
          __typename?: "ScoutGrade";
          id: string;
          grade: number;
        } | null;
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      }>;
      position: { __typename?: "Position"; id: string; shortName: string };
    }>;
    favorite?: { __typename?: "PlayerFavorite"; playerId: string } | null;
    grades: Array<{
      __typename?: "Grade";
      playerId: string;
      gradeType: GradeTypeEnum;
      value: number;
      dollars?: number | null;
      season: number;
      id: string;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      value: number;
      isVerified: boolean;
      measureType: {
        __typename?: "MeasureType";
        name: string;
        shortName: string;
        isFraction: boolean;
      };
      workout: {
        __typename?: "Workout";
        id: string;
        workoutDate: string;
        comments?: string | null;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
    }>;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
  }>;
};

export type GetPlayerListQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars["UUID"]>;
  group?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
}>;

export type GetPlayerListQuery = {
  __typename?: "Query";
  players: Array<{
    __typename?: "Player";
    id: string;
    footballName?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    eligibilityYear?: number | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    school?: { __typename?: "School"; id: string } | null;
    club?: { __typename?: "Club"; id: string } | null;
  }>;
};

export type GetClubRosterQueryVariables = Exact<{
  clubName?: InputMaybe<Scalars["String"]>;
  group?: InputMaybe<Scalars["String"]>;
  sortCriteria: PlayerSearchSortCriteriaEnum;
}>;

export type GetClubRosterQuery = {
  __typename?: "Query";
  players: Array<{
    __typename?: "Player";
    id: string;
    footballName?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    draftRound?: number | null;
    draftSelection?: number | null;
    eligibilityYear?: number | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    status: PlayerStatus;
    birthDate?: string | null;
    latestBirthDate?: string | null;
    latestClubId?: string | null;
    headshotUrl?: string | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    school?: { __typename?: "School"; id: string; schoolName: string } | null;
    club?: {
      __typename?: "Club";
      id: string;
      clubCode: string;
      clubName: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      userId: string;
      season: number;
      lastUpdated: string;
      isFinal: boolean;
      finalizedOn?: string | null;
      isPrimary: boolean;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      position: { __typename?: "Position"; id: string; shortName: string };
      evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        id: string;
        evaluationId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          sort?: number | null;
        };
      }>;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      }>;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
  }>;
};

export type GetCollegeRosterQueryVariables = Exact<{
  schoolId: Scalars["UUID"];
  overrideSchoolId?: InputMaybe<Scalars["String"]>;
  eligibilityYear?: InputMaybe<Scalars["Int"]>;
}>;

export type GetCollegeRosterQuery = {
  __typename?: "Query";
  rosterPlayers: Array<{
    __typename?: "Player";
    id: string;
    footballName?: string | null;
    firstName: string;
    lastName: string;
    jersey?: string | null;
    draftYear?: number | null;
    height?: number | null;
    weight?: number | null;
    speed?: number | null;
    birthDate?: string | null;
    hometownCity?: string | null;
    hometownState?: string | null;
    hometownCountry?: string | null;
    schoolId?: string | null;
    eligibilityYear?: number | null;
    eligibility?: EligibilityType | null;
    redshirt: boolean;
    headshotUrl?: string | null;
    latestSchoolId?: string | null;
    latestEligibility?: EligibilityType | null;
    latestEligibilityYear?: number | null;
    latestBirthDate?: string | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
    evaluations: Array<{
      __typename?: "Evaluation";
      id: string;
      userId: string;
      season: number;
      lastUpdated: string;
      isFinal: boolean;
      finalizedOn?: string | null;
      isPrimary: boolean;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
      };
      position: { __typename?: "Position"; id: string; shortName: string };
      evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
      scoutGrade?: {
        __typename?: "ScoutGrade";
        id: string;
        grade: number;
      } | null;
      alerts: Array<{
        __typename?: "EvaluationAlert";
        id: string;
        evaluationId: string;
        alert: {
          __typename?: "Alert";
          id: string;
          caption: string;
          abbreviation: string;
          sort?: number | null;
        };
      }>;
      fits: Array<{
        __typename?: "EvaluationFit";
        id: string;
        evaluationId: string;
        priority: number;
        fit: { __typename?: "Fit"; id: string; position: string; name: string };
      }>;
      gameGrades: Array<{
        __typename?: "EvaluationGame";
        gameId: string;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
      }>;
    }>;
    playerOverrides: Array<{
      __typename?: "PlayerOverride";
      field: string;
      value?: string | null;
    }>;
    workouts: Array<{
      __typename?: "WorkoutPlayerMeasure";
      id: string;
      playerId: string;
      value: number;
      isVerified: boolean;
      workout: {
        __typename?: "Workout";
        id: string;
        comments?: string | null;
        workoutDate: string;
        workoutType: {
          __typename?: "WorkoutType";
          name: string;
          priority: number;
        };
      };
      measureType: {
        __typename?: "MeasureType";
        shortName: string;
        name: string;
        isFraction: boolean;
      };
    }>;
  }>;
};

export type SearchPlayersQueryVariables = Exact<{
  searchTerm: Scalars["String"];
  orderByFields?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  filters?: InputMaybe<PlayerFiltersInput>;
  options?: InputMaybe<SearchOptionsInput>;
}>;

export type SearchPlayersQuery = {
  __typename?: "Query";
  searchPlayers: {
    __typename?: "PlayerSearchResults";
    total: number;
    players: Array<{
      __typename?: "SearchResultOfPlayerSearchDocument";
      record: {
        __typename?: "PlayerSearchDocument";
        id: string;
        clubCode?: string | null;
        clubId?: string | null;
        clubName?: string | null;
        schoolId?: string | null;
        schoolCode?: string | null;
        schoolName?: string | null;
        draftYear?: number | null;
        firstName?: string | null;
        lastName?: string | null;
        hometown?: string | null;
        height?: number | null;
        weight?: number | null;
        speed?: number | null;
        jersey?: string | null;
        sumerAtlasPositionShortName?: string | null;
        sumerGeneralPositionShortName?: string | null;
        primarySchemePositionShortName?: string | null;
        redshirt?: boolean | null;
        headshotUrl?: string | null;
        eligibility?: string | null;
        eligibilityYear?: number | null;
        bestClubGrade?: number | null;
        bestClubGradeType?: string | null;
        sumerPrimaryEvaluationGrade?: number | null;
      };
    }>;
  };
};

export type GetBigBoardPlayersQueryVariables = Exact<{ [key: string]: never }>;

export type GetBigBoardPlayersQuery = {
  __typename?: "Query";
  bigBoardPlayers: Array<{
    __typename?: "BigBoardPlayer";
    id: string;
    playerId: string;
    rank: number;
  }>;
};

export type GetRoleByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetRoleByIdQuery = {
  __typename?: "Query";
  roles: Array<{
    __typename?: "AppRole";
    id: string;
    name: string;
    permissions: Array<{
      __typename?: "Permission";
      id: string;
      name: string;
      description?: string | null;
    }>;
  }>;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = {
  __typename?: "Query";
  roles: Array<{
    __typename?: "AppRole";
    id: string;
    name: string;
    permissions: Array<{
      __typename?: "Permission";
      id: string;
      name: string;
      description?: string | null;
    }>;
  }>;
};

export type SchoolFragment = {
  __typename?: "School";
  id: string;
  schoolCode?: string | null;
  schoolName: string;
  nickname?: string | null;
  primaryColor?: string | null;
  secondaryColor?: string | null;
  conference?: string | null;
  division?: string | null;
};

export type SchoolListFragment = {
  __typename?: "School";
  id: string;
  schoolCode?: string | null;
  schoolName: string;
  division?: string | null;
};

export type GetSchoolsByDivisionQueryVariables = Exact<{
  division?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type GetSchoolsByDivisionQuery = {
  __typename?: "Query";
  schools: Array<{
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
    schoolName: string;
    nickname?: string | null;
    primaryColor?: string | null;
    secondaryColor?: string | null;
    conference?: string | null;
    division?: string | null;
  }>;
};

export type GetSchoolsForLookupQueryVariables = Exact<{
  division?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
}>;

export type GetSchoolsForLookupQuery = {
  __typename?: "Query";
  schools: Array<{
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
    schoolName: string;
    division?: string | null;
  }>;
};

export type GetSchoolQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetSchoolQuery = {
  __typename?: "Query";
  schoolById?: {
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
    schoolName: string;
    nickname?: string | null;
    primaryColor?: string | null;
    secondaryColor?: string | null;
    conference?: string | null;
    division?: string | null;
  } | null;
};

export type GetSchoolsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSchoolsQuery = {
  __typename?: "Query";
  schools: Array<{
    __typename?: "School";
    id: string;
    schoolCode?: string | null;
    schoolName: string;
    nickname?: string | null;
    primaryColor?: string | null;
    secondaryColor?: string | null;
    conference?: string | null;
    division?: string | null;
  }>;
};

export type SavePlayerAssignmentMutationVariables = Exact<{
  request: SavePlayerAssignmentRequest;
}>;

export type SavePlayerAssignmentMutation = {
  __typename?: "Mutation";
  savePlayerAssignment: Array<{
    __typename?: "ScoutingAssignment";
    id: string;
    userId: string;
    scoutType: ScoutTypeEnum;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
    position?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    player?: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  }>;
};

export type CreateScoutAssignmentMutationVariables = Exact<{
  request: CreateScoutingAssignmentRequest;
}>;

export type CreateScoutAssignmentMutation = {
  __typename?: "Mutation";
  createScoutingAssignment?: {
    __typename?: "ScoutingAssignment";
    id: string;
    userId: string;
    scoutType: ScoutTypeEnum;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
    position?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    player?: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      headshotUrl?: string | null;
      draftYear?: number | null;
      eligibilityYear?: number | null;
      accruedSeasons?: number | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
      } | null;
      club?: { __typename?: "Club"; id: string; clubCode: string } | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        season: number;
        status: EvalStatusEnum;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
      }>;
    } | null;
  } | null;
};

export type UpdateScoutAssignmentMutationVariables = Exact<{
  request: UpdateScoutingAssignmentRequest;
}>;

export type UpdateScoutAssignmentMutation = {
  __typename?: "Mutation";
  updateScoutingAssignment?: {
    __typename?: "ScoutingAssignment";
    id: string;
    userId: string;
    scoutType: ScoutTypeEnum;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
    position?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    player?: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      headshotUrl?: string | null;
      draftYear?: number | null;
      eligibilityYear?: number | null;
      accruedSeasons?: number | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
      } | null;
      club?: { __typename?: "Club"; id: string; clubCode: string } | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        season: number;
        status: EvalStatusEnum;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
      }>;
    } | null;
  } | null;
};

export type DeleteScoutAssignmentMutationVariables = Exact<{
  request: DeleteScoutingAssignmentRequest;
}>;

export type DeleteScoutAssignmentMutation = {
  __typename?: "Mutation";
  deleteScoutingAssignment: {
    __typename?: "ScoutingAssignment";
    id: string;
    userId: string;
    scoutType: ScoutTypeEnum;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
    position?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    player?: {
      __typename?: "Player";
      id: string;
      jersey?: string | null;
      firstName: string;
      lastName: string;
      headshotUrl?: string | null;
      draftYear?: number | null;
      eligibilityYear?: number | null;
      accruedSeasons?: number | null;
      freeAgencyStatus?: FreeAgencyStatus | null;
      sumerGeneralPosition?: {
        __typename?: "Position";
        id: string;
        shortName: string;
      } | null;
      sumerAtlasPosition?: {
        __typename?: "Position";
        shortName: string;
      } | null;
      school?: {
        __typename?: "School";
        id: string;
        schoolCode?: string | null;
      } | null;
      club?: { __typename?: "Club"; id: string; clubCode: string } | null;
      evaluations: Array<{
        __typename?: "Evaluation";
        season: number;
        status: EvalStatusEnum;
        lastUpdated: string;
        scoutGrade?: { __typename?: "ScoutGrade"; grade: number } | null;
        evalType: { __typename?: "EvalType"; type: EvalTypeEnum };
      }>;
    } | null;
  };
};

export type GetScoutingAssignmentsForUserQueryVariables = Exact<{
  userId: Scalars["UUID"];
}>;

export type GetScoutingAssignmentsForUserQuery = {
  __typename?: "Query";
  scoutingAssignmentsByUserId: Array<{
    __typename?: "ScoutingAssignment";
    id: string;
    userId: string;
    scoutType: ScoutTypeEnum;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
    position?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    player?: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  }>;
};

export type ScoutingAssignmentFieldsFragment = {
  __typename?: "ScoutingAssignment";
  id: string;
  userId: string;
  scoutType: ScoutTypeEnum;
  club?: { __typename?: "Club"; id: string; clubCode: string } | null;
  position?: { __typename?: "Position"; id: string; shortName: string } | null;
  player?: {
    __typename?: "Player";
    id: string;
    firstName: string;
    lastName: string;
  } | null;
};

export type GetScoutingAssignmentsQueryVariables = Exact<{
  playerId: Scalars["UUID"];
}>;

export type GetScoutingAssignmentsQuery = {
  __typename?: "Query";
  scoutingAssignments: Array<{
    __typename?: "ScoutingAssignment";
    id: string;
    userId: string;
    scoutType: ScoutTypeEnum;
    club?: { __typename?: "Club"; id: string; clubCode: string } | null;
    position?: {
      __typename?: "Position";
      id: string;
      shortName: string;
    } | null;
    player?: {
      __typename?: "Player";
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  }>;
};

export type GetGameByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetGameByIdQuery = {
  __typename?: "Query";
  games: Array<{
    __typename?: "Game";
    id: string;
    season: number;
    week?: number | null;
    venue?: string | null;
    homeTeamId?: string | null;
    homeTeam?: string | null;
    awayTeam?: string | null;
    homeTeamScore?: number | null;
    awayTeamScore?: number | null;
    kickoffTime?: string | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
  }>;
};

export type GetAllStarGamesQueryVariables = Exact<{
  homeTeams?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
  season: Scalars["Int"];
}>;

export type GetAllStarGamesQuery = {
  __typename?: "Query";
  games: Array<{
    __typename?: "Game";
    id: string;
    season: number;
    week?: number | null;
    venue?: string | null;
    homeTeamId?: string | null;
    homeTeam?: string | null;
    awayTeam?: string | null;
    homeTeamScore?: number | null;
    awayTeamScore?: number | null;
    kickoffTime?: string | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
  }>;
};

export type GetTeamGamesBySeasonQueryVariables = Exact<{
  season: Scalars["Int"];
  league: Scalars["Int"];
  team?: InputMaybe<Scalars["String"]>;
}>;

export type GetTeamGamesBySeasonQuery = {
  __typename?: "Query";
  games: Array<{
    __typename?: "Game";
    id: string;
    season: number;
    week?: number | null;
    venue?: string | null;
    homeTeamId?: string | null;
    homeTeam?: string | null;
    awayTeam?: string | null;
    homeTeamScore?: number | null;
    awayTeamScore?: number | null;
    kickoffTime?: string | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
  }>;
};

export type GetGamesByTeamIdQueryVariables = Exact<{
  season: Scalars["Int"];
  league: Scalars["Int"];
  teamId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetGamesByTeamIdQuery = {
  __typename?: "Query";
  games: Array<{
    __typename?: "Game";
    id: string;
    season: number;
    week?: number | null;
    venue?: string | null;
    homeTeamId?: string | null;
    homeTeam?: string | null;
    awayTeam?: string | null;
    homeTeamScore?: number | null;
    awayTeamScore?: number | null;
    kickoffTime?: string | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
  }>;
};

export type GetGamesByPlayerIdQueryVariables = Exact<{
  season: Scalars["Int"];
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetGamesByPlayerIdQuery = {
  __typename?: "Query";
  gameStats: Array<{
    __typename?: "GameStat";
    game: {
      __typename?: "Game";
      id: string;
      season: number;
      week?: number | null;
      venue?: string | null;
      homeTeamId?: string | null;
      homeTeam?: string | null;
      awayTeam?: string | null;
      homeTeamScore?: number | null;
      awayTeamScore?: number | null;
      kickoffTime?: string | null;
      seasonType: {
        __typename?: "SeasonType";
        id: number;
        abbreviation?: string | null;
        description?: string | null;
      };
      weekType: {
        __typename?: "WeekType";
        id: number;
        description?: string | null;
      };
      leagueType: {
        __typename?: "LeagueType";
        id: number;
        description?: string | null;
      };
    };
  }>;
};

export type GameFragment = {
  __typename?: "Game";
  id: string;
  season: number;
  week?: number | null;
  venue?: string | null;
  homeTeamId?: string | null;
  homeTeam?: string | null;
  awayTeam?: string | null;
  homeTeamScore?: number | null;
  awayTeamScore?: number | null;
  kickoffTime?: string | null;
  seasonType: {
    __typename?: "SeasonType";
    id: number;
    abbreviation?: string | null;
    description?: string | null;
  };
  weekType: {
    __typename?: "WeekType";
    id: number;
    description?: string | null;
  };
  leagueType: {
    __typename?: "LeagueType";
    id: number;
    description?: string | null;
  };
};

export type GetCareerStatsForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetCareerStatsForPlayerQuery = {
  __typename?: "Query";
  careerStats: Array<{
    __typename?: "CareerStat";
    perGameStatsGamesPlayed?: number | null;
    perGameStatsPassAttemptsPerGame?: number | null;
    perGameStatsPassingYardsPerGame?: number | null;
    perGameStatsReceivingYardsPerGame?: number | null;
    perGameStatsReceptionsPerGame?: number | null;
    perGameStatsRushAttemptsPerGame?: number | null;
    perGameStatsRushingYardsPerGame?: number | null;
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    playerStat?: {
      __typename?: "PlayerStat";
      rushingStatsYards?: number | null;
      rushingStatsAttempts?: number | null;
      rushingStatsTouchdowns?: number | null;
      rushingStatsRunsTwentyYardsPlus?: number | null;
      rushingStatsRunsFortyYardsPlus?: number | null;
      rushingStatsLongestRun?: number | null;
      rushingStatsFirstDownRushes?: number | null;
      rushingStatsFirstDownPercentage?: number | null;
      rushingStatsFumbles?: number | null;
      rushingStatsYardsAfterContact?: number | null;
      rushingStatsYardsAfterContactPerRush?: number | null;
      rushingStatsTacklesAvoided?: number | null;
      rushingStatsFumblesRecovered?: number | null;
      rushingStatsFumblesLost?: number | null;
      rushingStatsHurriesAllowed?: number | null;
      rushingStatsPenalties?: number | null;
      rushingStatsInjuriesInGame?: number | null;
      rushingStatsPressuresAllowed?: number | null;
      rushingStatsSacksAllowed?: number | null;
      rushingStatsYardsPerRush?: number | null;
      rushingStatsSnaps?: number | null;
      rushingStatsTeamSnaps?: number | null;
      rushingStatsSnapPercentage?: number | null;
      rushingStatsTwoPointAttempts?: number | null;
      rushingStatsTwoPointConversions?: number | null;
      returnStatsKickReturnYardsAverage?: number | null;
      returnStatsKickReturnAttempts?: number | null;
      returnStatsKickReturnYards?: number | null;
      returnStatsKickReturnTouchdowns?: number | null;
      returnStatsKickReturnsTwentyYardsPlus?: number | null;
      returnStatsKickReturnsFortyYardsPlus?: number | null;
      returnStatsKickReturnLongestYards?: number | null;
      returnStatsKickReturnFairCatches?: number | null;
      returnStatsKickReturnFumbles?: number | null;
      returnStatsKickReturnYardsAfterContact?: number | null;
      returnStatsKickReturnYardsAfterContactAverage?: number | null;
      returnStatsKickReturnSnaps?: number | null;
      returnStatsKickReturnTeamSnaps?: number | null;
      returnStatsKickReturnSnapPercentage?: number | null;
      returnStatsPuntReturnYardsAverage?: number | null;
      returnStatsPuntReturnAttempts?: number | null;
      returnStatsPuntReturnYards?: number | null;
      returnStatsPuntReturnTouchdowns?: number | null;
      returnStatsPuntReturnsTwentyYardsPlus?: number | null;
      returnStatsPuntReturnsFortyYardsPlus?: number | null;
      returnStatsPuntReturnLongestYards?: number | null;
      returnStatsPuntReturnFairCatches?: number | null;
      returnStatsPuntReturnFumbles?: number | null;
      returnStatsPuntReturnYardsAfterContact?: number | null;
      returnStatsPuntReturnYardsAfterContactAverage?: number | null;
      returnStatsPuntReturnSnaps?: number | null;
      returnStatsPuntReturnTeamSnaps?: number | null;
      returnStatsPuntReturnSnapPercentage?: number | null;
      receivingStatsReceptions?: number | null;
      receivingStatsTouchdowns?: number | null;
      receivingStatsYards?: number | null;
      receivingStatsYardsPerReception?: number | null;
      receivingStatsReceptionsTwentyYardsPlus?: number | null;
      receivingStatsReceptionsFortyYardsPlus?: number | null;
      receivingStatsLongestReception?: number | null;
      receivingStatsFirstDowns?: number | null;
      receivingStatsFirstDownPercentage?: number | null;
      receivingStatsReceivingFumbles?: number | null;
      receivingStatsYardsAfterCatch?: number | null;
      receivingStatsYardAfterCatchPerReception?: number | null;
      receivingStatsTargets?: number | null;
      receivingStatsTacklesAvoided?: number | null;
      receivingStatsDrops?: number | null;
      receivingStatsDropsPercentage?: number | null;
      receivingStatsYardsAfterContact?: number | null;
      receivingStatsInjuriesInGame?: number | null;
      receivingStatsPenalties?: number | null;
      receivingStatsCatchableThrows?: number | null;
      receivingStatsPassRouteDepthAverage?: number | null;
      receivingStatsSnaps?: number | null;
      receivingStatsTeamSnaps?: number | null;
      receivingStatsSnapPercentage?: number | null;
      receivingStatsPassRoutesRun?: number | null;
      receivingStatsReceptionsPerTouchdown?: number | null;
      receivingStatsTwoPointAttempts?: number | null;
      receivingStatsTwoPointConversions?: number | null;
      passingStatsYards?: number | null;
      passingStatsAttempts?: number | null;
      passingStatsCompletions?: number | null;
      passingStatsCompletionPercentage?: number | null;
      passingStatsYardsPerCompletionAverage?: number | null;
      passingStatsTouchdowns?: number | null;
      passingStatsTouchdownPercentage?: number | null;
      passingStatsInterceptions?: number | null;
      passingStatsInterceptionPercentage?: number | null;
      passingStatsPasserRating?: number | null;
      passingStatsFirstDowns?: number | null;
      passingStatsFirstDownPercentage?: number | null;
      passingStatsCompletionsTwentyYardsPlus?: number | null;
      passingStatsCompletionsFortyYardsPlus?: number | null;
      passingStatsLongestCompletion?: number | null;
      passingStatsSacks?: number | null;
      passingStatsSackYards?: number | null;
      passingStatsYardsAfterCatch?: number | null;
      passingStatsDrops?: number | null;
      passingStatsInjuriesInGame?: number | null;
      passingStatsFumbles?: number | null;
      passingStatsFumblesRecovered?: number | null;
      passingStatsHurriesAllowed?: number | null;
      passingStatsPassDepthAverage?: number | null;
      passingStatsPassDepthAttempts?: number | null;
      passingStatsCatchableThrows?: number | null;
      passingStatsCatchableThrowPercentage?: number | null;
      passingStatsPenalties?: number | null;
      passingStatsTimeToPressureAverage?: number | null;
      passingStatsTimeToPressureAttempts?: number | null;
      passingStatsTimeToThrowAverage?: number | null;
      passingStatsTimeToThrowAttempts?: number | null;
      passingStatsDropbackDepthAverage?: number | null;
      passingStatsDropbackAttempts?: number | null;
      passingStatsSnaps?: number | null;
      passingStatsTeamSnaps?: number | null;
      passingStatsSnapPercentage?: number | null;
      passingStatsPumpFakes?: number | null;
      passingStatsPassingEfficiency?: number | null;
      passingStatsYardsPerAttempt?: number | null;
      passingStatsTouchdownInterceptionRatio?: number | null;
      passingStatsTwoPointAttempts?: number | null;
      passingStatsTwoPointConversions?: number | null;
      defenseStatsFumblesForced?: number | null;
      defenseStatsTacklesSolo?: number | null;
      defenseStatsSacks?: number | null;
      defenseStatsSackPlays?: number | null;
      defenseStatsSackPercentage?: number | null;
      defenseStatsSnaps?: number | null;
      defenseStatsTeamSnaps?: number | null;
      defenseStatsSnapPercentage?: number | null;
      defenseStatsBattedPasses?: number | null;
      defenseStatsQbHits?: number | null;
      defenseStatsQbHitPercentage?: number | null;
      defenseStatsFumblesRecovered?: number | null;
      defenseStatsInjuriesInGame?: number | null;
      defenseStatsMissedTacklesOnQb?: number | null;
      defenseStatsPassBreakups?: number | null;
      defenseStatsStops?: number | null;
      defenseStatsTouchdowns?: number | null;
      defenseStatsPassRushAttempts?: number | null;
      defenseStatsCoverageAttempts?: number | null;
      defenseStatsBurns?: number | null;
      defenseStatsDefensiveTargets?: number | null;
      defenseStatsQbPressures?: number | null;
      defenseStatsFumblesRecoveredForTouchdown?: number | null;
      defenseStatsQbPressurePercentage?: number | null;
      defenseStatsQbHurries?: number | null;
      defenseStatsQbHurryPercentage?: number | null;
      defenseStatsMissedTackles?: number | null;
      defenseStatsPuntsBlocked?: number | null;
      defenseStatsFieldGoalsBlocked?: number | null;
      defenseStatsExtraPointsBlocked?: number | null;
      defenseStatsTacklesForLoss?: number | null;
      defenseStatsDroppedInterceptions?: number | null;
      defenseStatsInterceptions?: number | null;
      defenseStatsInterceptionTouchdowns?: number | null;
      defenseStatsInterceptionYards?: number | null;
      defenseStatsInterceptionLongestReturn?: number | null;
      defenseStatsInterceptionPercentage?: number | null;
      defenseStatsTacklesCombined?: number | null;
      defenseStatsTacklesAssisted?: number | null;
      kickingStatsFieldGoalsMade?: number | null;
      kickingStatsFieldGoalAttempts?: number | null;
      kickingStatsFieldGoalPercentage?: number | null;
      kickingStatsFieldGoals1To19Attempted?: number | null;
      kickingStatsFieldGoals1To19Made?: number | null;
      kickingStatsFieldGoals1To19Percentage?: number | null;
      kickingStatsFieldGoals20To29Attempted?: number | null;
      kickingStatsFieldGoals20To29Made?: number | null;
      kickingStatsFieldGoals20To29Percentage?: number | null;
      kickingStatsFieldGoals30To39Attempted?: number | null;
      kickingStatsFieldGoals30To39Made?: number | null;
      kickingStatsFieldGoals30To39Percentage?: number | null;
      kickingStatsFieldGoals40To49Attempted?: number | null;
      kickingStatsFieldGoals40To49Made?: number | null;
      kickingStatsFieldGoals40To49Percentage?: number | null;
      kickingStatsFieldGoals50To59Attempted?: number | null;
      kickingStatsFieldGoals50To59Made?: number | null;
      kickingStatsFieldGoals50To59Percentage?: number | null;
      kickingStatsFieldGoals60PlusAttempted?: number | null;
      kickingStatsFieldGoals60PlusMade?: number | null;
      kickingStatsFieldGoals60PlusPercentage?: number | null;
      kickingStatsFieldGoalMadeLongest?: number | null;
      kickingStatsFieldGoalsBlocked?: number | null;
      kickingStatsFieldGoalSnaps?: number | null;
      kickingStatsFieldGoalTeamSnaps?: number | null;
      kickingStatsFieldGoalSnapPercentage?: number | null;
      kickingStatsFieldGoalYardsAverage?: number | null;
      kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
      kickingStatsExtraPointAttempts?: number | null;
      kickingStatsExtraPointsMade?: number | null;
      kickingStatsExtraPointsBlocked?: number | null;
      kickingStatsExtraPointsPercentage?: number | null;
      kickingStatsPuntYardsAverage?: number | null;
      kickingStatsPuntYardsNetAverage?: number | null;
      kickingStatsPuntAttempts?: number | null;
      kickingStatsPuntYardsLongest?: number | null;
      kickingStatsPuntYards?: number | null;
      kickingStatsPuntsIn20?: number | null;
      kickingStatsPuntsOutOfBounds?: number | null;
      kickingStatsPuntsDowned?: number | null;
      kickingStatsPuntsTouchbacks?: number | null;
      kickingStatsPuntsFairCaught?: number | null;
      kickingStatsPuntsReturned?: number | null;
      kickingStatsPuntReturnYards?: number | null;
      kickingStatsPuntReturnTouchdowns?: number | null;
      kickingStatsPuntsBlocked?: number | null;
      kickingStatsPuntSnaps?: number | null;
      kickingStatsPuntTeamSnaps?: number | null;
      kickingStatsPuntSnapPercentage?: number | null;
      kickingStatsPuntReturnYardsAverage?: number | null;
      kickingStatsPuntYardsNet?: number | null;
      kickingStatsPuntHangTimeAverage?: number | null;
      kickingStatsPuntOperationTimeAverage?: number | null;
      kickingStatsKickoffAttempts?: number | null;
      kickingStatsKickoffYards?: number | null;
      kickingStatsKickoffReturnYards?: number | null;
      kickingStatsKickoffTouchbacks?: number | null;
      kickingStatsKickoffTouchbackPercentage?: number | null;
      kickingStatsKickoffsReturned?: number | null;
      kickingStatsKickoffReturnLong?: number | null;
      kickingStatsKickoffReturnYardsAverage?: number | null;
      kickingStatsKickoffOnsideKicks?: number | null;
      kickingStatsKickoffOnsideKicksRecovered?: number | null;
      kickingStatsKickoffsOutOfBounds?: number | null;
      kickingStatsKickoffReturnTouchdowns?: number | null;
      kickingStatsKickoffDepthAverage?: number | null;
      kickingStatsKickoffYardsAverage?: number | null;
      kickingStatsKickoffSnaps?: number | null;
      kickingStatsKickoffTeamSnaps?: number | null;
      kickingStatsKickoffSnapPercentage?: number | null;
      kickingStatsKickoffDepthAttempts?: number | null;
      offensiveLinePressuresAllowed?: number | null;
      offensiveLineSacksAllowed?: number | null;
      offensiveLineScreenBlocks?: number | null;
      penalties?: number | null;
    } | null;
  }>;
};

export type CareerStatsFragment = {
  __typename?: "CareerStat";
  perGameStatsGamesPlayed?: number | null;
  perGameStatsPassAttemptsPerGame?: number | null;
  perGameStatsPassingYardsPerGame?: number | null;
  perGameStatsReceivingYardsPerGame?: number | null;
  perGameStatsReceptionsPerGame?: number | null;
  perGameStatsRushAttemptsPerGame?: number | null;
  perGameStatsRushingYardsPerGame?: number | null;
  leagueType: {
    __typename?: "LeagueType";
    id: number;
    description?: string | null;
  };
  seasonType: {
    __typename?: "SeasonType";
    id: number;
    abbreviation?: string | null;
    description?: string | null;
  };
  playerStat?: {
    __typename?: "PlayerStat";
    rushingStatsYards?: number | null;
    rushingStatsAttempts?: number | null;
    rushingStatsTouchdowns?: number | null;
    rushingStatsRunsTwentyYardsPlus?: number | null;
    rushingStatsRunsFortyYardsPlus?: number | null;
    rushingStatsLongestRun?: number | null;
    rushingStatsFirstDownRushes?: number | null;
    rushingStatsFirstDownPercentage?: number | null;
    rushingStatsFumbles?: number | null;
    rushingStatsYardsAfterContact?: number | null;
    rushingStatsYardsAfterContactPerRush?: number | null;
    rushingStatsTacklesAvoided?: number | null;
    rushingStatsFumblesRecovered?: number | null;
    rushingStatsFumblesLost?: number | null;
    rushingStatsHurriesAllowed?: number | null;
    rushingStatsPenalties?: number | null;
    rushingStatsInjuriesInGame?: number | null;
    rushingStatsPressuresAllowed?: number | null;
    rushingStatsSacksAllowed?: number | null;
    rushingStatsYardsPerRush?: number | null;
    rushingStatsSnaps?: number | null;
    rushingStatsTeamSnaps?: number | null;
    rushingStatsSnapPercentage?: number | null;
    rushingStatsTwoPointAttempts?: number | null;
    rushingStatsTwoPointConversions?: number | null;
    returnStatsKickReturnYardsAverage?: number | null;
    returnStatsKickReturnAttempts?: number | null;
    returnStatsKickReturnYards?: number | null;
    returnStatsKickReturnTouchdowns?: number | null;
    returnStatsKickReturnsTwentyYardsPlus?: number | null;
    returnStatsKickReturnsFortyYardsPlus?: number | null;
    returnStatsKickReturnLongestYards?: number | null;
    returnStatsKickReturnFairCatches?: number | null;
    returnStatsKickReturnFumbles?: number | null;
    returnStatsKickReturnYardsAfterContact?: number | null;
    returnStatsKickReturnYardsAfterContactAverage?: number | null;
    returnStatsKickReturnSnaps?: number | null;
    returnStatsKickReturnTeamSnaps?: number | null;
    returnStatsKickReturnSnapPercentage?: number | null;
    returnStatsPuntReturnYardsAverage?: number | null;
    returnStatsPuntReturnAttempts?: number | null;
    returnStatsPuntReturnYards?: number | null;
    returnStatsPuntReturnTouchdowns?: number | null;
    returnStatsPuntReturnsTwentyYardsPlus?: number | null;
    returnStatsPuntReturnsFortyYardsPlus?: number | null;
    returnStatsPuntReturnLongestYards?: number | null;
    returnStatsPuntReturnFairCatches?: number | null;
    returnStatsPuntReturnFumbles?: number | null;
    returnStatsPuntReturnYardsAfterContact?: number | null;
    returnStatsPuntReturnYardsAfterContactAverage?: number | null;
    returnStatsPuntReturnSnaps?: number | null;
    returnStatsPuntReturnTeamSnaps?: number | null;
    returnStatsPuntReturnSnapPercentage?: number | null;
    receivingStatsReceptions?: number | null;
    receivingStatsTouchdowns?: number | null;
    receivingStatsYards?: number | null;
    receivingStatsYardsPerReception?: number | null;
    receivingStatsReceptionsTwentyYardsPlus?: number | null;
    receivingStatsReceptionsFortyYardsPlus?: number | null;
    receivingStatsLongestReception?: number | null;
    receivingStatsFirstDowns?: number | null;
    receivingStatsFirstDownPercentage?: number | null;
    receivingStatsReceivingFumbles?: number | null;
    receivingStatsYardsAfterCatch?: number | null;
    receivingStatsYardAfterCatchPerReception?: number | null;
    receivingStatsTargets?: number | null;
    receivingStatsTacklesAvoided?: number | null;
    receivingStatsDrops?: number | null;
    receivingStatsDropsPercentage?: number | null;
    receivingStatsYardsAfterContact?: number | null;
    receivingStatsInjuriesInGame?: number | null;
    receivingStatsPenalties?: number | null;
    receivingStatsCatchableThrows?: number | null;
    receivingStatsPassRouteDepthAverage?: number | null;
    receivingStatsSnaps?: number | null;
    receivingStatsTeamSnaps?: number | null;
    receivingStatsSnapPercentage?: number | null;
    receivingStatsPassRoutesRun?: number | null;
    receivingStatsReceptionsPerTouchdown?: number | null;
    receivingStatsTwoPointAttempts?: number | null;
    receivingStatsTwoPointConversions?: number | null;
    passingStatsYards?: number | null;
    passingStatsAttempts?: number | null;
    passingStatsCompletions?: number | null;
    passingStatsCompletionPercentage?: number | null;
    passingStatsYardsPerCompletionAverage?: number | null;
    passingStatsTouchdowns?: number | null;
    passingStatsTouchdownPercentage?: number | null;
    passingStatsInterceptions?: number | null;
    passingStatsInterceptionPercentage?: number | null;
    passingStatsPasserRating?: number | null;
    passingStatsFirstDowns?: number | null;
    passingStatsFirstDownPercentage?: number | null;
    passingStatsCompletionsTwentyYardsPlus?: number | null;
    passingStatsCompletionsFortyYardsPlus?: number | null;
    passingStatsLongestCompletion?: number | null;
    passingStatsSacks?: number | null;
    passingStatsSackYards?: number | null;
    passingStatsYardsAfterCatch?: number | null;
    passingStatsDrops?: number | null;
    passingStatsInjuriesInGame?: number | null;
    passingStatsFumbles?: number | null;
    passingStatsFumblesRecovered?: number | null;
    passingStatsHurriesAllowed?: number | null;
    passingStatsPassDepthAverage?: number | null;
    passingStatsPassDepthAttempts?: number | null;
    passingStatsCatchableThrows?: number | null;
    passingStatsCatchableThrowPercentage?: number | null;
    passingStatsPenalties?: number | null;
    passingStatsTimeToPressureAverage?: number | null;
    passingStatsTimeToPressureAttempts?: number | null;
    passingStatsTimeToThrowAverage?: number | null;
    passingStatsTimeToThrowAttempts?: number | null;
    passingStatsDropbackDepthAverage?: number | null;
    passingStatsDropbackAttempts?: number | null;
    passingStatsSnaps?: number | null;
    passingStatsTeamSnaps?: number | null;
    passingStatsSnapPercentage?: number | null;
    passingStatsPumpFakes?: number | null;
    passingStatsPassingEfficiency?: number | null;
    passingStatsYardsPerAttempt?: number | null;
    passingStatsTouchdownInterceptionRatio?: number | null;
    passingStatsTwoPointAttempts?: number | null;
    passingStatsTwoPointConversions?: number | null;
    defenseStatsFumblesForced?: number | null;
    defenseStatsTacklesSolo?: number | null;
    defenseStatsSacks?: number | null;
    defenseStatsSackPlays?: number | null;
    defenseStatsSackPercentage?: number | null;
    defenseStatsSnaps?: number | null;
    defenseStatsTeamSnaps?: number | null;
    defenseStatsSnapPercentage?: number | null;
    defenseStatsBattedPasses?: number | null;
    defenseStatsQbHits?: number | null;
    defenseStatsQbHitPercentage?: number | null;
    defenseStatsFumblesRecovered?: number | null;
    defenseStatsInjuriesInGame?: number | null;
    defenseStatsMissedTacklesOnQb?: number | null;
    defenseStatsPassBreakups?: number | null;
    defenseStatsStops?: number | null;
    defenseStatsTouchdowns?: number | null;
    defenseStatsPassRushAttempts?: number | null;
    defenseStatsCoverageAttempts?: number | null;
    defenseStatsBurns?: number | null;
    defenseStatsDefensiveTargets?: number | null;
    defenseStatsQbPressures?: number | null;
    defenseStatsFumblesRecoveredForTouchdown?: number | null;
    defenseStatsQbPressurePercentage?: number | null;
    defenseStatsQbHurries?: number | null;
    defenseStatsQbHurryPercentage?: number | null;
    defenseStatsMissedTackles?: number | null;
    defenseStatsPuntsBlocked?: number | null;
    defenseStatsFieldGoalsBlocked?: number | null;
    defenseStatsExtraPointsBlocked?: number | null;
    defenseStatsTacklesForLoss?: number | null;
    defenseStatsDroppedInterceptions?: number | null;
    defenseStatsInterceptions?: number | null;
    defenseStatsInterceptionTouchdowns?: number | null;
    defenseStatsInterceptionYards?: number | null;
    defenseStatsInterceptionLongestReturn?: number | null;
    defenseStatsInterceptionPercentage?: number | null;
    defenseStatsTacklesCombined?: number | null;
    defenseStatsTacklesAssisted?: number | null;
    kickingStatsFieldGoalsMade?: number | null;
    kickingStatsFieldGoalAttempts?: number | null;
    kickingStatsFieldGoalPercentage?: number | null;
    kickingStatsFieldGoals1To19Attempted?: number | null;
    kickingStatsFieldGoals1To19Made?: number | null;
    kickingStatsFieldGoals1To19Percentage?: number | null;
    kickingStatsFieldGoals20To29Attempted?: number | null;
    kickingStatsFieldGoals20To29Made?: number | null;
    kickingStatsFieldGoals20To29Percentage?: number | null;
    kickingStatsFieldGoals30To39Attempted?: number | null;
    kickingStatsFieldGoals30To39Made?: number | null;
    kickingStatsFieldGoals30To39Percentage?: number | null;
    kickingStatsFieldGoals40To49Attempted?: number | null;
    kickingStatsFieldGoals40To49Made?: number | null;
    kickingStatsFieldGoals40To49Percentage?: number | null;
    kickingStatsFieldGoals50To59Attempted?: number | null;
    kickingStatsFieldGoals50To59Made?: number | null;
    kickingStatsFieldGoals50To59Percentage?: number | null;
    kickingStatsFieldGoals60PlusAttempted?: number | null;
    kickingStatsFieldGoals60PlusMade?: number | null;
    kickingStatsFieldGoals60PlusPercentage?: number | null;
    kickingStatsFieldGoalMadeLongest?: number | null;
    kickingStatsFieldGoalsBlocked?: number | null;
    kickingStatsFieldGoalSnaps?: number | null;
    kickingStatsFieldGoalTeamSnaps?: number | null;
    kickingStatsFieldGoalSnapPercentage?: number | null;
    kickingStatsFieldGoalYardsAverage?: number | null;
    kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
    kickingStatsExtraPointAttempts?: number | null;
    kickingStatsExtraPointsMade?: number | null;
    kickingStatsExtraPointsBlocked?: number | null;
    kickingStatsExtraPointsPercentage?: number | null;
    kickingStatsPuntYardsAverage?: number | null;
    kickingStatsPuntYardsNetAverage?: number | null;
    kickingStatsPuntAttempts?: number | null;
    kickingStatsPuntYardsLongest?: number | null;
    kickingStatsPuntYards?: number | null;
    kickingStatsPuntsIn20?: number | null;
    kickingStatsPuntsOutOfBounds?: number | null;
    kickingStatsPuntsDowned?: number | null;
    kickingStatsPuntsTouchbacks?: number | null;
    kickingStatsPuntsFairCaught?: number | null;
    kickingStatsPuntsReturned?: number | null;
    kickingStatsPuntReturnYards?: number | null;
    kickingStatsPuntReturnTouchdowns?: number | null;
    kickingStatsPuntsBlocked?: number | null;
    kickingStatsPuntSnaps?: number | null;
    kickingStatsPuntTeamSnaps?: number | null;
    kickingStatsPuntSnapPercentage?: number | null;
    kickingStatsPuntReturnYardsAverage?: number | null;
    kickingStatsPuntYardsNet?: number | null;
    kickingStatsPuntHangTimeAverage?: number | null;
    kickingStatsPuntOperationTimeAverage?: number | null;
    kickingStatsKickoffAttempts?: number | null;
    kickingStatsKickoffYards?: number | null;
    kickingStatsKickoffReturnYards?: number | null;
    kickingStatsKickoffTouchbacks?: number | null;
    kickingStatsKickoffTouchbackPercentage?: number | null;
    kickingStatsKickoffsReturned?: number | null;
    kickingStatsKickoffReturnLong?: number | null;
    kickingStatsKickoffReturnYardsAverage?: number | null;
    kickingStatsKickoffOnsideKicks?: number | null;
    kickingStatsKickoffOnsideKicksRecovered?: number | null;
    kickingStatsKickoffsOutOfBounds?: number | null;
    kickingStatsKickoffReturnTouchdowns?: number | null;
    kickingStatsKickoffDepthAverage?: number | null;
    kickingStatsKickoffYardsAverage?: number | null;
    kickingStatsKickoffSnaps?: number | null;
    kickingStatsKickoffTeamSnaps?: number | null;
    kickingStatsKickoffSnapPercentage?: number | null;
    kickingStatsKickoffDepthAttempts?: number | null;
    offensiveLinePressuresAllowed?: number | null;
    offensiveLineSacksAllowed?: number | null;
    offensiveLineScreenBlocks?: number | null;
    penalties?: number | null;
  } | null;
};

export type GetSeasonStatsForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetSeasonStatsForPlayerQuery = {
  __typename?: "Query";
  seasonStats: Array<{
    __typename?: "SeasonStat";
    season: number;
    perGameStatsGamesPlayed?: number | null;
    perGameStatsPassAttemptsPerGame?: number | null;
    perGameStatsPassingYardsPerGame?: number | null;
    perGameStatsReceivingYardsPerGame?: number | null;
    perGameStatsReceptionsPerGame?: number | null;
    perGameStatsRushAttemptsPerGame?: number | null;
    perGameStatsRushingYardsPerGame?: number | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
    playerStat?: {
      __typename?: "PlayerStat";
      rushingStatsYards?: number | null;
      rushingStatsAttempts?: number | null;
      rushingStatsTouchdowns?: number | null;
      rushingStatsRunsTwentyYardsPlus?: number | null;
      rushingStatsRunsFortyYardsPlus?: number | null;
      rushingStatsLongestRun?: number | null;
      rushingStatsFirstDownRushes?: number | null;
      rushingStatsFirstDownPercentage?: number | null;
      rushingStatsFumbles?: number | null;
      rushingStatsYardsAfterContact?: number | null;
      rushingStatsYardsAfterContactPerRush?: number | null;
      rushingStatsTacklesAvoided?: number | null;
      rushingStatsFumblesRecovered?: number | null;
      rushingStatsFumblesLost?: number | null;
      rushingStatsHurriesAllowed?: number | null;
      rushingStatsPenalties?: number | null;
      rushingStatsInjuriesInGame?: number | null;
      rushingStatsPressuresAllowed?: number | null;
      rushingStatsSacksAllowed?: number | null;
      rushingStatsYardsPerRush?: number | null;
      rushingStatsSnaps?: number | null;
      rushingStatsTeamSnaps?: number | null;
      rushingStatsSnapPercentage?: number | null;
      rushingStatsTwoPointAttempts?: number | null;
      rushingStatsTwoPointConversions?: number | null;
      returnStatsKickReturnYardsAverage?: number | null;
      returnStatsKickReturnAttempts?: number | null;
      returnStatsKickReturnYards?: number | null;
      returnStatsKickReturnTouchdowns?: number | null;
      returnStatsKickReturnsTwentyYardsPlus?: number | null;
      returnStatsKickReturnsFortyYardsPlus?: number | null;
      returnStatsKickReturnLongestYards?: number | null;
      returnStatsKickReturnFairCatches?: number | null;
      returnStatsKickReturnFumbles?: number | null;
      returnStatsKickReturnYardsAfterContact?: number | null;
      returnStatsKickReturnYardsAfterContactAverage?: number | null;
      returnStatsKickReturnSnaps?: number | null;
      returnStatsKickReturnTeamSnaps?: number | null;
      returnStatsKickReturnSnapPercentage?: number | null;
      returnStatsPuntReturnYardsAverage?: number | null;
      returnStatsPuntReturnAttempts?: number | null;
      returnStatsPuntReturnYards?: number | null;
      returnStatsPuntReturnTouchdowns?: number | null;
      returnStatsPuntReturnsTwentyYardsPlus?: number | null;
      returnStatsPuntReturnsFortyYardsPlus?: number | null;
      returnStatsPuntReturnLongestYards?: number | null;
      returnStatsPuntReturnFairCatches?: number | null;
      returnStatsPuntReturnFumbles?: number | null;
      returnStatsPuntReturnYardsAfterContact?: number | null;
      returnStatsPuntReturnYardsAfterContactAverage?: number | null;
      returnStatsPuntReturnSnaps?: number | null;
      returnStatsPuntReturnTeamSnaps?: number | null;
      returnStatsPuntReturnSnapPercentage?: number | null;
      receivingStatsReceptions?: number | null;
      receivingStatsTouchdowns?: number | null;
      receivingStatsYards?: number | null;
      receivingStatsYardsPerReception?: number | null;
      receivingStatsReceptionsTwentyYardsPlus?: number | null;
      receivingStatsReceptionsFortyYardsPlus?: number | null;
      receivingStatsLongestReception?: number | null;
      receivingStatsFirstDowns?: number | null;
      receivingStatsFirstDownPercentage?: number | null;
      receivingStatsReceivingFumbles?: number | null;
      receivingStatsYardsAfterCatch?: number | null;
      receivingStatsYardAfterCatchPerReception?: number | null;
      receivingStatsTargets?: number | null;
      receivingStatsTacklesAvoided?: number | null;
      receivingStatsDrops?: number | null;
      receivingStatsDropsPercentage?: number | null;
      receivingStatsYardsAfterContact?: number | null;
      receivingStatsInjuriesInGame?: number | null;
      receivingStatsPenalties?: number | null;
      receivingStatsCatchableThrows?: number | null;
      receivingStatsPassRouteDepthAverage?: number | null;
      receivingStatsSnaps?: number | null;
      receivingStatsTeamSnaps?: number | null;
      receivingStatsSnapPercentage?: number | null;
      receivingStatsPassRoutesRun?: number | null;
      receivingStatsReceptionsPerTouchdown?: number | null;
      receivingStatsTwoPointAttempts?: number | null;
      receivingStatsTwoPointConversions?: number | null;
      passingStatsYards?: number | null;
      passingStatsAttempts?: number | null;
      passingStatsCompletions?: number | null;
      passingStatsCompletionPercentage?: number | null;
      passingStatsYardsPerCompletionAverage?: number | null;
      passingStatsTouchdowns?: number | null;
      passingStatsTouchdownPercentage?: number | null;
      passingStatsInterceptions?: number | null;
      passingStatsInterceptionPercentage?: number | null;
      passingStatsPasserRating?: number | null;
      passingStatsFirstDowns?: number | null;
      passingStatsFirstDownPercentage?: number | null;
      passingStatsCompletionsTwentyYardsPlus?: number | null;
      passingStatsCompletionsFortyYardsPlus?: number | null;
      passingStatsLongestCompletion?: number | null;
      passingStatsSacks?: number | null;
      passingStatsSackYards?: number | null;
      passingStatsYardsAfterCatch?: number | null;
      passingStatsDrops?: number | null;
      passingStatsInjuriesInGame?: number | null;
      passingStatsFumbles?: number | null;
      passingStatsFumblesRecovered?: number | null;
      passingStatsHurriesAllowed?: number | null;
      passingStatsPassDepthAverage?: number | null;
      passingStatsPassDepthAttempts?: number | null;
      passingStatsCatchableThrows?: number | null;
      passingStatsCatchableThrowPercentage?: number | null;
      passingStatsPenalties?: number | null;
      passingStatsTimeToPressureAverage?: number | null;
      passingStatsTimeToPressureAttempts?: number | null;
      passingStatsTimeToThrowAverage?: number | null;
      passingStatsTimeToThrowAttempts?: number | null;
      passingStatsDropbackDepthAverage?: number | null;
      passingStatsDropbackAttempts?: number | null;
      passingStatsSnaps?: number | null;
      passingStatsTeamSnaps?: number | null;
      passingStatsSnapPercentage?: number | null;
      passingStatsPumpFakes?: number | null;
      passingStatsPassingEfficiency?: number | null;
      passingStatsYardsPerAttempt?: number | null;
      passingStatsTouchdownInterceptionRatio?: number | null;
      passingStatsTwoPointAttempts?: number | null;
      passingStatsTwoPointConversions?: number | null;
      defenseStatsFumblesForced?: number | null;
      defenseStatsTacklesSolo?: number | null;
      defenseStatsSacks?: number | null;
      defenseStatsSackPlays?: number | null;
      defenseStatsSackPercentage?: number | null;
      defenseStatsSnaps?: number | null;
      defenseStatsTeamSnaps?: number | null;
      defenseStatsSnapPercentage?: number | null;
      defenseStatsBattedPasses?: number | null;
      defenseStatsQbHits?: number | null;
      defenseStatsQbHitPercentage?: number | null;
      defenseStatsFumblesRecovered?: number | null;
      defenseStatsInjuriesInGame?: number | null;
      defenseStatsMissedTacklesOnQb?: number | null;
      defenseStatsPassBreakups?: number | null;
      defenseStatsStops?: number | null;
      defenseStatsTouchdowns?: number | null;
      defenseStatsPassRushAttempts?: number | null;
      defenseStatsCoverageAttempts?: number | null;
      defenseStatsBurns?: number | null;
      defenseStatsDefensiveTargets?: number | null;
      defenseStatsQbPressures?: number | null;
      defenseStatsFumblesRecoveredForTouchdown?: number | null;
      defenseStatsQbPressurePercentage?: number | null;
      defenseStatsQbHurries?: number | null;
      defenseStatsQbHurryPercentage?: number | null;
      defenseStatsMissedTackles?: number | null;
      defenseStatsPuntsBlocked?: number | null;
      defenseStatsFieldGoalsBlocked?: number | null;
      defenseStatsExtraPointsBlocked?: number | null;
      defenseStatsTacklesForLoss?: number | null;
      defenseStatsDroppedInterceptions?: number | null;
      defenseStatsInterceptions?: number | null;
      defenseStatsInterceptionTouchdowns?: number | null;
      defenseStatsInterceptionYards?: number | null;
      defenseStatsInterceptionLongestReturn?: number | null;
      defenseStatsInterceptionPercentage?: number | null;
      defenseStatsTacklesCombined?: number | null;
      defenseStatsTacklesAssisted?: number | null;
      kickingStatsFieldGoalsMade?: number | null;
      kickingStatsFieldGoalAttempts?: number | null;
      kickingStatsFieldGoalPercentage?: number | null;
      kickingStatsFieldGoals1To19Attempted?: number | null;
      kickingStatsFieldGoals1To19Made?: number | null;
      kickingStatsFieldGoals1To19Percentage?: number | null;
      kickingStatsFieldGoals20To29Attempted?: number | null;
      kickingStatsFieldGoals20To29Made?: number | null;
      kickingStatsFieldGoals20To29Percentage?: number | null;
      kickingStatsFieldGoals30To39Attempted?: number | null;
      kickingStatsFieldGoals30To39Made?: number | null;
      kickingStatsFieldGoals30To39Percentage?: number | null;
      kickingStatsFieldGoals40To49Attempted?: number | null;
      kickingStatsFieldGoals40To49Made?: number | null;
      kickingStatsFieldGoals40To49Percentage?: number | null;
      kickingStatsFieldGoals50To59Attempted?: number | null;
      kickingStatsFieldGoals50To59Made?: number | null;
      kickingStatsFieldGoals50To59Percentage?: number | null;
      kickingStatsFieldGoals60PlusAttempted?: number | null;
      kickingStatsFieldGoals60PlusMade?: number | null;
      kickingStatsFieldGoals60PlusPercentage?: number | null;
      kickingStatsFieldGoalMadeLongest?: number | null;
      kickingStatsFieldGoalsBlocked?: number | null;
      kickingStatsFieldGoalSnaps?: number | null;
      kickingStatsFieldGoalTeamSnaps?: number | null;
      kickingStatsFieldGoalSnapPercentage?: number | null;
      kickingStatsFieldGoalYardsAverage?: number | null;
      kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
      kickingStatsExtraPointAttempts?: number | null;
      kickingStatsExtraPointsMade?: number | null;
      kickingStatsExtraPointsBlocked?: number | null;
      kickingStatsExtraPointsPercentage?: number | null;
      kickingStatsPuntYardsAverage?: number | null;
      kickingStatsPuntYardsNetAverage?: number | null;
      kickingStatsPuntAttempts?: number | null;
      kickingStatsPuntYardsLongest?: number | null;
      kickingStatsPuntYards?: number | null;
      kickingStatsPuntsIn20?: number | null;
      kickingStatsPuntsOutOfBounds?: number | null;
      kickingStatsPuntsDowned?: number | null;
      kickingStatsPuntsTouchbacks?: number | null;
      kickingStatsPuntsFairCaught?: number | null;
      kickingStatsPuntsReturned?: number | null;
      kickingStatsPuntReturnYards?: number | null;
      kickingStatsPuntReturnTouchdowns?: number | null;
      kickingStatsPuntsBlocked?: number | null;
      kickingStatsPuntSnaps?: number | null;
      kickingStatsPuntTeamSnaps?: number | null;
      kickingStatsPuntSnapPercentage?: number | null;
      kickingStatsPuntReturnYardsAverage?: number | null;
      kickingStatsPuntYardsNet?: number | null;
      kickingStatsPuntHangTimeAverage?: number | null;
      kickingStatsPuntOperationTimeAverage?: number | null;
      kickingStatsKickoffAttempts?: number | null;
      kickingStatsKickoffYards?: number | null;
      kickingStatsKickoffReturnYards?: number | null;
      kickingStatsKickoffTouchbacks?: number | null;
      kickingStatsKickoffTouchbackPercentage?: number | null;
      kickingStatsKickoffsReturned?: number | null;
      kickingStatsKickoffReturnLong?: number | null;
      kickingStatsKickoffReturnYardsAverage?: number | null;
      kickingStatsKickoffOnsideKicks?: number | null;
      kickingStatsKickoffOnsideKicksRecovered?: number | null;
      kickingStatsKickoffsOutOfBounds?: number | null;
      kickingStatsKickoffReturnTouchdowns?: number | null;
      kickingStatsKickoffDepthAverage?: number | null;
      kickingStatsKickoffYardsAverage?: number | null;
      kickingStatsKickoffSnaps?: number | null;
      kickingStatsKickoffTeamSnaps?: number | null;
      kickingStatsKickoffSnapPercentage?: number | null;
      kickingStatsKickoffDepthAttempts?: number | null;
      offensiveLinePressuresAllowed?: number | null;
      offensiveLineSacksAllowed?: number | null;
      offensiveLineScreenBlocks?: number | null;
      penalties?: number | null;
    } | null;
  }>;
};

export type SeasonStatsFragment = {
  __typename?: "SeasonStat";
  season: number;
  perGameStatsGamesPlayed?: number | null;
  perGameStatsPassAttemptsPerGame?: number | null;
  perGameStatsPassingYardsPerGame?: number | null;
  perGameStatsReceivingYardsPerGame?: number | null;
  perGameStatsReceptionsPerGame?: number | null;
  perGameStatsRushAttemptsPerGame?: number | null;
  perGameStatsRushingYardsPerGame?: number | null;
  seasonType: {
    __typename?: "SeasonType";
    id: number;
    abbreviation?: string | null;
    description?: string | null;
  };
  leagueType: {
    __typename?: "LeagueType";
    id: number;
    description?: string | null;
  };
  playerStat?: {
    __typename?: "PlayerStat";
    rushingStatsYards?: number | null;
    rushingStatsAttempts?: number | null;
    rushingStatsTouchdowns?: number | null;
    rushingStatsRunsTwentyYardsPlus?: number | null;
    rushingStatsRunsFortyYardsPlus?: number | null;
    rushingStatsLongestRun?: number | null;
    rushingStatsFirstDownRushes?: number | null;
    rushingStatsFirstDownPercentage?: number | null;
    rushingStatsFumbles?: number | null;
    rushingStatsYardsAfterContact?: number | null;
    rushingStatsYardsAfterContactPerRush?: number | null;
    rushingStatsTacklesAvoided?: number | null;
    rushingStatsFumblesRecovered?: number | null;
    rushingStatsFumblesLost?: number | null;
    rushingStatsHurriesAllowed?: number | null;
    rushingStatsPenalties?: number | null;
    rushingStatsInjuriesInGame?: number | null;
    rushingStatsPressuresAllowed?: number | null;
    rushingStatsSacksAllowed?: number | null;
    rushingStatsYardsPerRush?: number | null;
    rushingStatsSnaps?: number | null;
    rushingStatsTeamSnaps?: number | null;
    rushingStatsSnapPercentage?: number | null;
    rushingStatsTwoPointAttempts?: number | null;
    rushingStatsTwoPointConversions?: number | null;
    returnStatsKickReturnYardsAverage?: number | null;
    returnStatsKickReturnAttempts?: number | null;
    returnStatsKickReturnYards?: number | null;
    returnStatsKickReturnTouchdowns?: number | null;
    returnStatsKickReturnsTwentyYardsPlus?: number | null;
    returnStatsKickReturnsFortyYardsPlus?: number | null;
    returnStatsKickReturnLongestYards?: number | null;
    returnStatsKickReturnFairCatches?: number | null;
    returnStatsKickReturnFumbles?: number | null;
    returnStatsKickReturnYardsAfterContact?: number | null;
    returnStatsKickReturnYardsAfterContactAverage?: number | null;
    returnStatsKickReturnSnaps?: number | null;
    returnStatsKickReturnTeamSnaps?: number | null;
    returnStatsKickReturnSnapPercentage?: number | null;
    returnStatsPuntReturnYardsAverage?: number | null;
    returnStatsPuntReturnAttempts?: number | null;
    returnStatsPuntReturnYards?: number | null;
    returnStatsPuntReturnTouchdowns?: number | null;
    returnStatsPuntReturnsTwentyYardsPlus?: number | null;
    returnStatsPuntReturnsFortyYardsPlus?: number | null;
    returnStatsPuntReturnLongestYards?: number | null;
    returnStatsPuntReturnFairCatches?: number | null;
    returnStatsPuntReturnFumbles?: number | null;
    returnStatsPuntReturnYardsAfterContact?: number | null;
    returnStatsPuntReturnYardsAfterContactAverage?: number | null;
    returnStatsPuntReturnSnaps?: number | null;
    returnStatsPuntReturnTeamSnaps?: number | null;
    returnStatsPuntReturnSnapPercentage?: number | null;
    receivingStatsReceptions?: number | null;
    receivingStatsTouchdowns?: number | null;
    receivingStatsYards?: number | null;
    receivingStatsYardsPerReception?: number | null;
    receivingStatsReceptionsTwentyYardsPlus?: number | null;
    receivingStatsReceptionsFortyYardsPlus?: number | null;
    receivingStatsLongestReception?: number | null;
    receivingStatsFirstDowns?: number | null;
    receivingStatsFirstDownPercentage?: number | null;
    receivingStatsReceivingFumbles?: number | null;
    receivingStatsYardsAfterCatch?: number | null;
    receivingStatsYardAfterCatchPerReception?: number | null;
    receivingStatsTargets?: number | null;
    receivingStatsTacklesAvoided?: number | null;
    receivingStatsDrops?: number | null;
    receivingStatsDropsPercentage?: number | null;
    receivingStatsYardsAfterContact?: number | null;
    receivingStatsInjuriesInGame?: number | null;
    receivingStatsPenalties?: number | null;
    receivingStatsCatchableThrows?: number | null;
    receivingStatsPassRouteDepthAverage?: number | null;
    receivingStatsSnaps?: number | null;
    receivingStatsTeamSnaps?: number | null;
    receivingStatsSnapPercentage?: number | null;
    receivingStatsPassRoutesRun?: number | null;
    receivingStatsReceptionsPerTouchdown?: number | null;
    receivingStatsTwoPointAttempts?: number | null;
    receivingStatsTwoPointConversions?: number | null;
    passingStatsYards?: number | null;
    passingStatsAttempts?: number | null;
    passingStatsCompletions?: number | null;
    passingStatsCompletionPercentage?: number | null;
    passingStatsYardsPerCompletionAverage?: number | null;
    passingStatsTouchdowns?: number | null;
    passingStatsTouchdownPercentage?: number | null;
    passingStatsInterceptions?: number | null;
    passingStatsInterceptionPercentage?: number | null;
    passingStatsPasserRating?: number | null;
    passingStatsFirstDowns?: number | null;
    passingStatsFirstDownPercentage?: number | null;
    passingStatsCompletionsTwentyYardsPlus?: number | null;
    passingStatsCompletionsFortyYardsPlus?: number | null;
    passingStatsLongestCompletion?: number | null;
    passingStatsSacks?: number | null;
    passingStatsSackYards?: number | null;
    passingStatsYardsAfterCatch?: number | null;
    passingStatsDrops?: number | null;
    passingStatsInjuriesInGame?: number | null;
    passingStatsFumbles?: number | null;
    passingStatsFumblesRecovered?: number | null;
    passingStatsHurriesAllowed?: number | null;
    passingStatsPassDepthAverage?: number | null;
    passingStatsPassDepthAttempts?: number | null;
    passingStatsCatchableThrows?: number | null;
    passingStatsCatchableThrowPercentage?: number | null;
    passingStatsPenalties?: number | null;
    passingStatsTimeToPressureAverage?: number | null;
    passingStatsTimeToPressureAttempts?: number | null;
    passingStatsTimeToThrowAverage?: number | null;
    passingStatsTimeToThrowAttempts?: number | null;
    passingStatsDropbackDepthAverage?: number | null;
    passingStatsDropbackAttempts?: number | null;
    passingStatsSnaps?: number | null;
    passingStatsTeamSnaps?: number | null;
    passingStatsSnapPercentage?: number | null;
    passingStatsPumpFakes?: number | null;
    passingStatsPassingEfficiency?: number | null;
    passingStatsYardsPerAttempt?: number | null;
    passingStatsTouchdownInterceptionRatio?: number | null;
    passingStatsTwoPointAttempts?: number | null;
    passingStatsTwoPointConversions?: number | null;
    defenseStatsFumblesForced?: number | null;
    defenseStatsTacklesSolo?: number | null;
    defenseStatsSacks?: number | null;
    defenseStatsSackPlays?: number | null;
    defenseStatsSackPercentage?: number | null;
    defenseStatsSnaps?: number | null;
    defenseStatsTeamSnaps?: number | null;
    defenseStatsSnapPercentage?: number | null;
    defenseStatsBattedPasses?: number | null;
    defenseStatsQbHits?: number | null;
    defenseStatsQbHitPercentage?: number | null;
    defenseStatsFumblesRecovered?: number | null;
    defenseStatsInjuriesInGame?: number | null;
    defenseStatsMissedTacklesOnQb?: number | null;
    defenseStatsPassBreakups?: number | null;
    defenseStatsStops?: number | null;
    defenseStatsTouchdowns?: number | null;
    defenseStatsPassRushAttempts?: number | null;
    defenseStatsCoverageAttempts?: number | null;
    defenseStatsBurns?: number | null;
    defenseStatsDefensiveTargets?: number | null;
    defenseStatsQbPressures?: number | null;
    defenseStatsFumblesRecoveredForTouchdown?: number | null;
    defenseStatsQbPressurePercentage?: number | null;
    defenseStatsQbHurries?: number | null;
    defenseStatsQbHurryPercentage?: number | null;
    defenseStatsMissedTackles?: number | null;
    defenseStatsPuntsBlocked?: number | null;
    defenseStatsFieldGoalsBlocked?: number | null;
    defenseStatsExtraPointsBlocked?: number | null;
    defenseStatsTacklesForLoss?: number | null;
    defenseStatsDroppedInterceptions?: number | null;
    defenseStatsInterceptions?: number | null;
    defenseStatsInterceptionTouchdowns?: number | null;
    defenseStatsInterceptionYards?: number | null;
    defenseStatsInterceptionLongestReturn?: number | null;
    defenseStatsInterceptionPercentage?: number | null;
    defenseStatsTacklesCombined?: number | null;
    defenseStatsTacklesAssisted?: number | null;
    kickingStatsFieldGoalsMade?: number | null;
    kickingStatsFieldGoalAttempts?: number | null;
    kickingStatsFieldGoalPercentage?: number | null;
    kickingStatsFieldGoals1To19Attempted?: number | null;
    kickingStatsFieldGoals1To19Made?: number | null;
    kickingStatsFieldGoals1To19Percentage?: number | null;
    kickingStatsFieldGoals20To29Attempted?: number | null;
    kickingStatsFieldGoals20To29Made?: number | null;
    kickingStatsFieldGoals20To29Percentage?: number | null;
    kickingStatsFieldGoals30To39Attempted?: number | null;
    kickingStatsFieldGoals30To39Made?: number | null;
    kickingStatsFieldGoals30To39Percentage?: number | null;
    kickingStatsFieldGoals40To49Attempted?: number | null;
    kickingStatsFieldGoals40To49Made?: number | null;
    kickingStatsFieldGoals40To49Percentage?: number | null;
    kickingStatsFieldGoals50To59Attempted?: number | null;
    kickingStatsFieldGoals50To59Made?: number | null;
    kickingStatsFieldGoals50To59Percentage?: number | null;
    kickingStatsFieldGoals60PlusAttempted?: number | null;
    kickingStatsFieldGoals60PlusMade?: number | null;
    kickingStatsFieldGoals60PlusPercentage?: number | null;
    kickingStatsFieldGoalMadeLongest?: number | null;
    kickingStatsFieldGoalsBlocked?: number | null;
    kickingStatsFieldGoalSnaps?: number | null;
    kickingStatsFieldGoalTeamSnaps?: number | null;
    kickingStatsFieldGoalSnapPercentage?: number | null;
    kickingStatsFieldGoalYardsAverage?: number | null;
    kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
    kickingStatsExtraPointAttempts?: number | null;
    kickingStatsExtraPointsMade?: number | null;
    kickingStatsExtraPointsBlocked?: number | null;
    kickingStatsExtraPointsPercentage?: number | null;
    kickingStatsPuntYardsAverage?: number | null;
    kickingStatsPuntYardsNetAverage?: number | null;
    kickingStatsPuntAttempts?: number | null;
    kickingStatsPuntYardsLongest?: number | null;
    kickingStatsPuntYards?: number | null;
    kickingStatsPuntsIn20?: number | null;
    kickingStatsPuntsOutOfBounds?: number | null;
    kickingStatsPuntsDowned?: number | null;
    kickingStatsPuntsTouchbacks?: number | null;
    kickingStatsPuntsFairCaught?: number | null;
    kickingStatsPuntsReturned?: number | null;
    kickingStatsPuntReturnYards?: number | null;
    kickingStatsPuntReturnTouchdowns?: number | null;
    kickingStatsPuntsBlocked?: number | null;
    kickingStatsPuntSnaps?: number | null;
    kickingStatsPuntTeamSnaps?: number | null;
    kickingStatsPuntSnapPercentage?: number | null;
    kickingStatsPuntReturnYardsAverage?: number | null;
    kickingStatsPuntYardsNet?: number | null;
    kickingStatsPuntHangTimeAverage?: number | null;
    kickingStatsPuntOperationTimeAverage?: number | null;
    kickingStatsKickoffAttempts?: number | null;
    kickingStatsKickoffYards?: number | null;
    kickingStatsKickoffReturnYards?: number | null;
    kickingStatsKickoffTouchbacks?: number | null;
    kickingStatsKickoffTouchbackPercentage?: number | null;
    kickingStatsKickoffsReturned?: number | null;
    kickingStatsKickoffReturnLong?: number | null;
    kickingStatsKickoffReturnYardsAverage?: number | null;
    kickingStatsKickoffOnsideKicks?: number | null;
    kickingStatsKickoffOnsideKicksRecovered?: number | null;
    kickingStatsKickoffsOutOfBounds?: number | null;
    kickingStatsKickoffReturnTouchdowns?: number | null;
    kickingStatsKickoffDepthAverage?: number | null;
    kickingStatsKickoffYardsAverage?: number | null;
    kickingStatsKickoffSnaps?: number | null;
    kickingStatsKickoffTeamSnaps?: number | null;
    kickingStatsKickoffSnapPercentage?: number | null;
    kickingStatsKickoffDepthAttempts?: number | null;
    offensiveLinePressuresAllowed?: number | null;
    offensiveLineSacksAllowed?: number | null;
    offensiveLineScreenBlocks?: number | null;
    penalties?: number | null;
  } | null;
};

export type GetGameStatsForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetGameStatsForPlayerQuery = {
  __typename?: "Query";
  gameStats: Array<{
    __typename?: "GameStat";
    season: number;
    gameId: string;
    teamId?: string | null;
    game: {
      __typename?: "Game";
      id: string;
      season: number;
      week?: number | null;
      venue?: string | null;
      homeTeamId?: string | null;
      homeTeam?: string | null;
      awayTeam?: string | null;
      homeTeamScore?: number | null;
      awayTeamScore?: number | null;
      kickoffTime?: string | null;
      seasonType: {
        __typename?: "SeasonType";
        id: number;
        abbreviation?: string | null;
        description?: string | null;
      };
      weekType: {
        __typename?: "WeekType";
        id: number;
        description?: string | null;
      };
      leagueType: {
        __typename?: "LeagueType";
        id: number;
        description?: string | null;
      };
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
    playerStat: {
      __typename?: "PlayerStat";
      rushingStatsYards?: number | null;
      rushingStatsAttempts?: number | null;
      rushingStatsTouchdowns?: number | null;
      rushingStatsRunsTwentyYardsPlus?: number | null;
      rushingStatsRunsFortyYardsPlus?: number | null;
      rushingStatsLongestRun?: number | null;
      rushingStatsFirstDownRushes?: number | null;
      rushingStatsFirstDownPercentage?: number | null;
      rushingStatsFumbles?: number | null;
      rushingStatsYardsAfterContact?: number | null;
      rushingStatsYardsAfterContactPerRush?: number | null;
      rushingStatsTacklesAvoided?: number | null;
      rushingStatsFumblesRecovered?: number | null;
      rushingStatsFumblesLost?: number | null;
      rushingStatsHurriesAllowed?: number | null;
      rushingStatsPenalties?: number | null;
      rushingStatsInjuriesInGame?: number | null;
      rushingStatsPressuresAllowed?: number | null;
      rushingStatsSacksAllowed?: number | null;
      rushingStatsYardsPerRush?: number | null;
      rushingStatsSnaps?: number | null;
      rushingStatsTeamSnaps?: number | null;
      rushingStatsSnapPercentage?: number | null;
      rushingStatsTwoPointAttempts?: number | null;
      rushingStatsTwoPointConversions?: number | null;
      returnStatsKickReturnYardsAverage?: number | null;
      returnStatsKickReturnAttempts?: number | null;
      returnStatsKickReturnYards?: number | null;
      returnStatsKickReturnTouchdowns?: number | null;
      returnStatsKickReturnsTwentyYardsPlus?: number | null;
      returnStatsKickReturnsFortyYardsPlus?: number | null;
      returnStatsKickReturnLongestYards?: number | null;
      returnStatsKickReturnFairCatches?: number | null;
      returnStatsKickReturnFumbles?: number | null;
      returnStatsKickReturnYardsAfterContact?: number | null;
      returnStatsKickReturnYardsAfterContactAverage?: number | null;
      returnStatsKickReturnSnaps?: number | null;
      returnStatsKickReturnTeamSnaps?: number | null;
      returnStatsKickReturnSnapPercentage?: number | null;
      returnStatsPuntReturnYardsAverage?: number | null;
      returnStatsPuntReturnAttempts?: number | null;
      returnStatsPuntReturnYards?: number | null;
      returnStatsPuntReturnTouchdowns?: number | null;
      returnStatsPuntReturnsTwentyYardsPlus?: number | null;
      returnStatsPuntReturnsFortyYardsPlus?: number | null;
      returnStatsPuntReturnLongestYards?: number | null;
      returnStatsPuntReturnFairCatches?: number | null;
      returnStatsPuntReturnFumbles?: number | null;
      returnStatsPuntReturnYardsAfterContact?: number | null;
      returnStatsPuntReturnYardsAfterContactAverage?: number | null;
      returnStatsPuntReturnSnaps?: number | null;
      returnStatsPuntReturnTeamSnaps?: number | null;
      returnStatsPuntReturnSnapPercentage?: number | null;
      receivingStatsReceptions?: number | null;
      receivingStatsTouchdowns?: number | null;
      receivingStatsYards?: number | null;
      receivingStatsYardsPerReception?: number | null;
      receivingStatsReceptionsTwentyYardsPlus?: number | null;
      receivingStatsReceptionsFortyYardsPlus?: number | null;
      receivingStatsLongestReception?: number | null;
      receivingStatsFirstDowns?: number | null;
      receivingStatsFirstDownPercentage?: number | null;
      receivingStatsReceivingFumbles?: number | null;
      receivingStatsYardsAfterCatch?: number | null;
      receivingStatsYardAfterCatchPerReception?: number | null;
      receivingStatsTargets?: number | null;
      receivingStatsTacklesAvoided?: number | null;
      receivingStatsDrops?: number | null;
      receivingStatsDropsPercentage?: number | null;
      receivingStatsYardsAfterContact?: number | null;
      receivingStatsInjuriesInGame?: number | null;
      receivingStatsPenalties?: number | null;
      receivingStatsCatchableThrows?: number | null;
      receivingStatsPassRouteDepthAverage?: number | null;
      receivingStatsSnaps?: number | null;
      receivingStatsTeamSnaps?: number | null;
      receivingStatsSnapPercentage?: number | null;
      receivingStatsPassRoutesRun?: number | null;
      receivingStatsReceptionsPerTouchdown?: number | null;
      receivingStatsTwoPointAttempts?: number | null;
      receivingStatsTwoPointConversions?: number | null;
      passingStatsYards?: number | null;
      passingStatsAttempts?: number | null;
      passingStatsCompletions?: number | null;
      passingStatsCompletionPercentage?: number | null;
      passingStatsYardsPerCompletionAverage?: number | null;
      passingStatsTouchdowns?: number | null;
      passingStatsTouchdownPercentage?: number | null;
      passingStatsInterceptions?: number | null;
      passingStatsInterceptionPercentage?: number | null;
      passingStatsPasserRating?: number | null;
      passingStatsFirstDowns?: number | null;
      passingStatsFirstDownPercentage?: number | null;
      passingStatsCompletionsTwentyYardsPlus?: number | null;
      passingStatsCompletionsFortyYardsPlus?: number | null;
      passingStatsLongestCompletion?: number | null;
      passingStatsSacks?: number | null;
      passingStatsSackYards?: number | null;
      passingStatsYardsAfterCatch?: number | null;
      passingStatsDrops?: number | null;
      passingStatsInjuriesInGame?: number | null;
      passingStatsFumbles?: number | null;
      passingStatsFumblesRecovered?: number | null;
      passingStatsHurriesAllowed?: number | null;
      passingStatsPassDepthAverage?: number | null;
      passingStatsPassDepthAttempts?: number | null;
      passingStatsCatchableThrows?: number | null;
      passingStatsCatchableThrowPercentage?: number | null;
      passingStatsPenalties?: number | null;
      passingStatsTimeToPressureAverage?: number | null;
      passingStatsTimeToPressureAttempts?: number | null;
      passingStatsTimeToThrowAverage?: number | null;
      passingStatsTimeToThrowAttempts?: number | null;
      passingStatsDropbackDepthAverage?: number | null;
      passingStatsDropbackAttempts?: number | null;
      passingStatsSnaps?: number | null;
      passingStatsTeamSnaps?: number | null;
      passingStatsSnapPercentage?: number | null;
      passingStatsPumpFakes?: number | null;
      passingStatsPassingEfficiency?: number | null;
      passingStatsYardsPerAttempt?: number | null;
      passingStatsTouchdownInterceptionRatio?: number | null;
      passingStatsTwoPointAttempts?: number | null;
      passingStatsTwoPointConversions?: number | null;
      defenseStatsFumblesForced?: number | null;
      defenseStatsTacklesSolo?: number | null;
      defenseStatsSacks?: number | null;
      defenseStatsSackPlays?: number | null;
      defenseStatsSackPercentage?: number | null;
      defenseStatsSnaps?: number | null;
      defenseStatsTeamSnaps?: number | null;
      defenseStatsSnapPercentage?: number | null;
      defenseStatsBattedPasses?: number | null;
      defenseStatsQbHits?: number | null;
      defenseStatsQbHitPercentage?: number | null;
      defenseStatsFumblesRecovered?: number | null;
      defenseStatsInjuriesInGame?: number | null;
      defenseStatsMissedTacklesOnQb?: number | null;
      defenseStatsPassBreakups?: number | null;
      defenseStatsStops?: number | null;
      defenseStatsTouchdowns?: number | null;
      defenseStatsPassRushAttempts?: number | null;
      defenseStatsCoverageAttempts?: number | null;
      defenseStatsBurns?: number | null;
      defenseStatsDefensiveTargets?: number | null;
      defenseStatsQbPressures?: number | null;
      defenseStatsFumblesRecoveredForTouchdown?: number | null;
      defenseStatsQbPressurePercentage?: number | null;
      defenseStatsQbHurries?: number | null;
      defenseStatsQbHurryPercentage?: number | null;
      defenseStatsMissedTackles?: number | null;
      defenseStatsPuntsBlocked?: number | null;
      defenseStatsFieldGoalsBlocked?: number | null;
      defenseStatsExtraPointsBlocked?: number | null;
      defenseStatsTacklesForLoss?: number | null;
      defenseStatsDroppedInterceptions?: number | null;
      defenseStatsInterceptions?: number | null;
      defenseStatsInterceptionTouchdowns?: number | null;
      defenseStatsInterceptionYards?: number | null;
      defenseStatsInterceptionLongestReturn?: number | null;
      defenseStatsInterceptionPercentage?: number | null;
      defenseStatsTacklesCombined?: number | null;
      defenseStatsTacklesAssisted?: number | null;
      kickingStatsFieldGoalsMade?: number | null;
      kickingStatsFieldGoalAttempts?: number | null;
      kickingStatsFieldGoalPercentage?: number | null;
      kickingStatsFieldGoals1To19Attempted?: number | null;
      kickingStatsFieldGoals1To19Made?: number | null;
      kickingStatsFieldGoals1To19Percentage?: number | null;
      kickingStatsFieldGoals20To29Attempted?: number | null;
      kickingStatsFieldGoals20To29Made?: number | null;
      kickingStatsFieldGoals20To29Percentage?: number | null;
      kickingStatsFieldGoals30To39Attempted?: number | null;
      kickingStatsFieldGoals30To39Made?: number | null;
      kickingStatsFieldGoals30To39Percentage?: number | null;
      kickingStatsFieldGoals40To49Attempted?: number | null;
      kickingStatsFieldGoals40To49Made?: number | null;
      kickingStatsFieldGoals40To49Percentage?: number | null;
      kickingStatsFieldGoals50To59Attempted?: number | null;
      kickingStatsFieldGoals50To59Made?: number | null;
      kickingStatsFieldGoals50To59Percentage?: number | null;
      kickingStatsFieldGoals60PlusAttempted?: number | null;
      kickingStatsFieldGoals60PlusMade?: number | null;
      kickingStatsFieldGoals60PlusPercentage?: number | null;
      kickingStatsFieldGoalMadeLongest?: number | null;
      kickingStatsFieldGoalsBlocked?: number | null;
      kickingStatsFieldGoalSnaps?: number | null;
      kickingStatsFieldGoalTeamSnaps?: number | null;
      kickingStatsFieldGoalSnapPercentage?: number | null;
      kickingStatsFieldGoalYardsAverage?: number | null;
      kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
      kickingStatsExtraPointAttempts?: number | null;
      kickingStatsExtraPointsMade?: number | null;
      kickingStatsExtraPointsBlocked?: number | null;
      kickingStatsExtraPointsPercentage?: number | null;
      kickingStatsPuntYardsAverage?: number | null;
      kickingStatsPuntYardsNetAverage?: number | null;
      kickingStatsPuntAttempts?: number | null;
      kickingStatsPuntYardsLongest?: number | null;
      kickingStatsPuntYards?: number | null;
      kickingStatsPuntsIn20?: number | null;
      kickingStatsPuntsOutOfBounds?: number | null;
      kickingStatsPuntsDowned?: number | null;
      kickingStatsPuntsTouchbacks?: number | null;
      kickingStatsPuntsFairCaught?: number | null;
      kickingStatsPuntsReturned?: number | null;
      kickingStatsPuntReturnYards?: number | null;
      kickingStatsPuntReturnTouchdowns?: number | null;
      kickingStatsPuntsBlocked?: number | null;
      kickingStatsPuntSnaps?: number | null;
      kickingStatsPuntTeamSnaps?: number | null;
      kickingStatsPuntSnapPercentage?: number | null;
      kickingStatsPuntReturnYardsAverage?: number | null;
      kickingStatsPuntYardsNet?: number | null;
      kickingStatsPuntHangTimeAverage?: number | null;
      kickingStatsPuntOperationTimeAverage?: number | null;
      kickingStatsKickoffAttempts?: number | null;
      kickingStatsKickoffYards?: number | null;
      kickingStatsKickoffReturnYards?: number | null;
      kickingStatsKickoffTouchbacks?: number | null;
      kickingStatsKickoffTouchbackPercentage?: number | null;
      kickingStatsKickoffsReturned?: number | null;
      kickingStatsKickoffReturnLong?: number | null;
      kickingStatsKickoffReturnYardsAverage?: number | null;
      kickingStatsKickoffOnsideKicks?: number | null;
      kickingStatsKickoffOnsideKicksRecovered?: number | null;
      kickingStatsKickoffsOutOfBounds?: number | null;
      kickingStatsKickoffReturnTouchdowns?: number | null;
      kickingStatsKickoffDepthAverage?: number | null;
      kickingStatsKickoffYardsAverage?: number | null;
      kickingStatsKickoffSnaps?: number | null;
      kickingStatsKickoffTeamSnaps?: number | null;
      kickingStatsKickoffSnapPercentage?: number | null;
      kickingStatsKickoffDepthAttempts?: number | null;
      offensiveLinePressuresAllowed?: number | null;
      offensiveLineSacksAllowed?: number | null;
      offensiveLineScreenBlocks?: number | null;
      penalties?: number | null;
    };
  }>;
};

export type GetPlayHistoryForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetPlayHistoryForPlayerQuery = {
  __typename?: "Query";
  gameStats: Array<{
    __typename?: "GameStat";
    season: number;
    teamId?: string | null;
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    playerStat: {
      __typename?: "PlayerStat";
      gamesPlayed?: number | null;
      gamesStarted?: number | null;
      offensiveSnapsPercentage?: number | null;
      defensiveSnapsPercentage?: number | null;
      specialTeamsSnapsPercentage?: number | null;
    };
    game: { __typename?: "Game"; kickoffTime?: string | null };
  }>;
};

export type GetGamesMissedQueryQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetGamesMissedQueryQuery = {
  __typename?: "Query";
  playerStats: Array<{
    __typename?: "PlayerStat";
    season: number;
    gamesMissed?: number | null;
  }>;
};

export type GetCareerOffensiveLineStatsQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetCareerOffensiveLineStatsQuery = {
  __typename?: "Query";
  playerStats: Array<{
    __typename?: "PlayerStat";
    seasonTypeId: number;
    leagueTypeId: number;
    offensiveLinePressuresAllowed?: number | null;
    offensiveLineSacksAllowed?: number | null;
    offensiveLineScreenBlocks?: number | null;
    penalties?: number | null;
  }>;
};

export type OffensiveLineStatsFragment = {
  __typename?: "PlayerStat";
  seasonTypeId: number;
  leagueTypeId: number;
  offensiveLinePressuresAllowed?: number | null;
  offensiveLineSacksAllowed?: number | null;
  offensiveLineScreenBlocks?: number | null;
  penalties?: number | null;
};

export type GamesMissedFragment = {
  __typename?: "PlayerStat";
  season: number;
  gamesMissed?: number | null;
};

export type PlayHistoryStatFragment = {
  __typename?: "PlayerStat";
  gamesPlayed?: number | null;
  gamesStarted?: number | null;
  offensiveSnapsPercentage?: number | null;
  defensiveSnapsPercentage?: number | null;
  specialTeamsSnapsPercentage?: number | null;
};

export type PlayHistoryFragment = {
  __typename?: "GameStat";
  season: number;
  teamId?: string | null;
  leagueType: {
    __typename?: "LeagueType";
    id: number;
    description?: string | null;
  };
  seasonType: {
    __typename?: "SeasonType";
    id: number;
    abbreviation?: string | null;
    description?: string | null;
  };
  playerStat: {
    __typename?: "PlayerStat";
    gamesPlayed?: number | null;
    gamesStarted?: number | null;
    offensiveSnapsPercentage?: number | null;
    defensiveSnapsPercentage?: number | null;
    specialTeamsSnapsPercentage?: number | null;
  };
  game: { __typename?: "Game"; kickoffTime?: string | null };
};

export type GameStatsFragment = {
  __typename?: "GameStat";
  season: number;
  gameId: string;
  teamId?: string | null;
  game: {
    __typename?: "Game";
    id: string;
    season: number;
    week?: number | null;
    venue?: string | null;
    homeTeamId?: string | null;
    homeTeam?: string | null;
    awayTeam?: string | null;
    homeTeamScore?: number | null;
    awayTeamScore?: number | null;
    kickoffTime?: string | null;
    seasonType: {
      __typename?: "SeasonType";
      id: number;
      abbreviation?: string | null;
      description?: string | null;
    };
    weekType: {
      __typename?: "WeekType";
      id: number;
      description?: string | null;
    };
    leagueType: {
      __typename?: "LeagueType";
      id: number;
      description?: string | null;
    };
  };
  weekType: {
    __typename?: "WeekType";
    id: number;
    description?: string | null;
  };
  seasonType: {
    __typename?: "SeasonType";
    id: number;
    abbreviation?: string | null;
    description?: string | null;
  };
  leagueType: {
    __typename?: "LeagueType";
    id: number;
    description?: string | null;
  };
  playerStat: {
    __typename?: "PlayerStat";
    rushingStatsYards?: number | null;
    rushingStatsAttempts?: number | null;
    rushingStatsTouchdowns?: number | null;
    rushingStatsRunsTwentyYardsPlus?: number | null;
    rushingStatsRunsFortyYardsPlus?: number | null;
    rushingStatsLongestRun?: number | null;
    rushingStatsFirstDownRushes?: number | null;
    rushingStatsFirstDownPercentage?: number | null;
    rushingStatsFumbles?: number | null;
    rushingStatsYardsAfterContact?: number | null;
    rushingStatsYardsAfterContactPerRush?: number | null;
    rushingStatsTacklesAvoided?: number | null;
    rushingStatsFumblesRecovered?: number | null;
    rushingStatsFumblesLost?: number | null;
    rushingStatsHurriesAllowed?: number | null;
    rushingStatsPenalties?: number | null;
    rushingStatsInjuriesInGame?: number | null;
    rushingStatsPressuresAllowed?: number | null;
    rushingStatsSacksAllowed?: number | null;
    rushingStatsYardsPerRush?: number | null;
    rushingStatsSnaps?: number | null;
    rushingStatsTeamSnaps?: number | null;
    rushingStatsSnapPercentage?: number | null;
    rushingStatsTwoPointAttempts?: number | null;
    rushingStatsTwoPointConversions?: number | null;
    returnStatsKickReturnYardsAverage?: number | null;
    returnStatsKickReturnAttempts?: number | null;
    returnStatsKickReturnYards?: number | null;
    returnStatsKickReturnTouchdowns?: number | null;
    returnStatsKickReturnsTwentyYardsPlus?: number | null;
    returnStatsKickReturnsFortyYardsPlus?: number | null;
    returnStatsKickReturnLongestYards?: number | null;
    returnStatsKickReturnFairCatches?: number | null;
    returnStatsKickReturnFumbles?: number | null;
    returnStatsKickReturnYardsAfterContact?: number | null;
    returnStatsKickReturnYardsAfterContactAverage?: number | null;
    returnStatsKickReturnSnaps?: number | null;
    returnStatsKickReturnTeamSnaps?: number | null;
    returnStatsKickReturnSnapPercentage?: number | null;
    returnStatsPuntReturnYardsAverage?: number | null;
    returnStatsPuntReturnAttempts?: number | null;
    returnStatsPuntReturnYards?: number | null;
    returnStatsPuntReturnTouchdowns?: number | null;
    returnStatsPuntReturnsTwentyYardsPlus?: number | null;
    returnStatsPuntReturnsFortyYardsPlus?: number | null;
    returnStatsPuntReturnLongestYards?: number | null;
    returnStatsPuntReturnFairCatches?: number | null;
    returnStatsPuntReturnFumbles?: number | null;
    returnStatsPuntReturnYardsAfterContact?: number | null;
    returnStatsPuntReturnYardsAfterContactAverage?: number | null;
    returnStatsPuntReturnSnaps?: number | null;
    returnStatsPuntReturnTeamSnaps?: number | null;
    returnStatsPuntReturnSnapPercentage?: number | null;
    receivingStatsReceptions?: number | null;
    receivingStatsTouchdowns?: number | null;
    receivingStatsYards?: number | null;
    receivingStatsYardsPerReception?: number | null;
    receivingStatsReceptionsTwentyYardsPlus?: number | null;
    receivingStatsReceptionsFortyYardsPlus?: number | null;
    receivingStatsLongestReception?: number | null;
    receivingStatsFirstDowns?: number | null;
    receivingStatsFirstDownPercentage?: number | null;
    receivingStatsReceivingFumbles?: number | null;
    receivingStatsYardsAfterCatch?: number | null;
    receivingStatsYardAfterCatchPerReception?: number | null;
    receivingStatsTargets?: number | null;
    receivingStatsTacklesAvoided?: number | null;
    receivingStatsDrops?: number | null;
    receivingStatsDropsPercentage?: number | null;
    receivingStatsYardsAfterContact?: number | null;
    receivingStatsInjuriesInGame?: number | null;
    receivingStatsPenalties?: number | null;
    receivingStatsCatchableThrows?: number | null;
    receivingStatsPassRouteDepthAverage?: number | null;
    receivingStatsSnaps?: number | null;
    receivingStatsTeamSnaps?: number | null;
    receivingStatsSnapPercentage?: number | null;
    receivingStatsPassRoutesRun?: number | null;
    receivingStatsReceptionsPerTouchdown?: number | null;
    receivingStatsTwoPointAttempts?: number | null;
    receivingStatsTwoPointConversions?: number | null;
    passingStatsYards?: number | null;
    passingStatsAttempts?: number | null;
    passingStatsCompletions?: number | null;
    passingStatsCompletionPercentage?: number | null;
    passingStatsYardsPerCompletionAverage?: number | null;
    passingStatsTouchdowns?: number | null;
    passingStatsTouchdownPercentage?: number | null;
    passingStatsInterceptions?: number | null;
    passingStatsInterceptionPercentage?: number | null;
    passingStatsPasserRating?: number | null;
    passingStatsFirstDowns?: number | null;
    passingStatsFirstDownPercentage?: number | null;
    passingStatsCompletionsTwentyYardsPlus?: number | null;
    passingStatsCompletionsFortyYardsPlus?: number | null;
    passingStatsLongestCompletion?: number | null;
    passingStatsSacks?: number | null;
    passingStatsSackYards?: number | null;
    passingStatsYardsAfterCatch?: number | null;
    passingStatsDrops?: number | null;
    passingStatsInjuriesInGame?: number | null;
    passingStatsFumbles?: number | null;
    passingStatsFumblesRecovered?: number | null;
    passingStatsHurriesAllowed?: number | null;
    passingStatsPassDepthAverage?: number | null;
    passingStatsPassDepthAttempts?: number | null;
    passingStatsCatchableThrows?: number | null;
    passingStatsCatchableThrowPercentage?: number | null;
    passingStatsPenalties?: number | null;
    passingStatsTimeToPressureAverage?: number | null;
    passingStatsTimeToPressureAttempts?: number | null;
    passingStatsTimeToThrowAverage?: number | null;
    passingStatsTimeToThrowAttempts?: number | null;
    passingStatsDropbackDepthAverage?: number | null;
    passingStatsDropbackAttempts?: number | null;
    passingStatsSnaps?: number | null;
    passingStatsTeamSnaps?: number | null;
    passingStatsSnapPercentage?: number | null;
    passingStatsPumpFakes?: number | null;
    passingStatsPassingEfficiency?: number | null;
    passingStatsYardsPerAttempt?: number | null;
    passingStatsTouchdownInterceptionRatio?: number | null;
    passingStatsTwoPointAttempts?: number | null;
    passingStatsTwoPointConversions?: number | null;
    defenseStatsFumblesForced?: number | null;
    defenseStatsTacklesSolo?: number | null;
    defenseStatsSacks?: number | null;
    defenseStatsSackPlays?: number | null;
    defenseStatsSackPercentage?: number | null;
    defenseStatsSnaps?: number | null;
    defenseStatsTeamSnaps?: number | null;
    defenseStatsSnapPercentage?: number | null;
    defenseStatsBattedPasses?: number | null;
    defenseStatsQbHits?: number | null;
    defenseStatsQbHitPercentage?: number | null;
    defenseStatsFumblesRecovered?: number | null;
    defenseStatsInjuriesInGame?: number | null;
    defenseStatsMissedTacklesOnQb?: number | null;
    defenseStatsPassBreakups?: number | null;
    defenseStatsStops?: number | null;
    defenseStatsTouchdowns?: number | null;
    defenseStatsPassRushAttempts?: number | null;
    defenseStatsCoverageAttempts?: number | null;
    defenseStatsBurns?: number | null;
    defenseStatsDefensiveTargets?: number | null;
    defenseStatsQbPressures?: number | null;
    defenseStatsFumblesRecoveredForTouchdown?: number | null;
    defenseStatsQbPressurePercentage?: number | null;
    defenseStatsQbHurries?: number | null;
    defenseStatsQbHurryPercentage?: number | null;
    defenseStatsMissedTackles?: number | null;
    defenseStatsPuntsBlocked?: number | null;
    defenseStatsFieldGoalsBlocked?: number | null;
    defenseStatsExtraPointsBlocked?: number | null;
    defenseStatsTacklesForLoss?: number | null;
    defenseStatsDroppedInterceptions?: number | null;
    defenseStatsInterceptions?: number | null;
    defenseStatsInterceptionTouchdowns?: number | null;
    defenseStatsInterceptionYards?: number | null;
    defenseStatsInterceptionLongestReturn?: number | null;
    defenseStatsInterceptionPercentage?: number | null;
    defenseStatsTacklesCombined?: number | null;
    defenseStatsTacklesAssisted?: number | null;
    kickingStatsFieldGoalsMade?: number | null;
    kickingStatsFieldGoalAttempts?: number | null;
    kickingStatsFieldGoalPercentage?: number | null;
    kickingStatsFieldGoals1To19Attempted?: number | null;
    kickingStatsFieldGoals1To19Made?: number | null;
    kickingStatsFieldGoals1To19Percentage?: number | null;
    kickingStatsFieldGoals20To29Attempted?: number | null;
    kickingStatsFieldGoals20To29Made?: number | null;
    kickingStatsFieldGoals20To29Percentage?: number | null;
    kickingStatsFieldGoals30To39Attempted?: number | null;
    kickingStatsFieldGoals30To39Made?: number | null;
    kickingStatsFieldGoals30To39Percentage?: number | null;
    kickingStatsFieldGoals40To49Attempted?: number | null;
    kickingStatsFieldGoals40To49Made?: number | null;
    kickingStatsFieldGoals40To49Percentage?: number | null;
    kickingStatsFieldGoals50To59Attempted?: number | null;
    kickingStatsFieldGoals50To59Made?: number | null;
    kickingStatsFieldGoals50To59Percentage?: number | null;
    kickingStatsFieldGoals60PlusAttempted?: number | null;
    kickingStatsFieldGoals60PlusMade?: number | null;
    kickingStatsFieldGoals60PlusPercentage?: number | null;
    kickingStatsFieldGoalMadeLongest?: number | null;
    kickingStatsFieldGoalsBlocked?: number | null;
    kickingStatsFieldGoalSnaps?: number | null;
    kickingStatsFieldGoalTeamSnaps?: number | null;
    kickingStatsFieldGoalSnapPercentage?: number | null;
    kickingStatsFieldGoalYardsAverage?: number | null;
    kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
    kickingStatsExtraPointAttempts?: number | null;
    kickingStatsExtraPointsMade?: number | null;
    kickingStatsExtraPointsBlocked?: number | null;
    kickingStatsExtraPointsPercentage?: number | null;
    kickingStatsPuntYardsAverage?: number | null;
    kickingStatsPuntYardsNetAverage?: number | null;
    kickingStatsPuntAttempts?: number | null;
    kickingStatsPuntYardsLongest?: number | null;
    kickingStatsPuntYards?: number | null;
    kickingStatsPuntsIn20?: number | null;
    kickingStatsPuntsOutOfBounds?: number | null;
    kickingStatsPuntsDowned?: number | null;
    kickingStatsPuntsTouchbacks?: number | null;
    kickingStatsPuntsFairCaught?: number | null;
    kickingStatsPuntsReturned?: number | null;
    kickingStatsPuntReturnYards?: number | null;
    kickingStatsPuntReturnTouchdowns?: number | null;
    kickingStatsPuntsBlocked?: number | null;
    kickingStatsPuntSnaps?: number | null;
    kickingStatsPuntTeamSnaps?: number | null;
    kickingStatsPuntSnapPercentage?: number | null;
    kickingStatsPuntReturnYardsAverage?: number | null;
    kickingStatsPuntYardsNet?: number | null;
    kickingStatsPuntHangTimeAverage?: number | null;
    kickingStatsPuntOperationTimeAverage?: number | null;
    kickingStatsKickoffAttempts?: number | null;
    kickingStatsKickoffYards?: number | null;
    kickingStatsKickoffReturnYards?: number | null;
    kickingStatsKickoffTouchbacks?: number | null;
    kickingStatsKickoffTouchbackPercentage?: number | null;
    kickingStatsKickoffsReturned?: number | null;
    kickingStatsKickoffReturnLong?: number | null;
    kickingStatsKickoffReturnYardsAverage?: number | null;
    kickingStatsKickoffOnsideKicks?: number | null;
    kickingStatsKickoffOnsideKicksRecovered?: number | null;
    kickingStatsKickoffsOutOfBounds?: number | null;
    kickingStatsKickoffReturnTouchdowns?: number | null;
    kickingStatsKickoffDepthAverage?: number | null;
    kickingStatsKickoffYardsAverage?: number | null;
    kickingStatsKickoffSnaps?: number | null;
    kickingStatsKickoffTeamSnaps?: number | null;
    kickingStatsKickoffSnapPercentage?: number | null;
    kickingStatsKickoffDepthAttempts?: number | null;
    offensiveLinePressuresAllowed?: number | null;
    offensiveLineSacksAllowed?: number | null;
    offensiveLineScreenBlocks?: number | null;
    penalties?: number | null;
  };
};

export type LeagueTypeFragment = {
  __typename?: "LeagueType";
  id: number;
  description?: string | null;
};

export type SeasonTypeFragment = {
  __typename?: "SeasonType";
  id: number;
  abbreviation?: string | null;
  description?: string | null;
};

export type StatsFragment = {
  __typename?: "PlayerStat";
  rushingStatsYards?: number | null;
  rushingStatsAttempts?: number | null;
  rushingStatsTouchdowns?: number | null;
  rushingStatsRunsTwentyYardsPlus?: number | null;
  rushingStatsRunsFortyYardsPlus?: number | null;
  rushingStatsLongestRun?: number | null;
  rushingStatsFirstDownRushes?: number | null;
  rushingStatsFirstDownPercentage?: number | null;
  rushingStatsFumbles?: number | null;
  rushingStatsYardsAfterContact?: number | null;
  rushingStatsYardsAfterContactPerRush?: number | null;
  rushingStatsTacklesAvoided?: number | null;
  rushingStatsFumblesRecovered?: number | null;
  rushingStatsFumblesLost?: number | null;
  rushingStatsHurriesAllowed?: number | null;
  rushingStatsPenalties?: number | null;
  rushingStatsInjuriesInGame?: number | null;
  rushingStatsPressuresAllowed?: number | null;
  rushingStatsSacksAllowed?: number | null;
  rushingStatsYardsPerRush?: number | null;
  rushingStatsSnaps?: number | null;
  rushingStatsTeamSnaps?: number | null;
  rushingStatsSnapPercentage?: number | null;
  rushingStatsTwoPointAttempts?: number | null;
  rushingStatsTwoPointConversions?: number | null;
  returnStatsKickReturnYardsAverage?: number | null;
  returnStatsKickReturnAttempts?: number | null;
  returnStatsKickReturnYards?: number | null;
  returnStatsKickReturnTouchdowns?: number | null;
  returnStatsKickReturnsTwentyYardsPlus?: number | null;
  returnStatsKickReturnsFortyYardsPlus?: number | null;
  returnStatsKickReturnLongestYards?: number | null;
  returnStatsKickReturnFairCatches?: number | null;
  returnStatsKickReturnFumbles?: number | null;
  returnStatsKickReturnYardsAfterContact?: number | null;
  returnStatsKickReturnYardsAfterContactAverage?: number | null;
  returnStatsKickReturnSnaps?: number | null;
  returnStatsKickReturnTeamSnaps?: number | null;
  returnStatsKickReturnSnapPercentage?: number | null;
  returnStatsPuntReturnYardsAverage?: number | null;
  returnStatsPuntReturnAttempts?: number | null;
  returnStatsPuntReturnYards?: number | null;
  returnStatsPuntReturnTouchdowns?: number | null;
  returnStatsPuntReturnsTwentyYardsPlus?: number | null;
  returnStatsPuntReturnsFortyYardsPlus?: number | null;
  returnStatsPuntReturnLongestYards?: number | null;
  returnStatsPuntReturnFairCatches?: number | null;
  returnStatsPuntReturnFumbles?: number | null;
  returnStatsPuntReturnYardsAfterContact?: number | null;
  returnStatsPuntReturnYardsAfterContactAverage?: number | null;
  returnStatsPuntReturnSnaps?: number | null;
  returnStatsPuntReturnTeamSnaps?: number | null;
  returnStatsPuntReturnSnapPercentage?: number | null;
  receivingStatsReceptions?: number | null;
  receivingStatsTouchdowns?: number | null;
  receivingStatsYards?: number | null;
  receivingStatsYardsPerReception?: number | null;
  receivingStatsReceptionsTwentyYardsPlus?: number | null;
  receivingStatsReceptionsFortyYardsPlus?: number | null;
  receivingStatsLongestReception?: number | null;
  receivingStatsFirstDowns?: number | null;
  receivingStatsFirstDownPercentage?: number | null;
  receivingStatsReceivingFumbles?: number | null;
  receivingStatsYardsAfterCatch?: number | null;
  receivingStatsYardAfterCatchPerReception?: number | null;
  receivingStatsTargets?: number | null;
  receivingStatsTacklesAvoided?: number | null;
  receivingStatsDrops?: number | null;
  receivingStatsDropsPercentage?: number | null;
  receivingStatsYardsAfterContact?: number | null;
  receivingStatsInjuriesInGame?: number | null;
  receivingStatsPenalties?: number | null;
  receivingStatsCatchableThrows?: number | null;
  receivingStatsPassRouteDepthAverage?: number | null;
  receivingStatsSnaps?: number | null;
  receivingStatsTeamSnaps?: number | null;
  receivingStatsSnapPercentage?: number | null;
  receivingStatsPassRoutesRun?: number | null;
  receivingStatsReceptionsPerTouchdown?: number | null;
  receivingStatsTwoPointAttempts?: number | null;
  receivingStatsTwoPointConversions?: number | null;
  passingStatsYards?: number | null;
  passingStatsAttempts?: number | null;
  passingStatsCompletions?: number | null;
  passingStatsCompletionPercentage?: number | null;
  passingStatsYardsPerCompletionAverage?: number | null;
  passingStatsTouchdowns?: number | null;
  passingStatsTouchdownPercentage?: number | null;
  passingStatsInterceptions?: number | null;
  passingStatsInterceptionPercentage?: number | null;
  passingStatsPasserRating?: number | null;
  passingStatsFirstDowns?: number | null;
  passingStatsFirstDownPercentage?: number | null;
  passingStatsCompletionsTwentyYardsPlus?: number | null;
  passingStatsCompletionsFortyYardsPlus?: number | null;
  passingStatsLongestCompletion?: number | null;
  passingStatsSacks?: number | null;
  passingStatsSackYards?: number | null;
  passingStatsYardsAfterCatch?: number | null;
  passingStatsDrops?: number | null;
  passingStatsInjuriesInGame?: number | null;
  passingStatsFumbles?: number | null;
  passingStatsFumblesRecovered?: number | null;
  passingStatsHurriesAllowed?: number | null;
  passingStatsPassDepthAverage?: number | null;
  passingStatsPassDepthAttempts?: number | null;
  passingStatsCatchableThrows?: number | null;
  passingStatsCatchableThrowPercentage?: number | null;
  passingStatsPenalties?: number | null;
  passingStatsTimeToPressureAverage?: number | null;
  passingStatsTimeToPressureAttempts?: number | null;
  passingStatsTimeToThrowAverage?: number | null;
  passingStatsTimeToThrowAttempts?: number | null;
  passingStatsDropbackDepthAverage?: number | null;
  passingStatsDropbackAttempts?: number | null;
  passingStatsSnaps?: number | null;
  passingStatsTeamSnaps?: number | null;
  passingStatsSnapPercentage?: number | null;
  passingStatsPumpFakes?: number | null;
  passingStatsPassingEfficiency?: number | null;
  passingStatsYardsPerAttempt?: number | null;
  passingStatsTouchdownInterceptionRatio?: number | null;
  passingStatsTwoPointAttempts?: number | null;
  passingStatsTwoPointConversions?: number | null;
  defenseStatsFumblesForced?: number | null;
  defenseStatsTacklesSolo?: number | null;
  defenseStatsSacks?: number | null;
  defenseStatsSackPlays?: number | null;
  defenseStatsSackPercentage?: number | null;
  defenseStatsSnaps?: number | null;
  defenseStatsTeamSnaps?: number | null;
  defenseStatsSnapPercentage?: number | null;
  defenseStatsBattedPasses?: number | null;
  defenseStatsQbHits?: number | null;
  defenseStatsQbHitPercentage?: number | null;
  defenseStatsFumblesRecovered?: number | null;
  defenseStatsInjuriesInGame?: number | null;
  defenseStatsMissedTacklesOnQb?: number | null;
  defenseStatsPassBreakups?: number | null;
  defenseStatsStops?: number | null;
  defenseStatsTouchdowns?: number | null;
  defenseStatsPassRushAttempts?: number | null;
  defenseStatsCoverageAttempts?: number | null;
  defenseStatsBurns?: number | null;
  defenseStatsDefensiveTargets?: number | null;
  defenseStatsQbPressures?: number | null;
  defenseStatsFumblesRecoveredForTouchdown?: number | null;
  defenseStatsQbPressurePercentage?: number | null;
  defenseStatsQbHurries?: number | null;
  defenseStatsQbHurryPercentage?: number | null;
  defenseStatsMissedTackles?: number | null;
  defenseStatsPuntsBlocked?: number | null;
  defenseStatsFieldGoalsBlocked?: number | null;
  defenseStatsExtraPointsBlocked?: number | null;
  defenseStatsTacklesForLoss?: number | null;
  defenseStatsDroppedInterceptions?: number | null;
  defenseStatsInterceptions?: number | null;
  defenseStatsInterceptionTouchdowns?: number | null;
  defenseStatsInterceptionYards?: number | null;
  defenseStatsInterceptionLongestReturn?: number | null;
  defenseStatsInterceptionPercentage?: number | null;
  defenseStatsTacklesCombined?: number | null;
  defenseStatsTacklesAssisted?: number | null;
  kickingStatsFieldGoalsMade?: number | null;
  kickingStatsFieldGoalAttempts?: number | null;
  kickingStatsFieldGoalPercentage?: number | null;
  kickingStatsFieldGoals1To19Attempted?: number | null;
  kickingStatsFieldGoals1To19Made?: number | null;
  kickingStatsFieldGoals1To19Percentage?: number | null;
  kickingStatsFieldGoals20To29Attempted?: number | null;
  kickingStatsFieldGoals20To29Made?: number | null;
  kickingStatsFieldGoals20To29Percentage?: number | null;
  kickingStatsFieldGoals30To39Attempted?: number | null;
  kickingStatsFieldGoals30To39Made?: number | null;
  kickingStatsFieldGoals30To39Percentage?: number | null;
  kickingStatsFieldGoals40To49Attempted?: number | null;
  kickingStatsFieldGoals40To49Made?: number | null;
  kickingStatsFieldGoals40To49Percentage?: number | null;
  kickingStatsFieldGoals50To59Attempted?: number | null;
  kickingStatsFieldGoals50To59Made?: number | null;
  kickingStatsFieldGoals50To59Percentage?: number | null;
  kickingStatsFieldGoals60PlusAttempted?: number | null;
  kickingStatsFieldGoals60PlusMade?: number | null;
  kickingStatsFieldGoals60PlusPercentage?: number | null;
  kickingStatsFieldGoalMadeLongest?: number | null;
  kickingStatsFieldGoalsBlocked?: number | null;
  kickingStatsFieldGoalSnaps?: number | null;
  kickingStatsFieldGoalTeamSnaps?: number | null;
  kickingStatsFieldGoalSnapPercentage?: number | null;
  kickingStatsFieldGoalYardsAverage?: number | null;
  kickingStatsBlockedFieldGoalsAndExtraPoints?: number | null;
  kickingStatsExtraPointAttempts?: number | null;
  kickingStatsExtraPointsMade?: number | null;
  kickingStatsExtraPointsBlocked?: number | null;
  kickingStatsExtraPointsPercentage?: number | null;
  kickingStatsPuntYardsAverage?: number | null;
  kickingStatsPuntYardsNetAverage?: number | null;
  kickingStatsPuntAttempts?: number | null;
  kickingStatsPuntYardsLongest?: number | null;
  kickingStatsPuntYards?: number | null;
  kickingStatsPuntsIn20?: number | null;
  kickingStatsPuntsOutOfBounds?: number | null;
  kickingStatsPuntsDowned?: number | null;
  kickingStatsPuntsTouchbacks?: number | null;
  kickingStatsPuntsFairCaught?: number | null;
  kickingStatsPuntsReturned?: number | null;
  kickingStatsPuntReturnYards?: number | null;
  kickingStatsPuntReturnTouchdowns?: number | null;
  kickingStatsPuntsBlocked?: number | null;
  kickingStatsPuntSnaps?: number | null;
  kickingStatsPuntTeamSnaps?: number | null;
  kickingStatsPuntSnapPercentage?: number | null;
  kickingStatsPuntReturnYardsAverage?: number | null;
  kickingStatsPuntYardsNet?: number | null;
  kickingStatsPuntHangTimeAverage?: number | null;
  kickingStatsPuntOperationTimeAverage?: number | null;
  kickingStatsKickoffAttempts?: number | null;
  kickingStatsKickoffYards?: number | null;
  kickingStatsKickoffReturnYards?: number | null;
  kickingStatsKickoffTouchbacks?: number | null;
  kickingStatsKickoffTouchbackPercentage?: number | null;
  kickingStatsKickoffsReturned?: number | null;
  kickingStatsKickoffReturnLong?: number | null;
  kickingStatsKickoffReturnYardsAverage?: number | null;
  kickingStatsKickoffOnsideKicks?: number | null;
  kickingStatsKickoffOnsideKicksRecovered?: number | null;
  kickingStatsKickoffsOutOfBounds?: number | null;
  kickingStatsKickoffReturnTouchdowns?: number | null;
  kickingStatsKickoffDepthAverage?: number | null;
  kickingStatsKickoffYardsAverage?: number | null;
  kickingStatsKickoffSnaps?: number | null;
  kickingStatsKickoffTeamSnaps?: number | null;
  kickingStatsKickoffSnapPercentage?: number | null;
  kickingStatsKickoffDepthAttempts?: number | null;
  offensiveLinePressuresAllowed?: number | null;
  offensiveLineSacksAllowed?: number | null;
  offensiveLineScreenBlocks?: number | null;
  penalties?: number | null;
};

export type GetTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTagsQuery = {
  __typename?: "Query";
  tags: Array<{
    __typename?: "Tag";
    id: string;
    name: string;
    tagType: TagTypeEnum;
  }>;
};

export type TagFragment = {
  __typename?: "Tag";
  id: string;
  name: string;
  tagType: TagTypeEnum;
};

export type TransactionFragment = {
  __typename?: "Transaction";
  id: string;
  season: number;
  transactionDate: string;
  lastUpdated: string;
  transactionDescription: {
    __typename?: "TransactionDescription";
    transactionDesc: string;
  };
  startClub?: { __typename?: "Club"; id: string; clubCode: string } | null;
  resultClub?: { __typename?: "Club"; id: string; clubCode: string } | null;
  potentialClub?: { __typename?: "Club"; id: string; clubCode: string } | null;
  initialStatus: {
    __typename?: "TransactionStatus";
    transStatusShortDesc: string;
  };
  resultStatus: {
    __typename?: "TransactionStatus";
    transStatusShortDesc: string;
  };
  player: {
    __typename?: "Player";
    id: string;
    jersey?: string | null;
    firstName: string;
    lastName: string;
    headshotUrl?: string | null;
    draftYear?: number | null;
    draftSelection?: number | null;
    draftRound?: number | null;
    accruedSeasons?: number | null;
    freeAgencyStatus?: FreeAgencyStatus | null;
    birthDate?: string | null;
    sumerAtlasPosition?: { __typename?: "Position"; shortName: string } | null;
    sumerGeneralPosition?: {
      __typename?: "Position";
      shortName: string;
    } | null;
  };
};

export type GetTransactionsByDateRangeQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fromDate: Scalars["DateTime"];
  toDate: Scalars["DateTime"];
  name?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Array<TransactionSortInput> | TransactionSortInput>;
}>;

export type GetTransactionsByDateRangeQuery = {
  __typename?: "Query";
  transactionsByDateRange?: {
    __typename?: "TransactionsByDateRangeConnection";
    totalCount: number;
    edges?: Array<{
      __typename?: "TransactionsByDateRangeEdge";
      node: {
        __typename?: "Transaction";
        id: string;
        season: number;
        transactionDate: string;
        lastUpdated: string;
        transactionDescription: {
          __typename?: "TransactionDescription";
          transactionDesc: string;
        };
        startClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        resultClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        potentialClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        initialStatus: {
          __typename?: "TransactionStatus";
          transStatusShortDesc: string;
        };
        resultStatus: {
          __typename?: "TransactionStatus";
          transStatusShortDesc: string;
        };
        player: {
          __typename?: "Player";
          id: string;
          jersey?: string | null;
          firstName: string;
          lastName: string;
          headshotUrl?: string | null;
          draftYear?: number | null;
          draftSelection?: number | null;
          draftRound?: number | null;
          accruedSeasons?: number | null;
          freeAgencyStatus?: FreeAgencyStatus | null;
          birthDate?: string | null;
          sumerAtlasPosition?: {
            __typename?: "Position";
            shortName: string;
          } | null;
          sumerGeneralPosition?: {
            __typename?: "Position";
            shortName: string;
          } | null;
        };
      };
    }> | null;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type GetTransactionsForPlayerQueryVariables = Exact<{
  playerId?: InputMaybe<Scalars["UUID"]>;
}>;

export type GetTransactionsForPlayerQuery = {
  __typename?: "Query";
  transactionsByDateRange?: {
    __typename?: "TransactionsByDateRangeConnection";
    totalCount: number;
    edges?: Array<{
      __typename?: "TransactionsByDateRangeEdge";
      node: {
        __typename?: "Transaction";
        id: string;
        season: number;
        transactionDate: string;
        transactionDescription: {
          __typename?: "TransactionDescription";
          transactionDescAbbr: string;
          transactionDesc: string;
        };
        startClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        resultClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        potentialClub?: {
          __typename?: "Club";
          id: string;
          clubCode: string;
        } | null;
        initialStatus: {
          __typename?: "TransactionStatus";
          transStatusDesc: string;
          transStatusDescAbbr: string;
          transStatusShortDesc: string;
        };
        resultStatus: {
          __typename?: "TransactionStatus";
          transStatusDesc: string;
          transStatusDescAbbr: string;
          transStatusShortDesc: string;
        };
      };
    }> | null;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type AdminCreateUserMutationVariables = Exact<{
  createAppUserRequest: CreateUserRequest;
}>;

export type AdminCreateUserMutation = {
  __typename?: "Mutation";
  createUser: { __typename?: "User"; id: string };
};

export type EditAppUserMutationVariables = Exact<{
  editUserRequest: UpdateUserRequest;
}>;

export type EditAppUserMutation = {
  __typename?: "Mutation";
  updateUser: { __typename?: "User"; id: string };
};

export type ProvisionUserMutationVariables = Exact<{
  provisionUserRequest: ProvisionUserRequest;
}>;

export type ProvisionUserMutation = {
  __typename?: "Mutation";
  provisionUser: { __typename?: "User"; id: string };
};

export type DeactivateUserMutationVariables = Exact<{
  deactivateUserRequest: DeactivateUserRequest;
}>;

export type DeactivateUserMutation = {
  __typename?: "Mutation";
  deactivateUser: { __typename?: "User"; id: string };
};

export type UserFragment = {
  __typename?: "User";
  id: string;
  authenticatedId?: string | null;
  firstName: string;
  lastName: string;
  emailAddress: string;
  status: UserStatus;
  role?: {
    __typename?: "AppRole";
    name: string;
    permissions: Array<{ __typename?: "Permission"; name: string }>;
  } | null;
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars["UUID"];
}>;

export type GetUserByIdQuery = {
  __typename?: "Query";
  userById?: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: string;
    authenticatedId?: string | null;
    firstName: string;
    lastName: string;
    emailAddress: string;
    status: UserStatus;
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  } | null;
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  __typename?: "Query";
  users: Array<{
    __typename?: "User";
    id: string;
    authenticatedId?: string | null;
    firstName: string;
    lastName: string;
    emailAddress: string;
    status: UserStatus;
    role?: {
      __typename?: "AppRole";
      name: string;
      permissions: Array<{ __typename?: "Permission"; name: string }>;
    } | null;
  }>;
};

export type PermissionFragment = { __typename?: "Permission"; name: string };

export type GetVideoQueryVariables = Exact<{
  request: GetVideoForPlaysRequest;
}>;

export type GetVideoQuery = {
  __typename?: "Query";
  videoForPlays: { __typename?: "Video"; launchUrl: string };
};

export type GetFilteredPlaysForVideoQueryVariables = Exact<{
  playFilterRequest: PlayFilterRequestInput;
}>;

export type GetFilteredPlaysForVideoQuery = {
  __typename?: "Query";
  filteredPlays: Array<{ __typename?: "Play"; playId: string; gameId: string }>;
};

export const AdssignmentPlayerListFragmentDoc = gql`
  fragment AdssignmentPlayerList on Player {
    id
    footballName
    firstName
    lastName
    jersey
    eligibilityYear
    school {
      id
      schoolCode
    }
    club {
      id
      clubCode
    }
  }
`;
export const AdminScoutingAssignmentFieldsFragmentDoc = gql`
  fragment AdminScoutingAssignmentFields on ScoutingAssignment {
    id
    userId
    club {
      id
      clubCode
    }
    position {
      id
      shortName
    }
    player {
      id
      jersey
      firstName
      lastName
      headshotUrl
      draftYear
      eligibilityYear
      accruedSeasons
      freeAgencyStatus
      sumerGeneralPosition {
        id
        shortName
      }
      sumerAtlasPosition {
        shortName
      }
      school {
        id
        schoolCode
      }
      club {
        id
        clubCode
      }
      evaluations {
        season
        scoutGrade {
          grade
        }
        evalType {
          type
        }
        status
        lastUpdated
      }
    }
    scoutType
  }
`;
export const ProTraitGroupGradeFragmentDoc = gql`
  fragment ProTraitGroupGrade on EvaluationTraitGroup {
    scoutGrade {
      grade
    }
    comments
    traitGroup {
      id
      caption
      sort
    }
  }
`;
export const ProTraitGradeFragmentDoc = gql`
  fragment ProTraitGrade on EvaluationTrait {
    scoutGrade {
      grade
    }
    comments
    traitDefinition {
      id
      caption
      sort
    }
  }
`;
export const AlertFragmentDoc = gql`
  fragment Alert on Alert {
    id
    caption
    abbreviation
    sort
  }
`;
export const EvaluationAlertFragmentDoc = gql`
  fragment EvaluationAlert on EvaluationAlert {
    id
    evaluationId
    alert {
      ...Alert
    }
  }
  ${AlertFragmentDoc}
`;
export const FitFragmentDoc = gql`
  fragment Fit on Fit {
    id
    position
    name
  }
`;
export const EvaluationFitFragmentDoc = gql`
  fragment EvaluationFit on EvaluationFit {
    id
    evaluationId
    priority
    fit {
      ...Fit
    }
  }
  ${FitFragmentDoc}
`;
export const TraitGroupGradeFragmentDoc = gql`
  fragment TraitGroupGrade on EvaluationTraitGroup {
    scoutGrade {
      grade
    }
    comments
    traitGroup {
      id
      caption
      sort
    }
  }
`;
export const TraitGradeFragmentDoc = gql`
  fragment TraitGrade on EvaluationTrait {
    scoutGrade {
      grade
    }
    comments
    traitDefinition {
      id
      caption
      sort
    }
  }
`;
export const ProEvaluationFragmentDoc = gql`
  fragment ProEvaluation on Evaluation {
    id
    season
    lastUpdated
    position {
      id
      shortName
    }
    scoutGrade {
      grade
    }
    alerts {
      ...EvaluationAlert
    }
    fits {
      ...EvaluationFit
    }
    clubFits {
      club {
        clubCode
      }
    }
    traitGroupGrades {
      ...TraitGroupGrade
    }
    traitGrades {
      ...TraitGrade
    }
    gameGrades {
      gameId
      scoutGrade {
        grade
      }
      lastUpdated
    }
    user {
      id
      lastName
    }
    bottomLine
    summary
  }
  ${EvaluationAlertFragmentDoc}
  ${EvaluationFitFragmentDoc}
  ${TraitGroupGradeFragmentDoc}
  ${TraitGradeFragmentDoc}
`;
export const ProEvaluationPlayerFragmentDoc = gql`
  fragment ProEvaluationPlayer on Player {
    id
    jersey
    firstName
    lastName
    headshotUrl
    sumerAtlasPosition {
      shortName
    }
    sumerGeneralPosition {
      shortName
    }
    evaluations {
      ...ProEvaluation
    }
  }
  ${ProEvaluationFragmentDoc}
`;
export const PlayerMeasureFragmentDoc = gql`
  fragment PlayerMeasure on WorkoutPlayerMeasure {
    id
    playerId
    workout {
      workoutType {
        name
        priority
      }
      id
      comments
      workoutDate
    }
    measureType {
      shortName
      name
      isFraction
    }
    value
    isVerified
  }
`;
export const BigBoardEvalSummaryFragmentDoc = gql`
  fragment BigBoardEvalSummary on Evaluation {
    id
    scoutGrade {
      id
      grade
    }
    created
    lastUpdated
    user {
      id
      firstName
      lastName
    }
    player {
      id
      jersey
      firstName
      lastName
      draftYear
      redshirt
      eligibility
      latestEligibility
      eligibilityYear
      latestEligibilityYear
      latestSchoolId
      schoolId
      playerOverrides {
        field
        value
      }
      club {
        id
        division
        clubCode
      }
      workouts {
        ...PlayerMeasure
      }
      headshotUrl
      height
      weight
      speed
      freeAgencyStatus
    }
    position {
      id
      shortName
      sort
    }
    evalType {
      type
    }
    season
    isPrimary
  }
  ${PlayerMeasureFragmentDoc}
`;
export const BoardPlayerWarFragmentDoc = gql`
  fragment BoardPlayerWar on RawAiGrade {
    marvalPlayerId
    season
    xWar
    war
  }
`;
export const CollegeBoardPlayerEvalFragmentDoc = gql`
  fragment CollegeBoardPlayerEval on Evaluation {
    id
    scoutGrade {
      grade
    }
    user {
      firstName
      lastName
    }
    lastUpdated
    player {
      id
      jersey
      firstName
      lastName
      eligibility
      latestEligibility
      eligibilityYear
      latestEligibilityYear
      birthDate
      latestBirthDate
      redshirt
      latestSchoolId
      schoolId
      draftYear
      playerOverrides {
        field
        value
      }
      headshotUrl
      height
      weight
      speed
      workouts {
        ...PlayerMeasure
      }
    }
    position {
      id
      shortName
      sort
    }
    fits {
      ...EvaluationFit
    }
    alerts {
      ...EvaluationAlert
    }
    evalType {
      type
    }
    gameGrades {
      scoutGradeId
    }
    season
    isPrimary
    status
    finalizedOn
  }
  ${PlayerMeasureFragmentDoc}
  ${EvaluationFitFragmentDoc}
  ${EvaluationAlertFragmentDoc}
`;
export const EvalBoardTraitGradeFragmentDoc = gql`
  fragment EvalBoardTraitGrade on EvaluationTrait {
    id
    traitDefinition {
      id
    }
    scoutGradeId
  }
`;
export const EvalSummaryTraitGradeFragmentDoc = gql`
  fragment EvalSummaryTraitGrade on EvaluationTrait {
    id
    traitDefinition {
      id
    }
    scoutGradeId
    comments
  }
`;
export const ProListEvaluationFragmentDoc = gql`
  fragment ProListEvaluation on Evaluation {
    id
    scoutGrade {
      id
      grade
    }
    season
    lastUpdated
    isFinal
    finalizedOn
    user {
      id
      firstName
      lastName
    }
    player {
      id
      jersey
      footballName
      firstName
      lastName
      draftYear
      club {
        id
        division
        clubCode
      }
      workouts {
        ...PlayerMeasure
      }
      headshotUrl
      freeAgencyStatus
      status
    }
    position {
      id
      shortName
      sort
    }
    fits {
      ...EvaluationFit
    }
    alerts {
      ...EvaluationAlert
    }
    gameGrades {
      scoutGradeId
    }
    traitGrades {
      ...EvalSummaryTraitGrade
    }
    status
  }
  ${PlayerMeasureFragmentDoc}
  ${EvaluationFitFragmentDoc}
  ${EvaluationAlertFragmentDoc}
  ${EvalSummaryTraitGradeFragmentDoc}
`;
export const CollegeListEvaluationFragmentDoc = gql`
  fragment CollegeListEvaluation on Evaluation {
    id
    scoutGrade {
      id
      grade
    }
    season
    lastUpdated
    isFinal
    finalizedOn
    user {
      id
      firstName
      lastName
    }
    evalType {
      type
    }
    player {
      id
      jersey
      firstName
      lastName
      draftYear
      eligibility
      latestEligibility
      eligibilityYear
      latestEligibilityYear
      latestBirthDate
      redshirt
      latestSchoolId
      schoolId
      playerOverrides {
        field
        value
      }
      workouts {
        ...PlayerMeasure
      }
      headshotUrl
      height
      weight
      speed
    }
    position {
      id
      shortName
      sort
    }
    fits {
      ...EvaluationFit
    }
    alerts {
      ...EvaluationAlert
    }
    gameGrades {
      scoutGradeId
    }
    traitGrades {
      ...EvalSummaryTraitGrade
    }
    status
    isPrimary
  }
  ${PlayerMeasureFragmentDoc}
  ${EvaluationFitFragmentDoc}
  ${EvaluationAlertFragmentDoc}
  ${EvalSummaryTraitGradeFragmentDoc}
`;
export const FreeAgencyEvalFragmentDoc = gql`
  fragment FreeAgencyEval on Evaluation {
    id
    scoutGrade {
      grade
    }
    fits {
      ...EvaluationFit
    }
    alerts {
      ...EvaluationAlert
    }
    isPrimary
  }
  ${EvaluationFitFragmentDoc}
  ${EvaluationAlertFragmentDoc}
`;
export const FreeAgencyPlayerFragmentDoc = gql`
  fragment FreeAgencyPlayer on Player {
    id
    firstName
    lastName
    jersey
    draftYear
    birthDate
    latestBirthDate
    club {
      id
      clubCode
    }
    evaluations {
      ...FreeAgencyEval
    }
    workouts {
      ...PlayerMeasure
    }
    sumerGeneralPosition {
      shortName
      sort
    }
    sumerAtlasPosition {
      shortName
    }
    headshotUrl
    height
    weight
    speed
    freeAgencyStatus
  }
  ${FreeAgencyEvalFragmentDoc}
  ${PlayerMeasureFragmentDoc}
`;
export const AssignedPlayerEvaluationFragmentDoc = gql`
  fragment AssignedPlayerEvaluation on Evaluation {
    season
    user {
      id
    }
    scoutGrade {
      grade
    }
    position {
      id
      shortName
    }
    alerts {
      alert {
        abbreviation
        caption
      }
    }
    fits {
      fit {
        name
      }
    }
    gameGrades {
      id
    }
    traitGrades {
      ...EvalSummaryTraitGrade
    }
    evalType {
      id
      type
    }
    lastUpdated
    status
    finalizedOn
    isPrimary
    isFinal
  }
  ${EvalSummaryTraitGradeFragmentDoc}
`;
export const AssignedPlayerFragmentDoc = gql`
  fragment AssignedPlayer on Player {
    id
    jersey
    firstName
    lastName
    footballName
    headshotUrl
    height
    weight
    speed
    draftYear
    playerOverrides {
      field
      value
    }
    club {
      id
      clubCode
    }
    freeAgencyStatus
    evaluations {
      ...AssignedPlayerEvaluation
    }
    sumerGeneralPosition {
      shortName
    }
    workouts {
      ...PlayerMeasure
    }
    eligibility
    latestEligibility
    eligibilityYear
    latestEligibilityYear
    latestBirthDate
    redshirt
    latestSchoolId
    schoolId
    school {
      id
      schoolCode
    }
    highSchoolGradYear
    tags {
      tag {
        name
      }
    }
    status
  }
  ${AssignedPlayerEvaluationFragmentDoc}
  ${PlayerMeasureFragmentDoc}
`;
export const TaggedPlayerFragmentDoc = gql`
  fragment TaggedPlayer on Player {
    id
    jersey
    firstName
    lastName
    headshotUrl
    height
    weight
    speed
    sumerGeneralPosition {
      shortName
    }
    sumerAtlasPosition {
      shortName
    }
    workouts {
      ...PlayerMeasure
    }
    tags {
      tag {
        id
        name
      }
      sort
    }
    evaluations {
      scoutGrade {
        id
        grade
      }
      isPrimary
    }
    mostRecentClub {
      id
      clubCode
    }
    draftYear
    eligibilityYear
    latestEligibilityYear
    birthDate
    latestBirthDate
    latestSchoolId
    schoolId
    eligibility
    latestEligibility
    redshirt
    hometownCity
    hometownState
    hometownCountry
    highschool
    playerOverrides {
      field
      value
    }
  }
  ${PlayerMeasureFragmentDoc}
`;
export const PlayerTagFragmentDoc = gql`
  fragment PlayerTag on PlayerTag {
    tag {
      id
      name
      tagType
    }
  }
`;
export const PlayerSummaryFragmentDoc = gql`
  fragment PlayerSummary on Player {
    id
    gsisId
    footballName
    firstName
    lastName
    jersey
    draftYear
    hometownCity
    hometownState
    hometownCountry
    highschool
    highSchoolGradYear
    latestHighSchoolGradYear
    eligibilityYear
    eligibility
    schoolId
    redshirt
    draftRound
    draftSelection
    draftClub {
      clubCode
    }
    headshotUrl
    status
    sumerAtlasPosition {
      shortName
    }
    sumerGeneralPosition {
      shortName
    }
    clubId
    agent {
      firstName
      lastName
      company
    }
    birthDate
    namePronunciation
    nflExperience
    minSalaryCredits
    accruedSeasons
    mostRecentClub {
      id
      clubName
      clubCode
    }
    latestBirthDate
    latestClubId
    latestSchoolId
    latestEligibility
    latestEligibilityYear
    playerOverrides {
      field
      value
    }
    tags {
      ...PlayerTag
    }
  }
  ${PlayerTagFragmentDoc}
`;
export const SeasonTypeFragmentDoc = gql`
  fragment SeasonType on SeasonType {
    id
    abbreviation
    description
  }
`;
export const LeagueTypeFragmentDoc = gql`
  fragment LeagueType on LeagueType {
    id
    description
  }
`;
export const GameFragmentDoc = gql`
  fragment Game on Game {
    id
    season
    seasonType {
      ...SeasonType
    }
    week
    weekType {
      id
      description
    }
    leagueType {
      ...LeagueType
    }
    venue
    homeTeamId
    homeTeam
    awayTeam
    homeTeamScore
    awayTeamScore
    kickoffTime
  }
  ${SeasonTypeFragmentDoc}
  ${LeagueTypeFragmentDoc}
`;
export const ReportGameGradesFragmentDoc = gql`
  fragment ReportGameGrades on EvaluationGame {
    gameId
    scoutGrade {
      grade
    }
    game {
      ...Game
    }
  }
  ${GameFragmentDoc}
`;
export const CollegeReportEvaluationFragmentDoc = gql`
  fragment CollegeReportEvaluation on Evaluation {
    id
    scoutGrade {
      grade
    }
    isFinal
    finalizedOn
    position {
      id
      shortName
      sort
    }
    fits {
      ...EvaluationFit
    }
    alerts {
      ...EvaluationAlert
    }
    traitGroupGrades {
      ...TraitGroupGrade
    }
    traitGrades {
      ...TraitGrade
    }
    gameGrades {
      ...ReportGameGrades
    }
    season
    isPrimary
    bottomLine
    summary
  }
  ${EvaluationFitFragmentDoc}
  ${EvaluationAlertFragmentDoc}
  ${TraitGroupGradeFragmentDoc}
  ${TraitGradeFragmentDoc}
  ${ReportGameGradesFragmentDoc}
`;
export const TopCollegePlayerFragmentDoc = gql`
  fragment TopCollegePlayer on CollegeReportPlayer {
    id
    jersey
    firstName
    lastName
    birthDate
    latestBirthDate
    eligibility
    agentName
    agentCompany
    schoolCode
    sumerGeneralPosition
    sumerAtlasPosition
    hometownCity
    hometownState
    hometownCountry
    headshotUrl
    height
    weight
    speed
    workouts {
      ...PlayerMeasure
    }
    evaluations {
      ...CollegeReportEvaluation
    }
    sage
    expectedDraftPosition
  }
  ${PlayerMeasureFragmentDoc}
  ${CollegeReportEvaluationFragmentDoc}
`;
export const CollegeSpringPlayerFragmentDoc = gql`
  fragment CollegeSpringPlayer on CollegeReportPlayer {
    id
    jersey
    firstName
    lastName
    birthDate
    latestBirthDate
    eligibility
    schoolCode
    sumerGeneralPosition
    sumerAtlasPosition
    hometownCity
    hometownState
    hometownCountry
    headshotUrl
    height
    weight
    speed
    workouts {
      ...PlayerMeasure
    }
    evaluations {
      ...CollegeReportEvaluation
    }
  }
  ${PlayerMeasureFragmentDoc}
  ${CollegeReportEvaluationFragmentDoc}
`;
export const DraftContractTierFragmentDoc = gql`
  fragment DraftContractTier on DraftContractTier {
    minPick
    maxPick
    averageGuarantee
    description
  }
`;
export const ReportEvaluationFragmentDoc = gql`
  fragment ReportEvaluation on Evaluation {
    id
    season
    position {
      id
      shortName
    }
    scoutGrade {
      grade
    }
    fits {
      ...EvaluationFit
    }
    traitGroupGrades {
      ...TraitGroupGrade
    }
    traitGrades {
      ...TraitGrade
    }
    gameGrades {
      ...ReportGameGrades
    }
    alerts {
      alert {
        abbreviation
        caption
      }
    }
    summary
    bottomLine
    isPrimary
    isFinal
  }
  ${EvaluationFitFragmentDoc}
  ${TraitGroupGradeFragmentDoc}
  ${TraitGradeFragmentDoc}
  ${ReportGameGradesFragmentDoc}
`;
export const ReportPlayerFragmentDoc = gql`
  fragment ReportPlayer on Player {
    id
    jersey
    firstName
    lastName
    birthDate
    latestBirthDate
    draftClub {
      clubCode
    }
    draftYear
    draftRound
    draftSelection
    height
    weight
    speed
    accruedSeasons
    headshotUrl
    hometownCity
    hometownState
    hometownCountry
    school {
      schoolName
    }
    club {
      clubCode
    }
    sumerAtlasPosition {
      shortName
    }
    sumerGeneralPosition {
      shortName
    }
    evaluations {
      ...ReportEvaluation
    }
    workouts {
      ...PlayerMeasure
    }
    agent {
      id
      firstName
      lastName
      company
    }
    freeAgencyStatus
  }
  ${ReportEvaluationFragmentDoc}
  ${PlayerMeasureFragmentDoc}
`;
export const PlayerWarFragmentDoc = gql`
  fragment PlayerWar on RawAiGrade {
    marvalPlayerId
    season
    league
    war
    passWar
    rushWar
    passRouteWrWar
    passRouteTeWar
    passRouteHbWar
    passBlockOlWar
    passBlockNOlWar
    runBlockOlWar
    runBlockNOlWar
    coverageCbWar
    coverageSWar
    coverageNDbWar
    runDefenseNiDlWar
    runDefenseIDlWar
    passRushNiDlWar
    passRushIDlWar
    xWar
    passXwar
    rushXwar
    passRouteWrXwar
    passRouteTeXwar
    passRouteHbXwar
    passBlockOlXwar
    passBlockNOlXwar
    runBlockOlXwar
    runBlockNOlXwar
    coverageCbXwar
    coverageSXwar
    coverageNDbXwar
    runDefenseNiDlXwar
    runDefenseIDlXwar
    passRushNiDlXwar
    passRushIDlXwar
    rushHbXwar
    rushHbWar
  }
`;
export const UfaPlayerFragmentDoc = gql`
  fragment UfaPlayer on UfaPlayer {
    id
    jersey
    firstName
    lastName
    birthDate
    latestBirthDate
    sumerGeneralPosition
    sumerAtlasPosition
    height
    weight
    speed
    club
    school
    draftYear
    draftRound
    draftSelection
    draftClub
    agentName
    agentCompany
    hometownCity
    hometownState
    hometownCountry
    headshotUrl
    accruedSeasons
    workouts {
      ...PlayerMeasure
    }
    evaluations {
      ...ReportEvaluation
    }
    freeAgencyStatus
  }
  ${PlayerMeasureFragmentDoc}
  ${ReportEvaluationFragmentDoc}
`;
export const AdvancedAnalyticsFragmentDoc = gql`
  fragment AdvancedAnalytics on RawAiGrade {
    season
    marvalPlayerId
    passAccuracy
    passAccuracyRank
    passAccuracyN
    bigThrow
    bigThrowRank
    bigThrowN
    decisionMaking
    decisionMakingRank
    decisionMakingN
    sackAvoidance
    sackAvoidanceRank
    sackAvoidanceN
    aiChartingPassingGrade
    aiChartingPassingGradeRank
    aiChartingPassingGradeN
    rushingElusiveness
    rushingElusivenessRank
    rushingElusivenessN
    rushingYardsPlusMinus
    rushingYardsPlusMinusRank
    rushingYardsPlusMinusN
    productionUnderPressure
    productionUnderPressureRank
    productionUnderPressureN
    yardsAfterCatchPlusMinus
    yardsAfterCatchPlusMinusN
    yardsAfterCatchPlusMinusRank
    passBlockGetOff
    passBlockGetOffRank
    passBlockGetOffN
    passBlockFinishing
    passBlockFinishingRank
    passBlockFinishingN
    aiChartingRushingGrade
    aiChartingRushingGradeRank
    aiChartingRushingGradeN
    aiChartingReceivingGrade
    aiChartingReceivingGradeN
    aiChartingReceivingGradeRank
    aiChartingPassBlockGrade
    aiChartingPassBlockGradeRank
    aiChartingPassBlockGradeN
    aiChartingRunBlockGrade
    aiChartingRunBlockGradeRank
    aiChartingRunBlockGradeN
    runPursuitGetOff
    runPursuitGetOffRank
    runPursuitGetOffN
    runPursuitFinishing
    runPursuitFinishingRank
    runPursuitFinishingN
    passRushWinGetOff
    passRushWinGetOffRank
    passRushWinGetOffN
    passRushWinFinishing
    passRushWinFinishingRank
    passRushWinFinishingN
    aiChartingRunDefenseGrade
    aiChartingRunDefenseGradeRank
    aiChartingRunDefenseGradeN
    productionPlusMinus
    productionPlusMinusRank
    productionPlusMinusN
  }
`;
export const CollegeEvaluationFragmentDoc = gql`
  fragment CollegeEvaluation on Evaluation {
    id
    season
    lastUpdated
    position {
      id
      shortName
    }
    scoutGrade {
      grade
    }
    alerts {
      ...EvaluationAlert
    }
    fits {
      ...EvaluationFit
    }
    clubFits {
      club {
        clubCode
      }
    }
    traitGroupGrades {
      ...TraitGroupGrade
    }
    traitGrades {
      ...TraitGrade
    }
    gameGrades {
      gameId
      scoutGrade {
        grade
      }
      lastUpdated
    }
    user {
      id
      lastName
    }
    summary
    bottomLine
  }
  ${EvaluationAlertFragmentDoc}
  ${EvaluationFitFragmentDoc}
  ${TraitGroupGradeFragmentDoc}
  ${TraitGradeFragmentDoc}
`;
export const CollegeEvaluationPlayerFragmentDoc = gql`
  fragment CollegeEvaluationPlayer on Player {
    id
    jersey
    firstName
    lastName
    headshotUrl
    eligibilityYear
    latestEligibilityYear
    latestSchoolId
    schoolId
    playerOverrides {
      field
      value
    }
    sumerAtlasPosition {
      shortName
    }
    sumerGeneralPosition {
      shortName
    }
    evaluations {
      ...CollegeEvaluation
    }
  }
  ${CollegeEvaluationFragmentDoc}
`;
export const ClubFragmentDoc = gql`
  fragment Club on Club {
    id
    clubCode
    clubName
    clubRosterUrl
    conference
    division
    outdoorStadium
    primaryColor
    secondaryColor
    currentCoach
    location
    venue
    yearEstablished
  }
`;
export const TransactionContractFragmentDoc = gql`
  fragment TransactionContract on Contract {
    playerId
    total {
      guaranteed
    }
    firstContractYear
    effectiveLastContractYear
    signingType
    sumer {
      evaluated
    }
  }
`;
export const ContractSeasonFragmentDoc = gql`
  fragment ContractSeason on ContractSeason {
    contractId
    season
    p5
    signingBonus
    signingBonusProrated
    optionBonus
    optionBonusProrated
    existingProration
    rosterBonuses {
      ninetyMan
      fiftyThreeMan
      fortySixMan
      perGame
    }
    carryOvers {
      existingCash
      cap
    }
    reportingBonus
    workoutBonus
    incentives
    escalators
    guarantees {
      p5
      p5GuaranteedType
      p5Conversion
      p5Injury
      rosterBonus53Man
      rosterBonus53ManGuaranteedType
      rosterBonusPerGame
      rosterBonusPerGameProrated
      rosterBonusPerGameGuaranteedType
      rosterBonus46Man
      rosterBonus46ManGuaranteedType
      rosterBonus90Man
      rosterBonus90ManProrated
      rosterBonus90ManGuaranteedType
      rosterBonusConversion
      rosterBonusInjury
      reportingBonus
      reportingBonusProrated
      reportingBonusGuaranteedType
      workoutBonus
      workoutBonusGuaranteedType
      incentives
      incentivesGuaranteedType
      optionBonus
      practical
      offseasonConversion
    }
    isVoidYear
    isOptionYear
  }
`;
export const ContractSectionFragmentDoc = gql`
  fragment ContractSection on ContractSeasonSection {
    id
    contractId
    season
    isProrate
    sectionType {
      id
      type
    }
    clauseType
    capAmt
    guaranteeAmount
    sectionTitle
    sectionText
    sectionYearRepId
    statusId
  }
`;
export const ContractFragmentDoc = gql`
  fragment Contract on Contract {
    id
    leagueContractId
    playerId
    clubId
    firstContractYear
    lastContractYear
    effectiveLastContractYear
    isEffectiveContract
    isMostRecentContract
    signDate
    signingType
    contractSignAge
    previousContractId
    agent {
      id
      firstName
      lastName
      company
    }
    seasons(order: { season: ASC }) {
      ...ContractSeason
    }
    sections(order: { season: ASC, sectionYearRepId: ASC }) {
      ...ContractSection
    }
    sumer {
      isRework
      evaluated
      existingYears
      existingAmount
      existingAmountGuaranteed
      isExistingAmountGuaranteed
      functionalYears
      functionalAmount
      practicalGuaranteed
      comments
    }
  }
  ${ContractSeasonFragmentDoc}
  ${ContractSectionFragmentDoc}
`;
export const AgentFragmentDoc = gql`
  fragment Agent on Agent {
    id
    firstName
    lastName
    company
  }
`;
export const EvaluationGameFragmentDoc = gql`
  fragment EvaluationGame on EvaluationGame {
    id
    gameId
    scoutGrade {
      id
      grade
    }
    game {
      ...Game
    }
    comments
  }
  ${GameFragmentDoc}
`;
export const EvaluationClubFitFragmentDoc = gql`
  fragment EvaluationClubFit on EvaluationClubFit {
    id
    evaluationId
    club {
      id
      clubCode
    }
  }
`;
export const TraitGroupPositionFragmentDoc = gql`
  fragment TraitGroupPosition on TraitGroupPosition {
    id
    positionId
    sort
    traitDefinition {
      id
      caption
      description
      sort
    }
  }
`;
export const TraitGroupFragmentDoc = gql`
  fragment TraitGroup on TraitGroup {
    id
    caption
    sort
    traitGroupPositions {
      ...TraitGroupPosition
    }
  }
  ${TraitGroupPositionFragmentDoc}
`;
export const EvaluationTraitGroupFragmentDoc = gql`
  fragment EvaluationTraitGroup on EvaluationTraitGroup {
    id
    evaluationId
    scoutGrade {
      id
      grade
    }
    traitGroup {
      ...TraitGroup
    }
    comments
  }
  ${TraitGroupFragmentDoc}
`;
export const EvalFragmentDoc = gql`
  fragment Eval on Evaluation {
    id
    scoutGrade {
      id
      grade
    }
    created
    lastUpdated
    isFinal
    finalizedOn
    user {
      id
      firstName
      lastName
    }
    player {
      id
      firstName
      lastName
      school {
        id
        schoolName
        schoolCode
      }
      club {
        id
        division
        conference
        clubCode
        clubName
        location
      }
      headshotUrl
    }
    position {
      id
      shortName
    }
    traitGrades {
      id
      scoutGrade {
        id
        grade
      }
      traitDefinition {
        id
        caption
        shortName
        sort
      }
      comments
    }
    alerts {
      ...EvaluationAlert
    }
    fits {
      ...EvaluationFit
    }
    gameGrades {
      ...EvaluationGame
    }
    clubFits {
      ...EvaluationClubFit
    }
    traitGroupGrades {
      ...EvaluationTraitGroup
    }
    evalType {
      id
      type
    }
    summary
    bottomLine
    characterSummary
    season
    isPrimary
    status
  }
  ${EvaluationAlertFragmentDoc}
  ${EvaluationFitFragmentDoc}
  ${EvaluationGameFragmentDoc}
  ${EvaluationClubFitFragmentDoc}
  ${EvaluationTraitGroupFragmentDoc}
`;
export const EvalSummaryFragmentDoc = gql`
  fragment EvalSummary on Evaluation {
    id
    scoutGrade {
      id
      grade
    }
    created
    lastUpdated
    isFinal
    finalizedOn
    user {
      id
      firstName
      lastName
    }
    player {
      id
      jersey
      firstName
      lastName
      draftYear
      eligibility
      latestEligibility
      eligibilityYear
      latestEligibilityYear
      latestBirthDate
      redshirt
      latestSchoolId
      schoolId
      club {
        id
        division
        clubCode
        clubName
        location
      }
      playerOverrides {
        field
        value
      }
      workouts {
        ...PlayerMeasure
      }
      headshotUrl
      height
      weight
      speed
      freeAgencyStatus
    }
    position {
      id
      shortName
      sort
    }
    fits {
      ...EvaluationFit
    }
    alerts {
      ...EvaluationAlert
    }
    clubFits {
      ...EvaluationClubFit
    }
    evalType {
      type
    }
    gameGrades {
      scoutGradeId
    }
    traitGrades {
      ...EvalSummaryTraitGrade
    }
    season
    isPrimary
    status
  }
  ${PlayerMeasureFragmentDoc}
  ${EvaluationFitFragmentDoc}
  ${EvaluationAlertFragmentDoc}
  ${EvaluationClubFitFragmentDoc}
  ${EvalSummaryTraitGradeFragmentDoc}
`;
export const PriorSeasonEvalFragmentDoc = gql`
  fragment PriorSeasonEval on Evaluation {
    id
    season
    lastUpdated
    traitGrades {
      id
      scoutGrade {
        grade
      }
      traitDefinition {
        id
      }
    }
    traitGroupGrades {
      id
      scoutGrade {
        grade
      }
      traitGroup {
        id
      }
    }
  }
`;
export const EvalSummaryWithMeasuresFragmentDoc = gql`
  fragment EvalSummaryWithMeasures on Evaluation {
    id
    scoutGrade {
      id
      grade
    }
    created
    lastUpdated
    isFinal
    finalizedOn
    user {
      id
      firstName
      lastName
    }
    player {
      id
      jersey
      firstName
      lastName
      draftYear
      eligibility
      eligibilityYear
      redshirt
      school {
        id
        schoolName
        schoolCode
      }
      club {
        id
        division
        clubCode
        clubName
        location
      }
      workouts {
        ...PlayerMeasure
      }
      headshotUrl
      height
      weight
      speed
    }
    position {
      id
      shortName
      sort
    }
    fits {
      ...EvaluationFit
    }
    alerts {
      ...EvaluationAlert
    }
    clubFits {
      ...EvaluationClubFit
    }
    evalType {
      type
    }
    season
    isPrimary
  }
  ${PlayerMeasureFragmentDoc}
  ${EvaluationFitFragmentDoc}
  ${EvaluationAlertFragmentDoc}
  ${EvaluationClubFitFragmentDoc}
`;
export const EvalMatrixExportFragmentDoc = gql`
  fragment EvalMatrixExport on Evaluation {
    id
    scoutGrade {
      id
      grade
    }
    created
    lastUpdated
    isFinal
    finalizedOn
    user {
      id
      firstName
      lastName
    }
    evalType {
      id
      type
    }
    player {
      id
      jersey
      firstName
      lastName
      draftYear
      eligibility
      latestEligibility
      eligibilityYear
      latestEligibilityYear
      latestBirthDate
      redshirt
      latestSchoolId
      schoolId
      club {
        id
        clubCode
      }
      playerOverrides {
        field
        value
      }
      workouts {
        ...PlayerMeasure
      }
      height
      weight
      speed
      freeAgencyStatus
    }
    position {
      id
      shortName
      sort
    }
    fits {
      ...EvaluationFit
    }
    alerts {
      ...EvaluationAlert
    }
    gameGrades {
      scoutGrade {
        grade
      }
    }
    traitGrades {
      id
      scoutGrade {
        id
        grade
      }
      traitDefinition {
        id
        caption
        shortName
        sort
      }
    }
    traitGroupGrades {
      id
      scoutGrade {
        id
        grade
      }
      traitGroup {
        ...TraitGroup
      }
    }
    season
    isPrimary
    status
  }
  ${PlayerMeasureFragmentDoc}
  ${EvaluationFitFragmentDoc}
  ${EvaluationAlertFragmentDoc}
  ${TraitGroupFragmentDoc}
`;
export const EvaluationTraitFragmentDoc = gql`
  fragment EvaluationTrait on EvaluationTrait {
    id
    traitDefinition {
      id
      caption
      sort
    }
    scoutGrade {
      id
      grade
    }
    comments
  }
`;
export const ScoutGradeFragmentDoc = gql`
  fragment ScoutGrade on ScoutGrade {
    id
    grade
    quality
    description
  }
`;
export const EvaluatorFragmentDoc = gql`
  fragment Evaluator on User {
    id
    firstName
    lastName
    status
  }
`;
export const EvalTypeFragmentDoc = gql`
  fragment EvalType on EvalType {
    id
    type
  }
`;
export const MeasureTypeFragmentDoc = gql`
  fragment MeasureType on MeasureType {
    id
    name
    shortName
    description
    precision
    isFraction
    minValue
    maxValue
    priority
  }
`;
export const WorkoutFragmentDoc = gql`
  fragment Workout on Workout {
    id
    season
    comments
    workoutType {
      id
      name
    }
  }
`;
export const PositionFragmentDoc = gql`
  fragment Position on Position {
    id
    shortName
    sort
    sideOfBall
  }
`;
export const PackageFieldsFragmentDoc = gql`
  fragment PackageFields on Package {
    id
    abbreviation
    description
    name
    pffPackageId
    sideOfBall
  }
`;
export const PlayerPositionDepthFragmentDoc = gql`
  fragment PlayerPositionDepth on PlayerPackagePosition {
    depth
    id
    playerId
    player {
      id
      lastName
      jersey
      status
    }
    position {
      id
      shortName
    }
  }
`;
export const PlayerListFragmentDoc = gql`
  fragment PlayerList on Player {
    id
    footballName
    firstName
    lastName
    jersey
    eligibilityYear
    sumerAtlasPosition {
      shortName
    }
    sumerGeneralPosition {
      shortName
    }
    school {
      id
    }
    club {
      id
    }
  }
`;
export const PlayerPositionFragmentDoc = gql`
  fragment PlayerPosition on PlayerPackagePosition {
    depth
    package {
      name
    }
    position {
      shortName
    }
  }
`;
export const PlayerEvalFragmentDoc = gql`
  fragment PlayerEval on Evaluation {
    id
    userId
    evalType {
      type
    }
    user {
      id
      firstName
      lastName
    }
    alerts {
      evaluationId
      id
      alertId
      alert {
        id
        caption
        abbreviation
        description
        sort
      }
    }
    fits {
      ...EvaluationFit
    }
    scoutGrade {
      id
      grade
    }
    summary
    lastUpdated
    season
    isFinal
    finalizedOn
    clubFits {
      club {
        id
        clubCode
      }
    }
    traitGrades {
      id
      scoutGrade {
        id
        grade
      }
      traitDefinition {
        id
        caption
        sort
      }
      comments
    }
    traitGroupGrades {
      traitGroup {
        caption
      }
      scoutGrade {
        id
        grade
      }
      comments
    }
    gameGrades {
      gameId
      scoutGrade {
        grade
      }
      lastUpdated
    }
    user {
      id
    }
    position {
      id
      shortName
    }
    isPrimary
  }
  ${EvaluationFitFragmentDoc}
`;
export const PlayerFragmentDoc = gql`
  fragment Player on Player {
    id
    gsisId
    collegeGsisId
    footballName
    firstName
    lastName
    jersey
    draftYear
    height
    weight
    speed
    status
    birthDate
    draftRound
    draftSelection
    draftClub {
      clubCode
    }
    hometownCity
    hometownState
    hometownCountry
    eligibilityYear
    eligibility
    redshirt
    headshotUrl
    nflExperience
    minSalaryCredits
    accruedSeasons
    sumerAtlasPosition {
      shortName
    }
    sumerGeneralPosition {
      shortName
    }
    playerPffPackagePositions {
      ...PlayerPosition
    }
    schoolId
    school {
      id
      schoolCode
      schoolName
      primaryColor
      secondaryColor
    }
    clubId
    club {
      id
      clubCode
      clubName
    }
    mostRecentClub {
      id
      clubCode
    }
    evaluations {
      ...PlayerEval
    }
    favorite {
      playerId
    }
    grades {
      playerId
      gradeType
      value
      dollars
      season
      id
    }
    workouts {
      id
      value
      isVerified
      measureType {
        name
        shortName
        isFraction
      }
      workout {
        id
        workoutType {
          name
          priority
        }
        workoutDate
        comments
      }
    }
    latestBirthDate
    highschool
    namePronunciation
    freeAgencyStatus
    playerOverrides {
      field
      value
    }
    latestClubId
    latestSchoolId
    latestEligibility
    latestEligibilityYear
    totalPensionCredits
  }
  ${PlayerPositionFragmentDoc}
  ${PlayerEvalFragmentDoc}
`;
export const PlayerEvalSlimFragmentDoc = gql`
  fragment PlayerEvalSlim on Evaluation {
    id
    userId
    season
    lastUpdated
    user {
      id
      firstName
      lastName
    }
    position {
      id
      shortName
    }
    evalType {
      type
    }
    scoutGrade {
      id
      grade
    }
    alerts {
      ...EvaluationAlert
    }
    fits {
      ...EvaluationFit
    }
    gameGrades {
      gameId
      scoutGrade {
        grade
      }
      lastUpdated
    }
    isFinal
    finalizedOn
    isPrimary
  }
  ${EvaluationAlertFragmentDoc}
  ${EvaluationFitFragmentDoc}
`;
export const CollegeRosterPlayerFragmentDoc = gql`
  fragment CollegeRosterPlayer on Player {
    id
    footballName
    firstName
    lastName
    jersey
    draftYear
    height
    weight
    speed
    birthDate
    hometownCity
    hometownState
    hometownCountry
    schoolId
    eligibilityYear
    eligibility
    redshirt
    headshotUrl
    sumerAtlasPosition {
      shortName
    }
    sumerGeneralPosition {
      shortName
    }
    evaluations {
      ...PlayerEvalSlim
    }
    playerOverrides {
      field
      value
    }
    workouts {
      ...PlayerMeasure
    }
    latestSchoolId
    latestEligibility
    latestEligibilityYear
    latestBirthDate
  }
  ${PlayerEvalSlimFragmentDoc}
  ${PlayerMeasureFragmentDoc}
`;
export const ClubRosterPlayerFragmentDoc = gql`
  fragment ClubRosterPlayer on Player {
    id
    footballName
    firstName
    lastName
    jersey
    draftYear
    draftRound
    draftSelection
    eligibilityYear
    height
    weight
    speed
    status
    birthDate
    latestBirthDate
    latestClubId
    headshotUrl
    sumerAtlasPosition {
      shortName
    }
    sumerGeneralPosition {
      shortName
    }
    school {
      id
      schoolName
    }
    club {
      id
      clubCode
      clubName
    }
    evaluations {
      ...PlayerEvalSlim
    }
    workouts {
      ...PlayerMeasure
    }
    freeAgencyStatus
  }
  ${PlayerEvalSlimFragmentDoc}
  ${PlayerMeasureFragmentDoc}
`;
export const PlayerSlimFragmentDoc = gql`
  fragment PlayerSlim on Player {
    id
    gsisId
    firstName
    lastName
    jersey
    draftYear
    hometownCity
    hometownState
    hometownCountry
    height
    weight
    speed
    highschool
    schoolId
    eligibilityYear
    eligibility
    redshirt
    draftRound
    draftSelection
    draftClub {
      clubCode
    }
    headshotUrl
    status
    evaluations {
      ...PlayerEvalSlim
    }
    sumerAtlasPosition {
      shortName
    }
    sumerGeneralPosition {
      shortName
    }
    playerPffPackagePositions {
      ...PlayerPosition
    }
    club {
      id
      clubCode
      clubName
    }
    latestBirthDate
    latestSchoolId
    latestClubId
    latestEligibility
    latestEligibilityYear
    namePronunciation
    nflExperience
    minSalaryCredits
    accruedSeasons
  }
  ${PlayerEvalSlimFragmentDoc}
  ${PlayerPositionFragmentDoc}
`;
export const SchoolFragmentDoc = gql`
  fragment School on School {
    id
    schoolCode
    schoolName
    nickname
    primaryColor
    secondaryColor
    conference
    division
  }
`;
export const SchoolListFragmentDoc = gql`
  fragment SchoolList on School {
    id
    schoolCode
    schoolName
    division
  }
`;
export const ScoutingAssignmentFieldsFragmentDoc = gql`
  fragment ScoutingAssignmentFields on ScoutingAssignment {
    id
    userId
    club {
      id
      clubCode
    }
    position {
      id
      shortName
    }
    player {
      id
      firstName
      lastName
    }
    scoutType
  }
`;
export const StatsFragmentDoc = gql`
  fragment Stats on PlayerStat {
    rushingStatsYards
    rushingStatsAttempts
    rushingStatsTouchdowns
    rushingStatsRunsTwentyYardsPlus
    rushingStatsRunsFortyYardsPlus
    rushingStatsLongestRun
    rushingStatsFirstDownRushes
    rushingStatsFirstDownPercentage
    rushingStatsFumbles
    rushingStatsYardsAfterContact
    rushingStatsYardsAfterContactPerRush
    rushingStatsTacklesAvoided
    rushingStatsFumblesRecovered
    rushingStatsFumblesLost
    rushingStatsHurriesAllowed
    rushingStatsPenalties
    rushingStatsInjuriesInGame
    rushingStatsPressuresAllowed
    rushingStatsSacksAllowed
    rushingStatsYardsPerRush
    rushingStatsSnaps
    rushingStatsTeamSnaps
    rushingStatsSnapPercentage
    rushingStatsTwoPointAttempts
    rushingStatsTwoPointConversions
    returnStatsKickReturnYardsAverage
    returnStatsKickReturnAttempts
    returnStatsKickReturnYards
    returnStatsKickReturnTouchdowns
    returnStatsKickReturnsTwentyYardsPlus
    returnStatsKickReturnsFortyYardsPlus
    returnStatsKickReturnLongestYards
    returnStatsKickReturnFairCatches
    returnStatsKickReturnFumbles
    returnStatsKickReturnYardsAfterContact
    returnStatsKickReturnYardsAfterContactAverage
    returnStatsKickReturnSnaps
    returnStatsKickReturnTeamSnaps
    returnStatsKickReturnSnapPercentage
    returnStatsPuntReturnYardsAverage
    returnStatsPuntReturnAttempts
    returnStatsPuntReturnYards
    returnStatsPuntReturnTouchdowns
    returnStatsPuntReturnsTwentyYardsPlus
    returnStatsPuntReturnsFortyYardsPlus
    returnStatsPuntReturnLongestYards
    returnStatsPuntReturnFairCatches
    returnStatsPuntReturnFumbles
    returnStatsPuntReturnYardsAfterContact
    returnStatsPuntReturnYardsAfterContactAverage
    returnStatsPuntReturnSnaps
    returnStatsPuntReturnTeamSnaps
    returnStatsPuntReturnSnapPercentage
    receivingStatsReceptions
    receivingStatsTouchdowns
    receivingStatsYards
    receivingStatsYardsPerReception
    receivingStatsReceptionsTwentyYardsPlus
    receivingStatsReceptionsFortyYardsPlus
    receivingStatsLongestReception
    receivingStatsFirstDowns
    receivingStatsFirstDownPercentage
    receivingStatsReceivingFumbles
    receivingStatsYardsAfterCatch
    receivingStatsYardAfterCatchPerReception
    receivingStatsTargets
    receivingStatsTacklesAvoided
    receivingStatsDrops
    receivingStatsDropsPercentage
    receivingStatsYardsAfterContact
    receivingStatsInjuriesInGame
    receivingStatsPenalties
    receivingStatsCatchableThrows
    receivingStatsPassRouteDepthAverage
    receivingStatsSnaps
    receivingStatsTeamSnaps
    receivingStatsSnapPercentage
    receivingStatsPassRoutesRun
    receivingStatsReceptionsPerTouchdown
    receivingStatsTwoPointAttempts
    receivingStatsTwoPointConversions
    passingStatsYards
    passingStatsAttempts
    passingStatsCompletions
    passingStatsCompletionPercentage
    passingStatsYardsPerCompletionAverage
    passingStatsTouchdowns
    passingStatsTouchdownPercentage
    passingStatsInterceptions
    passingStatsInterceptionPercentage
    passingStatsPasserRating
    passingStatsFirstDowns
    passingStatsFirstDownPercentage
    passingStatsCompletionsTwentyYardsPlus
    passingStatsCompletionsFortyYardsPlus
    passingStatsLongestCompletion
    passingStatsSacks
    passingStatsSackYards
    passingStatsYardsAfterCatch
    passingStatsDrops
    passingStatsInjuriesInGame
    passingStatsFumbles
    passingStatsFumblesRecovered
    passingStatsHurriesAllowed
    passingStatsPassDepthAverage
    passingStatsPassDepthAttempts
    passingStatsCatchableThrows
    passingStatsCatchableThrowPercentage
    passingStatsPenalties
    passingStatsTimeToPressureAverage
    passingStatsTimeToPressureAttempts
    passingStatsTimeToThrowAverage
    passingStatsTimeToThrowAttempts
    passingStatsDropbackDepthAverage
    passingStatsDropbackAttempts
    passingStatsSnaps
    passingStatsTeamSnaps
    passingStatsSnapPercentage
    passingStatsPumpFakes
    passingStatsPassingEfficiency
    passingStatsYardsPerAttempt
    passingStatsTouchdownInterceptionRatio
    passingStatsTwoPointAttempts
    passingStatsTwoPointConversions
    defenseStatsFumblesForced
    defenseStatsTacklesSolo
    defenseStatsSacks
    defenseStatsSackPlays
    defenseStatsSackPercentage
    defenseStatsSnaps
    defenseStatsTeamSnaps
    defenseStatsSnapPercentage
    defenseStatsBattedPasses
    defenseStatsQbHits
    defenseStatsQbHitPercentage
    defenseStatsFumblesRecovered
    defenseStatsInjuriesInGame
    defenseStatsMissedTacklesOnQb
    defenseStatsPassBreakups
    defenseStatsStops
    defenseStatsTouchdowns
    defenseStatsPassRushAttempts
    defenseStatsCoverageAttempts
    defenseStatsBurns
    defenseStatsDefensiveTargets
    defenseStatsQbPressures
    defenseStatsFumblesRecoveredForTouchdown
    defenseStatsQbPressurePercentage
    defenseStatsQbHurries
    defenseStatsQbHurryPercentage
    defenseStatsMissedTackles
    defenseStatsPuntsBlocked
    defenseStatsFieldGoalsBlocked
    defenseStatsExtraPointsBlocked
    defenseStatsTacklesForLoss
    defenseStatsDroppedInterceptions
    defenseStatsInterceptions
    defenseStatsInterceptionTouchdowns
    defenseStatsInterceptionYards
    defenseStatsInterceptionLongestReturn
    defenseStatsInterceptionPercentage
    defenseStatsTacklesCombined
    defenseStatsTacklesAssisted
    kickingStatsFieldGoalsMade
    kickingStatsFieldGoalAttempts
    kickingStatsFieldGoalPercentage
    kickingStatsFieldGoals1To19Attempted
    kickingStatsFieldGoals1To19Made
    kickingStatsFieldGoals1To19Percentage
    kickingStatsFieldGoals20To29Attempted
    kickingStatsFieldGoals20To29Made
    kickingStatsFieldGoals20To29Percentage
    kickingStatsFieldGoals30To39Attempted
    kickingStatsFieldGoals30To39Made
    kickingStatsFieldGoals30To39Percentage
    kickingStatsFieldGoals40To49Attempted
    kickingStatsFieldGoals40To49Made
    kickingStatsFieldGoals40To49Percentage
    kickingStatsFieldGoals50To59Attempted
    kickingStatsFieldGoals50To59Made
    kickingStatsFieldGoals50To59Percentage
    kickingStatsFieldGoals60PlusAttempted
    kickingStatsFieldGoals60PlusMade
    kickingStatsFieldGoals60PlusPercentage
    kickingStatsFieldGoalMadeLongest
    kickingStatsFieldGoalsBlocked
    kickingStatsFieldGoalSnaps
    kickingStatsFieldGoalTeamSnaps
    kickingStatsFieldGoalSnapPercentage
    kickingStatsFieldGoalYardsAverage
    kickingStatsBlockedFieldGoalsAndExtraPoints
    kickingStatsExtraPointAttempts
    kickingStatsExtraPointsMade
    kickingStatsExtraPointsBlocked
    kickingStatsExtraPointsPercentage
    kickingStatsPuntYardsAverage
    kickingStatsPuntYardsNetAverage
    kickingStatsPuntAttempts
    kickingStatsPuntYardsLongest
    kickingStatsPuntYards
    kickingStatsPuntsIn20
    kickingStatsPuntsOutOfBounds
    kickingStatsPuntsDowned
    kickingStatsPuntsTouchbacks
    kickingStatsPuntsFairCaught
    kickingStatsPuntsReturned
    kickingStatsPuntReturnYards
    kickingStatsPuntReturnTouchdowns
    kickingStatsPuntsBlocked
    kickingStatsPuntSnaps
    kickingStatsPuntTeamSnaps
    kickingStatsPuntSnapPercentage
    kickingStatsPuntReturnYardsAverage
    kickingStatsPuntYardsNet
    kickingStatsPuntHangTimeAverage
    kickingStatsPuntOperationTimeAverage
    kickingStatsKickoffAttempts
    kickingStatsKickoffYards
    kickingStatsKickoffReturnYards
    kickingStatsKickoffTouchbacks
    kickingStatsKickoffTouchbackPercentage
    kickingStatsKickoffsReturned
    kickingStatsKickoffReturnLong
    kickingStatsKickoffReturnYardsAverage
    kickingStatsKickoffOnsideKicks
    kickingStatsKickoffOnsideKicksRecovered
    kickingStatsKickoffsOutOfBounds
    kickingStatsKickoffReturnTouchdowns
    kickingStatsKickoffDepthAverage
    kickingStatsKickoffYardsAverage
    kickingStatsKickoffSnaps
    kickingStatsKickoffTeamSnaps
    kickingStatsKickoffSnapPercentage
    kickingStatsKickoffDepthAttempts
    offensiveLinePressuresAllowed
    offensiveLineSacksAllowed
    offensiveLineScreenBlocks
    penalties
  }
`;
export const CareerStatsFragmentDoc = gql`
  fragment CareerStats on CareerStat {
    leagueType {
      ...LeagueType
    }
    seasonType {
      ...SeasonType
    }
    playerStat {
      ...Stats
    }
    perGameStatsGamesPlayed
    perGameStatsPassAttemptsPerGame
    perGameStatsPassingYardsPerGame
    perGameStatsReceivingYardsPerGame
    perGameStatsReceptionsPerGame
    perGameStatsRushAttemptsPerGame
    perGameStatsRushingYardsPerGame
  }
  ${LeagueTypeFragmentDoc}
  ${SeasonTypeFragmentDoc}
  ${StatsFragmentDoc}
`;
export const SeasonStatsFragmentDoc = gql`
  fragment SeasonStats on SeasonStat {
    season
    seasonType {
      ...SeasonType
    }
    leagueType {
      ...LeagueType
    }
    playerStat {
      ...Stats
    }
    perGameStatsGamesPlayed
    perGameStatsPassAttemptsPerGame
    perGameStatsPassingYardsPerGame
    perGameStatsReceivingYardsPerGame
    perGameStatsReceptionsPerGame
    perGameStatsRushAttemptsPerGame
    perGameStatsRushingYardsPerGame
  }
  ${SeasonTypeFragmentDoc}
  ${LeagueTypeFragmentDoc}
  ${StatsFragmentDoc}
`;
export const OffensiveLineStatsFragmentDoc = gql`
  fragment OffensiveLineStats on PlayerStat {
    seasonTypeId
    leagueTypeId
    offensiveLinePressuresAllowed
    offensiveLineSacksAllowed
    offensiveLineScreenBlocks
    penalties
  }
`;
export const GamesMissedFragmentDoc = gql`
  fragment GamesMissed on PlayerStat {
    season
    gamesMissed
  }
`;
export const PlayHistoryStatFragmentDoc = gql`
  fragment PlayHistoryStat on PlayerStat {
    gamesPlayed
    gamesStarted
    offensiveSnapsPercentage
    defensiveSnapsPercentage
    specialTeamsSnapsPercentage
  }
`;
export const PlayHistoryFragmentDoc = gql`
  fragment PlayHistory on GameStat {
    season
    leagueType {
      ...LeagueType
    }
    seasonType {
      ...SeasonType
    }
    teamId
    playerStat {
      ...PlayHistoryStat
    }
    game {
      kickoffTime
    }
  }
  ${LeagueTypeFragmentDoc}
  ${SeasonTypeFragmentDoc}
  ${PlayHistoryStatFragmentDoc}
`;
export const GameStatsFragmentDoc = gql`
  fragment GameStats on GameStat {
    season
    gameId
    game {
      ...Game
    }
    weekType {
      id
      description
    }
    teamId
    seasonType {
      ...SeasonType
    }
    leagueType {
      ...LeagueType
    }
    playerStat {
      ...Stats
    }
  }
  ${GameFragmentDoc}
  ${SeasonTypeFragmentDoc}
  ${LeagueTypeFragmentDoc}
  ${StatsFragmentDoc}
`;
export const TagFragmentDoc = gql`
  fragment Tag on Tag {
    id
    name
    tagType
  }
`;
export const TransactionFragmentDoc = gql`
  fragment Transaction on Transaction {
    id
    season
    transactionDate
    lastUpdated
    transactionDescription {
      transactionDesc
    }
    startClub {
      id
      clubCode
    }
    resultClub {
      id
      clubCode
    }
    potentialClub {
      id
      clubCode
    }
    initialStatus {
      transStatusShortDesc
    }
    resultStatus {
      transStatusShortDesc
    }
    player {
      id
      jersey
      firstName
      lastName
      headshotUrl
      draftYear
      draftSelection
      draftRound
      accruedSeasons
      freeAgencyStatus
      sumerAtlasPosition {
        shortName
      }
      sumerGeneralPosition {
        shortName
      }
      birthDate
    }
  }
`;
export const PermissionFragmentDoc = gql`
  fragment Permission on Permission {
    name
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    authenticatedId
    firstName
    lastName
    emailAddress
    status
    role {
      name
      permissions {
        ...Permission
      }
    }
  }
  ${PermissionFragmentDoc}
`;
export const GetAdminScoutingAssignmentsForUserDocument = gql`
  query GetAdminScoutingAssignmentsForUser(
    $userId: UUID!
    $scoutType: ScoutTypeEnum!
  ) {
    scoutingAssignments(
      where: {
        and: [{ userId: { eq: $userId } }, { scoutType: { eq: $scoutType } }]
      }
    ) {
      ...AdminScoutingAssignmentFields
    }
  }
  ${AdminScoutingAssignmentFieldsFragmentDoc}
`;

/**
 * __useGetAdminScoutingAssignmentsForUserQuery__
 *
 * To run a query within a React component, call `useGetAdminScoutingAssignmentsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminScoutingAssignmentsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminScoutingAssignmentsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      scoutType: // value for 'scoutType'
 *   },
 * });
 */
export function useGetAdminScoutingAssignmentsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdminScoutingAssignmentsForUserQuery,
    GetAdminScoutingAssignmentsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdminScoutingAssignmentsForUserQuery,
    GetAdminScoutingAssignmentsForUserQueryVariables
  >(GetAdminScoutingAssignmentsForUserDocument, options);
}
export function useGetAdminScoutingAssignmentsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminScoutingAssignmentsForUserQuery,
    GetAdminScoutingAssignmentsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdminScoutingAssignmentsForUserQuery,
    GetAdminScoutingAssignmentsForUserQueryVariables
  >(GetAdminScoutingAssignmentsForUserDocument, options);
}
export type GetAdminScoutingAssignmentsForUserQueryHookResult = ReturnType<
  typeof useGetAdminScoutingAssignmentsForUserQuery
>;
export type GetAdminScoutingAssignmentsForUserLazyQueryHookResult = ReturnType<
  typeof useGetAdminScoutingAssignmentsForUserLazyQuery
>;
export type GetAdminScoutingAssignmentsForUserQueryResult = Apollo.QueryResult<
  GetAdminScoutingAssignmentsForUserQuery,
  GetAdminScoutingAssignmentsForUserQueryVariables
>;
export const GetProPlayerListDocument = gql`
  query GetProPlayerList(
    $sortCriteria: PlayerSearchSortCriteriaEnum!
    $positionId: UUID!
    $clubIds: [UUID]!
  ) {
    players(
      groupFilter: "Pro"
      sortCriteria: $sortCriteria
      where: {
        and: [
          { sumerGeneralPositionId: { eq: $positionId } }
          { clubId: { in: $clubIds } }
        ]
      }
    ) {
      ...AdssignmentPlayerList
    }
  }
  ${AdssignmentPlayerListFragmentDoc}
`;

/**
 * __useGetProPlayerListQuery__
 *
 * To run a query within a React component, call `useGetProPlayerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProPlayerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProPlayerListQuery({
 *   variables: {
 *      sortCriteria: // value for 'sortCriteria'
 *      positionId: // value for 'positionId'
 *      clubIds: // value for 'clubIds'
 *   },
 * });
 */
export function useGetProPlayerListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProPlayerListQuery,
    GetProPlayerListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProPlayerListQuery, GetProPlayerListQueryVariables>(
    GetProPlayerListDocument,
    options,
  );
}
export function useGetProPlayerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProPlayerListQuery,
    GetProPlayerListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProPlayerListQuery,
    GetProPlayerListQueryVariables
  >(GetProPlayerListDocument, options);
}
export type GetProPlayerListQueryHookResult = ReturnType<
  typeof useGetProPlayerListQuery
>;
export type GetProPlayerListLazyQueryHookResult = ReturnType<
  typeof useGetProPlayerListLazyQuery
>;
export type GetProPlayerListQueryResult = Apollo.QueryResult<
  GetProPlayerListQuery,
  GetProPlayerListQueryVariables
>;
export const GetCollegePlayerListDocument = gql`
  query GetCollegePlayerList(
    $sortCriteria: PlayerSearchSortCriteriaEnum!
    $positionId: UUID!
  ) {
    players(
      groupFilter: "College"
      sortCriteria: $sortCriteria
      where: {
        and: [
          {
            sumerGeneralPositionId: { eq: $positionId }
            school: { division: { in: ["1A", "1AA"] } }
          }
        ]
      }
    ) {
      ...AdssignmentPlayerList
    }
  }
  ${AdssignmentPlayerListFragmentDoc}
`;

/**
 * __useGetCollegePlayerListQuery__
 *
 * To run a query within a React component, call `useGetCollegePlayerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollegePlayerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollegePlayerListQuery({
 *   variables: {
 *      sortCriteria: // value for 'sortCriteria'
 *      positionId: // value for 'positionId'
 *   },
 * });
 */
export function useGetCollegePlayerListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollegePlayerListQuery,
    GetCollegePlayerListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollegePlayerListQuery,
    GetCollegePlayerListQueryVariables
  >(GetCollegePlayerListDocument, options);
}
export function useGetCollegePlayerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollegePlayerListQuery,
    GetCollegePlayerListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollegePlayerListQuery,
    GetCollegePlayerListQueryVariables
  >(GetCollegePlayerListDocument, options);
}
export type GetCollegePlayerListQueryHookResult = ReturnType<
  typeof useGetCollegePlayerListQuery
>;
export type GetCollegePlayerListLazyQueryHookResult = ReturnType<
  typeof useGetCollegePlayerListLazyQuery
>;
export type GetCollegePlayerListQueryResult = Apollo.QueryResult<
  GetCollegePlayerListQuery,
  GetCollegePlayerListQueryVariables
>;
export const GetProRosterEvaluationsDocument = gql`
  query GetProRosterEvaluations(
    $clubCode: String
    $group: String
    $sortCriteria: PlayerSearchSortCriteriaEnum!
  ) {
    players(
      clubFilter: $clubCode
      groupFilter: $group
      sortCriteria: $sortCriteria
    ) {
      ...ProEvaluationPlayer
    }
  }
  ${ProEvaluationPlayerFragmentDoc}
`;

/**
 * __useGetProRosterEvaluationsQuery__
 *
 * To run a query within a React component, call `useGetProRosterEvaluationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProRosterEvaluationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProRosterEvaluationsQuery({
 *   variables: {
 *      clubCode: // value for 'clubCode'
 *      group: // value for 'group'
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetProRosterEvaluationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProRosterEvaluationsQuery,
    GetProRosterEvaluationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProRosterEvaluationsQuery,
    GetProRosterEvaluationsQueryVariables
  >(GetProRosterEvaluationsDocument, options);
}
export function useGetProRosterEvaluationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProRosterEvaluationsQuery,
    GetProRosterEvaluationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProRosterEvaluationsQuery,
    GetProRosterEvaluationsQueryVariables
  >(GetProRosterEvaluationsDocument, options);
}
export type GetProRosterEvaluationsQueryHookResult = ReturnType<
  typeof useGetProRosterEvaluationsQuery
>;
export type GetProRosterEvaluationsLazyQueryHookResult = ReturnType<
  typeof useGetProRosterEvaluationsLazyQuery
>;
export type GetProRosterEvaluationsQueryResult = Apollo.QueryResult<
  GetProRosterEvaluationsQuery,
  GetProRosterEvaluationsQueryVariables
>;
export const GetBigBoardEvalSummariesDocument = gql`
  query GetBigBoardEvalSummaries(
    $position: UUID
    $user: UUID
    $playerId: UUID
    $group: EvalTypeEnum
  ) {
    evals(
      order: { lastUpdated: DESC }
      positionFilter: $position
      userFilter: $user
      playerFilter: $playerId
      groupFilter: $group
    ) {
      ...BigBoardEvalSummary
    }
  }
  ${BigBoardEvalSummaryFragmentDoc}
`;

/**
 * __useGetBigBoardEvalSummariesQuery__
 *
 * To run a query within a React component, call `useGetBigBoardEvalSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBigBoardEvalSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBigBoardEvalSummariesQuery({
 *   variables: {
 *      position: // value for 'position'
 *      user: // value for 'user'
 *      playerId: // value for 'playerId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useGetBigBoardEvalSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBigBoardEvalSummariesQuery,
    GetBigBoardEvalSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBigBoardEvalSummariesQuery,
    GetBigBoardEvalSummariesQueryVariables
  >(GetBigBoardEvalSummariesDocument, options);
}
export function useGetBigBoardEvalSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBigBoardEvalSummariesQuery,
    GetBigBoardEvalSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBigBoardEvalSummariesQuery,
    GetBigBoardEvalSummariesQueryVariables
  >(GetBigBoardEvalSummariesDocument, options);
}
export type GetBigBoardEvalSummariesQueryHookResult = ReturnType<
  typeof useGetBigBoardEvalSummariesQuery
>;
export type GetBigBoardEvalSummariesLazyQueryHookResult = ReturnType<
  typeof useGetBigBoardEvalSummariesLazyQuery
>;
export type GetBigBoardEvalSummariesQueryResult = Apollo.QueryResult<
  GetBigBoardEvalSummariesQuery,
  GetBigBoardEvalSummariesQueryVariables
>;
export const GetLatestWarForPlayersDocument = gql`
  query GetLatestWarForPlayers($playerIds: [UUID]!, $league: String!) {
    playerAdvancedAnalytics(
      where: {
        and: [
          { marvalPlayerId: { in: $playerIds } }
          { league: { eq: $league } }
        ]
      }
      order: { marvalPlayerId: ASC, season: DESC }
    ) {
      ...BoardPlayerWar
    }
  }
  ${BoardPlayerWarFragmentDoc}
`;

/**
 * __useGetLatestWarForPlayersQuery__
 *
 * To run a query within a React component, call `useGetLatestWarForPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestWarForPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestWarForPlayersQuery({
 *   variables: {
 *      playerIds: // value for 'playerIds'
 *      league: // value for 'league'
 *   },
 * });
 */
export function useGetLatestWarForPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestWarForPlayersQuery,
    GetLatestWarForPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestWarForPlayersQuery,
    GetLatestWarForPlayersQueryVariables
  >(GetLatestWarForPlayersDocument, options);
}
export function useGetLatestWarForPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestWarForPlayersQuery,
    GetLatestWarForPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestWarForPlayersQuery,
    GetLatestWarForPlayersQueryVariables
  >(GetLatestWarForPlayersDocument, options);
}
export type GetLatestWarForPlayersQueryHookResult = ReturnType<
  typeof useGetLatestWarForPlayersQuery
>;
export type GetLatestWarForPlayersLazyQueryHookResult = ReturnType<
  typeof useGetLatestWarForPlayersLazyQuery
>;
export type GetLatestWarForPlayersQueryResult = Apollo.QueryResult<
  GetLatestWarForPlayersQuery,
  GetLatestWarForPlayersQueryVariables
>;
export const GetCollegeBoardDocument = gql`
  query GetCollegeBoard(
    $position: UUID
    $user: UUID
    $playerId: UUID
    $group: EvalTypeEnum
  ) {
    evals(
      order: { lastUpdated: DESC }
      positionFilter: $position
      userFilter: $user
      playerFilter: $playerId
      groupFilter: $group
    ) {
      ...CollegeBoardPlayerEval
    }
  }
  ${CollegeBoardPlayerEvalFragmentDoc}
`;

/**
 * __useGetCollegeBoardQuery__
 *
 * To run a query within a React component, call `useGetCollegeBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollegeBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollegeBoardQuery({
 *   variables: {
 *      position: // value for 'position'
 *      user: // value for 'user'
 *      playerId: // value for 'playerId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useGetCollegeBoardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCollegeBoardQuery,
    GetCollegeBoardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCollegeBoardQuery, GetCollegeBoardQueryVariables>(
    GetCollegeBoardDocument,
    options,
  );
}
export function useGetCollegeBoardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollegeBoardQuery,
    GetCollegeBoardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollegeBoardQuery,
    GetCollegeBoardQueryVariables
  >(GetCollegeBoardDocument, options);
}
export type GetCollegeBoardQueryHookResult = ReturnType<
  typeof useGetCollegeBoardQuery
>;
export type GetCollegeBoardLazyQueryHookResult = ReturnType<
  typeof useGetCollegeBoardLazyQuery
>;
export type GetCollegeBoardQueryResult = Apollo.QueryResult<
  GetCollegeBoardQuery,
  GetCollegeBoardQueryVariables
>;
export const GetProEvaluationListDocument = gql`
  query GetProEvaluationList($group: EvalTypeEnum, $season: Int) {
    evals(
      where: { season: { eq: $season } }
      order: { scoutGrade: { grade: DESC } }
      groupFilter: $group
    ) {
      ...ProListEvaluation
    }
  }
  ${ProListEvaluationFragmentDoc}
`;

/**
 * __useGetProEvaluationListQuery__
 *
 * To run a query within a React component, call `useGetProEvaluationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProEvaluationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProEvaluationListQuery({
 *   variables: {
 *      group: // value for 'group'
 *      season: // value for 'season'
 *   },
 * });
 */
export function useGetProEvaluationListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProEvaluationListQuery,
    GetProEvaluationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProEvaluationListQuery,
    GetProEvaluationListQueryVariables
  >(GetProEvaluationListDocument, options);
}
export function useGetProEvaluationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProEvaluationListQuery,
    GetProEvaluationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProEvaluationListQuery,
    GetProEvaluationListQueryVariables
  >(GetProEvaluationListDocument, options);
}
export type GetProEvaluationListQueryHookResult = ReturnType<
  typeof useGetProEvaluationListQuery
>;
export type GetProEvaluationListLazyQueryHookResult = ReturnType<
  typeof useGetProEvaluationListLazyQuery
>;
export type GetProEvaluationListQueryResult = Apollo.QueryResult<
  GetProEvaluationListQuery,
  GetProEvaluationListQueryVariables
>;
export const GetCollegeEvaluationListDocument = gql`
  query GetCollegeEvaluationList($group: EvalTypeEnum, $eligibilityYear: Int) {
    evals(
      where: { player: { eligibilityYear: { eq: $eligibilityYear } } }
      order: { scoutGrade: { grade: DESC } }
      groupFilter: $group
    ) {
      ...CollegeListEvaluation
    }
  }
  ${CollegeListEvaluationFragmentDoc}
`;

/**
 * __useGetCollegeEvaluationListQuery__
 *
 * To run a query within a React component, call `useGetCollegeEvaluationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollegeEvaluationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollegeEvaluationListQuery({
 *   variables: {
 *      group: // value for 'group'
 *      eligibilityYear: // value for 'eligibilityYear'
 *   },
 * });
 */
export function useGetCollegeEvaluationListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCollegeEvaluationListQuery,
    GetCollegeEvaluationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollegeEvaluationListQuery,
    GetCollegeEvaluationListQueryVariables
  >(GetCollegeEvaluationListDocument, options);
}
export function useGetCollegeEvaluationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollegeEvaluationListQuery,
    GetCollegeEvaluationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollegeEvaluationListQuery,
    GetCollegeEvaluationListQueryVariables
  >(GetCollegeEvaluationListDocument, options);
}
export type GetCollegeEvaluationListQueryHookResult = ReturnType<
  typeof useGetCollegeEvaluationListQuery
>;
export type GetCollegeEvaluationListLazyQueryHookResult = ReturnType<
  typeof useGetCollegeEvaluationListLazyQuery
>;
export type GetCollegeEvaluationListQueryResult = Apollo.QueryResult<
  GetCollegeEvaluationListQuery,
  GetCollegeEvaluationListQueryVariables
>;
export const GetFreeAgencyPlayersDocument = gql`
  query GetFreeAgencyPlayers($sortCriteria: PlayerSearchSortCriteriaEnum!) {
    players(
      where: {
        or: [
          { freeAgencyStatus: { eq: UFA } }
          { freeAgencyStatus: { eq: RFA } }
          { freeAgencyStatus: { eq: ERFA } }
          { freeAgencyStatus: { eq: CAP_CASUALTY } }
        ]
      }
      sortCriteria: $sortCriteria
    ) {
      ...FreeAgencyPlayer
    }
  }
  ${FreeAgencyPlayerFragmentDoc}
`;

/**
 * __useGetFreeAgencyPlayersQuery__
 *
 * To run a query within a React component, call `useGetFreeAgencyPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFreeAgencyPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFreeAgencyPlayersQuery({
 *   variables: {
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetFreeAgencyPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFreeAgencyPlayersQuery,
    GetFreeAgencyPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFreeAgencyPlayersQuery,
    GetFreeAgencyPlayersQueryVariables
  >(GetFreeAgencyPlayersDocument, options);
}
export function useGetFreeAgencyPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFreeAgencyPlayersQuery,
    GetFreeAgencyPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFreeAgencyPlayersQuery,
    GetFreeAgencyPlayersQueryVariables
  >(GetFreeAgencyPlayersDocument, options);
}
export type GetFreeAgencyPlayersQueryHookResult = ReturnType<
  typeof useGetFreeAgencyPlayersQuery
>;
export type GetFreeAgencyPlayersLazyQueryHookResult = ReturnType<
  typeof useGetFreeAgencyPlayersLazyQuery
>;
export type GetFreeAgencyPlayersQueryResult = Apollo.QueryResult<
  GetFreeAgencyPlayersQuery,
  GetFreeAgencyPlayersQueryVariables
>;
export const GetPlayerSageDocument = gql`
  query GetPlayerSage($season: Int!, $playerIds: [UUID]!) {
    playerSage(
      where: {
        and: [
          { marvalPlayerId: { in: $playerIds } }
          { season: { eq: $season } }
        ]
      }
    ) {
      marvalPlayerId
      season
      sumerGeneralPosition
      gapypocPrediction
    }
  }
`;

/**
 * __useGetPlayerSageQuery__
 *
 * To run a query within a React component, call `useGetPlayerSageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerSageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerSageQuery({
 *   variables: {
 *      season: // value for 'season'
 *      playerIds: // value for 'playerIds'
 *   },
 * });
 */
export function useGetPlayerSageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayerSageQuery,
    GetPlayerSageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayerSageQuery, GetPlayerSageQueryVariables>(
    GetPlayerSageDocument,
    options,
  );
}
export function useGetPlayerSageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerSageQuery,
    GetPlayerSageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlayerSageQuery, GetPlayerSageQueryVariables>(
    GetPlayerSageDocument,
    options,
  );
}
export type GetPlayerSageQueryHookResult = ReturnType<
  typeof useGetPlayerSageQuery
>;
export type GetPlayerSageLazyQueryHookResult = ReturnType<
  typeof useGetPlayerSageLazyQuery
>;
export type GetPlayerSageQueryResult = Apollo.QueryResult<
  GetPlayerSageQuery,
  GetPlayerSageQueryVariables
>;
export const GetAssignedCollegePlayersDocument = gql`
  query GetAssignedCollegePlayers($userId: UUID!) {
    scoutingAssignments(
      where: {
        and: [
          { userId: { eq: $userId } }
          { scoutType: { in: [COLLEGE, CROSS_CHECK] } }
        ]
      }
    ) {
      id
      userId
      playerId
      scoutType
      player {
        ...AssignedPlayer
      }
    }
  }
  ${AssignedPlayerFragmentDoc}
`;

/**
 * __useGetAssignedCollegePlayersQuery__
 *
 * To run a query within a React component, call `useGetAssignedCollegePlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedCollegePlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedCollegePlayersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAssignedCollegePlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssignedCollegePlayersQuery,
    GetAssignedCollegePlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssignedCollegePlayersQuery,
    GetAssignedCollegePlayersQueryVariables
  >(GetAssignedCollegePlayersDocument, options);
}
export function useGetAssignedCollegePlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignedCollegePlayersQuery,
    GetAssignedCollegePlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssignedCollegePlayersQuery,
    GetAssignedCollegePlayersQueryVariables
  >(GetAssignedCollegePlayersDocument, options);
}
export type GetAssignedCollegePlayersQueryHookResult = ReturnType<
  typeof useGetAssignedCollegePlayersQuery
>;
export type GetAssignedCollegePlayersLazyQueryHookResult = ReturnType<
  typeof useGetAssignedCollegePlayersLazyQuery
>;
export type GetAssignedCollegePlayersQueryResult = Apollo.QueryResult<
  GetAssignedCollegePlayersQuery,
  GetAssignedCollegePlayersQueryVariables
>;
export const GetAssignedProPlayersDocument = gql`
  query GetAssignedProPlayers($userId: UUID!) {
    scoutingAssignments(
      where: {
        and: [
          { userId: { eq: $userId } }
          { scoutType: { in: [PRO, CROSS_CHECK] } }
        ]
      }
    ) {
      id
      userId
      playerId
      scoutType
      player {
        ...AssignedPlayer
      }
    }
  }
  ${AssignedPlayerFragmentDoc}
`;

/**
 * __useGetAssignedProPlayersQuery__
 *
 * To run a query within a React component, call `useGetAssignedProPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedProPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedProPlayersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAssignedProPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssignedProPlayersQuery,
    GetAssignedProPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssignedProPlayersQuery,
    GetAssignedProPlayersQueryVariables
  >(GetAssignedProPlayersDocument, options);
}
export function useGetAssignedProPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignedProPlayersQuery,
    GetAssignedProPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssignedProPlayersQuery,
    GetAssignedProPlayersQueryVariables
  >(GetAssignedProPlayersDocument, options);
}
export type GetAssignedProPlayersQueryHookResult = ReturnType<
  typeof useGetAssignedProPlayersQuery
>;
export type GetAssignedProPlayersLazyQueryHookResult = ReturnType<
  typeof useGetAssignedProPlayersLazyQuery
>;
export type GetAssignedProPlayersQueryResult = Apollo.QueryResult<
  GetAssignedProPlayersQuery,
  GetAssignedProPlayersQueryVariables
>;
export const GetAssignedPortalPlayersDocument = gql`
  query GetAssignedPortalPlayers($userId: UUID!) {
    scoutingAssignments(
      where: {
        and: [{ userId: { eq: $userId } }, { scoutType: { eq: PORTAL } }]
      }
    ) {
      id
      userId
      playerId
      scoutType
      player {
        ...AssignedPlayer
      }
    }
  }
  ${AssignedPlayerFragmentDoc}
`;

/**
 * __useGetAssignedPortalPlayersQuery__
 *
 * To run a query within a React component, call `useGetAssignedPortalPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedPortalPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedPortalPlayersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAssignedPortalPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssignedPortalPlayersQuery,
    GetAssignedPortalPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssignedPortalPlayersQuery,
    GetAssignedPortalPlayersQueryVariables
  >(GetAssignedPortalPlayersDocument, options);
}
export function useGetAssignedPortalPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssignedPortalPlayersQuery,
    GetAssignedPortalPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssignedPortalPlayersQuery,
    GetAssignedPortalPlayersQueryVariables
  >(GetAssignedPortalPlayersDocument, options);
}
export type GetAssignedPortalPlayersQueryHookResult = ReturnType<
  typeof useGetAssignedPortalPlayersQuery
>;
export type GetAssignedPortalPlayersLazyQueryHookResult = ReturnType<
  typeof useGetAssignedPortalPlayersLazyQuery
>;
export type GetAssignedPortalPlayersQueryResult = Apollo.QueryResult<
  GetAssignedPortalPlayersQuery,
  GetAssignedPortalPlayersQueryVariables
>;
export const GetNflCutdownPredictionsDocument = gql`
  query GetNflCutdownPredictions($playerIds: [UUID]!) {
    nflCutdownPredictions(where: { marvalPlayerId: { in: $playerIds } }) {
      marvalPlayerId
      cutdownBucket
      cutProb
    }
  }
`;

/**
 * __useGetNflCutdownPredictionsQuery__
 *
 * To run a query within a React component, call `useGetNflCutdownPredictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNflCutdownPredictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNflCutdownPredictionsQuery({
 *   variables: {
 *      playerIds: // value for 'playerIds'
 *   },
 * });
 */
export function useGetNflCutdownPredictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNflCutdownPredictionsQuery,
    GetNflCutdownPredictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetNflCutdownPredictionsQuery,
    GetNflCutdownPredictionsQueryVariables
  >(GetNflCutdownPredictionsDocument, options);
}
export function useGetNflCutdownPredictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNflCutdownPredictionsQuery,
    GetNflCutdownPredictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNflCutdownPredictionsQuery,
    GetNflCutdownPredictionsQueryVariables
  >(GetNflCutdownPredictionsDocument, options);
}
export type GetNflCutdownPredictionsQueryHookResult = ReturnType<
  typeof useGetNflCutdownPredictionsQuery
>;
export type GetNflCutdownPredictionsLazyQueryHookResult = ReturnType<
  typeof useGetNflCutdownPredictionsLazyQuery
>;
export type GetNflCutdownPredictionsQueryResult = Apollo.QueryResult<
  GetNflCutdownPredictionsQuery,
  GetNflCutdownPredictionsQueryVariables
>;
export const GetTaggedPlayersDocument = gql`
  query GetTaggedPlayers(
    $after: String
    $before: String
    $last: Int
    $first: Int
    $tagId: UUID!
    $name: String
    $sortCriteria: TaggedPlayerSortCriteriaEnum
  ) {
    taggedPlayers(
      after: $after
      before: $before
      last: $last
      first: $first
      where: {
        or: [
          { firstName: { contains: $name } }
          { lastName: { contains: $name } }
        ]
      }
      tagId: $tagId
      sortCriteria: $sortCriteria
    ) {
      edges {
        node {
          ...TaggedPlayer
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${TaggedPlayerFragmentDoc}
`;

/**
 * __useGetTaggedPlayersQuery__
 *
 * To run a query within a React component, call `useGetTaggedPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaggedPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaggedPlayersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      tagId: // value for 'tagId'
 *      name: // value for 'name'
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetTaggedPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTaggedPlayersQuery,
    GetTaggedPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaggedPlayersQuery, GetTaggedPlayersQueryVariables>(
    GetTaggedPlayersDocument,
    options,
  );
}
export function useGetTaggedPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTaggedPlayersQuery,
    GetTaggedPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTaggedPlayersQuery,
    GetTaggedPlayersQueryVariables
  >(GetTaggedPlayersDocument, options);
}
export type GetTaggedPlayersQueryHookResult = ReturnType<
  typeof useGetTaggedPlayersQuery
>;
export type GetTaggedPlayersLazyQueryHookResult = ReturnType<
  typeof useGetTaggedPlayersLazyQuery
>;
export type GetTaggedPlayersQueryResult = Apollo.QueryResult<
  GetTaggedPlayersQuery,
  GetTaggedPlayersQueryVariables
>;
export const GetPlayerSummaryDocument = gql`
  query GetPlayerSummary($id: UUID!) {
    playerById(id: $id) {
      ...PlayerSummary
    }
  }
  ${PlayerSummaryFragmentDoc}
`;

/**
 * __useGetPlayerSummaryQuery__
 *
 * To run a query within a React component, call `useGetPlayerSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayerSummaryQuery,
    GetPlayerSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayerSummaryQuery, GetPlayerSummaryQueryVariables>(
    GetPlayerSummaryDocument,
    options,
  );
}
export function useGetPlayerSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerSummaryQuery,
    GetPlayerSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayerSummaryQuery,
    GetPlayerSummaryQueryVariables
  >(GetPlayerSummaryDocument, options);
}
export type GetPlayerSummaryQueryHookResult = ReturnType<
  typeof useGetPlayerSummaryQuery
>;
export type GetPlayerSummaryLazyQueryHookResult = ReturnType<
  typeof useGetPlayerSummaryLazyQuery
>;
export type GetPlayerSummaryQueryResult = Apollo.QueryResult<
  GetPlayerSummaryQuery,
  GetPlayerSummaryQueryVariables
>;
export const GetPlayerHonorsDocument = gql`
  query GetPlayerHonors($id: UUID!) {
    playerHonors(where: { marvalPlayerId: { eq: $id } }) {
      marvalPlayerId
      marvalClubId
      description
      season
    }
  }
`;

/**
 * __useGetPlayerHonorsQuery__
 *
 * To run a query within a React component, call `useGetPlayerHonorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerHonorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerHonorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerHonorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayerHonorsQuery,
    GetPlayerHonorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayerHonorsQuery, GetPlayerHonorsQueryVariables>(
    GetPlayerHonorsDocument,
    options,
  );
}
export function useGetPlayerHonorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerHonorsQuery,
    GetPlayerHonorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayerHonorsQuery,
    GetPlayerHonorsQueryVariables
  >(GetPlayerHonorsDocument, options);
}
export type GetPlayerHonorsQueryHookResult = ReturnType<
  typeof useGetPlayerHonorsQuery
>;
export type GetPlayerHonorsLazyQueryHookResult = ReturnType<
  typeof useGetPlayerHonorsLazyQuery
>;
export type GetPlayerHonorsQueryResult = Apollo.QueryResult<
  GetPlayerHonorsQuery,
  GetPlayerHonorsQueryVariables
>;
export const GetTopCollegePlayersDocument = gql`
  query GetTopCollegePlayers($eligibilityYear: Int!, $count: Int!) {
    topCollegePlayers(
      request: { eligibilityYear: $eligibilityYear, count: $count }
    ) {
      ...TopCollegePlayer
    }
  }
  ${TopCollegePlayerFragmentDoc}
`;

/**
 * __useGetTopCollegePlayersQuery__
 *
 * To run a query within a React component, call `useGetTopCollegePlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopCollegePlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopCollegePlayersQuery({
 *   variables: {
 *      eligibilityYear: // value for 'eligibilityYear'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetTopCollegePlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTopCollegePlayersQuery,
    GetTopCollegePlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTopCollegePlayersQuery,
    GetTopCollegePlayersQueryVariables
  >(GetTopCollegePlayersDocument, options);
}
export function useGetTopCollegePlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTopCollegePlayersQuery,
    GetTopCollegePlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTopCollegePlayersQuery,
    GetTopCollegePlayersQueryVariables
  >(GetTopCollegePlayersDocument, options);
}
export type GetTopCollegePlayersQueryHookResult = ReturnType<
  typeof useGetTopCollegePlayersQuery
>;
export type GetTopCollegePlayersLazyQueryHookResult = ReturnType<
  typeof useGetTopCollegePlayersLazyQuery
>;
export type GetTopCollegePlayersQueryResult = Apollo.QueryResult<
  GetTopCollegePlayersQuery,
  GetTopCollegePlayersQueryVariables
>;
export const GetCollegeReportByPlayerIdDocument = gql`
  query GetCollegeReportByPlayerId($playerId: UUID!) {
    playerById(id: $playerId) {
      id
      jersey
      firstName
      lastName
      birthDate
      latestBirthDate
      eligibility
      eligibilityYear
      agent {
        firstName
        lastName
        company
      }
      school {
        schoolCode
      }
      sumerGeneralPosition {
        shortName
      }
      sumerAtlasPosition {
        shortName
      }
      hometownCity
      hometownState
      hometownCountry
      headshotUrl
      height
      weight
      speed
      workouts {
        ...PlayerMeasure
      }
      evaluations {
        ...CollegeReportEvaluation
      }
      schoolId
      latestSchoolId
      latestEligibility
      latestEligibilityYear
      playerOverrides {
        field
        value
      }
    }
  }
  ${PlayerMeasureFragmentDoc}
  ${CollegeReportEvaluationFragmentDoc}
`;

/**
 * __useGetCollegeReportByPlayerIdQuery__
 *
 * To run a query within a React component, call `useGetCollegeReportByPlayerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollegeReportByPlayerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollegeReportByPlayerIdQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetCollegeReportByPlayerIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollegeReportByPlayerIdQuery,
    GetCollegeReportByPlayerIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollegeReportByPlayerIdQuery,
    GetCollegeReportByPlayerIdQueryVariables
  >(GetCollegeReportByPlayerIdDocument, options);
}
export function useGetCollegeReportByPlayerIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollegeReportByPlayerIdQuery,
    GetCollegeReportByPlayerIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollegeReportByPlayerIdQuery,
    GetCollegeReportByPlayerIdQueryVariables
  >(GetCollegeReportByPlayerIdDocument, options);
}
export type GetCollegeReportByPlayerIdQueryHookResult = ReturnType<
  typeof useGetCollegeReportByPlayerIdQuery
>;
export type GetCollegeReportByPlayerIdLazyQueryHookResult = ReturnType<
  typeof useGetCollegeReportByPlayerIdLazyQuery
>;
export type GetCollegeReportByPlayerIdQueryResult = Apollo.QueryResult<
  GetCollegeReportByPlayerIdQuery,
  GetCollegeReportByPlayerIdQueryVariables
>;
export const GetDraftContractTiersDocument = gql`
  query GetDraftContractTiers {
    draftContractTiers {
      ...DraftContractTier
    }
  }
  ${DraftContractTierFragmentDoc}
`;

/**
 * __useGetDraftContractTiersQuery__
 *
 * To run a query within a React component, call `useGetDraftContractTiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDraftContractTiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDraftContractTiersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDraftContractTiersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDraftContractTiersQuery,
    GetDraftContractTiersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDraftContractTiersQuery,
    GetDraftContractTiersQueryVariables
  >(GetDraftContractTiersDocument, options);
}
export function useGetDraftContractTiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDraftContractTiersQuery,
    GetDraftContractTiersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDraftContractTiersQuery,
    GetDraftContractTiersQueryVariables
  >(GetDraftContractTiersDocument, options);
}
export type GetDraftContractTiersQueryHookResult = ReturnType<
  typeof useGetDraftContractTiersQuery
>;
export type GetDraftContractTiersLazyQueryHookResult = ReturnType<
  typeof useGetDraftContractTiersLazyQuery
>;
export type GetDraftContractTiersQueryResult = Apollo.QueryResult<
  GetDraftContractTiersQuery,
  GetDraftContractTiersQueryVariables
>;
export const GetPlayerEdpDocument = gql`
  query GetPlayerEdp($playerId: UUID!, $season: Int) {
    playerExpectedDraftPosition(
      where: {
        and: [
          { marvalPlayerId: { eq: $playerId } }
          { season: { eq: $season } }
        ]
      }
    ) {
      marvalPlayerId
      season
      expectedDraftPosition
    }
  }
`;

/**
 * __useGetPlayerEdpQuery__
 *
 * To run a query within a React component, call `useGetPlayerEdpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerEdpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerEdpQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      season: // value for 'season'
 *   },
 * });
 */
export function useGetPlayerEdpQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayerEdpQuery,
    GetPlayerEdpQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayerEdpQuery, GetPlayerEdpQueryVariables>(
    GetPlayerEdpDocument,
    options,
  );
}
export function useGetPlayerEdpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerEdpQuery,
    GetPlayerEdpQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlayerEdpQuery, GetPlayerEdpQueryVariables>(
    GetPlayerEdpDocument,
    options,
  );
}
export type GetPlayerEdpQueryHookResult = ReturnType<
  typeof useGetPlayerEdpQuery
>;
export type GetPlayerEdpLazyQueryHookResult = ReturnType<
  typeof useGetPlayerEdpLazyQuery
>;
export type GetPlayerEdpQueryResult = Apollo.QueryResult<
  GetPlayerEdpQuery,
  GetPlayerEdpQueryVariables
>;
export const GetCollegeSpringPlayersDocument = gql`
  query GetCollegeSpringPlayers(
    $eligibilityYear: Int!
    $count: Int!
    $includeAll: Boolean!
    $position: String!
  ) {
    collegeSpringPlayers(
      request: {
        eligibilityYear: $eligibilityYear
        count: $count
        includeAll: $includeAll
        position: $position
      }
    ) {
      ...CollegeSpringPlayer
    }
  }
  ${CollegeSpringPlayerFragmentDoc}
`;

/**
 * __useGetCollegeSpringPlayersQuery__
 *
 * To run a query within a React component, call `useGetCollegeSpringPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollegeSpringPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollegeSpringPlayersQuery({
 *   variables: {
 *      eligibilityYear: // value for 'eligibilityYear'
 *      count: // value for 'count'
 *      includeAll: // value for 'includeAll'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useGetCollegeSpringPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollegeSpringPlayersQuery,
    GetCollegeSpringPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollegeSpringPlayersQuery,
    GetCollegeSpringPlayersQueryVariables
  >(GetCollegeSpringPlayersDocument, options);
}
export function useGetCollegeSpringPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollegeSpringPlayersQuery,
    GetCollegeSpringPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollegeSpringPlayersQuery,
    GetCollegeSpringPlayersQueryVariables
  >(GetCollegeSpringPlayersDocument, options);
}
export type GetCollegeSpringPlayersQueryHookResult = ReturnType<
  typeof useGetCollegeSpringPlayersQuery
>;
export type GetCollegeSpringPlayersLazyQueryHookResult = ReturnType<
  typeof useGetCollegeSpringPlayersLazyQuery
>;
export type GetCollegeSpringPlayersQueryResult = Apollo.QueryResult<
  GetCollegeSpringPlayersQuery,
  GetCollegeSpringPlayersQueryVariables
>;
export const GetReportStatsForPlayerDocument = gql`
  query GetReportStatsForPlayer(
    $playerId: UUID
    $leagueType: Int
    $seasonType: String
  ) {
    seasonStats(
      order: { season: DESC }
      where: {
        and: [
          { playerId: { eq: $playerId } }
          { leagueType: { id: { eq: $leagueType } } }
          { seasonType: { abbreviation: { eq: $seasonType } } }
        ]
      }
    ) {
      ...SeasonStats
    }
  }
  ${SeasonStatsFragmentDoc}
`;

/**
 * __useGetReportStatsForPlayerQuery__
 *
 * To run a query within a React component, call `useGetReportStatsForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportStatsForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportStatsForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      leagueType: // value for 'leagueType'
 *      seasonType: // value for 'seasonType'
 *   },
 * });
 */
export function useGetReportStatsForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetReportStatsForPlayerQuery,
    GetReportStatsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReportStatsForPlayerQuery,
    GetReportStatsForPlayerQueryVariables
  >(GetReportStatsForPlayerDocument, options);
}
export function useGetReportStatsForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReportStatsForPlayerQuery,
    GetReportStatsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReportStatsForPlayerQuery,
    GetReportStatsForPlayerQueryVariables
  >(GetReportStatsForPlayerDocument, options);
}
export type GetReportStatsForPlayerQueryHookResult = ReturnType<
  typeof useGetReportStatsForPlayerQuery
>;
export type GetReportStatsForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetReportStatsForPlayerLazyQuery
>;
export type GetReportStatsForPlayerQueryResult = Apollo.QueryResult<
  GetReportStatsForPlayerQuery,
  GetReportStatsForPlayerQueryVariables
>;
export const GetAdvancedAnalyticsForPlayerDocument = gql`
  query GetAdvancedAnalyticsForPlayer($playerId: UUID, $season: Int) {
    playerAdvancedAnalytics(
      where: {
        and: [
          { marvalPlayerId: { eq: $playerId } }
          { season: { eq: $season } }
        ]
      }
    ) {
      ...AdvancedAnalytics
    }
  }
  ${AdvancedAnalyticsFragmentDoc}
`;

/**
 * __useGetAdvancedAnalyticsForPlayerQuery__
 *
 * To run a query within a React component, call `useGetAdvancedAnalyticsForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvancedAnalyticsForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvancedAnalyticsForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      season: // value for 'season'
 *   },
 * });
 */
export function useGetAdvancedAnalyticsForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAdvancedAnalyticsForPlayerQuery,
    GetAdvancedAnalyticsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAdvancedAnalyticsForPlayerQuery,
    GetAdvancedAnalyticsForPlayerQueryVariables
  >(GetAdvancedAnalyticsForPlayerDocument, options);
}
export function useGetAdvancedAnalyticsForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdvancedAnalyticsForPlayerQuery,
    GetAdvancedAnalyticsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAdvancedAnalyticsForPlayerQuery,
    GetAdvancedAnalyticsForPlayerQueryVariables
  >(GetAdvancedAnalyticsForPlayerDocument, options);
}
export type GetAdvancedAnalyticsForPlayerQueryHookResult = ReturnType<
  typeof useGetAdvancedAnalyticsForPlayerQuery
>;
export type GetAdvancedAnalyticsForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetAdvancedAnalyticsForPlayerLazyQuery
>;
export type GetAdvancedAnalyticsForPlayerQueryResult = Apollo.QueryResult<
  GetAdvancedAnalyticsForPlayerQuery,
  GetAdvancedAnalyticsForPlayerQueryVariables
>;
export const GetMarketTierForPlayerDocument = gql`
  query GetMarketTierForPlayer($ids: [UUID]) {
    playerMarketTiers(where: { marvalPlayerId: { in: $ids } }) {
      marvalPlayerId
      currentContractTier
      ufaContractTier
    }
  }
`;

/**
 * __useGetMarketTierForPlayerQuery__
 *
 * To run a query within a React component, call `useGetMarketTierForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketTierForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketTierForPlayerQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMarketTierForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMarketTierForPlayerQuery,
    GetMarketTierForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMarketTierForPlayerQuery,
    GetMarketTierForPlayerQueryVariables
  >(GetMarketTierForPlayerDocument, options);
}
export function useGetMarketTierForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMarketTierForPlayerQuery,
    GetMarketTierForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMarketTierForPlayerQuery,
    GetMarketTierForPlayerQueryVariables
  >(GetMarketTierForPlayerDocument, options);
}
export type GetMarketTierForPlayerQueryHookResult = ReturnType<
  typeof useGetMarketTierForPlayerQuery
>;
export type GetMarketTierForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetMarketTierForPlayerLazyQuery
>;
export type GetMarketTierForPlayerQueryResult = Apollo.QueryResult<
  GetMarketTierForPlayerQuery,
  GetMarketTierForPlayerQueryVariables
>;
export const GetWarForPlayerDocument = gql`
  query GetWarForPlayer($playerId: UUID!, $league: String) {
    playerAdvancedAnalytics(
      where: {
        and: [
          { marvalPlayerId: { eq: $playerId } }
          { league: { eq: $league } }
        ]
      }
    ) {
      ...PlayerWar
    }
  }
  ${PlayerWarFragmentDoc}
`;

/**
 * __useGetWarForPlayerQuery__
 *
 * To run a query within a React component, call `useGetWarForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      league: // value for 'league'
 *   },
 * });
 */
export function useGetWarForPlayerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWarForPlayerQuery,
    GetWarForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWarForPlayerQuery, GetWarForPlayerQueryVariables>(
    GetWarForPlayerDocument,
    options,
  );
}
export function useGetWarForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWarForPlayerQuery,
    GetWarForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWarForPlayerQuery,
    GetWarForPlayerQueryVariables
  >(GetWarForPlayerDocument, options);
}
export type GetWarForPlayerQueryHookResult = ReturnType<
  typeof useGetWarForPlayerQuery
>;
export type GetWarForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetWarForPlayerLazyQuery
>;
export type GetWarForPlayerQueryResult = Apollo.QueryResult<
  GetWarForPlayerQuery,
  GetWarForPlayerQueryVariables
>;
export const GetPositionMarketTiersDocument = gql`
  query GetPositionMarketTiers {
    marketTiersByPosition {
      sumerGeneralPosition
      tier
      totalGuaranteedMin
      totalGuaranteedMax
      totalApyMin
      totalApyMax
    }
  }
`;

/**
 * __useGetPositionMarketTiersQuery__
 *
 * To run a query within a React component, call `useGetPositionMarketTiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionMarketTiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionMarketTiersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPositionMarketTiersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPositionMarketTiersQuery,
    GetPositionMarketTiersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPositionMarketTiersQuery,
    GetPositionMarketTiersQueryVariables
  >(GetPositionMarketTiersDocument, options);
}
export function useGetPositionMarketTiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPositionMarketTiersQuery,
    GetPositionMarketTiersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPositionMarketTiersQuery,
    GetPositionMarketTiersQueryVariables
  >(GetPositionMarketTiersDocument, options);
}
export type GetPositionMarketTiersQueryHookResult = ReturnType<
  typeof useGetPositionMarketTiersQuery
>;
export type GetPositionMarketTiersLazyQueryHookResult = ReturnType<
  typeof useGetPositionMarketTiersLazyQuery
>;
export type GetPositionMarketTiersQueryResult = Apollo.QueryResult<
  GetPositionMarketTiersQuery,
  GetPositionMarketTiersQueryVariables
>;
export const GetUfaPlayersDocument = gql`
  query GetUfaPlayers($count: Int!) {
    unRestrictedFreeAgents(request: { count: $count }) {
      ...UfaPlayer
    }
  }
  ${UfaPlayerFragmentDoc}
`;

/**
 * __useGetUfaPlayersQuery__
 *
 * To run a query within a React component, call `useGetUfaPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUfaPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUfaPlayersQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetUfaPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUfaPlayersQuery,
    GetUfaPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUfaPlayersQuery, GetUfaPlayersQueryVariables>(
    GetUfaPlayersDocument,
    options,
  );
}
export function useGetUfaPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUfaPlayersQuery,
    GetUfaPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUfaPlayersQuery, GetUfaPlayersQueryVariables>(
    GetUfaPlayersDocument,
    options,
  );
}
export type GetUfaPlayersQueryHookResult = ReturnType<
  typeof useGetUfaPlayersQuery
>;
export type GetUfaPlayersLazyQueryHookResult = ReturnType<
  typeof useGetUfaPlayersLazyQuery
>;
export type GetUfaPlayersQueryResult = Apollo.QueryResult<
  GetUfaPlayersQuery,
  GetUfaPlayersQueryVariables
>;
export const GetProReportByPlayerIdDocument = gql`
  query GetProReportByPlayerId($playerId: UUID!) {
    playerById(id: $playerId) {
      ...ReportPlayer
    }
  }
  ${ReportPlayerFragmentDoc}
`;

/**
 * __useGetProReportByPlayerIdQuery__
 *
 * To run a query within a React component, call `useGetProReportByPlayerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProReportByPlayerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProReportByPlayerIdQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetProReportByPlayerIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProReportByPlayerIdQuery,
    GetProReportByPlayerIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProReportByPlayerIdQuery,
    GetProReportByPlayerIdQueryVariables
  >(GetProReportByPlayerIdDocument, options);
}
export function useGetProReportByPlayerIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProReportByPlayerIdQuery,
    GetProReportByPlayerIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProReportByPlayerIdQuery,
    GetProReportByPlayerIdQueryVariables
  >(GetProReportByPlayerIdDocument, options);
}
export type GetProReportByPlayerIdQueryHookResult = ReturnType<
  typeof useGetProReportByPlayerIdQuery
>;
export type GetProReportByPlayerIdLazyQueryHookResult = ReturnType<
  typeof useGetProReportByPlayerIdLazyQuery
>;
export type GetProReportByPlayerIdQueryResult = Apollo.QueryResult<
  GetProReportByPlayerIdQuery,
  GetProReportByPlayerIdQueryVariables
>;
export const GetCollegeRosterEvaluationsDocument = gql`
  query GetCollegeRosterEvaluations(
    $schoolId: UUID!
    $overrideSchoolId: String
    $eligibilityYear: Int
  ) {
    rosterPlayers(
      where: {
        and: [
          { eligibilityYear: { gte: $eligibilityYear } }
          { draftYear: { eq: null } }
        ]
        or: [
          { schoolId: { eq: $schoolId } }
          { playerOverrides: { some: { value: { eq: $overrideSchoolId } } } }
        ]
      }
    ) {
      ...CollegeEvaluationPlayer
    }
  }
  ${CollegeEvaluationPlayerFragmentDoc}
`;

/**
 * __useGetCollegeRosterEvaluationsQuery__
 *
 * To run a query within a React component, call `useGetCollegeRosterEvaluationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollegeRosterEvaluationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollegeRosterEvaluationsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      overrideSchoolId: // value for 'overrideSchoolId'
 *      eligibilityYear: // value for 'eligibilityYear'
 *   },
 * });
 */
export function useGetCollegeRosterEvaluationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollegeRosterEvaluationsQuery,
    GetCollegeRosterEvaluationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCollegeRosterEvaluationsQuery,
    GetCollegeRosterEvaluationsQueryVariables
  >(GetCollegeRosterEvaluationsDocument, options);
}
export function useGetCollegeRosterEvaluationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollegeRosterEvaluationsQuery,
    GetCollegeRosterEvaluationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollegeRosterEvaluationsQuery,
    GetCollegeRosterEvaluationsQueryVariables
  >(GetCollegeRosterEvaluationsDocument, options);
}
export type GetCollegeRosterEvaluationsQueryHookResult = ReturnType<
  typeof useGetCollegeRosterEvaluationsQuery
>;
export type GetCollegeRosterEvaluationsLazyQueryHookResult = ReturnType<
  typeof useGetCollegeRosterEvaluationsLazyQuery
>;
export type GetCollegeRosterEvaluationsQueryResult = Apollo.QueryResult<
  GetCollegeRosterEvaluationsQuery,
  GetCollegeRosterEvaluationsQueryVariables
>;
export const GetSearchIndexStatusDocument = gql`
  query GetSearchIndexStatus {
    indexStatus {
      playersDocumentCount
      playersDatabaseCount
      schoolsDocumentCount
      schoolsDatabaseCount
      clubsDocumentCount
      clubsDatabaseCount
    }
  }
`;

/**
 * __useGetSearchIndexStatusQuery__
 *
 * To run a query within a React component, call `useGetSearchIndexStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchIndexStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchIndexStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSearchIndexStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSearchIndexStatusQuery,
    GetSearchIndexStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSearchIndexStatusQuery,
    GetSearchIndexStatusQueryVariables
  >(GetSearchIndexStatusDocument, options);
}
export function useGetSearchIndexStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchIndexStatusQuery,
    GetSearchIndexStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchIndexStatusQuery,
    GetSearchIndexStatusQueryVariables
  >(GetSearchIndexStatusDocument, options);
}
export type GetSearchIndexStatusQueryHookResult = ReturnType<
  typeof useGetSearchIndexStatusQuery
>;
export type GetSearchIndexStatusLazyQueryHookResult = ReturnType<
  typeof useGetSearchIndexStatusLazyQuery
>;
export type GetSearchIndexStatusQueryResult = Apollo.QueryResult<
  GetSearchIndexStatusQuery,
  GetSearchIndexStatusQueryVariables
>;
export const ResetPlayerSearchIndexDocument = gql`
  mutation ResetPlayerSearchIndex {
    resetPlayerSearchIndices(
      resetPlayerSearchIndicesRequest: {
        skipIndexRecreation: false
        refreshClubs: false
        refreshSchools: false
        refreshPlayers: true
      }
    )
  }
`;
export type ResetPlayerSearchIndexMutationFn = Apollo.MutationFunction<
  ResetPlayerSearchIndexMutation,
  ResetPlayerSearchIndexMutationVariables
>;

/**
 * __useResetPlayerSearchIndexMutation__
 *
 * To run a mutation, you first call `useResetPlayerSearchIndexMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPlayerSearchIndexMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPlayerSearchIndexMutation, { data, loading, error }] = useResetPlayerSearchIndexMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetPlayerSearchIndexMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPlayerSearchIndexMutation,
    ResetPlayerSearchIndexMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPlayerSearchIndexMutation,
    ResetPlayerSearchIndexMutationVariables
  >(ResetPlayerSearchIndexDocument, options);
}
export type ResetPlayerSearchIndexMutationHookResult = ReturnType<
  typeof useResetPlayerSearchIndexMutation
>;
export type ResetPlayerSearchIndexMutationResult =
  Apollo.MutationResult<ResetPlayerSearchIndexMutation>;
export type ResetPlayerSearchIndexMutationOptions = Apollo.BaseMutationOptions<
  ResetPlayerSearchIndexMutation,
  ResetPlayerSearchIndexMutationVariables
>;
export const GetPositionAtlasDocument = gql`
  query GetPositionAtlas {
    atlas {
      nodes {
        id
        nodeId
        nodeType
        children {
          nodeId
          id
        }
      }
    }
    positionMaps {
      nodeId
      positionSetName
    }
  }
`;

/**
 * __useGetPositionAtlasQuery__
 *
 * To run a query within a React component, call `useGetPositionAtlasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionAtlasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionAtlasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPositionAtlasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPositionAtlasQuery,
    GetPositionAtlasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPositionAtlasQuery, GetPositionAtlasQueryVariables>(
    GetPositionAtlasDocument,
    options,
  );
}
export function useGetPositionAtlasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPositionAtlasQuery,
    GetPositionAtlasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPositionAtlasQuery,
    GetPositionAtlasQueryVariables
  >(GetPositionAtlasDocument, options);
}
export type GetPositionAtlasQueryHookResult = ReturnType<
  typeof useGetPositionAtlasQuery
>;
export type GetPositionAtlasLazyQueryHookResult = ReturnType<
  typeof useGetPositionAtlasLazyQuery
>;
export type GetPositionAtlasQueryResult = Apollo.QueryResult<
  GetPositionAtlasQuery,
  GetPositionAtlasQueryVariables
>;
export const GetClubsDocument = gql`
  query GetClubs {
    clubs(order: { clubCode: ASC }) {
      id
      clubCode
      clubName
      conference
      division
      primaryColor
      secondaryColor
      nickName
    }
  }
`;

/**
 * __useGetClubsQuery__
 *
 * To run a query within a React component, call `useGetClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClubsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetClubsQuery, GetClubsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClubsQuery, GetClubsQueryVariables>(
    GetClubsDocument,
    options,
  );
}
export function useGetClubsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubsQuery,
    GetClubsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClubsQuery, GetClubsQueryVariables>(
    GetClubsDocument,
    options,
  );
}
export type GetClubsQueryHookResult = ReturnType<typeof useGetClubsQuery>;
export type GetClubsLazyQueryHookResult = ReturnType<
  typeof useGetClubsLazyQuery
>;
export type GetClubsQueryResult = Apollo.QueryResult<
  GetClubsQuery,
  GetClubsQueryVariables
>;
export const GetClubDocument = gql`
  query getClub($id: UUID!) {
    clubById(id: $id) {
      ...Club
    }
  }
  ${ClubFragmentDoc}
`;

/**
 * __useGetClubQuery__
 *
 * To run a query within a React component, call `useGetClubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClubQuery(
  baseOptions: Apollo.QueryHookOptions<GetClubQuery, GetClubQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClubQuery, GetClubQueryVariables>(
    GetClubDocument,
    options,
  );
}
export function useGetClubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubQuery,
    GetClubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClubQuery, GetClubQueryVariables>(
    GetClubDocument,
    options,
  );
}
export type GetClubQueryHookResult = ReturnType<typeof useGetClubQuery>;
export type GetClubLazyQueryHookResult = ReturnType<typeof useGetClubLazyQuery>;
export type GetClubQueryResult = Apollo.QueryResult<
  GetClubQuery,
  GetClubQueryVariables
>;
export const UpdateContractSummaryDocument = gql`
  mutation UpdateContractSummary(
    $updateContractSummaryRequest: UpdateContractSummaryRequest!
  ) {
    updateContractSummary(
      updateContractSummaryRequest: $updateContractSummaryRequest
    ) {
      id
      sumer {
        existingAmount
        existingAmountGuaranteed
        existingYears
        functionalAmount
        functionalYears
        practicalGuaranteed
        comments
        evaluated
        isRework
      }
    }
  }
`;
export type UpdateContractSummaryMutationFn = Apollo.MutationFunction<
  UpdateContractSummaryMutation,
  UpdateContractSummaryMutationVariables
>;

/**
 * __useUpdateContractSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateContractSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractSummaryMutation, { data, loading, error }] = useUpdateContractSummaryMutation({
 *   variables: {
 *      updateContractSummaryRequest: // value for 'updateContractSummaryRequest'
 *   },
 * });
 */
export function useUpdateContractSummaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractSummaryMutation,
    UpdateContractSummaryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContractSummaryMutation,
    UpdateContractSummaryMutationVariables
  >(UpdateContractSummaryDocument, options);
}
export type UpdateContractSummaryMutationHookResult = ReturnType<
  typeof useUpdateContractSummaryMutation
>;
export type UpdateContractSummaryMutationResult =
  Apollo.MutationResult<UpdateContractSummaryMutation>;
export type UpdateContractSummaryMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractSummaryMutation,
  UpdateContractSummaryMutationVariables
>;
export const CreateWorksheetItemDocument = gql`
  mutation CreateWorksheetItem(
    $createWorksheetItemRequest: CreateWorksheetItemRequest!
  ) {
    createWorksheetItem(
      createWorksheetItemRequest: $createWorksheetItemRequest
    ) {
      id
      season
      item
      amount
      guaranteedAmount
      vestingDate
      vestingOption
      deferred
      deferredAmount
      deferredDate
      comments
    }
  }
`;
export type CreateWorksheetItemMutationFn = Apollo.MutationFunction<
  CreateWorksheetItemMutation,
  CreateWorksheetItemMutationVariables
>;

/**
 * __useCreateWorksheetItemMutation__
 *
 * To run a mutation, you first call `useCreateWorksheetItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorksheetItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorksheetItemMutation, { data, loading, error }] = useCreateWorksheetItemMutation({
 *   variables: {
 *      createWorksheetItemRequest: // value for 'createWorksheetItemRequest'
 *   },
 * });
 */
export function useCreateWorksheetItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorksheetItemMutation,
    CreateWorksheetItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWorksheetItemMutation,
    CreateWorksheetItemMutationVariables
  >(CreateWorksheetItemDocument, options);
}
export type CreateWorksheetItemMutationHookResult = ReturnType<
  typeof useCreateWorksheetItemMutation
>;
export type CreateWorksheetItemMutationResult =
  Apollo.MutationResult<CreateWorksheetItemMutation>;
export type CreateWorksheetItemMutationOptions = Apollo.BaseMutationOptions<
  CreateWorksheetItemMutation,
  CreateWorksheetItemMutationVariables
>;
export const UpdateWorksheetItemDocument = gql`
  mutation UpdateWorksheetItem(
    $updateWorksheetItemRequest: UpdateWorksheetItemRequest!
  ) {
    updateWorksheetItem(
      updateWorksheetItemRequest: $updateWorksheetItemRequest
    ) {
      id
      season
      item
      amount
      guaranteedAmount
      vestingDate
      vestingOption
      deferred
      deferredAmount
      deferredDate
      comments
    }
  }
`;
export type UpdateWorksheetItemMutationFn = Apollo.MutationFunction<
  UpdateWorksheetItemMutation,
  UpdateWorksheetItemMutationVariables
>;

/**
 * __useUpdateWorksheetItemMutation__
 *
 * To run a mutation, you first call `useUpdateWorksheetItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorksheetItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorksheetItemMutation, { data, loading, error }] = useUpdateWorksheetItemMutation({
 *   variables: {
 *      updateWorksheetItemRequest: // value for 'updateWorksheetItemRequest'
 *   },
 * });
 */
export function useUpdateWorksheetItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorksheetItemMutation,
    UpdateWorksheetItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorksheetItemMutation,
    UpdateWorksheetItemMutationVariables
  >(UpdateWorksheetItemDocument, options);
}
export type UpdateWorksheetItemMutationHookResult = ReturnType<
  typeof useUpdateWorksheetItemMutation
>;
export type UpdateWorksheetItemMutationResult =
  Apollo.MutationResult<UpdateWorksheetItemMutation>;
export type UpdateWorksheetItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorksheetItemMutation,
  UpdateWorksheetItemMutationVariables
>;
export const ResetContractDocument = gql`
  mutation ResetContract($resetContractRequest: ResetContractRequest!) {
    resetContract(resetContractRequest: $resetContractRequest) {
      id
    }
  }
`;
export type ResetContractMutationFn = Apollo.MutationFunction<
  ResetContractMutation,
  ResetContractMutationVariables
>;

/**
 * __useResetContractMutation__
 *
 * To run a mutation, you first call `useResetContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetContractMutation, { data, loading, error }] = useResetContractMutation({
 *   variables: {
 *      resetContractRequest: // value for 'resetContractRequest'
 *   },
 * });
 */
export function useResetContractMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetContractMutation,
    ResetContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetContractMutation,
    ResetContractMutationVariables
  >(ResetContractDocument, options);
}
export type ResetContractMutationHookResult = ReturnType<
  typeof useResetContractMutation
>;
export type ResetContractMutationResult =
  Apollo.MutationResult<ResetContractMutation>;
export type ResetContractMutationOptions = Apollo.BaseMutationOptions<
  ResetContractMutation,
  ResetContractMutationVariables
>;
export const DeleteWorksheetItemDocument = gql`
  mutation DeleteWorksheetItem(
    $deleteWorksheetItemRequest: DeleteWorksheetItemRequest!
  ) {
    deleteWorksheetItem(
      deleteWorksheetItemRequest: $deleteWorksheetItemRequest
    ) {
      id
    }
  }
`;
export type DeleteWorksheetItemMutationFn = Apollo.MutationFunction<
  DeleteWorksheetItemMutation,
  DeleteWorksheetItemMutationVariables
>;

/**
 * __useDeleteWorksheetItemMutation__
 *
 * To run a mutation, you first call `useDeleteWorksheetItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorksheetItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorksheetItemMutation, { data, loading, error }] = useDeleteWorksheetItemMutation({
 *   variables: {
 *      deleteWorksheetItemRequest: // value for 'deleteWorksheetItemRequest'
 *   },
 * });
 */
export function useDeleteWorksheetItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorksheetItemMutation,
    DeleteWorksheetItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWorksheetItemMutation,
    DeleteWorksheetItemMutationVariables
  >(DeleteWorksheetItemDocument, options);
}
export type DeleteWorksheetItemMutationHookResult = ReturnType<
  typeof useDeleteWorksheetItemMutation
>;
export type DeleteWorksheetItemMutationResult =
  Apollo.MutationResult<DeleteWorksheetItemMutation>;
export type DeleteWorksheetItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorksheetItemMutation,
  DeleteWorksheetItemMutationVariables
>;
export const UpdateContractTotalsDocument = gql`
  mutation UpdateContractTotals(
    $updateContractTotalsRequest: AssignContractTotalsRequest!
  ) {
    updateContractTotals(
      updateContractTotalsRequest: $updateContractTotalsRequest
    ) {
      id
    }
  }
`;
export type UpdateContractTotalsMutationFn = Apollo.MutationFunction<
  UpdateContractTotalsMutation,
  UpdateContractTotalsMutationVariables
>;

/**
 * __useUpdateContractTotalsMutation__
 *
 * To run a mutation, you first call `useUpdateContractTotalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractTotalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractTotalsMutation, { data, loading, error }] = useUpdateContractTotalsMutation({
 *   variables: {
 *      updateContractTotalsRequest: // value for 'updateContractTotalsRequest'
 *   },
 * });
 */
export function useUpdateContractTotalsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContractTotalsMutation,
    UpdateContractTotalsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContractTotalsMutation,
    UpdateContractTotalsMutationVariables
  >(UpdateContractTotalsDocument, options);
}
export type UpdateContractTotalsMutationHookResult = ReturnType<
  typeof useUpdateContractTotalsMutation
>;
export type UpdateContractTotalsMutationResult =
  Apollo.MutationResult<UpdateContractTotalsMutation>;
export type UpdateContractTotalsMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractTotalsMutation,
  UpdateContractTotalsMutationVariables
>;
export const GetPlayerContractsByIdDocument = gql`
  query GetPlayerContractsById($id: UUID!, $effectiveOnly: Boolean!) {
    playerContractsById(
      id: $id
      effectiveOnly: $effectiveOnly
      order: { signDate: DESC }
    ) {
      ...Contract
    }
  }
  ${ContractFragmentDoc}
`;

/**
 * __useGetPlayerContractsByIdQuery__
 *
 * To run a query within a React component, call `useGetPlayerContractsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerContractsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerContractsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      effectiveOnly: // value for 'effectiveOnly'
 *   },
 * });
 */
export function useGetPlayerContractsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayerContractsByIdQuery,
    GetPlayerContractsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlayerContractsByIdQuery,
    GetPlayerContractsByIdQueryVariables
  >(GetPlayerContractsByIdDocument, options);
}
export function useGetPlayerContractsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerContractsByIdQuery,
    GetPlayerContractsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayerContractsByIdQuery,
    GetPlayerContractsByIdQueryVariables
  >(GetPlayerContractsByIdDocument, options);
}
export type GetPlayerContractsByIdQueryHookResult = ReturnType<
  typeof useGetPlayerContractsByIdQuery
>;
export type GetPlayerContractsByIdLazyQueryHookResult = ReturnType<
  typeof useGetPlayerContractsByIdLazyQuery
>;
export type GetPlayerContractsByIdQueryResult = Apollo.QueryResult<
  GetPlayerContractsByIdQuery,
  GetPlayerContractsByIdQueryVariables
>;
export const GetPlayerTransactionContractByIdDocument = gql`
  query GetPlayerTransactionContractById($id: UUID!) {
    playerContractsById(
      id: $id
      effectiveOnly: true
      order: { signDate: DESC }
    ) {
      ...TransactionContract
    }
  }
  ${TransactionContractFragmentDoc}
`;

/**
 * __useGetPlayerTransactionContractByIdQuery__
 *
 * To run a query within a React component, call `useGetPlayerTransactionContractByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerTransactionContractByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerTransactionContractByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerTransactionContractByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayerTransactionContractByIdQuery,
    GetPlayerTransactionContractByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlayerTransactionContractByIdQuery,
    GetPlayerTransactionContractByIdQueryVariables
  >(GetPlayerTransactionContractByIdDocument, options);
}
export function useGetPlayerTransactionContractByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerTransactionContractByIdQuery,
    GetPlayerTransactionContractByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayerTransactionContractByIdQuery,
    GetPlayerTransactionContractByIdQueryVariables
  >(GetPlayerTransactionContractByIdDocument, options);
}
export type GetPlayerTransactionContractByIdQueryHookResult = ReturnType<
  typeof useGetPlayerTransactionContractByIdQuery
>;
export type GetPlayerTransactionContractByIdLazyQueryHookResult = ReturnType<
  typeof useGetPlayerTransactionContractByIdLazyQuery
>;
export type GetPlayerTransactionContractByIdQueryResult = Apollo.QueryResult<
  GetPlayerTransactionContractByIdQuery,
  GetPlayerTransactionContractByIdQueryVariables
>;
export const GetContractByIdDocument = gql`
  query GetContractById($id: UUID!) {
    contracts(where: { id: { eq: $id } }) {
      ...Contract
    }
  }
  ${ContractFragmentDoc}
`;

/**
 * __useGetContractByIdQuery__
 *
 * To run a query within a React component, call `useGetContractByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractByIdQuery,
    GetContractByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractByIdQuery, GetContractByIdQueryVariables>(
    GetContractByIdDocument,
    options,
  );
}
export function useGetContractByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractByIdQuery,
    GetContractByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractByIdQuery,
    GetContractByIdQueryVariables
  >(GetContractByIdDocument, options);
}
export type GetContractByIdQueryHookResult = ReturnType<
  typeof useGetContractByIdQuery
>;
export type GetContractByIdLazyQueryHookResult = ReturnType<
  typeof useGetContractByIdLazyQuery
>;
export type GetContractByIdQueryResult = Apollo.QueryResult<
  GetContractByIdQuery,
  GetContractByIdQueryVariables
>;
export const GetContractWorksheetItemsByIdDocument = gql`
  query GetContractWorksheetItemsById($id: UUID!) {
    contractWorksheetItemsById(id: $id, order: { season: ASC, item: DESC }) {
      id
      contractId
      season
      label
      item
      amount
      prorate
      proratedAmount
      guaranteed
      guaranteedType
      guaranteedAmount
      conversionType
      vestingDate
      comments
      vestingOption
      day
      deferred
      deferredDate
      deferredAmount
    }
  }
`;

/**
 * __useGetContractWorksheetItemsByIdQuery__
 *
 * To run a query within a React component, call `useGetContractWorksheetItemsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractWorksheetItemsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractWorksheetItemsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContractWorksheetItemsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContractWorksheetItemsByIdQuery,
    GetContractWorksheetItemsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetContractWorksheetItemsByIdQuery,
    GetContractWorksheetItemsByIdQueryVariables
  >(GetContractWorksheetItemsByIdDocument, options);
}
export function useGetContractWorksheetItemsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContractWorksheetItemsByIdQuery,
    GetContractWorksheetItemsByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContractWorksheetItemsByIdQuery,
    GetContractWorksheetItemsByIdQueryVariables
  >(GetContractWorksheetItemsByIdDocument, options);
}
export type GetContractWorksheetItemsByIdQueryHookResult = ReturnType<
  typeof useGetContractWorksheetItemsByIdQuery
>;
export type GetContractWorksheetItemsByIdLazyQueryHookResult = ReturnType<
  typeof useGetContractWorksheetItemsByIdLazyQuery
>;
export type GetContractWorksheetItemsByIdQueryResult = Apollo.QueryResult<
  GetContractWorksheetItemsByIdQuery,
  GetContractWorksheetItemsByIdQueryVariables
>;
export const GetPlayerContractsDocument = gql`
  query GetPlayerContracts($ids: [UUID]) {
    contracts(
      where: { playerId: { in: $ids }, isEffectiveContract: { eq: true } }
    ) {
      id
      playerId
      clubId
      isEffectiveContract
      effectiveLastContractYear
      sumer {
        isRework
        evaluated
      }
      total {
        guaranteed
        apy
      }
    }
  }
`;

/**
 * __useGetPlayerContractsQuery__
 *
 * To run a query within a React component, call `useGetPlayerContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerContractsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetPlayerContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlayerContractsQuery,
    GetPlayerContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlayerContractsQuery,
    GetPlayerContractsQueryVariables
  >(GetPlayerContractsDocument, options);
}
export function useGetPlayerContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerContractsQuery,
    GetPlayerContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayerContractsQuery,
    GetPlayerContractsQueryVariables
  >(GetPlayerContractsDocument, options);
}
export type GetPlayerContractsQueryHookResult = ReturnType<
  typeof useGetPlayerContractsQuery
>;
export type GetPlayerContractsLazyQueryHookResult = ReturnType<
  typeof useGetPlayerContractsLazyQuery
>;
export type GetPlayerContractsQueryResult = Apollo.QueryResult<
  GetPlayerContractsQuery,
  GetPlayerContractsQueryVariables
>;
export const GetSalaryCapAccountingByPlayerDocument = gql`
  query GetSalaryCapAccountingByPlayer($id: UUID!) {
    leagueSalaryCapRollupsByPlayer(playerId: $id, order: { season: ASC }) {
      id
      playerId
      season
      capAmount
      para5
      signingBonus
      ltbe
      miscellaneous
      potAccel
      fullPara5
      reAllocation
      vetCapCredit
      loadDate
    }
    leagueSalaryCapDetailsByPlayer(playerId: $id, order: { season: ASC }) {
      id
      playerId
      season
      capAmount
      cashAmount
      sectionId
      sectionType
      sectionTitle
      clubCode
      signDate
    }
  }
`;

/**
 * __useGetSalaryCapAccountingByPlayerQuery__
 *
 * To run a query within a React component, call `useGetSalaryCapAccountingByPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryCapAccountingByPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryCapAccountingByPlayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSalaryCapAccountingByPlayerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalaryCapAccountingByPlayerQuery,
    GetSalaryCapAccountingByPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSalaryCapAccountingByPlayerQuery,
    GetSalaryCapAccountingByPlayerQueryVariables
  >(GetSalaryCapAccountingByPlayerDocument, options);
}
export function useGetSalaryCapAccountingByPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalaryCapAccountingByPlayerQuery,
    GetSalaryCapAccountingByPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSalaryCapAccountingByPlayerQuery,
    GetSalaryCapAccountingByPlayerQueryVariables
  >(GetSalaryCapAccountingByPlayerDocument, options);
}
export type GetSalaryCapAccountingByPlayerQueryHookResult = ReturnType<
  typeof useGetSalaryCapAccountingByPlayerQuery
>;
export type GetSalaryCapAccountingByPlayerLazyQueryHookResult = ReturnType<
  typeof useGetSalaryCapAccountingByPlayerLazyQuery
>;
export type GetSalaryCapAccountingByPlayerQueryResult = Apollo.QueryResult<
  GetSalaryCapAccountingByPlayerQuery,
  GetSalaryCapAccountingByPlayerQueryVariables
>;
export const GetLeagueCapAmountsDocument = gql`
  query GetLeagueCapAmounts {
    leagueCapAmounts {
      id
      season
      capAmount
      minRookieP5
    }
  }
`;

/**
 * __useGetLeagueCapAmountsQuery__
 *
 * To run a query within a React component, call `useGetLeagueCapAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeagueCapAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeagueCapAmountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLeagueCapAmountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLeagueCapAmountsQuery,
    GetLeagueCapAmountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeagueCapAmountsQuery,
    GetLeagueCapAmountsQueryVariables
  >(GetLeagueCapAmountsDocument, options);
}
export function useGetLeagueCapAmountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeagueCapAmountsQuery,
    GetLeagueCapAmountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeagueCapAmountsQuery,
    GetLeagueCapAmountsQueryVariables
  >(GetLeagueCapAmountsDocument, options);
}
export type GetLeagueCapAmountsQueryHookResult = ReturnType<
  typeof useGetLeagueCapAmountsQuery
>;
export type GetLeagueCapAmountsLazyQueryHookResult = ReturnType<
  typeof useGetLeagueCapAmountsLazyQuery
>;
export type GetLeagueCapAmountsQueryResult = Apollo.QueryResult<
  GetLeagueCapAmountsQuery,
  GetLeagueCapAmountsQueryVariables
>;
export const GetLeagueClubCapAmountsDocument = gql`
  query GetLeagueClubCapAmounts($season: Int!) {
    leagueClubCapAmounts(where: { season: { eq: $season } }) {
      id
      season
      clubId
      nflCap
      clubCap
      minRookieP5
      clubAdjustment
      carryOver
    }
  }
`;

/**
 * __useGetLeagueClubCapAmountsQuery__
 *
 * To run a query within a React component, call `useGetLeagueClubCapAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeagueClubCapAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeagueClubCapAmountsQuery({
 *   variables: {
 *      season: // value for 'season'
 *   },
 * });
 */
export function useGetLeagueClubCapAmountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLeagueClubCapAmountsQuery,
    GetLeagueClubCapAmountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeagueClubCapAmountsQuery,
    GetLeagueClubCapAmountsQueryVariables
  >(GetLeagueClubCapAmountsDocument, options);
}
export function useGetLeagueClubCapAmountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeagueClubCapAmountsQuery,
    GetLeagueClubCapAmountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeagueClubCapAmountsQuery,
    GetLeagueClubCapAmountsQueryVariables
  >(GetLeagueClubCapAmountsDocument, options);
}
export type GetLeagueClubCapAmountsQueryHookResult = ReturnType<
  typeof useGetLeagueClubCapAmountsQuery
>;
export type GetLeagueClubCapAmountsLazyQueryHookResult = ReturnType<
  typeof useGetLeagueClubCapAmountsLazyQuery
>;
export type GetLeagueClubCapAmountsQueryResult = Apollo.QueryResult<
  GetLeagueClubCapAmountsQuery,
  GetLeagueClubCapAmountsQueryVariables
>;
export const CreateEvaluationDocument = gql`
  mutation CreateEvaluation($request: CreateEvaluationRequest!) {
    createEvaluation(request: $request) {
      id
      season
      isFinal
      isPrimary
      playerId
      positionId
      position {
        id
        shortName
      }
      scoutGradeId
      scoutGrade {
        id
        grade
      }
      fits {
        ...EvaluationFit
      }
      alerts {
        ...EvaluationAlert
      }
      evalType {
        id
        type
      }
      summary
      bottomLine
      characterSummary
      lastUpdated
      finalizedOn
      status
    }
  }
  ${EvaluationFitFragmentDoc}
  ${EvaluationAlertFragmentDoc}
`;
export type CreateEvaluationMutationFn = Apollo.MutationFunction<
  CreateEvaluationMutation,
  CreateEvaluationMutationVariables
>;

/**
 * __useCreateEvaluationMutation__
 *
 * To run a mutation, you first call `useCreateEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvaluationMutation, { data, loading, error }] = useCreateEvaluationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEvaluationMutation,
    CreateEvaluationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEvaluationMutation,
    CreateEvaluationMutationVariables
  >(CreateEvaluationDocument, options);
}
export type CreateEvaluationMutationHookResult = ReturnType<
  typeof useCreateEvaluationMutation
>;
export type CreateEvaluationMutationResult =
  Apollo.MutationResult<CreateEvaluationMutation>;
export type CreateEvaluationMutationOptions = Apollo.BaseMutationOptions<
  CreateEvaluationMutation,
  CreateEvaluationMutationVariables
>;
export const UpdateEvaluationDocument = gql`
  mutation UpdateEvaluation($request: UpdateEvaluationRequest!) {
    updateEvaluation(request: $request) {
      id
      season
      isFinal
      isPrimary
      playerId
      positionId
      position {
        id
        shortName
      }
      scoutGradeId
      scoutGrade {
        id
        grade
      }
      fits {
        ...EvaluationFit
      }
      alerts {
        ...EvaluationAlert
      }
      evalType {
        type
      }
      summary
      bottomLine
      characterSummary
      lastUpdated
      finalizedOn
      status
    }
  }
  ${EvaluationFitFragmentDoc}
  ${EvaluationAlertFragmentDoc}
`;
export type UpdateEvaluationMutationFn = Apollo.MutationFunction<
  UpdateEvaluationMutation,
  UpdateEvaluationMutationVariables
>;

/**
 * __useUpdateEvaluationMutation__
 *
 * To run a mutation, you first call `useUpdateEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvaluationMutation, { data, loading, error }] = useUpdateEvaluationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEvaluationMutation,
    UpdateEvaluationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEvaluationMutation,
    UpdateEvaluationMutationVariables
  >(UpdateEvaluationDocument, options);
}
export type UpdateEvaluationMutationHookResult = ReturnType<
  typeof useUpdateEvaluationMutation
>;
export type UpdateEvaluationMutationResult =
  Apollo.MutationResult<UpdateEvaluationMutation>;
export type UpdateEvaluationMutationOptions = Apollo.BaseMutationOptions<
  UpdateEvaluationMutation,
  UpdateEvaluationMutationVariables
>;
export const SaveEvaluationTraitDocument = gql`
  mutation SaveEvaluationTrait($request: SaveEvaluationTraitRequest!) {
    saveEvaluationTrait(request: $request) {
      id
      traitGrades {
        id
        scoutGrade {
          id
          grade
          quality
        }
        traitDefinition {
          id
          caption
          shortName
          sort
        }
        comments
      }
    }
  }
`;
export type SaveEvaluationTraitMutationFn = Apollo.MutationFunction<
  SaveEvaluationTraitMutation,
  SaveEvaluationTraitMutationVariables
>;

/**
 * __useSaveEvaluationTraitMutation__
 *
 * To run a mutation, you first call `useSaveEvaluationTraitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEvaluationTraitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEvaluationTraitMutation, { data, loading, error }] = useSaveEvaluationTraitMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveEvaluationTraitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEvaluationTraitMutation,
    SaveEvaluationTraitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEvaluationTraitMutation,
    SaveEvaluationTraitMutationVariables
  >(SaveEvaluationTraitDocument, options);
}
export type SaveEvaluationTraitMutationHookResult = ReturnType<
  typeof useSaveEvaluationTraitMutation
>;
export type SaveEvaluationTraitMutationResult =
  Apollo.MutationResult<SaveEvaluationTraitMutation>;
export type SaveEvaluationTraitMutationOptions = Apollo.BaseMutationOptions<
  SaveEvaluationTraitMutation,
  SaveEvaluationTraitMutationVariables
>;
export const SaveEvaluationFitsDocument = gql`
  mutation SaveEvaluationFits($request: SaveEvaluationFitRequest!) {
    saveEvaluationFits(request: $request) {
      id
      fits {
        ...EvaluationFit
      }
    }
  }
  ${EvaluationFitFragmentDoc}
`;
export type SaveEvaluationFitsMutationFn = Apollo.MutationFunction<
  SaveEvaluationFitsMutation,
  SaveEvaluationFitsMutationVariables
>;

/**
 * __useSaveEvaluationFitsMutation__
 *
 * To run a mutation, you first call `useSaveEvaluationFitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEvaluationFitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEvaluationFitsMutation, { data, loading, error }] = useSaveEvaluationFitsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveEvaluationFitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEvaluationFitsMutation,
    SaveEvaluationFitsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEvaluationFitsMutation,
    SaveEvaluationFitsMutationVariables
  >(SaveEvaluationFitsDocument, options);
}
export type SaveEvaluationFitsMutationHookResult = ReturnType<
  typeof useSaveEvaluationFitsMutation
>;
export type SaveEvaluationFitsMutationResult =
  Apollo.MutationResult<SaveEvaluationFitsMutation>;
export type SaveEvaluationFitsMutationOptions = Apollo.BaseMutationOptions<
  SaveEvaluationFitsMutation,
  SaveEvaluationFitsMutationVariables
>;
export const SaveEvaluationClubFitsDocument = gql`
  mutation SaveEvaluationClubFits($request: SaveEvaluationClubFitRequest!) {
    saveEvaluationClubFits(request: $request) {
      id
      clubFits {
        ...EvaluationClubFit
      }
    }
  }
  ${EvaluationClubFitFragmentDoc}
`;
export type SaveEvaluationClubFitsMutationFn = Apollo.MutationFunction<
  SaveEvaluationClubFitsMutation,
  SaveEvaluationClubFitsMutationVariables
>;

/**
 * __useSaveEvaluationClubFitsMutation__
 *
 * To run a mutation, you first call `useSaveEvaluationClubFitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEvaluationClubFitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEvaluationClubFitsMutation, { data, loading, error }] = useSaveEvaluationClubFitsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveEvaluationClubFitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEvaluationClubFitsMutation,
    SaveEvaluationClubFitsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEvaluationClubFitsMutation,
    SaveEvaluationClubFitsMutationVariables
  >(SaveEvaluationClubFitsDocument, options);
}
export type SaveEvaluationClubFitsMutationHookResult = ReturnType<
  typeof useSaveEvaluationClubFitsMutation
>;
export type SaveEvaluationClubFitsMutationResult =
  Apollo.MutationResult<SaveEvaluationClubFitsMutation>;
export type SaveEvaluationClubFitsMutationOptions = Apollo.BaseMutationOptions<
  SaveEvaluationClubFitsMutation,
  SaveEvaluationClubFitsMutationVariables
>;
export const UnMarkEvaluationAsFinalDocument = gql`
  mutation UnMarkEvaluationAsFinal($request: UnMarkEvaluationAsFinalRequest!) {
    unMarkEvaluationAsFinal(request: $request) {
      id
      isFinal
      finalizedOn
    }
  }
`;
export type UnMarkEvaluationAsFinalMutationFn = Apollo.MutationFunction<
  UnMarkEvaluationAsFinalMutation,
  UnMarkEvaluationAsFinalMutationVariables
>;

/**
 * __useUnMarkEvaluationAsFinalMutation__
 *
 * To run a mutation, you first call `useUnMarkEvaluationAsFinalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnMarkEvaluationAsFinalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unMarkEvaluationAsFinalMutation, { data, loading, error }] = useUnMarkEvaluationAsFinalMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUnMarkEvaluationAsFinalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnMarkEvaluationAsFinalMutation,
    UnMarkEvaluationAsFinalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnMarkEvaluationAsFinalMutation,
    UnMarkEvaluationAsFinalMutationVariables
  >(UnMarkEvaluationAsFinalDocument, options);
}
export type UnMarkEvaluationAsFinalMutationHookResult = ReturnType<
  typeof useUnMarkEvaluationAsFinalMutation
>;
export type UnMarkEvaluationAsFinalMutationResult =
  Apollo.MutationResult<UnMarkEvaluationAsFinalMutation>;
export type UnMarkEvaluationAsFinalMutationOptions = Apollo.BaseMutationOptions<
  UnMarkEvaluationAsFinalMutation,
  UnMarkEvaluationAsFinalMutationVariables
>;
export const SaveEvaluationAlertsDocument = gql`
  mutation SaveEvaluationAlerts($request: SaveEvaluationAlertRequest!) {
    saveEvaluationAlerts(request: $request) {
      id
      alerts {
        ...EvaluationAlert
      }
    }
  }
  ${EvaluationAlertFragmentDoc}
`;
export type SaveEvaluationAlertsMutationFn = Apollo.MutationFunction<
  SaveEvaluationAlertsMutation,
  SaveEvaluationAlertsMutationVariables
>;

/**
 * __useSaveEvaluationAlertsMutation__
 *
 * To run a mutation, you first call `useSaveEvaluationAlertsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEvaluationAlertsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEvaluationAlertsMutation, { data, loading, error }] = useSaveEvaluationAlertsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveEvaluationAlertsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEvaluationAlertsMutation,
    SaveEvaluationAlertsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEvaluationAlertsMutation,
    SaveEvaluationAlertsMutationVariables
  >(SaveEvaluationAlertsDocument, options);
}
export type SaveEvaluationAlertsMutationHookResult = ReturnType<
  typeof useSaveEvaluationAlertsMutation
>;
export type SaveEvaluationAlertsMutationResult =
  Apollo.MutationResult<SaveEvaluationAlertsMutation>;
export type SaveEvaluationAlertsMutationOptions = Apollo.BaseMutationOptions<
  SaveEvaluationAlertsMutation,
  SaveEvaluationAlertsMutationVariables
>;
export const CreateGameEvaluationDocument = gql`
  mutation CreateGameEvaluation($request: CreateGameEvaluationRequest!) {
    createGameEvaluation(request: $request) {
      id
      gameGrades {
        id
        gameId
        game {
          id
          kickoffTime
          homeTeam
          awayTeam
        }
        evaluationId
        scoutGradeId
        scoutGrade {
          id
          grade
        }
        comments
      }
    }
  }
`;
export type CreateGameEvaluationMutationFn = Apollo.MutationFunction<
  CreateGameEvaluationMutation,
  CreateGameEvaluationMutationVariables
>;

/**
 * __useCreateGameEvaluationMutation__
 *
 * To run a mutation, you first call `useCreateGameEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameEvaluationMutation, { data, loading, error }] = useCreateGameEvaluationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateGameEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGameEvaluationMutation,
    CreateGameEvaluationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGameEvaluationMutation,
    CreateGameEvaluationMutationVariables
  >(CreateGameEvaluationDocument, options);
}
export type CreateGameEvaluationMutationHookResult = ReturnType<
  typeof useCreateGameEvaluationMutation
>;
export type CreateGameEvaluationMutationResult =
  Apollo.MutationResult<CreateGameEvaluationMutation>;
export type CreateGameEvaluationMutationOptions = Apollo.BaseMutationOptions<
  CreateGameEvaluationMutation,
  CreateGameEvaluationMutationVariables
>;
export const UpdateGameEvaluationDocument = gql`
  mutation UpdateGameEvaluation($request: UpdateGameEvaluationRequest!) {
    updateGameEvaluation(request: $request) {
      id
      gameGrades {
        id
        gameId
        game {
          id
          kickoffTime
          homeTeam
          awayTeam
        }
        evaluationId
        scoutGradeId
        scoutGrade {
          id
          grade
        }
        comments
      }
    }
  }
`;
export type UpdateGameEvaluationMutationFn = Apollo.MutationFunction<
  UpdateGameEvaluationMutation,
  UpdateGameEvaluationMutationVariables
>;

/**
 * __useUpdateGameEvaluationMutation__
 *
 * To run a mutation, you first call `useUpdateGameEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameEvaluationMutation, { data, loading, error }] = useUpdateGameEvaluationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateGameEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGameEvaluationMutation,
    UpdateGameEvaluationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGameEvaluationMutation,
    UpdateGameEvaluationMutationVariables
  >(UpdateGameEvaluationDocument, options);
}
export type UpdateGameEvaluationMutationHookResult = ReturnType<
  typeof useUpdateGameEvaluationMutation
>;
export type UpdateGameEvaluationMutationResult =
  Apollo.MutationResult<UpdateGameEvaluationMutation>;
export type UpdateGameEvaluationMutationOptions = Apollo.BaseMutationOptions<
  UpdateGameEvaluationMutation,
  UpdateGameEvaluationMutationVariables
>;
export const SaveEvaluationTraitGroupDocument = gql`
  mutation SaveEvaluationTraitGroup(
    $request: SaveEvaluationTraitGroupRequest!
  ) {
    saveEvaluationTraitGroup(request: $request) {
      id
      scoutGradeId
      scoutGrade {
        id
        grade
        quality
      }
      traitGroupGrades {
        id
        scoutGrade {
          id
          grade
        }
        comments
      }
    }
  }
`;
export type SaveEvaluationTraitGroupMutationFn = Apollo.MutationFunction<
  SaveEvaluationTraitGroupMutation,
  SaveEvaluationTraitGroupMutationVariables
>;

/**
 * __useSaveEvaluationTraitGroupMutation__
 *
 * To run a mutation, you first call `useSaveEvaluationTraitGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveEvaluationTraitGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveEvaluationTraitGroupMutation, { data, loading, error }] = useSaveEvaluationTraitGroupMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveEvaluationTraitGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEvaluationTraitGroupMutation,
    SaveEvaluationTraitGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEvaluationTraitGroupMutation,
    SaveEvaluationTraitGroupMutationVariables
  >(SaveEvaluationTraitGroupDocument, options);
}
export type SaveEvaluationTraitGroupMutationHookResult = ReturnType<
  typeof useSaveEvaluationTraitGroupMutation
>;
export type SaveEvaluationTraitGroupMutationResult =
  Apollo.MutationResult<SaveEvaluationTraitGroupMutation>;
export type SaveEvaluationTraitGroupMutationOptions =
  Apollo.BaseMutationOptions<
    SaveEvaluationTraitGroupMutation,
    SaveEvaluationTraitGroupMutationVariables
  >;
export const FlagEvaluationForDeleteDocument = gql`
  mutation FlagEvaluationForDelete($request: FlagEvaluationForDeleteRequest!) {
    flagEvaluationForDelete(request: $request) {
      id
      isDeleted
    }
  }
`;
export type FlagEvaluationForDeleteMutationFn = Apollo.MutationFunction<
  FlagEvaluationForDeleteMutation,
  FlagEvaluationForDeleteMutationVariables
>;

/**
 * __useFlagEvaluationForDeleteMutation__
 *
 * To run a mutation, you first call `useFlagEvaluationForDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlagEvaluationForDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flagEvaluationForDeleteMutation, { data, loading, error }] = useFlagEvaluationForDeleteMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useFlagEvaluationForDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FlagEvaluationForDeleteMutation,
    FlagEvaluationForDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FlagEvaluationForDeleteMutation,
    FlagEvaluationForDeleteMutationVariables
  >(FlagEvaluationForDeleteDocument, options);
}
export type FlagEvaluationForDeleteMutationHookResult = ReturnType<
  typeof useFlagEvaluationForDeleteMutation
>;
export type FlagEvaluationForDeleteMutationResult =
  Apollo.MutationResult<FlagEvaluationForDeleteMutation>;
export type FlagEvaluationForDeleteMutationOptions = Apollo.BaseMutationOptions<
  FlagEvaluationForDeleteMutation,
  FlagEvaluationForDeleteMutationVariables
>;
export const MarkEvaluationAsPrimaryDocument = gql`
  mutation MarkEvaluationAsPrimary($request: MarkEvaluationAsPrimaryRequest!) {
    markEvaluationAsPrimary(request: $request) {
      id
      isPrimary
    }
  }
`;
export type MarkEvaluationAsPrimaryMutationFn = Apollo.MutationFunction<
  MarkEvaluationAsPrimaryMutation,
  MarkEvaluationAsPrimaryMutationVariables
>;

/**
 * __useMarkEvaluationAsPrimaryMutation__
 *
 * To run a mutation, you first call `useMarkEvaluationAsPrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkEvaluationAsPrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markEvaluationAsPrimaryMutation, { data, loading, error }] = useMarkEvaluationAsPrimaryMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useMarkEvaluationAsPrimaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkEvaluationAsPrimaryMutation,
    MarkEvaluationAsPrimaryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkEvaluationAsPrimaryMutation,
    MarkEvaluationAsPrimaryMutationVariables
  >(MarkEvaluationAsPrimaryDocument, options);
}
export type MarkEvaluationAsPrimaryMutationHookResult = ReturnType<
  typeof useMarkEvaluationAsPrimaryMutation
>;
export type MarkEvaluationAsPrimaryMutationResult =
  Apollo.MutationResult<MarkEvaluationAsPrimaryMutation>;
export type MarkEvaluationAsPrimaryMutationOptions = Apollo.BaseMutationOptions<
  MarkEvaluationAsPrimaryMutation,
  MarkEvaluationAsPrimaryMutationVariables
>;
export const DeleteGameEvaluationDocument = gql`
  mutation DeleteGameEvaluation(
    $evaluationId: UUID!
    $evaluationGameId: UUID!
  ) {
    deleteGameEvaluation(
      evaluationId: $evaluationId
      evaluationGameId: $evaluationGameId
    ) {
      id
    }
  }
`;
export type DeleteGameEvaluationMutationFn = Apollo.MutationFunction<
  DeleteGameEvaluationMutation,
  DeleteGameEvaluationMutationVariables
>;

/**
 * __useDeleteGameEvaluationMutation__
 *
 * To run a mutation, you first call `useDeleteGameEvaluationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameEvaluationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameEvaluationMutation, { data, loading, error }] = useDeleteGameEvaluationMutation({
 *   variables: {
 *      evaluationId: // value for 'evaluationId'
 *      evaluationGameId: // value for 'evaluationGameId'
 *   },
 * });
 */
export function useDeleteGameEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGameEvaluationMutation,
    DeleteGameEvaluationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteGameEvaluationMutation,
    DeleteGameEvaluationMutationVariables
  >(DeleteGameEvaluationDocument, options);
}
export type DeleteGameEvaluationMutationHookResult = ReturnType<
  typeof useDeleteGameEvaluationMutation
>;
export type DeleteGameEvaluationMutationResult =
  Apollo.MutationResult<DeleteGameEvaluationMutation>;
export type DeleteGameEvaluationMutationOptions = Apollo.BaseMutationOptions<
  DeleteGameEvaluationMutation,
  DeleteGameEvaluationMutationVariables
>;
export const AssignWorkInProgressEvaluationStatusDocument = gql`
  mutation AssignWorkInProgressEvaluationStatus(
    $request: AssignWipEvaluationStatusRequest!
  ) {
    assignWorkInProgressEvaluationStatus(request: $request) {
      id
      status
    }
  }
`;
export type AssignWorkInProgressEvaluationStatusMutationFn =
  Apollo.MutationFunction<
    AssignWorkInProgressEvaluationStatusMutation,
    AssignWorkInProgressEvaluationStatusMutationVariables
  >;

/**
 * __useAssignWorkInProgressEvaluationStatusMutation__
 *
 * To run a mutation, you first call `useAssignWorkInProgressEvaluationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignWorkInProgressEvaluationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignWorkInProgressEvaluationStatusMutation, { data, loading, error }] = useAssignWorkInProgressEvaluationStatusMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssignWorkInProgressEvaluationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignWorkInProgressEvaluationStatusMutation,
    AssignWorkInProgressEvaluationStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignWorkInProgressEvaluationStatusMutation,
    AssignWorkInProgressEvaluationStatusMutationVariables
  >(AssignWorkInProgressEvaluationStatusDocument, options);
}
export type AssignWorkInProgressEvaluationStatusMutationHookResult = ReturnType<
  typeof useAssignWorkInProgressEvaluationStatusMutation
>;
export type AssignWorkInProgressEvaluationStatusMutationResult =
  Apollo.MutationResult<AssignWorkInProgressEvaluationStatusMutation>;
export type AssignWorkInProgressEvaluationStatusMutationOptions =
  Apollo.BaseMutationOptions<
    AssignWorkInProgressEvaluationStatusMutation,
    AssignWorkInProgressEvaluationStatusMutationVariables
  >;
export const AssignReadyForEditEvaluationStatusDocument = gql`
  mutation AssignReadyForEditEvaluationStatus(
    $request: AssignReadyForEditEvaluationStatusRequest!
  ) {
    assignReadyForEditEvaluationStatus(request: $request) {
      id
      status
    }
  }
`;
export type AssignReadyForEditEvaluationStatusMutationFn =
  Apollo.MutationFunction<
    AssignReadyForEditEvaluationStatusMutation,
    AssignReadyForEditEvaluationStatusMutationVariables
  >;

/**
 * __useAssignReadyForEditEvaluationStatusMutation__
 *
 * To run a mutation, you first call `useAssignReadyForEditEvaluationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignReadyForEditEvaluationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignReadyForEditEvaluationStatusMutation, { data, loading, error }] = useAssignReadyForEditEvaluationStatusMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssignReadyForEditEvaluationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignReadyForEditEvaluationStatusMutation,
    AssignReadyForEditEvaluationStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignReadyForEditEvaluationStatusMutation,
    AssignReadyForEditEvaluationStatusMutationVariables
  >(AssignReadyForEditEvaluationStatusDocument, options);
}
export type AssignReadyForEditEvaluationStatusMutationHookResult = ReturnType<
  typeof useAssignReadyForEditEvaluationStatusMutation
>;
export type AssignReadyForEditEvaluationStatusMutationResult =
  Apollo.MutationResult<AssignReadyForEditEvaluationStatusMutation>;
export type AssignReadyForEditEvaluationStatusMutationOptions =
  Apollo.BaseMutationOptions<
    AssignReadyForEditEvaluationStatusMutation,
    AssignReadyForEditEvaluationStatusMutationVariables
  >;
export const AssignFinalEvaluationStatusDocument = gql`
  mutation AssignFinalEvaluationStatus(
    $request: AssignFinalEvaluationStatusRequest!
  ) {
    assignFinalEvaluationStatus(request: $request) {
      id
      status
    }
  }
`;
export type AssignFinalEvaluationStatusMutationFn = Apollo.MutationFunction<
  AssignFinalEvaluationStatusMutation,
  AssignFinalEvaluationStatusMutationVariables
>;

/**
 * __useAssignFinalEvaluationStatusMutation__
 *
 * To run a mutation, you first call `useAssignFinalEvaluationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignFinalEvaluationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignFinalEvaluationStatusMutation, { data, loading, error }] = useAssignFinalEvaluationStatusMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAssignFinalEvaluationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignFinalEvaluationStatusMutation,
    AssignFinalEvaluationStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignFinalEvaluationStatusMutation,
    AssignFinalEvaluationStatusMutationVariables
  >(AssignFinalEvaluationStatusDocument, options);
}
export type AssignFinalEvaluationStatusMutationHookResult = ReturnType<
  typeof useAssignFinalEvaluationStatusMutation
>;
export type AssignFinalEvaluationStatusMutationResult =
  Apollo.MutationResult<AssignFinalEvaluationStatusMutation>;
export type AssignFinalEvaluationStatusMutationOptions =
  Apollo.BaseMutationOptions<
    AssignFinalEvaluationStatusMutation,
    AssignFinalEvaluationStatusMutationVariables
  >;
export const GetEvalDocument = gql`
  query GetEval($id: UUID!) {
    evalById(id: $id) {
      ...Eval
    }
  }
  ${EvalFragmentDoc}
`;

/**
 * __useGetEvalQuery__
 *
 * To run a query within a React component, call `useGetEvalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEvalQuery(
  baseOptions: Apollo.QueryHookOptions<GetEvalQuery, GetEvalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEvalQuery, GetEvalQueryVariables>(
    GetEvalDocument,
    options,
  );
}
export function useGetEvalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEvalQuery,
    GetEvalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEvalQuery, GetEvalQueryVariables>(
    GetEvalDocument,
    options,
  );
}
export type GetEvalQueryHookResult = ReturnType<typeof useGetEvalQuery>;
export type GetEvalLazyQueryHookResult = ReturnType<typeof useGetEvalLazyQuery>;
export type GetEvalQueryResult = Apollo.QueryResult<
  GetEvalQuery,
  GetEvalQueryVariables
>;
export const GetEvalsDocument = gql`
  query GetEvals(
    $position: UUID
    $user: UUID
    $playerId: UUID
    $group: EvalTypeEnum
  ) {
    evals(
      order: { lastUpdated: DESC }
      positionFilter: $position
      userFilter: $user
      playerFilter: $playerId
      groupFilter: $group
    ) {
      ...Eval
    }
  }
  ${EvalFragmentDoc}
`;

/**
 * __useGetEvalsQuery__
 *
 * To run a query within a React component, call `useGetEvalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvalsQuery({
 *   variables: {
 *      position: // value for 'position'
 *      user: // value for 'user'
 *      playerId: // value for 'playerId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useGetEvalsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEvalsQuery, GetEvalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEvalsQuery, GetEvalsQueryVariables>(
    GetEvalsDocument,
    options,
  );
}
export function useGetEvalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEvalsQuery,
    GetEvalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEvalsQuery, GetEvalsQueryVariables>(
    GetEvalsDocument,
    options,
  );
}
export type GetEvalsQueryHookResult = ReturnType<typeof useGetEvalsQuery>;
export type GetEvalsLazyQueryHookResult = ReturnType<
  typeof useGetEvalsLazyQuery
>;
export type GetEvalsQueryResult = Apollo.QueryResult<
  GetEvalsQuery,
  GetEvalsQueryVariables
>;
export const GetPriorSeasonEvalTraitsDocument = gql`
  query GetPriorSeasonEvalTraits($playerId: UUID, $season: Int) {
    evals(
      playerFilter: $playerId
      where: {
        season: { lt: $season }
        evalType: { type: { in: [COLLEGE_SCOUT, PRO_SCOUT] } }
      }
      order: [{ created: DESC }]
    ) {
      ...PriorSeasonEval
    }
  }
  ${PriorSeasonEvalFragmentDoc}
`;

/**
 * __useGetPriorSeasonEvalTraitsQuery__
 *
 * To run a query within a React component, call `useGetPriorSeasonEvalTraitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriorSeasonEvalTraitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriorSeasonEvalTraitsQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      season: // value for 'season'
 *   },
 * });
 */
export function useGetPriorSeasonEvalTraitsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPriorSeasonEvalTraitsQuery,
    GetPriorSeasonEvalTraitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPriorSeasonEvalTraitsQuery,
    GetPriorSeasonEvalTraitsQueryVariables
  >(GetPriorSeasonEvalTraitsDocument, options);
}
export function useGetPriorSeasonEvalTraitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPriorSeasonEvalTraitsQuery,
    GetPriorSeasonEvalTraitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPriorSeasonEvalTraitsQuery,
    GetPriorSeasonEvalTraitsQueryVariables
  >(GetPriorSeasonEvalTraitsDocument, options);
}
export type GetPriorSeasonEvalTraitsQueryHookResult = ReturnType<
  typeof useGetPriorSeasonEvalTraitsQuery
>;
export type GetPriorSeasonEvalTraitsLazyQueryHookResult = ReturnType<
  typeof useGetPriorSeasonEvalTraitsLazyQuery
>;
export type GetPriorSeasonEvalTraitsQueryResult = Apollo.QueryResult<
  GetPriorSeasonEvalTraitsQuery,
  GetPriorSeasonEvalTraitsQueryVariables
>;
export const GetEvalSummariesDocument = gql`
  query GetEvalSummaries(
    $position: UUID
    $user: UUID
    $playerId: UUID
    $group: EvalTypeEnum
  ) {
    evals(
      order: { lastUpdated: DESC }
      positionFilter: $position
      userFilter: $user
      playerFilter: $playerId
      groupFilter: $group
    ) {
      ...EvalSummary
    }
  }
  ${EvalSummaryFragmentDoc}
`;

/**
 * __useGetEvalSummariesQuery__
 *
 * To run a query within a React component, call `useGetEvalSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvalSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvalSummariesQuery({
 *   variables: {
 *      position: // value for 'position'
 *      user: // value for 'user'
 *      playerId: // value for 'playerId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useGetEvalSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEvalSummariesQuery,
    GetEvalSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEvalSummariesQuery, GetEvalSummariesQueryVariables>(
    GetEvalSummariesDocument,
    options,
  );
}
export function useGetEvalSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEvalSummariesQuery,
    GetEvalSummariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEvalSummariesQuery,
    GetEvalSummariesQueryVariables
  >(GetEvalSummariesDocument, options);
}
export type GetEvalSummariesQueryHookResult = ReturnType<
  typeof useGetEvalSummariesQuery
>;
export type GetEvalSummariesLazyQueryHookResult = ReturnType<
  typeof useGetEvalSummariesLazyQuery
>;
export type GetEvalSummariesQueryResult = Apollo.QueryResult<
  GetEvalSummariesQuery,
  GetEvalSummariesQueryVariables
>;
export const GetEvalMatrixForExportDocument = gql`
  query GetEvalMatrixForExport(
    $position: UUID
    $user: UUID
    $playerId: UUID
    $group: EvalTypeEnum
  ) {
    evals(
      order: { lastUpdated: DESC }
      positionFilter: $position
      userFilter: $user
      playerFilter: $playerId
      groupFilter: $group
      where: { and: [{ isPrimary: { eq: true } }] }
    ) {
      ...EvalMatrixExport
    }
  }
  ${EvalMatrixExportFragmentDoc}
`;

/**
 * __useGetEvalMatrixForExportQuery__
 *
 * To run a query within a React component, call `useGetEvalMatrixForExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvalMatrixForExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvalMatrixForExportQuery({
 *   variables: {
 *      position: // value for 'position'
 *      user: // value for 'user'
 *      playerId: // value for 'playerId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useGetEvalMatrixForExportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEvalMatrixForExportQuery,
    GetEvalMatrixForExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEvalMatrixForExportQuery,
    GetEvalMatrixForExportQueryVariables
  >(GetEvalMatrixForExportDocument, options);
}
export function useGetEvalMatrixForExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEvalMatrixForExportQuery,
    GetEvalMatrixForExportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEvalMatrixForExportQuery,
    GetEvalMatrixForExportQueryVariables
  >(GetEvalMatrixForExportDocument, options);
}
export type GetEvalMatrixForExportQueryHookResult = ReturnType<
  typeof useGetEvalMatrixForExportQuery
>;
export type GetEvalMatrixForExportLazyQueryHookResult = ReturnType<
  typeof useGetEvalMatrixForExportLazyQuery
>;
export type GetEvalMatrixForExportQueryResult = Apollo.QueryResult<
  GetEvalMatrixForExportQuery,
  GetEvalMatrixForExportQueryVariables
>;
export const GetTraitGroupsDocument = gql`
  query GetTraitGroups {
    traitGroups(order: { sort: ASC }) {
      ...TraitGroup
    }
  }
  ${TraitGroupFragmentDoc}
`;

/**
 * __useGetTraitGroupsQuery__
 *
 * To run a query within a React component, call `useGetTraitGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTraitGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTraitGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTraitGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTraitGroupsQuery,
    GetTraitGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTraitGroupsQuery, GetTraitGroupsQueryVariables>(
    GetTraitGroupsDocument,
    options,
  );
}
export function useGetTraitGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTraitGroupsQuery,
    GetTraitGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTraitGroupsQuery, GetTraitGroupsQueryVariables>(
    GetTraitGroupsDocument,
    options,
  );
}
export type GetTraitGroupsQueryHookResult = ReturnType<
  typeof useGetTraitGroupsQuery
>;
export type GetTraitGroupsLazyQueryHookResult = ReturnType<
  typeof useGetTraitGroupsLazyQuery
>;
export type GetTraitGroupsQueryResult = Apollo.QueryResult<
  GetTraitGroupsQuery,
  GetTraitGroupsQueryVariables
>;
export const GetScoutGradesDocument = gql`
  query GetScoutGrades($scale: String) {
    scoutGrades(
      order: { sort: ASC }
      where: {
        and: [{ scale: { contains: $scale } }, { isDeleted: { eq: false } }]
      }
    ) {
      ...ScoutGrade
    }
  }
  ${ScoutGradeFragmentDoc}
`;

/**
 * __useGetScoutGradesQuery__
 *
 * To run a query within a React component, call `useGetScoutGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScoutGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScoutGradesQuery({
 *   variables: {
 *      scale: // value for 'scale'
 *   },
 * });
 */
export function useGetScoutGradesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetScoutGradesQuery,
    GetScoutGradesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetScoutGradesQuery, GetScoutGradesQueryVariables>(
    GetScoutGradesDocument,
    options,
  );
}
export function useGetScoutGradesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScoutGradesQuery,
    GetScoutGradesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetScoutGradesQuery, GetScoutGradesQueryVariables>(
    GetScoutGradesDocument,
    options,
  );
}
export type GetScoutGradesQueryHookResult = ReturnType<
  typeof useGetScoutGradesQuery
>;
export type GetScoutGradesLazyQueryHookResult = ReturnType<
  typeof useGetScoutGradesLazyQuery
>;
export type GetScoutGradesQueryResult = Apollo.QueryResult<
  GetScoutGradesQuery,
  GetScoutGradesQueryVariables
>;
export const GetFitsDocument = gql`
  query GetFits {
    fits(order: { sort: ASC }, where: { isDeleted: { eq: false } }) {
      ...Fit
    }
  }
  ${FitFragmentDoc}
`;

/**
 * __useGetFitsQuery__
 *
 * To run a query within a React component, call `useGetFitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFitsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFitsQuery, GetFitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFitsQuery, GetFitsQueryVariables>(
    GetFitsDocument,
    options,
  );
}
export function useGetFitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFitsQuery,
    GetFitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFitsQuery, GetFitsQueryVariables>(
    GetFitsDocument,
    options,
  );
}
export type GetFitsQueryHookResult = ReturnType<typeof useGetFitsQuery>;
export type GetFitsLazyQueryHookResult = ReturnType<typeof useGetFitsLazyQuery>;
export type GetFitsQueryResult = Apollo.QueryResult<
  GetFitsQuery,
  GetFitsQueryVariables
>;
export const GetAlertsDocument = gql`
  query GetAlerts {
    alerts(order: { sort: ASC }, where: { isDeleted: { eq: false } }) {
      ...Alert
    }
  }
  ${AlertFragmentDoc}
`;

/**
 * __useGetAlertsQuery__
 *
 * To run a query within a React component, call `useGetAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAlertsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAlertsQuery,
    GetAlertsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAlertsQuery, GetAlertsQueryVariables>(
    GetAlertsDocument,
    options,
  );
}
export function useGetAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAlertsQuery,
    GetAlertsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAlertsQuery, GetAlertsQueryVariables>(
    GetAlertsDocument,
    options,
  );
}
export type GetAlertsQueryHookResult = ReturnType<typeof useGetAlertsQuery>;
export type GetAlertsLazyQueryHookResult = ReturnType<
  typeof useGetAlertsLazyQuery
>;
export type GetAlertsQueryResult = Apollo.QueryResult<
  GetAlertsQuery,
  GetAlertsQueryVariables
>;
export const GetEvaluatorsDocument = gql`
  query GetEvaluators {
    users(where: { role: { name: { eq: "Scout" } } }) {
      ...Evaluator
    }
  }
  ${EvaluatorFragmentDoc}
`;

/**
 * __useGetEvaluatorsQuery__
 *
 * To run a query within a React component, call `useGetEvaluatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvaluatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvaluatorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEvaluatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEvaluatorsQuery,
    GetEvaluatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEvaluatorsQuery, GetEvaluatorsQueryVariables>(
    GetEvaluatorsDocument,
    options,
  );
}
export function useGetEvaluatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEvaluatorsQuery,
    GetEvaluatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEvaluatorsQuery, GetEvaluatorsQueryVariables>(
    GetEvaluatorsDocument,
    options,
  );
}
export type GetEvaluatorsQueryHookResult = ReturnType<
  typeof useGetEvaluatorsQuery
>;
export type GetEvaluatorsLazyQueryHookResult = ReturnType<
  typeof useGetEvaluatorsLazyQuery
>;
export type GetEvaluatorsQueryResult = Apollo.QueryResult<
  GetEvaluatorsQuery,
  GetEvaluatorsQueryVariables
>;
export const GetEvalTypesDocument = gql`
  query GetEvalTypes {
    evalTypes {
      ...EvalType
    }
  }
  ${EvalTypeFragmentDoc}
`;

/**
 * __useGetEvalTypesQuery__
 *
 * To run a query within a React component, call `useGetEvalTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvalTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvalTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEvalTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEvalTypesQuery,
    GetEvalTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEvalTypesQuery, GetEvalTypesQueryVariables>(
    GetEvalTypesDocument,
    options,
  );
}
export function useGetEvalTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEvalTypesQuery,
    GetEvalTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEvalTypesQuery, GetEvalTypesQueryVariables>(
    GetEvalTypesDocument,
    options,
  );
}
export type GetEvalTypesQueryHookResult = ReturnType<
  typeof useGetEvalTypesQuery
>;
export type GetEvalTypesLazyQueryHookResult = ReturnType<
  typeof useGetEvalTypesLazyQuery
>;
export type GetEvalTypesQueryResult = Apollo.QueryResult<
  GetEvalTypesQuery,
  GetEvalTypesQueryVariables
>;
export const CreateInjuryCollegeDocument = gql`
  mutation CreateInjuryCollege(
    $createInjuryCollegeRequest: CreatePlayerInjuryColWithDetailsRequest!
  ) {
    createInjuryCollege(
      createInjuryCollegeRequest: $createInjuryCollegeRequest
    ) {
      id
      playerId
      schoolId
      dateTime
      source
      injuryType
      injuryDetail
      injuryStatus
    }
  }
`;
export type CreateInjuryCollegeMutationFn = Apollo.MutationFunction<
  CreateInjuryCollegeMutation,
  CreateInjuryCollegeMutationVariables
>;

/**
 * __useCreateInjuryCollegeMutation__
 *
 * To run a mutation, you first call `useCreateInjuryCollegeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInjuryCollegeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInjuryCollegeMutation, { data, loading, error }] = useCreateInjuryCollegeMutation({
 *   variables: {
 *      createInjuryCollegeRequest: // value for 'createInjuryCollegeRequest'
 *   },
 * });
 */
export function useCreateInjuryCollegeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInjuryCollegeMutation,
    CreateInjuryCollegeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInjuryCollegeMutation,
    CreateInjuryCollegeMutationVariables
  >(CreateInjuryCollegeDocument, options);
}
export type CreateInjuryCollegeMutationHookResult = ReturnType<
  typeof useCreateInjuryCollegeMutation
>;
export type CreateInjuryCollegeMutationResult =
  Apollo.MutationResult<CreateInjuryCollegeMutation>;
export type CreateInjuryCollegeMutationOptions = Apollo.BaseMutationOptions<
  CreateInjuryCollegeMutation,
  CreateInjuryCollegeMutationVariables
>;
export const CreateInjuryProDocument = gql`
  mutation CreateInjuryPro(
    $createInjuryProRequest: CreatePlayerInjuryProWithDetailsRequest!
  ) {
    createInjuryPro(createInjuryProRequest: $createInjuryProRequest) {
      id
      playerId
      clubId
      dateTime
      source
      injury1
      injuryDesc1
      injuryStatus
    }
  }
`;
export type CreateInjuryProMutationFn = Apollo.MutationFunction<
  CreateInjuryProMutation,
  CreateInjuryProMutationVariables
>;

/**
 * __useCreateInjuryProMutation__
 *
 * To run a mutation, you first call `useCreateInjuryProMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInjuryProMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInjuryProMutation, { data, loading, error }] = useCreateInjuryProMutation({
 *   variables: {
 *      createInjuryProRequest: // value for 'createInjuryProRequest'
 *   },
 * });
 */
export function useCreateInjuryProMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInjuryProMutation,
    CreateInjuryProMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInjuryProMutation,
    CreateInjuryProMutationVariables
  >(CreateInjuryProDocument, options);
}
export type CreateInjuryProMutationHookResult = ReturnType<
  typeof useCreateInjuryProMutation
>;
export type CreateInjuryProMutationResult =
  Apollo.MutationResult<CreateInjuryProMutation>;
export type CreateInjuryProMutationOptions = Apollo.BaseMutationOptions<
  CreateInjuryProMutation,
  CreateInjuryProMutationVariables
>;
export const UpdateInjuryCollegeDocument = gql`
  mutation UpdateInjuryCollege(
    $updateInjuryCollegeRequest: UpdatePlayerInjuryCollegeWithDetailsRequest!
  ) {
    updateInjuryCollege(
      updateInjuryCollegeRequest: $updateInjuryCollegeRequest
    ) {
      id
      playerId
      schoolId
      dateTime
      source
      injuryType
      injuryDetail
      injuryStatus
    }
  }
`;
export type UpdateInjuryCollegeMutationFn = Apollo.MutationFunction<
  UpdateInjuryCollegeMutation,
  UpdateInjuryCollegeMutationVariables
>;

/**
 * __useUpdateInjuryCollegeMutation__
 *
 * To run a mutation, you first call `useUpdateInjuryCollegeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInjuryCollegeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInjuryCollegeMutation, { data, loading, error }] = useUpdateInjuryCollegeMutation({
 *   variables: {
 *      updateInjuryCollegeRequest: // value for 'updateInjuryCollegeRequest'
 *   },
 * });
 */
export function useUpdateInjuryCollegeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInjuryCollegeMutation,
    UpdateInjuryCollegeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInjuryCollegeMutation,
    UpdateInjuryCollegeMutationVariables
  >(UpdateInjuryCollegeDocument, options);
}
export type UpdateInjuryCollegeMutationHookResult = ReturnType<
  typeof useUpdateInjuryCollegeMutation
>;
export type UpdateInjuryCollegeMutationResult =
  Apollo.MutationResult<UpdateInjuryCollegeMutation>;
export type UpdateInjuryCollegeMutationOptions = Apollo.BaseMutationOptions<
  UpdateInjuryCollegeMutation,
  UpdateInjuryCollegeMutationVariables
>;
export const UpdateInjuryProDocument = gql`
  mutation UpdateInjuryPro(
    $updateInjuryProRequest: UpdatePlayerInjuryProWithDetailsRequest!
  ) {
    updateInjuryPro(updateInjuryProRequest: $updateInjuryProRequest) {
      id
      playerId
      clubId
      dateTime
      source
      injury1
      injuryDesc1
      injuryStatus
    }
  }
`;
export type UpdateInjuryProMutationFn = Apollo.MutationFunction<
  UpdateInjuryProMutation,
  UpdateInjuryProMutationVariables
>;

/**
 * __useUpdateInjuryProMutation__
 *
 * To run a mutation, you first call `useUpdateInjuryProMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInjuryProMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInjuryProMutation, { data, loading, error }] = useUpdateInjuryProMutation({
 *   variables: {
 *      updateInjuryProRequest: // value for 'updateInjuryProRequest'
 *   },
 * });
 */
export function useUpdateInjuryProMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInjuryProMutation,
    UpdateInjuryProMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateInjuryProMutation,
    UpdateInjuryProMutationVariables
  >(UpdateInjuryProDocument, options);
}
export type UpdateInjuryProMutationHookResult = ReturnType<
  typeof useUpdateInjuryProMutation
>;
export type UpdateInjuryProMutationResult =
  Apollo.MutationResult<UpdateInjuryProMutation>;
export type UpdateInjuryProMutationOptions = Apollo.BaseMutationOptions<
  UpdateInjuryProMutation,
  UpdateInjuryProMutationVariables
>;
export const GetInjuriesForProPlayerDocument = gql`
  query GetInjuriesForProPlayer($playerId: UUID) {
    playerInjuryPro(where: { playerId: { eq: $playerId } }) {
      edges {
        node {
          id
          playerId
          source
          season
          injury1
          injuryDesc1
          injury2
          injuryDesc2
          leagueInjuryId
          reportType
          reportDay
          injuryStatus
          dateTime
          clubId
          club {
            clubCode
          }
          gameId
          game {
            kickoffTime
          }
        }
      }
    }
  }
`;

/**
 * __useGetInjuriesForProPlayerQuery__
 *
 * To run a query within a React component, call `useGetInjuriesForProPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInjuriesForProPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInjuriesForProPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetInjuriesForProPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInjuriesForProPlayerQuery,
    GetInjuriesForProPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInjuriesForProPlayerQuery,
    GetInjuriesForProPlayerQueryVariables
  >(GetInjuriesForProPlayerDocument, options);
}
export function useGetInjuriesForProPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInjuriesForProPlayerQuery,
    GetInjuriesForProPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInjuriesForProPlayerQuery,
    GetInjuriesForProPlayerQueryVariables
  >(GetInjuriesForProPlayerDocument, options);
}
export type GetInjuriesForProPlayerQueryHookResult = ReturnType<
  typeof useGetInjuriesForProPlayerQuery
>;
export type GetInjuriesForProPlayerLazyQueryHookResult = ReturnType<
  typeof useGetInjuriesForProPlayerLazyQuery
>;
export type GetInjuriesForProPlayerQueryResult = Apollo.QueryResult<
  GetInjuriesForProPlayerQuery,
  GetInjuriesForProPlayerQueryVariables
>;
export const GetInjuriesForCollegePlayerDocument = gql`
  query GetInjuriesForCollegePlayer($playerId: UUID) {
    playerInjuryCollege(where: { playerId: { eq: $playerId } }) {
      edges {
        node {
          id
          playerId
          source
          school {
            schoolCode
          }
          dateTime
          injuryStatus
          injuryType
          injuryDetail
          injurySide
        }
      }
    }
  }
`;

/**
 * __useGetInjuriesForCollegePlayerQuery__
 *
 * To run a query within a React component, call `useGetInjuriesForCollegePlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInjuriesForCollegePlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInjuriesForCollegePlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetInjuriesForCollegePlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInjuriesForCollegePlayerQuery,
    GetInjuriesForCollegePlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInjuriesForCollegePlayerQuery,
    GetInjuriesForCollegePlayerQueryVariables
  >(GetInjuriesForCollegePlayerDocument, options);
}
export function useGetInjuriesForCollegePlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInjuriesForCollegePlayerQuery,
    GetInjuriesForCollegePlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInjuriesForCollegePlayerQuery,
    GetInjuriesForCollegePlayerQueryVariables
  >(GetInjuriesForCollegePlayerDocument, options);
}
export type GetInjuriesForCollegePlayerQueryHookResult = ReturnType<
  typeof useGetInjuriesForCollegePlayerQuery
>;
export type GetInjuriesForCollegePlayerLazyQueryHookResult = ReturnType<
  typeof useGetInjuriesForCollegePlayerLazyQuery
>;
export type GetInjuriesForCollegePlayerQueryResult = Apollo.QueryResult<
  GetInjuriesForCollegePlayerQuery,
  GetInjuriesForCollegePlayerQueryVariables
>;
export const SaveScoutMeasuresDocument = gql`
  mutation SaveScoutMeasures($request: UpsertSumerScoutMeasureRequest!) {
    saveSumerScoutMeasure(request: $request) {
      id
      measureTypeId
      value
      isVerified
    }
  }
`;
export type SaveScoutMeasuresMutationFn = Apollo.MutationFunction<
  SaveScoutMeasuresMutation,
  SaveScoutMeasuresMutationVariables
>;

/**
 * __useSaveScoutMeasuresMutation__
 *
 * To run a mutation, you first call `useSaveScoutMeasuresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveScoutMeasuresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveScoutMeasuresMutation, { data, loading, error }] = useSaveScoutMeasuresMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveScoutMeasuresMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveScoutMeasuresMutation,
    SaveScoutMeasuresMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveScoutMeasuresMutation,
    SaveScoutMeasuresMutationVariables
  >(SaveScoutMeasuresDocument, options);
}
export type SaveScoutMeasuresMutationHookResult = ReturnType<
  typeof useSaveScoutMeasuresMutation
>;
export type SaveScoutMeasuresMutationResult =
  Apollo.MutationResult<SaveScoutMeasuresMutation>;
export type SaveScoutMeasuresMutationOptions = Apollo.BaseMutationOptions<
  SaveScoutMeasuresMutation,
  SaveScoutMeasuresMutationVariables
>;
export const GetWorkoutsDocument = gql`
  query getWorkouts($type: String!) {
    workouts(where: { workoutType: { name: { eq: $type } } }) {
      ...Workout
    }
  }
  ${WorkoutFragmentDoc}
`;

/**
 * __useGetWorkoutsQuery__
 *
 * To run a query within a React component, call `useGetWorkoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetWorkoutsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkoutsQuery,
    GetWorkoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkoutsQuery, GetWorkoutsQueryVariables>(
    GetWorkoutsDocument,
    options,
  );
}
export function useGetWorkoutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkoutsQuery,
    GetWorkoutsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkoutsQuery, GetWorkoutsQueryVariables>(
    GetWorkoutsDocument,
    options,
  );
}
export type GetWorkoutsQueryHookResult = ReturnType<typeof useGetWorkoutsQuery>;
export type GetWorkoutsLazyQueryHookResult = ReturnType<
  typeof useGetWorkoutsLazyQuery
>;
export type GetWorkoutsQueryResult = Apollo.QueryResult<
  GetWorkoutsQuery,
  GetWorkoutsQueryVariables
>;
export const GetWorkoutPlayersDocument = gql`
  query getWorkoutPlayers($workoutId: UUID!) {
    workoutPlayers(where: { workoutId: { eq: $workoutId } }) {
      id
      player {
        id
        firstName
        lastName
        headshotUrl
        sumerAtlasPosition {
          shortName
        }
        sumerGeneralPosition {
          shortName
        }
        school {
          id
          schoolCode
          schoolName
        }
      }
      measureType {
        name
      }
      value
    }
  }
`;

/**
 * __useGetWorkoutPlayersQuery__
 *
 * To run a query within a React component, call `useGetWorkoutPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutPlayersQuery({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *   },
 * });
 */
export function useGetWorkoutPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkoutPlayersQuery,
    GetWorkoutPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkoutPlayersQuery,
    GetWorkoutPlayersQueryVariables
  >(GetWorkoutPlayersDocument, options);
}
export function useGetWorkoutPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkoutPlayersQuery,
    GetWorkoutPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkoutPlayersQuery,
    GetWorkoutPlayersQueryVariables
  >(GetWorkoutPlayersDocument, options);
}
export type GetWorkoutPlayersQueryHookResult = ReturnType<
  typeof useGetWorkoutPlayersQuery
>;
export type GetWorkoutPlayersLazyQueryHookResult = ReturnType<
  typeof useGetWorkoutPlayersLazyQuery
>;
export type GetWorkoutPlayersQueryResult = Apollo.QueryResult<
  GetWorkoutPlayersQuery,
  GetWorkoutPlayersQueryVariables
>;
export const GetWorkoutsByPlayerDocument = gql`
  query getWorkoutsByPlayer($playerId: UUID!) {
    workoutsByPlayerId(playerId: $playerId) {
      ...PlayerMeasure
    }
  }
  ${PlayerMeasureFragmentDoc}
`;

/**
 * __useGetWorkoutsByPlayerQuery__
 *
 * To run a query within a React component, call `useGetWorkoutsByPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutsByPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutsByPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetWorkoutsByPlayerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkoutsByPlayerQuery,
    GetWorkoutsByPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkoutsByPlayerQuery,
    GetWorkoutsByPlayerQueryVariables
  >(GetWorkoutsByPlayerDocument, options);
}
export function useGetWorkoutsByPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkoutsByPlayerQuery,
    GetWorkoutsByPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkoutsByPlayerQuery,
    GetWorkoutsByPlayerQueryVariables
  >(GetWorkoutsByPlayerDocument, options);
}
export type GetWorkoutsByPlayerQueryHookResult = ReturnType<
  typeof useGetWorkoutsByPlayerQuery
>;
export type GetWorkoutsByPlayerLazyQueryHookResult = ReturnType<
  typeof useGetWorkoutsByPlayerLazyQuery
>;
export type GetWorkoutsByPlayerQueryResult = Apollo.QueryResult<
  GetWorkoutsByPlayerQuery,
  GetWorkoutsByPlayerQueryVariables
>;
export const GetWorkoutMeasuresBySchoolDocument = gql`
  query getWorkoutMeasuresBySchool($schoolId: UUID!) {
    workoutsBySchoolId(schoolId: $schoolId) {
      ...PlayerMeasure
    }
  }
  ${PlayerMeasureFragmentDoc}
`;

/**
 * __useGetWorkoutMeasuresBySchoolQuery__
 *
 * To run a query within a React component, call `useGetWorkoutMeasuresBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutMeasuresBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutMeasuresBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetWorkoutMeasuresBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkoutMeasuresBySchoolQuery,
    GetWorkoutMeasuresBySchoolQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkoutMeasuresBySchoolQuery,
    GetWorkoutMeasuresBySchoolQueryVariables
  >(GetWorkoutMeasuresBySchoolDocument, options);
}
export function useGetWorkoutMeasuresBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkoutMeasuresBySchoolQuery,
    GetWorkoutMeasuresBySchoolQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkoutMeasuresBySchoolQuery,
    GetWorkoutMeasuresBySchoolQueryVariables
  >(GetWorkoutMeasuresBySchoolDocument, options);
}
export type GetWorkoutMeasuresBySchoolQueryHookResult = ReturnType<
  typeof useGetWorkoutMeasuresBySchoolQuery
>;
export type GetWorkoutMeasuresBySchoolLazyQueryHookResult = ReturnType<
  typeof useGetWorkoutMeasuresBySchoolLazyQuery
>;
export type GetWorkoutMeasuresBySchoolQueryResult = Apollo.QueryResult<
  GetWorkoutMeasuresBySchoolQuery,
  GetWorkoutMeasuresBySchoolQueryVariables
>;
export const GetWorkoutMeasuresByPlayerDocument = gql`
  query getWorkoutMeasuresByPlayer($playerId: UUID!) {
    workoutMeasuresByPlayerId(playerId: $playerId) {
      name
      measure
    }
  }
`;

/**
 * __useGetWorkoutMeasuresByPlayerQuery__
 *
 * To run a query within a React component, call `useGetWorkoutMeasuresByPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutMeasuresByPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutMeasuresByPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetWorkoutMeasuresByPlayerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkoutMeasuresByPlayerQuery,
    GetWorkoutMeasuresByPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkoutMeasuresByPlayerQuery,
    GetWorkoutMeasuresByPlayerQueryVariables
  >(GetWorkoutMeasuresByPlayerDocument, options);
}
export function useGetWorkoutMeasuresByPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkoutMeasuresByPlayerQuery,
    GetWorkoutMeasuresByPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkoutMeasuresByPlayerQuery,
    GetWorkoutMeasuresByPlayerQueryVariables
  >(GetWorkoutMeasuresByPlayerDocument, options);
}
export type GetWorkoutMeasuresByPlayerQueryHookResult = ReturnType<
  typeof useGetWorkoutMeasuresByPlayerQuery
>;
export type GetWorkoutMeasuresByPlayerLazyQueryHookResult = ReturnType<
  typeof useGetWorkoutMeasuresByPlayerLazyQuery
>;
export type GetWorkoutMeasuresByPlayerQueryResult = Apollo.QueryResult<
  GetWorkoutMeasuresByPlayerQuery,
  GetWorkoutMeasuresByPlayerQueryVariables
>;
export const GetWorkoutsBySchoolDocument = gql`
  query getWorkoutsBySchool($schoolId: UUID!) {
    workoutsBySchoolId(schoolId: $schoolId) {
      ...PlayerMeasure
    }
  }
  ${PlayerMeasureFragmentDoc}
`;

/**
 * __useGetWorkoutsBySchoolQuery__
 *
 * To run a query within a React component, call `useGetWorkoutsBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutsBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutsBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetWorkoutsBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkoutsBySchoolQuery,
    GetWorkoutsBySchoolQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkoutsBySchoolQuery,
    GetWorkoutsBySchoolQueryVariables
  >(GetWorkoutsBySchoolDocument, options);
}
export function useGetWorkoutsBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkoutsBySchoolQuery,
    GetWorkoutsBySchoolQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkoutsBySchoolQuery,
    GetWorkoutsBySchoolQueryVariables
  >(GetWorkoutsBySchoolDocument, options);
}
export type GetWorkoutsBySchoolQueryHookResult = ReturnType<
  typeof useGetWorkoutsBySchoolQuery
>;
export type GetWorkoutsBySchoolLazyQueryHookResult = ReturnType<
  typeof useGetWorkoutsBySchoolLazyQuery
>;
export type GetWorkoutsBySchoolQueryResult = Apollo.QueryResult<
  GetWorkoutsBySchoolQuery,
  GetWorkoutsBySchoolQueryVariables
>;
export const GetMeasureTypesDocument = gql`
  query getMeasureTypes {
    measureTypes {
      ...MeasureType
    }
  }
  ${MeasureTypeFragmentDoc}
`;

/**
 * __useGetMeasureTypesQuery__
 *
 * To run a query within a React component, call `useGetMeasureTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasureTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasureTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeasureTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMeasureTypesQuery,
    GetMeasureTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeasureTypesQuery, GetMeasureTypesQueryVariables>(
    GetMeasureTypesDocument,
    options,
  );
}
export function useGetMeasureTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMeasureTypesQuery,
    GetMeasureTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMeasureTypesQuery,
    GetMeasureTypesQueryVariables
  >(GetMeasureTypesDocument, options);
}
export type GetMeasureTypesQueryHookResult = ReturnType<
  typeof useGetMeasureTypesQuery
>;
export type GetMeasureTypesLazyQueryHookResult = ReturnType<
  typeof useGetMeasureTypesLazyQuery
>;
export type GetMeasureTypesQueryResult = Apollo.QueryResult<
  GetMeasureTypesQuery,
  GetMeasureTypesQueryVariables
>;
export const SearchAllDocument = gql`
  query SearchAll($searchTerm: String!) {
    searchAll(searchTerm: $searchTerm) {
      score
      player {
        searchScore
        record {
          id
          clubCode
          clubId
          clubName
          schoolId
          schoolCode
          draftYear
          firstName
          lastName
          hometown
          height
          sumerAtlasPositionShortName
          sumerGeneralPositionShortName
          primarySchemePositionShortName
        }
      }
      school {
        searchScore
        record {
          id
          schoolCode
          schoolName
        }
      }
      club {
        searchScore
        record {
          id
          clubCode
          clubName
        }
      }
    }
  }
`;

/**
 * __useSearchAllQuery__
 *
 * To run a query within a React component, call `useSearchAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAllQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSearchAllQuery(
  baseOptions: Apollo.QueryHookOptions<SearchAllQuery, SearchAllQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchAllQuery, SearchAllQueryVariables>(
    SearchAllDocument,
    options,
  );
}
export function useSearchAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAllQuery,
    SearchAllQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchAllQuery, SearchAllQueryVariables>(
    SearchAllDocument,
    options,
  );
}
export type SearchAllQueryHookResult = ReturnType<typeof useSearchAllQuery>;
export type SearchAllLazyQueryHookResult = ReturnType<
  typeof useSearchAllLazyQuery
>;
export type SearchAllQueryResult = Apollo.QueryResult<
  SearchAllQuery,
  SearchAllQueryVariables
>;
export const GetPositionsDocument = gql`
  query GetPositions($positionTypeFilter: PositionType) {
    positions(positionTypeFilter: $positionTypeFilter, order: { sort: ASC }) {
      ...Position
    }
  }
  ${PositionFragmentDoc}
`;

/**
 * __useGetPositionsQuery__
 *
 * To run a query within a React component, call `useGetPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPositionsQuery({
 *   variables: {
 *      positionTypeFilter: // value for 'positionTypeFilter'
 *   },
 * });
 */
export function useGetPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPositionsQuery,
    GetPositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPositionsQuery, GetPositionsQueryVariables>(
    GetPositionsDocument,
    options,
  );
}
export function useGetPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPositionsQuery,
    GetPositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPositionsQuery, GetPositionsQueryVariables>(
    GetPositionsDocument,
    options,
  );
}
export type GetPositionsQueryHookResult = ReturnType<
  typeof useGetPositionsQuery
>;
export type GetPositionsLazyQueryHookResult = ReturnType<
  typeof useGetPositionsLazyQuery
>;
export type GetPositionsQueryResult = Apollo.QueryResult<
  GetPositionsQuery,
  GetPositionsQueryVariables
>;
export const GetCurrentSeasonDocument = gql`
  query GetCurrentSeason {
    currentSeason {
      calendarDate
      season
      description
      isRegSeason
      isPostSeason
      isDraft
    }
  }
`;

/**
 * __useGetCurrentSeasonQuery__
 *
 * To run a query within a React component, call `useGetCurrentSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSeasonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentSeasonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentSeasonQuery,
    GetCurrentSeasonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentSeasonQuery, GetCurrentSeasonQueryVariables>(
    GetCurrentSeasonDocument,
    options,
  );
}
export function useGetCurrentSeasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentSeasonQuery,
    GetCurrentSeasonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentSeasonQuery,
    GetCurrentSeasonQueryVariables
  >(GetCurrentSeasonDocument, options);
}
export type GetCurrentSeasonQueryHookResult = ReturnType<
  typeof useGetCurrentSeasonQuery
>;
export type GetCurrentSeasonLazyQueryHookResult = ReturnType<
  typeof useGetCurrentSeasonLazyQuery
>;
export type GetCurrentSeasonQueryResult = Apollo.QueryResult<
  GetCurrentSeasonQuery,
  GetCurrentSeasonQueryVariables
>;
export const GetPlayerPositionDepthsDocument = gql`
  query GetPlayerPositionDepths($packageId: UUID) {
    playerPositionDepths(where: { packageId: { eq: $packageId } }) {
      depth
      id
      packageId
      package {
        ...PackageFields
      }
      playerId
      player {
        id
        firstName
        lastName
        jersey
        sumerAtlasPositionId
        sumerGeneralPositionId
        status
      }
      positionId
      position {
        id
        sideOfBall
        positionType
        shortName
      }
    }
  }
  ${PackageFieldsFragmentDoc}
`;

/**
 * __useGetPlayerPositionDepthsQuery__
 *
 * To run a query within a React component, call `useGetPlayerPositionDepthsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerPositionDepthsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerPositionDepthsQuery({
 *   variables: {
 *      packageId: // value for 'packageId'
 *   },
 * });
 */
export function useGetPlayerPositionDepthsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlayerPositionDepthsQuery,
    GetPlayerPositionDepthsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlayerPositionDepthsQuery,
    GetPlayerPositionDepthsQueryVariables
  >(GetPlayerPositionDepthsDocument, options);
}
export function useGetPlayerPositionDepthsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerPositionDepthsQuery,
    GetPlayerPositionDepthsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayerPositionDepthsQuery,
    GetPlayerPositionDepthsQueryVariables
  >(GetPlayerPositionDepthsDocument, options);
}
export type GetPlayerPositionDepthsQueryHookResult = ReturnType<
  typeof useGetPlayerPositionDepthsQuery
>;
export type GetPlayerPositionDepthsLazyQueryHookResult = ReturnType<
  typeof useGetPlayerPositionDepthsLazyQuery
>;
export type GetPlayerPositionDepthsQueryResult = Apollo.QueryResult<
  GetPlayerPositionDepthsQuery,
  GetPlayerPositionDepthsQueryVariables
>;
export const GetPackagesDocument = gql`
  query GetPackages {
    packages {
      ...PackageFields
    }
  }
  ${PackageFieldsFragmentDoc}
`;

/**
 * __useGetPackagesQuery__
 *
 * To run a query within a React component, call `useGetPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPackagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPackagesQuery,
    GetPackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPackagesQuery, GetPackagesQueryVariables>(
    GetPackagesDocument,
    options,
  );
}
export function useGetPackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPackagesQuery,
    GetPackagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPackagesQuery, GetPackagesQueryVariables>(
    GetPackagesDocument,
    options,
  );
}
export type GetPackagesQueryHookResult = ReturnType<typeof useGetPackagesQuery>;
export type GetPackagesLazyQueryHookResult = ReturnType<
  typeof useGetPackagesLazyQuery
>;
export type GetPackagesQueryResult = Apollo.QueryResult<
  GetPackagesQuery,
  GetPackagesQueryVariables
>;
export const GetPackagePositionsDocument = gql`
  query GetPackagePositions {
    packagePositions {
      id
      package {
        abbreviation
        description
        id
        name
        pffPackageId
        sideOfBall
      }
      packageId
      position {
        id
        sideOfBall
        positionType
        shortName
      }
      positionId
      x
      y
    }
  }
`;

/**
 * __useGetPackagePositionsQuery__
 *
 * To run a query within a React component, call `useGetPackagePositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagePositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagePositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPackagePositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPackagePositionsQuery,
    GetPackagePositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPackagePositionsQuery,
    GetPackagePositionsQueryVariables
  >(GetPackagePositionsDocument, options);
}
export function useGetPackagePositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPackagePositionsQuery,
    GetPackagePositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPackagePositionsQuery,
    GetPackagePositionsQueryVariables
  >(GetPackagePositionsDocument, options);
}
export type GetPackagePositionsQueryHookResult = ReturnType<
  typeof useGetPackagePositionsQuery
>;
export type GetPackagePositionsLazyQueryHookResult = ReturnType<
  typeof useGetPackagePositionsLazyQuery
>;
export type GetPackagePositionsQueryResult = Apollo.QueryResult<
  GetPackagePositionsQuery,
  GetPackagePositionsQueryVariables
>;
export const GetPackageClubsDocument = gql`
  query GetPackageClubs($clubId: UUID) {
    packageClubs(where: { clubId: { eq: $clubId } }) {
      id
      club {
        id
        clubName
        clubCode
      }
      clubId
      isBase
      package {
        ...PackageFields
      }
      packageId
    }
  }
  ${PackageFieldsFragmentDoc}
`;

/**
 * __useGetPackageClubsQuery__
 *
 * To run a query within a React component, call `useGetPackageClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageClubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageClubsQuery({
 *   variables: {
 *      clubId: // value for 'clubId'
 *   },
 * });
 */
export function useGetPackageClubsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPackageClubsQuery,
    GetPackageClubsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPackageClubsQuery, GetPackageClubsQueryVariables>(
    GetPackageClubsDocument,
    options,
  );
}
export function useGetPackageClubsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPackageClubsQuery,
    GetPackageClubsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPackageClubsQuery,
    GetPackageClubsQueryVariables
  >(GetPackageClubsDocument, options);
}
export type GetPackageClubsQueryHookResult = ReturnType<
  typeof useGetPackageClubsQuery
>;
export type GetPackageClubsLazyQueryHookResult = ReturnType<
  typeof useGetPackageClubsLazyQuery
>;
export type GetPackageClubsQueryResult = Apollo.QueryResult<
  GetPackageClubsQuery,
  GetPackageClubsQueryVariables
>;
export const GetPermissionByIdDocument = gql`
  query GetPermissionById($id: UUID!) {
    permissions(where: { id: { eq: $id } }) {
      id
      name
      description
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useGetPermissionByIdQuery__
 *
 * To run a query within a React component, call `useGetPermissionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPermissionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPermissionByIdQuery,
    GetPermissionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPermissionByIdQuery,
    GetPermissionByIdQueryVariables
  >(GetPermissionByIdDocument, options);
}
export function useGetPermissionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionByIdQuery,
    GetPermissionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPermissionByIdQuery,
    GetPermissionByIdQueryVariables
  >(GetPermissionByIdDocument, options);
}
export type GetPermissionByIdQueryHookResult = ReturnType<
  typeof useGetPermissionByIdQuery
>;
export type GetPermissionByIdLazyQueryHookResult = ReturnType<
  typeof useGetPermissionByIdLazyQuery
>;
export type GetPermissionByIdQueryResult = Apollo.QueryResult<
  GetPermissionByIdQuery,
  GetPermissionByIdQueryVariables
>;
export const GetPermissionsDocument = gql`
  query GetPermissions {
    permissions {
      id
      name
      description
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options,
  );
}
export function useGetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options,
  );
}
export type GetPermissionsQueryHookResult = ReturnType<
  typeof useGetPermissionsQuery
>;
export type GetPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermissionsLazyQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
  GetPermissionsQuery,
  GetPermissionsQueryVariables
>;
export const UpdatePlayerDocument = gql`
  mutation UpdatePlayer($updatePlayerRequest: UpdatePlayerRequest!) {
    updatePlayer(updatePlayerRequest: $updatePlayerRequest) {
      id
    }
  }
`;
export type UpdatePlayerMutationFn = Apollo.MutationFunction<
  UpdatePlayerMutation,
  UpdatePlayerMutationVariables
>;

/**
 * __useUpdatePlayerMutation__
 *
 * To run a mutation, you first call `useUpdatePlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerMutation, { data, loading, error }] = useUpdatePlayerMutation({
 *   variables: {
 *      updatePlayerRequest: // value for 'updatePlayerRequest'
 *   },
 * });
 */
export function useUpdatePlayerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlayerMutation,
    UpdatePlayerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePlayerMutation,
    UpdatePlayerMutationVariables
  >(UpdatePlayerDocument, options);
}
export type UpdatePlayerMutationHookResult = ReturnType<
  typeof useUpdatePlayerMutation
>;
export type UpdatePlayerMutationResult =
  Apollo.MutationResult<UpdatePlayerMutation>;
export type UpdatePlayerMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlayerMutation,
  UpdatePlayerMutationVariables
>;
export const UpdateBasicPlayerDetailsDocument = gql`
  mutation UpdateBasicPlayerDetails(
    $updateBasicPlayerDetailsRequest: UpdateBasicPlayerDetailsRequest!
  ) {
    updateBasicPlayerDetails(
      updateBasicPlayerDetailsRequest: $updateBasicPlayerDetailsRequest
    ) {
      id
    }
  }
`;
export type UpdateBasicPlayerDetailsMutationFn = Apollo.MutationFunction<
  UpdateBasicPlayerDetailsMutation,
  UpdateBasicPlayerDetailsMutationVariables
>;

/**
 * __useUpdateBasicPlayerDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateBasicPlayerDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBasicPlayerDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasicPlayerDetailsMutation, { data, loading, error }] = useUpdateBasicPlayerDetailsMutation({
 *   variables: {
 *      updateBasicPlayerDetailsRequest: // value for 'updateBasicPlayerDetailsRequest'
 *   },
 * });
 */
export function useUpdateBasicPlayerDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBasicPlayerDetailsMutation,
    UpdateBasicPlayerDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBasicPlayerDetailsMutation,
    UpdateBasicPlayerDetailsMutationVariables
  >(UpdateBasicPlayerDetailsDocument, options);
}
export type UpdateBasicPlayerDetailsMutationHookResult = ReturnType<
  typeof useUpdateBasicPlayerDetailsMutation
>;
export type UpdateBasicPlayerDetailsMutationResult =
  Apollo.MutationResult<UpdateBasicPlayerDetailsMutation>;
export type UpdateBasicPlayerDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateBasicPlayerDetailsMutation,
    UpdateBasicPlayerDetailsMutationVariables
  >;
export const AssignPlayerOverrideDocument = gql`
  mutation AssignPlayerOverride(
    $assignPlayerOverride: AssignPlayerOverrideRequest!
  ) {
    assignPlayerOverride(assignPlayerOverride: $assignPlayerOverride) {
      id
      latestBirthDate
      eligibilityYear
      eligibility
      latestEligibilityYear
      latestEligibility
      latestSchoolId
      latestClubId
      latestHighSchoolGradYear
      highSchoolGradYear
      clubId
      schoolId
      playerOverrides {
        field
        value
      }
    }
  }
`;
export type AssignPlayerOverrideMutationFn = Apollo.MutationFunction<
  AssignPlayerOverrideMutation,
  AssignPlayerOverrideMutationVariables
>;

/**
 * __useAssignPlayerOverrideMutation__
 *
 * To run a mutation, you first call `useAssignPlayerOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPlayerOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPlayerOverrideMutation, { data, loading, error }] = useAssignPlayerOverrideMutation({
 *   variables: {
 *      assignPlayerOverride: // value for 'assignPlayerOverride'
 *   },
 * });
 */
export function useAssignPlayerOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignPlayerOverrideMutation,
    AssignPlayerOverrideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignPlayerOverrideMutation,
    AssignPlayerOverrideMutationVariables
  >(AssignPlayerOverrideDocument, options);
}
export type AssignPlayerOverrideMutationHookResult = ReturnType<
  typeof useAssignPlayerOverrideMutation
>;
export type AssignPlayerOverrideMutationResult =
  Apollo.MutationResult<AssignPlayerOverrideMutation>;
export type AssignPlayerOverrideMutationOptions = Apollo.BaseMutationOptions<
  AssignPlayerOverrideMutation,
  AssignPlayerOverrideMutationVariables
>;
export const SaveBigBoardPlayersDocument = gql`
  mutation SaveBigBoardPlayers($request: SaveBigBoardPlayerRequest!) {
    saveBigBoardPlayers(request: $request) {
      id
      playerId
      rank
    }
  }
`;
export type SaveBigBoardPlayersMutationFn = Apollo.MutationFunction<
  SaveBigBoardPlayersMutation,
  SaveBigBoardPlayersMutationVariables
>;

/**
 * __useSaveBigBoardPlayersMutation__
 *
 * To run a mutation, you first call `useSaveBigBoardPlayersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBigBoardPlayersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBigBoardPlayersMutation, { data, loading, error }] = useSaveBigBoardPlayersMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveBigBoardPlayersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveBigBoardPlayersMutation,
    SaveBigBoardPlayersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveBigBoardPlayersMutation,
    SaveBigBoardPlayersMutationVariables
  >(SaveBigBoardPlayersDocument, options);
}
export type SaveBigBoardPlayersMutationHookResult = ReturnType<
  typeof useSaveBigBoardPlayersMutation
>;
export type SaveBigBoardPlayersMutationResult =
  Apollo.MutationResult<SaveBigBoardPlayersMutation>;
export type SaveBigBoardPlayersMutationOptions = Apollo.BaseMutationOptions<
  SaveBigBoardPlayersMutation,
  SaveBigBoardPlayersMutationVariables
>;
export const SavePlayerTagsDocument = gql`
  mutation SavePlayerTags($request: SavePlayerTagsRequest!) {
    savePlayerTags(request: $request) {
      id
    }
  }
`;
export type SavePlayerTagsMutationFn = Apollo.MutationFunction<
  SavePlayerTagsMutation,
  SavePlayerTagsMutationVariables
>;

/**
 * __useSavePlayerTagsMutation__
 *
 * To run a mutation, you first call `useSavePlayerTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePlayerTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePlayerTagsMutation, { data, loading, error }] = useSavePlayerTagsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSavePlayerTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePlayerTagsMutation,
    SavePlayerTagsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SavePlayerTagsMutation,
    SavePlayerTagsMutationVariables
  >(SavePlayerTagsDocument, options);
}
export type SavePlayerTagsMutationHookResult = ReturnType<
  typeof useSavePlayerTagsMutation
>;
export type SavePlayerTagsMutationResult =
  Apollo.MutationResult<SavePlayerTagsMutation>;
export type SavePlayerTagsMutationOptions = Apollo.BaseMutationOptions<
  SavePlayerTagsMutation,
  SavePlayerTagsMutationVariables
>;
export const GetPlayerDocument = gql`
  query getPlayer($id: UUID!) {
    user {
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
    playerById(id: $id) {
      ...Player
    }
  }
  ${PermissionFragmentDoc}
  ${PlayerFragmentDoc}
`;

/**
 * __useGetPlayerQuery__
 *
 * To run a query within a React component, call `useGetPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlayerQuery, GetPlayerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayerQuery, GetPlayerQueryVariables>(
    GetPlayerDocument,
    options,
  );
}
export function useGetPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerQuery,
    GetPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlayerQuery, GetPlayerQueryVariables>(
    GetPlayerDocument,
    options,
  );
}
export type GetPlayerQueryHookResult = ReturnType<typeof useGetPlayerQuery>;
export type GetPlayerLazyQueryHookResult = ReturnType<
  typeof useGetPlayerLazyQuery
>;
export type GetPlayerQueryResult = Apollo.QueryResult<
  GetPlayerQuery,
  GetPlayerQueryVariables
>;
export const GetPlayerSlimDocument = gql`
  query getPlayerSlim($id: UUID!) {
    user {
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
    playerById(id: $id) {
      ...PlayerSlim
    }
  }
  ${PermissionFragmentDoc}
  ${PlayerSlimFragmentDoc}
`;

/**
 * __useGetPlayerSlimQuery__
 *
 * To run a query within a React component, call `useGetPlayerSlimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerSlimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerSlimQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPlayerSlimQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayerSlimQuery,
    GetPlayerSlimQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayerSlimQuery, GetPlayerSlimQueryVariables>(
    GetPlayerSlimDocument,
    options,
  );
}
export function useGetPlayerSlimLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerSlimQuery,
    GetPlayerSlimQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlayerSlimQuery, GetPlayerSlimQueryVariables>(
    GetPlayerSlimDocument,
    options,
  );
}
export type GetPlayerSlimQueryHookResult = ReturnType<
  typeof useGetPlayerSlimQuery
>;
export type GetPlayerSlimLazyQueryHookResult = ReturnType<
  typeof useGetPlayerSlimLazyQuery
>;
export type GetPlayerSlimQueryResult = Apollo.QueryResult<
  GetPlayerSlimQuery,
  GetPlayerSlimQueryVariables
>;
export const GetPlayersDocument = gql`
  query GetPlayers(
    $name: String
    $clubName: String
    $schoolCode: String
    $position: String
    $positionGroup: String
    $group: String
    $draftYear: String
    $sortCriteria: PlayerSearchSortCriteriaEnum!
  ) {
    user {
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
    players(
      sumerAtlasPositionFilter: $position
      clubFilter: $clubName
      schoolFilter: $schoolCode
      sumerGeneralPositionFilter: $positionGroup
      groupFilter: $group
      draftYearFilter: $draftYear
      sortCriteria: $sortCriteria
      where: {
        or: [
          { firstName: { contains: $name } }
          { lastName: { contains: $name } }
        ]
      }
    ) {
      ...Player
    }
  }
  ${PermissionFragmentDoc}
  ${PlayerFragmentDoc}
`;

/**
 * __useGetPlayersQuery__
 *
 * To run a query within a React component, call `useGetPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersQuery({
 *   variables: {
 *      name: // value for 'name'
 *      clubName: // value for 'clubName'
 *      schoolCode: // value for 'schoolCode'
 *      position: // value for 'position'
 *      positionGroup: // value for 'positionGroup'
 *      group: // value for 'group'
 *      draftYear: // value for 'draftYear'
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayersQuery,
    GetPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayersQuery, GetPlayersQueryVariables>(
    GetPlayersDocument,
    options,
  );
}
export function useGetPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayersQuery,
    GetPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlayersQuery, GetPlayersQueryVariables>(
    GetPlayersDocument,
    options,
  );
}
export type GetPlayersQueryHookResult = ReturnType<typeof useGetPlayersQuery>;
export type GetPlayersLazyQueryHookResult = ReturnType<
  typeof useGetPlayersLazyQuery
>;
export type GetPlayersQueryResult = Apollo.QueryResult<
  GetPlayersQuery,
  GetPlayersQueryVariables
>;
export const GetPlayersPagedDocument = gql`
  query GetPlayersPaged(
    $after: String
    $before: String
    $last: Int
    $first: Int
    $name: String
    $clubName: String
    $schoolCode: String
    $position: String
    $positionGroup: String
    $group: String
    $draftYear: String
    $sortCriteria: PlayerSearchSortCriteriaEnum!
  ) {
    user {
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
    playersPaged(
      after: $after
      before: $before
      last: $last
      first: $first
      sumerAtlasPositionFilter: $position
      clubFilter: $clubName
      schoolFilter: $schoolCode
      sumerGeneralPositionFilter: $positionGroup
      groupFilter: $group
      draftYearFilter: $draftYear
      sortCriteria: $sortCriteria
      where: {
        or: [
          { firstName: { contains: $name } }
          { lastName: { contains: $name } }
        ]
      }
    ) {
      edges {
        node {
          ...Player
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${PermissionFragmentDoc}
  ${PlayerFragmentDoc}
`;

/**
 * __useGetPlayersPagedQuery__
 *
 * To run a query within a React component, call `useGetPlayersPagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersPagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersPagedQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      name: // value for 'name'
 *      clubName: // value for 'clubName'
 *      schoolCode: // value for 'schoolCode'
 *      position: // value for 'position'
 *      positionGroup: // value for 'positionGroup'
 *      group: // value for 'group'
 *      draftYear: // value for 'draftYear'
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetPlayersPagedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayersPagedQuery,
    GetPlayersPagedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayersPagedQuery, GetPlayersPagedQueryVariables>(
    GetPlayersPagedDocument,
    options,
  );
}
export function useGetPlayersPagedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayersPagedQuery,
    GetPlayersPagedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayersPagedQuery,
    GetPlayersPagedQueryVariables
  >(GetPlayersPagedDocument, options);
}
export type GetPlayersPagedQueryHookResult = ReturnType<
  typeof useGetPlayersPagedQuery
>;
export type GetPlayersPagedLazyQueryHookResult = ReturnType<
  typeof useGetPlayersPagedLazyQuery
>;
export type GetPlayersPagedQueryResult = Apollo.QueryResult<
  GetPlayersPagedQuery,
  GetPlayersPagedQueryVariables
>;
export const GetOnRosterPlayersDocument = gql`
  query GetOnRosterPlayers(
    $clubName: String
    $group: String
    $sortCriteria: PlayerSearchSortCriteriaEnum!
  ) {
    players(
      clubFilter: $clubName
      groupFilter: $group
      sortCriteria: $sortCriteria
    ) {
      ...Player
    }
  }
  ${PlayerFragmentDoc}
`;

/**
 * __useGetOnRosterPlayersQuery__
 *
 * To run a query within a React component, call `useGetOnRosterPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnRosterPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnRosterPlayersQuery({
 *   variables: {
 *      clubName: // value for 'clubName'
 *      group: // value for 'group'
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetOnRosterPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnRosterPlayersQuery,
    GetOnRosterPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOnRosterPlayersQuery,
    GetOnRosterPlayersQueryVariables
  >(GetOnRosterPlayersDocument, options);
}
export function useGetOnRosterPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnRosterPlayersQuery,
    GetOnRosterPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOnRosterPlayersQuery,
    GetOnRosterPlayersQueryVariables
  >(GetOnRosterPlayersDocument, options);
}
export type GetOnRosterPlayersQueryHookResult = ReturnType<
  typeof useGetOnRosterPlayersQuery
>;
export type GetOnRosterPlayersLazyQueryHookResult = ReturnType<
  typeof useGetOnRosterPlayersLazyQuery
>;
export type GetOnRosterPlayersQueryResult = Apollo.QueryResult<
  GetOnRosterPlayersQuery,
  GetOnRosterPlayersQueryVariables
>;
export const GetPlayerListDocument = gql`
  query GetPlayerList(
    $teamId: UUID
    $group: String
    $sortCriteria: PlayerSearchSortCriteriaEnum!
  ) {
    players(
      groupFilter: $group
      sortCriteria: $sortCriteria
      where: {
        or: [
          { schoolId: { eq: $teamId } }
          { mostRecentClubId: { eq: $teamId } }
        ]
      }
    ) {
      ...PlayerList
    }
  }
  ${PlayerListFragmentDoc}
`;

/**
 * __useGetPlayerListQuery__
 *
 * To run a query within a React component, call `useGetPlayerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayerListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      group: // value for 'group'
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetPlayerListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayerListQuery,
    GetPlayerListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlayerListQuery, GetPlayerListQueryVariables>(
    GetPlayerListDocument,
    options,
  );
}
export function useGetPlayerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayerListQuery,
    GetPlayerListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlayerListQuery, GetPlayerListQueryVariables>(
    GetPlayerListDocument,
    options,
  );
}
export type GetPlayerListQueryHookResult = ReturnType<
  typeof useGetPlayerListQuery
>;
export type GetPlayerListLazyQueryHookResult = ReturnType<
  typeof useGetPlayerListLazyQuery
>;
export type GetPlayerListQueryResult = Apollo.QueryResult<
  GetPlayerListQuery,
  GetPlayerListQueryVariables
>;
export const GetClubRosterDocument = gql`
  query GetClubRoster(
    $clubName: String
    $group: String
    $sortCriteria: PlayerSearchSortCriteriaEnum!
  ) {
    players(
      clubFilter: $clubName
      groupFilter: $group
      sortCriteria: $sortCriteria
    ) {
      ...ClubRosterPlayer
    }
  }
  ${ClubRosterPlayerFragmentDoc}
`;

/**
 * __useGetClubRosterQuery__
 *
 * To run a query within a React component, call `useGetClubRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubRosterQuery({
 *   variables: {
 *      clubName: // value for 'clubName'
 *      group: // value for 'group'
 *      sortCriteria: // value for 'sortCriteria'
 *   },
 * });
 */
export function useGetClubRosterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClubRosterQuery,
    GetClubRosterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClubRosterQuery, GetClubRosterQueryVariables>(
    GetClubRosterDocument,
    options,
  );
}
export function useGetClubRosterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClubRosterQuery,
    GetClubRosterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClubRosterQuery, GetClubRosterQueryVariables>(
    GetClubRosterDocument,
    options,
  );
}
export type GetClubRosterQueryHookResult = ReturnType<
  typeof useGetClubRosterQuery
>;
export type GetClubRosterLazyQueryHookResult = ReturnType<
  typeof useGetClubRosterLazyQuery
>;
export type GetClubRosterQueryResult = Apollo.QueryResult<
  GetClubRosterQuery,
  GetClubRosterQueryVariables
>;
export const GetCollegeRosterDocument = gql`
  query GetCollegeRoster(
    $schoolId: UUID!
    $overrideSchoolId: String
    $eligibilityYear: Int
  ) {
    rosterPlayers(
      where: {
        and: [
          { eligibilityYear: { gte: $eligibilityYear } }
          { draftYear: { eq: null } }
        ]
        or: [
          { schoolId: { eq: $schoolId } }
          { playerOverrides: { some: { value: { eq: $overrideSchoolId } } } }
        ]
      }
    ) {
      ...CollegeRosterPlayer
    }
  }
  ${CollegeRosterPlayerFragmentDoc}
`;

/**
 * __useGetCollegeRosterQuery__
 *
 * To run a query within a React component, call `useGetCollegeRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollegeRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollegeRosterQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      overrideSchoolId: // value for 'overrideSchoolId'
 *      eligibilityYear: // value for 'eligibilityYear'
 *   },
 * });
 */
export function useGetCollegeRosterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCollegeRosterQuery,
    GetCollegeRosterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCollegeRosterQuery, GetCollegeRosterQueryVariables>(
    GetCollegeRosterDocument,
    options,
  );
}
export function useGetCollegeRosterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCollegeRosterQuery,
    GetCollegeRosterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCollegeRosterQuery,
    GetCollegeRosterQueryVariables
  >(GetCollegeRosterDocument, options);
}
export type GetCollegeRosterQueryHookResult = ReturnType<
  typeof useGetCollegeRosterQuery
>;
export type GetCollegeRosterLazyQueryHookResult = ReturnType<
  typeof useGetCollegeRosterLazyQuery
>;
export type GetCollegeRosterQueryResult = Apollo.QueryResult<
  GetCollegeRosterQuery,
  GetCollegeRosterQueryVariables
>;
export const SearchPlayersDocument = gql`
  query SearchPlayers(
    $searchTerm: String!
    $orderByFields: [String!]
    $filters: PlayerFiltersInput
    $options: SearchOptionsInput
  ) {
    searchPlayers(
      searchTerm: $searchTerm
      orderByFields: $orderByFields
      filters: $filters
      options: $options
    ) {
      total
      players {
        record {
          id
          clubCode
          clubId
          clubName
          schoolId
          schoolCode
          schoolName
          draftYear
          firstName
          lastName
          hometown
          height
          weight
          speed
          jersey
          sumerAtlasPositionShortName
          sumerGeneralPositionShortName
          primarySchemePositionShortName
          redshirt
          headshotUrl
          eligibility
          eligibilityYear
          bestClubGrade
          bestClubGradeType
          sumerPrimaryEvaluationGrade
        }
      }
    }
  }
`;

/**
 * __useSearchPlayersQuery__
 *
 * To run a query within a React component, call `useSearchPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlayersQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      orderByFields: // value for 'orderByFields'
 *      filters: // value for 'filters'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useSearchPlayersQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPlayersQuery,
    SearchPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPlayersQuery, SearchPlayersQueryVariables>(
    SearchPlayersDocument,
    options,
  );
}
export function useSearchPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPlayersQuery,
    SearchPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPlayersQuery, SearchPlayersQueryVariables>(
    SearchPlayersDocument,
    options,
  );
}
export type SearchPlayersQueryHookResult = ReturnType<
  typeof useSearchPlayersQuery
>;
export type SearchPlayersLazyQueryHookResult = ReturnType<
  typeof useSearchPlayersLazyQuery
>;
export type SearchPlayersQueryResult = Apollo.QueryResult<
  SearchPlayersQuery,
  SearchPlayersQueryVariables
>;
export const GetBigBoardPlayersDocument = gql`
  query GetBigBoardPlayers {
    bigBoardPlayers {
      id
      playerId
      rank
    }
  }
`;

/**
 * __useGetBigBoardPlayersQuery__
 *
 * To run a query within a React component, call `useGetBigBoardPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBigBoardPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBigBoardPlayersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBigBoardPlayersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBigBoardPlayersQuery,
    GetBigBoardPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBigBoardPlayersQuery,
    GetBigBoardPlayersQueryVariables
  >(GetBigBoardPlayersDocument, options);
}
export function useGetBigBoardPlayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBigBoardPlayersQuery,
    GetBigBoardPlayersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBigBoardPlayersQuery,
    GetBigBoardPlayersQueryVariables
  >(GetBigBoardPlayersDocument, options);
}
export type GetBigBoardPlayersQueryHookResult = ReturnType<
  typeof useGetBigBoardPlayersQuery
>;
export type GetBigBoardPlayersLazyQueryHookResult = ReturnType<
  typeof useGetBigBoardPlayersLazyQuery
>;
export type GetBigBoardPlayersQueryResult = Apollo.QueryResult<
  GetBigBoardPlayersQuery,
  GetBigBoardPlayersQueryVariables
>;
export const GetRoleByIdDocument = gql`
  query GetRoleById($id: UUID!) {
    roles(where: { id: { eq: $id } }) {
      id
      name
      permissions {
        id
        name
        description
      }
    }
  }
`;

/**
 * __useGetRoleByIdQuery__
 *
 * To run a query within a React component, call `useGetRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoleByIdQuery,
    GetRoleByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(
    GetRoleByIdDocument,
    options,
  );
}
export function useGetRoleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoleByIdQuery,
    GetRoleByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoleByIdQuery, GetRoleByIdQueryVariables>(
    GetRoleByIdDocument,
    options,
  );
}
export type GetRoleByIdQueryHookResult = ReturnType<typeof useGetRoleByIdQuery>;
export type GetRoleByIdLazyQueryHookResult = ReturnType<
  typeof useGetRoleByIdLazyQuery
>;
export type GetRoleByIdQueryResult = Apollo.QueryResult<
  GetRoleByIdQuery,
  GetRoleByIdQueryVariables
>;
export const GetRolesDocument = gql`
  query GetRoles {
    roles {
      id
      name
      permissions {
        id
        name
        description
      }
    }
  }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options,
  );
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRolesQuery,
    GetRolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(
    GetRolesDocument,
    options,
  );
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<
  typeof useGetRolesLazyQuery
>;
export type GetRolesQueryResult = Apollo.QueryResult<
  GetRolesQuery,
  GetRolesQueryVariables
>;
export const GetSchoolsByDivisionDocument = gql`
  query getSchoolsByDivision($division: [String]) {
    schools(
      order: { schoolCode: ASC }
      where: { and: [{ division: { in: $division } }] }
    ) {
      ...School
    }
  }
  ${SchoolFragmentDoc}
`;

/**
 * __useGetSchoolsByDivisionQuery__
 *
 * To run a query within a React component, call `useGetSchoolsByDivisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsByDivisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsByDivisionQuery({
 *   variables: {
 *      division: // value for 'division'
 *   },
 * });
 */
export function useGetSchoolsByDivisionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolsByDivisionQuery,
    GetSchoolsByDivisionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolsByDivisionQuery,
    GetSchoolsByDivisionQueryVariables
  >(GetSchoolsByDivisionDocument, options);
}
export function useGetSchoolsByDivisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolsByDivisionQuery,
    GetSchoolsByDivisionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolsByDivisionQuery,
    GetSchoolsByDivisionQueryVariables
  >(GetSchoolsByDivisionDocument, options);
}
export type GetSchoolsByDivisionQueryHookResult = ReturnType<
  typeof useGetSchoolsByDivisionQuery
>;
export type GetSchoolsByDivisionLazyQueryHookResult = ReturnType<
  typeof useGetSchoolsByDivisionLazyQuery
>;
export type GetSchoolsByDivisionQueryResult = Apollo.QueryResult<
  GetSchoolsByDivisionQuery,
  GetSchoolsByDivisionQueryVariables
>;
export const GetSchoolsForLookupDocument = gql`
  query getSchoolsForLookup($division: [String]) {
    schools(
      order: { schoolCode: ASC }
      where: { and: [{ division: { in: $division } }] }
    ) {
      ...SchoolList
    }
  }
  ${SchoolListFragmentDoc}
`;

/**
 * __useGetSchoolsForLookupQuery__
 *
 * To run a query within a React component, call `useGetSchoolsForLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsForLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsForLookupQuery({
 *   variables: {
 *      division: // value for 'division'
 *   },
 * });
 */
export function useGetSchoolsForLookupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolsForLookupQuery,
    GetSchoolsForLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolsForLookupQuery,
    GetSchoolsForLookupQueryVariables
  >(GetSchoolsForLookupDocument, options);
}
export function useGetSchoolsForLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolsForLookupQuery,
    GetSchoolsForLookupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolsForLookupQuery,
    GetSchoolsForLookupQueryVariables
  >(GetSchoolsForLookupDocument, options);
}
export type GetSchoolsForLookupQueryHookResult = ReturnType<
  typeof useGetSchoolsForLookupQuery
>;
export type GetSchoolsForLookupLazyQueryHookResult = ReturnType<
  typeof useGetSchoolsForLookupLazyQuery
>;
export type GetSchoolsForLookupQueryResult = Apollo.QueryResult<
  GetSchoolsForLookupQuery,
  GetSchoolsForLookupQueryVariables
>;
export const GetSchoolDocument = gql`
  query getSchool($id: UUID!) {
    schoolById(id: $id) {
      ...School
    }
  }
  ${SchoolFragmentDoc}
`;

/**
 * __useGetSchoolQuery__
 *
 * To run a query within a React component, call `useGetSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolQuery, GetSchoolQueryVariables>(
    GetSchoolDocument,
    options,
  );
}
export function useGetSchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolQuery,
    GetSchoolQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSchoolQuery, GetSchoolQueryVariables>(
    GetSchoolDocument,
    options,
  );
}
export type GetSchoolQueryHookResult = ReturnType<typeof useGetSchoolQuery>;
export type GetSchoolLazyQueryHookResult = ReturnType<
  typeof useGetSchoolLazyQuery
>;
export type GetSchoolQueryResult = Apollo.QueryResult<
  GetSchoolQuery,
  GetSchoolQueryVariables
>;
export const GetSchoolsDocument = gql`
  query getSchools {
    schools(order: { schoolCode: ASC }) {
      ...School
    }
  }
  ${SchoolFragmentDoc}
`;

/**
 * __useGetSchoolsQuery__
 *
 * To run a query within a React component, call `useGetSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchoolsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolsQuery,
    GetSchoolsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(
    GetSchoolsDocument,
    options,
  );
}
export function useGetSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolsQuery,
    GetSchoolsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(
    GetSchoolsDocument,
    options,
  );
}
export type GetSchoolsQueryHookResult = ReturnType<typeof useGetSchoolsQuery>;
export type GetSchoolsLazyQueryHookResult = ReturnType<
  typeof useGetSchoolsLazyQuery
>;
export type GetSchoolsQueryResult = Apollo.QueryResult<
  GetSchoolsQuery,
  GetSchoolsQueryVariables
>;
export const SavePlayerAssignmentDocument = gql`
  mutation SavePlayerAssignment($request: SavePlayerAssignmentRequest!) {
    savePlayerAssignment(request: $request) {
      ...ScoutingAssignmentFields
    }
  }
  ${ScoutingAssignmentFieldsFragmentDoc}
`;
export type SavePlayerAssignmentMutationFn = Apollo.MutationFunction<
  SavePlayerAssignmentMutation,
  SavePlayerAssignmentMutationVariables
>;

/**
 * __useSavePlayerAssignmentMutation__
 *
 * To run a mutation, you first call `useSavePlayerAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePlayerAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePlayerAssignmentMutation, { data, loading, error }] = useSavePlayerAssignmentMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSavePlayerAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePlayerAssignmentMutation,
    SavePlayerAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SavePlayerAssignmentMutation,
    SavePlayerAssignmentMutationVariables
  >(SavePlayerAssignmentDocument, options);
}
export type SavePlayerAssignmentMutationHookResult = ReturnType<
  typeof useSavePlayerAssignmentMutation
>;
export type SavePlayerAssignmentMutationResult =
  Apollo.MutationResult<SavePlayerAssignmentMutation>;
export type SavePlayerAssignmentMutationOptions = Apollo.BaseMutationOptions<
  SavePlayerAssignmentMutation,
  SavePlayerAssignmentMutationVariables
>;
export const CreateScoutAssignmentDocument = gql`
  mutation CreateScoutAssignment($request: CreateScoutingAssignmentRequest!) {
    createScoutingAssignment(request: $request) {
      ...AdminScoutingAssignmentFields
    }
  }
  ${AdminScoutingAssignmentFieldsFragmentDoc}
`;
export type CreateScoutAssignmentMutationFn = Apollo.MutationFunction<
  CreateScoutAssignmentMutation,
  CreateScoutAssignmentMutationVariables
>;

/**
 * __useCreateScoutAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateScoutAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScoutAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScoutAssignmentMutation, { data, loading, error }] = useCreateScoutAssignmentMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateScoutAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScoutAssignmentMutation,
    CreateScoutAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScoutAssignmentMutation,
    CreateScoutAssignmentMutationVariables
  >(CreateScoutAssignmentDocument, options);
}
export type CreateScoutAssignmentMutationHookResult = ReturnType<
  typeof useCreateScoutAssignmentMutation
>;
export type CreateScoutAssignmentMutationResult =
  Apollo.MutationResult<CreateScoutAssignmentMutation>;
export type CreateScoutAssignmentMutationOptions = Apollo.BaseMutationOptions<
  CreateScoutAssignmentMutation,
  CreateScoutAssignmentMutationVariables
>;
export const UpdateScoutAssignmentDocument = gql`
  mutation UpdateScoutAssignment($request: UpdateScoutingAssignmentRequest!) {
    updateScoutingAssignment(request: $request) {
      ...AdminScoutingAssignmentFields
    }
  }
  ${AdminScoutingAssignmentFieldsFragmentDoc}
`;
export type UpdateScoutAssignmentMutationFn = Apollo.MutationFunction<
  UpdateScoutAssignmentMutation,
  UpdateScoutAssignmentMutationVariables
>;

/**
 * __useUpdateScoutAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateScoutAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScoutAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScoutAssignmentMutation, { data, loading, error }] = useUpdateScoutAssignmentMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateScoutAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScoutAssignmentMutation,
    UpdateScoutAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScoutAssignmentMutation,
    UpdateScoutAssignmentMutationVariables
  >(UpdateScoutAssignmentDocument, options);
}
export type UpdateScoutAssignmentMutationHookResult = ReturnType<
  typeof useUpdateScoutAssignmentMutation
>;
export type UpdateScoutAssignmentMutationResult =
  Apollo.MutationResult<UpdateScoutAssignmentMutation>;
export type UpdateScoutAssignmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateScoutAssignmentMutation,
  UpdateScoutAssignmentMutationVariables
>;
export const DeleteScoutAssignmentDocument = gql`
  mutation DeleteScoutAssignment($request: DeleteScoutingAssignmentRequest!) {
    deleteScoutingAssignment(request: $request) {
      ...AdminScoutingAssignmentFields
    }
  }
  ${AdminScoutingAssignmentFieldsFragmentDoc}
`;
export type DeleteScoutAssignmentMutationFn = Apollo.MutationFunction<
  DeleteScoutAssignmentMutation,
  DeleteScoutAssignmentMutationVariables
>;

/**
 * __useDeleteScoutAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteScoutAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScoutAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScoutAssignmentMutation, { data, loading, error }] = useDeleteScoutAssignmentMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDeleteScoutAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScoutAssignmentMutation,
    DeleteScoutAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScoutAssignmentMutation,
    DeleteScoutAssignmentMutationVariables
  >(DeleteScoutAssignmentDocument, options);
}
export type DeleteScoutAssignmentMutationHookResult = ReturnType<
  typeof useDeleteScoutAssignmentMutation
>;
export type DeleteScoutAssignmentMutationResult =
  Apollo.MutationResult<DeleteScoutAssignmentMutation>;
export type DeleteScoutAssignmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteScoutAssignmentMutation,
  DeleteScoutAssignmentMutationVariables
>;
export const GetScoutingAssignmentsForUserDocument = gql`
  query GetScoutingAssignmentsForUser($userId: UUID!) {
    scoutingAssignmentsByUserId(userId: $userId) {
      ...ScoutingAssignmentFields
    }
  }
  ${ScoutingAssignmentFieldsFragmentDoc}
`;

/**
 * __useGetScoutingAssignmentsForUserQuery__
 *
 * To run a query within a React component, call `useGetScoutingAssignmentsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScoutingAssignmentsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScoutingAssignmentsForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetScoutingAssignmentsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScoutingAssignmentsForUserQuery,
    GetScoutingAssignmentsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetScoutingAssignmentsForUserQuery,
    GetScoutingAssignmentsForUserQueryVariables
  >(GetScoutingAssignmentsForUserDocument, options);
}
export function useGetScoutingAssignmentsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScoutingAssignmentsForUserQuery,
    GetScoutingAssignmentsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScoutingAssignmentsForUserQuery,
    GetScoutingAssignmentsForUserQueryVariables
  >(GetScoutingAssignmentsForUserDocument, options);
}
export type GetScoutingAssignmentsForUserQueryHookResult = ReturnType<
  typeof useGetScoutingAssignmentsForUserQuery
>;
export type GetScoutingAssignmentsForUserLazyQueryHookResult = ReturnType<
  typeof useGetScoutingAssignmentsForUserLazyQuery
>;
export type GetScoutingAssignmentsForUserQueryResult = Apollo.QueryResult<
  GetScoutingAssignmentsForUserQuery,
  GetScoutingAssignmentsForUserQueryVariables
>;
export const GetScoutingAssignmentsDocument = gql`
  query GetScoutingAssignments($playerId: UUID!) {
    scoutingAssignments(where: { playerId: { eq: $playerId } }) {
      ...ScoutingAssignmentFields
    }
  }
  ${ScoutingAssignmentFieldsFragmentDoc}
`;

/**
 * __useGetScoutingAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetScoutingAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScoutingAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScoutingAssignmentsQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetScoutingAssignmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetScoutingAssignmentsQuery,
    GetScoutingAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetScoutingAssignmentsQuery,
    GetScoutingAssignmentsQueryVariables
  >(GetScoutingAssignmentsDocument, options);
}
export function useGetScoutingAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetScoutingAssignmentsQuery,
    GetScoutingAssignmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetScoutingAssignmentsQuery,
    GetScoutingAssignmentsQueryVariables
  >(GetScoutingAssignmentsDocument, options);
}
export type GetScoutingAssignmentsQueryHookResult = ReturnType<
  typeof useGetScoutingAssignmentsQuery
>;
export type GetScoutingAssignmentsLazyQueryHookResult = ReturnType<
  typeof useGetScoutingAssignmentsLazyQuery
>;
export type GetScoutingAssignmentsQueryResult = Apollo.QueryResult<
  GetScoutingAssignmentsQuery,
  GetScoutingAssignmentsQueryVariables
>;
export const GetGameByIdDocument = gql`
  query GetGameById($id: UUID!) {
    games(where: { id: { eq: $id } }) {
      ...Game
    }
  }
  ${GameFragmentDoc}
`;

/**
 * __useGetGameByIdQuery__
 *
 * To run a query within a React component, call `useGetGameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGameByIdQuery,
    GetGameByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameByIdQuery, GetGameByIdQueryVariables>(
    GetGameByIdDocument,
    options,
  );
}
export function useGetGameByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameByIdQuery,
    GetGameByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameByIdQuery, GetGameByIdQueryVariables>(
    GetGameByIdDocument,
    options,
  );
}
export type GetGameByIdQueryHookResult = ReturnType<typeof useGetGameByIdQuery>;
export type GetGameByIdLazyQueryHookResult = ReturnType<
  typeof useGetGameByIdLazyQuery
>;
export type GetGameByIdQueryResult = Apollo.QueryResult<
  GetGameByIdQuery,
  GetGameByIdQueryVariables
>;
export const GetAllStarGamesDocument = gql`
  query GetAllStarGames($homeTeams: [String], $season: Int!) {
    games(
      where: {
        and: [
          { homeTeam: { in: $homeTeams } }
          { season: { eq: $season } }
          { leagueTypeId: { eq: 1 } }
          { seasonTypeId: { eq: 4 } }
        ]
      }
    ) {
      ...Game
    }
  }
  ${GameFragmentDoc}
`;

/**
 * __useGetAllStarGamesQuery__
 *
 * To run a query within a React component, call `useGetAllStarGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStarGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStarGamesQuery({
 *   variables: {
 *      homeTeams: // value for 'homeTeams'
 *      season: // value for 'season'
 *   },
 * });
 */
export function useGetAllStarGamesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllStarGamesQuery,
    GetAllStarGamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllStarGamesQuery, GetAllStarGamesQueryVariables>(
    GetAllStarGamesDocument,
    options,
  );
}
export function useGetAllStarGamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllStarGamesQuery,
    GetAllStarGamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllStarGamesQuery,
    GetAllStarGamesQueryVariables
  >(GetAllStarGamesDocument, options);
}
export type GetAllStarGamesQueryHookResult = ReturnType<
  typeof useGetAllStarGamesQuery
>;
export type GetAllStarGamesLazyQueryHookResult = ReturnType<
  typeof useGetAllStarGamesLazyQuery
>;
export type GetAllStarGamesQueryResult = Apollo.QueryResult<
  GetAllStarGamesQuery,
  GetAllStarGamesQueryVariables
>;
export const GetTeamGamesBySeasonDocument = gql`
  query GetTeamGamesBySeason($season: Int!, $league: Int!, $team: String) {
    games(
      order: { season: DESC, week: ASC }
      where: {
        and: [
          { season: { gte: $season } }
          { leagueType: { id: { eq: $league } } }
        ]
        or: [{ homeTeam: { eq: $team } }, { awayTeam: { eq: $team } }]
      }
    ) {
      ...Game
    }
  }
  ${GameFragmentDoc}
`;

/**
 * __useGetTeamGamesBySeasonQuery__
 *
 * To run a query within a React component, call `useGetTeamGamesBySeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamGamesBySeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamGamesBySeasonQuery({
 *   variables: {
 *      season: // value for 'season'
 *      league: // value for 'league'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useGetTeamGamesBySeasonQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTeamGamesBySeasonQuery,
    GetTeamGamesBySeasonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTeamGamesBySeasonQuery,
    GetTeamGamesBySeasonQueryVariables
  >(GetTeamGamesBySeasonDocument, options);
}
export function useGetTeamGamesBySeasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTeamGamesBySeasonQuery,
    GetTeamGamesBySeasonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTeamGamesBySeasonQuery,
    GetTeamGamesBySeasonQueryVariables
  >(GetTeamGamesBySeasonDocument, options);
}
export type GetTeamGamesBySeasonQueryHookResult = ReturnType<
  typeof useGetTeamGamesBySeasonQuery
>;
export type GetTeamGamesBySeasonLazyQueryHookResult = ReturnType<
  typeof useGetTeamGamesBySeasonLazyQuery
>;
export type GetTeamGamesBySeasonQueryResult = Apollo.QueryResult<
  GetTeamGamesBySeasonQuery,
  GetTeamGamesBySeasonQueryVariables
>;
export const GetGamesByTeamIdDocument = gql`
  query GetGamesByTeamId($season: Int!, $league: Int!, $teamId: UUID) {
    games(
      order: { week: ASC }
      where: {
        and: [
          { season: { eq: $season } }
          { leagueType: { id: { eq: $league } } }
        ]
        or: [{ homeTeamId: { eq: $teamId } }, { awayTeamId: { eq: $teamId } }]
      }
    ) {
      ...Game
    }
  }
  ${GameFragmentDoc}
`;

/**
 * __useGetGamesByTeamIdQuery__
 *
 * To run a query within a React component, call `useGetGamesByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesByTeamIdQuery({
 *   variables: {
 *      season: // value for 'season'
 *      league: // value for 'league'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetGamesByTeamIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGamesByTeamIdQuery,
    GetGamesByTeamIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGamesByTeamIdQuery, GetGamesByTeamIdQueryVariables>(
    GetGamesByTeamIdDocument,
    options,
  );
}
export function useGetGamesByTeamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGamesByTeamIdQuery,
    GetGamesByTeamIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGamesByTeamIdQuery,
    GetGamesByTeamIdQueryVariables
  >(GetGamesByTeamIdDocument, options);
}
export type GetGamesByTeamIdQueryHookResult = ReturnType<
  typeof useGetGamesByTeamIdQuery
>;
export type GetGamesByTeamIdLazyQueryHookResult = ReturnType<
  typeof useGetGamesByTeamIdLazyQuery
>;
export type GetGamesByTeamIdQueryResult = Apollo.QueryResult<
  GetGamesByTeamIdQuery,
  GetGamesByTeamIdQueryVariables
>;
export const GetGamesByPlayerIdDocument = gql`
  query GetGamesByPlayerId($season: Int!, $playerId: UUID) {
    gameStats(
      order: { game: { week: ASC } }
      where: {
        and: [{ season: { eq: $season } }, { playerId: { eq: $playerId } }]
      }
    ) {
      game {
        ...Game
      }
    }
  }
  ${GameFragmentDoc}
`;

/**
 * __useGetGamesByPlayerIdQuery__
 *
 * To run a query within a React component, call `useGetGamesByPlayerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesByPlayerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesByPlayerIdQuery({
 *   variables: {
 *      season: // value for 'season'
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetGamesByPlayerIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGamesByPlayerIdQuery,
    GetGamesByPlayerIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGamesByPlayerIdQuery,
    GetGamesByPlayerIdQueryVariables
  >(GetGamesByPlayerIdDocument, options);
}
export function useGetGamesByPlayerIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGamesByPlayerIdQuery,
    GetGamesByPlayerIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGamesByPlayerIdQuery,
    GetGamesByPlayerIdQueryVariables
  >(GetGamesByPlayerIdDocument, options);
}
export type GetGamesByPlayerIdQueryHookResult = ReturnType<
  typeof useGetGamesByPlayerIdQuery
>;
export type GetGamesByPlayerIdLazyQueryHookResult = ReturnType<
  typeof useGetGamesByPlayerIdLazyQuery
>;
export type GetGamesByPlayerIdQueryResult = Apollo.QueryResult<
  GetGamesByPlayerIdQuery,
  GetGamesByPlayerIdQueryVariables
>;
export const GetCareerStatsForPlayerDocument = gql`
  query GetCareerStatsForPlayer($playerId: UUID) {
    careerStats(where: { playerId: { eq: $playerId } }) {
      ...CareerStats
    }
  }
  ${CareerStatsFragmentDoc}
`;

/**
 * __useGetCareerStatsForPlayerQuery__
 *
 * To run a query within a React component, call `useGetCareerStatsForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareerStatsForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareerStatsForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetCareerStatsForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCareerStatsForPlayerQuery,
    GetCareerStatsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCareerStatsForPlayerQuery,
    GetCareerStatsForPlayerQueryVariables
  >(GetCareerStatsForPlayerDocument, options);
}
export function useGetCareerStatsForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCareerStatsForPlayerQuery,
    GetCareerStatsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCareerStatsForPlayerQuery,
    GetCareerStatsForPlayerQueryVariables
  >(GetCareerStatsForPlayerDocument, options);
}
export type GetCareerStatsForPlayerQueryHookResult = ReturnType<
  typeof useGetCareerStatsForPlayerQuery
>;
export type GetCareerStatsForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetCareerStatsForPlayerLazyQuery
>;
export type GetCareerStatsForPlayerQueryResult = Apollo.QueryResult<
  GetCareerStatsForPlayerQuery,
  GetCareerStatsForPlayerQueryVariables
>;
export const GetSeasonStatsForPlayerDocument = gql`
  query GetSeasonStatsForPlayer($playerId: UUID) {
    seasonStats(where: { playerId: { eq: $playerId } }) {
      ...SeasonStats
    }
  }
  ${SeasonStatsFragmentDoc}
`;

/**
 * __useGetSeasonStatsForPlayerQuery__
 *
 * To run a query within a React component, call `useGetSeasonStatsForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeasonStatsForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeasonStatsForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetSeasonStatsForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSeasonStatsForPlayerQuery,
    GetSeasonStatsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSeasonStatsForPlayerQuery,
    GetSeasonStatsForPlayerQueryVariables
  >(GetSeasonStatsForPlayerDocument, options);
}
export function useGetSeasonStatsForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSeasonStatsForPlayerQuery,
    GetSeasonStatsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSeasonStatsForPlayerQuery,
    GetSeasonStatsForPlayerQueryVariables
  >(GetSeasonStatsForPlayerDocument, options);
}
export type GetSeasonStatsForPlayerQueryHookResult = ReturnType<
  typeof useGetSeasonStatsForPlayerQuery
>;
export type GetSeasonStatsForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetSeasonStatsForPlayerLazyQuery
>;
export type GetSeasonStatsForPlayerQueryResult = Apollo.QueryResult<
  GetSeasonStatsForPlayerQuery,
  GetSeasonStatsForPlayerQueryVariables
>;
export const GetGameStatsForPlayerDocument = gql`
  query GetGameStatsForPlayer($playerId: UUID) {
    gameStats(where: { playerId: { eq: $playerId } }) {
      ...GameStats
    }
  }
  ${GameStatsFragmentDoc}
`;

/**
 * __useGetGameStatsForPlayerQuery__
 *
 * To run a query within a React component, call `useGetGameStatsForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameStatsForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameStatsForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetGameStatsForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGameStatsForPlayerQuery,
    GetGameStatsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGameStatsForPlayerQuery,
    GetGameStatsForPlayerQueryVariables
  >(GetGameStatsForPlayerDocument, options);
}
export function useGetGameStatsForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameStatsForPlayerQuery,
    GetGameStatsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGameStatsForPlayerQuery,
    GetGameStatsForPlayerQueryVariables
  >(GetGameStatsForPlayerDocument, options);
}
export type GetGameStatsForPlayerQueryHookResult = ReturnType<
  typeof useGetGameStatsForPlayerQuery
>;
export type GetGameStatsForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetGameStatsForPlayerLazyQuery
>;
export type GetGameStatsForPlayerQueryResult = Apollo.QueryResult<
  GetGameStatsForPlayerQuery,
  GetGameStatsForPlayerQueryVariables
>;
export const GetPlayHistoryForPlayerDocument = gql`
  query GetPlayHistoryForPlayer($playerId: UUID) {
    gameStats(where: { playerId: { eq: $playerId } }) {
      ...PlayHistory
    }
  }
  ${PlayHistoryFragmentDoc}
`;

/**
 * __useGetPlayHistoryForPlayerQuery__
 *
 * To run a query within a React component, call `useGetPlayHistoryForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayHistoryForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayHistoryForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetPlayHistoryForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlayHistoryForPlayerQuery,
    GetPlayHistoryForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPlayHistoryForPlayerQuery,
    GetPlayHistoryForPlayerQueryVariables
  >(GetPlayHistoryForPlayerDocument, options);
}
export function useGetPlayHistoryForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayHistoryForPlayerQuery,
    GetPlayHistoryForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayHistoryForPlayerQuery,
    GetPlayHistoryForPlayerQueryVariables
  >(GetPlayHistoryForPlayerDocument, options);
}
export type GetPlayHistoryForPlayerQueryHookResult = ReturnType<
  typeof useGetPlayHistoryForPlayerQuery
>;
export type GetPlayHistoryForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetPlayHistoryForPlayerLazyQuery
>;
export type GetPlayHistoryForPlayerQueryResult = Apollo.QueryResult<
  GetPlayHistoryForPlayerQuery,
  GetPlayHistoryForPlayerQueryVariables
>;
export const GetGamesMissedQueryDocument = gql`
  query GetGamesMissedQuery($playerId: UUID) {
    playerStats(
      where: {
        and: [
          { playerId: { eq: $playerId } }
          { gameId: { eq: "00000000-0000-0000-0000-000000000000" } }
          { seasonTypeId: { eq: 0 } }
        ]
      }
    ) {
      ...GamesMissed
    }
  }
  ${GamesMissedFragmentDoc}
`;

/**
 * __useGetGamesMissedQueryQuery__
 *
 * To run a query within a React component, call `useGetGamesMissedQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesMissedQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesMissedQueryQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetGamesMissedQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGamesMissedQueryQuery,
    GetGamesMissedQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGamesMissedQueryQuery,
    GetGamesMissedQueryQueryVariables
  >(GetGamesMissedQueryDocument, options);
}
export function useGetGamesMissedQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGamesMissedQueryQuery,
    GetGamesMissedQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGamesMissedQueryQuery,
    GetGamesMissedQueryQueryVariables
  >(GetGamesMissedQueryDocument, options);
}
export type GetGamesMissedQueryQueryHookResult = ReturnType<
  typeof useGetGamesMissedQueryQuery
>;
export type GetGamesMissedQueryLazyQueryHookResult = ReturnType<
  typeof useGetGamesMissedQueryLazyQuery
>;
export type GetGamesMissedQueryQueryResult = Apollo.QueryResult<
  GetGamesMissedQueryQuery,
  GetGamesMissedQueryQueryVariables
>;
export const GetCareerOffensiveLineStatsDocument = gql`
  query GetCareerOffensiveLineStats($playerId: UUID) {
    playerStats(
      where: {
        and: [
          { playerId: { eq: $playerId } }
          { gameId: { eq: "00000000-0000-0000-0000-000000000001" } }
        ]
      }
    ) {
      ...OffensiveLineStats
    }
  }
  ${OffensiveLineStatsFragmentDoc}
`;

/**
 * __useGetCareerOffensiveLineStatsQuery__
 *
 * To run a query within a React component, call `useGetCareerOffensiveLineStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareerOffensiveLineStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareerOffensiveLineStatsQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetCareerOffensiveLineStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCareerOffensiveLineStatsQuery,
    GetCareerOffensiveLineStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCareerOffensiveLineStatsQuery,
    GetCareerOffensiveLineStatsQueryVariables
  >(GetCareerOffensiveLineStatsDocument, options);
}
export function useGetCareerOffensiveLineStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCareerOffensiveLineStatsQuery,
    GetCareerOffensiveLineStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCareerOffensiveLineStatsQuery,
    GetCareerOffensiveLineStatsQueryVariables
  >(GetCareerOffensiveLineStatsDocument, options);
}
export type GetCareerOffensiveLineStatsQueryHookResult = ReturnType<
  typeof useGetCareerOffensiveLineStatsQuery
>;
export type GetCareerOffensiveLineStatsLazyQueryHookResult = ReturnType<
  typeof useGetCareerOffensiveLineStatsLazyQuery
>;
export type GetCareerOffensiveLineStatsQueryResult = Apollo.QueryResult<
  GetCareerOffensiveLineStatsQuery,
  GetCareerOffensiveLineStatsQueryVariables
>;
export const GetTagsDocument = gql`
  query GetTags {
    tags {
      ...Tag
    }
  }
  ${TagFragmentDoc}
`;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(
    GetTagsDocument,
    options,
  );
}
export function useGetTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTagsQuery,
    GetTagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(
    GetTagsDocument,
    options,
  );
}
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<
  GetTagsQuery,
  GetTagsQueryVariables
>;
export const GetTransactionsByDateRangeDocument = gql`
  query GetTransactionsByDateRange(
    $after: String
    $before: String
    $last: Int
    $first: Int
    $fromDate: DateTime!
    $toDate: DateTime!
    $name: String
    $order: [TransactionSortInput!]
  ) {
    transactionsByDateRange(
      after: $after
      before: $before
      last: $last
      first: $first
      order: $order
      where: {
        and: [
          { transactionDate: { gte: $fromDate } }
          { transactionDate: { lte: $toDate } }
        ]
        or: [
          { player: { firstName: { contains: $name } } }
          { player: { lastName: { contains: $name } } }
        ]
      }
    ) {
      edges {
        node {
          ...Transaction
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${TransactionFragmentDoc}
`;

/**
 * __useGetTransactionsByDateRangeQuery__
 *
 * To run a query within a React component, call `useGetTransactionsByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsByDateRangeQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      name: // value for 'name'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetTransactionsByDateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >(GetTransactionsByDateRangeDocument, options);
}
export function useGetTransactionsByDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTransactionsByDateRangeQuery,
    GetTransactionsByDateRangeQueryVariables
  >(GetTransactionsByDateRangeDocument, options);
}
export type GetTransactionsByDateRangeQueryHookResult = ReturnType<
  typeof useGetTransactionsByDateRangeQuery
>;
export type GetTransactionsByDateRangeLazyQueryHookResult = ReturnType<
  typeof useGetTransactionsByDateRangeLazyQuery
>;
export type GetTransactionsByDateRangeQueryResult = Apollo.QueryResult<
  GetTransactionsByDateRangeQuery,
  GetTransactionsByDateRangeQueryVariables
>;
export const GetTransactionsForPlayerDocument = gql`
  query GetTransactionsForPlayer($playerId: UUID) {
    transactionsByDateRange(
      where: { playerId: { eq: $playerId } }
      order: [{ transactionDate: DESC }]
    ) {
      edges {
        node {
          id
          season
          transactionDate
          transactionDescription {
            transactionDescAbbr
            transactionDesc
          }
          startClub {
            id
            clubCode
          }
          resultClub {
            id
            clubCode
          }
          potentialClub {
            id
            clubCode
          }
          initialStatus {
            transStatusDesc
            transStatusDescAbbr
            transStatusShortDesc
          }
          resultStatus {
            transStatusDesc
            transStatusDescAbbr
            transStatusShortDesc
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetTransactionsForPlayerQuery__
 *
 * To run a query within a React component, call `useGetTransactionsForPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsForPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsForPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetTransactionsForPlayerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTransactionsForPlayerQuery,
    GetTransactionsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTransactionsForPlayerQuery,
    GetTransactionsForPlayerQueryVariables
  >(GetTransactionsForPlayerDocument, options);
}
export function useGetTransactionsForPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTransactionsForPlayerQuery,
    GetTransactionsForPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTransactionsForPlayerQuery,
    GetTransactionsForPlayerQueryVariables
  >(GetTransactionsForPlayerDocument, options);
}
export type GetTransactionsForPlayerQueryHookResult = ReturnType<
  typeof useGetTransactionsForPlayerQuery
>;
export type GetTransactionsForPlayerLazyQueryHookResult = ReturnType<
  typeof useGetTransactionsForPlayerLazyQuery
>;
export type GetTransactionsForPlayerQueryResult = Apollo.QueryResult<
  GetTransactionsForPlayerQuery,
  GetTransactionsForPlayerQueryVariables
>;
export const AdminCreateUserDocument = gql`
  mutation AdminCreateUser($createAppUserRequest: CreateUserRequest!) {
    createUser(request: $createAppUserRequest) {
      id
    }
  }
`;
export type AdminCreateUserMutationFn = Apollo.MutationFunction<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;

/**
 * __useAdminCreateUserMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserMutation, { data, loading, error }] = useAdminCreateUserMutation({
 *   variables: {
 *      createAppUserRequest: // value for 'createAppUserRequest'
 *   },
 * });
 */
export function useAdminCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateUserMutation,
    AdminCreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateUserMutation,
    AdminCreateUserMutationVariables
  >(AdminCreateUserDocument, options);
}
export type AdminCreateUserMutationHookResult = ReturnType<
  typeof useAdminCreateUserMutation
>;
export type AdminCreateUserMutationResult =
  Apollo.MutationResult<AdminCreateUserMutation>;
export type AdminCreateUserMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;
export const EditAppUserDocument = gql`
  mutation EditAppUser($editUserRequest: UpdateUserRequest!) {
    updateUser(request: $editUserRequest) {
      id
    }
  }
`;
export type EditAppUserMutationFn = Apollo.MutationFunction<
  EditAppUserMutation,
  EditAppUserMutationVariables
>;

/**
 * __useEditAppUserMutation__
 *
 * To run a mutation, you first call `useEditAppUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAppUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAppUserMutation, { data, loading, error }] = useEditAppUserMutation({
 *   variables: {
 *      editUserRequest: // value for 'editUserRequest'
 *   },
 * });
 */
export function useEditAppUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditAppUserMutation,
    EditAppUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditAppUserMutation, EditAppUserMutationVariables>(
    EditAppUserDocument,
    options,
  );
}
export type EditAppUserMutationHookResult = ReturnType<
  typeof useEditAppUserMutation
>;
export type EditAppUserMutationResult =
  Apollo.MutationResult<EditAppUserMutation>;
export type EditAppUserMutationOptions = Apollo.BaseMutationOptions<
  EditAppUserMutation,
  EditAppUserMutationVariables
>;
export const ProvisionUserDocument = gql`
  mutation ProvisionUser($provisionUserRequest: ProvisionUserRequest!) {
    provisionUser(request: $provisionUserRequest) {
      id
    }
  }
`;
export type ProvisionUserMutationFn = Apollo.MutationFunction<
  ProvisionUserMutation,
  ProvisionUserMutationVariables
>;

/**
 * __useProvisionUserMutation__
 *
 * To run a mutation, you first call `useProvisionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvisionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provisionUserMutation, { data, loading, error }] = useProvisionUserMutation({
 *   variables: {
 *      provisionUserRequest: // value for 'provisionUserRequest'
 *   },
 * });
 */
export function useProvisionUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProvisionUserMutation,
    ProvisionUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProvisionUserMutation,
    ProvisionUserMutationVariables
  >(ProvisionUserDocument, options);
}
export type ProvisionUserMutationHookResult = ReturnType<
  typeof useProvisionUserMutation
>;
export type ProvisionUserMutationResult =
  Apollo.MutationResult<ProvisionUserMutation>;
export type ProvisionUserMutationOptions = Apollo.BaseMutationOptions<
  ProvisionUserMutation,
  ProvisionUserMutationVariables
>;
export const DeactivateUserDocument = gql`
  mutation DeactivateUser($deactivateUserRequest: DeactivateUserRequest!) {
    deactivateUser(request: $deactivateUserRequest) {
      id
    }
  }
`;
export type DeactivateUserMutationFn = Apollo.MutationFunction<
  DeactivateUserMutation,
  DeactivateUserMutationVariables
>;

/**
 * __useDeactivateUserMutation__
 *
 * To run a mutation, you first call `useDeactivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserMutation, { data, loading, error }] = useDeactivateUserMutation({
 *   variables: {
 *      deactivateUserRequest: // value for 'deactivateUserRequest'
 *   },
 * });
 */
export function useDeactivateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateUserMutation,
    DeactivateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateUserMutation,
    DeactivateUserMutationVariables
  >(DeactivateUserDocument, options);
}
export type DeactivateUserMutationHookResult = ReturnType<
  typeof useDeactivateUserMutation
>;
export type DeactivateUserMutationResult =
  Apollo.MutationResult<DeactivateUserMutation>;
export type DeactivateUserMutationOptions = Apollo.BaseMutationOptions<
  DeactivateUserMutation,
  DeactivateUserMutationVariables
>;
export const GetUserByIdDocument = gql`
  query GetUserById($id: UUID!) {
    userById(id: $id) {
      id
      firstName
      lastName
      emailAddress
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
  }
  ${PermissionFragmentDoc}
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options,
  );
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserByIdQuery,
    GetUserByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options,
  );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<
  typeof useGetUserByIdLazyQuery
>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
  GetUserByIdQuery,
  GetUserByIdQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser {
    user {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserQuery,
    GetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers {
    users {
      id
      authenticatedId
      firstName
      lastName
      emailAddress
      status
      role {
        name
        permissions {
          ...Permission
        }
      }
    }
  }
  ${PermissionFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options,
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options,
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
export const GetVideoDocument = gql`
  query GetVideo($request: GetVideoForPlaysRequest!) {
    videoForPlays(request: $request) {
      launchUrl
    }
  }
`;

/**
 * __useGetVideoQuery__
 *
 * To run a query within a React component, call `useGetVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetVideoQuery(
  baseOptions: Apollo.QueryHookOptions<GetVideoQuery, GetVideoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVideoQuery, GetVideoQueryVariables>(
    GetVideoDocument,
    options,
  );
}
export function useGetVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVideoQuery,
    GetVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVideoQuery, GetVideoQueryVariables>(
    GetVideoDocument,
    options,
  );
}
export type GetVideoQueryHookResult = ReturnType<typeof useGetVideoQuery>;
export type GetVideoLazyQueryHookResult = ReturnType<
  typeof useGetVideoLazyQuery
>;
export type GetVideoQueryResult = Apollo.QueryResult<
  GetVideoQuery,
  GetVideoQueryVariables
>;
export const GetFilteredPlaysForVideoDocument = gql`
  query GetFilteredPlaysForVideo($playFilterRequest: PlayFilterRequestInput!) {
    filteredPlays(playFilterRequest: $playFilterRequest) {
      playId
      gameId
    }
  }
`;

/**
 * __useGetFilteredPlaysForVideoQuery__
 *
 * To run a query within a React component, call `useGetFilteredPlaysForVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredPlaysForVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredPlaysForVideoQuery({
 *   variables: {
 *      playFilterRequest: // value for 'playFilterRequest'
 *   },
 * });
 */
export function useGetFilteredPlaysForVideoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFilteredPlaysForVideoQuery,
    GetFilteredPlaysForVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFilteredPlaysForVideoQuery,
    GetFilteredPlaysForVideoQueryVariables
  >(GetFilteredPlaysForVideoDocument, options);
}
export function useGetFilteredPlaysForVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFilteredPlaysForVideoQuery,
    GetFilteredPlaysForVideoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFilteredPlaysForVideoQuery,
    GetFilteredPlaysForVideoQueryVariables
  >(GetFilteredPlaysForVideoDocument, options);
}
export type GetFilteredPlaysForVideoQueryHookResult = ReturnType<
  typeof useGetFilteredPlaysForVideoQuery
>;
export type GetFilteredPlaysForVideoLazyQueryHookResult = ReturnType<
  typeof useGetFilteredPlaysForVideoLazyQuery
>;
export type GetFilteredPlaysForVideoQueryResult = Apollo.QueryResult<
  GetFilteredPlaysForVideoQuery,
  GetFilteredPlaysForVideoQueryVariables
>;
